import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import TRANSLATIONS_NO from './locales/no/translations.json';
import TRANSLATIONS_EN from './locales/en/translations.json';
import TRANSLATIONS_SW from './locales/sv/translations.json';
// the translations
const resources = {
    en: {
        translation: TRANSLATIONS_EN
    },
    no: {
        translation: TRANSLATIONS_NO
    },
    sv: {
        translation: TRANSLATIONS_SW
    }
}
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });


i18n.languages = ['en', 'no', 'sv'];
export default i18n;