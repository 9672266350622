import * as React from "react";
import "./EqEventinviteList.scss";
import { Row, Col } from "antd";
import EqEventInvite from "../EqEventInvite/EqEventInvite";
import { EqEventAttendeeStatus } from "../../shared/enums";
export interface IEqEventinviteList {
  invitesList: Array<any>;
  setEventDetail?: any;
  step: number;
  requestText?: string;
}
const EqEventinviteList = ({
  invitesList,
  setEventDetail,
  step,
  requestText
}: IEqEventinviteList) => {
  // console.log(invitesList);
  React.useEffect(() => {
    // 
    // console.log(invitesList);
  }, [invitesList]);
  return (
    <>
      <Row className="">
        <Col span={24}>
          {invitesList &&
            invitesList.length > 0 &&
            invitesList.map((item: any) => (
              <>
                <EqEventInvite
                  {...{
                    props: item,
                    viewEventDetail: setEventDetail,
                    step: step,
                    requestText:requestText
                  }}
                ></EqEventInvite>
              </>
            ))}
        </Col>
      </Row>
    </>
  );
};
export default EqEventinviteList;
