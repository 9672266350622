// SubscriptionSettingTable.tsx

import React from "react";
import { Table, Button, Popconfirm } from "antd";
import { ISubscriptionSetting } from "../../../shared/interfaces/subsctiption/ISubscriptionSetting";
import { EqSubscriptionRoleType } from "../../../shared/enums";

interface EqSubscriptionSettingTableProps {
  settings: ISubscriptionSetting[];
  subscriptionProducts: any[];
  onDelete: (settingId: string) => void;
  onEdit: (setting: ISubscriptionSetting) => void;
}

const EqSubscriptionSettingTable: React.FC<EqSubscriptionSettingTableProps> = ({
  subscriptionProducts,
  settings,
  onDelete,
  onEdit,
}) => {
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Subscription Product",
      dataIndex: "subscriptionProductId",
      key: "subscriptionProductId",
      render: (subscriptionProductId: string) => {
        const product = subscriptionProducts.find(
          (product: any) => product.id === subscriptionProductId
        );
        return product ? product.name : subscriptionProductId;
      },
    },
    {
      title: "Transactions Fee",
      dataIndex: "transactionsFee",
      key: "transactionsFee",
    },
    { title: "Min Capacity", dataIndex: "minCapacity", key: "minCapacity" },
    { title: "Max Capacity", dataIndex: "maxCapacity", key: "maxCapacity" },
    { title: "Free Days", dataIndex: "freeDays", key: "freeDays" },
    {
      title: "Subscription Type",
      dataIndex: "subscriptionType",
      key: "subscriptionType",
    },
    {
      title: "Role Type",
      dataIndex: "roleType",
      key: "roleType",
      render: (roleType: any) => {
        const role = EqSubscriptionRoleType[roleType];
        return role;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: ISubscriptionSetting) => (
        <>
          <Button type="link" onClick={() => onEdit(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => onDelete(record.id)}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return <Table dataSource={settings} columns={columns} />;
};

export default EqSubscriptionSettingTable;
