import * as React from "react";
import "./EqEventInviteDetail.scss";
import { Row, Col, Popconfirm, Space, Button } from "antd";
import {
  ShoppingCartOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  CheckCircleFilled,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  getDefaultImage,
  imageType,
  ValidateIsStudent,
  ValidateIsTrainer,
} from "../../shared/Common";
import { IEventUserGroupDto } from "../../shared/interfaces/IEventUserGroupDto";
import moment from "moment";
import Text from "antd/lib/typography/Text";
import { useAuthState } from "../../context/Context";
import { useTranslation, Trans } from 'react-i18next';

export interface IEqEventInviteDetail {
  props: any;
  acceptClicked: (event: any) => void;
  acceptAllClicked: (events: any) => void;
  rejectAllClicked: (events: any) => void;
  rejectClicked: (event: any) => void;
  step: number;
  setEventDetail?: any;
  requestText?: string;
}
const EqEventInviteDetail = ({
  props,
  acceptClicked,
  acceptAllClicked,
  rejectAllClicked,
  rejectClicked,
  setEventDetail,
  step,
  requestText
}: IEqEventInviteDetail) => {
  const userDetails: any = useAuthState();
  const { t, i18n } = useTranslation();

  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const isStudent: boolean = ValidateIsStudent(userDetails);
  console.log(props);
  const { organizer, user, events } = props;
  const accept = (event: any) => {
    acceptClicked(event);
  };
  const reject = (event: any) => {
    rejectClicked(event);
  };
  const acceptAll = () => {
    
    acceptAllClicked(events);
  };
  const rejectAll = () => {
    rejectAllClicked(events);
  };

  const rowClicked = (item: any) => {
    console.log(step);
    if (step == 2) {
      if (setEventDetail != null) {
        console.log("view pending invites");
        setEventDetail(props);
      }
    }
  };

  React.useEffect(() => {
console.log(props);

  }, [props]);
  return (
    <div className="eq-event-invite">
      <Row
        className="eq-invite-row"
        // justify="space-around"
        onClick={rowClicked}
      >
        <Col
          xs={{ span: 4, offset: 0 }}
          sm={{ span: 4, offset: 0 }}
          md={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          xl={{ span: 2, offset: 0 }}
          xxl={{ span: 2, offset: 0 }}
        >
          <img
            src={
              !isStudent
                ? user?.userImage?.url || getDefaultImage(imageType.partner)
                : organizer?.userImage?.url ||
                  getDefaultImage(imageType.partner)
            }
            className="user-img"
            alt="user"
          />
        </Col>

        <Col xs={17} sm={17} md={20} lg={20}>
          <Row>
            {isStudent && (
              <Col xs={24} sm={24} md={5}>
                <Text className="eq-invite-row-subTitle" type="secondary">
                  Team {organizer?.firstName}
                </Text>
              </Col>
            )}
            <Col xs={24} sm={24} md={5}>
              <Text className="eq-invite-row-title">
                {!isStudent
                  ? user?.firstName + " " + user?.lastName
                  : organizer?.firstName + " " + organizer?.lastName}
              </Text>
            </Col>
            <Col className="eq-invite-row-dis" xs={24} sm={24} md={8}>
              From: {!isStudent ? user?.userName : organizer?.userName}
            </Col>
            <Col className="eq-invite-row-dis" xs={24} sm={24} md={6}>
               {requestText?requestText:t("invitesYouTo") +":"}  

              {events?.length == 1
                ? events.length + t("activity")
                : events.length + t("activities")}
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ span: 3, offset: 0 }}
          sm={{ span: 3, offset: 0 }}
          md={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          xl={{ span: 2, offset: 0 }}
          xxl={{ span: 2, offset: 0 }}
        >
          <div>
            <ShoppingCartOutlined style={{ fontSize: "125%" }} />
          </div>
        </Col>
      </Row>
      {events &&
        events.length > 0 &&
        events.map((userEvent: IEventUserGroupDto) => (
          <>
            <Row className="eq-invites-list">
              <Col flex="auto">
                {userEvent.event?.title+" || "} 
                {moment(userEvent.event?.startDate).format("dddd DD/MM") +
                  " at " +
                  moment(userEvent.event?.startTime).format("HH:mm")}
              </Col>
              <Col>
                <Space>
                  <Button
                    onClick={() => accept(userEvent)}
                    className="invite-accept-btn"
                    size="small"
                    shape="circle"
                    icon={<CheckOutlined />}
                  />
                  <Button
                    onClick={() => reject(userEvent)}
                    className="invite-reject-btn"
                    size="small"
                    shape="circle"
                    icon={<CloseOutlined />}
                  />

                  {/* <Popconfirm
                    title={"This is Paid Activity. \n Do you want to Proceed?"}
                    onConfirm={() => accept(userEvent)}
                    // onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <span>
                      <CheckCircleTwoTone twoToneColor="#03dac5" />
                    </span>{" "}
                  </Popconfirm>

                  <span onClick={() => reject(userEvent)}>
                    <CloseCircleTwoTone twoToneColor="red" />
                  </span> */}
                </Space>

              </Col>
              
            </Row>
          </>
        ))}
      <Row className="invite-action" justify="space-between">
        <Col><b> {t("Accept/RejectAll")}</b></Col>
        <Col>
          <Space>
            <Button
              onClick={acceptAll}
              className="invite-accept-btn"
              shape="circle"
              icon={<CheckOutlined />}
            />
            <Button
              onClick={rejectAll}
              className="invite-reject-btn"
              shape="circle"
              icon={<CloseOutlined />}
            />

            {/*          
              <span onClick={acceptAll}>
                <CheckCircleTwoTone  twoToneColor="#03dac5" />
                <CheckCircleFilled twoToneColor="#03dac5"  />
              </span> */}
            {/* 
              <span onClick={rejectAll}>
                <CloseCircleTwoTone twoToneColor="red" />
                RejectAll
              </span> */}
          </Space>
        </Col>
      </Row>
    </div>
  );
};
export default EqEventInviteDetail;
