import * as React from "react";
import "./EqEventHolderActivityBoard.scss";
import { Row, Col, Tabs } from "antd";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsUser,
} from "../../shared/Common";
import Api from "../../shared/Api";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import { ProductType, TrainingFilters } from "../../shared/enums";
import EqToggle, { IEqToggle } from "../../custom/eqToggle/EqToggle";
import EqProductList from "../../custom/eqProductList/EqProductList";
import TabPane from "antd/lib/tabs/TabPane";
import EqTrainerAllEvents from "../../eqTrainer/trainerAllEvents/EqTrainerAllEvents";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";

export interface IFilteredTrainings {
  name: any;
  type: any;
  date: any;
}

const EqEventHolderActivityBoard = (props: any) => {
  const userDetails: any = useAuthState();
  const isUserValid: boolean = ValidateIsUser(userDetails);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [toggle, setToggle] = React.useState(false);

  const [trainingsList, setTrainingsList] = React.useState([]) as any;
  const [filteredTrainingsList, setFilteredTrainingsList] = React.useState(
    {} as IFilteredTrainings
  );
  const [selectedTrainingsList, setSelectedTrainingsList] = React.useState(
    []
  ) as any;

  const [toggleFilters, setToggleFilters] = React.useState([] as any);
  const [selectedToggle, setSelectedToggle] = React.useState(
    TrainingFilters.All
  ) as any;


  React.useEffect(() => {

    window.scrollTo(0, 0);
  }, []);

  // Toggle Filters

  /////

  return (
    <AnimationDiv>

      <div className={`eq-trainer-activity-board`}>
        <div className="eq-container-fluid">


          <Tabs
            className="ab-tabs"
            // onChange={onChange}
            type="card"
            defaultActiveKey="1"
          >


            <TabPane tab={t("events")} key="2">
              <EqTrainerAllEvents></EqTrainerAllEvents>
            </TabPane>



          </Tabs>
        </div>
      </div>
    </AnimationDiv>


  );
};
export default EqEventHolderActivityBoard;
