
//"https://localhost:44366/"

// const config = {
//     apiUrl: "https://equinet-test.azurewebsites.net/",
//     baseUrl:"https://equinet-test.azurewebsites.net"

// }
const config = {
    apiUrl: window.location.protocol + "//" + window.location.host + '/',
    baseUrl:window.location.protocol + "//" + window.location.host
}

export default config;