import { Avatar, Card, Col, List, Modal, Row } from "antd";
import React, { useState } from "react";
import EqButton from "../eqButton/EqButton";
import "./EqAddParent.scss";
import EqGroupsBoard from "../../eqTrainer/CreateGroupBoard/EqCreateGroupBoard";
import EqGroupCreateOrEdit from "../../eqTrainer/eqGroupCreateOrEdit/EqGroupCreateOrEdit";
import { EqInput } from "../eqInput/EqInput";
import Meta from "antd/lib/card/Meta";
import { DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { IEqGroupMembersList } from "../../eqTrainer/eqGroupMembersList/EqGroupMembersList";
import { IStudentGroupDto } from "../../shared/interfaces/IStudentGroupDto";
import { useTranslation } from "react-i18next";
import { IEqGroupMember } from "../../eqTrainer/eqGroupMembers/EqGroupMembers";
import Api from "../../shared/Api";
import {
  ApiEndpoints,
  GetProfessionalsMessage,
  openNotificationWithIcon,
} from "../../shared/Common";
import { eqNotificationTypes } from "../../shared/enums";
// import "../../../components/eqTrainer/editTrainer/eqEditTrainer"
const EqAddParent = ({ delFunc, selected, hideGroup, hideName }: any) => {
  const [addNew, setAddNew] = useState(false);
  const [show, setShow] = useState(true);

  const parentInitial = {
    name: "",
    email: "",
    phoneNumber: "",
  };

  const [parentFormData, setParentFormData] = React.useState({
    ...parentInitial,
  } as any);
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [parentList, setParentList] = React.useState([] as any);
  const [parentData, setParentData] = React.useState([] as any);
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const showAddNew = () => {
    setAddNew(true);
  };
  const cancelFunc = (e: any) => {
    setAddNew(false);
  };



  const successModal = (
    
    <Modal
      className="register-popup"
      open={successModalVisible}
      onOk={() => setSuccessModalVisible(false)}
      onCancel={() => setSuccessModalVisible(false)}
    >
      {/* <img className="logo-home" src="images/Logo-home.svg" /> */}
      
      <h2 className="popup-heading">Parent Registration Success</h2>
      <img src="images/icons/open-envelope.png" className="icon" alt="Email Icon" />
      <p className="register-popup-text">
        A email has been sent to your Parent email address! <br />
        Please check your email and Update Password With Email link to activate your account.
      </p>
    </Modal>
  );

  const validateData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    if (data.name == "") {
      openNotificationWithIcon(msg, "Name" + desc);
      isComplete = false;
    } else if (data.email == "") {
      openNotificationWithIcon(msg, "Date Of Birth" + desc);
      isComplete = false;
    }
    // else if (data.phoneNumber == "") {
    //   openNotificationWithIcon(msg, "Image" + desc);
    //   isComplete = false;
    //   // } else if (uiImage == null || Object.keys(uiImage).length === 0) {
    //   //   openNotificationWithIcon(msg, "Image" + desc);
    //   //   isComplete = false;
    // }

    return isComplete;
  };
  const saveFunc = (e: any) => {
    let validate = validateData(parentFormData);
    if (validate == true) {
      Api.post(ApiEndpoints.CreateStudentParent, parentFormData).then(
        (response) => {
          if (response && response.status == 200) {
            openNotificationWithIcon(
              "Created!",
              "Parent Has been Added",
              eqNotificationTypes.success
            );
            setSuccessModalVisible(true);
            setIsError(false);
            setParentFormData({ ...parentInitial });


            const list = [...parentList];
            list.push(response.data);

            setParentList(list);
            // setImageUrl("")
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating Parent. Please try again or contact administrator."
            );
            setIsError(true);
          }
        },
        (error) => {
          if (error?.response?.status === 403) {
            openNotificationWithIcon(
              "Server error",
              "Not Authorized Kindly contact administrator."
            );
            setIsError(true);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the Parent. Please try again or contact administrator."
            );
            setIsError(true);
          }
        }
      );
    } else {
      setIsError(true);
    }
    // setAddNew(true);
    // setAddNew(false);
  };

  const { t, i18n } = useTranslation();
  const [isCheckAll, setIsCheckAll] = React.useState(false);
  const [selectedAttendees, setSelectedAttendees] = React.useState([]) as any;

  const checkAll = () => {
    //setIsCheckAll(!isCheckAll);
    //groupMembersList?.map(
    //    (item: IStudentGroupDto) => {
    //        item.selected = !isCheckAll;
    //    }
    //);
    //setMembersList(membersList);
  };

  const toggleCheck = () => {};

  const [visible, setVisible] = useState(true);

  const removeCard = () => {
    setVisible((prev) => !prev);
  };

  //
  const parentsDetails = [
    { name: "Asad", email: "asad@gmail.com", number: "0123456789" },
    { name: "Mary", email: "mary@gmail.com", number: "0123456789" },
  ];

  // interface IProps {
  //   parent: {
  //     name: string;
  //     email: string;
  //     number: number;
  //   };
  // }

  const removeParent = (data: any) => {
    Api.delete(ApiEndpoints.DeleteParent + `/${data.key}`).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(data);
        } else {
          setIsError(true);
          // setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        // setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const removeItem = (data: any) => {
    if (parentList != undefined) {
      var updatedList =
        parentList != undefined
          ? parentList.filter((item: any) => item.key !== data.key)
          : new Array<any>();
      setParentList(updatedList);

      // horsesList.map((item: any, index: number) => {
      //   item.display = index == 0 ? true : false;
      // });
    }
  };

  const getParents = () => {
    // Api.get(ApiEndpoints.getTrainers).then(
    Api.get(ApiEndpoints.GetStudentParents).then(
      (response) => {
        if (response && response.status == 200) {
          var dto = [] as any;
          setParentData(response.data);
          response.data?.map((item: any) => {
            dto.push({
              key: item.key,
              email: item.email,
              name: item?.firstName
                ? item.firstName
                : "" + " " + item.lastName
                ? item.lastName
                : "",
              phoneNumber: item.phoneNumber,
            });
          });
          // console.log(dto);
          setParentList(dto);
          setIsError(false);
          setMessage(GetProfessionalsMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetProfessionalsMessage(error.response.status));
      }
    );
  };
  React.useEffect(() => {
    getParents();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="parent-sb">
      <div className="eq-sb-parent">
        <h3 className="heading">{t("parent")}</h3>
        <EqButton
          type="button"
          onClick={showAddNew}
          classNames="btn-background-orange btn-info-parent"
        >
          <img alt="" src="/plus.svg" />
        </EqButton>
      </div>
      <Col md={12} xs={24} sm={24} className="mb-16"></Col>
      {addNew && (
        <>
          {hideGroup != true && <Col xs={2} sm={2} md={1}></Col>}
          <Row className="input-row">
            <Col md={12} xs={24} sm={24} className="col-left mb-16">
              <EqInput
                label="Name"
                className="profile-input"
                placeholder=""
                value={parentFormData.name}
                onChange={(event: any) =>
                  setParentFormData({
                    ...parentFormData,
                    name: event?.target?.value,
                  })
                }
              ></EqInput>
            </Col>
            <Col md={12} xs={24} sm={24} className="mb-16">
              <EqInput
                label="E-mail"
                className="profile-input"
                placeholder=""
                type="email"
                value={parentFormData.email}
                onChange={(event: any) =>
                  setParentFormData({
                    ...parentFormData,
                    email: event?.target?.value,
                  })
                }
              ></EqInput>
            </Col>
            <Col md={12} xs={24} sm={24} className="col-left mb-16">
              <EqInput
                label="Number"
                className="profile-input"
                placeholder=""
                value={parentFormData.phoneNumber}
                onChange={(event: any) =>
                  setParentFormData({
                    ...parentFormData,
                    phoneNumber: event?.target?.value,
                  })
                }
              ></EqInput>
            </Col>
          </Row>
          <Row justify="end" align="bottom" className="eq-cg-btn">
            <Col className="eq-cg-buttons">
              <EqButton
                type="button"
                bordered="true"
                classNames="btn-light-gray parent-btn"
                onClick={cancelFunc}
              >
                {t("cancel")}
              </EqButton>
            </Col>
            {/* {isEdit ? (
                        <Col className="eq-cg-buttons">
                            <EqButton
                            type="button"
                            bordered="true"
                            classNames="btn-light-gray"
                            //   onClick={saveGroup}
                            >
                            Save Group
                            </EqButton>
                        </Col>
                        ) : (
                        ""
                        )} */}
            <Col className="eq-cg-buttons">
              <EqButton
                type="button"
                classNames="btn-background-orange parent-btn"
                onClick={saveFunc}
              >
                {t("save")}
              </EqButton>
            </Col>
          </Row>
        </>
      )}

      <List className="parents-list">
        {parentList.map((item: any) => (
          <Row className="parent-row">
            <Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={item.name}
            />

            <Col span={12}>
              <Row className="parent-info">
                <Col>
                  <Meta className="parent-email" description={item.email} />
                </Col>
                <Col>
                  <Meta
                    className="parent-number"
                    description={item.phoneNumber}
                  />
                </Col>
              </Row>
            </Col>

            <Col className="eq-msg-icons" onClick={() => removeParent(item)}>
              <span>
                <img alt="" src="/trash-alt.svg" />
              </span>
            </Col>

            {/* <EqButton
                    type="button"
                    classNames="btn-background-orange parent-btn"
                    onClick={()=>removeParent(item)}
                    >
                    {t("delete")}
                        </EqButton> */}
          </Row>
        ))}
      </List>
      {successModal}
    </div>
  );
};

export default EqAddParent;
