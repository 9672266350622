import * as React from "react";
import "./EqTrainingStudentView.scss";
import {
  Row,
  Col,
  Input,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useAuthDispatch, useAuthState } from "../../../context/Context";
import {
  ApiEndpoints,
  GetEventMessage,
} from "../../../shared/Common";
import { loadState } from "../../../shared/LocalStorage";

// import EqGroupMembersList from "../../eqGroupMembersList/EqGroupMembersList";
import {
  IDatePickerOptions,
} from "../../../custom/eqDatePicker/EqDatePicker";
import Api from "../../../shared/Api";
import { IStudentGroupDto } from "../../../shared/interfaces/IStudentGroupDto";
import {
  EqDisciplineType,
  EqEventRepeatType,
  EqEventAccessType,
  eqNotificationTypes,
  EqUserType,
} from "../../../shared/enums";
import { ISelectProps } from "../../../shared/Interfaces";
import { notification } from "antd";
// import EqAttendeeCard, {
//   IEqAttendeeCardData,
// } from "../../eqAttendeeCard/EqAttendeeCard";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import { IEqCollapse } from "../../../custom/eqCollapse/EqCollapse";
import { updateActivityData } from "../../../context/Actions";
import { ToolFilled } from "@ant-design/icons";
import EqAttendeeCard, { IEqAttendeeCardData } from "../../../eqTrainer/eqAttendeeCard/EqAttendeeCard";
import EqTags from "../../../custom/eqTags/EqTags";

const newEmptyCardData: IEqAttendeeCardData = {
  key: 0,
  cardkey: 1,
  timeFrom: moment(),
  timeTo: moment(),
  duration: "",
  maxSpaceCount: 1,
  price: "",
  trainingLevel: "",
  attendees: [] as any,
  attendeesKeys: [] as any,
  display: true,
};

export interface IEqTrainingStudentView {
  areas: any;
  weekDaysData: any;
  trainerStudents?: any;
  studentHorsesList?: any;
  groupPopulation: any;
  eventTypes: any;
  eventKey: any;
  eventData: any;
  onCreateOrUpdateActivity?: any;
  isStudent:boolean
}

const EqTrainingStudentView = ({
  areas,
  weekDaysData,
  trainerStudents,
  studentHorsesList,
  groupPopulation,
  eventTypes,
  eventKey,
  eventData,
  onCreateOrUpdateActivity,
  isStudent,
}: IEqTrainingStudentView) => {
  const dispatch = useAuthDispatch();
  const userDetails: any = useAuthState();
  const localData = loadState();

  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { t } = useTranslation();
  const [value, setValue] = React.useState(1);
  const [studentsList, setStudentsList] = React.useState([]) as any;
  const [groupStudentsList, setGroupStudentsList] = React.useState([]) as any;

  const [groupStudentPopulation, setGroupStudentPopulation] = React.useState(
    [] as any
  );
  const [studentPopulation, setStudentPopulation] = React.useState({
    allStudents: [] as any,
    filteredStudents: [] as any,
  });
  // const [areas, setAreas] = React.useState([]) as any;
  // const [groupPopulation, setGroupPopulation] = React.useState([]) as any;
  const [searchedAttendees, setSearchedAttendees] = React.useState([]) as any;
  const [eventAttendees, setEventAttendees] = React.useState<
    Array<IStudentGroupDto>
  >([]);
  const [searchStudentsList, setSearchStudentsList] = React.useState([] as any);

  const [eventDurations, setEventDurations] = React.useState([]) as any;
  const [eventRepeats, setEventRepeats] = React.useState([]) as any;
  // const [eventTypes, setEventTypes] = React.useState({
  //   privateDiscipline: [],
  //   publicDiscipline: [],
  //   editOpenDiscipline: [],
  //   toggleOptions: [],
  // } as any);

  const [eventAccessTypes, setEventAccessTypes] = React.useState({
    allAccessTypes: [],
    privateOnly: [],
    toggleOptions: [],
  } as any);
  const [trainingData, setTrainingData] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState({
    allWeekDays: [],
    selectedWeekDays: [],
    weekDayTags: [],
  } as any);
  // const [eventKey, setEventKey] = React.useState(0);
  const [memberSearch, setMemberSearch] = React.useState([] as any);
  const [selectedGroups, setSelectedGroups] = React.useState([] as any);
  const [groupKeys, setGroupKeys] = React.useState([] as any);
  const [isMultipleDateSelected, setIsMultipleDateSelected] =
    React.useState(false);
  // const [isPublic, setIsPublic] = React.useState(false);
  const [isOpenEvent, setIsOpenEvent] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isPaymentDeadline, setIsPaymentDeadline] = React.useState(true);
  const [selectedLoc, setSelectedLoc] = React.useState("");
  const [isEventCollapse, setIsEventCollapse] = React.useState(true);

  const [isTrainingSaved, setIsTrainingSaved] = React.useState(false);
  //for private event

  const [multipleStartOn, setMultipleStartOn] = React.useState([] as any);
  const [attendeeCardData, setAttendeeCardData] = React.useState(
    [] as Array<IEqAttendeeCardData>
  );

  const [formData, setFormData] = React.useState({}) as any;

  const openNotificationWithIcon = (
    msg: any,
    desc: any,
    type = eqNotificationTypes.error
  ) => {
    if (type == eqNotificationTypes.success) {
      notification["success"]({
        message: msg,
        description: desc,
      });
    } else if (type == eqNotificationTypes.info) {
      notification["info"]({
        message: msg,
        description: desc,
      });
    } else {
      notification["error"]({
        message: msg,
        description: desc,
      });
    }
  };

  const setEditFormData = (data: any) => {
    if (data != undefined) {
      var selectedDate = new Date(data.startDate);
      var selectedTimeFrom = new Date(data.startTime);

      var calculatedTimeFrom = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        selectedTimeFrom.getHours(),
        selectedTimeFrom.getMinutes(),
        0,
        0
      );

      var calculatedTimeTo;
      if (data.endTime != undefined) {
        var selectedTimeTo = new Date(data.endTime);
        calculatedTimeTo = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          selectedTimeTo.getHours(),
          selectedTimeTo.getMinutes(),
          0,
          0
        );
      }
      var selectedGroups = new Array<string>();
      if (data.selectedGroups != null) {
        data.selectedGroups?.map((item: string) => {
          selectedGroups.push("" + item);
        });
      }
      let loc = areas?.filter((item: any) => {
        return item.key == data.locationKey;
      })?.shift();
   
      if (loc != null && loc != undefined) {
      setSelectedLoc(loc.value);
    }
      setSelectedGroups(selectedGroups);
      getStudentPopulation([...selectedGroups]);
      //setSelectedStartDate(selectedDate);
      //setSelectedStartTime(calculatedTimeFrom);

      return {
        id: data.id,
        key: data.key,

        title: data.title,
        startDate: moment(data.startDate).local(),
        endDate: moment(data.endDate).local(),
        repeats: data.repeatType,
        isRepeat: data.isRepeat,
        timeFrom: moment(calculatedTimeFrom.valueOf()).local(),
        timeTo:
          calculatedTimeTo != undefined
            ? moment(calculatedTimeTo.valueOf()).local()
            : calculatedTimeTo,
        duration: data.duration,
        disciplineType: data.disciplineType as EqDisciplineType,
        trainingLevel: data?.trainingLevel,
        locationKey: data.locationKey,
        maxSpaceCount: data.maxAvailableSpace,
        isPaid: data.price > 0 ? true : false,
        price: data.price > 0 ? data.price : "",
        paymentMethod: data.paymentMethod,
        isLatePayment: data.isLatePayment,
        paymentDeadline:
          data.paymentDeadline > 0
            ? moment(data.paymentDeadline).local()
            : null,
        message: data.message,
        accessType: data.accessType as EqEventAccessType,
        horseAvailabilityStatus: data.horseAvailabilityStatus,
      };
    } else {
      return {
        title: "",
        startDate: moment(),
        endDate: moment(),
        repeats: "",
        isRepeat: false,
        timeFrom: moment(),
        timeTo: moment(),
        duration: "",
        disciplineType: EqDisciplineType.Jumping,
        trainingLevel: "",
        locationKey: "",
        maxSpaceCount: "",
        isPaid: true,
        price: "",
        paymentMethod: 1,
        isLatePayment: false,
        paymentDeadline: null,
        message: "",
        accessType: EqEventAccessType.Private + "",
        horseAvailabilityStatus: 0,
        eqImage: {},
      };
    }
  };

  const setEditAttendeeCardData = (data: any) => {
    
    if (data != undefined) {
      
      var cardKey = 0;
      var attendeeCardDataList = [] as Array<IEqAttendeeCardData>;
      data.trainingTimes?.map((item: any) => {
        var keysList = item.attendees?.map((attendee: any) => {
          return attendee.userKey;
        });

        cardKey = cardKey + 1;
        var newAddedCardData: IEqAttendeeCardData = {
          key: item.key,
          cardkey: cardKey,
          timeFrom: moment(item.startTime),
          timeTo: moment(item.endTime),
          duration: item.duration,
          maxSpaceCount: item.maxAvailableSpace,
          price: item.price,

          trainingLevel: item.trainingLevel,
          attendees: item.attendees,
          attendeesKeys: keysList,
          attendeesHorse: item.attendeesHorse ? item.attendeesHorse : [],
          // attendeesKeys:[],
          display: true,
        };
        attendeeCardDataList.push(newAddedCardData);
      });
      setAttendeeCardData(attendeeCardDataList);
    }
  };

  const { TextArea } = Input;
  const gutter = { xs: 8, sm: 16, md: 24, lg: 32 };

  /////////////

  const setEventDefaults = () => {
    setFormData({
      title: "",
      startDate: moment(),
      endDate: moment(),
      repeats: "",
      isRepeat: false,
      timeFrom: moment(),
      timeTo: moment(),
      duration: "",
      disciplineType: EqDisciplineType.Jumping,
      trainingLevel: "",
      locationKey: "",
      maxSpaceCount: "",
      isPaid: true,
      price: "",
      paymentMethod: 1,
      isLatePayment: false,
      paymentDeadline: null,
      message: "",
      accessType: EqEventAccessType.Private + "",
      horseAvailabilityStatus: 0,
      eqImage: {},
    });
    setEventAttendees([] as any);
    setAttendeeCardData([] as Array<IEqAttendeeCardData>);
    setSelectedGroups([]);
    // InitializeAttendeeCard();
    setIsTrainingSaved(false);

    // if(localData!== undefined){
    //   var data={...localData,activityData:{}}
    //   saveState(data)
    // }
    setMultipleStartOn([]);
    // setEventAccessAndDisciplineTypes(
    //   false,
    //   EqEventAccessType.Private,
    //   EqDisciplineType.Dressage
    // );
    // setIsPublic(false);
    setIsPaymentDeadline(true);
    setIsOpenEvent(false);
    updateActivityData(dispatch, {});
  };

  const validateData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    //same for public and private
    if (data.title == "") {
      openNotificationWithIcon(msg, "Title" + desc);
      isComplete = false;
    }
    //else if (uiImage == null || Object.keys(uiImage).length === 0) {
    //    openNotificationWithIcon(msg, "Image" + desc);
    //    isComplete = false;
    //}
    else if (data.disciplineType == "") {
      openNotificationWithIcon(msg, "Event Type" + desc);
      isComplete = false;
    } else if (data.accessType == "") {
      openNotificationWithIcon(msg, "Access type" + desc);
      isComplete = false;
    } else if (data.locationKey == "") {
      openNotificationWithIcon(msg, "Location" + desc);
      isComplete = false;
    } else if (!data.startDate) {
      openNotificationWithIcon(msg, "Start On" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Private &&
      data.isRepeat == true &&
      data.repeats == ""
    ) {
      openNotificationWithIcon(msg, "Repeats" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Private &&
      isEdit == false &&
      data.isRepeat == true &&
      (weekDays.selectedWeekDays == null ||
        weekDays.selectedWeekDays.length == 0)
    ) {
      openNotificationWithIcon(msg, "Week days" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Private &&
      (attendeeCardData == null || attendeeCardData.length == 0) &&
      isEdit == false
    ) {
      // addNewAttendeeCard();
      openNotificationWithIcon(msg, "Time and duration" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Private &&
      attendeeCardData.length > 0 &&
      isEdit == false
    ) {
      attendeeCardData.map((d: any) => {
        console.log(d);
        if (data.disciplineType != EqDisciplineType.Open && d.duration == "") {
          openNotificationWithIcon(msg, "Duration" + desc);
          isComplete = false;
        } else if (
          data.disciplineType != EqDisciplineType.Open &&
          d.maxSpaceCount == ""
        ) {
          openNotificationWithIcon(msg, "Maximum available spaces" + desc);
          isComplete = false;
        } else if (
          data.disciplineType == EqDisciplineType.Jumping &&
          d.trainingLevel == ""
        ) {
          openNotificationWithIcon(msg, "Training level" + desc);
          isComplete = false;
        }
      });
    }
    return isComplete;
  };

  const validateTrainingData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    
    //same for public and private
    if (data.title == "") {
      openNotificationWithIcon(msg, "Title" + desc);
      isComplete = false;
    } else if (data.disciplineType == "") {
      openNotificationWithIcon(msg, "Event Type" + desc);
      isComplete = false;
    } else if (data.accessType == "") {
      openNotificationWithIcon(msg, "Access type" + desc);
      isComplete = false;
    } else if (data.locationKey == "") {
      openNotificationWithIcon(msg, "Location" + desc);
      isComplete = false;
    } else if (!data.startDate) {
      openNotificationWithIcon(msg, "Start On" + desc);
      isComplete = false;
    } else if (data.isRepeat == true && data.repeats == "") {
      openNotificationWithIcon(msg, "Repeats" + desc);
      isComplete = false;
    } else if (
      isEdit == false &&
      data.isRepeat == true &&
      (weekDays.selectedWeekDays == null ||
        weekDays.selectedWeekDays.length == 0)
    ) {
      openNotificationWithIcon(msg, "Week days" + desc);
      isComplete = false;
    }

    return isComplete;
  };

  const OnSaveTraining = () => {
    let validate = validateTrainingData(formData);

    if (validate == true) {
      // if(localData!== undefined){
      //   var data={...localData,activityData:formData}
      //   saveState(data)
      // }

      updateActivityData(dispatch, formData);
      setIsTrainingSaved(true);
      // getGroupsStudentList();
      getStudentPopulation(null);
    }
  };

  React.useEffect(() => {
    // getWeekDays();
    createEventDurations();
    createEventRepeats();


    if (eventKey !== 0) {
      setIsEdit(true);

      setEditAttendeeCardData({ ...eventData });
      var formObj = setEditFormData({ ...eventData });
      setFormData(formObj);
    } else {
      setIsEdit(false);



      if (
        userDetails.activityData &&
        userDetails.activityData.accessType === "1"
      ) {
        setFormData(userDetails.activityData);
      } else {
        setEventDefaults();
      }

      createEventAccessTypes();
 
    }
  }, [eventKey,areas]);

  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays({ ...weekDays, allWeekDays: response.data });
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };




  const getStudentPopulation = (groups: any) => {
    const selectedGroupsData = groups || selectedGroups;
    var students = new Array<IStudentGroupDto>();
    var studentsData = [] as any;

    var groupStudents = new Array<IStudentGroupDto>();
    var groupStudentsData = [] as any;

    // var sData = new Array<any>();
    // var gsData = new Array<any>();
    if (selectedGroupsData && selectedGroupsData.length > 0) {
      if (trainerStudents) {
        [...trainerStudents].map((item: any) => {
          [...item.user.groups].filter((s: any) => {
            let res = selectedGroupsData.some((gs: number) => {
              if (gs == s.group.key) {
                let studentGroup: IStudentGroupDto = {
                  key: item.key,
                  user: item.user,
                  userType: 2,
                  group: s.group,
                };
                groupStudents.push(studentGroup);

                var name =
                  item.user != undefined
                    ? item.user.firstName
                      ? item.user.firstName
                      : item.user.userName
                    : item.group != undefined
                    ? item.group.name
                    : "";
                var newKey = item.key;

                groupStudentsData.push({
                  key: newKey,
                  value: name,
                  label: name,
                });
              }
            });
          });
        });
      }
      // console.log("groupStudent 2:", groupStudents);
      // console.log("groupStudentData 2:", groupStudentsData);
    }

    // Trainer Students
    if (trainerStudents) {
      trainerStudents?.map((d: any) => {
        if (d.userType == EqUserType.Student) {
          students.push(d);

          var name =
            d.user != undefined
              ? d.user.firstName
                ? d.user.firstName
                : d.user.userName
              : d.group != undefined
              ? d.group.name
              : "";
          var newKey = d.key;

          studentsData.push({
            key: newKey,
            value: name,
            label: name,
          });
        }
      });
      if (groupStudentsData && groupStudentsData.length > 0) {
        //console.log("filter data");
        //console.log(groupStudentsData);
        let filteredStudents = studentsData.filter((s: any) => {
          let res = groupStudentsData.some((gs: any) => {
            return s.key === gs.key;
          });
          return !res;
        });
        setGroupStudentPopulation([...groupStudentsData]);
        setStudentPopulation({
          ...studentPopulation,
          filteredStudents: [...filteredStudents],
        });
        groupStudentsData = [];
      } else {
        setStudentPopulation({
          ...studentPopulation,
          allStudents: [...studentsData],
        });
      }

      if (groupStudents && groupStudents.length > 0) {
        var list = [] as any;
        let filteredStudents = students.filter((s: any) => {
          let res = groupStudents.some((gs: any) => {
            return s.key === gs.key;
          });
          if (res == true) {
            var index = groupStudents.findIndex((x: any) => {
              return x.key === s.key;
            });
            list.push(groupStudents[index]);
          } else {
            list.push(s);
          }
          return !res;
        });
        setSearchStudentsList([...list]);
      } else {
        setSearchStudentsList([...students]);
      }
    }
  };




  const createEventDurations = () => {
    var durations = [];
    let durationVals = [30, 45, 60, 90];
    for (var x = 0; x < durationVals.length; x++) {
      durations.push({ key: durationVals[x], value: durationVals[x] });
    }
    //for (var i = 5; i < 101; i += 5) {
    //    durations.push({ key: i, value: i });
    //}
    setEventDurations(durations);
  };

  const createEventRepeats = () => {
    var repeats = [];
    for (var x in EqEventRepeatType) {
      if (isNaN(Number(x)) == false) {
        repeats.push({ key: x, value: EqEventRepeatType[x] });
      }
    }
    setEventRepeats(repeats);
  };

  const createEventAccessTypes = () => {
    var accessTypes = [];
    var pAccess = [];
    for (var x in EqEventAccessType) {
      let label;
      if (x == EqEventAccessType.Private.toString()) {
        label = "Training";
      } else if (x == EqEventAccessType.Public.toString()) {
        label = "Event";
      }
      if (isNaN(Number(x)) == false) {
        accessTypes.push({ key: x, value: label });

        if (Number(x) == EqEventAccessType.Private) {
          pAccess.push({ key: Number(x), value: label });
        }
      }
    }
    // console.log(formData?.disciplineType);
    if (isEdit == true) {
      if (formData?.disciplineType == EqDisciplineType.Open) {
        setEventAccessTypes({
          allAccessTypes: [...accessTypes],
          privateOnly: [...pAccess],
          toggleOptions: [...pAccess],
        });
      } else {
        setEventAccessTypes({
          allAccessTypes: [...accessTypes],
          privateOnly: [...pAccess],
          toggleOptions: [...accessTypes],
        });
      }
    } else {
      setEventAccessTypes({
        allAccessTypes: [...accessTypes],
        privateOnly: [...pAccess],
        toggleOptions: [...accessTypes],
      });
    }
  };

  const updateEndTime = (
    fullYear: number,
    month: number,
    date: number,
    hours: number,
    minutes: number,
    seconds: number,
    milliseconds: number
  ) => {
    var calculatedDate = new Date(
      fullYear,
      month,
      date,
      hours,
      minutes,
      seconds,
      milliseconds
    );
    setFormData({ ...formData, timeTo: moment(calculatedDate.valueOf()) });
  };

  // const createTraining = () => {
  //   var data = setEventObject(formData);

  //   if (data.startDate != null) {
  //     Api.post(ApiEndpoints.CreateTraining, data).then(
  //       (response) => {
  //         if (response && response.status == 200) {
  //           openNotificationWithIcon(
  //             "Created!",
  //             "Training(s) have been created, you can go to trainings and look at all trainings",
  //             eqNotificationTypes.success
  //           );
  //           setIsError(false);
  //           setMessage(GetEventMessage(response.status));
  //           setEventDefaults();
  //           setEventAttendees([] as any);
  //           onCreateOrUpdateActivity();
  //         } else {
  //           openNotificationWithIcon(
  //             "Server error",
  //             "Error occurred while creating the event(s). Please try again or contact administrator."
  //           );
  //           setIsError(true);
  //         }
  //       },
  //       (error) => {
  //         if (error?.response?.status === 403) {
  //           openNotificationWithIcon(
  //             "Server error",
  //             "Not Authorized Kindly Add Payment or contact administrator."
  //           );
  //           setIsError(true);
  //         } else {
  //           openNotificationWithIcon(
  //             "Server error",
  //             "Error occurred while creating the event(s). Please try again or contact administrator."
  //           );
  //           setIsError(true);
  //         }
  //       }
  //     );
  //   } else {
  //     setIsError(true);
  //   }
  // };

  // const editTraining = () => {
  //   var data = setEventObject(formData);
  //   // Api.post(ApiEndpoints.eventEdit, data).then(
  //   Api.post(ApiEndpoints.UpdateTraining, data).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         setIsError(false);
  //         setIsEdit(false);
  //         setMessage(GetEventMessage(response.status));

  //         setEventDefaults();
  //         createEventAccessTypes();
  //         // createEventTypes();
  //         onCreateOrUpdateActivity();
  //       } else {
  //         setIsError(true);
  //         setMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };
  // const UpdateTrainingLesson = () => {
  //   var data = setEventObject(formData);
  //   // Api.post(ApiEndpoints.eventEdit, data).then(
  //   Api.post(ApiEndpoints.UpdateTrainingLesson, data).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         setIsError(false);
  //         setIsEdit(false);
  //         setMessage(GetEventMessage(response.status));

  //         setEventDefaults();
  //         createEventAccessTypes();
  //         // createEventTypes();
  //         onCreateOrUpdateActivity();
  //       } else {
  //         setIsError(true);
  //         setMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };

  const sortAscending = (unsorted: Array<IStudentGroupDto>) => {
    const sorted = [...unsorted].sort((obj1, obj2) => {
      if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() >
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return 1;
      } else if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() <
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    // console.log(sorted);
    return sorted;
  };

  const sortDescending = (unsorted: Array<IStudentGroupDto>) => {
    const sorted = [...unsorted].sort((obj1, obj2) => {
      console.log(obj1);
      console.log(obj2);
      if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() <
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return 1;
      } else if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() >
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    // console.log(sorted);
    return sorted;
  };

  const onSortChange = (group: any): any => {
    var sortedList = [] as any;
    switch (group) {
      case "asc":
        sortedList = sortAscending(eventAttendees);
        setEventAttendees(sortedList);
        break;
      case "desc":
        sortedList = sortDescending(eventAttendees);
        setEventAttendees(sortedList);
        break;
      default:
        sortedList = sortAscending(eventAttendees);
        setEventAttendees(sortedList);
    }
  };

  const removeEventMember = (member: any) => {
    console.log(eventAttendees);
    var attendees = [...eventAttendees];
    var keys = [...groupKeys];
    if (attendees != null && attendees.length > 0) {
      const index = attendees.findIndex((x: any) => x.key == member.key);
      if (index > -1) {
        attendees.splice(index, 1);
      }
    }
    if (keys != null && keys.length > 0) {
      const index = keys.findIndex((x: any) => x);
      if (index > -1) {
        keys.splice(index, 1);
      }
    }
    setGroupKeys([...keys]);
    console.log(keys);
    setEventAttendees([...attendees]);
    console.log(attendees);
  };



  ////////////////

  const typeSelectProps: ISelectProps = {
    options: eventTypes.toggleOptions,
    defaultSelectedValue: formData?.disciplineType,
    classNames: "extra-height",
    changeFunc: (value: string) => {
      let type: number = +value;
      setFormData({ ...formData, disciplineType: type });

      if (isEdit == true) {
        if (type == EqDisciplineType.Open) {
          setIsOpenEvent(true);
        } else {
          setIsOpenEvent(false);
        }
      } else {
        if (type == EqDisciplineType.Open) {
          setIsOpenEvent(true);
        } else {
          setIsOpenEvent(false);
        }
        // setEventAccessAndDisciplineTypes(false, formData?.accessType, type);
      }
    },
  };



  const repeatSelectProps: ISelectProps = {
    mode: "tags",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    placeholder: "Select repeating duration",
    classNames: "eq-addSelect extra-height",
    defaultSelectedValue: formData?.repeatType
      ? formData.repeatType.toString()
      : "",
    options: eventRepeats,
    changeFunc: (value: string) => {
      let repeat: number = +value;
      setFormData({ ...formData, repeats: repeat });
    },
  };

  const areaSelectProps: ISelectProps = {
    isTag: false,
    placeholder: "Select area",
    defaultSelectedValue: formData?.locationKey
      ? formData.locationKey + ""
      : "",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    options: areas,
    changeFunc: (value: string) => {
      setFormData({ ...formData, locationKey: value });

      let loc = areas
        ?.filter((item: any) => {
          return item.key == value;
        })
        ?.shift();
      if (loc != null && loc != undefined) {
        setSelectedLoc(loc.value);
      }
    },
  };

  const onChangeGroupSelect = (value: any) => {
    const selectedMembers = [...value];
    setSelectedGroups(selectedMembers);
  };

  const GroupProps = {
    mode: "multiple",
    showSearch: true,
    optionFilterProp: "label",
    filterOption: true,
    isTag: false,
    showArrow: false,
    allowClear: true,
    changeFunc: onChangeGroupSelect,
    // changeFunc: (value: any) => {
    //   setFormData({ ...formData,selectedGroups: value });
    // },
    classNames: "eq-multiSelect eq-searchSelect extra-height",
    ddClassName: "eq-select-dropdown",
    icon: [
      <span className="eq-search-icon">
        <img alt="" src="/search-icon.svg" />
      </span>,
    ],
    selectedIcon: [
      <span className="">
        <img alt="" src="/check-square.svg" />
      </span>,
    ],
    options: groupPopulation,
    // defaultSelectedValue: selectedGroups,
    defaultSelectedValue: selectedGroups,
  };

  const sortProps = {
    border: false,
    allowClear: false,
    classNames: "eq-select-sort",
    ddClassName: "eq-cg-dropdown",
    defaultSelectedValue: "Sort A-Z",
    changeFunc: onSortChange,
    options: [
      { key: "asc", value: "Sort A-Z" },
      { key: "desc", value: "Sort Z-A" },
    ],
  };

  const startOnProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.startDate,
    placeHolder: "Date",
    classNames: "eq-event",
    onChangeFunc: (date: any) => {
      setFormData({ ...formData, startDate: moment(date) });
    },
  };

  const endOnProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.endDate,
    placeHolder: "Date",
    classNames: "eq-event",
    onChangeFunc: (date: any) => {
      setFormData({ ...formData, endDate: moment(date) });
    },
  };

  const advancePaymentDateProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.paymentDeadline,
    placeHolder: "Date",
    classNames: "eq-event",
    onChangeFunc: (date: any) => {
      setFormData({ ...formData, isLatePayment: false });
      setFormData({ ...formData, paymentDeadline: moment(date) });
    },
  };

  const weekDaysSelectProps: ISelectProps = {
    mode: "multiple",
    isTag: false,
    showArrow: true,
    allowClear: true,
    classNames: "eq-multiSelect eq-addSelect",
    ddClassName: "eq-select-dropdown",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    placeholder: "Select days",
    defaultSelectedValue: weekDays.selectedWeekDays,
    options: weekDays.allWeekDays,
    changeFunc: (param: any) => {
      setWeekDays({
        ...weekDays,
        selectedWeekDays: [...param],
        weekDayTags: [...param],
      });
    },
  };
  const changeEventCollapseDisplay = (display: boolean) => {
    setIsEventCollapse(display);
  };
  const eventCollapsibleProps: IEqCollapse = {
    display: isEventCollapse,
    setDisplay: changeEventCollapseDisplay,
    showArrow: false,
    classNames: "eq-transaparent-header",
    header: [
      <Row align="middle" justify="end">
        {selectedLoc && (
          <>
            <Col span={1}>
              <span className="">
                <img alt="" src="/map-marker-alt.svg" />
              </span>
            </Col>
          </>
        )}
        <Col flex="auto">
          <div className="header-label">{selectedLoc}</div>
        </Col>
        <Col>
          <EqTags className="eq-tag"
          text={t(EqDisciplineType[formData.disciplineType])}
          dicipline={true}
          />
            
          
        </Col>
      </Row>,
    ],
  };

  const attendeeCardTimeFromChange = (cardkey: number, timeFrom: any) => {
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].timeFrom = moment(timeFrom);
    }
    setAttendeeCardData(modifyList);
  };
  const attendeeCardTimeToChange = (cardkey: number, timeTo: any) => {
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].timeTo = moment(timeTo);
    }
    setAttendeeCardData(modifyList);
  };
  const attendeeCardDurationChange = (cardkey: number, minutes: any) => {
    console.log(attendeeCardData);
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].duration = minutes;
    }
    setAttendeeCardData(modifyList);
  };
  const attendeeCardAvailableSpaceChange = (cardkey: number, spaces: any) => {
    console.log(attendeeCardData);
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].maxSpaceCount = spaces;
    }
    setAttendeeCardData(modifyList);
  };
  const attendeeCardTrainingLevelChange = (cardkey: number, levels: any) => {
    //console.log(attendeeCardData);
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].trainingLevel = levels;
    }
    setAttendeeCardData(modifyList);
  };
  const attendeeCardAttendeesChange = (cardkey: number, attendees: any) => {
    //console.log(attendees);
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].attendees = attendees;
      let keys = modifyList[index]?.attendees?.map((a: any) => a.key);
      modifyList[index].attendeesKeys = keys;
    }

    setAttendeeCardData(modifyList);
  };

  const removeAttendeeCard = (cardkey: number) => {
    var newDataArr = [...attendeeCardData];
    if (attendeeCardData != null && attendeeCardData.length > 0) {
      newDataArr = newDataArr.filter((x: any) => x.cardkey != cardkey);
    }
    if (newDataArr.length > 0) {
      newDataArr[newDataArr.length - 1].display = true;
    }
    setAttendeeCardData(newDataArr);
  };
  //console.log("Data", attendeeCardData);

  const InitializeAttendeeCard = () => {
    var newDataArr = [] as Array<IEqAttendeeCardData>;
    var newAddedCardData = newEmptyCardData;
    // : IEqAttendeeCardData = {
    //   // key: 0,
    //   cardkey: 1,
    //   timeFrom: moment(),
    //   timeTo: moment(),
    //   duration: "",
    //   maxSpaceCount: 1,
    //   trainingLevel: "",
    //   attendees: [] as any,
    //   attendeesKeys: [] as any,
    //   display: true,
    // };
    newDataArr.push({ ...newAddedCardData });

    setAttendeeCardData(newDataArr);
  };

  const addNewAttendeeCard = () => {
    console.log("length", attendeeCardData.length);

    var newDataArr = [...attendeeCardData];
    var newAddedCardData = newEmptyCardData;
    // : IEqAttendeeCardData = {
    //   // key: 0,
    //   cardkey: 1,
    //   timeFrom: moment(),
    //   timeTo: moment(),
    //   duration: "",
    //   maxSpaceCount: 1,
    //   trainingLevel: "",
    //   attendees: [] as any,
    //   attendeesKeys: [] as any,
    //   display: true,
    // };

    if (attendeeCardData.length > 0) {
      var lastAddedCardData = attendeeCardData[attendeeCardData.length - 1];
      newDataArr[attendeeCardData.length - 1].display = false;
      newAddedCardData.timeFrom = moment(lastAddedCardData.timeFrom).add(
        lastAddedCardData.duration,
        "minutes"
      );
      newAddedCardData.cardkey = lastAddedCardData.cardkey + 1;
    } else {
      setMultipleStartOn([]);
    }
    //console.log(newAddedCardData);
    newDataArr.push({ ...newAddedCardData });

    setAttendeeCardData(newDataArr);
  };
  const onDragAttendee = (result: any) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    //console.log(source);
    //console.log(destination);
    var modifyList = [...attendeeCardData];
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    const sourceCard = attendeeCardData[sInd - 1];
    const destCard = attendeeCardData[dInd - 1];

    const srcKey = sourceCard.attendeesKeys[source.index];
    if (srcKey) {
      let srcKeys = [...sourceCard.attendeesKeys];
      let srcAttendees = sourceCard.attendees.filter((item: any) => {
        return item.key != srcKey;
      });
      let destKeys = [...destCard.attendeesKeys];
      let destAttendees = [...destCard.attendees];
      if (sInd === dInd) {
        const [reorderedKeys] = srcKeys.splice(result.source.index, 1);
        srcKeys.splice(result.destination.index, 0, reorderedKeys);
        sourceCard.attendeesKeys = srcKeys;

        modifyList[sInd - 1] = sourceCard;
      } else {
        const itemExists = destKeys.indexOf(srcKey);
        if (itemExists === -1) {
          destKeys.push(srcKey);
          destCard.attendeesKeys = destKeys;

          let srcAttendee = sourceCard.attendees
            .filter((item: any) => {
              return item.key == srcKey;
            })
            .shift();
          destAttendees.push(srcAttendee);
          destCard.attendees = destAttendees;

          modifyList[dInd - 1] = destCard;

          srcKeys.splice(source.index, 1);
          sourceCard.attendeesKeys = srcKeys;

          sourceCard.attendees = srcAttendees;

          modifyList[sInd - 1] = sourceCard;
        }
      }
    }
    //console.log(modifyList);
    setAttendeeCardData(modifyList);
  };
  return (<div className="student-training-view">
  {/* Removing Tags Code */}



    <div className="eq-create-training-container">
      <div className="">
        <Row gutter={gutter} className="event-info-input">
          <Col className="gutter-row" span={24}>
   

            {(isEdit || isTrainingSaved) && (
              <>
        

                <Row align="middle" justify="space-around">
                  <Col xs={24} sm={24} md={16}>
                    {/* <div className="attendee-card-box"> */}
                    <div className={`attendee-card-box ${isStudent ? "attendee-card-box-student" : ""}`}>

                      <DragDropContext onDragEnd={onDragAttendee}>
                        <>
                          {attendeeCardData && attendeeCardData.length > 0 ? (
                            attendeeCardData?.map(
                              (item: IEqAttendeeCardData, index: number) => (
                                <Droppable isDropDisabled={ isStudent}
                                  key={item.cardkey}
                                  droppableId={`${item.cardkey}`}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      <EqAttendeeCard
                                        key={item.cardkey}
                                        {...{
                                          data: item,
                                          timeFromChange:
                                            attendeeCardTimeFromChange,
                                          timeToChange:
                                            attendeeCardTimeToChange,
                                          durationChange:
                                            attendeeCardDurationChange,
                                          maxSpaceCountChange:
                                            attendeeCardAvailableSpaceChange,
                                          trainingLevelChange:
                                            attendeeCardTrainingLevelChange,
                                          attendeesChange:
                                            attendeeCardAttendeesChange,
                                          removeCard: removeAttendeeCard,
                                          groupKeys: [...groupKeys],
                                          selectedGroups: [...selectedGroups],
                                          groupStudentPopulation: [
                                            ...groupStudentPopulation,
                                          ],
                                          studentPopulation: studentPopulation,
                                          searchStudentsList: [
                                            ...searchStudentsList,
                                          ],
                                          // studentHorsesList:[...studentHorsesList],
                                          multipleStartOn: [...multipleStartOn],
                                          startDate: formData.startDate,
                                          trainingType: formData.disciplineType,
                                          isStudent:isStudent,
                                          
                                          enableCardRemove:
                                            attendeeCardData?.length > 1
                                              ? true
                                              : false,
                                        }}
                                      ></EqAttendeeCard>
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              )
                            )
                          ) : (
                            <Row className="empty-card">
                              <Col span={24}>
                                <ToolFilled style={{ fontSize: "2rem" }} />
                              </Col>

                              <Col>Nothing here yet...</Col>
                            </Row>
                          )}
                          {!isEdit && (
                            <span
                              className="eq-btn-add"
                              onClick={addNewAttendeeCard}
                            >
                              <img
                                alt=""
                                className="add"
                                src="/plus-black.svg"
                              />
                            </span>
                          )}
                        </>
                      </DragDropContext>
                    </div>
                  </Col>
                </Row>
              </>
            )}

            {/* </EqCollapse> */}
            {/* {(isEdit || isTrainingSaved) && (
              <Row justify="end" align="bottom" className="eq-cg-btn">
        
                <Col className="eq-cg-buttons">
                  {isEdit  ? (
                   !isStudent &&(
                     <EqButton
                     disabled={isStudent}
                     type="submit"
                     classNames="btn-background-orange"
                     onClick={UpdateTrainingLesson}
                     // onClick={editTraining}
                     >
                      Save Changes
                    </EqButton>
                      )
                  ) : (
                    <EqButton
                    disabled={isStudent}

                      type="submit"
                      classNames="btn-background-orange"
                      onClick={createTraining}
                    >
                      Create Activity
                    </EqButton>
                  )}
                </Col>
              </Row>
            )} */}
          </Col>
        </Row>
      </div>
    </div>
    </div>
  );
};
export default EqTrainingStudentView;
