const authSessionKey = "auth_key";
const eventKey = "eq_event_key";
// const rememberMe = "rememberMe";

export interface LocalData {
    isSignedin: boolean,
    accessToken: string,
    refreshToken:string,
    roles: Array<string>,
    permissions: Array<string>,
    imageUrl: string,
    key?: number,
    userType?: number,
    name: string,
    isPaymentDue?: boolean,
    isSubscribed?: boolean,
    activityData?:{
        accessType?:string
    } 

}

export const saveState = (data: LocalData): void => {
    let strData: any = null;
    if (data) {
        strData = JSON.stringify(data);
    }
    //sessionStorage.setItem(authSessionKey, strData);
    localStorage.setItem(authSessionKey, strData);
}

export const removeState = (): void => {   
    //sessionStorage.removeItem(authSessionKey);
    localStorage.removeItem(authSessionKey);
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("isRefreshing");
    
}

export const loadState = (): LocalData | undefined => {
    //const data: any = sessionStorage.getItem(authSessionKey);
    const data: any = localStorage.getItem(authSessionKey);
    
    if (data === null) {
        return undefined;
    }
    return JSON.parse(data);
};

export const saveEvent = (event: any): void => {
    sessionStorage.setItem(eventKey, JSON.stringify(event));
}

export const loadEvent = (): any | undefined => {
    const data: any = sessionStorage.getItem(eventKey);
    if (data === null) {
        return undefined;
    }
    
    return JSON.parse(data);
};