import React, { useEffect, useState } from 'react';
import './EqToggle.scss';
import {
    Button,
    Radio
} from 'antd';
//test
export interface IEqToggle {
    items: Array<IEqToggleItem>;
    changeFunc: (selectedValue: string) => void;
    classNames?: string;
    defaultSelectedValue?: any;
    radioGroupClasses?: string;
    isFlex?: boolean;
    name?: string;
}

export interface IEqToggleItem {
    key: string;
    value: any;
    icon?: any;
}


const EqToggle = ({ items, changeFunc, classNames, defaultSelectedValue, radioGroupClasses, isFlex, name }: IEqToggle) => {

    const [selectedValue, setSelectedValue] = useState(defaultSelectedValue?.key ?? defaultSelectedValue);

    //console.log(defaultSelectedValue);

    React.useEffect(() => {
        setSelectedValue(defaultSelectedValue?.key?.toString() ?? defaultSelectedValue?.toString());
    }, [defaultSelectedValue]);



    const onChange = (e: any) => {
        setSelectedValue(e.target.value);
        //console.log(e.target.value);
        changeFunc(e.target.value);
    }



    return (
        <div className={`eq-toggle ${classNames && classNames.length ? classNames : ""}`} >
            <Radio.Group name={name && name.length ? name : ""} onChange={onChange}
                value={selectedValue}
                className={`${radioGroupClasses && radioGroupClasses.length ? radioGroupClasses : ""} ${isFlex ? " radio-group-flex" : ""}`}>
                {items.map((item: IEqToggleItem) => (
                    <Radio.Button key={item.key} value={item.key.toString()} className={`${isFlex ? " radio-button-flex" : ""}`}>{item.icon}{item.value}</Radio.Button>
                ))}
            </Radio.Group>
        </div>
    );
}

export default EqToggle;
