import * as React from "react";
import "./EqTrainerAllTrainings.scss";
import { Row, Col, Card, Popconfirm } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsTrainer,
  ValidateIsUser,
  FrontEndPoints,
  GenericMessage,
  openNotificationWithIcon,
} from "../../shared/Common";
import Api from "../../shared/Api";
import "./EqTrainerAllTrainings.scss";
import { useTranslation, Trans } from "react-i18next";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import {
  EqDisciplineType,
  EqEventRepeatType,
  TrainingFilters,
} from "../../shared/enums";
import CreateActivity from "../createActivity/CreateActivity";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import EqActivityTrainingList from "../eqActivityTrainingList/EqActivityTrainingList";
import EqActivityCollapse from "../eqActivityCollapse/EqActivityCollapse";
import EqToggle, { IEqToggle } from "../../custom/eqToggle/EqToggle";
import { IGetTrainingDto } from "../../shared/interfaces/trainingDto/IGetTrainingDto";

export interface IFilteredTrainings {
  name: any;
  type: any;
  date: any;
}

const EqTrainerAllTrainings = (props: any) => {
  const userDetails: any = useAuthState();
  const isUserValid: boolean = ValidateIsUser(userDetails);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [toggle, setToggle] = React.useState(false);
  const [areas, setAreas] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState() as any;
  const [population, setPopulation] = React.useState([]) as any;
  const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;

  // const [showEventList, setShowEventList] = React.useState(true);
  // const [createActivityList, setCreateActivityList] = React.useState([] as Array<any>);
  const [trainingsList, setTrainingsList] = React.useState([]) as any;
  const [filteredTrainingsList, setFilteredTrainingsList] = React.useState(
    {} as IFilteredTrainings
  );
  const [selectedTrainingsList, setSelectedTrainingsList] = React.useState(
    []
  ) as any;

  const [toggleFilters, setToggleFilters] = React.useState([] as any);
  const [selectedToggle, setSelectedToggle] = React.useState(
    TrainingFilters.All
  ) as any;

  const [studentHorsesList, setStudentHorsesList] = React.useState({});

  //initial gets

  const getAreas = () => {
    Api.get(ApiEndpoints.eventGetAreas).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const GetTrainerStudents = () => {
    Api.get(ApiEndpoints.GetTrainerStudents).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setTrainerStudents(response.data);
          var studentHorsesListOptions = [] as any;

          [...trainerStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.trainer.horses.length > 0) {
              [...item.trainer.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
          console.log("studentHorses:", studentHorsesListOptions);
          // console.log("TrainerStudents:", response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setPopulation(response.data);
          // console.log("population:",response.data);
          // console.table(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  React.useEffect(() => {
    getAreas();
    getWeekDays();
    GetTrainerStudents();
    getPopulation();
    getGroupPopulation();
    createToggleFilters();
    getAllTrainingsByTrainer();

    window.scrollTo(0, 0);
  }, []);

  // Toggle Filters
  const createToggleFilters = () => {
    var filters = [];
    for (var x in TrainingFilters) {
      if (isNaN(Number(x)) == false) {
        filters.push({ key: Number(x), value: TrainingFilters[x] });
      }
    }
    setToggleFilters(filters);
  };

  const accessTogglerProps: IEqToggle = {
    items: toggleFilters,
    defaultSelectedValue: toggleFilters[0],
    classNames: "eq-toggle-orange-border eq-toggle-create-event",
    changeFunc: (value: string) => {
      console.log(filteredTrainingsList);
      let filter: number = +value;
      setSelectedToggle(filter);
      if (filter === TrainingFilters.All) {
        setSelectedTrainingsList(trainingsList);
      } else if (filter === TrainingFilters.Name) {
        setSelectedTrainingsList(filteredTrainingsList.name);
      } else if (filter === TrainingFilters.Type) {
        setSelectedTrainingsList(filteredTrainingsList.type);
      } else if (filter === TrainingFilters.Date) {
        setSelectedTrainingsList(filteredTrainingsList.date);
      }
    },
  };

  /////

  const handleOnUpdateTraining = (newData: any, oldData: any) => {
    if (trainingsList != undefined) {
      const trainings = [...trainingsList];
      var foundIndex = trainings.findIndex((x) => x.key == oldData.key);
      trainings[foundIndex] = newData;
      setTrainingsList(trainings);
      formatListData(trainings);
    }
  };

  const trainerEventDefaults = (e: any) => {
    getAllTrainingsByTrainer();
    // console.log(createActivityList);

    // if (e > -1) {
    //   // only splice array when item is found
    //   const dataArr=[...createActivityList]

    //   // dataArr.splice(e, 1); // 2nd parameter means remove one item only
    //   setCreateActivityList([...dataArr])
    // }
    // setCreateActivityShow(false);
  };
  const editEvent = (data: IEqEventData) => {
    navigate(FrontEndPoints.trainer.createEvent, { state: data });

  };


  const removeTraining = (trainingData: IGetTrainingDto, refund?: boolean) => {
    let path =
      ApiEndpoints.TrainerTraining.Delete +
      `?key=${trainingData.key}&refund=${refund}`;
    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(trainingData);
        } else {
          setIsError(true);
          setErrorMessage(GenericMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GenericMessage(error.response.status));
        openNotificationWithIcon(
          GenericMessage(error.response.status),
          "Error occurred while Deleting. Please try again or contact administrator."
        );
      }
    );
  };
  // const removeEvent = (data: IEqEventData) => {
  //   Api.post(ApiEndpoints.deleteEvent, { Key: data.key }).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         removeItem(data);
  //         // getAllTrainingsByTrainer();
  //       } else {
  //         setIsError(true);
  //         setErrorMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setErrorMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };

  const getAllTrainingsByTrainer = () => {
    setErrorMessage("");
    // setShowEventList(true);

    let path =
      // ApiEndpoints.GetTrainerPrivateTrainings +
      // ApiEndpoints.GetAllTrainingsByTrainer +
      ApiEndpoints.GetAllTrainingsByTrainerWithLessons +
      `?ExcludeDisciple=${EqDisciplineType.Open}`;

    Api.get(path).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          setTrainingsList(data);
          setSelectedTrainingsList(data);
          // filtered Trainings
          var formedListData = {} as IFilteredTrainings;
          formedListData.name = [...data].sort((a: any, b: any) =>
            a.title.toLowerCase() > b.title.toLowerCase()
              ? 1
              : a.title.toLowerCase() < b.title.toLowerCase()
              ? -1
              : 0
          );

          formedListData.type = [...data].sort(
            (a: any, b: any) => a.disciplineType - b.disciplineType
          );
          formedListData.date = [...data].sort(
            (a: any, b: any) => b.startTime - a.startTime
          );
          setFilteredTrainingsList(formedListData);
          // console.log(data);
          // console.log(formedListData);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const formatListData = (data: any) => {
    var formedListData = {} as IFilteredTrainings;
    formedListData.name = [...data].sort((a: any, b: any) =>
      a.title.toLowerCase() > b.title.toLowerCase()
        ? 1
        : a.title.toLowerCase() < b.title.toLowerCase()
        ? -1
        : 0
    );

    formedListData.type = [...data].sort(
      (a: any, b: any) => a.disciplineType - b.disciplineType
    );
    formedListData.date = [...data].sort(
      (a: any, b: any) => b.startTime - a.startTime
    );
    setFilteredTrainingsList(formedListData);

    if (selectedToggle === TrainingFilters.All) {
      setSelectedTrainingsList([...data]);
    } else if (selectedToggle === TrainingFilters.Name) {
      setSelectedTrainingsList(formedListData.name);
    } else if (selectedToggle === TrainingFilters.Type) {
      setSelectedTrainingsList(formedListData.type);
    } else if (selectedToggle === TrainingFilters.Date) {
      setSelectedTrainingsList(formedListData.date);
    }
  };

  const removeItem = (data: any) => {
    if (trainingsList != undefined) {
      var updatedList =
        trainingsList != undefined
          ? trainingsList.filter((item: any) => item.key !== data.key)
          : new Array<any>();
      setTrainingsList(updatedList);
      formatListData(updatedList);
      trainingsList.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  return (
    <div className={`eq-all-trainings-board`}>
      <div className="eq-container-fluid">
        <Row className="eq-event-heading-row" align="middle" justify="start">
          <Col className="eq-event-heading-col" flex="auto">
            <h3 className="eq-event-heading">{t("allTrainings")}</h3>
          </Col>
        </Row>

        <Row
        // gutter={gutter}
        >
          <Col className="gutter-row" span={24}>
            <div className="event-type">
              <div className="label">Select Filters</div>
              <div className="toggler">
                <EqToggle {...accessTogglerProps} />
              </div>
            </div>
          </Col>
        </Row>

        {selectedTrainingsList?.length > 0 && (
          <>
            {selectedTrainingsList.map((item: any, index: number) => (
              <EqActivityCollapse
                {...{
                  event: { ...item },
                  areas: areas,
                  weekDays: weekDays,
                  population: population,
                  groupPopulation: groupPopulation,
                  trainerStudents: trainerStudents,
                  studentHorsesList: studentHorsesList,

                  removeEventFunc: removeTraining,
                  editEventFunc: editEvent,
                  onCreateOrUpdateActivity: handleOnUpdateTraining,
                }}
                key={index}
              ></EqActivityCollapse>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
export default EqTrainerAllTrainings;
function setErrorMessage(arg0: string) {
  throw new Error("Function not implemented.");
}

