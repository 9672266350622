import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./EqViewEvent.scss";
import { useTranslation } from "react-i18next";
import {
  ApiEndpoints,
  FrontEndPoints,
  getEmailFromToken,
  GetEventMessage,
  ValidateIsUser,
  getDefaultImage,
  imageType,
  checkDuePayment,
  ValidateIsStudent,
  ValidateIsTrainer,
} from "../../shared/Common";
import { IEqToggle } from "../../custom/eqToggle/EqToggle";
import { IStudentGroupDto } from "../../shared/interfaces/IStudentGroupDto";
import Api from "../../shared/Api";
import moment from "moment";
import { Row, Col, Radio, Input, List } from "antd";
import {
  EqDisciplineType,
  EqEventAccessType,
  EqSlotStatusEnum,
  EqEventUserType,
  EqLocationType,
  EqCurrency,
} from "../../shared/enums";
import EqButton from "../../custom/eqButton/EqButton";
import { IUserProfileDto } from "../../shared/interfaces/IUserProfileDto";
import { IEqProfessionalsList } from "../../eqTrainer/eqProfessionalsList/EqProfessionalsList";
import { ISubscribeEventDto } from "../../shared/interfaces/ISubscribeEventDto";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useAuthState } from "../../context/Context";
import { notification } from "antd";
import { saveEvent } from "../../shared/LocalStorage";
import EventClass from "./EventClass/EventClass";
import EqJoinEvent from "../eqEventJoin/EqJoinEvent";
import Title from "antd/lib/skeleton/Title";
import { Link } from "react-router-dom";

export interface IEqEventDetail {
  event: IEqEventData;
}

const EqViewEvent = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const userDetails: any = useAuthState();
  const isLoggedInUser: boolean = ValidateIsUser(userDetails);
  const isStudent: boolean = ValidateIsStudent(userDetails);
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const currentUserKey = getEmailFromToken();

  const [previewVisible, setPreviewVisible] = React.useState(false);

  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { state } = useLocation();
  const { t, i18n } = useTranslation();

  const [event, setEvent] = React.useState() as any;
  const [studentJoinedEventClasses, setStudentJoinedEventClasses] =
    React.useState() as any;
  const [joinedEventClasses, setJoinedEventClasses] = React.useState() as any;
  // const [event, setEvent] = React.useState(
  //   {} as IEqEventData
  // );
  const [displayEvent, setDisplayEvent] = React.useState() as any;
  const [eventAttendees, setEventAttendees] = React.useState<
    Array<IStudentGroupDto>
  >([]);
  const [organizers, setOrganizers] = React.useState(
    location?.state?.event?.organizer as IUserProfileDto[]
  );
  // const [organizers, setOrganizers] = React.useState(
  //   {} as IUserProfileDto[]
  // );
  const [slotRange, setSlotRange] = React.useState({
    allSlotRanges: [],
    selectedSlotRange: [],
    currentUserSlots: [],
    currentSelection: {},
    isSlotSelected: false,
  } as any);
  const [subscriptionStatus, setSubscriptionStatus] = React.useState({
    buttonDisabled: false,
    buttonTextSubscribe: "Join",
    buttonTextSubscribeAll: "Join all",
  } as any);
  const [isEventSubscribed, setIsEventSubscribed] = React.useState(false);
  const [isPaidByUser, setIsPaidByUser] = React.useState(false);
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };
  const [formData, setFormData] = React.useState({
    title: "",
    startOn: new Date(),
    endOn: new Date(),
    repeats: 1,
    isRepeat: false,
    timeFrom: new Date(),
    duration: 1,
    eventType: 1,
    trainingLevel: 1,
    locationKey: 1,
    maxSpaceCount: 1,
    isPaid: false,
    eventPrice: 0,
    paymentMethod: 1,
    isLatePayment: false,
    paymentDeadline: new Date(),
    message: "event",
    accessType: EqEventAccessType.Private,
    horseAvailabilityStatus: 0,
  }) as any;

  const openNotificationWithIcon = (msg: any, desc: any, event: any) => {
    const key = `open${Date.now()}`;
    const btn = (
      <EqButton type="button" onClick={() => viewEvent(event)}>
        Pay Now
      </EqButton>
    );
    notification["error"]({
      message: msg,
      btn,
      key,
      description: desc,
    });
  };
  const { TextArea } = Input;
  const gutter = { xs: 8, sm: 16, md: 24, lg: 25 };

  const filterUniquePrices = (event: any) => {
    const uniquePrices = new Set();

    event?.eventClasses?.forEach((item: any) => {
      item.classTimes?.forEach((classTime: any) => {
        const price = classTime?.price;
        if (price != null && !uniquePrices.has(price)) {
          uniquePrices.add(price);
        }
      });
    });

    return Array.from(uniquePrices);
  };

  function renderUniqueEventPrices(event: any, EqCurrency: any) {
    const uniquePrices = filterUniquePrices(event);

    return (
      <Row>
        {event?.price && <span className="label">{t("eventPrice")} : </span>}
        {uniquePrices.map((price: any) => (
          <span key={price}>
            {price}
            {event?.currency != null
              ? " " + EqCurrency[event.currency] + ","
              : ""}
          </span>
        ))}
      </Row>
    );
  }

  const getOrganizers = () => {
    var data = [] as any;
    if (
      event != undefined &&
      event.organizers != undefined &&
      event.organizers.length > 0
    ) {
      data = event.organizers.map((item: IUserProfileDto) => {
        var itmLoc = item?.locations
          ?.filter((opt: any) => opt?.location?.type == EqLocationType.Home)
          ?.shift()?.location;
        var organizersProfile: any = {
          userProfile: {
            key: item.key,
            email: item.email,
            about: item.aboutMe,
            name: item.firstName
              ? item.firstName
              : "" + " " + item.lastName
              ? item.lastName
              : "",
            // homeAddress: item.locations?.filter(
            //     (opt: any) => (
            //         opt.location?.type == EqLocationType.Home)),
            //         // ).shift(1).location,
            homeAddress: itmLoc,
            disciplineList: item.disciplines,
            img: <img src="images/partners/partner.png" alt="trainer" />,
            display: false,
            userImage: item.userImage,
            trainingLevels: item.trainingLevels,
            serviceAreaList: item.locations?.filter(
              (opt: any) => opt.location?.type == EqLocationType.Work
            ),
          },
        };

        return organizersProfile;
      });
    }

    return data;
  };

  const organizersProps: IEqProfessionalsList = {
    professionalsList: getOrganizers(),
  };

  const viewEvent = (event: IEqEventData) => {
    saveEvent(event);
    const key = `open${Date.now()}`;
    notification.close(key);
    navigate(`${FrontEndPoints.student.viewEvent}?key=${event.key}`, {
      state: { event: event },
    });
  };
  const subscribe = () => {
    if (isLoggedInUser == false) {
      navigate(FrontEndPoints.login);
    } else {
      let selected = slotRange.currentSelection;

      let data: ISubscribeEventDto = {
        ownerKey: organizers != undefined ? organizers[0].email : "",
        subscriberKey: currentUserKey,
        eventKey: event.key,
        slotStartTime: selected?.key,
        slotStatus: EqSlotStatusEnum.Booked,
        eventUserType: EqEventUserType.Attendee,
      };

      checkDuePayment(openNotificationWithIcon).then(
        (success: any) => {
          console.log("success", success);
          if (success == true) {
          } else {
            Api.post(ApiEndpoints.eventSubscribe, data).then(
              (response) => {
                if (response && response.status == 200) {
                  //
                  console.log(response);
                  setIsError(false);
                  setMessage("");
                  setIsEventSubscribed(true);

                  if (response.data != null) {
                    let currentUserList = [
                      ...slotRange.currentUserSlots,
                      selected,
                    ];
                    let selectedList = [
                      ...slotRange.selectedSlotRange,
                      selected,
                    ];
                    let remaingAllList = slotRange.allSlotRanges.filter(
                      (x: any) => x.key != selected.key
                    );

                    setSlotRange({
                      allSlotRanges: remaingAllList,
                      selectedSlotRange: selectedList,
                      currentUserSlots: currentUserList,
                      currentSelection: {},
                      isSlotSelected: false,
                    });
                    updateSubscribeButtonState(
                      false,
                      currentUserList,
                      remaingAllList
                    );
                  } else {
                    setSubscriptionStatus({
                      ...subscriptionStatus,
                      buttonDisabled: true,
                      buttonTextSubscribe: t("Joined"),
                    });
                    setMessage("User already has booked this time slot.");
                  }
                } else if (response && response.status == 204) {
                  setIsError(false);
                  setMessage("");
                  setIsEventSubscribed(true);
                  setSubscriptionStatus({
                    ...subscriptionStatus,
                    buttonDisabled: true,
                    buttonTextSubscribe: t("Joined"),
                  });
                  setMessage("User already has booked this time slot.");
                } else {
                  setIsError(true);
                  setMessage(GetEventMessage(response.status));
                  setIsEventSubscribed(false);
                }
              },
              (error) => {
                setIsError(true);
                setMessage(GetEventMessage(error.response.status));
                setIsEventSubscribed(false);
              }
            );
          }
        },
        (err: any) => {
          console.log("error", err);
        }
      );
    }
  };

  const subscribeAll = () => {
    if (isLoggedInUser == false) {
      navigate(FrontEndPoints.login);
    } else {
      let selected = slotRange.currentSelection;

      let data: ISubscribeEventDto = {
        ownerKey: organizers != undefined ? organizers[0].email : "",
        subscriberKey: currentUserKey,
        eventKey: event.key,
        slotStartTime: selected.key,
        slotStatus: EqSlotStatusEnum.Booked,
        eventUserType: EqEventUserType.Attendee,
      };

      checkDuePayment(openNotificationWithIcon).then(
        (success: any) => {
          console.log("success", success);
          if (success == true) {
          } else {
            Api.post(ApiEndpoints.eventSubscribeAll, data).then(
              (response) => {
                if (response && response.status == 202) {
                  setIsError(false);
                  setMessage("");
                  setIsEventSubscribed(true);

                  let currentUserList = [
                    ...slotRange.currentUserSlots,
                    selected,
                  ];
                  let selectedList = [...slotRange.selectedSlotRange, selected];
                  let remaingAllList = slotRange.allSlotRanges.filter(
                    (x: any) => x.key != selected.key
                  );

                  setSlotRange({
                    allSlotRanges: remaingAllList,
                    selectedSlotRange: selectedList,
                    currentUserSlots: currentUserList,
                    currentSelection: null,
                    isSlotSelected: false,
                  });
                  updateSubscribeButtonState(
                    false,
                    currentUserList,
                    remaingAllList
                  );
                } else {
                  setIsError(true);
                  setMessage(GetEventMessage(response.status));
                  setIsEventSubscribed(false);
                }
              },
              (error) => {
                setIsError(true);
                setMessage(GetEventMessage(error.response.status));
                setIsEventSubscribed(false);
              }
            );
          }
        },
        (err: any) => {
          console.log("error", err);
        }
      );
    }
  };

  const payNow = () => {
    navigate(FrontEndPoints.trainer.eventPayment);
  };

  const updateSubscribeButtonState = (
    isSlotSelected: boolean,
    currentUserSlots: any,
    allSlots: any
  ) => {
    // console.log(currentUserSlots);
    if (event?.disciplineType != EqDisciplineType.Open) {
      if (isEventSubscribed) {
        console.log("joined");
        setSubscriptionStatus({
          ...subscriptionStatus,
          buttonDisabled: true,
          buttonTextSubscribe: t("Joined"),
        });
      } else {
        setSubscriptionStatus({
          ...subscriptionStatus,
          buttonDisabled: false,
          buttonTextSubscribe: t("Join"),
        });
      }
    } else if (
      allSlots?.length == 0 &&
      event?.disciplineType == EqDisciplineType.Open
    ) {
      setSubscriptionStatus({
        ...subscriptionStatus,
        buttonDisabled: true,
        buttonTextSubscribe: t("Join"),
      });
    } else if (currentUserKey?.length > 0) {
      if (event?.disciplineType == EqDisciplineType.Open) {
        if (isSlotSelected == false) {
          setSubscriptionStatus({
            ...subscriptionStatus,
            buttonDisabled: true,
            buttonTextSubscribe: t("Join"),
          });
        } else {
          setSubscriptionStatus({
            ...subscriptionStatus,
            buttonDisabled: false,
            buttonTextSubscribe: t("Join"),
          });
        }
      } else {
        if (currentUserSlots?.length > 0) {
          setSubscriptionStatus({
            ...subscriptionStatus,
            buttonDisabled: true,
            buttonTextSubscribe: t("Joined"),
          });
        } else {
          setSubscriptionStatus({
            ...subscriptionStatus,
            buttonDisabled: false,
            buttonTextSubscribe: t("Join"),
          });
        }
      }
    } else {
      setSubscriptionStatus({
        ...subscriptionStatus,
        buttonDisabled: true,
        buttonTextSubscribe: t("Joined"),
      });
    }
  };

  const setAttendees = (users: any) => {
    console.log(users);
    let attendees = users?.filter((u: any) => {
      return u.eventUserType == EqEventUserType.Attendee;
    });

    var memberData = [] as Array<IStudentGroupDto>;
    attendees?.map((item: IStudentGroupDto) => {
      memberData.push({ ...item });
    });
    setEventAttendees(memberData);
  };

  const setTimeSlots = (event: any) => {
    var allSlots = [] as any;
    let currentUserRange = [] as any;
    let selectedRange = [] as any;

    event?.attendees?.map((attendee: any) => {
      if (attendee.slotStartTime > 0) {
        let slotStartTime = moment(attendee.slotStartTime);
        let slotEndTime = moment(attendee.slotStartTime).hours(
          moment(attendee.slotStartTime).hours() + 1
        );

        let slot = {
          key: slotStartTime.valueOf(),
          value:
            slotStartTime.format("HH:mm") + "-" + slotEndTime.format("HH:mm"),
        };

        selectedRange.push(slot);

        if (attendee.user?.email == currentUserKey) {
          currentUserRange.push(slot);
        }
      }
    });

    if (event?.disciplineType == EqDisciplineType.Open) {
      let slotStartTime = moment(event.startTime);
      let slotEndTime = moment(event.startTime).hours(
        moment(event.startTime).hours() + 1
      );

      let totalHours = Math.floor(
        (event.endTime - event.startTime) / (3600 * 1000)
      );

      let currentTags = currentUserRange;

      for (var hour = 0; hour < totalHours; hour++) {
        let slot = {
          key: slotStartTime.valueOf(),
          value:
            slotStartTime.format("HH:mm") + "-" + slotEndTime.format("HH:mm"),
        };

        if (!selectedRange?.some((x: any) => x.key == slot.key)) {
          allSlots.push(slot);
        }

        slotStartTime = slotStartTime.hours(slotStartTime.hours() + 1);
        slotEndTime = slotEndTime.hours(slotStartTime.hours() + 1);
      }
    }

    setSlotRange({
      ...slotRange,
      allSlotRanges: allSlots,
      selectedSlotRange: selectedRange,
      currentUserSlots: currentUserRange,
    });
    updateSubscribeButtonState(false, currentUserRange, allSlots);
  };

  React.useEffect(() => {
    // console.log(location);
    // console.log(state);
    window.scrollTo(0, 0);
    const params = new URLSearchParams(location.search);
    var key = params.get("key");
    var studentEmail = params.get("studentEmail");

    if (location.state) {
      if (location.state.event) {
        setEvent(location.state.event);
        setupEvent(location.state.event);
      }
      if (location.state.event.organizer) {
        setOrganizers(location.state.event.organizer);
        console.log("organizer", organizers);
      }
      console.log("organizer", location?.state?.event?.organizers.firstName);
    } else if (key) {
      GetEventWithClassesByKey(key);
    }

    if (isLoggedInUser && isStudent) {
      GetStudentJoinedClassesByEventId(key, currentUserKey);
    } else if (isLoggedInUser && isTrainer) {
      GetAllJoinedEventClassesByEventKey(key);
    } else if (key && studentEmail) {
      GetStudentJoinedClassesByEventId(key, studentEmail);
    }

    const name = location.state?.event?.organizers.map(
      (item: IUserProfileDto, index: number) => item.firstName
    );

    console.log("name", name);

    // }, []);
  }, [location.state]);

  const GetEventWithClassesByKey = async (key: any) => {
    // let path = ApiEndpoints.GetAllPublicEvents;
    let path = ApiEndpoints.GetEventWithClassesByKey;

    Api.get(path, {
      params: {
        evtKey: key, // Replace 123 with the actual event key
      },
    }).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? response.data : [];
          setEvent(data);
          setupEvent(data);
        } else {
          setIsError(true);
          // setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        // setErrorMessage(GetEventMessage(error.response.status));
        // setErrorMessage(error.message);
      }
    );
  };

  const GetStudentJoinedClassesByEventId = async (
    key: any,
    studentEmail: any
  ) => {
    // let path = ApiEndpoints.GetAllPublicEvents;
    let path = ApiEndpoints.GetStudentJoinedClassesByEventId;

    Api.get(path, {
      params: {
        evtKey: key,
        studentEmail: studentEmail, // Replace 123 with the actual event key
      },
    }).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? response.data : [];

          setStudentJoinedEventClasses(data);
        } else {
          setIsError(true);
          // setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        // setErrorMessage(GetEventMessage(error.response.status));
        // setErrorMessage(error.message);
      }
    );
  };

  const GetAllJoinedEventClassesByEventKey = async (key: any) => {
    // let path = ApiEndpoints.GetAllPublicEvents;
    let path = ApiEndpoints.GetAllJoinedEventClassesByEventKey;

    Api.get(path, {
      params: {
        evtKey: key, // Replace 123 with the actual event key
      },
    }).then(
      (response) => {
        if (response && response.status == 200) {
          var joinedClasses = [] as any;
          var data = response.data ? response.data : [];
          // setJoinedEventClasses(data);
          //

          if (data.length > 0) {
            var grouped = data.reduce((acc: any, obj: any) => {
              const key = obj.event.startDate;
              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(obj);
              return acc;
            }, {});

            console.log(grouped);

            joinedClasses = Object.keys(grouped).map((key) => ({
              startDate: grouped[key][0].event.startDate,
              classes: grouped[key],
            }));
            setJoinedEventClasses(joinedClasses);
          }
        } else {
          setIsError(true);
          // setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        // setErrorMessage(GetEventMessage(error.response.status));
        // setErrorMessage(error.message);
      }
    );
  };
  const setupEvent = (evt: any) => {
    // console.log(evt, evt?.attendees?.length, evt.maxAvailableSpace);
    var newEvent = evt as any;
    let sortedClasses = evt?.eventClasses?.forEach((eventClass: any) => {
      eventClass.classTimes.sort((a: any, b: any) => a.key - b.key);
    });
    newEvent.classes = sortedClasses;
    setEvent(newEvent);
    formatEvtToDisplay(evt);
    setAttendees(evt?.attendees);
    setTimeSlots({ ...evt });
    if (evt && evt.organizers) {
      setOrganizers(evt.organizers);
    }
    if (evt.accessType == 2) {
      if (evt?.attendees?.some((item: any) => item.key == currentUserKey)) {
        if (
          evt?.attendees?.some(
            (item: any) => item.key == currentUserKey && item.userStatus != 1
          )
        ) {
          setIsEventSubscribed(false);
          setSubscriptionStatus({
            ...subscriptionStatus,
            buttonDisabled: false,
            buttonTextSubscribe: t("Join"),
          });
        } else {
          setIsEventSubscribed(true);
          setSubscriptionStatus({
            ...subscriptionStatus,
            buttonDisabled: true,
            buttonTextSubscribe: t("Joined"),
          });

          if (
            evt?.payments?.some(
              (item: any) => item.user?.email == currentUserKey
            )
          ) {
            setIsPaidByUser(true);
          }
        }
      } else if (
        evt?.maxAvailableSpace &&
        evt?.maxAvailableSpace > 0 &&
        evt?.attendees &&
        evt?.attendees?.length > 0 &&
        evt?.attendees?.length > evt.maxAvailableSpace
      ) {
        setSubscriptionStatus({
          ...subscriptionStatus,
          buttonDisabled: true,
          buttonTextSubscribe: t("Join"),
        });
      }
    }
  };

  const togglerProps: IEqToggle = {
    items: slotRange.allSlotRanges,
    defaultSelectedValue: slotRange.currentSelection,
    classNames: "eq-toggle-orange-border eq-toggle-create-event",
    changeFunc: (value: any) => {
      console.log(value);
      let selected = slotRange?.allSlotRanges?.filter(
        (x: any) => x.key == value
      );
      setSlotRange({
        ...slotRange,
        currentSelection: selected?.length > 0 ? selected[0] : {},
        isSlotSelected: true,
      });
      updateSubscribeButtonState(
        true,
        slotRange.currentUserSlots,
        slotRange.allSlotRanges
      );
    },
  };

  // To Display Event main
  const formatEvtToDisplay = (evt: any) => {
    console.log(evt);

    // Class Prices
    // Get unique Prices
    const uniquePrices = [] as any;
    evt?.eventClasses?.forEach((evtClass: any) => {
      evtClass?.classTimes?.forEach((classTime: any) => {
        const price = classTime?.price;
        if (price != undefined && !uniquePrices.includes(price)) {
          uniquePrices.push(price);
        }
      });
    });

    if (evt && evt.eventClasses) {
    }
    let evtCurrency =
      evt?.currency != null ? " " + EqCurrency[evt.currency] + "," : "";

    var displayEvt = {
      title: evt.title,
      eqImage: evt.eqImage,
      price: evt.price,
      prices: uniquePrices,
      currency: evtCurrency,
      phoneNumber: evt.phoneNumber,
      email: evt.email,
      description: evt.description,
      day: startDay(evt.startDate),
      month: startMonth(evt.startDate),
      formattedDate: formatDateTime(evt.startDate, evt.endDate, evt.duration),
      location: evt.location,
      attendanceCount: evt?.attendees?.length || 0,
      evQuestion: evt.eventQuestions,
      organizers: evt.organizers,
    };
    setDisplayEvent(displayEvt);
  };

  const createMapSource = (): string => {
    var adr3 =
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2000.6386579699074!2d10.68221881616009!3d59.904947471777994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416c38363f15fd%3A0x43a6abff981ee007!2sViking%20Ship%20Museum!5e0!3m2!1sen!2sno!4v1650535821646!5m2!1sen!2sno";
    var adr1 = 'https://maps.google.com/maps?&amp;q="';
    var adr2 = adr1 + encodeURIComponent("Oslo");
    var adr = adr2 + '"&amp;output=embed';
    return adr;
  };

  const createStartDate = (miliseconds: number): string => {
    return new Date(miliseconds).getDay().toString();
  };

  const startDay = (miliseconds: number): string => {
    var sDate = new Date(miliseconds);
    return sDate.getDate().toString().padStart(2, "0");
    //return sDate.getDate().toString();
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

  const tomorrow = new Date();
  tomorrow.setHours(24, 0, 0, 0);
  const tomorrowTime = tomorrow.getTime();

  const dayAfterTomorrow = new Date();
  dayAfterTomorrow.setHours(48, 0, 0, 0);
  const dayAfterTomorrowTime = dayAfterTomorrow.getTime();

  const startMonth = (miliseconds: number): string => {
    var cMonth = new Date(miliseconds);
    return cMonth.toLocaleString("en-us", { month: "short" });
    //return months[cMonth];
  };

  const formatDateTime = (
    startDate: number,
    endDate: number,
    duration: number
  ): string => {
    var selDate = new Date(startDate);
    if (startDate < tomorrowTime && startDate > todayTime) {
      return `Today AT ${selDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${selDate.getMinutes().toString().padStart(2, "0")}`;
    } else if (startDate > tomorrowTime && startDate < dayAfterTomorrowTime) {
      return `Tomorrow AT ${selDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${selDate.getMinutes().toString().padStart(2, "0")}`;
    } else if (startDate > dayAfterTomorrowTime) {
      return `${days[selDate.getDay() - 1]}, ${selDate
        .getDate()
        .toString()
        .padStart(2, "0")} ${
        months[selDate.getMonth()]
      } ${selDate.getFullYear()} ${selDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${selDate.getMinutes().toString().padStart(2, "0")}`;
    }
    return "";
  };

  const formatLocation = (): string => {
    var loc =
      event?.eventLocation != undefined
        ? event.eventLocation.street +
          event.eventLocation.zipCode +
          ", " +
          event.eventLocation.municipality
        : event?.location;

    var locUrl = "https://www.google.no/maps?q='" + loc + "'&output=embed";

    return locUrl;
  };

  const removeEventMember = (member: IStudentGroupDto) => {
    console.log(eventAttendees);
    var attendees = [...eventAttendees];
    if (attendees != null && attendees.length > 0) {
      const index = attendees.findIndex((x: any) => x.key == member.key);
      if (index > -1) {
        attendees.splice(index, 1);
      }
    }

    setEventAttendees([...attendees]);
  };

  // Single Event Public View
  return (
    <div className="eq-view-event">
      <div className="event-top">
        <div className="container">
          <Row>
            <Col flex="auto">
              <div className="important-info">
                <h2 className="title">{displayEvent?.title}</h2>
                {/* <div className="horizontal-line"></div> */}
              </div>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col className="left-col" md={16} xs={24} sm={24}>
              <div className="event-top-details">
                <Row className="event-img">
                  <img
                    alt=""
                    src={
                      displayEvent?.eqImage?.url ||
                      getDefaultImage(imageType.events)
                    }
                    className="eq-img"
                  />
                </Row>
              </div>
            </Col>
            <Col className="right-col" md={8} xs={24} sm={24}>
              <div className="section">
                <Row className="section-heading">{t("prices")}</Row>
                <Row className="section-label">
                  {renderUniqueEventPrices(event, EqCurrency)}
                </Row>
              </div>

              <div className="section">
                <Row className="section-heading">{t("date")}</Row>
                <Row className="section-label">
                  {event?.startDate && (
                    <span className="label">
                      {event && event.eventClasses ? (
                        event.eventClasses.map((item: any) => (
                          <>
                            {moment(item?.startDate).format("DD/MM/YYYY")} -{" "}
                            {t("StartTime")} :{" "}
                            {new Date(
                              item.classTimes[0].startTime
                            ).toLocaleTimeString()}
                            {item?.startDate ? <br /> : ""}
                          </>
                        ))
                      ) : event ? (
                        <>{moment(event?.startDate).format("DD/MM/YYYY")}</>
                      ) : (
                        ""
                      )}
                    </span>
                  )}
                </Row>
                {/* <Row>
                  {event?.paymentDeadline && (
                    <span className="label">
                      Payment Deadline :{" "}
                      {moment(event?.paymentDeadline).format("DD/MM/YYYY")}
                    </span>
                  )}
                </Row> */}
              </div>
              <div className="section">
                <Row className="section-heading">{t("place")}</Row>
                <Row className="section-label">
                  {event?.eventLocation &&
                    event.eventLocation.street +
                      ", " +
                      event.eventLocation.city +
                      (event.eventLocation.zipCode
                        ? " - " + event.eventLocation.zipCode
                        : "")}
                </Row>
              </div>
              <div className="section">
                <Row className="section-heading">{t("ContactforEvent")}</Row>
                <Row className="section-label">
                  {event?.phoneNumber && (
                    <span className="label">{event?.phoneNumber}</span>
                  )}
                </Row>
                {event?.email && <span className="label">{event?.email}</span>}
              </div>
              <div className="section">
                <Row className="section-heading">{t("createdBy")}</Row>
                <Row className="section-label">
                  {displayEvent?.organizers?.map(
                    (item: IUserProfileDto, index: number) => (
                      <Link
                        to={`${FrontEndPoints.trainer.profileBoard}?key=${item.key}`}
                        onClick={(e: any) => {
                          e.stopPropagation();
                        }}
                        className="trainer-link"
                      >
                        <span> {item.firstName}</span>
                      </Link>
                    )
                  )}
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="event-decs">
              {event?.description && (
                <div className="section">
                  <Row className="section-heading">{t("description")}</Row>
                  <p
                    className="section-label"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {event.description}
                  </p>
                </div>
                // <p className="label" > {event?.description} </p>
              )}
            </Col>
          </Row>

          {isStudent &&
            studentJoinedEventClasses &&
            studentJoinedEventClasses.length > 0 && (
              <div className="student-joined-event-class">
                <div className="section">
                  <Row className="heading">{t("joinedEventClasses")}</Row>
                  <Row className="tb-heading">
                    <Col xs={6} span={3}>
                      {t("date")}
                    </Col>
                    <Col xs={6} span={3}>
                      {t("StartTime")}
                    </Col>
                    {/* <Col xs={6} span={3}>
                      Horse
                    </Col> */}
                    <Col xs={6} span={3}>
                      {t("price")}
                    </Col>
                  </Row>
                  {studentJoinedEventClasses?.map(
                    (eventClass: any, i: number) => (
                      <div key={i}>
                        <Row
                          className={i % 2 === 0 ? "tr-even" : "tr-odd"}
                          key={i}
                        >
                          <Col xs={6} span={3}>
                            {new Date(
                              eventClass.event.startDate
                            ).toLocaleDateString()}
                          </Col>
                          <Col xs={6} span={3}>
                            {new Date(
                              eventClass.event.startTime
                            ).toLocaleTimeString("en-US", {
                              hour12: true,
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </Col>
                          {/* <Col xs={6} span={3}>
                            {eventClass.classHorse}
                          </Col> */}
                          <Col xs={6} span={3}>
                            {eventClass.event.price}
                          </Col>
                        </Row>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}

          {isTrainer && joinedEventClasses && joinedEventClasses.length > 0 && (
            <div className="student-joined-event-class">
              <div className="section">
                <Row className="heading">{t("startList")}</Row>

                {joinedEventClasses?.map((obj: any, i: number) => (
                  <>
                    <div key={i}>
                      <div className="heading">
                        {new Date(obj.startDate).toLocaleDateString(
                          t("dateLang"),
                          options as any
                        )}
                      </div>
                    </div>

                    <Row className="tb-heading">
                      <Col xs={12} md={12}>
                        {t("rider")}
                      </Col>
                      {/* <Col xs={12} md={4}>
                        Horse
                      </Col> */}
                      <Col xs={0} md={12}>
                        {t("questionAndAnswers")}
                      </Col>
                    </Row>
                    {obj.classes?.map((eventClass: any, j: number) => (
                      <div key={j}>
                        <Row
                          className={j % 2 === 0 ? "tr-even" : "tr-odd"}
                          key={j}
                        >
                          <Col className="table-col" xs={12} md={12}>
                            {eventClass.attendUsingName
                              ? eventClass.attendUsingName
                              : eventClass.user.email}
                          </Col>
                          {/* <Col className="table-col" xs={12} md={4}>
                            {eventClass.classHorse}
                          </Col> */}
                          <Col xs={0} md={12}>
                            <ul>
                              {eventClass.answers.map(
                                (answer: any, k: number) => (
                                  <li key={k}>
                                    {/* <strong>Question:</strong> */}
                                    <strong>
                                      {answer.question.questionText}
                                    </strong>
                                    <br />
                                    {/* <strong>Answer:</strong>  */}
                                    {answer.answer}
                                  </li>
                                )
                              )}
                            </ul>
                          </Col>
                          {eventClass.answers &&
                            eventClass.answers.length > 0 && (
                              <Col className="qa-Col" offset={2} xs={20} md={0}>
                                <>
                                  <Row className="tb-heading">
                                    <Col xs={12} md={8}>
                                      {t("label")}
                                    </Col>
                                    <Col xs={12} md={4}>
                                      Answer
                                    </Col>
                                  </Row>
                                  {eventClass.answers.map(
                                    (answer: any, k: number) => (
                                      <Row className="tr-even" key={k}>
                                        <Col
                                          className="table-col"
                                          xs={12}
                                          sm={12}
                                          md={8}
                                        >
                                          {answer.question.questionText}
                                        </Col>

                                        <Col
                                          className="table-col"
                                          xs={12}
                                          sm={12}
                                          md={5}
                                        >
                                          {answer.answer}
                                        </Col>
                                      </Row>
                                    )
                                  )}
                                </>
                              </Col>
                            )}
                        </Row>
                      </div>
                    ))}
                  </>
                ))}
              </div>
            </div>
          )}

          {/* {isTrainer && joinedEventClasses && joinedEventClasses.length > 0 && (
            <div className="student-joined-event-class">
              <div className="section">
                <Row className="heading">{t("startList")}</Row>

                {joinedEventClasses?.map((obj: any, i: number) => (
                  <div key={i}>
                    <div className="heading">
                      {new Date(obj.startDate).toLocaleDateString(
                        "en-US",
                        options as any
                      )}
                    </div>

                    <Row className="tb-heading">
                      <Col xs={12} sm={12} md={8}>
                        Rider
                      </Col>
                      <Col xs={12} sm={12} md={5}>
                        Horse / Info
                      </Col>
                    </Row>
                    {obj.classes?.map((eventClass: any, j: number) => (
                      <div key={j}>
                        <Row
                          className={j % 2 === 0 ? "tr-even" : "tr-odd"}
                          key={j}
                        >
                          <Col className="table-col" xs={12} sm={12} md={8}>
                            {eventClass.user.email}
                          </Col>
                          <Col className="table-col" xs={12} sm={12} md={5}>
                            {eventClass.classHorse}
                          </Col>
                          <Col xs={24} sm={24} md={24}>
                            <Row  className="tb-heading">  
                              <Col xs={12} sm={12} md={8}>
                                Question
                              </Col>
                              <Col xs={12} sm={12} md={5}>
                                Answer
                              </Col>
                            </Row>
                           
                              {eventClass.answers.map(
                                (answer: any, k: number) => (
                                  <Row key={k}>
                                       <Col xs={12} sm={12} md={8}>
                                    {answer.question.questionText}
                                    </Col> 
                                    
                                    <Col xs={12} sm={12} md={5}>
                                    {answer.answer}
                                    </Col> 
                                  </Row>
                                )
                              )}
                            
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )} */}

          {/* Button for Preview Classes */}
          {/* {event?.isClassPreviewAllowed && (
            <EqButton
              type="button"
              bordered="true"
              // classNames="btn-background-orange"
              onClick={() => {
                setPreviewVisible(!previewVisible);
              }}
            >
              {t("preview")}{" "}
              {event?.customClassName
                ? event?.customClassName
                : t("eventClasses")}
            </EqButton>
          )} */}

          {event && event.eventClasses && (
            <>
              {event.isClassPreviewAllowed && (
                <div className="section">
                  <Row className="section-heading">
                    {event?.customClassName
                      ? event?.customClassName
                      : t("eventClasses")}
                  </Row>
                  <Row>
                    <EventClass event={event}></EventClass>
                  </Row>
                </div>
              )}
              {!isTrainer && <EqJoinEvent event={event}></EqJoinEvent>}
            </>
          )}

          {/* </Row> */}
        </div>
      </div>
      {/* <div className="event-middle">
        <div className="container">
          <Row gutter={{ xs: 24, sm: 24, md: 16 }}>
            <EventClass event={event} ></EventClass>
          </Row>
        </div>
      </div> */}

      <div className="event-end">
        <div className="container">
          <Row>
            <Col>
              <div className="event-end-details"></div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default EqViewEvent;
