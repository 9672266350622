import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FrontEndPoints } from "../shared/Common";
import Api from "../shared/Api";
import "./ConfirmEmail.scss";
import history from "../shared/history";
import EqButton, { IEqButton } from "../custom/eqButton/EqButton";
import { useTranslation } from "react-i18next";

const ConfirmEmail = (props: any) => {
  const [isError, setIsError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [trainerKey, setTrainerKey] = useState() as any;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const buttonProps: IEqButton = {
    //changeFunc: validateUser,
    classNames: "eq-sign-btn",
  };
  const NavigateToLogin = (e: any) => {
    // e.stopPropagation();
    console.log()


    const params = new URLSearchParams(window.location.search);

    if (params != null || undefined) {
      var trainerKey = params.get("trainerKey")|| params.get("ByTrainer");
      var schoolKey = params.get("schoolKey")|| params.get("BySchool");

      console.log("trainerKey",trainerKey)
      console.log("schoolKey",schoolKey)

      if (trainerKey) {
        // navigate(`${FrontEndPoints.login}?key=${trainerKey}`);
        navigate(FrontEndPoints.login, { state: { trainerKey: trainerKey } });
      }
      else if (schoolKey) {
        navigate(FrontEndPoints.login, { state: { schoolKey: schoolKey } });
      }
      else {
        navigate(FrontEndPoints.login);
      }
    }
  };
  const confirmEmail = (): void => {
    setIsError("");

    var searchParams = new URLSearchParams(window.location.search);
    // let key=searchParams.get("trainerKey");
    // setTrainerKey(key);

    let email = searchParams.get("email");
    let emailToken = searchParams.get("emailToken");
    if (email && emailToken) {
      Api.post("account/ValidateEmail", {
        email: email,
        EmailToken: emailToken,
      }).then(
        (response) => {
          setIsError("");
          setIsSuccess(true);
        },
        (err) => {
          setIsError("emailConfirmFail");
          setIsSuccess(false);
        }
      );
    }
  };
 



  useEffect(() => {
    
    confirmEmail();
  }, []);

  return (
    <div className="confirm-email">
      {isError && <h6 className="confirm-email-error">{t(isError)}</h6>}
      {isSuccess && (
        <>
          <h6 className="confirm-email-success">{t("emailConfirmSuccess")}</h6>
          {/* <Navigate to={FrontEndPoints.login} state={ { trainerKey: trainerKey }} /> */}
          {/* <Link to={FrontEndPoints.login} state={{ trainerKey: trainerKey }}>Go to Login page</Link> */}
          {/* <a onClick={(e:any)=>NavigateToLogin} >Go to Login page</a> */}
        </>
      )}
      {/* <a onClick={NavigateToLogin} >Go to Login page</a> */}

      <EqButton
                            type="primary"
                            htmlType="submit"
                            centered="centered"
                            {...buttonProps}
                            onClick={NavigateToLogin}
                        >
                            {t("logIn")}
                        </EqButton>

    </div>
  );
};

export default ConfirmEmail;
