import * as React from "react";
import "./EqTrainerAllSchoolTrainings.scss";
import { Row, Col, Card, Popconfirm, Avatar, Skeleton } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsTrainer,
  ValidateIsUser,
  FrontEndPoints,
  ValidateIsSchool,
  getDefaultImage,
  imageType,
} from "../../shared/Common";
import Api from "../../shared/Api";
import "./EqTrainerAllSchoolTrainings.scss";
import { useTranslation, Trans } from "react-i18next";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import {
  EqDisciplineType,
  EqEventRepeatType,
  EqTrainingType,
  ProductType,
} from "../../shared/enums";

import { UserOutlined } from "@ant-design/icons";
import SchoolTrainingList from "../../eqSchool/SchoolTrainingList/SchoolTrainingList";
// import EqSchoolTrainingTrainingList from "../eqSchoolTrainingTrainingList/EqSchoolTrainingTrainingList";
// import CreateOrEditPrivateTraining from "../createOrEditPrivateTraining/CreateOrEditPrivateTraining";
export interface IEqSchoolTrainings {
  updateCount?: (count: number) => void;
}
const EqTrainerAllSchoolTrainings = (
  { updateCount }: IEqSchoolTrainings
) => {
  const userDetails: any = useAuthState();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isSuccess, setSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [toggle, setToggle] = React.useState(false);
  const [createSchoolTrainingHeader, setCreateSchoolTrainingHeader] =
    React.useState([]) as any;
  const [message, setMessage] = React.useState("");
  const [areas, setAreas] = React.useState([]) as any;
  const [trainersList, setTrainersList] = React.useState([]) as any;

  const [trainersOptions, setTrainersOptions] = React.useState([]) as any;
  const [SchoolsTrainersOptionsList, setSchoolsTrainersOptionsList] = React.useState([]) as any;
  const [productOptions, setProductOptions] = React.useState([]) as any;
  const [products, setProducts] = React.useState([]) as any;

  const [weekDays, setWeekDays] = React.useState([]) as any;
  const [eventTypes, setEventTypes] = React.useState([]) as any;

  const [population, setPopulation] = React.useState([]) as any;
  const [schoolStudents, setSchoolStudents] = React.useState([]) as any;
  const [studentHorsesList, setStudentHorsesList] = React.useState([]);

  const [trainerSchoolStudents, setTrainerSchoolStudents] = React.useState(
    []
  ) as any;
  const [schoolStudentHorsesList, setSchoolStudentHorsesList] = React.useState(
    []
  );

  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;

  const [showEventList, setShowEventList] = React.useState(true);
  const [createSchoolTrainingShow, setCreateSchoolTrainingShow] =
    React.useState(false);
  const [createSchoolTrainingList, setCreateSchoolTrainingList] =
    React.useState([] as Array<any>);
  const [trainingsList, setTrainingsList] = React.useState(
    [] as Array<IEqEventData>
  );
  const [activityTitle, setActivityTitle] = React.useState("");



  const [trainerHorsesList, setTrainerHorsesList] = React.useState({});

  const location = useLocation();

  // const GetAllTrainerHorses = () => {
  //   Api.get(ApiEndpoints.GetAllHorses).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         setIsError(false);
  //         setMessage("");
  //         var data = response.data ? [...response.data] : new Array<any>();
  //         setTrainerHorsesList(data);
  //       } else {
  //         setIsError(true);
  //         setMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };

  const GetSchoolStudents = () => {
    Api.get(ApiEndpoints.GetSchoolStudents).then(
      (response) => {
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var schoolStudents = response.data;
          setIsError(false);
          // console.log(userDetails);
          setMessage("");
          setSchoolStudents(response.data);
          // console.log(schoolHorsesList);
          [...schoolStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.school.horses.length > 0) {
              [...item.school.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
          console.log("studentHorses:", studentHorsesListOptions);
          // console.log("TrainerStudents:",response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetTrainerSchoolsStudents = () => {
    Api.get(ApiEndpoints.GetTrainerSchoolsStudents).then(
      (response) => {
        if (response && response.status == 200) {


          var trainerSchoolStudents = response.data;

          setIsError(false);
          // console.log(userDetails);
          setMessage("");
          setTrainerSchoolStudents(response.data);
          // console.log(schoolHorsesList);

          var schoolsStudentHorsesListOptions = [] as any;

          [...trainerSchoolStudents].map((trSchool: any) => {
            var studentHorsesListOptions = [] as any;

            trSchool.schoolStudentsDto.map((item: any) => {
              let horseListObj = [] as any;

              if (item.user.horses.length > 0) {
                [...item.user.horses].map((h: any) => {
                  let horseObj = {
                    key: h.id,
                    value: h.name,
                    label: h.name,
                  };
                  horseListObj.push(horseObj);
                });
              }
              if (item.school.horses.length > 0) {
                [...item.school.horses].map((h: any) => {
                  let horseObj = {
                    key: h.id,
                    value: h.name,
                    label: h.name,
                  };
                  horseListObj.push(horseObj);
                });
              }

              let studentHorsesObj = {
                key: item.key,
                horseList: horseListObj,
              };

              studentHorsesListOptions.push(studentHorsesObj);
            });

            let SchoolStudentHorseObj = {
              key: trSchool.user.email,
              studentHorsesList: studentHorsesListOptions,
            };

            schoolsStudentHorsesListOptions.push(SchoolStudentHorseObj);
          });

          setSchoolStudentHorsesList(schoolsStudentHorsesListOptions);
          console.log("SchoolStudentHorses:", schoolsStudentHorsesListOptions);
          // console.log("TrainerStudents:",response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setPopulation(response.data);
          // console.log("population:",response.data);
          // console.table(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getAreas = () => {
    Api.get(ApiEndpoints.eventGetAreas).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetSchoolTrainers = async () => {
    try {
      const response = await Api.get(ApiEndpoints.SchoolGetTrainers);
  
      if (response && response.status === 200) {
        const trainers = response.data;
        setTrainersList(trainers);
      
  
        const trainerObjList = [
          {
            key: userDetails.key,
            value: userDetails.name,
            label: userDetails.name,
            icon: (
              <Avatar
                size={30}
                src={
                  userDetails.userImage
                    ? userDetails.userImage.url
                    : getDefaultImage(imageType.trainer)
                }
              />
            ),
          },
          ...trainers.map((item:any) => ({
            key: item.key,
            value: item.firstName + item.lastName,
            label: item.firstName,
            icon: (
              <Avatar
                size={30}
                src={
                  item.userImage
                    ? item.userImage.url
                    : getDefaultImage(imageType.trainer)
                }
              />
            ),
          })),
        ];
  
        setTrainersOptions(trainerObjList);
      }
    } catch (error) {
      throw error;
    }
  };


  const GetTrainerSchoolsTrainers = () => {
    Api.get(ApiEndpoints.GetTrainerSchoolTrainers).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");

          var schoolTrainers = response.data;
          // setTrainersList(trainers);

          var trainerSchoolsTrainerList = [] as any;

          schoolTrainers.map((schTrain: any) => {

            let trainerObjList = [] as any;
            schTrain.trainers.map((item: any) => {
              let trainerObj = {
                key: item.key,
                value: item.firstName + item.lastName,
                label: item.firstName,
                icon: (
                  <Avatar
                    size={30}
                    src={
                      item.userImage
                        ? item.userImage.url
                        : getDefaultImage(imageType.trainer)
                    }
                  />
                ),
              };
              trainerObjList.push(trainerObj);
            });
            let SchoolTrainerObj = {
              key: schTrain.schoolEmail,
              trainersOptions: trainerObjList,
            };

            trainerSchoolsTrainerList.push(SchoolTrainerObj);

          });
          setSchoolsTrainersOptionsList(trainerSchoolsTrainerList)

        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const GetProductList = () => {
    Api.get(ApiEndpoints.UserProduct).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          let data = response.data;
          var products = [] as any;
          if (data) {
            var publicList = data?.filter(
              (opt: any) => opt.type == ProductType.Public
            );

            publicList.map((item: any, Idx: any) => {
              let obj = {
                key: item.id,
                value: item.title,
                label: item.title,
              };
              products.push(obj);
            });
            setProductOptions(products);
            setProducts(data);
          }
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const createEventTypes = () => {
    var eventTypes = [];

    eventTypes.push({
      key: EqDisciplineType.Dressage,
      value: EqTrainingType[EqDisciplineType.Dressage],
    });
    eventTypes.push({
      key: EqDisciplineType.Jumping,
      value: EqTrainingType[EqDisciplineType.Jumping],
    });
    eventTypes.push({
      key: EqDisciplineType.Open,
      value: EqTrainingType[EqDisciplineType.Open],
    });
    eventTypes.push({
      key: EqDisciplineType.RidingCourse,
      value: EqDisciplineType[EqDisciplineType.RidingCourse],
    });

    setEventTypes(eventTypes);
  };

  React.useEffect(() => {
    console.log("Trainer Trainings", location);
    if (isTrainer) {
      GetTrainerSchoolsStudents();
      GetTrainerSchoolsTrainers();
    }
    GetSchoolTrainers();
    GetSchoolStudents();
    createEventTypes();
    getWeekDays();
    // GetAllProducts();
    GetProductList();
    getAreas();
    getPopulation();
    getGroupPopulation();
    // GetAllTrainerHorses();
    // eventGetTrainerStudents();
    GetAllTrainerSchoolTrainingsWithLessons();

    window.scrollTo(0, 0);
    if (location.state) {
      if (
        location.state.prevPath == FrontEndPoints.trainer.dashboard ||
        location.state.prevPath == FrontEndPoints.trainer.groupsBoard
      ) {
        setCreateSchoolTrainingShow(true);
        // setTimeout(() => {
        //   onHandleAddSchoolTraining();
        // }, 10000);
      }
    }
  }, []);
  const handleChangeActivityTitle = (e: any) => {
    setActivityTitle(e);
  };
  const handleOnCreateTraining = (data: any) => {
    setCreateSchoolTrainingList([]);
    console.log(createSchoolTrainingList);
    const trainings = [...trainingsList];
    data.forEach((e: any) => {
      trainings.push(e);
    });
    setTrainingsList(trainings);
  };

  // const trainerEventDefaults = (e: any, i: any) => {
  //   setCreateSchoolTrainingList([]);
  //   GetTrainerPrivateTrainings();
  //   console.log(createSchoolTrainingList);
  // };

  const handleOnUpdateTraining = (newData: any, oldData: any) => {
    if (trainingsList != undefined) {
      const trainings = [...trainingsList];
      var foundIndex = trainings.findIndex((x) => x.key == oldData.key);
      trainings[foundIndex] = newData;
      setTrainingsList(trainings);
      // setCreateSchoolTrainingList([]);
      // console.log(createSchoolTrainingList);
      // const trainings = [...trainingsList];
      // newData.forEach((e: any) => {
      //   trainings.push(e);
      // });
      // setTrainingsList(trainings);
      // onHandleAddSchoolTraining();
    }
  };

  const editTraining = (data: IEqEventData) => {
    navigate(FrontEndPoints.trainer.createEvent, { state: data });
    // history.push({ pathname: FrontEndPoints.trainer.createEvent, });
    // history.push(FrontEndPoints.trainer.createEvent, { event: data });
  };
  const toggleEventList = () => {
    setShowEventList(!showEventList);
  };

  // trainer cant Delete school training
  // remove that
  const removeTraining = (data: IEqEventData) => {
    Api.delete(ApiEndpoints.SchoolTraining.Delete + `/${data.key}`).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );

    if (updateCount) {
      updateCount(trainersList.length); // Replace 'newCount' with the actual count
    }
  };

  const GetAllTrainerSchoolTrainingsWithLessons = async () => {
    setErrorMessage("");
    let path =
      ApiEndpoints.SchoolTraining.GetTrainerSchoolTrainingsOfMonths;
    Api.get(path).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          setTrainingsList(data);
          // console.log("GetTrainerPrivateTrainings");
          // const today = new Date();
          // today.setHours(0, 0, 0, 0);
          // const todayTime = today.getTime();
          // const eventsFromToday = data.filter((event) => event.startDate >= todayTime);
          // const eventsCountFromToday = eventsFromToday.length;
          // onloadSendCount(eventsCountFromToday);
          // console.log(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const removeItem = (data: any) => {
    if (trainingsList != undefined) {
      var updatedList =
        trainingsList != undefined
          ? trainingsList.filter((item) => item.key !== data.key)
          : new Array<any>();
      setTrainingsList(updatedList);

      trainingsList.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
    if (updateCount) {
      updateCount(trainersList.length); // Replace 'newCount' with the actual count
    }
  };

  const handleCancelSchoolTraining = (e: any) => {
    console.log(createSchoolTrainingList);

    if (e > -1) {
      // only splice array when item is found
      const dataArr = [...createSchoolTrainingList];

      // dataArr.splice(e, 1); // 2nd parameter means remove one item only
      setCreateSchoolTrainingList([...dataArr]);
    }
    // setCreateSchoolTrainingShow(false);
  };

  return (
    <div className={`eq-school-training`}>
      <Row>
        {[...Array(5)].map((_, index) => (
          <Skeleton key={index} loading={!trainingsList} paragraph={{ rows: 2 }} active />
        ))}
      </Row>
      <div className="eq-container-fluid">
        <SchoolTrainingList
          trainingList={[...trainingsList]}
          trainersOptions={trainersOptions}
          eventTypes={eventTypes}
          productOptions={productOptions}
          products={products}
          areas={areas}
          weekDays={weekDays}
          population={population}
          groupPopulation={groupPopulation}
          schoolStudents={schoolStudents}
          studentHorsesList={studentHorsesList}
          schoolStudentHorsesList={schoolStudentHorsesList}
          // for trainer 
          trainerSchoolStudents={trainerSchoolStudents}
          SchoolsTrainersOptionsList={SchoolsTrainersOptionsList}
          removeEventFunc={removeTraining}
          editEventFunc={editTraining}
          onCreateOrUpdateSchoolTraining={handleOnUpdateTraining}
        ></SchoolTrainingList>
      </div>
    </div>
  );
};
export default EqTrainerAllSchoolTrainings;
