import * as React from 'react';
import EqEventList from '../../eqTrainer/eqEventList/EqEventList';
import EqEventGrid from '../../eqTrainer/eqEventGrid/EqEventGrid';
import { Row, Col } from 'antd';
import EqButton from '../../custom/eqButton/EqButton';
import { addDays, ApiEndpoints, FrontEndPoints, GetEventMessage } from '../../shared/Common';
import './EqTrainingsBoard.scss';
import { useTranslation, Trans } from 'react-i18next';
import history from '../../shared/history';
import Api from '../../shared/Api';
import EqTrainingFilter from '../../eqTrainer/eqTrainingFilter/EqTrainingFilter';
import EqActivityTrainingList from '../../eqTrainer/eqActivityTrainingList/EqActivityTrainingList';


const EqTrainingsBoard = () => {
    const { t, i18n } = useTranslation();
    const [isError, setIsError] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [eventsList, setEventsList] = React.useState({ allEvents: [], filteredEvents: [] }) as any;
    const [eventList, setEventList] = React.useState(true);
    const [areas, setAreas] = React.useState([]) as any;

    const getAreas = () => {
        
        Api.get(ApiEndpoints.eventGetAreas).then(
          (response) => {
            if (response && response.status == 200) {
              setIsError(false);
              setMessage("");
              setAreas(response.data);
            } else {
              setIsError(true);
              setMessage(GetEventMessage(response.status));
            }
          },
          (error) => {
            setIsError(true);
            setMessage(GetEventMessage(error.response.status));
          }
        );
      };
    const GetAllPrivateEvents = () => {
        
        // Api.get(ApiEndpoints.GetAllPrivateEvents).then(response => {
        Api.get(ApiEndpoints.GetAllStudentTrainingsBySubscribedTrainers).then(response => {
            
        // Api.get(ApiEndpoints.GetAllTrainings).then(response => {
            //console.log(response);
            if (response && response.status == 200) {
                setIsError(false);
                setMessage("");
                setEventsList({ allEvents: response.data, filteredEvents: response.data });
            }
            else {
                setIsError(true);
                setMessage(GetEventMessage(response.status));
            }
        },
            error => {
                setIsError(true);
                setMessage(GetEventMessage(error.response.status));
            });
    }

    const toggleEventList = () => {
        setEventList(!eventList);
    }
    const onSearch = (searchData: any) => {
        console.log(searchData);
        var filterResult = eventsList.allEvents;
        console.log("before", filterResult);
        if (searchData != null && searchData.date != null) {
            filterResult = filterResult.filter((item: any) => {
                return new Date(item.startDate) >= new Date(searchData.date);
            });
            //console.log("after", filterResult);
        }
        if (searchData != null && searchData.trainer != null && searchData.trainer!="") {
            filterResult = filterResult.filter((item: any) => {
                
                let res = item?.organizers?.some(
                    (trainer: any) => {
                        return searchData.trainer == trainer.key
                    }
                );
                return res;
            });
            //console.log("after", filterResult);
        }
        if (searchData != null && searchData.location != null && searchData.location != "") {
            filterResult = filterResult.filter((item: any) => {
                return searchData.location == item.eventLocation.key
            });
            //console.log("after", filterResult);
        }
        if (searchData != null && searchData.type != null && searchData.type != "") {
            console.log(searchData.type);
            filterResult = filterResult.filter((item: any) => {
                console.log(item.disciplineType);
                return searchData.type == item.disciplineType
            });
            console.log("after", filterResult);
        }
        setEventsList({ ...eventsList, filteredEvents: filterResult });
    }
    const filterProps = {
        searchChange: onSearch
    }
    React.useEffect(() => {
        GetAllPrivateEvents();
        getAreas();
    }, []);

    return (
        <>
            <div className="eq-student-Trainings-board">
                <Row className="eq-event-heading-row" align="middle" justify="start">
                    <Col className="eq-event-heading-col" flex="auto">
                        <h3 className="eq-event-heading">{t("Trainings")}</h3>
                    </Col>
                </Row>
                <Row className="eq-event-heading-line">
                </Row>
                <div>
                    <EqTrainingFilter {...filterProps}></EqTrainingFilter>
                </div>
                {isError && <p>{message}</p>}
               
                <EqActivityTrainingList
                
                {...{ 
                eventList: [...eventsList.filteredEvents],
                areas:areas,
                    onCreateOrUpdateActivity:GetAllPrivateEvents }}
              ></EqActivityTrainingList>
                
                {/* old View for Student Trainings */}
               

                
            </div>
        </>
    );
}
export default EqTrainingsBoard;