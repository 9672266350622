import { Button, Card } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiEndpoints, FrontEndPoints } from "../../shared/Common";
import Config from "../../shared/Config";

const EqEventPrint = () => {
  const componentRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const handlePrint = () => {
    
    const originalContents = document.body.innerHTML;
    const printContent = componentRef.current;


    if (printContent) {
       // Detach the printable section from the DOM
       const printContent = componentRef.current;
       const originalContents = document.body.innerHTML;
   
       if (printContent) {
         // Hide other content on the page
         document.body.innerHTML = printContent.innerHTML;
   
         // Print the specific component content
         window.print();
   
         // Restore the original page contents
         document.body.innerHTML = originalContents;
         window.location.replace(Config.apiUrl + "trainer/activityBoard");
        //  navigate(
        //     FrontEndPoints.trainer.activityBoard,
        //     {
        //       state: {
        //         prevPath: location.pathname,
        //       },
        //     }
        //   )
       }

    }
  };
  React.useEffect(() => {
    // getStudentPopulation();
    handlePrint()
  }, []);

  return (
    <div ref={componentRef}>
      <Card title="Row 1" style={{ marginBottom: 16 }}>
        <p>Some content in Row 1</p>
        <Button
        // onClick={() => handleRowCopy("Row 1")}
        >
          Copy to Modal
        </Button>
      </Card>

      <Card title="Row 2" style={{ marginBottom: 16 }}>
        <p>Some content in Row 2</p>
        <Button
        // onClick={() => handleRowCopy("Row 2")}
        >
          Copy to Modal
        </Button>
      </Card>
    </div>
  );
};
export default EqEventPrint;
