import * as React from "react";
import "./EqApprovalRequest.scss";
import { Row, Col, Space, Button } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  getDefaultImage,
  imageType,
} from "../../shared/Common";
import Text from "antd/lib/typography/Text";
import { EqLocationType, EqProfessionType } from "../../shared/enums";
export interface IEqApprovalRequest {
  user?: any;
  acceptClicked: (user: any) => void;
  rejectClicked: (user: any) => void;
}
const EqApprovalRequest = ({
  user,
  acceptClicked,
  rejectClicked,
}: IEqApprovalRequest) => {

  const [homeAddress, setHomeAddress] = React.useState([]) as any;


  const accept = (event: any) => {
    acceptClicked(event);
  };
  const reject = (event: any) => {
    rejectClicked(event);
  };

  React.useEffect(() => {
    
    // console.log(step);
    if(user){
      let homeAddress = user.locations?.filter((opt: any) => (opt.location?.type == EqLocationType.Home)).shift()?.location;
setHomeAddress(homeAddress);
    }
  }, [user]);
  return (
    <>
      <Row
        className="eq-approval-Request"
        justify="space-around"
      >
        <Col
          xs={{ span: 4, offset: 0 }}
          sm={{ span: 4, offset: 0 }}
          md={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          xl={{ span: 2, offset: 0 }}
          xxl={{ span: 2, offset: 0 }}
        >
          <img
            src={user?.userImage?.url || getDefaultImage(imageType.partner)}
            className="user-img"
            alt="user"
          />
        </Col>

        <Col xs={17} sm={17} md={20} lg={20}>
          <Row>

          <Col xs={24} sm={24} md={4}>
              <Text className="eq-invite-row-subTitle" type="secondary">
               {EqProfessionType[user?.professionType]}
              </Text>
            </Col>
          <Col xs={24} sm={24} md={5}>
              <Text className="eq-invite-row-title">
                {user?.firstName + " " + user?.lastName}
              </Text>
            </Col>
            <Col className="eq-invite-row-dis" xs={24} sm={24} md={8}>
            <Text>{user?.email} </Text> 
            </Col>

            <Col xs={24} sm={24} md={6}>
              <Text className="eq-invite-row-subTitle" type="secondary">
                {homeAddress?.locationName
                  ? homeAddress?.locationName
                  : (homeAddress?.street
                      ? homeAddress?.street + " "
                      : "") +
                    (homeAddress?.city
                      ? homeAddress?.city
                      : "")}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col
               xs={{ span: 3, offset: 0 }}
               sm={{ span: 3, offset: 0 }}
               md={{ span: 2, offset: 0 }}
               lg={{ span: 2, offset: 0 }}
               xl={{ span: 2, offset: 0 }}
               xxl={{ span: 2, offset: 0 }}
        >
        
          <Space>
            <Button
              onClick={() => accept(user)}
              className="invite-accept-btn"
              size="small"
              shape="circle"
              icon={<CheckOutlined />}
            />
            <Button
              onClick={() => reject(user)}
              className="invite-reject-btn"
              size="small"
              shape="circle"
              icon={<CloseOutlined />}
            />
          </Space>
        </Col>
   
      </Row>
    </>
  );
};
export default EqApprovalRequest;
