import "./EqStudentCalendar.scss";
import React, { useRef, useState, useEffect } from "react";
import {
  Calendar,
  Badge,
  Row,
  Col,
  Button,
  Modal,
  Input,
  Image,
  Collapse,
} from "antd";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";

import FullCalendar from "@fullcalendar/react";
import {
  EventContentArg,
  EventInput,
  EventClickArg,
  DateSelectArg,
  DayCellContentArg,
  EventChangeArg,
} from "@fullcalendar/common";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
// import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import bedroomBabySvg from "../../../../public/images/icons/bedroom_baby.svg";
// font aswosome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChessKnight,
  faCalendarDays,
  faClock,
  faTag,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

import multiMonthPlugin from "@fullcalendar/multimonth";
import EqSchedule from "../../eqTrainer/eqSchedule/EqSchedule";
import { useAuthState } from "../../context/Context";
import {
  FrontEndPoints,
  ValidateIsSchool,
  ValidateIsStudent,
  ValidateIsTrainer,
  ValidateIsUser,
  getDefaultImage,
  imageType,
} from "../../shared/Common";
import EqButton from "../eqButton/EqButton";
import EqDatePicker from "../eqDatePicker/EqDatePicker";
import EqSelect from "../eqSelect/EqSelect";
import moment from "moment";
import EqCalendarComponents from "../eqCalendarComponents/EqCalendarComponents";
import AsyncImage from "../asyncImage/AsyncImage";
import Icon from "@ant-design/icons/lib/components/Icon";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import EqCollapsePanel from "../eqCollapsePanel/EqCollapsePanel";
import CreateOrEditPrivateTraining from "../../eqTrainer/createOrEditPrivateTraining/CreateOrEditPrivateTraining";
import EqCreateOrEditPublicEvent from "../../eqTrainer/eqCreateOrEditPublicEvent/EqCreateOrEditPublicEvent";
import EqActivityTrainingList from "../../eqTrainer/eqActivityTrainingList/EqActivityTrainingList";
import CreateOrEditSchoolTraining from "../../eqSchool/createOrEditSchoolTraining/CreateOrEditSchoolTraining";
import { useLocation, useNavigate } from "react-router-dom";
import EqCalenderEvent from "../eqCalendar/EqCalenderEvent/EqCalenderEvent";
// import { update } from 'react-spring';

export interface IStudentCalendarProps {
  isOwner?: any;
  classNames?: string;
  selectFun: any;
  products?: any;
  events?: any;
  trainings?: any;
  areas?: any;
  imagesData?: any;
  weekDays?: any;
  trainerStudents?: any;
  studentHorsesList?: any;
  population?: any;
  groupPopulation?: any;
  onCreateOrUpdateTraining?: any;
  onCreateOrUpdateEvent?: any;
  onRemoveTraining?: any;
  trainersOptions?: any;
  schoolEventTypes?: any;
  productOptions?: any;
  schoolStudents?: any;
  onStudentJoin?: any;
}
const EqStudentCalendar = ({
  isOwner,
  areas,
  imagesData,
  weekDays,
  trainerStudents,
  studentHorsesList,
  population,
  groupPopulation,
  classNames,
  selectFun,
  products,
  events,
  trainings,
  onCreateOrUpdateTraining,
  onCreateOrUpdateEvent,
  onRemoveTraining,
  trainersOptions,
  schoolEventTypes,
  productOptions,
  schoolStudents,
  onStudentJoin,
}: IStudentCalendarProps) => {
  const userDetails: any = useAuthState();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const isSchool: boolean = ValidateIsSchool(userDetails);
  const navigate = useNavigate();
  const location = useLocation();

  const calendarRef = useRef<FullCalendar | null>(null);

  const [view, setView] = useState("timeGridWeek");
  const [eventSources, setEventSources] = useState([]) as any;

  const [selectedSchoolTraining, setSelectedSchoolTraining] = useState(
    null
  ) as any;
  const [isSchoolTrainingModalOpen, setIsSchoolTrainingModalOpen] =
    useState(false);

  const [selectedTraining, setSelectedTraining] = useState(null);
  const [isTrainingModalOpen, setIsTrainingModalOpen] = useState(false);

  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [calendarData, setCalendarData] = useState() as any;

  const plugins = [dayGridPlugin, timeGridPlugin, multiMonthPlugin, listPlugin];
 

  const handleJoinEvent = (key: any) => {
    var url = `${FrontEndPoints.student.viewEvent}?key=${key}`;
    navigate(url);
  };

  const handleJoinTraining = (key: any) => {
    if (ValidateIsUser(userDetails)) {
      if (ValidateIsStudent(userDetails)) {
        // var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${userEmail}`;
        // navigate(out1);
      } else {
        // if not Student trying to book
        // notify to login as student
        var out1 = FrontEndPoints.login;
        navigate(out1);
      }
    } else {
      navigate(FrontEndPoints.login, {
        state: {
          prevPath: location.pathname,
          trainingKey: key,
        },
      });
    }
  };

  // School Training Edit
 


  // Training Edit
  const handleEditTraining = (key: any) => {
    const selectedTraining = trainings.find(
      (training: any) => training.key === key
    );
    if (selectedTraining) {
      setSelectedTraining(selectedTraining);
      setIsTrainingModalOpen(true);
    }
  };
  const handleOnUpdateTraining = (newData: any, oldData: any) => {
    setSelectedTraining(null);
    setIsTrainingModalOpen(false);
    onCreateOrUpdateTraining(newData, oldData);
  };

  const handleCancelTrainingModal = () => {
    setSelectedTraining(null);
    setIsTrainingModalOpen(false);
  };

  // Event Edit
  const handleEditEvent = (key: any) => {
    const selectedEvent = events.find((event: any) => event.key === key);
    if (selectedEvent) {
      setSelectedEvent(selectedEvent);
      setIsEventModalOpen(true);
    }
  };
  const handleOnUpdateEvent = (newData: any, oldData: any) => {
    setSelectedEvent(null);
    setIsEventModalOpen(false);
    onCreateOrUpdateEvent(newData, oldData);
  };

  const handleCancelEventModal = () => {
    setSelectedEvent(null);
    setIsEventModalOpen(false);
  };

  const showModal = () => {
    setIsTrainingModalOpen(true);
  };
  const handleOk = () => {
    setIsTrainingModalOpen(false);
  };

  const handleViewChange = (arg: any) => {
    const viewName = arg.view.type;
    // setCurrentView(viewName);
    setView(viewName);
  };
  // const [eventData, setEventData] = React.useState(eventList);

  const handleEventAdd = (event: any) => {
    // Do something with the new event data
    console.log("heloo");
  };

  // Define a custom function to render the event content
  const renderEventContent = (eventInfo: EventContentArg) => {
    //
    const { view } = eventInfo;
    const start = eventInfo.event.start as Date;
    const end = eventInfo.event.end as Date;
    // const duration = end.getTime() - start.getTime();

    const style1 = {
      color: `${eventInfo.event.extendedProps.eventLocation.color}`,
      border: `1px solid ${eventInfo.event.extendedProps.eventLocation.color}`,
      background: `${eventInfo.event.extendedProps.eventLocation.color}20`,
    };
    const iconType1 =
      eventInfo.event.extendedProps.type === "training"
        ? faChessKnight
        : faCalendarDays;
    const color1 = {
      color: `${eventInfo.event.extendedProps.eventLocation.color}`,
    };
    const background = {
      background: `${eventInfo.event.extendedProps.eventLocation.color}`,
    };

    // trainning
    // Day
    if (eventInfo.event.extendedProps.type === "training") {
      if (view.type === "timeGridDay") {
        return (
          <EqCalenderEvent
            view={view}
            event={eventInfo.event.extendedProps}
            isOwner={isOwner}
            handleEditEvent={handleEditTraining}
            handleJoinEvent={handleJoinTraining}
            onStudentJoin={onStudentJoin}
          ></EqCalenderEvent>
        );

        // return (
        //   <div className="my-event timeGridDay-event" style={style1}>
        //     <div className="img-container">

        //       <div className="image-size">
        //         <AsyncImage
        //           className="imge-training"
        //           src={
        //             eventInfo.event.extendedProps.imageUrl ||
        //             getDefaultImage(imageType.training)
        //           }
        //         />
        //       </div>
        //     </div>
        //     <div className="training-info">
        //       <Row>
        //         <Col>
        //           <Row>
        //             <FontAwesomeIcon
        //               icon={iconType1}
        //               style={color1}
        //               className="icon"
        //             />
        //             <div className="training-content">
        //               <span className="heading" style={color1}>
        //                 Training:
        //               </span>{" "}
        //               <span className="info" style={color1}>
        //                 {eventInfo.event.title}
        //               </span>
        //             </div>
        //           </Row>
        //           <Row>
        //             <FontAwesomeIcon
        //               icon={faLocationDot}
        //               style={color1}
        //               className="icon"
        //             />
        //             <div className="training-content">
        //               <span className="heading" style={color1}>
        //                 Area:
        //               </span>{" "}
        //               <span className="info" style={color1}>
        //                 {eventInfo.event.extendedProps.location}
        //               </span>
        //             </div>
        //           </Row>
        //         </Col>
        //         <Col className="training-info-col">
        //           <Row>
        //             <FontAwesomeIcon
        //               icon={faClock}
        //               style={color1}
        //               className="icon"
        //             />
        //             <div className="training-content">
        //               <span className="heading" style={color1}>
        //                 Time:
        //               </span>{" "}
        //               <span className="info info-time" style={color1}>
        //                 At {moment(eventInfo.event.start).format("h:mm a")}
        //               </span>
        //             </div>
        //           </Row>
        //           <Row>
        //             <FontAwesomeIcon
        //               icon={faTag}
        //               style={color1}
        //               className="icon"
        //             />
        //             <div className="training-content">
        //               <span className="heading" style={color1}>
        //                 Price:
        //               </span>{" "}
        //               <span className="info" style={color1}>
        //                 Kr.{" "}
        //                 {`${eventInfo.event.extendedProps.price.toFixed(2)}`}
        //               </span>
        //             </div>
        //           </Row>
        //         </Col>
        //       </Row>

        //     </div>
        //     <div className="book-btn">

        //       {isTrainer ? (
        //         <>
        //           <EqButton
        //             className="btn bordered"
        //             onClick={() =>
        //               handleEditTraining(eventInfo.event.extendedProps.key)
        //             }
        //           >
        //             Edit
        //           </EqButton>

        //         </>
        //       ) : isSchool ? (
        //         <EqButton
        //           className="btn bordered"
        //           onClick={() =>
        //             handleEditSchoolTraining(eventInfo.event.extendedProps.key)
        //           }
        //         >
        //           Edit
        //         </EqButton>
        //       ) : (

        //         <EqButton onClick={()=>handleJoinTraining(eventInfo.event.extendedProps.key)}>BOOK</EqButton>

        //       )}
        //     </div>
        //   </div>
        // );
        // Month
        // Render the event content for the month view
      } else if (view.type === "dayGridMonth") {
        return (
          <>
            {eventInfo.event.extendedProps.monthView && (
              <>
                <div className="fc-event-month " style={style1}>
                  <div className="trin-info">
                    <div className="month-title">{eventInfo.event.title}</div>
                    <div className="month-type">
                      {eventInfo.event.extendedProps.type}
                    </div>
                  </div>
                  <Badge
                    style={background}
                    count={
                      eventInfo.event.extendedProps.count > 1
                        ? eventInfo.event.extendedProps.count
                        : 0
                    }
                  />
                </div>
                <div className="hide">{eventInfo.event.extendedProps.type}</div>
              </>
            )}
          </>
        );
      } else {
        // week
        return (
          <div className="my-event timeGridWeek-event" style={style1}>
            <FontAwesomeIcon icon={iconType1} style={color1} className="icon" />
            {/* <AsyncImage
                        className= 'icon'

                            alt="" src={iconType} /> */}
            <Col className="timeGridWeek-info training-info">
              <Row
                className="timeGridWeek-title training-content"
                style={color1}
              >
                {eventInfo.event.title}
              </Row>
              <Row
                className="timeGridWeek-time training-content"
                style={color1}
              >
                {moment(eventInfo.event.start).format("h:mm a")}-{" "}
                {moment(eventInfo.event.end).format("h:mm a")}
                {/* <img src='../../../../public/images/icons/training.png' /> */}
              </Row>
            </Col>
           
          </div>
        );
      }
      // Events
    } else if (eventInfo.event.extendedProps.type === "event") {
      if (view.type === "timeGridDay") {
        // Render the event content for the month view
        return (
          <div className="my-event timeGridDay-event" style={style1}>
            <div className="img-container">
        
              <div className="image-size">
                <AsyncImage
                  className="imge-training"
                  src={
                    eventInfo.event.extendedProps.eqImage.url ||
                    getDefaultImage(imageType.training)
                  }
                />
              </div>
            </div>
            <div className="training-info">
            <Row  className="training-row">
                <Col className="training-col" sm={6} md={12}>
                  <Row className="content-row">
                    <Col className="icon">
                      <FontAwesomeIcon
                        icon={iconType1}
                        style={color1}
                        
                      /></Col>
                    <Col className="training-content">

                        <span className="heading" style={color1}>
                          Event:
                        </span>{" "}
                        <span className="info" style={color1}>
                          {eventInfo.event.title}
                        </span>

                    </Col>
                  </Row>
                </Col>
                <Col className="training-col" sm={6} md={12}>
                  <Row className="content-row">
                    <Col>
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        style={color1}
                        className="icon"
                      />
                    </Col>
                    <Col>
                      <div className="training-content">
                        <span className="heading" style={color1}>
                          Area:
                        </span>{" "}
                        <span className="info" style={color1}>
                          {eventInfo.event.extendedProps.location?.city}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col className="training-col" sm={6} md={12}>
                  <Row className="content-row">
                    <Col>
                      <FontAwesomeIcon
                        icon={faClock}
                        style={color1}
                        className="icon"
                      />
                    </Col>
                    <Col>
                      <div className="training-content">
                        <span className="heading" style={color1}>
                          Time:
                        </span>{" "}
                        <span className="info info-time" style={color1}>
                          At {moment(eventInfo.event.start).format("h:mm a")}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col className="training-col" sm={6} md={12}>
                  <Row className="content-row">
                    <Col>
                      <FontAwesomeIcon
                        icon={faTag}
                        style={color1}
                        className="icon"
                      />
                    </Col>
                    <Col>
                      <div className="training-content">
                        <span className="heading" style={color1}>
                          Price:
                        </span>{" "}
                        <span className="info" style={color1}>
                          Kr.{" "}
                          {`${eventInfo.event.extendedProps.price.toFixed(2)}`}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* <Row className='attende-content '>
                                          <Col className={duration >= 3600000 ? 'training-content' : 'training-content-half-hr'}>
                                              <span className='heading'>Attendees</span>{eventInfo.event.extendedProps.attendees.map((attendee: any, index: number) => (
                                                  <span className='info attende-name' key={index}>{attendee.name}</span>
                                              ))}
                                          </Col>
                                      </Row> */}
            </div>
            <div className="book-btn">
              {/* {isTrainer || isSchool ? ( */}
              {isOwner ? (
                <>
                  <EqButton
                    className="btn bordered"
                    onClick={() =>
                      handleEditEvent(eventInfo.event.extendedProps.key)
                    }
                  >
                    Edit
                  </EqButton>
                </>
              ) : (
                <EqButton
                  onClick={() =>
                    handleJoinEvent(eventInfo.event.extendedProps.key)
                  }
                >
                  BOOK
                </EqButton>
              )}
            </div>
          </div>
        );
        // month
      } else if (view.type === "dayGridMonth") {
        return (
          <>
            <div
              className="fc-event-month events-month"
              style={{
                color: `${eventInfo.event.extendedProps.eventLocation?.color}`,
                border: `1px solid ${eventInfo.event.extendedProps?.eventLocation.color}`,
                background: `${eventInfo.event.extendedProps.eventLocation?.color}20`,
              }}
            >
              <div className="month-title">{eventInfo.event.title}</div>
              <div>{eventInfo.event.extendedProps.type}</div>
            </div>
            <div className="hide">{eventInfo.event.extendedProps.type}</div>
          </>
        );
      } else {
        // week
        return (
          <div className="my-event timeGridWeek-event" style={style1}>
            <FontAwesomeIcon icon={iconType1} style={color1} className="icon" />
            {/* <AsyncImage
                        className= 'icon'

                            alt="" src={iconType} /> */}
            <Col className="timeGridWeek-info training-info">
              <Row
                className="timeGridWeek-title training-content"
                style={color1}
              >
                {eventInfo.event.title}
              </Row>
              <Row
                className="timeGridWeek-time training-content"
                style={color1}
              >
                {moment(eventInfo.event.start).format("h:mm a")}
              </Row>
            </Col>
          </div>
        );
      }
      // Product
    } else if (eventInfo.event.extendedProps.type === "product") {
      if (view.type === "timeGridDay") {
        // Render the event content for the month view
        return (
          <div className="my-event timeGridDay-event" style={style1}>
            <div className="img-container">
              <div className="image-size">
                <AsyncImage
                  className="imge-training"
                  src={
                    eventInfo.event.extendedProps.imageUrl ||
                    getDefaultImage(imageType.training)
                  }
                />
              </div>
            </div>
            <div className="training-info">
              <Row>
                <Col>
                  <Row>
                    <FontAwesomeIcon
                      icon={iconType1}
                      style={color1}
                      className="icon"
                    />
                    <div className="training-content">
                      <span className="heading" style={color1}>
                        Training:
                      </span>{" "}
                      <span className="info" style={color1}>
                        {eventInfo.event.title}
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      style={color1}
                      className="icon"
                    />
                    <div className="training-content">
                      <span className="heading" style={color1}>
                        Area:
                      </span>{" "}
                      <span className="info" style={color1}>
                        {eventInfo.event.extendedProps.eventLocation?.city}
                      </span>
                    </div>
                  </Row>
                </Col>
                <Col className="training-info-col">
                  <Row>
                    <FontAwesomeIcon
                      icon={faClock}
                      style={color1}
                      className="icon"
                    />
                    <div className="training-content">
                      <span className="heading" style={color1}>
                        Time:
                      </span>{" "}
                      <span className="info info-time" style={color1}>
                        At {moment(eventInfo.event.start).format("h:mm a")}
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <FontAwesomeIcon
                      icon={faTag}
                      style={color1}
                      className="icon"
                    />
                    <div className="training-content">
                      <span className="heading" style={color1}>
                        Price:
                      </span>{" "}
                      <span className="info" style={color1}>
                        Kr.{" "}
                        {`${eventInfo.event.extendedProps.price.toFixed(2)}`}
                      </span>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="book-btn">
              {isOwner ? (
                <>
                  <EqButton
                    className="btn bordered"
                    onClick={() =>
                      handleEditTraining(eventInfo.event.extendedProps.key)
                    }
                  >
                    Edit
                  </EqButton>
                </>
              ) : (
                <EqButton
                  onClick={() =>
                    handleJoinTraining(eventInfo.event.extendedProps.key)
                  }
                >
                  BOOK
                </EqButton>
              )}
            </div>
          </div>
        );
        // month
      } else if (view.type === "dayGridMonth") {
        return (
          <>
            <div
              className="fc-event-month"
              style={{
                color: `${eventInfo.event.extendedProps.eventLocation.color}`,
                border: `1px solid ${eventInfo.event.extendedProps.eventLocation.color}`,
                background: `${eventInfo.event.extendedProps.eventLocation.color}20`,
              }}
            >
              <div className="month-title">{eventInfo.event.title}</div>
              <div>{eventInfo.event.extendedProps.type}</div>
            </div>
            <div className="hide">{eventInfo.event.extendedProps.type}</div>
          </>
        );
      } else {
        // week
        return (
          <div className="my-event timeGridWeek-event" style={style1}>
            <FontAwesomeIcon icon={iconType1} style={color1} className="icon" />
            {/* <AsyncImage
                        className= 'icon'

                            alt="" src={iconType} /> */}
            <Col className="timeGridWeek-info training-info">
              <Row
                className="timeGridWeek-title training-content"
                style={color1}
              >
                {eventInfo.event.title}
              </Row>
              <Row
                className="timeGridWeek-time training-content"
                style={color1}
              >
                {moment(eventInfo.event.start).format("h:mm a")}
                {/* -{" "}
                            {moment(eventInfo.event.end).format("h:mm a")} */}
                {/* <img src='../../../../public/images/icons/training.png' /> */}
              </Row>
            </Col>
            {/* <AsyncImage className={duration <= 3600000 ? 'icons icons-h' : duration >= 3600000 ? 'icons icnons-l' : 'icons '}

                            alt="" src={iconType} /> */}
          </div>
        );
      }
    }
  };





  const handleItemClick = (eventClickInfo: any) => {
    // handle item clicked

    const clickedEventDate = eventClickInfo.event.start as Date;
    // eventClickInfo.view.calendar.changeView('timeGridDay', clickedEventDate);

    const timeInString = `${clickedEventDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${clickedEventDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    const calendarApi = calendarRef?.current?.getApi();
    // Switch to the day view
    if (calendarApi) {
      // Switch to the day view and set the view to the clicked event's time
      calendarApi.changeView("timeGridDay", clickedEventDate);

      // calendarApi.gotoDate(clickedEventDate);

      calendarApi.scrollToTime(timeInString);

      // calendarApi.scrollToTime(clickedEventDate.getHours(), clickedEventDate.getMinutes());
    }
    // alert(arg.dateStr);
    // console.log(arg.date);
  };

  const handleDateClick = (arg: any) => {
    // handle date click
    alert(arg.dateStr);
  };

  const handleDayClick = (date: Date) => {
    console.log("Clicked date:", date);
    // Perform any actions or navigate to a specific day view
  };

  const renderDayCellContent = (arg: DayCellContentArg) => {
    const handleClick = () => {
      handleDayClick(arg.date);
    };

    return (
      <div className="custom-day-cell" onClick={handleClick}>
        {arg.dayNumberText}
      </div>
    );
  };

  const handleEventDrop = (info: any) => {
    const { event, delta } = info;

    // Calculate the new start and end time
    const newStart = event.start.add(delta);
    const newEnd = event.end.add(delta);

    // Update the event's start and end time
    event.setDates(newStart, newEnd);

    // Persist the changes to your data source (e.g., API call, state update)
    // ...
  };
  const handleEventResize = (arg: any) => {
    // Handle event resize logic
    const handleClick = () => {
      handleDayClick(arg.date);
    };

    return (
      <div className="custom-day-cell" onClick={handleClick}>
        {arg.dayNumberText}
      </div>
    );
  };

  const handleDateSelect = (selectInfo: any) => {
    const { start, end } = selectInfo;
  };

  React.useEffect(() => {
    console.log(events);
    console.log(trainings);
    console.log(schoolStudents);
    if (events) MyCalendarData(events, trainings);
  }, [events, trainings, schoolEventTypes]);

  const MyCalendarData = (events: any, trainings: any) => {
    //
    
    let calendarTrainings = [] as any;
    if (trainings) {
      calendarTrainings = trainings?.flatMap((training: any) => {
        const { id, startTime, endTime, trainingTimes, ...rest } = training;
        return trainingTimes.map((trainingTime: any, idTrain: any) => ({
          ...rest,
          type: "training",
          title: training.title,
          start: new Date(trainingTime.startTime),
          end: new Date(trainingTime.endTime),
          count: trainingTimes.length,
          monthView: idTrain > 0 ? false : true,
        }));
      });
    }

    const calendarEvents = [] as any;
    // working

    if (events) {

    events.map((evtClass:any)=>{
      const { startTime, endTime, ...rest } = evtClass.event;
      const obj = {
        ...rest,
        id:evtClass.id,
        type: "event",
        title: evtClass.event.title,
        // start: new Date(evtClass.startDate),
        start: new Date(evtClass.event.startTime),

        end: new Date(evtClass.event.startTime + 1 * 60 * 60 * 1000),
      };
      calendarEvents.push(obj);
    });

    }



    setCalendarData(calendarEvents);

    const calenderData = [...calendarTrainings, ...calendarEvents];

    setCalendarData(calenderData);
  };

  return (
    <>
      {/* <div className={`eq-calendar ${classNames ? classNames : ""}`} >
                <Calendar fullscreen={false} dateCellRender={dateCellRender} monthCellRender={monthCellRender}
                    onSelect={selectFun}
                />
                </div> */}
      <FullCalendar
        ref={calendarRef}
        plugins={plugins}
        allDaySlot={false}
        initialView={view}
        viewDidMount={handleViewChange}
        viewWillUnmount={handleViewChange}
        headerToolbar={{
          left: "today",
          center: "prev title next",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        navLinks={true}
        nowIndicator={true}
        eventClick={handleItemClick}
        // events={myEvents}
        events={calendarData}
        eventContent={renderEventContent}
        // dayCellContent={renderDayCellContent}
        editable={true}
        dragScroll={true} // Enable drag-scrolling
        scrollTime="06:00:00" // Set the scroll start time
        scrollTimeReset={false} // Disable automatic scroll time reset
        droppable={true}
        eventDrop={handleEventDrop}
        eventResize={handleEventResize}
        // // dateSelect={handleDateSelect}
        slotEventOverlap={false}
        // selectable={true}
        select={handleDateSelect}
        eventAdd={handleEventAdd}
        views={{
          dayGridMonth: {
            titleFormat: { year: "numeric", month: "long" },
          },
          timeGridWeek: {
            titleFormat: { year: "numeric", month: "short", day: "numeric" },
            dayHeaderFormat: {
              weekday: "short",
              day: "numeric",
            },
            viewClassNames: ["my-timegridweek"],
          },
          timeGridDay: {
            titleFormat: { year: "numeric", month: "short", day: "numeric" },
          },
          listWeek: {
            titleFormat: { year: "numeric", month: "long", day: "numeric" },
          },
        }}

        // dayHeaderFormat={
        //     { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true }
        // }
      />


    </>
  );
};
export default EqStudentCalendar;
