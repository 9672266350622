import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiEndpoints, FrontEndPoints } from "../shared/Common";
import Api from "../shared/Api";
import "./UpdatePassword.scss";

import { Col, Row } from "antd";
import {  EqInputPassword } from "../custom/eqInput/EqInput";
import EqButton from "../custom/eqButton/EqButton";

const UpdatePassword = (props: any) => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isPasswordChanged, setIsPasswordChanged] = useState("");
  const [isError, setIsError] = useState("");

  const changePassword = (): void => {
    if (
      !password.length ||
      !repeatPassword.length ||
      password !== repeatPassword
    ) {
      setIsError("please set password correctly!");
      return;
    }

    setIsError("");

    var searchParams = new URLSearchParams(window.location.search);
    Api.post(ApiEndpoints.UserAccount.UpdateUserPasswordAndVerifyEmail, {
      password: password,
      repeatPassword: repeatPassword,
      email: searchParams.get("email"),
      EmailToken: searchParams.get("token"),
    }).then(
      (response) => {
        setIsError("");
        setIsPasswordChanged("Password changed, Please Login");
      },
      (err) => {
        setIsPasswordChanged("");
        setIsError("Error occurred");
      }
    );
  };

  return (
    <div className="eq-update-password">
        <div className="eq-container-fluid">

        <Row className="eq-up-heading-row" align="middle" justify="start">
          <Col className="eq-up-heading-col" flex="auto">
            <h2 className="eq-up-heading">{"Update Password"}</h2>
          </Col>
        
        </Row>
      {isError && <h6 className="password-error">{isError}</h6>}

      <Row>
        <Col md={12} xs={24} sm={24} className="col-left mb-16">
          <EqInputPassword
            label="Password"
            className="profile-input"
            // type="password"
            placeholder=""
            value={password}
            onChange={(event: any) => setPassword(event.target.value)}
          ></EqInputPassword>
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={24} sm={24} className="col-left mb-16">
          <EqInputPassword
            label="Repeat Password"
            // type="password"
            className="profile-input"
            placeholder=""
            value={repeatPassword}
            onChange={(event: any) => setRepeatPassword(event.target.value)}
          ></EqInputPassword>
        </Col>
      </Row>
      <Row>
        <Col className="eq-up-button">
          <EqButton
            type="button"
            classNames="btn-background-orange parent-btn"
            onClick={() => changePassword()}
          >
            {"Update password"}
          </EqButton>
        </Col>
      </Row>
      {/* <div className="form-group">
            <label className="label" htmlFor="password">Password</label>
            <input type="password" value={password} onChange={event => setPassword(event.target.value)} className="form-control user-input" id="password" aria-describedby="password" placeholder="******" />
        </div> */}

      {/* <div className="form-group">
                <label htmlFor="repeatPassword">Confirm Password</label>
                <input type="password" value={repeatPassword} onChange={event => setRepeatPassword(event.target.value)} className="form-control user-input" id="repeatPassword" placeholder="******" aria-describedby="repeatPassword" />
            </div> */}
      {/* <button className="btn btn-primary" onClick={() => changePassword()}>Reset password</button> */}
      {isPasswordChanged.length > 0 && (
        <>
          <h6 className="password-success">{isPasswordChanged}</h6>
          <Link to={FrontEndPoints.login}>Go to Login page</Link>
        </>
      )}
    </div></div>
  );
};

export default UpdatePassword;
