import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./EqEventInvitationBoard.scss";
import Api from "../../shared/Api";
import {
	ApiEndpoints,
	FrontEndPoints,
	openNotificationWithIcon,
	ValidateStudentCanBook,
	ValidateStudentCanPay,
} from "../../shared/Common";
import { Row, Col, notification } from "antd";
import {
	EqEventAttendeeStatus,
	EqEventUserType,
	eqNotificationTypes,
} from "../../shared/enums";
import EqEventInvitesList from "../EqEventinviteList/EqEventinviteList";
import EqEventInviteDetail from "../EqEventInviteDetail/EqEventInviteDetail";
import EqButton from "../../custom/eqButton/EqButton";
import { loadState } from "../../shared/LocalStorage";
import { useAuthState } from "../../context/Context";
import modal from "antd/lib/modal";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export interface IEqEventInvitationBoard {
	onloadActivity?: any;
}
const EqEventInvitationBoard = ({
	onloadActivity,
}: IEqEventInvitationBoard) => {
	const navigate = useNavigate();
	const location = useLocation();
	const userDetails: any = useAuthState();

	const studentCanBook: boolean = ValidateStudentCanBook(userDetails);
	const studentCanPay: boolean = ValidateStudentCanPay(userDetails);

	const [messageString, setMessageString] = React.useState("");
	const [eventInvites, setEventInvites] = React.useState([] as any);
	const [pendingInvites, setPendingInvites] = React.useState([] as any);
	const [acceptedInvites, setAcceptedInvites] = React.useState([] as any);
	const [steps, setSteps] = React.useState(1 as number);
	const [eventDetail, setEventDetail] = React.useState({} as any);

	// const payNow = () => {
	// 	navigate(FrontEndPoints.student.allTrainings, {
	// 		state: { prevPath: location.pathname },
	// 	});
	// };

	const CreateCheckoutSession = (event: any) => {
		var eventKey = event?.key;
		Api.post(ApiEndpoints.StudentTrainingStripeCheckout.Create, {
			key: eventKey,
		})
			.then((response) => {
				console.log(response);
				window.location.href = response.data.sessionUrl;
			})
			.catch((error) => {
				openNotificationWithIcon(
					"Error!",
					"Unable to Create checkout",
					eqNotificationTypes.error
				);
			});
	};

	const openNotificationCheckOutNotification = (
		msg: any,
		desc: any,
		event: any
	) => {
		const btn = (
			<div className="right">
				<EqButton onClick={() => CreateCheckoutSession(event)}>
					Pay Now
				</EqButton>
			</div>
		);
		notification["info"]({
			message: msg,
			btn,
			description: desc,
		});
	};

	const CreateCheckoutSessionForMultiple = (events: any) => {
		Api.post(
			ApiEndpoints.StudentTrainingStripeCheckout.CreateForMultiple,
			events
		)
			.then((response) => {
				console.log(response);
				window.location.href = response.data.sessionUrl;
			})
			.catch((error) => {
				openNotificationWithIcon(
					"Error!",
					"Unable to Create checkout",
					eqNotificationTypes.error
				);
			});
	};

	const openNotificationCheckOutMultipleEvents = (
		msg: any,
		desc: any,
		events: any
	) => {
		const btn = (
			<div className="right">
				<EqButton
					onClick={() => CreateCheckoutSessionForMultiple(events)}
				>
					Pay Now
				</EqButton>
			</div>
		);
		notification["info"]({
			message: msg,
			btn,
			description: desc,
		});
	};

	function AcceptEventInvite(e: any) {
		// e.stopPropagation();
		// setStatus("accept");

		Api.post(ApiEndpoints.StudentTrainingInvite.AcceptEventInvite, {
			key: e.eventKey,
		}).then(
			(response) => {
				if (response && response.status == 202) {
					GetAllPendingStudentEventInvites();
					setSteps(1);
					// payNow()
					if (studentCanPay) {
						openNotificationCheckOutNotification(
							"Pay for the accepted Activity",
							"",
							e.event
						);
					}
				} else if (response && response.status == 200) {
					GetAllPendingStudentEventInvites();
					setSteps(1);
					openNotificationWithIcon(
						"Accepted! ",
						"Invite Accepted and Paid",
						eqNotificationTypes.success
					);
				} else {
					setMessageString("");
				}
			},
			(err) => {
				setMessageString("");
			}
		);
	}
	function AcceptAllEventInvites(events: any) {
		console.log(events);
		var data = JSON.stringify(events);
		console.log(data);

		Api.post(
			ApiEndpoints.StudentTrainingInvite.AcceptEventInvitesList,
			events
		).then(
			(response) => {
				if (response && response.status == 202) {
					GetAllPendingStudentEventInvites();
					setSteps(1);
					// payNow()
					openNotificationCheckOutMultipleEvents(
						"Pay for the accepted Activities",
						"",
						events
					);
				} else if (response && response.status == 200) {
					GetAllPendingStudentEventInvites();
					setSteps(1);
					openNotificationWithIcon(
						"Accepted And Paid! ",
						"Invites Accepted and Paid",
						eqNotificationTypes.success
					);
				} else {
					openNotificationWithIcon(
						"Server error",
						" Please try again or contact administrator."
					);
				}
			},
			(err) => {
				openNotificationWithIcon(
					"Server error",
					" Please try again or contact administrator."
				);
			}
		);
	}

	function RejectAllEventInvites(e: any) {
		Api.post(
			ApiEndpoints.StudentTrainingInvite.RejectEventInvitesList,
			e
		).then(
			(response) => {
				if (response && response.status == 200) {
					GetAllPendingStudentEventInvites();
					setSteps(1);
				} else {
					setMessageString("");
				}
			},
			(err) => {
				setMessageString("");
			}
		);
	}
	function RejectEventInvite(e: any) {
		// e.stopPropagation();
		// setStatus("accept");
		Api.post(ApiEndpoints.StudentTrainingInvite.RejectEventInvite, {
			key: e,
		}).then(
			(response) => {
				if (response && response.status == 200) {
					GetAllPendingStudentEventInvites();
					setSteps(1);
				} else {
					setMessageString("");
				}
			},
			(err) => {
				setMessageString("");
			}
		);
	}

	// function AcceptEventInvite(e:any) {
	//     // e.stopPropagation();
	//     // setStatus("accept");
	//     Api.post(ApiEndpoints.AcceptEventInvite, { eventKey: e }).then(response => {
	//         if (response && response.status == 200) {
	//             GetAllPendingStudentEventInvites();
	//         } else {
	//             setMessageString('');
	//         }
	//     }, err => {
	//         setMessageString('');
	//     });
	// }

	const GetAllPendingStudentEventInvites = () => {
		setMessageString("");
		// Api.get(ApiEndpoints.StudentTrainingInvite.GetAllPendingInvites).then(
		Api.get(ApiEndpoints.StudentTrainingInvite.GetAllPending).then(
			(response) => {
				if (response && response.status == 200) {
					var pendingInvites = [] as any;
					var data = response?.data ? response?.data : ([] as any);
					setPendingInvites(data);
					if (data.length) {
						onloadActivity(true);
					} else {
						onloadActivity(false);
					}
					//data.map((d: any, index: number) => {
					//    pendingInvites.push(d);
					//});
					//console.log(pendingInvites);
				} else {
					setMessageString("Not found");
				}
			},
			(err) => {
				setMessageString("Not found");
			}
		);
	};
	const getAcceptedInvitations = () => {
		setMessageString("");
		Api.get(ApiEndpoints.getAcceptedUserEventsInvites).then(
			(response) => {
				if (response && response.status == 200) {
					var acceptedInvites = [] as any;
					var data = response?.data ? response?.data : ([] as any);
					setAcceptedInvites(data);
					//data.map((d: any, index: number) => {
					//    acceptedInvites.push(d);
					//});
				} else {
					setMessageString("Not found");
				}
			},
			(err) => {
				setMessageString("Not found");
			}
		);
	};
	const getAllInvitations = () => {
		setMessageString("");
		Api.get(ApiEndpoints.getAllUserEventsInvites).then(
			(response) => {
				if (response && response.status == 200) {
					console.log(response);

					var allInvites = [] as any;
					var data = response?.data ? response?.data : ([] as any);
					setEventInvites(data);
				} else {
					setMessageString("Not found");
				}
			},
			(err) => {
				setMessageString("Not found");
			}
		);
	};
	const viewEventDetail = (detail: any) => {
		if (steps === 1) {
			setSteps(2);
			console.log(detail);
			setEventDetail(detail);
		} else {
			setSteps(1);
		}
	};

	const isLatePaymentAllowed = (event: any) => {

		const ownerOrganizer = event.organizers.find(
			(organizer: any) => organizer.eventUserType === EqEventUserType.Owner
		  );
		// const ownerOrganizer = event.organizers[0];

		// Check if organizer has Allowed Late Payment To the Student
		return ownerOrganizer?.user?.allowLatePayments || false;
	};
	const acceptClickedFun = (eventOrder: any) => {
		// if (!userDetails.isSubscribed) {
		// 	openNotificationWithIcon(
		// 		"Error!",
		// 		"Kindly Subscribe to join Training",
		// 		eqNotificationTypes.error
		// 	);
		// } else 
		if (studentCanBook) {
			

			const isLateAllowed = isLatePaymentAllowed(eventOrder.event);
			if (isLateAllowed) {
				AcceptEventInvite(eventOrder);
			} else {
				// checkout
				CreateCheckoutSession(eventOrder.event);
			}
			console.log(eventOrder);
		} else {
			openNotificationWithIcon(
				"Error!",
				"you don't have permission, contact Admin",
				// "Add Parent to buy"
				eqNotificationTypes.error
			);
		}
		console.log(eventOrder);
	};
	const acceptAllClickedFun = (eventOrders: any) => {
		// if (!userDetails.isSubscribed) {
		// 	openNotificationWithIcon(
		// 		"Error!",
		// 		"Kindly Subscribe to join Training",
		// 		eqNotificationTypes.error
		// 	);
		// } else 
		if (studentCanBook) {
      
			let result = eventOrders.map((item: any) => {
				return { key: item.eventKey };
			});
      const isLateAllowed = isLatePaymentAllowed(eventOrders[0].event);
			if (isLateAllowed) {
			AcceptAllEventInvites(result);
      }
      else{
        // checkout
        CreateCheckoutSessionForMultiple(result)
      }
		} else {
			openNotificationWithIcon(
				"Error!",
				"Add Parent to join Trainings",
				eqNotificationTypes.error
			);
		}
		// setSteps(3);
	};
	const rejectClickedFun = (event: any) => {
		RejectEventInvite(event.eventKey);
		console.log(event);
		// setSteps(5);
	};
	const rejectAllClickedFun = (events: any) => {
		let result = events.map((item: any) => {
			return { key: item.eventKey };
		});
		RejectAllEventInvites(result);
		// setSteps(5);
	};
	React.useEffect(() => {
		GetAllPendingStudentEventInvites();
		// getAcceptedInvitations();
		console.log(eventInvites);
	}, []);
	return (
		<>
			<div className="eq-eventInvitationBoard-container">
				{/* <Row className="eq-eventInvitationBoard-row">
                    <Col className="eq-eventInvitationBoard-col">
                        <div className="eq-eventInvitationBoard-heading">Activity Invitations</div>
                    </Col>
                </Row> */}
				<div className="">
					{/*Pending Invites*/}
					{steps === 1 && (
						<EqEventInvitesList
							{...{
								invitesList: [...pendingInvites],
								setEventDetail: viewEventDetail,
								step: steps,
							}}
						></EqEventInvitesList>
					)}
				</div>
				<div className="">
					{/*All Invites*/}
					{steps === 2 && (
						<EqEventInviteDetail
							{...{
								props: eventDetail,
								acceptClicked: acceptClickedFun,
								acceptAllClicked: acceptAllClickedFun,
								rejectClicked: rejectClickedFun,
								rejectAllClicked: rejectAllClickedFun,
								setEventDetail: viewEventDetail,
								step: steps,
							}}
						></EqEventInviteDetail>
					)}
				</div>

				<div className="">
					{/*Accepted Invites*/}
					{steps === 3 && (
						<EqEventInvitesList
							{...{
								invitesList: [...acceptedInvites],
								step: steps,
							}}
						></EqEventInvitesList>
					)}
				</div>
				<div className="">
					{/*Remaining Invites*/}
					{steps === 5 && (
						<EqEventInvitesList
							{...{
								invitesList: [...pendingInvites],
								step: steps,
							}}
						></EqEventInvitesList>
					)}
				</div>
			</div>
		</>
	);
};
export default EqEventInvitationBoard;
