import * as React from 'react';
import './EqGroupMembers.scss';
import { Row, Col, Tooltip, message, Button } from 'antd';
import { Checkbox, Tag } from 'antd';
import { IGroups } from '../../shared/Interfaces';
import { IStudentGroupDto } from '../../shared/interfaces/IStudentGroupDto';
import { IGroupDto } from '../../shared/interfaces/IGroupDto';
import { IStudentDto } from '../../shared/interfaces/IStudentDto';
import { EqUserType } from '../../shared/enums';
import { IEqGroupDto } from '../../shared/interfaces/IEqGroup';
import EqTags from '../../custom/eqTags/EqTags';
import EqCollapse from '../../custom/eqCollapse/EqCollapse';
import { useTranslation, Trans } from 'react-i18next';
import {
    CopyOutlined,
    PhoneOutlined
} from "@ant-design/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import EqButton from '../../custom/eqButton/EqButton';

export interface IEqGroupMember {
    //selected inside IStudentGroupDto was getting undefined
    member: IStudentGroupDto;
    onCheckChangeFunc?: (item: IStudentGroupDto, checked: boolean) => void;
    delFunc?: (item: IStudentGroupDto) => void;
    selected?: boolean;
    hideGroup?: boolean;
    hideName?: boolean;
    onCheck?: any;
}

const EqGroupMembers = ({ member, delFunc, selected = false, onCheck, onCheckChangeFunc, hideGroup, hideName, ...rest }: IEqGroupMember) => {
    // const [check, setCheck] = React.useState(selected) ;
    const { t, i18n } = useTranslation();
    const [copyMessage, contextCopyMessage] = message.useMessage();

    var name = "";



    function onChange(event: any) {
        if (onCheckChangeFunc != undefined) {
            onCheckChangeFunc(member, event.currentTarget.value);
        }
    }
    const deleteClicked = (e: any) => {
        //e.preventDefault();
        //e.stopPropagation();
        if (delFunc != null) {
            delFunc(member);
        }
    }

    const getEmailsText = () => {
        const emails = member?.user?.email || "";

        return emails;
    };

    const getNumbersText = () => {
        const numbers = member?.user?.phoneNumber || "";

        return numbers
    };
    const handleCopyLink = () => {

        copyMessage.open({
            type: "success",
            content: "Copied",
        });
    };


    React.useEffect(() => {
        console.log(member);
        // 
    }, [member]);

    return (
        <>

            <Row className="eq-s-tr hidden-sm" justify="space-between" align="middle">
                {hideGroup != true &&
                    <Col className="eq-td-checkbox" span={1}>
                        <Checkbox
                            onChange={() => {
                                onCheck(member);
                            }}
                            checked={member.selected}></Checkbox>
                    </Col>
                }
                <Col className="eq-s-td" span={5}>
                    {member?.user?.firstName ?? member?.user?.userName ?? member?.group?.name ?? ""}
                </Col>
                <Col className="eq-s-td horse-detail-copy" span={6}>
                    {contextCopyMessage}
                    <CopyToClipboard text={getEmailsText()}>
                        <div className="copy-text" onClick={handleCopyLink} >
                            <Tooltip title={t("copyEmail")} color="#9cb9ca">
                                {member?.user?.email ?? ""}
                            </Tooltip>
                        </div>
                    </CopyToClipboard>

                </Col>

                <Col className="eq-s-td horse-detail-copy" span={5}>
                    <CopyToClipboard text={getNumbersText()}>
                        <div className="copy-text" onClick={handleCopyLink} >
                            <Tooltip title={t("copyNumbers")} color="#9cb9ca">
                                {member?.user?.phoneNumber ?? ""}
                            </Tooltip>
                        </div>
                    </CopyToClipboard>

                </Col>
                {hideGroup != true &&
                    <Col className="eq-s-td eq-s-groups" span={5}>
                        {member && member.group ? (
                            <EqTags color={member?.group?.color}
                                text={member.group.name}></EqTags>
                        ) :
                            (
                                member.user?.groups?.map((item: any, index: number) =>
                                    <EqTags
                                        color={item?.color}
                                        key={index}
                                        text={item?.name}></EqTags>
                                )
                            )
                        }
                    </Col>
                }
                <Col className="eq-s-td action-btn" span={2}>

                    <div className="" >
                        {/*<span className="eq-action-invite"><img alt="" src="/Invite.svg" /></span>*/}
                        <span className="eq-action-delete"><img alt="" src="/Delete.svg" onClick={deleteClicked} /></span>
                    </div>
                </Col>
            </Row>

            <div className="hidden-md mobile-view">
                <EqCollapse header={
                    <Row justify="start" align="middle">
                        <Col className="" span={2}>
                            <Checkbox
                                onChange={() => {
                                    onCheck(member);
                                }}
                                checked={member.selected}></Checkbox>
                        </Col>
                        <Col className="" flex="auto">
                            {member?.user?.firstName ?? member?.user?.userName ?? member?.group?.name ?? ""}

                        </Col>
                    </Row>


                } >
                    <>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("name")}
                            </Col>
                            <Col className="eq-card-row horse-detail" span={24}>
                                {member?.user?.firstName ?? member?.user?.userName ?? member?.group?.name ?? ""}

                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading " span={24}>
                                {t("e-mail")}
                            </Col>
                            <Col className="horse-detail-copy" span={24}>
                                <CopyToClipboard text={getEmailsText()}>
                                    <div className="copy-text" onClick={handleCopyLink} >
                                        <Tooltip title={t("copyEmails")} color="#9cb9ca">
                                            {member?.user?.email ?? ""}
                                        </Tooltip>
                                    </div>
                                </CopyToClipboard>

                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("phoneNumber")}
                            </Col>
                            <Col className="horse-detail-copy" span={24}>

                                <CopyToClipboard text={getNumbersText()}>
                                    <div className="copy-text" onClick={handleCopyLink} >
                                        <Tooltip title={t("copyNumbers")} color="#9cb9ca">
                                            {member?.user?.phoneNumber ?? ""}
                                        </Tooltip>
                                    </div>
                                </CopyToClipboard>


                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("groups")}
                            </Col>
                            <Col className="eq-s-td eq-s-groups" span={24}>
                                {hideGroup != true &&
                                    <>
                                        {member && member.group ? (
                                            <EqTags color={member?.group?.color}
                                                text={member.group.name}></EqTags>
                                        ) :
                                            (
                                                member.user?.groups?.map((item: any, index: number) =>
                                                    <EqTags
                                                        color={item?.color}
                                                        key={index}
                                                        text={item?.name}></EqTags>
                                                )
                                            )
                                        }
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("actions")}
                            </Col>
                            <Col span={24} className='action-btn'>
                                <div className="" >
                                    {/*<span className="eq-action-invite"><img alt="" src="/Invite.svg" /></span>*/}
                                    <span className="eq-action-delete"><img alt="" src="/Delete.svg" onClick={deleteClicked} /></span>
                                </div>

                            </Col>


                        </Row>

                    </>

                </EqCollapse>

            </div >
        </>
    );
}
export default EqGroupMembers;