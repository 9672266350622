import React, { useEffect, useState } from "react";
import { Avatar, Col, Input, Row, Tooltip } from "antd";
import { EqMessageType } from "../../../shared/enums";
import { EqInput } from "../../../custom/eqInput/EqInput";
import EqButton from "../../../custom/eqButton/EqButton";
import {
    getDefaultImage,
    getEmailFromToken,
    imageType,
    openNotificationWithIcon,
} from "../../../shared/Common";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./EqMessageReply.scss";
import moment from "moment";
import { useAuthState } from "../../../context/Context";
import { useTranslation } from "react-i18next";


interface EqMessageReplyFormProps {
    message: any;
    onCreateReply: (message: any, reply: any) => void;
}

const EqMessageReply: React.FC<EqMessageReplyFormProps> = ({
    message,
    onCreateReply,
}) => {
    const { t, i18n } = useTranslation();

    const userDetails: any = useAuthState();
    const currentUserEmail = getEmailFromToken();
    const [showReplies, setShowReplies] = useState(true);
    const [replyText, setReplyText] = useState("");


    // useEffect(() => {
    //     if (message) {

    //         console.log(message)
   
    //     }
    // }, [message]);

    // const handleToggleReplies = () => {
    //     setShowReplies(!showReplies);
    // };


    const handleReply = () => {
        if (replyText.trim() !== "") {
            // Perform any necessary logic before sending the reply
            onCreateReply(message, replyText);
            setReplyText("");
        } else {
            openNotificationWithIcon(t("error"), t("validReplyText"));
        }
    };
    const formatRecipients = () => {
        if (message.messageType == EqMessageType.Message) {
            var msgSender = message.sender;
            return `${msgSender?.firstName} ${msgSender?.lastName}`;
        } else {
            let stringBuilder: string = "";
            message.messageRecipients.forEach((item: any) => {
                stringBuilder += `${item?.recipient?.firstName} ${item?.recipient?.lastName}, `;
            })
            return stringBuilder.length ? stringBuilder.slice(0, -2) : "";
        }
    }

    const formatEmails = () => {
        if (message.messageType == EqMessageType.Message) {
            return message.sender?.email;
        } else {
            let stringBuilder: string = "";
            message.messageRecipients.forEach((item: any) => {
                stringBuilder += `${item?.recipient?.email}, `;
            })
            return stringBuilder.length ? stringBuilder.slice(0, -2) : "";
        }
    }

   const renderChatHeading=()=>{
    const isPrivateMessage = message.messageType === EqMessageType.Message;
    const isSenderCurrentUser = currentUserEmail === message.sender?.email

    // For Private Message
        if(isPrivateMessage)
        {
            if(isSenderCurrentUser && message.messageRecipients.length > 0){
                 // If current user is the sender, show the recipient's details

                let messageRecipient=message.messageRecipients[0].recipient
                return(
                    <>
                      <Col>
                        <Avatar.Group maxCount={5} maxStyle={{ color: '#FF8838', backgroundColor: '#fde3cf' }}>
                        <Tooltip title={`${messageRecipient?.firstName} ${messageRecipient?.lastName}`}>
                                    <Avatar
                                        size={40}
                                        src={
                                            messageRecipient
                                                ? messageRecipient.userImage?.url
                                                : getDefaultImage(imageType.trainer)
                                        }
                                    />
                                </Tooltip>
                        </Avatar.Group>
                        </Col>
                        <Col>
                        <Tooltip title={`${messageRecipient?.firstName} ${messageRecipient?.lastName}`}>
                                <div className="overflow-hidden email-body-receipients">
                                    {`${messageRecipient?.firstName} ${messageRecipient?.lastName}`}
                                </div>
                            </Tooltip>
                            <Tooltip title={messageRecipient.email}>
                                <div className="overflow-hidden">{messageRecipient.email}</div>
                            </Tooltip>
                        </Col>
                    </>
                  )


                
            }else{
                // If current user is not the sender, show the sender's details
                return(
                    <>
                <Col>        
                    <Avatar.Group 
                    maxCount={2}
                     maxStyle={{ color: '#FF8838', backgroundColor: '#fde3cf' }}>
                      <Tooltip title={`${message?.sender?.firstName} ${message?.sender?.lastName}`}>
                            <Avatar
                                size={40}
                                src={
                                 message
                                        ? message.sender.userImage?.url
                                        : getDefaultImage(imageType.trainer)
                                }
                            />
                        </Tooltip>
                </Avatar.Group>
                </Col>
                <Col>
                        <Tooltip title={`${ message.sender?.firstName} ${ message.sender?.lastName}`}>
                                <div className="overflow-hidden email-body-receipients">
                                    {`${ message.sender?.firstName} ${ message.sender?.lastName}`}
                                </div>
                            </Tooltip>
                            <Tooltip title={ message.sender.email}>
                                <div className="overflow-hidden">{ message.sender.email}</div>
                            </Tooltip>
                        </Col>
                    
                    </>
            

                )
            }


        }else{
            // For group or broadcast messages
          return (
              <>
                  <Col>
                      
                      <Avatar.Group
                          maxCount={2}
                          maxStyle={{ color: "#FF8838", backgroundColor: "#fde3cf" }}
                      >
                          <Tooltip
                              title={`${message?.sender?.firstName} ${message?.sender?.lastName}`}
                          >
                              <Avatar
                                  size={40}
                                  src={
                                      message
                                          ? message.sender.userImage?.url
                                          : getDefaultImage(imageType.trainer)
                                  }
                              />
                          </Tooltip>
                      </Avatar.Group>
                  </Col>
                  <Col>
                      <Tooltip title={formatRecipients()}>
                          <div className="overflow-hidden email-body-receipients">
                              {formatRecipients()}
                          </div>
                      </Tooltip>
                      <Tooltip title={formatEmails()}>
                          <div className="overflow-hidden">{formatEmails()}</div>
                      </Tooltip>
                  </Col>
              </>
          ); 
        }
   }

    return (
        <div className="eq-MessageReply">
            {/* Display message details */}
            <Row >
                <Col span={24}>
                    <Row gutter={8} className="row-one">
                    {renderChatHeading()}
                    </Row>
            
                      

              
                    {/* Display message content */}
                    <Row justify="space-between">
                                        <Col span={22}>
                                            <Row gutter={8}>
                                                <Col>
                                                    <Avatar
                                                        size={40}
                                                        src={
                                                            message.sender?.userImage?.url ||
                                                            getDefaultImage(imageType.trainer)
                                                        }
                                                    />
                                                </Col>
                                                <Col>
                                                    <strong>
                                                        {message.sender?.firstName} {message.sender?.lastName}
                                                    </strong>
                                                    <div>{message.sender ? message.sender.email : ""}</div>
                                                </Col>
                                            </Row>
                                            <Row className="reply-text">
                                                <Col span={23} offset={1}>
                                                    <span className="msg-reply"> {message.message} </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={2}>
                                            {/* <FontAwesomeIcon icon={faReply} onClick={handleToggleReplies} /> */}
                                            <span className="msg-time"> {moment(message.sentOn).format("HH:mm")} </span>
                                        </Col>
                    </Row>



                </Col>

                {/* <Col span={2}>
                    <span className="msg-time"> {moment(message.createOn).format("HH:mm")}</span>
                </Col> */}
            </Row>



   {/* Existing replies */}
   {showReplies && message.messageReplies.length > 0 && (
                <div className="existing-replies">
                    {message.messageReplies
                    .sort((a:any, b:any) => a.createdOn - b.createdOn)
                    .map((reply: any) => (
                        <div key={reply.id} className="reply-item">
                            <Row justify="space-between">
                                <Col span={22}>
                                    <Row gutter={8}>
                                        <Col>
                                            <Avatar
                                                size={40}
                                                src={reply.sender?.userImage?.url || getDefaultImage(imageType.trainer)}
                                            />
                                        </Col>
                                        <Col>
                                            <strong>{`${reply.sender?.firstName} ${reply.sender?.lastName}`}</strong>
                                            <div>{reply.sender?.email || ""}</div>
                                        </Col>
                                    </Row>
                                    <Row className="reply-text">
                                        <Col span={23} offset={1}>
                                            <span className="msg-reply">{reply.reply}</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={2}>
                                    <span className="msg-time">{moment(reply.sentOn).format("HH:mm")}</span>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>
            )}
  {/* Reply box */}
<div className="reply-box">
                        <Row >
                            <Col span={2}>
                                <Avatar
                                    size={40}
                                    src={
                                        userDetails?.imageUrl ||
                                        getDefaultImage(imageType.trainer)
                                    }
                                />
                            </Col>
                            <Col span={20} className="reply-input-container">
                                <span><FontAwesomeIcon icon={faReply} /> {currentUserEmail}</span>

                                <Input.Group compact>
                                    <EqInput
                                        className="reply-textarea"
                                        style={{ border: "none" }}
                                        placeholder={t("typeReply")}
                                        value={replyText}
                                        onChange={(e: any) => setReplyText(e.target.value)}

                                    />
                                    <EqButton type="primary" onClick={handleReply}>
                                        {t("reply")}
                                    </EqButton>
                                </Input.Group>
                            </Col>
                        </Row>
                    </div>
         
        </div>
    );
};

export default EqMessageReply;
