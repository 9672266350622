import React, { useState, useEffect } from "react";
import EqStudentList, { IEqStudentList } from "../eqStudentList/EqStudentList";
import EqJoiningRequestList, {
  IEqJoiningRequestList,
} from "../eqJoiningRequestList/EqJoiningRequestList";
import EqSentInvitesList, {
  IEqSentInvitesList,
} from "../eqSentInvitesList/EqSentInvitesList";
import "./EqTrainerStudentsBoard.scss";
import {
  paymentStatus,
  paymentStatusType,
  EqLocationType,
} from "../../shared/enums";
import { Row, Col, message } from "antd";
import { Input } from "antd";
import EqSelect from "../../custom/eqSelect/EqSelect";
import EqButton from "../../custom/eqButton/EqButton";
import EqInviteStudent from "../eqInviteStudent/EqInviteStudent";
import { useTranslation, Trans } from "react-i18next";
import Api from "../../shared/Api";
import {
  EqStatusCode,
  GetInvitesMessage,
  ApiEndpoints,
  GetSentInviteMessage,
} from "../../shared/Common";
import { ISelectProps } from "../../shared/Interfaces";
import { IEqSentInvites } from "../eqSentInvites/EqSentInvites";
import { IEqJoiningRequest } from "../eqJoiningRequest/EqJoiningRequest";
import CopyToClipboard from "react-copy-to-clipboard";
import config from "../../shared/Config";
import { EqInput } from "../../custom/eqInput/EqInput";
import { CopyFilled } from "@ant-design/icons";
import { useAuthState } from "../../context/Context";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";

const EqTrainerStudentsBoard = () => {
  const userDetails: any = useAuthState();
  const { t, i18n } = useTranslation();
  const { Search } = Input;
  const [addNew, setAddNew] = useState(false);
  const [reqisCheckAll, reqsetIsCheckAll] = useState(false);
  const [inisCheckAll, insetIsCheckAll] = useState(false);
  const [requestslist, setrequestsList] = React.useState([]) as any;
  const [inviteslist, setInvitesList] = React.useState([]) as any;
  const [currentStudentslist, setCurrentStudentsList] = React.useState({
    allStudents: [],
    filteredStudents: [],
  }) as any;
  const [messageString, setMessageString] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [requestKeys, setRequestKeys] = useState(new Array<any>());
  const [inviteKeys, setInviteKeys] = useState(new Array<any>());
  const [paymentStatus, setPaymentStatus] = React.useState([] as any);
  const [search, setSearch] = React.useState({
    searchVal: "",
    paymentFilter: [] as any,
  });

  const [messageApi, contextHolder] = message.useMessage();

  var selectedRequests = new Array<any>();
  var selectedInvites = new Array<any>();

  var sortedList: Array<any> | undefined;

  const getPaymentStatusClass = (status: any): string => {
    if (status == paymentStatusType.Paid) return "green";
    else if (status == paymentStatusType.UnpaidButAttending) return "red";
    else if (status == paymentStatusType.StudentCancelled) return "purple";
    else return "orange";
  };

  const onRequestCheckChange = (data: any, checked: boolean) => {
    if (checked) {
      selectedRequests.push({ Key: data.key });
    } else {
      if (selectedRequests != null && selectedRequests.length > 0) {
        const index = selectedRequests.findIndex((x) => x.Key == data.key);
        if (index > -1) {
          selectedRequests.splice(index, 1);
        }
      }
    }
    console.log(selectedRequests);
    setRequestKeys(selectedRequests);
  };
  const requestsCheckAll = (list: any, checked: boolean) => {
    //console.log(list);
    reqsetIsCheckAll(!reqisCheckAll);
    list.map((m: IEqJoiningRequest) => {
      if (m.selected == false) {
        m.selected = !checked;
        selectedRequests.push({
          Key: m.request.key,
          Email: m.request.email,
        });
      } else {
        m.selected = !checked;
        if (selectedRequests != null && selectedRequests.length > 0) {
          const index = selectedInvites.findIndex(
            (x) => x.Key == m.request.key
          );
          if (index > -1) {
            selectedRequests.splice(index, 1);
          }
        }
      }
      setRequestKeys(selectedRequests);
    });
    //console.log(selectedRequests);
    const modifiedList = [...list];
    setrequestsList(modifiedList);
  };
  const deleteSelectedRequest = () => {
    reqsetIsCheckAll(false);
    console.log("clear request", requestKeys);
    if (requestKeys.length > 0) {
      Api.post(ApiEndpoints.deleteSelectedRequest, requestKeys).then(
        (response) => {
          if (response && response.status == 200) {
            GetPendingRequests();
            setMessageString("success");
          } else {
            setMessageString("failed");
          }
        },
        (err) => {
          setMessageString("failed");
        }
      );
    }
  };
  const invitesCheckAll = (list: any, checked: boolean) => {
    //console.log(list);
    insetIsCheckAll(!inisCheckAll);

    list.map((m: IEqSentInvites) => {
      if (m.selected == false) {
        m.selected = !checked;
        selectedInvites.push({ Key: m.student.key, Email: m.student.email });
      } else {
        m.selected = !checked;
        if (selectedInvites != null && selectedInvites.length > 0) {
          const index = selectedInvites.findIndex(
            (x) => x.Key == m.student.key
          );
          if (index > -1) {
            selectedInvites.splice(index, 1);
          }
        }
      }
      setInviteKeys(selectedInvites);
    });
    console.log(selectedInvites);
    const modifiedList = [...list];
    setInvitesList(modifiedList);
  };
  const onInviteCheckChange = (data: any, checked: boolean) => {
    if (checked) {
      selectedInvites.push({ Key: data.key, Email: data.email });
    } else {
      if (selectedInvites != null && selectedInvites.length > 0) {
        const index = selectedInvites.findIndex((x) => x.Key == data.key);
        if (index > -1) {
          selectedInvites.splice(index, 1);
        }
      }
    }
    console.log(selectedInvites);
    setInviteKeys(selectedInvites);
  };
  const deleteSelectedInvitation = () => {
    //console.log(inviteKeys);
    insetIsCheckAll(false);
    if (inviteKeys.length > 0) {
      Api.post(ApiEndpoints.deleteSelectedInvitation, inviteKeys).then(
        (response) => {
          if (response && response.status == 200) {
            GetInvitations();
            setMessageString("success");
          } else {
            setMessageString("failed");
          }
        },
        (err) => {
          setMessageString("failed");
        }
      );
    }
  };
  const showAddNew = () => {
    setAddNew(true);
  };
  const cancelFunction = (e: any) => {
    setAddNew(false);
  };
  const SendInviteClicked = (success: boolean) => {
    if (success == true) {
      GetInvitations();
    }
  };
  const onSortChange = (event: any) => {
    console.log(currentStudentslist.filteredStudents);
    switch (event) {
      case "Sort A-Z":
        sortedList = [...currentStudentslist.filteredStudents].sort(
          (obj1, obj2) => {
            if (
              obj1.student.userName.toLowerCase() >
              obj2.student.userName.toLowerCase()
            ) {
              return 1;
            } else if (
              obj1.student.userName.toLowerCase() <
              obj2.student.userName.toLowerCase()
            ) {
              return -1;
            } else {
              return 0;
            }
          }
        );
        setCurrentStudentsList({
          ...currentStudentslist,
          filteredStudents: sortedList,
        });
        break;
      case "Sort Z-A":
        sortedList = [...currentStudentslist.filteredStudents].sort(
          (obj1, obj2) => {
            if (
              obj1.student.userName.toLowerCase() <
              obj2.student.userName.toLowerCase()
            ) {
              return 1;
            } else if (
              obj1.student.userName.toLowerCase() >
              obj2.student.userName.toLowerCase()
            ) {
              return -1;
            } else {
              return 0;
            }
          }
        );
        setCurrentStudentsList({
          ...currentStudentslist,
          filteredStudents: sortedList,
        });
        break;
    }
  };
  const onRequestsSortChange = (event: any) => {
    switch (event) {
      case "Sort A-Z":
        sortedList = [...requestslist].sort((obj1, obj2) => {
          if (
            obj1.request.userName.toLowerCase() >
            obj2.request.userName.toLowerCase()
          ) {
            return 1;
          } else if (
            obj1.request.userName.toLowerCase() <
            obj2.request.userName.toLowerCase()
          ) {
            return -1;
          } else {
            return 0;
          }
        });
        setrequestsList(sortedList);
        break;
      case "Sort Z-A":
        sortedList = [...requestslist].sort((obj1, obj2) => {
          if (
            obj1.request.userName.toLowerCase() <
            obj2.request.userName.toLowerCase()
          ) {
            return 1;
          } else if (
            obj1.request.userName.toLowerCase() >
            obj2.request.userName.toLowerCase()
          ) {
            return -1;
          } else {
            return 0;
          }
        });
        setrequestsList(sortedList);
        break;
    }
  };
  const onInvitesSortChange = (event: any) => {

    switch (event) {
      case "Sort A-Z":
        sortedList = [...inviteslist].sort((obj1, obj2) => {
          if (
            obj1.student.userName.toLowerCase() > obj2.student.userName.toLowerCase()
          ) {
            return 1;
          } else if (
            obj1.student.userName.toLowerCase() < obj2.student.userName.toLowerCase()
          ) {
            return -1;
          } else {
            return 0;
          }
        });
        setInvitesList(sortedList);
        break;
      case "Sort Z-A":
        sortedList = [...inviteslist].sort((obj1, obj2) => {
          if (
            obj1.student.userName.toLowerCase() < obj2.student.userName.toLowerCase()
          ) {
            return 1;
          } else if (
            obj1.student.userName.toLowerCase() > obj2.student.userName.toLowerCase()
          ) {
            return -1;
          } else {
            return 0;
          }
        });
        setInvitesList(sortedList);
        break;
    }
  };
  const acceptJoiningRequest = (student: any) => {
    Api.post(ApiEndpoints.acceptJoiningRequest, student).then(
      (response) => {
        if (response && response.status == 200) {
          GetPendingRequests();
          GetCurrentStudents();
          return true;
        } else {
          return false;
        }
      },
      (err) => {
        return false;
      }
    );
  };
  const deleteInvitation = (student: any): boolean => {

    Api.post(ApiEndpoints.deleteInvitation, student).then(
      (response) => {
        if (response && response.status == 200) {
          GetInvitations();
          return true;
        } else {
          return false;
        }
      },
      (err) => {
        return false;
      }
    );
    return false;
  };
  const deleteRequest = (student: any): boolean => {
    setrequestsList([] as any);
    Api.post(ApiEndpoints.deleteRequest, student).then(
      (response) => {
        if (response && response.status == 200) {
          GetPendingRequests();
          return true;
        } else {
          return false;
        }
      },
      (err) => {
        return false;
      }
    );
    return false;
  };
  const deleteStudent = (student: any) => {

    Api.post(ApiEndpoints.deleteStudent, student).then(
      (response) => {
        if (response && response.status == 200) {
          GetCurrentStudents();
          return true;
        } else {
          return false;
        }
      },
      (err) => {
        return false;
      }
    );
  };

  const searchClick = (searchStr: string) => {
    setSearch({ ...search, searchVal: searchStr });
    filterStudents(searchStr, search.paymentFilter);
  };
  const filterStudents = (searchFilter: string, paymentFilter: any) => {
    //console.log(searchFilter);
    //console.log(paymentFilter);

    var filterResult = currentStudentslist.allStudents;
    if (searchFilter != "" && searchFilter != null && searchFilter.length > 0) {
      filterResult = filterResult.filter((g: any) => {
        return g?.student?.userName.toLowerCase().includes(searchStr.toLowerCase()) ||
          (g?.student?.firstName + " " + g?.student?.lastName + " ").toLowerCase().includes(searchStr.toLowerCase());
      });
    }
    if (paymentFilter != null && paymentFilter.length > 0) {
      filterResult = filterResult.filter((g: any) => {
        let res = paymentFilter.some((status: any) => {
          return status == g?.student?.payment;
        });
        return res;
      });
    }
    setCurrentStudentsList({
      ...currentStudentslist,
      filteredStudents: filterResult,
    });
  };
  const studentInviteProps = {
    header: t("Invite-Student"),
    emailHeading: "Please add your student email address",
    messageHeading: "Message",
    cancelFunc: cancelFunction,
    sendFunc: SendInviteClicked,
  };
  const filterProps: ISelectProps = {
    mode: "multiple",
    classNames: "eq-multiSelect",
    border: false,
    dropdownClassName: "eq-select-dropdown",
    isTag: true,
    ddClassName: "eq-select-dropdown",
    icon: [
      <span className="eq-fb-filter">
        <img alt="" src="/Filter.svg" />
      </span>,
    ],
    selectedIcon: [
      <span className="eq-fb-filter">
        <img alt="" src="/check-square.svg" />
      </span>,
    ],
    options: paymentStatus,
    changeFunc: (value: any) => {
      console.log(value);
      setSearch({ ...search, paymentFilter: value });
      filterStudents(search.searchVal, value);
    },
  };
  const invitesSortProps: ISelectProps = {
    border: false,
    allowClear: false,
    classNames: "eq-select-sort",
    defaultSelectedValue: "Sort A-Z",
    changeFunc: onInvitesSortChange,
    options: [
      { key: "Sort A-Z", value: "Sort A-Z" },
      { key: "Sort Z-A", value: "Sort Z-A" },
    ],
  };
  const requestsSortProps: ISelectProps = {
    border: false,
    allowClear: false,
    classNames: "eq-select-sort",
    defaultSelectedValue: "Sort A-Z",
    changeFunc: onRequestsSortChange,
    options: [
      { key: "Sort A-Z", value: "Sort A-Z" },
      { key: "Sort Z-A", value: "Sort Z-A" },
    ],
  };
  const studentSortProps: ISelectProps = {
    border: false,
    allowClear: false,
    classNames: "eq-select-sort",
    defaultSelectedValue: "Sort A-Z",
    changeFunc: onSortChange,
    options: [
      { key: "Sort A-Z", value: "Sort A-Z" },
      { key: "Sort Z-A", value: "Sort Z-A" },
    ],
  };

  var studentsList: IEqStudentList = {
    studentsList: currentStudentslist.filteredStudents,
  };

  var requestList: IEqJoiningRequestList = {
    joiningRequestList: requestslist,
    checkAllFun: requestsCheckAll,
    checkAll: reqisCheckAll,
  };
  var invitesList: IEqSentInvitesList = {
    sentInvitesList: inviteslist,
    checkAllFun: invitesCheckAll,
    checkAll: inisCheckAll,
  };

  useEffect(() => {
    GetInvitations();
    GetPendingRequests();
    GetCurrentStudents();
    createPaymentStatusTypes();
  }, []);

  const GetInvitations = () => {
    setMessageString("");
    setInvitesList([] as any);
    Api.get(ApiEndpoints.getInvites).then(
      (response) => {
        var students = [] as any;
        //console.log(response);
        if (response && response.status == 200) {
          var invitedStudents = response.data?.result
            ? [...response.data.result]
            : ([] as any);
          invitedStudents.map((d: any) => {
            students.push({
              student: {
                key: d.key,
                firstName: d.firstName,
                lastName: d?.lastName,
                userName: d?.userName,
                email: d.email,
                locations: d.locations
                  ?.filter(
                    (opt: any) => opt.location?.type == EqLocationType.Home
                  )
                  .shift(),
                payment: d.paymentStatus,
                groups: d.groups,
                selected: false,
              },
              deleteFunc: deleteInvitation,
              checkChangeFunc: onInviteCheckChange,
              selected: false,
            });
          });
          setInvitesList(students);
        } else {
          setMessageString(GetInvitesMessage(EqStatusCode.serverException502));
        }
      },
      (err) => {
        setMessageString(GetInvitesMessage(err.response.status));
      }
    );
  };
  const GetPendingRequests = () => {
    setMessageString("");
    setrequestsList([] as any);
    Api.get(ApiEndpoints.getRequests).then(
      (response) => {
        var requests = [] as any;
        if (response && response.status == 200) {
          var pendingRequests = response.data
            ? [...response.data]
            : ([] as any);
          pendingRequests.map((d: any) => {
            //console.log(d);
            requests.push({
              request: {
                key: d.user?.key,
                firstName: d.user?.firstName,
                lastName: d.user?.lastName,
                userName: d.user?.userName,
                email: d.user?.email,
                locations: d.user?.locations
                  ?.filter(
                    (opt: any) => opt.location?.type == EqLocationType.Home
                  )
                  .shift(),
                payment: d.user?.paymentStatus,
                groups: d.user?.groups,
                selected: false,
              },
              deleteFunc: deleteRequest,
              acceptFunc: acceptJoiningRequest,
              checkChangeFunc: onRequestCheckChange,
              selected: false,
            });
          });
          setrequestsList(requests);
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };


  // current implementation
  // Getting Current Students and mapping to add selected and Delete Function to student Object
  const GetCurrentStudents = () => {
    setMessageString("");
    setCurrentStudentsList([] as any);
    var students = [] as any;
    Api.get(ApiEndpoints.TrainerStudents.CurrentStudents).then(
      (response) => {
        if (response && response.status == 200) {
           
          var data = response.data ? [...response.data] : ([] as any);

          data.map((d: any) => {
            students.push({
              student: {
                firstName: d?.firstName,
                lastName: d?.lastName,
                userName: d?.userName,
                phoneNumber: d?.phoneNumber,
                email: d?.email,
                locations: d?.locations
                  ?.filter(
                    (opt: any) => opt.location?.type == EqLocationType.Home
                  )
                  .shift(),
                payment: d?.paymentStatus,
                groups: d?.groups,
                selected: false,
              },
              deleteFunc: deleteStudent,
              selected: false,
            });
          });
          setCurrentStudentsList({
            allStudents: [...students],
            filteredStudents: [...students],
          });
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };
  const createPaymentStatusTypes = () => {
    var types = [];

    for (var x in paymentStatusType) {
      if (isNaN(Number(x)) == false) {
        types.push({
          key: x,
          value: getPaymentStatusClass(x),
          label: paymentStatusType[x],
        });
      }
    }
    setPaymentStatus(types);
    //console.log(types);
  };
  const handleCopyLink = () => {
    messageApi.open({
      type: "success",
      content: "Copied",
    });
  };

  return (
    <>
      <AnimationDiv>

        <div className="eq-sb-container">
          {/* First Row With Filters Title */}
          <Row className="eq-sb-heading-row">
            <Col className="eq-sb-col" flex="auto" sm={5} xs={4}>
              <h2 className="eq-sb-heading">{t("students")}</h2>
            </Col>
            <Col className="eq-sb-col" flex="auto" sm={19} xs={20}>
              <Row className="eq-sb-student-header" justify="end" align="middle">
                <Col className="eq-sb-followers hidden-sm">
                  {t("followers")} {currentStudentslist.allStudents?.length}
                </Col>
                <Col className="eq-sb-student-sort hidden-sm">
                  <EqSelect key={1} {...studentSortProps}></EqSelect>
                </Col>
                <Col
                  className="eq-sb-student-search"
                  xs={14}
                  sm={14}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <Search
                    placeholder={t("inputSearchText")}
                    onChange={(e: any) => setSearchStr(e.target.value)}
                    onSearch={searchClick}
                  />
                </Col>
                <Col>
                  {/* <div className="eq-sb-student-btn">
                                    <EqButton type="button" bordered="true" classNames="btn-border-text-orange">
                                        <span className="hidden-md"><img alt="" src="/filter-black.svg" /></span>
                                        <span className="hidden-sm">Filters</span></EqButton>
                                </div> */}
                </Col>
                <Col>
                  <div className="eq-sb-student-btn">
                    <EqButton
                      type="button"
                      onClick={showAddNew}
                      classNames="btn-background-orange"
                    >
                      <img alt="" src="/plus.svg" />
                      <span className="hidden-sm">
                        <span className="icon-space"></span>{t("addNew")}
                      </span>
                    </EqButton>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Send New invite to Student  */}
          <div>
            {addNew && (
              <EqInviteStudent {...studentInviteProps}></EqInviteStudent>
            )}
          </div>

          {/* Only for mobile View */}
          <Row className="eq-sb-list-header" justify="center" align="middle">
            <Col className="hidden-md" xs={24} sm={24}>
              <div className="eq-sb-followers">
                {t("followers")}  {currentStudentslist.allStudents?.length}
              </div>
            </Col>
            <Col className="hidden-md" xs={7} sm={6}>
              <div className="eq-sb-student-sort">
                <EqSelect key={2} {...studentSortProps}></EqSelect>
              </div>
            </Col>
            <Col xs={24} sm={24}>
              <Row justify="center">
                {/* <Col>
                                <div className="eq-sb-student-btn hidden-md">
                                    <EqButton type="button" bordered="true">
                                        <span className="">Filters</span></EqButton>
                                </div>
                            </Col> */}
                {/* <Col>
                                <div className="eq-sb-student-btn hidden-md">
                                    <EqButton type="button" onClick={showAddNew} classNames="btn-background-orange">
                                        <span className="eq-btn-icon-text"><span className="eq-btn-icon"><img alt="" src="/plus.svg" /></span>
                                       {t("addNew")}</span></EqButton>
                                </div>
                            </Col> */}
              </Row>
            </Col>
          </Row>

          {/* filter On Payment Status */}
          <Row align="middle" justify="space-between">
            {contextHolder}
            <Col className="eq-copy-link">
              <Row align="middle">
                <Col className="section-heading">{t("studentInvite")}</Col>
                <Col  >
                  {" "}
                  <CopyToClipboard
                    text={
                      config.apiUrl +
                      `newUser?trainerKey=${userDetails.key}&profession=3`
                    }
                    onCopy={handleCopyLink}
                  >
                    <EqInput
                      classNames="eq-input-copy"
                      readOnly
                      //  disabled
                      value={
                        config.apiUrl +
                        `newUser?trainerKey=${userDetails.key}&profession=3`
                      }
                      suffix={
                        <CopyToClipboard
                          text={
                            config.apiUrl +
                            `newUser?trainerKey=${userDetails.key}&profession=3`
                          }
                          onCopy={handleCopyLink}
                        >
                          <CopyFilled />
                        </CopyToClipboard>
                      }
                    />
                  </CopyToClipboard>
                </Col>
              </Row>
            </Col>

            <Col className="eq-sb-select hidden-sm" span={12}>
              <EqSelect key={3} {...filterProps}></EqSelect>
            </Col>
          </Row>

          {/* List of joined Students */}
          {/* Passing Filtered Students */}
          <div className="">
            <EqStudentList {...studentsList}></EqStudentList>
          </div>


          <Row className="eq-sb-heading-row">
            <Col className="eq-sb-heading" flex="auto">
              {t("joiningRequests")}
            </Col>
            <Col className="eq-sb-col hidden-sm">
              <Row className="eq-sb-student-header" justify="end" align="bottom">
                <Col className="eq-sb-followers">
                  {t("totalRequests")} {requestslist.length}
                </Col>
                <Col className="eq-sb-student-sort">
                  <EqSelect key={4} {...requestsSortProps}></EqSelect>
                </Col>
                {/* <Col>
                                <div className="eq-sb-student-btn">
                                    <EqButton type="button" bordered="true" classNames="btn-light-gray">
                                        Filters</EqButton>
                                </div>
                            </Col> */}
                <Col>
                  <div className="eq-sb-student-btn">
                    <EqButton
                      type="button"
                      bordered="true"
                      classNames="btn-border-orange"
                      onClick={deleteSelectedRequest}
                    >
                      <img alt="" src="/text.svg" />
                      <span className="icon-space"></span>
                      {t("clearRequest")}
                    </EqButton>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="eq-sb-list-header" justify="center" align="middle">
            <Col className="hidden-md" xs={24} sm={24}>
              <div className="eq-sb-followers">
                {t("totalRequests")} {requestslist.length}
              </div>
            </Col>
            <Col className="hidden-md" xs={7} sm={6}>
              <div className="eq-sb-student-sort">
                <EqSelect key={5} {...requestsSortProps}></EqSelect>
              </div>
            </Col>
            <Col xs={24} sm={24}>
              <Row justify="center">
                {/* <Col>
                                <div className="eq-sb-student-btn hidden-md">
                                    <EqButton type="button" bordered="true">
                                        <span className="">Filters</span></EqButton>
                                </div>
                            </Col> */}
                <Col>
                  <div className="eq-sb-student-btn hidden-md">
                    <EqButton
                      type="button"
                      bordered="true"
                      classNames="btn-border-orange"
                      onClick={deleteSelectedRequest}
                    >
                      <img alt="" src="/text.svg" />
                      <span className="icon-space"></span>
                      {t("clearRequest")}
                    </EqButton>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/*<Row>*/}
          {/*    <Col className="eq-sb-select hidden-sm" span={24}>*/}
          {/*        <EqSelect key={1} {...filterProps}></EqSelect>*/}
          {/*    </Col>*/}
          {/*</Row>*/}
          <div className="">
            <EqJoiningRequestList {...requestList}></EqJoiningRequestList>
          </div>

          <Row className="eq-sb-heading-row">
            <Col className="eq-sb-heading" flex="auto">
              {t("sentInvites")}
            </Col>
            <Col className="eq-sb-col hidden-sm" flex="auto">
              <Row className="eq-sb-student-header" justify="end" align="bottom">
                <Col className="eq-sb-followers">
                  {t("sentInvites")} {inviteslist.length}
                </Col>
                <Col className="eq-sb-student-header-right">
                  <div className="eq-sb-student-sort">
                    <EqSelect key={6} {...invitesSortProps}></EqSelect>
                  </div>
                </Col>
                {/* <Col>
                                <div className="eq-sb-student-btn">
                                    <EqButton type="button" bordered="true" classNames="btn-light-gray">
                                        Filters</EqButton>
                                </div>
                            </Col> */}
                <Col>
                  <div className="eq-sb-student-btn">
                    <EqButton
                      type="button"
                      bordered="true"
                      classNames="btn-border-orange"
                      onClick={deleteSelectedInvitation}
                    >
                      <span className="eq-btn-icon">
                        <img alt="" src="/text.svg" />
                      </span>
                      {t("clearInvites")}
                    </EqButton>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="eq-sb-list-header" justify="center" align="middle">
            <Col className="hidden-md" xs={24} sm={24}>
              <div className="eq-sb-followers">
                {t("sentInvites")} {inviteslist.length}
              </div>
            </Col>
            <Col className="hidden-md" xs={7} sm={6}>
              <div className="eq-sb-student-sort">
                <EqSelect key={7} {...invitesSortProps}></EqSelect>
              </div>
            </Col>
            <Col xs={24} sm={24}>
              <Row justify="center">
                {/* <Col>
                                <div className="eq-sb-student-btn hidden-md">
                                    <EqButton type="button" bordered="true">
                                        <span className="">Filters</span></EqButton>
                                </div>
                            </Col> */}
                <Col>
                  <div
                    className="eq-sb-student-btn hidden-md"
                    onClick={deleteSelectedInvitation}
                  >
                    <EqButton
                      type="button"
                      bordered="true"
                      classNames="btn-border-orange"
                    >
                      <img alt="" src="/text.svg" />
                      <span className="icon-space"></span>
                      {t("clearInvites")}
                    </EqButton>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/*<Row>*/}
          {/*    <Col className="eq-sb-select hidden-sm" span={24}>*/}
          {/*        <EqSelect key={1} {...filterProps}></EqSelect>*/}
          {/*    </Col>*/}
          {/*</Row>*/}
          <div className="">
            <EqSentInvitesList {...invitesList}></EqSentInvitesList>
          </div>
        </div>

      </AnimationDiv>
    </>
  );
};
export default EqTrainerStudentsBoard;
