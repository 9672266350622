import * as React from "react";
import ReactDOM from "react-dom";
import "./CreateOrEditEvent.scss";
import {
  Row,
  Col,
  Switch,
  Tag,
  Input,
  Card,
  Divider,
  Space,
  Typography,
  Upload,
  Button,
  Tabs,
  Modal,
  message,
  Spin,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useAuthDispatch, useAuthState } from "../../../context/Context";
import {
  ApiEndpoints,
  covertMillisecondToStringDate,
  dateFormat,
  DateWithZeroTime,
  FrontEndPoints,
  GetEventMessage,
} from "../../../shared/Common";
import { loadState, saveState } from "../../../shared/LocalStorage";

import EqButton from "../../../custom/eqButton/EqButton";
import { EqInput, EqInputNumber } from "../../../custom/eqInput/EqInput";
import EqSelect from "../../../custom/eqSelect/EqSelect";
import EqGroupMembersList from "../../eqGroupMembersList/EqGroupMembersList";
import EqDatePicker, {
  IDatePickerOptions,
} from "../../../custom/eqDatePicker/EqDatePicker";
import Api from "../../../shared/Api";
import history from "../../../shared/history";
import {
  IClassTimes,
  ICreateEventDto,
  IEventClassDto,
} from "../../../shared/interfaces/ICreateEventDto";
import { IStudentGroupDto } from "../../../shared/interfaces/IStudentGroupDto";
import {
  EqDisciplineType,
  EqEventRepeatType,
  EqEventAccessType,
  eqNotificationTypes,
  EqUserType,
  EqEventHorseAvailabilityStatus,
  EqCurrency,
  QuestionType,
} from "../../../shared/enums";
import { ISelectProps } from "../../../shared/Interfaces";
import { notification } from "antd";
import EqEventClassCard, {
  IEqEventClassCardData,
} from "./eqEventClassCard/eqEventClassCard";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import EqCollapse, { IEqCollapse } from "../../../custom/eqCollapse/EqCollapse";
import { updateActivityData } from "../../../context/Actions";
import {
  DeleteOutlined,
  PlusOutlined,
  ToolFilled,
  UploadOutlined,
} from "@ant-design/icons";
import Item from "antd/lib/list/Item";
import EqUpload from "../../../custom/eqUpload/EqUpload";
import EqMultiDatePicker, {
  IMultiDatePickerOptions,
} from "../../../custom/eqMultiDatePicker/EqMultiDatePicker";
import config from "../../../shared/Config";
import TabPane from "antd/lib/tabs/TabPane";
import EqEventPdfGenerate from "../../EventPdfGenerate/EqEventPdfGenerate";
import EventClass from "../../eqEventsView/EqEventsView";
import EqJoinEvent from "../../../eqStudent/eqEventJoin/EqJoinEvent";
import CopyToClipboard from "react-copy-to-clipboard";
import { useState } from "react";
import EqQuestionModal from "./eqQuestionModal/EqQuestionModal";
import EqTags from "../../../custom/eqTags/EqTags";
import EqEventPdfGenerateListView from "../../EventPdfGenerateListView/EqEventPdfGenerateListView";
// import EqEventClassCard from "./eqEventClassCard/eqEventClassCard";

const newEmptyCardData: IEqEventClassCardData = {
  key: 0,
  cardkey: 1,
  timeFrom: moment(),
  height: "",
  classDescription: "",
  // timeTo: moment(),
  // duration: 30,
  maxSpaceCount: 1,
  price: "",
  // trainingLevel: "",
  attendees: [] as any,
  attendeesKeys: [] as any,
  display: true,
};

export interface ICreateOrEditEvent {
  imagesData: any;
  areas: any;
  weekDaysData: any;
  population: any;
  trainerStudents?: any;
  studentHorsesList?: any;
  groupPopulation: any;
  eventTypes: any;
  eventKey: any;
  eventData: any;
  onCreateOrUpdateActivity?: any;
  isStudent: boolean;
  onActivityTitleChange: any;
  onCancelActivity?: any;
  onSaveSetBackButton: any;
  // trainingBackButton:any;
  isTrainingSaved: any;
  currencyOptions: any;
}

const CreateOrEditEvent = ({
  imagesData,
  areas,
  weekDaysData,
  population,
  trainerStudents,
  studentHorsesList,
  groupPopulation,
  eventTypes,
  eventKey,
  eventData,
  onCreateOrUpdateActivity,
  isStudent,
  onActivityTitleChange,
  onCancelActivity,
  onSaveSetBackButton,
  isTrainingSaved,
  currencyOptions,
}: // trainingBackButton,
ICreateOrEditEvent) => {
  const componentRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useAuthDispatch();
  const userDetails: any = useAuthState();
  const localData = loadState();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();
  const [spinning, setSpinning] = React.useState<boolean>(false);

  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [errMessage, setErrMessage] = React.useState("");
  const [value, setValue] = React.useState(1);
  const [studentsList, setStudentsList] = React.useState([]) as any;
  const [groupStudentsList, setGroupStudentsList] = React.useState([]) as any;
  const [uiImage, setUiImage] = React.useState() as any;
  const [horseAvailability, setHorseAvailability] = React.useState([]) as any;
  const [fileLoading, setFileLoading] = React.useState(false);
  const [fileObj, setFileObj] = React.useState<any | undefined>(undefined);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [pdfVisible, setPdfVisible] = React.useState(false);
  const [pdfListViewVisible, setPdfListViewVisible] = React.useState(false);
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [groupStudentPopulation, setGroupStudentPopulation] = React.useState(
    [] as any
  );
  const [studentsByGroup, setStudentsByGroup] = React.useState([] as any);
  const [studentPopulation, setStudentPopulation] = React.useState({
    allStudents: [] as any,
    filteredStudents: [] as any,
  });
  // const [areas, setAreas] = React.useState([]) as any;
  // const [population, setPopulation] = React.useState([]) as any;
  // const [groupPopulation, setGroupPopulation] = React.useState([]) as any;
  const [searchedAttendees, setSearchedAttendees] = React.useState([]) as any;
  const [eventAttendees, setEventAttendees] = React.useState<
    Array<IStudentGroupDto>
  >([]);
  const [searchStudentsList, setSearchStudentsList] = React.useState([] as any);

  const [eventDurations, setEventDurations] = React.useState([]) as any;
  const [eventRepeats, setEventRepeats] = React.useState([]) as any;
  // const [eventTypes, setEventTypes] = React.useState({
  //   privateDiscipline: [],
  //   publicDiscipline: [],
  //   editOpenDiscipline: [],
  //   toggleOptions: [],
  // } as any);

  const [eventAccessTypes, setEventAccessTypes] = React.useState({
    allAccessTypes: [],
    privateOnly: [],
    toggleOptions: [],
  } as any);
  const [trainingData, setTrainingData] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState({
    allWeekDays: [],
    selectedWeekDays: [],
    weekDayTags: [],
  } as any);
  // const [eventKey, setEventKey] = React.useState(0);
  const [memberSearch, setMemberSearch] = React.useState([] as any);
  const [selectedGroups, setSelectedGroups] = React.useState([] as any);
  const [groupKeys, setGroupKeys] = React.useState([] as any);
  const [isMultipleDateSelected, setIsMultipleDateSelected] =
    React.useState(false);
  // const [isPublic, setIsPublic] = React.useState(false);
  const [isOpenEvent, setIsOpenEvent] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isPaymentDeadline, setIsPaymentDeadline] = React.useState(true);
  const [selectedLoc, setSelectedLoc] = React.useState("");
  const [isEventCollapse, setIsEventCollapse] = React.useState(true);

  // const [isTrainingSaved, setIsTrainingSaved] = React.useState(false);
  //for private event

  const [multipleStartOn, setMultipleStartOn] = React.useState([] as any);
  // const [cardDateOptions, setCardDateOptions] = React.useState([] as any);
  // const [eventClassCardData, setEventClassCardData] = React.useState(
  //   [] as Array<IEqEventClassCardData>
  // );

  const [eventClassCardDataList, setEventClassCardDataList] = React.useState(
    [] as any
  );
  const [removedAttendeesList, setRemovedAttendeesList] = React.useState(
    [] as any
  );
  const [formData, setFormData] = React.useState({}) as any;
  const [event, setEvent] = React.useState() as any;

  const [attendeeCardData, setAttendeeCardData] = React.useState(
    [] as Array<IEqEventClassCardData>
  );

  // Dynamic Fields
  const [fieldModalVisible, setFieldModalVisible] = useState(false);
  const [multipleChoiceModalVisible, setMultipleChoiceModalVisible] =
    useState(false);
  const [questions, setQuestions] = useState([]) as any;

  const [copyMessage, contextCopyMessage] = message.useMessage();
  const openNotificationWithIcon = (
    msg: any,
    desc: any,
    type = eqNotificationTypes.error
  ) => {
    if (type == eqNotificationTypes.success) {
      notification["success"]({
        message: msg,
        description: desc,
      });
    } else if (type == eqNotificationTypes.info) {
      notification["info"]({
        message: msg,
        description: desc,
      });
    } else {
      notification["error"]({
        message: msg,
        description: desc,
      });
    }
  };

  const setEditFormData = (data: any) => {
    if (data != undefined) {
      
      var selectedDate = new Date(data.startDate);
      var selectedTimeFrom = new Date(data.startTime);

      var calculatedTimeFrom = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        selectedTimeFrom.getHours(),
        selectedTimeFrom.getMinutes(),
        0,
        0
      );

      var calculatedTimeTo;
      if (data.endTime != undefined) {
        var selectedTimeTo = new Date(data.endTime);
        calculatedTimeTo = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          selectedTimeTo.getHours(),
          selectedTimeTo.getMinutes(),
          0,
          0
        );
      }
      var selectedGroups = new Array<string>();
      if (data.selectedGroups != null) {
        data.selectedGroups?.map((item: string) => {
          selectedGroups.push("" + item);
        });
      }

      // console.log(areas);

      if (areas) {
        var loc = [...areas]
          ?.filter((item: any) => {
            return item.key == data.locationKey;
          })
          ?.shift();

        if (loc != null && loc != undefined) {
          setSelectedLoc(loc.value);
        }
      }

      setSelectedGroups(selectedGroups);
      getStudentPopulation([...selectedGroups]);
      //setSelectedStartDate(selectedDate);
      //setSelectedStartTime(calculatedTimeFrom);
      if (data.multipleStartDates && data.multipleStartDates.length > 0) {
        let mDates = [] as any;
        data.multipleStartDates?.map((item: any) => {
          mDates.push(DateWithZeroTime(new Date(item)));
        });
        // Create unique dates
        const uniqueDates = [] as any;
        mDates.forEach((date: any) => {
          if (!uniqueDates.some((d: any) => d.getTime() === date.getTime())) {
            uniqueDates.push(date);
          }
        });
        setMultipleStartOn(uniqueDates);
      }
      if (data.eventTermsFile) {
        setFileObj({
          name: "Event Terms",
          status: "done",
          url: config.apiUrl + data.eventTermsFile,
        });
      }

      if (data.eqImage) {
        setSelectedImage(eventData.eqImage);
      }
      // Questions
      if (data.questions) {
        setQuestions(data.questions);
      }

      return {
        id: data.id,
        key: data.key,
        referenceKey: data.referenceKey,

        title: data.title,
        description: data.description,
        customClassName: data.customClassName,
        isClassPreviewAllowed: data.isClassPreviewAllowed,
        startDate: moment(data.startDate).local(),
        endDate: moment(data.endDate).local(),
        repeats: data.repeatType,
        isRepeat: data.isRepeat,
        timeFrom: moment(calculatedTimeFrom.valueOf()).local(),
        timeTo:
          calculatedTimeTo != undefined
            ? moment(calculatedTimeTo.valueOf()).local()
            : calculatedTimeTo,
        duration: data.duration,
        disciplineType: data.disciplineType as EqDisciplineType,
        currency: data.currency as EqCurrency,
        trainingLevel: data?.trainingLevel,
        locationKey: data.locationKey,
        maxSpaceCount: data.maxAvailableSpace,
        isPaid: data.price > 0 ? true : false,
        price: data.price > 0 ? data.price : "",
        paymentMethod: data.paymentMethod,
        isLatePayment: data.isLatePayment,
        paymentDeadline:
          data.paymentDeadline > 0
            ? moment(data.paymentDeadline).local()
            : null,
        message: data.message,
        accessType: data.accessType as EqEventAccessType,
        horseAvailabilityStatus: data.horseAvailabilityStatus,
        eqImage: uiImage,
        phoneNumber: data.phoneNumber,
        email: data.email,
        eventTermsFile: data.eventTermsFile,
        questions: data.questions,
      };
    } else {
      return {
        title: "",
        description: "",
        startDate: moment(),
        endDate: moment(),
        repeats: "",
        isRepeat: false,
        timeFrom: moment(),
        timeTo: moment(),
        duration: "",
        disciplineType: EqDisciplineType.Dressage,
        trainingLevel: "",
        locationKey: "",
        maxSpaceCount: "",
        isPaid: true,
        price: "",
        paymentMethod: 1,
        isLatePayment: false,
        paymentDeadline: null,
        message: "",
        accessType: EqEventAccessType.Public + "",
        horseAvailabilityStatus: 0,
        eqImage: {},
      };
    }
  };

  // let count=0;
  const setEditEventClassCardData = (data: any) => {
    // count++
    if (data != undefined) {
      console.log(eventClassCardDataList);

      var cardKey = 0;
      var newEventClassCardDataList = [] as any;

      data.eventClasses?.map((tabItem: any, tabIdx: any) => {
        // var keysList = item.attendees?.map((attendee: any) => {
        //   return attendee.key;
        // });
        var newEventClassCardData = [] as any;

        tabItem.classTimes.map((item: any, cardIdx: any) => {
          cardKey = cardIdx + 1;
          // cardKey = cardKey + 1;
          var newAddedCardData: IEqEventClassCardData = {
            key: item.key,
            cardkey: cardKey,
            timeFrom: moment(item.startTime),
            // timeTo: moment(item.endTime),
            height: item.height,
            maxSpaceCount: item.maxAvailableSpace,

            classDescription: item.classDescription,
            price: item.price,
            // trainingLevel: item.trainingLevel,
            attendees: item.attendees ? item.attendees : [],
            // attendeesKeys: keysList ? keysList : [],
            attendeesHorse: item.attendeesHorse ? item.attendeesHorse : [],
            attendeesKeys: [],
            // display: true,
            // selectedDates: selectedDates,
          };
          newEventClassCardData.push(newAddedCardData);
        });
        newEventClassCardDataList.push(newEventClassCardData);
      });

      // var selectedDates = item.classTimes?.map((classTime: any) => {
      //   return covertMillisecondToStringDate(classTime.startTime);
      // });

      // setEventClassCardData(eventClassCardDataList);
      setEventClassCardDataList(newEventClassCardDataList);

      // if(count==2){

      //   setEventClassCardDataList(null);
      // }else{

      //   setEventClassCardDataList(newEventClassCardDataList);
      // }
    }
  };

  const { TextArea } = Input;
  const gutter = { xs: 20, sm: 16, md: 24, lg: 32 };

  /////////////

  const setEventDefaults = () => {
    setFormData({
      title: "",
      description: "",
      isClassPreviewAllowed: true,
      startDate: moment(),
      endDate: moment(),
      repeats: "",
      isRepeat: false,
      timeFrom: moment(),
      timeTo: moment(),
      duration: "",
      disciplineType: EqDisciplineType.Dressage,
      currency: EqCurrency.NOK,
      trainingLevel: "",
      locationKey: "",
      // maxSpaceCount:  EqDisciplineType.Jumping?4:1,
      maxSpaceCount: "",
      isPaid: true,
      price: "",
      paymentMethod: 1,
      isLatePayment: false,
      paymentDeadline: null,
      message: "",
      accessType: EqEventAccessType.Public + "",
      horseAvailabilityStatus: 0,
      eqImage: {},
    });
    setEventAttendees([] as any);
    // setEventClassCardData([] as Array<IEqEventClassCardData>);
    setEventClassCardDataList([] as Array<any>);
    setSelectedGroups([]);

    setMultipleStartOn([]);

    setIsPaymentDeadline(true);
    setIsOpenEvent(false);
    updateActivityData(dispatch, {});
    setFileObj(undefined);
  };

  const validateData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    //same for public and private
    if (data.title == "") {
      openNotificationWithIcon(msg, "Title" + desc);
      isComplete = false;
    } else if (uiImage == null || Object.keys(uiImage).length === 0) {
      openNotificationWithIcon(msg, "Image" + desc);
      isComplete = false;
    } else if (data.disciplineType == "") {
      openNotificationWithIcon(msg, "Event Type" + desc);
      isComplete = false;
    } else if (data.accessType == "") {
      openNotificationWithIcon(msg, "Access type" + desc);
      isComplete = false;
    } else if (data.locationKey == "") {
      openNotificationWithIcon(msg, "Location" + desc);
      isComplete = false;
    } else if (!data.startDate) {
      openNotificationWithIcon(msg, "Start On" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Public &&
      eventClassCardDataList.length > 0 &&
      isEdit == false
    ) {
      eventClassCardDataList.map((dList: any, tabIdx: any) => {
        dList.map((d: any, cardIdx: any) => {
          console.log(d);
          if (
            data.disciplineType != EqDisciplineType.Open &&
            d.duration == ""
          ) {
            openNotificationWithIcon(
              msg,
              `Duration at tab${tabIdx} card${cardIdx}` + desc
            );
            isComplete = false;
          } else if (d.height == "") {
            openNotificationWithIcon(
              msg,
              `Title at tab ${tabIdx} card ${cardIdx}` + desc
            );
            isComplete = false;
          } else if (d.price === "" || Number.isNaN(d.price)) {
            openNotificationWithIcon(
              msg,
              `Price at tab ${tabIdx} card ${cardIdx}` + desc
            );
            isComplete = false;
          }
        });
      });
    }
    return isComplete;
  };

  const validateTrainingData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";

    //same for public and private
    if (data.title == "") {
      openNotificationWithIcon(msg, "Title" + desc);
      isComplete = false;
    } else if (data.disciplineType == "") {
      openNotificationWithIcon(msg, "Event Type" + desc);
      isComplete = false;
    } else if (data.accessType == "") {
      openNotificationWithIcon(msg, "Access type" + desc);
      isComplete = false;
    } else if (data.locationKey == "") {
      openNotificationWithIcon(msg, "Location" + desc);
      isComplete = false;
    } else if (!data.startDate) {
      openNotificationWithIcon(msg, "Start On" + desc);
      isComplete = false;
    } else if (data.isRepeat == true && data.repeats == "") {
      openNotificationWithIcon(msg, "Repeats" + desc);
      isComplete = false;
    } else if (
      isEdit == false &&
      data.isRepeat == true &&
      (weekDays.selectedWeekDays == null ||
        weekDays.selectedWeekDays.length == 0)
    ) {
      openNotificationWithIcon(msg, "Week days" + desc);
      isComplete = false;
    }

    return isComplete;
  };

  const OnSaveTraining = () => {
    let validate = validateTrainingData(formData);

    if (validate == true) {
      // if(localData!== undefined){
      //   var data={...localData,activityData:formData}
      //   saveState(data)
      // }

      updateActivityData(dispatch, formData);
      // onSaveSetBackButton();
      // setIsTrainingSaved(true);
      // getGroupsStudentList();
      getStudentPopulation(null);
      // onSaveSetBackButton(true);
    }
  };

  // const setEventObject = (data: any): ICreateEventDto => {
  const setEventObject = (data: any): any => {
    console.log("paymentDeadline", isPaymentDeadline);

    let validate = validateData(data);
    //
    if (validate == true) {
      let dStart = new Date(data.startDate);
      let dTimeFrom = new Date(data.timeFrom);
      let calTimeFrom = new Date(
        dStart.getFullYear(),
        dStart.getMonth(),
        dStart.getDate(),
        dTimeFrom.getHours(),
        dTimeFrom.getMinutes(),
        0,
        0
      );

      const newDto: ICreateEventDto = {
        key: eventKey,
        eqImage: uiImage,
        referenceKey: data.referenceKey,
        disciplineType: data.disciplineType,
        title: data.title,
        currency: data.currency,
        locationKey: +data.locationKey,
        // horseAvailabilityStatus: data.horseAvailabilityStatus,
        paymentDeadline: data.paymentDeadline
          ? moment(data.paymentDeadline, dateFormat).utc().valueOf()
          : (null as any),
        maxAvailableSpace: parseInt(
          data.maxSpaceCount ? data.maxSpaceCount : 0
        ), //issue
        email: data.email,
        phoneNumber: data.phoneNumber,
        eventTermsFile: data.eventTermsFile,
        description: data.description,
        customClassName: data.customClassName,
        isClassPreviewAllowed: data.isClassPreviewAllowed,
        accessType: data.accessType,
        startDate: moment(data.startDate, dateFormat).utc().valueOf(),
        startTime: moment(calTimeFrom.valueOf()).utc().valueOf(),
        // questions:questions
      };

      if (questions.length > 0) {
        newDto.questions = [...questions];
      }
      if (removedAttendeesList.length > 0) {
        newDto.removedAttendees = [...removedAttendeesList];
      }
      if (newDto.multipleStartDates == null) {
        newDto.multipleStartDates = new Array<number>();
      }
      multipleStartOn?.map((item: any) =>
        newDto.multipleStartDates?.push(
          moment(new Date(item).valueOf()).utc().valueOf()
        )
      );

      //for public events
      console.log("public event data");
      if (newDto.eventClasses == null) {
        newDto.eventClasses = new Array<IEventClassDto>();
      }

      if (data.accessType == EqEventAccessType.Public) {
        eventClassCardDataList?.map((dList: any, idxTab: any) => {
          let dStartClass = moment(
            multipleStartOn[idxTab],
            "DD/MM/YYYY"
          ).toDate();

          let dStartTime = dList[0].timeFrom;

          let classTimes = new Array<IClassTimes>();

          dList?.map((item: any) => {
            // let classTime: IClassTimes;

            let dClassTimeFrom = new Date(item.timeFrom);
            let calClassTimeFrom = new Date(
              dStartClass.getFullYear(),
              dStartClass.getMonth(),
              dStartClass.getDate(),
              dClassTimeFrom.getHours(),
              dClassTimeFrom.getMinutes(),
              0,
              0
            );

            classTimes.push({
              key: item.key,
              startDate: moment(dStartClass.valueOf()).utc().valueOf(),
              startTime: moment(calClassTimeFrom.valueOf()).utc().valueOf(),
              height: item.height,
              classDescription: item.classDescription,
              price: parseInt(item.price ? item.price : 0),
              maxAvailableSpace: parseInt(
                item.maxSpaceCount ? item.maxSpaceCount : 0
              ),
              attendees: item.attendees,
            });
            // });
          });
          newDto.eventClasses?.push({
            trainingLessonsKey: isEdit ? dList.trainingLessonsKey : 0,
            startDate: moment(dStartClass.valueOf()).utc().valueOf(),
            classTimes: classTimes,
          });
        });
      }

      return newDto;
    } else {
      return null;
    }
  };

  const createHorseAvailabilities = () => {
    var accessTypes = [];
    for (var x in EqEventHorseAvailabilityStatus) {
      if (isNaN(Number(x)) == false) {
        accessTypes.push({ key: x, value: EqEventHorseAvailabilityStatus[x] });
      }
    }
    setHorseAvailability(accessTypes);
  };

  const handleChangeFile = (info: any) => {
    if (info.file.status === "uploading") {
      setFileLoading(true);
      return;
    }
    if (info.file.originFileObj) {
      UploadToServer(info.file.originFileObj);
    } else {
      UploadToServer(info.file);
    }
  };

  const UploadToServer = (file: any) => {
    setFileLoading(true);

    var form = new FormData();
    form.append("EventFile", file);
    // form.append("EventSrc", apiImgSrc);
    // form.append("EventName", img.name);
    // setError("");

    Api.post("EventFile/UploadEventFile", form).then(
      (response: any) => {
        var lst: any = fileObj;
        setFileObj({
          uid: file.uid,
          name: file.name,
          status: "done",
          url: response.data,
        });
        setFormData({ ...formData, eventTermsFile: response.data });

        setFileLoading(false);
      },
      (error) => {
        setFileLoading(false);
        console.log("Error Occurred while uploading image");
      }
    );
  };

  const handleDeleteFile = () => {
    // Remove the file from the component's state (fileObj in this case)
    setFileObj(null);
    // Optionally, reset any related data in your component state
    setFormData({ ...formData, eventTermsFile: null });
  };

  const handlePrint = () => {
    const originalContents = document.body.innerHTML;
    const printContent = componentRef.current;

    if (printContent) {
      // Detach the printable section from the DOM
      const printableSection = printContent.cloneNode(true) as HTMLElement;
      printContent.parentElement?.removeChild(printContent);

      // Print the specific component content
      document.body.appendChild(printableSection);
      window.print();

      // Restore the original page contents
      document.body.innerHTML = originalContents;
    }
  };

  const NavigateToPrint = () => {
    navigate(FrontEndPoints.trainer.eventPrint, {
      state: {
        prevPath: location.pathname,
      },
    });
  };

  const PrintButton = () => {
    const handlePrint = () => {
      const printableContent: any = document.getElementById("printableContent");
      const printWindow: any = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><title>Print</title></head><body>"
      );
      printWindow.document.write(printableContent.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    };

    return <button onClick={handlePrint}>Print</button>;
  };

  React.useEffect(() => {
    console.log(eventData);

    //
    if (weekDaysData) {
      setWeekDays({ ...weekDays, allWeekDays: weekDaysData });
    }
    if (population) {
      sortAscending(population);
    }
    createEventDurations();
    createEventRepeats();
    createHorseAvailabilities();

    if (eventKey !== 0) {
      setIsEdit(true);
      // GetTrainingWithLessonByKey(eventKey)
      // or
      //
      // console.log(eventData);
      setEditEventClassCardData({ ...eventData });
      var formObj = setEditFormData({ ...eventData });
      setFormData(formObj);
      setSelectedImage(eventData.eqImage);
    } else {
      setIsEdit(false);
      getStudentPopulation(null);

      if (
        userDetails.activityData &&
        userDetails.activityData.accessType === "1"
      ) {
        setFormData(userDetails.activityData);
      } else {
        setEventDefaults();
      }

      createEventAccessTypes();
    }
  }, [
    ,
    eventData,
    eventKey,
    areas,
    population,
    groupPopulation,
    trainerStudents,
    isTrainingSaved,
    weekDaysData,
  ]);

  // studentPopulationNew
  const getStudentPopulation = (groups: any) => {
    const selectedGroupsData = groups || selectedGroups;
    var students = new Array<IStudentGroupDto>();
    var studentsData = [] as any;

    var groupStudents = new Array<IStudentGroupDto>();
    var groupStudentsData = [] as any;

    // separate students by groups
    var studentsDataByGroup = [] as any;

    if (selectedGroupsData && selectedGroupsData.length > 0) {
      if (trainerStudents) {
        [...trainerStudents].map((item: any) => {
          [...item.user.groups].filter((s: any) => {
            let res = selectedGroupsData.some((gs: number) => {
              if (gs == s.group.key) {
                let studentGroup: IStudentGroupDto = {
                  key: item.key,
                  user: item.user,
                  userType: 2,
                  group: s.group,
                };
                groupStudents.push(studentGroup);

                var name =
                  item.user != undefined
                    ? item.user.firstName
                      ? item.user.firstName
                      : item.user.userName
                    : item.group != undefined
                    ? item.group.name
                    : "";
                var newKey = item.key;

                groupStudentsData.push({
                  key: newKey,
                  value: name,
                  label: name,
                });
              }
            });
          });
        });

        // const groups = groupStudents.reduce((groups: any, item: any) => {
        //   const group = groups[item.group.key] || [];
        //   group.push(item);
        //   groups[item.group.key] = group;
        //   return groups;
        // }, {});
        // console.log(groups);
      }

      [...selectedGroupsData].map((grpItem: any) => {
        let grpName = "";
        let sortStd = [] as any;
        groupStudents.map((grpSt: any) => {
          if (grpItem == grpSt.group.key) {
            grpName = grpSt.group.name;

            var name =
              grpSt.user != undefined
                ? grpSt.user.firstName
                  ? grpSt.user.firstName
                  : grpSt.user.userName
                : grpSt.group != undefined
                ? grpSt.group.name
                : "";

            sortStd.push({
              key: grpSt.key,
              value: name,
              label: name,
            });
          }
        });

        studentsDataByGroup.push({
          label: grpName,
          options: [...sortStd],
        });
      });

      // groups.map((g:any)=>{

      // })
      // console.log("groupStudent 2:", groupStudents);
      // console.log("groupStudentData 2:", groupStudentsData);
    }
    // console.log(studentsDataByGroup);
    // Trainer Students
    if (trainerStudents) {
      trainerStudents?.map((d: any) => {
        if (d.userType == EqUserType.Student) {
          students.push(d);

          var name =
            d.user != undefined
              ? d.user.firstName
                ? d.user.firstName
                : d.user.userName
              : d.group != undefined
              ? d.group.name
              : "";
          var newKey = d.key;

          studentsData.push({
            key: newKey,
            value: name,
            label: name,
          });
        }
      });
      if (groupStudentsData && groupStudentsData.length > 0) {
        //console.log("filter data");
        //console.log(groupStudentsData);
        let filteredStudents = studentsData.filter((s: any) => {
          let res = groupStudentsData.some((gs: any) => {
            return s.key === gs.key;
          });
          return !res;
        });
        setGroupStudentPopulation([...groupStudentsData]);
        setStudentsByGroup([...studentsDataByGroup]);
        setStudentPopulation({
          ...studentPopulation,
          filteredStudents: [...filteredStudents],
        });
        groupStudentsData = [];
      } else {
        setStudentPopulation({
          ...studentPopulation,
          allStudents: [...studentsData],
        });
        setGroupStudentPopulation([]);
        setStudentsByGroup([]);
      }

      if (groupStudents && groupStudents.length > 0) {
        var list = [] as any;
        let filteredStudents = students.filter((s: any) => {
          let res = groupStudents.some((gs: any) => {
            return s.key === gs.key;
          });
          if (res == true) {
            var index = groupStudents.findIndex((x: any) => {
              return x.key === s.key;
            });
            list.push(groupStudents[index]);
          } else {
            list.push(s);
          }
          return !res;
        });
        setSearchStudentsList([...list]);
      } else {
        setSearchStudentsList([...students]);
      }
    }
  };

  const createEventDurations = () => {
    var durations = [];
    let durationVals = [30, 45, 60, 90];
    for (var x = 0; x < durationVals.length; x++) {
      durations.push({ key: durationVals[x], value: durationVals[x] });
    }
    //for (var i = 5; i < 101; i += 5) {
    //    durations.push({ key: i, value: i });
    //}
    setEventDurations(durations);
  };

  const createEventRepeats = () => {
    var repeats = [];
    for (var x in EqEventRepeatType) {
      if (isNaN(Number(x)) == false) {
        repeats.push({ key: x, value: EqEventRepeatType[x] });
      }
    }
    setEventRepeats(repeats);
  };

  const createEventAccessTypes = () => {
    var accessTypes = [];
    var pAccess = [];
    for (var x in EqEventAccessType) {
      let label;
      if (x == EqEventAccessType.Private.toString()) {
        label = "Training";
      } else if (x == EqEventAccessType.Public.toString()) {
        label = "Event";
      }
      if (isNaN(Number(x)) == false) {
        accessTypes.push({ key: x, value: label });

        if (Number(x) == EqEventAccessType.Private) {
          pAccess.push({ key: Number(x), value: label });
        }
      }
    }
    // // console.log(formData?.disciplineType);
    if (isEdit == true) {
      if (formData?.disciplineType == EqDisciplineType.Open) {
        setEventAccessTypes({
          allAccessTypes: [...accessTypes],
          privateOnly: [...pAccess],
          toggleOptions: [...pAccess],
        });
      } else {
        setEventAccessTypes({
          allAccessTypes: [...accessTypes],
          privateOnly: [...pAccess],
          toggleOptions: [...accessTypes],
        });
      }
    } else {
      setEventAccessTypes({
        allAccessTypes: [...accessTypes],
        privateOnly: [...pAccess],
        toggleOptions: [...accessTypes],
      });
    }
  };

  const updateEndTime = (
    fullYear: number,
    month: number,
    date: number,
    hours: number,
    minutes: number,
    seconds: number,
    milliseconds: number
  ) => {
    var calculatedDate = new Date(
      fullYear,
      month,
      date,
      hours,
      minutes,
      seconds,
      milliseconds
    );
    setFormData({ ...formData, timeTo: moment(calculatedDate.valueOf()) });
  };

  const createEvent = () => {
    // console.log(questions);
    if(!userDetails.isSubscribed){
      openNotificationWithIcon(
        "Error!",
        "Kindly Subscribe to Create",
        eqNotificationTypes.error
      );
      return;
    }
    var data = setEventObject(formData);
    if (data != null) {
      // console.log(data);
      setSpinning(true)
      Api.post(ApiEndpoints.CreateTrainerEvent, data).then(
        (response) => {
      setSpinning(false)

          if (response && response.status == 200) {
            openNotificationWithIcon(
              "Created!",
              "Event(s) have been created",
              eqNotificationTypes.success
            );
            setIsError(false);
            setErrMessage(GetEventMessage(response.status));
            setEventDefaults();
            onSaveSetBackButton(false);
            setEventAttendees([] as any);
            onActivityTitleChange("");
            onCreateOrUpdateActivity(response.data);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the event(s). Please try again or contact administrator."
            );
            setIsError(true);
          }
        },
        (error) => {
      setSpinning(false)

          if (error?.response?.status === 403) {
            openNotificationWithIcon(
              "Server error",
              "Not Authorized Kindly Add Payment or contact administrator."
            );
            setIsError(true);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the event(s). Please try again or contact administrator."
            );
            setIsError(true);
          }
        }
      );
    } else {
      setIsError(true);
    }
  };

  const createEventPreview = () => {
    var data = setEventObject(formData);
    if (data != null) {
      data.organizers = [userDetails];
      setEvent(data);
      console.log(data);
    }
  };

  const UpdateEventWithClass = () => {
    
    var data = setEventObject(formData);
    setSpinning(true)

    // Api.post(ApiEndpoints.eventEdit, data).then(
    // Api.post(ApiEndpoints.UpdateEventWithClass, data).then(
    Api.post(ApiEndpoints.UpdateTrainerEvent, data).then(
      (response) => {
    setSpinning(false)

        if (response && response.status == 200) {
          openNotificationWithIcon(
            "Updated!",
            "You have successfully Updated your event",
            eqNotificationTypes.success
          );
          setIsError(false);
          setIsEdit(false);
          setErrMessage(GetEventMessage(response.status));
          setEventClassCardDataList([]);

          setEventDefaults();
          createEventAccessTypes();
          // createEventTypes();
          onCreateOrUpdateActivity(response.data, eventData);
        } else {
          setIsError(true);
          setErrMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
    setSpinning(false)

        setIsError(true);
        setErrMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const sortAscending = (unsorted: Array<IStudentGroupDto>) => {
    const sorted = [...unsorted].sort((obj1, obj2) => {
      if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() >
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return 1;
      } else if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() <
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    //console.log(sorted);
    return sorted;
  };

  const sortDescending = (unsorted: Array<IStudentGroupDto>) => {
    const sorted = [...unsorted].sort((obj1, obj2) => {
      console.log(obj1);
      console.log(obj2);
      if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() <
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return 1;
      } else if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() >
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    //console.log(sorted);
    return sorted;
  };

  const onSortChange = (group: any): any => {
    var sortedList = [] as any;
    switch (group) {
      case "asc":
        sortedList = sortAscending(eventAttendees);
        setEventAttendees(sortedList);
        break;
      case "desc":
        sortedList = sortDescending(eventAttendees);
        setEventAttendees(sortedList);
        break;
      default:
        sortedList = sortAscending(eventAttendees);
        setEventAttendees(sortedList);
    }
  };

  const removeEventMember = (member: any) => {
    console.log(eventAttendees);
    var attendees = [...eventAttendees];
    var keys = [...groupKeys];
    if (attendees != null && attendees.length > 0) {
      const index = attendees.findIndex((x: any) => x.key == member.key);
      if (index > -1) {
        attendees.splice(index, 1);
      }
    }
    if (keys != null && keys.length > 0) {
      const index = keys.findIndex((x: any) => x);
      if (index > -1) {
        keys.splice(index, 1);
      }
    }
    setGroupKeys([...keys]);
    console.log(keys);
    setEventAttendees([...attendees]);
    console.log(attendees);
  };

  const onGroupChange = (value: any) => {
    const selectedMembers = [...value];
    var selectedPopulation = [] as any;
    population.filter((item: any) => {
      if (selectedMembers.includes(item.key) == true) {
        selectedPopulation.push(item);
      }
    });
    console.log(selectedMembers);
    console.log(selectedPopulation);
    setSearchedAttendees([...selectedPopulation]);
  };

  const setSelectedImage = (eqUiImage: any) => {
    //console.log(eqUiImage);
    setUiImage(eqUiImage);
    // setFormData({ ...formData, eqImage: eqUiImage });
  };
  ////////////////

  // keep this for future Use
  const horseAvailabilitySelectProps: ISelectProps = {
   
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    placeholder: "Select availability of horse",
    classNames: "eq-addSelect extra-height",
    defaultSelectedValue: formData?.horseAvailabilityStatus
      ? formData.horseAvailabilityStatus + ""
      : "",
    options: horseAvailability,
    changeFunc: (value: string) => {
      setFormData({ ...formData, horseAvailabilityStatus: +value });
    },
  };

  const typeSelectProps: ISelectProps = {
    options: eventTypes.toggleOptions.map((filter: any) => ({
      key: filter.key,
      value: t(filter.value), // Translate the filter label
    })),
    defaultSelectedValue: formData?.disciplineType,
    classNames: "extra-height",
    changeFunc: (value: string) => {
      let type: number = +value;
      setFormData({ ...formData, disciplineType: type });

      if (isEdit == true) {
        if (type == EqDisciplineType.Open) {
          setIsOpenEvent(true);
        } else {
          setIsOpenEvent(false);
        }
      } else {
        if (type == EqDisciplineType.Open) {
          setIsOpenEvent(true);
        } else {
          setIsOpenEvent(false);
        }
        // setEventAccessAndDisciplineTypes(false, formData?.accessType, type);
      }
    },
  };

  const currencySelectProps: ISelectProps = {
    options: currencyOptions,
    defaultSelectedValue: formData?.currency,
    classNames: "extra-height",
    changeFunc: (value: string) => {
      let type: number = +value;
      setFormData({ ...formData, currency: type });
    },
  };
  const repeatSelectProps: ISelectProps = {
    mode: "tags",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    placeholder: "Select repeating duration",
    classNames: "eq-addSelect extra-height",
    defaultSelectedValue: formData?.repeatType
      ? formData.repeatType.toString()
      : "",
    options: eventRepeats,
    changeFunc: (value: string) => {
      let repeat: number = +value;
      setFormData({ ...formData, repeats: repeat });
    },
  };

  const areaSelectProps: ISelectProps = {
    isTag: false,
    // for Search
    showSearch: true,
    optionFilterProp: "label",
    filterOption: true,
    //--
    placeholder: "Select area",
    ddClassName: "eq-select-dropdown",
    defaultSelectedValue: formData?.locationKey
      ? formData.locationKey + ""
      : "",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    options: areas,
    changeFunc: (value: string) => {
      setFormData({ ...formData, locationKey: value });

      let loc = areas
        ?.filter((item: any) => {
          return item.key == value;
        })
        ?.shift();
      if (loc != null && loc != undefined) {
        setSelectedLoc(loc.value);
      }
    },
  };

  const onChangeGroupSelect = (value: any) => {
    const selectedMembers = [...value];
    setSelectedGroups(selectedMembers);
    getStudentPopulation(selectedMembers);
  };

  const GroupProps = {
    mode: "multiple",
    showSearch: true,
    optionFilterProp: "label",
    filterOption: true,
    isTag: false,
    showArrow: false,
    allowClear: false,
    changeFunc: onChangeGroupSelect,
    // changeFunc: (value: any) => {
    //   setFormData({ ...formData,selectedGroups: value });
    // },
    classNames: "eq-multiSelect eq-searchSelect extra-height",
    ddClassName: "eq-select-dropdown",
    icon: [
      <span className="eq-search-icon">
        <img alt="" src="/search-icon.svg" />
      </span>,
    ],
    selectedIcon: [
      <span className="">
        <img alt="" src="/check-square.svg" />
      </span>,
    ],
    options: groupPopulation,
    // defaultSelectedValue: selectedGroups,
    defaultSelectedValue: selectedGroups,
  };

  const sortProps = {
    border: false,
    allowClear: false,
    classNames: "eq-select-sort",
    ddClassName: "eq-cg-dropdown",
    defaultSelectedValue: "Sort A-Z",
    changeFunc: onSortChange,
    options: [
      { key: "asc", value: "Sort A-Z" },
      { key: "desc", value: "Sort Z-A" },
    ],
  };

  const startOnProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.startDate,
    placeHolder: t("date"),
    classNames: "eq-event",
    onChangeFunc: (date: any) => {
      setFormData({ ...formData, startDate: moment(date) });
    },
  };
  const multipleStartOnProps: IMultiDatePickerOptions = {
    defaultValue: multipleStartOn,
    placeHolder: t("date"),
    classNames: "eq-event",
    onChangeFunc: (selectedDates: any) => {
      console.log(selectedDates);
      var dateArr = [] as any;
      if (selectedDates && selectedDates.length > 0) {
        if (selectedDates.length == 1) {
          setIsMultipleDateSelected(false);
        } else {
          setFormData({
            ...formData,
            repeats: "",
            isRepeat: false,
          });
          setIsMultipleDateSelected(true);
        }
        dateArr = selectedDates.map((date: any) => {
          return date;
        });
      }
      setMultipleStartOn(dateArr);
      // eventClassCardDateOptions(dateArr);
    },
  };

  //  const eventClassCardDateOptions = (selectedDates:any) => {
  //     var dates = []as any;

  //     selectedDates.map((date:any) => (
  //       dates.push({ key: date, value: date })
  //     ))

  //     // setCardDateOptions(dates);

  //   };

  const endOnProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.endDate,
    placeHolder: "Date",
    classNames: "eq-event",
    onChangeFunc: (date: any) => {
      setFormData({ ...formData, endDate: moment(date) });
    },
  };

  const advancePaymentDateProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.paymentDeadline,
    placeHolder: "Date",
    classNames: "eq-event",
    onChangeFunc: (date: any) => {
      setFormData({ ...formData, isLatePayment: false });
      setFormData({ ...formData, paymentDeadline: moment(date) });
    },
  };

  const weekDaysSelectProps: ISelectProps = {
    mode: "multiple",
    isTag: false,
    showArrow: true,
    allowClear: true,
    classNames: "eq-multiSelect eq-addSelect",
    ddClassName: "eq-select-dropdown",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    placeholder: "Select days",
    defaultSelectedValue: weekDays.selectedWeekDays,
    options: weekDays.allWeekDays,
    changeFunc: (param: any) => {
      setWeekDays({
        ...weekDays,
        selectedWeekDays: [...param],
        weekDayTags: [...param],
      });
    },
  };
  const changeEventCollapseDisplay = (display: boolean) => {
    setIsEventCollapse(display);
  };
  const eventCollapsibleProps: IEqCollapse = {
    display: isEventCollapse,
    setDisplay: changeEventCollapseDisplay,
    showArrow: false,
    classNames: "eq-transaparent-header",
    header: [
      <Row align="middle" justify="end">
        {selectedLoc && (
          <>
            <Col span={1}>
              <span className="">
                <img alt="" src="/map-marker-alt.svg" />
              </span>
            </Col>
          </>
        )}
        <Col flex="auto">
          <div className="header-label">{selectedLoc}</div>
        </Col>
        <Col>
          <EqTags
            className="eq-tag"
            text={t(EqDisciplineType[formData.disciplineType])}
            dicipline={true}
          />
        </Col>
      </Row>,
    ],
  };

  const eventClassCardTimeFromChange = (
    tabIdx: number,
    cardkey: number,
    timeFrom: any
  ) => {
    var newEventClassCardDataList = [...eventClassCardDataList];
    var newEventClassCardData = newEventClassCardDataList[tabIdx];

    newEventClassCardData.forEach((obj: any) => {
      obj.timeFrom = moment(timeFrom);
    });
    // const index = newEventClassCardData.findIndex((item: any) => {
    //   return item.cardkey == cardkey;
    // });
    // var modifyList = [...newEventClassCardData];
    // if (index > -1) {
    //   newEventClassCardData[index].timeFrom = moment(timeFrom);
    // }

    newEventClassCardDataList[tabIdx] = newEventClassCardData;
    setEventClassCardDataList(newEventClassCardDataList);
    // setEventClassCardData(modifyList);
  };

  const eventClassCardPriceChange = (
    tabIdx: number,
    cardkey: number,
    price: any
  ) => {
    console.log(eventClassCardDataList);
    var newEventClassCardDataList = [...eventClassCardDataList];
    var newEventClassCardData = newEventClassCardDataList[tabIdx];

    const index = newEventClassCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...newEventClassCardData];
    if (index > -1) {
      newEventClassCardData[index].price = price;
    }
    newEventClassCardDataList[tabIdx] = newEventClassCardData;
    setEventClassCardDataList(newEventClassCardDataList);
    // setEventClassCardData(modifyList);
  };
  const eventClassCardHeightChange = (
    tabIdx: number,
    cardkey: number,
    height: any
  ) => {
    console.log(eventClassCardDataList);
    var newEventClassCardDataList = [...eventClassCardDataList];
    var newEventClassCardData = newEventClassCardDataList[tabIdx];

    const index = newEventClassCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...newEventClassCardData];
    if (index > -1) {
      newEventClassCardData[index].height = height;
    }
    newEventClassCardDataList[tabIdx] = newEventClassCardData;
    setEventClassCardDataList(newEventClassCardDataList);
    // setEventClassCardData(modifyList);
  };

  const eventClassCardClassDescriptionChange = (
    tabIdx: number,
    cardkey: number,
    classDescription: any
  ) => {
    console.log(eventClassCardDataList);
    var newEventClassCardDataList = [...eventClassCardDataList];
    var newEventClassCardData = newEventClassCardDataList[tabIdx];

    const index = newEventClassCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...newEventClassCardData];
    if (index > -1) {
      newEventClassCardData[index].classDescription = classDescription;
    }
    newEventClassCardDataList[tabIdx] = newEventClassCardData;
    setEventClassCardDataList(newEventClassCardDataList);
    // setEventClassCardData(modifyList);
  };

  const eventClassCardAvailableSpaceChange = (
    tabIdx: number,
    cardkey: number,
    spaces: any
  ) => {
    console.log(eventClassCardDataList);

    var newEventClassCardDataList = [...eventClassCardDataList];
    var newEventClassCardData = newEventClassCardDataList[tabIdx];

    const index = newEventClassCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });

    var modifyList = [...newEventClassCardData];
    if (index > -1) {
      newEventClassCardData[index].maxSpaceCount = spaces;
    }
    // const index = eventClassCardData.findIndex((item: any) => {
    //   return item.cardkey == cardkey;
    // });
    // var modifyList = [...eventClassCardData];
    // if (index > -1) {
    //   modifyList[index].maxSpaceCount = spaces;
    // }

    newEventClassCardDataList[tabIdx] = newEventClassCardData;
    setEventClassCardDataList(newEventClassCardDataList);
    // setEventClassCardData(modifyList);
  };

  const eventClassCardSelectedDateChange = (cardkey: number, dates: any) => {
    //console.log(eventClassCardData);
    // const index = eventClassCardData.findIndex((item: any) => {
    //   return item.cardkey == cardkey;
    // });
    // var modifyList = [...eventClassCardData];
    // if (index > -1) {
    //   modifyList[index].selectedDates = dates;
    // }
    // setEventClassCardData(modifyList);
  };

  const eventClassCardHorseChange = (
    cardkey: number,
    selectedHorsesData: any
  ) => {
    //console.log(attendees);
    // const index = eventClassCardData.findIndex((item: any) => {
    //   return item.cardkey == cardkey;
    // });
    // var modifyList = [...eventClassCardData];
    // if (index > -1) {
    //   modifyList[index].attendeesHorse = selectedHorsesData;
    // }
    // setEventClassCardData(modifyList);
  };

  const removeAttendeeFromCard = (
    tabIdx: number,
    cardkey: number,
    attendeeKey: any,
    refund: boolean
  ) => {
    //console.log(attendees);

    var newEventClassCardDataList = [...eventClassCardDataList];
    var newEventClassCardData = newEventClassCardDataList[tabIdx];
    var removedAttendees = [];

    const cardIndex = newEventClassCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...newEventClassCardData];
    if (cardIndex > -1) {
      var attendeesList = modifyList[cardIndex].attendees;
      var indexAttendee = attendeesList.findIndex(
        (x: any) => x.attendeeIndex === attendeeKey
      );
      if (indexAttendee !== -1) {
        removedAttendees = attendeesList.splice(indexAttendee, 1);
      }
      modifyList[cardIndex].attendees = attendeesList;

      newEventClassCardDataList[tabIdx] = modifyList;
      setEventClassCardDataList(newEventClassCardDataList);

      const removedAttendee = {
        refund: refund,
        removedAttendee: removedAttendees[0],
      };
      setRemovedAttendeesList([...removedAttendeesList, removedAttendee]);
      // let keys = modifyList[index]?.attendees?.map((a: any) => a.key);
    }

    // setEventClassCardData(modifyList);
  };

  const removeEventClassCard = (tabIdx: number, cardkey: number) => {
    var newEventClassCardDataList = [...eventClassCardDataList];
    var newEventClassCardData = newEventClassCardDataList[tabIdx];

    // var newDataArr = [...eventClassCardData];
    if (newEventClassCardData != null && newEventClassCardData.length > 0) {
      newEventClassCardData = newEventClassCardData.filter(
        (x: any) => x.cardkey != cardkey
      );
    }
    if (newEventClassCardData.length > 0) {
      newEventClassCardData[newEventClassCardData.length - 1].display = true;
    }

    newEventClassCardDataList[tabIdx] = newEventClassCardData;
    setEventClassCardDataList(newEventClassCardDataList);
    // setEventClassCardData(newEventClassCardData);
  };
  //console.log("Data", eventClassCardData);

  // const InitializeEventClassCard = () => {
  //   var newDataArr = [] as Array<IEqEventClassCardData>;
  //   var newAddedCardData = newEmptyCardData;

  //   newDataArr.push({ ...newAddedCardData });

  //   // setEventClassCardData(newDataArr);
  // };

  // const onHandleCopyEmails = () => {
  //   const emails = [] as any;

  //   eventData?.eventClasses?.map((evtClass: any) => {
  //     evtClass?.classTimes.map((classTime: any) => {
  //       if (classTime.attendees && classTime.attendees) {
  //         classTime.attendees.map((attendee: any) => {
  //           emails.push(attendee.key);
  //         });
  //       }
  //     });
  //   });

  //   console.log(emails);

  //   // return emails;
  // };

  const getEmailsText = () => {
    const emails = new Set<any>();
    eventData?.eventClasses?.forEach((evtClass: any) => {
      evtClass?.classTimes.forEach((classTime: any) => {
        if (classTime.attendees && classTime.attendees.length) {
          classTime.attendees.forEach((attendee: any) => {
            emails.add(attendee.key);
          });
        }
      });
    });
    // return emails;
    return Array.from(emails).join(",");
  };

  const getNumbersText = () => {
    const emails = new Set<any>();
    eventData?.eventClasses?.forEach((evtClass: any) => {
      evtClass?.classTimes.forEach((classTime: any) => {
        if (classTime.attendees && classTime.attendees.length) {
          classTime.attendees.forEach((attendee: any) => {
            emails.add(attendee?.user?.phoneNumber);
          });
        }
      });
    });
    // return emails;
    return Array.from(emails).join(",");
  };

  const handleCopyLink = () => {
    copyMessage.open({
      type: "success",
      content: "Copied",
    });
  };

  const addNewEventClassCardToDate = (dateIndex: any, selectedTabDate: any) => {
    let tabDate = moment(selectedTabDate).format(dateFormat);

    var newEventClassCardDataList = [...eventClassCardDataList];
    var newEventClassCardData = newEventClassCardDataList[dateIndex]
      ? newEventClassCardDataList[dateIndex]
      : [];
    var newAddedCardData = { ...newEmptyCardData };

    if (newEventClassCardData.length > 0) {
      var lastAddedCardData =
        newEventClassCardData[newEventClassCardData.length - 1];
      newEventClassCardData[newEventClassCardData.length - 1].display = false;

      newAddedCardData.timeFrom = moment(lastAddedCardData.timeFrom);
      newAddedCardData.cardkey = lastAddedCardData.cardkey + 1;
      newAddedCardData.price = lastAddedCardData.price;
      newAddedCardData.maxSpaceCount = lastAddedCardData.maxSpaceCount;

      // newAddedCardData.attendeesKeys = [];
    }

    newEventClassCardData.push({ ...newAddedCardData });

    newEventClassCardDataList[dateIndex] = newEventClassCardData;

    setEventClassCardDataList(newEventClassCardDataList);
    // setEventClassCardData(newEventClassCardData);
  };

  const onDragAttendee = (result: any, tabIdx: any) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    // Split the string by comma
    const [attendeeIndex, srcCardKey] = result.draggableId.split(",");

    var newEventClassCardDataList = [...eventClassCardDataList];
    var newEventClassCardData = newEventClassCardDataList[tabIdx];

    var modifyList = [...newEventClassCardData];
    //console.log(source);
    //console.log(destination);

    if (source.droppableId === destination.droppableId) {
      const cardIndex = newEventClassCardData.findIndex((item: any) => {
        return item.cardkey == source.droppableId;
      });
      let newAttendees = [...newEventClassCardData[cardIndex].attendees];
      var indexA = source.index;
      var indexB = destination.index;

      var tempSrcObj = newAttendees[indexA];
      // Swap the objects
      newAttendees[indexA] = newAttendees[indexB];
      newAttendees[indexB] = tempSrcObj;

      var tempSrcObjIndex = newAttendees[indexA].attendeeIndex;
      // Swap the attendeeIndex values
      newAttendees[indexA].attendeeIndex = newAttendees[indexB].attendeeIndex;
      newAttendees[indexB].attendeeIndex = tempSrcObjIndex;

      newEventClassCardData[cardIndex].attendees = newAttendees;
    } else {
      const sInd = +source.droppableId;
      const dInd = +destination.droppableId;

      const sourceCard = newEventClassCardData[sInd - 1];
      const destCard = newEventClassCardData[dInd - 1];

      const srcKey = attendeeIndex;
      if (srcKey) {
        // remove attendee From src
        var removedObj = null;
        var updatedSourceAttendees = sourceCard.attendees.filter((obj: any) => {
          if (obj.attendeeIndex == srcKey) {
            removedObj = obj;
            return false; // Exclude this object from the updatedSourceList
          }
          return true; // Include other objects in the updatedSourceList
        });
        sourceCard.attendees = updatedSourceAttendees;
        if (removedObj !== null) {
          destCard.attendees.push(removedObj);
        }
        newEventClassCardData[sInd - 1] = sourceCard;
        newEventClassCardData[dInd - 1] = destCard;
      }
    }
    newEventClassCardDataList[tabIdx] = newEventClassCardData;
    setEventClassCardDataList(newEventClassCardDataList);

    //console.log(modifyList);
    setAttendeeCardData(modifyList);
  };

  {
    /* dynamic Questions  */
  }
  const handleCreate = (
    questionText: any,
    questionType: QuestionType,
    inputOptions: any
  ) => {
    var options = [] as any;
    inputOptions?.map((item: any) => {
      var obj = {
        optionText: item,
      };
      options.push(obj);
    });
    const newQuestion = { questionText, questionType, options };
    setQuestions([...questions, newQuestion]);
    setFieldModalVisible(false);
    setMultipleChoiceModalVisible(false);
  };

  const handleDelete = (index: any) => {
    const newQuestions = questions.filter((_: any, i: any) => i !== index);
    setQuestions(newQuestions);
  };

  const onClickPdfVisible = () => {
    if(pdfVisible){
      setPdfVisible(!pdfVisible)
    }
    else{
      setPdfListViewVisible(false)
      setPdfVisible(!pdfVisible)
    }
  };
  const onClickPdfListViewVisible = () => {
    if(pdfListViewVisible){
      setPdfListViewVisible(!pdfListViewVisible)
    }
    else{
      setPdfVisible(false)
      setPdfListViewVisible(!pdfListViewVisible)
    }
  };
  
  return (
    <div className="create-edit-training">
      <Spin spinning={spinning} size="large">
      
      <div className="eq-create-training-container">
        <div className="">
          <Row gutter={gutter} className="event-info-input">
            <Col className="gutter-row" span={24}>
              {/* <EqCollapse {...eventCollapsibleProps}> */}
              {/* {(isEdit || !isTrainingSaved) && ( */}

              <>
                {isEdit && (
                  <Row>
                    <Space>
                      <Col>
                        {contextCopyMessage}
                        <CopyToClipboard text={getEmailsText()}>
                          <EqButton
                            disabled={isStudent}
                            type="submit"
                            classNames="btn-background-orange"
                            onClick={handleCopyLink}
                          >
                            {t("copyEmails")}
                          </EqButton>
                        </CopyToClipboard>
                      </Col>
                      <Col>
                        <CopyToClipboard text={getNumbersText()}>
                          <EqButton
                            disabled={isStudent}
                            type="submit"
                            classNames="btn-background-orange"
                            onClick={handleCopyLink}
                          >
                            {t("copyNumbers")}
                          </EqButton>
                        </CopyToClipboard>
                      </Col>{" "}
                    </Space>
                  </Row>
                )}
                <Row>
                  <Col span={24}>
                    {" "}
                    <div className="label">{t("selectImage")} </div>
                    <EqUpload
                      preview={false}
                      imagesData={imagesData}
                      selected={setSelectedImage}
                      defaultSelected={uiImage}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="gutter-row" span={24}>
                    <Row justify="space-between">
                      <Col className="col-1" xs={24} sm={24} md={12}>
                        <div className="eq-input-group">
                          <div className="input-label">{t("title")}</div>
                          <EqInput
                            disabled={isStudent}
                            className="name-input"
                            value={formData.title}
                            onBlur={(e: any) =>
                              onActivityTitleChange(e.currentTarget.value)
                            }
                            onChange={(event: any) =>
                              setFormData({
                                ...formData,
                                title: event?.target?.value,
                              })
                            }
                          />
                        </div>

                        <Row className="eq-input-group">
                          <Col className="input-label">{t("location")}</Col>
                          <Col span={24}>
                            <EqSelect
                              isDisabled={isStudent}
                              extraHeight={true}
                              {...areaSelectProps}
                              dropdownRender={(menu: any) => (
                                <>
                                  {menu}
                                  <Divider style={{ margin: "8px 0" }} />
                                  <Space
                                    align="center"
                                    style={{ padding: "0 8px 4px" }}
                                  >
                                    <Typography.Link
                                      onClick={() =>
                                        navigate(
                                          FrontEndPoints.trainer.editTrainer,
                                          {
                                            state: {
                                              prevPath: location.pathname,
                                            },
                                          }
                                        )
                                      }
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <PlusOutlined /> {t("createNew")}
                                    </Typography.Link>
                                  </Space>
                                </>
                              )}
                            />
                          </Col>
                        </Row>
                        {/* Show Data on 1nd Column on Create */}
                        {/* {!isEdit && (
                            <div className="date-time-inputs">
                              <Row className="eq-input-group">
                                <Col className="input-label">Date</Col>
                                <Col span={24}>
                                  <EqDatePicker
                                    isDisabled={isStudent}
                                    {...startOnProps}
                                  ></EqDatePicker>
                                </Col>
                              </Row>
                            </div>
                          )} */}

                        <div className="date-time-inputs">
                          <Row className="eq-input-group">
                            <Col className="input-label">
                              {t("selectYourDates")}
                            </Col>
                            <Col span={24}>
                              <EqMultiDatePicker
                                {...multipleStartOnProps}
                              ></EqMultiDatePicker>

                              {/* {isEdit == true ? (
                                  <EqDatePicker
                                    {...startOnProps}
                                  ></EqDatePicker>
                                ) : (
                                  <EqMultiDatePicker
                                    {...multipleStartOnProps}
                                  ></EqMultiDatePicker>
                                )} */}
                            </Col>
                          </Row>
                        </div>

                        <div className="date-time-inputs">
                          <Row className="eq-input-group">
                            <Col className="input-label">{t("currency")}</Col>
                            <Col span={24}>
                              <EqSelect
                                isDisabled={isStudent}
                                {...currencySelectProps}
                              />
                            </Col>
                          </Row>
                        </div>
                        {/* <div className="eq-input-group">
                            <div className="input-label">Capacity</div>
                            <EqInputNumber
                              min={1}
                              value={formData.maxSpaceCount}
                              onChange={(value: any) =>
                                setFormData({
                                  ...formData,
                                  maxSpaceCount: value,
                                })
                              }
                            />
                          </div> */}
                      </Col>
                      <Col className="col-2" xs={24} sm={24} md={12}>
                        {/*{isPaymentDeadline &&*/}
                        <div className="eq-input-group">
                          <div className="event-type">
                            <div className="input-label">{t("type")}</div>
                            <div className="toggler">
                              <EqSelect
                                isDisabled={isStudent}
                                {...typeSelectProps}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <Row className="eq-input-group">
                            <Col span={24} className="input-label">
                              Horses
                            </Col>
                            <Col span={24}>
                              <EqSelect {...horseAvailabilitySelectProps} />
                            </Col>
                          </Row> */}
                        {/* <Row className="eq-input-group">
                          <Col span={24} className="input-label">
                            Payment Deadline
                          </Col>
                          <Col span={24}>
                            <EqDatePicker
                              isDisabled={isStudent}
                              {...advancePaymentDateProps}
                            ></EqDatePicker>
                          </Col>
                        </Row> */}

                        <div className="eq-input-group">
                          <div className="input-label">{t("e-mail")}</div>
                          <EqInput
                            disabled={isStudent}
                            className="name-input"
                            value={formData.email}
                            onChange={(event: any) =>
                              setFormData({
                                ...formData,
                                email: event?.target?.value,
                              })
                            }
                          />
                        </div>
                        <div className="eq-input-group">
                          <div className="input-label">{t("phoneNumber")}</div>
                          <EqInput
                            disabled={isStudent}
                            className="name-input"
                            value={formData.phoneNumber}
                            onChange={(event: any) =>
                              setFormData({
                                ...formData,
                                phoneNumber: event?.target?.value,
                              })
                            }
                          />
                        </div>
                        {/* Show Data on 2nd Column on Edit */}
                        {/* {isEdit && (
                            <div className="date-time-inputs">
                              <Row className="eq-input-group">
                                <Col className="input-label">Date</Col>
                                <Col span={24}>
                                  <EqDatePicker
                                    isDisabled={isStudent}
                                    {...startOnProps}
                                  ></EqDatePicker>
                                </Col>
                              </Row>
                            </div>
                          )} */}

                        {/* <div className="date-time-inputs">
                            <Row className="eq-input-group">
                              <Col className="input-label">Start on Date</Col>
                              <Col span={24}>
                                {isPublic || isEdit == true ? (
                                  <EqDatePicker
                                    {...startOnProps}
                                  ></EqDatePicker>
                                ) : (
                                  <EqMultiDatePicker
                                    {...multipleStartOnProps}
                                  ></EqMultiDatePicker>
                                )}
                              </Col>
                            </Row>
                          </div> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <div className="eq-input-group">
                      <div className="input-label">{t("eventTerms")}</div>
                      <Upload
                        fileList={fileObj ? [fileObj] : []}
                        customRequest={handleChangeFile}
                        onRemove={() => handleDeleteFile()} // Call your delete function here
                      >
                        <Button icon={<UploadOutlined />}>
                          {t("clicktoUpload")}
                        </Button>
                      </Upload>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className="eq-input-group">
                      <div className="input-label">{t("description")}</div>
                      <TextArea
                        disabled={isStudent}
                        rows={4}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          })
                        }
                        value={formData.description}
                        className="profile-input text-area"
                      ></TextArea>
                    </div>
                  </Col>
                </Row>

                {/* dynamic Questions  */}

                <div className="dynamic-q-container">
                  <div className="add-buttons">
                    <Row>
                      <Col>
                        <EqButton
                          type="primary"
                          icon={<PlusOutlined />}
                          bordered
                          classNames="btn-border-text-orange"
                          onClick={() => setFieldModalVisible(true)}
                        >
                          {t("add") + " " + t("information") + " " + t("field")}
                        </EqButton>
                      </Col>
                      <Col>
                        <EqButton
                          type="primary"
                          icon={<PlusOutlined />}
                          bordered
                          classNames="btn-border-text-orange"
                          onClick={() => setMultipleChoiceModalVisible(true)}
                        >
                          {t("addMultipleChoice")}
                        </EqButton>
                      </Col>
                    </Row>
                  </div>
                  {questions.length > 0 && (
                    <div className="question-list">
                      <div className="table-header">
                        <span className="header-cell">{t("label")}</span>
                        <span className="header-cell">{t("options")}</span>
                        <span className="header-cell action-cell">
                          {t("actions")}
                        </span>
                      </div>
                      {questions.map((question: any, index: any) => (
                        <div key={index} className="question-item">
                          <span className="question-cell">
                            {question.questionText}
                          </span>
                          <div className="options-cell">
                            {question.options &&
                              question.options.map(
                                (option: any, optionIndex: any) => (
                                  <Tag key={optionIndex} className="option-tag">
                                    {option.optionText}
                                  </Tag>
                                )
                              )}
                          </div>
                          <span className="actions-cell">
                            <img
                              alt=""
                              src="/trash-alt-red.svg"
                              onClick={() => handleDelete(index)}
                            />
                            {/* <DeleteOutlined
                            className="delete-icon"
                            onClick={() => handleDelete(index)}
                          /> */}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                  <EqQuestionModal
                    visible={fieldModalVisible}
                    type={QuestionType.Text}
                    onCreate={handleCreate}
                    onCancel={() => setFieldModalVisible(false)}
                  />

                  <EqQuestionModal
                    visible={multipleChoiceModalVisible}
                    type={QuestionType.MultipleChoice}
                    onCreate={handleCreate}
                    onCancel={() => setMultipleChoiceModalVisible(false)}
                  />
                </div>
                <Divider
                  dashed
                  style={{ backgroundColor: "#FF8838", borderWidth: "1px" }}
                />

                {/* <Row 
        // justify="center" 
        className="label">{t("addClasses")} </Row> */}
                <Row align="middle">
                  <Col className="classLabel">
                    {t("add") + " " + t("field")}
                  </Col>
                  {/* <Col className="col-2">
                    <div className="input-label">{t("fieldTitleChange")} </div>
                  </Col>
                  <Col className="col-1" xs={24} sm={24} md={8}>
                    <div>
                      <EqInput
                        placeHolder="write here..."
                        disabled={isStudent}
                        className="name-input"
                        value={formData.customClassName}
                        onChange={(event: any) =>
                          setFormData({
                            ...formData,
                            customClassName: event?.target?.value,
                          })
                        }
                      />
                    </div>
                  </Col> */}
                </Row>
                <Row align="middle">
                  <Col className="col-1">
                    {/* <div className="input-label">{t("classTitleChange")} </div> */}
                    <div className="input-label">{t("fieldTitleChange")} </div>
                  </Col>
                  <Col className="col-2" xs={24} sm={24} md={8}>
                    <div>
                      {/* <div className="input-label">{t("classTitleChange")}</div> */}
                      <EqInput
                        placeHolder="write here..."
                        disabled={isStudent}
                        className="name-input"
                        value={formData.customClassName}
                        onChange={(event: any) =>
                          setFormData({
                            ...formData,
                            customClassName: event?.target?.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-1" xs={24} sm={24} md={12}>
                    <div className="eq-input-group">
                      <div className="input-label">
                        {t("wantToPreviewFields")}
                      </div>
                      <Switch
                        className="switch-input"
                        checkedChildren={t("showClasses")}
                        unCheckedChildren={t("dontShowClasses")}
                        defaultChecked={formData.isClassPreviewAllowed}
                        checked={formData.isClassPreviewAllowed}
                        onChange={(checked: any) =>
                          setFormData({
                            ...formData,
                            isClassPreviewAllowed: checked,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
                {multipleStartOn && multipleStartOn.length > 0 ? (
                  <>
                    <Tabs type="card" id="printableContent">
                      {multipleStartOn.map((tabDate: any, tabIdx: any) => (
                        <TabPane
                          tab={moment(tabDate).format(dateFormat)}
                          key={tabIdx}
                        >
                          <>
                            <Row
                              // ref={componentRef}
                              className="attendee-card-section"
                              align="middle"
                              justify="space-around"
                            >
                              <Col xs={24} sm={24} md={24}>
                                <div className="attendee-card-box">
                                  <DragDropContext
                                    onDragEnd={(e: any) => {
                                      onDragAttendee(e, tabIdx);
                                    }}
                                  >
                                    <>
                                      {eventClassCardDataList &&
                                      eventClassCardDataList.length > 0 &&
                                      eventClassCardDataList[tabIdx] &&
                                      eventClassCardDataList[tabIdx].length >
                                        0 ? (
                                        eventClassCardDataList[tabIdx]?.map(
                                          (
                                            item: IEqEventClassCardData,
                                            index: number
                                          ) => (
                                            <Droppable
                                              // isDropDisabled={ isStudent}
                                              key={item.cardkey}
                                              droppableId={`${item.cardkey.toString()}`}
                                            >
                                              {(provided) => (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  <EqEventClassCard
                                                    key={item.cardkey}
                                                    // key={index}
                                                    {...{
                                                      tabIndex: tabIdx,
                                                      data: item,
                                                      timeFromChange:
                                                        eventClassCardTimeFromChange,

                                                      eventClassCardHeightChange:
                                                        eventClassCardHeightChange,
                                                      eventClassCardClassDescriptionChange:
                                                        eventClassCardClassDescriptionChange,

                                                      // timeToChange:
                                                      //   eventClassCardTimeToChange,
                                                      // durationChange:
                                                      //   eventClassCardDurationChange,
                                                      maxSpaceCountChange:
                                                        eventClassCardAvailableSpaceChange,
                                                      eventClassCardPriceChange:
                                                        eventClassCardPriceChange,
                                                      // trainingLevelChange:
                                                      //   eventClassCardTrainingLevelChange,
                                                      selectedDateChange:
                                                        eventClassCardSelectedDateChange,
                                                      // attendeesChange:
                                                      //   eventClassCardAttendeesChange,
                                                      eventClassCardHorseChange:
                                                        eventClassCardHorseChange,
                                                      removeCard:
                                                        removeEventClassCard,
                                                      removeAttendeeFromCard:
                                                        removeAttendeeFromCard,
                                                      groupKeys: [...groupKeys],
                                                      selectedGroups: [
                                                        ...selectedGroups,
                                                      ],
                                                      groupStudentPopulation: [
                                                        ...groupStudentPopulation,
                                                      ],
                                                      studentsByGroup: [
                                                        ...studentsByGroup,
                                                      ],
                                                      studentPopulation:
                                                        studentPopulation,
                                                      searchStudentsList: [
                                                        ...searchStudentsList,
                                                      ],
                                                      studentHorsesList:
                                                        studentHorsesList,
                                                      multipleStartOn: [
                                                        ...multipleStartOn,
                                                      ],
                                                      // startDate: formData.startDate,
                                                      startDate: tabDate,
                                                      trainingType:
                                                        formData.disciplineType,
                                                      isStudent: isStudent,
                                                      isEdit: isEdit,
                                                      enableCardRemove:
                                                        eventClassCardDataList[
                                                          tabIdx
                                                        ]?.length > 0
                                                          ? true
                                                          : false,
                                                    }}
                                                  ></EqEventClassCard>
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          )
                                        )
                                      ) : (
                                        <Row className="empty-card">
                                          <Col span={24}>
                                            <ToolFilled
                                              style={{ fontSize: "2rem" }}
                                            />
                                          </Col>

                                          <Col>{t("noActivityYet")}...</Col>
                                        </Row>
                                      )}
                                      {/* {!isEdit && ( */}
                                      <Row
                                        className="add-btn-icon"
                                        // onClick={()=>addNewEventClassCard(dateIndex)}
                                        onClick={() =>
                                          addNewEventClassCardToDate(
                                            tabIdx,
                                            tabDate
                                          )
                                        }
                                      >
                                        <img
                                          alt=""
                                          className="add"
                                          src="/plus-black.svg"
                                        />
                                      </Row>
                                      {/* )} */}
                                    </>
                                  </DragDropContext>
                                </div>
                              </Col>
                            </Row>
                            <Modal
                              title="Row Details"
                              open={modalVisible}
                              // onCancel={handleModalClose}
                              footer={[
                                <Button
                                  key="close"
                                  onClick={() => setModalVisible(false)}
                                >
                                  Close
                                </Button>,
                                <Button
                                  key="print"
                                  type="primary"
                                  onClick={handlePrint}
                                >
                                  Print
                                </Button>,
                              ]}
                            >
                              <div ref={componentRef}>
                                <Card
                                  title="Row 1"
                                  style={{ marginBottom: 16 }}
                                >
                                  <p>Some content in Row 1</p>
                                  <Button
                                  // onClick={() => handleRowCopy("Row 1")}
                                  >
                                    Copy to Modal
                                  </Button>
                                </Card>

                                <Card
                                  title="Row 2"
                                  style={{ marginBottom: 16 }}
                                >
                                  <p>Some content in Row 2</p>
                                  <Button
                                  // onClick={() => handleRowCopy("Row 2")}
                                  >
                                    Copy to Modal
                                  </Button>
                                </Card>
                              </div>
                            </Modal>
                          </>
                        </TabPane>
                      ))}
                    </Tabs>
                  </>
                ) : (
                  <></>
                )}
                {/* {!isStudent && (
                    <Row justify="center" align="bottom" className="eq-cg-btn">
                      {!isEdit && (
                        <>
                          <Col className="eq-cg-buttons">
                            <EqButton
                              disabled={isStudent}
                              type="submit"
                              classNames="btn-background-orange eq-save-btn"
                              onClick={() => OnSaveTraining()}
                              // onClick={() => setIsEventCollapse(false)}
                            >
                              Save
                            </EqButton>
                          </Col>
                          <Col className="eq-cg-buttons">
                            <EqButton
                              type="button"
                              bordered="true"
                              classNames="btn-light-gray"
                              onClick={onCancelActivity}
                            >
                              {t("cancel")}
                            </EqButton>
                          </Col>
                        </>
                      )}
                    </Row>
                  )} */}
              </>

              {/* </EqCollapse> */}
              {/* {(isEdit || isTrainingSaved) && ( */}
              {true && (
                <Row justify="center" align="bottom" className="eq-cg-btn">
                  <Col className="eq-cg-buttons">
                    {isEdit ? (
                      !isStudent && (
                        <EqButton
                          block
                          disabled={isStudent}
                          type="submit"
                          classNames="btn-background-orange"
                          onClick={UpdateEventWithClass}
                          // onClick={editTraining}
                        >
                          {t("saveChanges")}
                        </EqButton>
                      )
                    ) : (
                      <EqButton
                        disabled={isStudent}
                        type="submit"
                        classNames="btn-background-orange"
                        onClick={createEvent}
                      >
                        {t("create")}
                      </EqButton>
                    )}
                  </Col>

                  {!isEdit && (
                    <Col className="eq-cg-buttons">
                      <EqButton
                        type="button"
                        bordered="true"
                        classNames="btn-light-gray"
                        onClick={onCancelActivity}
                      >
                        {t("cancel")}
                      </EqButton>
                    </Col>
                  )}
                </Row>
              )}
              {isEdit && (
                <Space>
                  <Button
                  type="primary"
                  onClick={onClickPdfVisible}
                >
                  {t("pdfPreview")}
                </Button>
                <Button
                  type="primary"
                  onClick={onClickPdfListViewVisible}
                >
                  {t("Pdf Preview List")}
                </Button>
                </Space>
           
              )}
              {eventData && pdfVisible && (
                <EqEventPdfGenerate
                  event={eventData}
                  selectedDates={multipleStartOn}
                ></EqEventPdfGenerate>
              )}
              {eventData && pdfListViewVisible && (
                <EqEventPdfGenerateListView
                  event={eventData}
                  selectedDates={multipleStartOn}
                ></EqEventPdfGenerateListView>
              )}
        

              {!isEdit && (
                <Button
                  type="primary"
                  onClick={() => {
                    setPreviewVisible(!previewVisible);
                    createEventPreview();
                  }}
                >
                  {t("preview")}
                </Button>
              )}
              {event && previewVisible && (
                <div className="section">
                  <Row className="section-heading">{t("eventClasses")}</Row>
                  <Row>
                    <EventClass event={event}></EventClass>
                  </Row>
                  <EqJoinEvent event={event}></EqJoinEvent>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
      </Spin>
      {contextHolder}
    </div>
  );
};
export default CreateOrEditEvent;
