import * as React from "react";
import { Row, Col, Popover, notification, Popconfirm, Modal } from "antd";
import "./EqSchoolTrainingCollapseDashBoard.scss";
import { Tag } from "antd";
import EqCollapse, { IEqCollapse } from "./../../custom/eqCollapse/EqCollapse";
import { IGroupDto } from "../../shared/interfaces/IGroupDto";
import { useTranslation, Trans } from "react-i18next";

import {
    EqDisciplineType,
    EqEventAccessType,
    EqEventUserType,
    EqSlotStatusEnum,
    EqTrainingType,
    eqNotificationTypes,
} from "../../shared/enums";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { IStudentGroupDto } from "../../shared/interfaces/IStudentGroupDto";

import {
    addDays,
    ApiEndpoints,
    checkDuePayment,
    FrontEndPoints,
    dateFormat,
    getDefaultImage,
    getEmailFromToken,
    GetEventMessage,
    imageType,
    IsStudentUnderAge,
    ValidateIsParent,
    ValidateIsSchool,
    ValidateIsTrainer,
    ValidateIsUser,
    ValidateStudentCanBook,
    ValidateStudentCanPay,
    ValidateIsStudent,
} from "../../shared/Common";
import { IEventUserGroupDto } from "../../shared/interfaces/IEventUserGroupDto";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { IUserProfileDto } from "../../shared/interfaces/IUserProfileDto";
import { loadState, saveEvent } from "../../shared/LocalStorage";
import { useAuthState } from "../../context/Context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import EqActivityStudentView from "../../eqStudent/eqActivityStudentView/EqActivityStudentView";
import EqButton from "../../custom/eqButton/EqButton";
import { ISubscribeEventDto } from "../../shared/interfaces/ISubscribeEventDto";
import Api from "../../shared/Api";
import {
    EqIconAttendee,
    EqIconCalendar,
    EqIconLocation,
    EqIconPrice,
    EqIconUser,
} from "../../custom/eqIcon/EqIcons";
import Tooltip from "antd/es/tooltip";
import CreateOrEditSchoolTraining from "../createOrEditSchoolTraining/CreateOrEditSchoolTraining";
import EqTags from "../../custom/eqTags/EqTags";

export interface IEqSchoolTrainingCollapse {
    training: any;
    trainersOptions: any;
    productOptions: any;
    products: any;
    areas?: any;
    weekDays?: any;
    eventTypes: any;
    population?: any;
    groupPopulation?: any;
    trainerStudents?: any;
    studentHorsesList?: any;
    schoolStudents?: any;
    removeEventFunc?: (trainingData: any, refund?: any) => void;
    removeAttendeeFunc?: (data: any) => void;
    onCreateOrUpdateSchoolTraining?: any;
}
//IEqEvent
const EqSchoolTrainingCollapseDashBoard = ({
    training,
    trainersOptions,
    productOptions,
    products,
    areas,
    weekDays,
    eventTypes,
    population,
    groupPopulation,
    trainerStudents,
    studentHorsesList,
    schoolStudents,
    removeEventFunc,
    removeAttendeeFunc,
    onCreateOrUpdateSchoolTraining,
    ...rest
}: any) => {
    const userDetails: any = useAuthState();
    const isLoggedInUser: boolean = ValidateIsUser(userDetails);
    const isTrainer: boolean = ValidateIsTrainer(userDetails);
    const isSchool: boolean = ValidateIsSchool(userDetails);
    const isStudent: boolean = ValidateIsStudent(userDetails);
    const isParent: boolean = ValidateIsParent(userDetails);
    const isStudentUnderAge: boolean = IsStudentUnderAge(userDetails);
    const studentCanBook: boolean = ValidateStudentCanBook(userDetails);
    const studentCanPay: boolean = ValidateStudentCanPay(userDetails);

    //Modal
    const [modalRefundPayment, contextRefundPayment] = Modal.useModal();

    const currentUserKey = getEmailFromToken();
    const { t, i18n } = useTranslation();

    const collapseInnerRef = React.useRef<any>(null);
    const [collapseInnerHeight, setCollapseInnerHeight] = React.useState(0);

    const [isError, setIsError] = React.useState(false);
    const [message, setMessage] = React.useState("");
    // subscription Related
    const [slotRange, setSlotRange] = React.useState({
        allSlotRanges: [],
        selectedSlotRange: [],
        currentUserSlots: [],
        currentSelection: {},
        isSlotSelected: false,
    } as any);
    const [subscriptionStatus, setSubscriptionStatus] = React.useState({
        buttonDisabled: false,
        buttonTextSubscribe: "Join",
        buttonTextSubscribeAll: "Join all",
    } as any);
    const [isEventSubscribed, setIsEventSubscribed] = React.useState(false);
    const [organizers, setOrganizers] = React.useState(
        training?.organizer as IUserProfileDto[]
    );
    const [isPaidByUser, setIsPaidByUser] = React.useState(false);

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayTime = today.getTime();
    //
    //console.log(userDetails);
    //console.log(training.organizers);
    const [isAttendee, setIsAttendee] = React.useState(false);
    const [studentGroups, setStudentGroups] = React.useState([]) as any;
    const [isOwner, setIsOwner] = React.useState(false);
    const [isStudentTrainings, setIsStudentTrainings] = React.useState(false);
    const [toggleRow, setToggleRow] = React.useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const [path, setPath] = React.useState(location.pathname);
    const [activityTitle, setActivityTitle] = React.useState("");

    const openNotificationCheckOutNotification = (
        msg: any,
        desc: any,
        training: any
    ) => {
        const btn = (
            <div className="right">
                <EqButton onClick={CreateCheckoutSession}>Pay Now</EqButton>
            </div>
        );
        notification["info"]({
            message: msg,
            btn,
            description: desc,
        });
    };

    const checkStudentTrainings = () => {
        let pathname = location.pathname;

        if (pathname == FrontEndPoints.student.allTrainings) {
            setIsStudentTrainings(true);
        } else {
            setIsStudentTrainings(false);
        }
    };
    const checkEventOwner = () => {
        //console.log(training?.organizers);
        var owner = training?.attendees
            ?.filter((o: any) => {
                return (
                    o.user.key == userDetails.key &&
                    o.eventUserType == EqEventUserType.Owner
                );
            })
            .shift();
        //console.log(owner);
        if (owner != null || undefined) {
            setIsOwner(true);
        } else {
            setIsOwner(false);
        }
    };
    const checkEventAttendee = () => {
        //console.log(training?.attendees);
        var attendee = training?.attendees
            ?.filter((a: any) => {
                return (
                    a.user.key == userDetails.key &&
                    a.eventUserType == EqEventUserType.Attendee
                );
            })
            .shift();
        //console.log(attendee);
        if (attendee != null || undefined) {
            setIsAttendee(true);
        }
    };
    //console.log(training);
    const getEventGroups = () => {
        //console.log(training);
        var studentGroups = training?.attendees?.filter(
            (x: any) => x.group != null
        );
        studentGroups = studentGroups?.map((d: any) => d.group);
        const result = [];
        const map = new Map();
        if (studentGroups != null && studentGroups.length > 0) {
            for (const item of studentGroups) {
                if (!map.has(item.key)) {
                    map.set(item.key, true); // set any value to Map
                    result.push(item);
                }
            }
        }
        setStudentGroups(result);
    };
    const handleChangeActivityTitle = (e: any) => {
        setActivityTitle(e);
    };

    const removeEvent = (trainingArgs: any) => {
        trainingArgs.stopPropagation();

        if (!isAttendee) {
            if (removeEventFunc != null) {
                removeEventFunc(training);
            }
        }
    };

    const handleDeleteTraining = (e: any) => {
        e.stopPropagation();
        if (removeEventFunc != null) {
            confirmPaymentRefund(training);
        }
    };

    const confirmPaymentRefund = (training: any) => {
        modalRefundPayment.confirm({
            title: "Confirm",
            icon: <ExclamationCircleOutlined />,
            content: "Would you like to Refund Attendee",
            okText: "Refund",
            cancelText: "Pay Later",
            onOk: () => removeEventFunc(training, true),
            onCancel: () => removeEventFunc(training, false),
        });
    };

    const removeAttendee = (trainingArgs: any) => {
        trainingArgs.stopPropagation();
        removeAttendeeFunc(training);
    };

    // Subscription Related
    const viewEvent = (training: IEqEventData) => {
        saveEvent(training);
        const key = `open${Date.now()}`;
        notification.close(key);
        navigate(`${FrontEndPoints.student.viewEvent}?key=${training.key}`, {
            state: {
                training: training,
            },
        });
    };
    const openNotificationWithIcon = (msg: any, desc: any, training: any) => {
        const key = `open${Date.now()}`;
        const btn = (
            <EqButton
                type="button"
            // onClick={() => viewEvent(training)}
            >
                Pay Now
            </EqButton>
        );
        notification["error"]({
            message: msg,
            btn,
            key,
            description: desc,
        });
    };

    const updateSubscribeButtonState = (
        isSlotSelected: boolean,
        currentUserSlots: any,
        allSlots: any
    ) => {
        // console.log(currentUserSlots);
        if (training?.disciplineType != EqDisciplineType.Open) {
            if (isEventSubscribed) {
                console.log("joined");
                setSubscriptionStatus({
                    ...subscriptionStatus,
                    buttonDisabled: true,
                    buttonTextSubscribe: t("Joined"),
                });
            } else {
                setSubscriptionStatus({
                    ...subscriptionStatus,
                    buttonDisabled: false,
                    buttonTextSubscribe: t("Join"),
                });
            }
        } else if (
            allSlots?.length == 0 &&
            training?.disciplineType == EqDisciplineType.Open
        ) {
            setSubscriptionStatus({
                ...subscriptionStatus,
                buttonDisabled: true,
                buttonTextSubscribe: t("Join"),
            });
        } else if (currentUserKey?.length > 0) {
            if (training?.disciplineType == EqDisciplineType.Open) {
                if (isSlotSelected == false) {
                    setSubscriptionStatus({
                        ...subscriptionStatus,
                        buttonDisabled: true,
                        buttonTextSubscribe: t("Join"),
                    });
                } else {
                    setSubscriptionStatus({
                        ...subscriptionStatus,
                        buttonDisabled: false,
                        buttonTextSubscribe: t("Join"),
                    });
                }
            } else {
                if (currentUserSlots?.length > 0) {
                    setSubscriptionStatus({
                        ...subscriptionStatus,
                        buttonDisabled: true,
                        buttonTextSubscribe: t("Joined"),
                    });
                } else {
                    setSubscriptionStatus({
                        ...subscriptionStatus,
                        buttonDisabled: false,
                        buttonTextSubscribe: t("Join"),
                    });
                }
            }
        } else {
            setSubscriptionStatus({
                ...subscriptionStatus,
                buttonDisabled: true,
                buttonTextSubscribe: t("Joined"),
            });
        }
    };
    const setTimeSlots = (training: any) => {
        var allSlots = [] as any;
        let currentUserRange = [] as any;
        let selectedRange = [] as any;

        training?.attendees?.map((attendee: any) => {
            if (attendee.slotStartTime > 0) {
                let slotStartTime = moment(attendee.slotStartTime);
                let slotEndTime = moment(attendee.slotStartTime).hours(
                    moment(attendee.slotStartTime).hours() + 1
                );

                let slot = {
                    key: slotStartTime.valueOf(),
                    value:
                        slotStartTime.format("HH:mm") + "-" + slotEndTime.format("HH:mm"),
                };

                selectedRange.push(slot);

                if (attendee.user?.email == currentUserKey) {
                    currentUserRange.push(slot);
                }
            }
        });

        if (training?.disciplineType == EqDisciplineType.Open) {
            let slotStartTime = moment(training?.startTime);
            let slotEndTime = moment(training?.startTime).hours(
                moment(training.startTime).hours() + 1
            );

            let totalHours = Math.floor(
                (training.endTime - training.startTime) / (3600 * 1000)
            );

            let currentTags = currentUserRange;

            for (var hour = 0; hour < totalHours; hour++) {
                let slot = {
                    key: slotStartTime.valueOf(),
                    value:
                        slotStartTime.format("HH:mm") + "-" + slotEndTime.format("HH:mm"),
                };

                if (!selectedRange?.some((x: any) => x.key == slot.key)) {
                    allSlots.push(slot);
                }

                slotStartTime = slotStartTime.hours(slotStartTime.hours() + 1);
                slotEndTime = slotEndTime.hours(slotStartTime.hours() + 1);
            }
        }

        setSlotRange({
            ...slotRange,
            allSlotRanges: allSlots,
            selectedSlotRange: selectedRange,
            currentUserSlots: currentUserRange,
        });
        updateSubscribeButtonState(false, currentUserRange, allSlots);
    };

    const setupEvent = (evt: any) => {
        // console.log(evt, evt?.attendees?.length, evt.maxAvailableSpace);
        setTimeSlots({ ...evt });
        if (evt && evt.organizers) {
            setOrganizers(evt.organizers);
        }
        // replacing userKey with Key
        if (evt?.attendees?.some((item: any) => item.key == currentUserKey)) {
            if (
                evt?.attendees?.some(
                    (item: any) => item.key == currentUserKey && item.userStatus != 1
                )
            ) {
                setIsEventSubscribed(false);
                setSubscriptionStatus({
                    ...subscriptionStatus,
                    buttonDisabled: false,
                    buttonTextSubscribe: t("Join"),
                });
            } else {
                setIsEventSubscribed(true);
                setSubscriptionStatus({
                    ...subscriptionStatus,
                    buttonDisabled: true,
                    buttonTextSubscribe: t("Joined"),
                });

                if (
                    evt?.payments?.some((item: any) => item.user?.email == currentUserKey)
                ) {
                    setIsPaidByUser(true);
                }
            }
        } else if (
            evt?.maxAvailableSpace &&
            evt?.maxAvailableSpace > 0 &&
            evt?.attendees &&
            evt?.attendees?.length > 0 &&
            evt?.attendees?.length > evt.maxAvailableSpace
        ) {
            setSubscriptionStatus({
                ...subscriptionStatus,
                buttonDisabled: true,
                buttonTextSubscribe: t("Join"),
            });
        } else {
            setSubscriptionStatus({
                ...subscriptionStatus,
                buttonDisabled: false,
                buttonTextSubscribe: t("Join"),
            });
        }
    };

    const dateTomorrow = () => {
        var result = addDays(new Date(), 1).getTime();
        return result;
    };

    const CreateCheckoutSession = () => {

        var trainingKey = training?.key;
        Api.post(ApiEndpoints.StudentTrainingStripeCheckout.Create, {
            key: trainingKey,
        })
            .then((response) => {
                console.log(response);
                window.location.href = response.data.sessionUrl;
            })
            .catch((error) => {
                openNotificationWithIcon(
                    "Error!",
                    "Unable to Create checkout",
                    eqNotificationTypes.error
                );
            });
    };

    const subscribeTraining = (e: any) => {
        e.stopPropagation();

        console.log(userDetails);
        console.log(isParent);
        if (isLoggedInUser == false) {
            navigate(FrontEndPoints.login);
        } else {
            let selected = slotRange.currentSelection;

            let data: ISubscribeEventDto = {
                ownerKey: organizers != undefined ? organizers[0].email : "",
                subscriberKey: currentUserKey,
                eventKey: training.key,
                slotStartTime: selected?.key,
                slotStatus: EqSlotStatusEnum.Booked,
                eventUserType: EqEventUserType.Attendee,
            };

            checkDuePayment(openNotificationWithIcon).then(
                (success: any) => {
                    //    console.log("success", success);
                    if (success == false) {
                        Api.post(ApiEndpoints.SubscribeTrainer, data).then(
                            (response) => {
                                setIsEventSubscribed(true);
                                setSubscriptionStatus({
                                    ...subscriptionStatus,
                                    buttonDisabled: true,
                                    buttonTextSubscribe: t("Joined"),
                                });

                                if (response && response.data != null) {
                                    //console.log(response);
                                    openNotificationCheckOutNotification(
                                        "Pay for the accepted Activity",
                                        "",
                                        training
                                    );

                                    onCreateOrUpdateSchoolTraining();

                                    let currentUserList = [
                                        ...slotRange.currentUserSlots,
                                        selected,
                                    ];
                                    let selectedList = [...slotRange.selectedSlotRange, selected];
                                    let remaingAllList = slotRange.allSlotRanges.filter(
                                        (x: any) => x.key != selected.key
                                    );

                                    setSlotRange({
                                        allSlotRanges: remaingAllList,
                                        selectedSlotRange: selectedList,
                                        currentUserSlots: currentUserList,
                                        currentSelection: {},
                                        isSlotSelected: false,
                                    });
                                    updateSubscribeButtonState(
                                        false,
                                        currentUserList,
                                        remaingAllList
                                    );
                                }
                            },
                            (error) => {
                                setIsError(true);
                                setMessage(GetEventMessage(error.response.status));
                                setIsEventSubscribed(false);
                            }
                        );
                    }
                },
                (err: any) => {
                    console.log("error", err);
                    setIsError(true);
                    setMessage(GetEventMessage(err.response.status));
                    setIsEventSubscribed(false);
                }
            );
        }
    };

    //

    React.useEffect(() => {
        // console.log("path", path);

        setCollapseInnerHeight(collapseInnerRef.current.clientHeight);

        getEventGroups();
        checkEventOwner();
        checkEventAttendee();
        checkStudentTrainings();
        // copied from ViewEvent
        setupEvent(training);
    }, [training]);
    return (
        <div className="eq-activity-collapse">
            <EqCollapsePanel
                header={
                    <>
                        <div className="eq-activity-row">
                            <Row className="eq-status-row-border" align="middle">
                                <Col className="content-col">
                                    <Row>
                                        <Col>
                                            <div className="date-icon">
                                                <EqIconCalendar
                                                    eqWidth={34}
                                                    eqHeight={34}
                                                    eqClass="eq-cal-icon"
                                                    eqFill="#083b49"
                                                />
                                                <div className="day">
                                                    {moment(training.startDate).format("DD")}
                                                </div>
                                                <div className="month">
                                                    {moment(training.startDate).format("MMM")}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="header-details">
                                            <Row>
                                                <Col></Col>
                                                <Col>
                                                    <span className="time">
                                                        {moment(training.startTime).format("hh:mm A")} -{" "}
                                                        {training.disciplineType != EqDisciplineType.Open
                                                            ? moment(training.startTime)
                                                                .add(training.duration, "minutes")
                                                                .format("hh:mm A")
                                                            : moment(training.endTime).format("hh:mm A")}
                                                    </span>
                                                </Col>
                                                <Col>
                                                    <div className="">
                                                        <EqTags
                                                            dicipline={true}
                                                            text={t(EqTrainingType[training.disciplineType])}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-trancated">
                                                    <span className="location">
                                                        <EqIconLocation
                                                            eqWidth={13}
                                                            eqHeight={16}
                                                            // eqFill="#b5b5b5"
                                                        />
                                                        {training?.location?.city}
                                                    </span>
                                                </Col>
                                                <Col className="hidden-sm col-trancated">
                                                    {studentGroups?.map(
                                                        (item: IGroupDto, index: number) => (
                                                            <EqTags
                                                                // className={`eq-group-color-${(index % 4) + 1}`}
                                                                color={item.color}
                                                                key={index}
                                                                text={item.name}
                                                            />
                                                        )
                                                    )}
                                                </Col>
                                                <Col>
                                                    <span className="trainer">
                                                    {training.organizers &&
                                                            training.organizers.length > 0 &&
                                                            training.organizers.map(
                                                                (item: any, index: number) => (
                                                                    item.eventUserType === 1 && (
                                                                    <span className="trainer">
                                                                        <EqIconUser
                                                                            eqWidth="16"
                                                                            eqHeight="16"
                                                                            eqFill="#b5b5b5"
                                                                        />
                                                                        <Link
                                                                            to={`${FrontEndPoints.school.profileBoard}?key=${item.user.key}`}
                                                                            onClick={(e: any) => {
                                                                                e.stopPropagation();
                                                                            }}
                                                                            className="trainer-link"
                                                                        >
                                                                            {item.user.firstName || item.user.email}
                                                                        </Link>
                                                                    </span>)
                                                                )
                                                            )}
                                                    </span>
                                                </Col>
                                                {isStudent ? (
                                                    <>
                                                        <Col>
                                                            <span className="attendees">
                                                                <EqIconAttendee
                                                                    eqWidth="15"
                                                                    eqHeight="15"
                                                                    eqFill="#b5b5b5"
                                                                />
                                                                {training.attendees?.length || "0"}/
                                                                {training.maxAvailableSpace}
                                                            </span>
                                                        </Col>
                                                        <Col>
                                                            <span className="price">
                                                                <EqIconPrice
                                                                    eqWidth="15"
                                                                    eqHeight="13"
                                                                    eqFill="#b5b5b5"
                                                                />{" "}
                                                                {training.price}
                                                            </span>
                                                        </Col>
                                                    </>
                                                ) : (
                                                    ""
                                                )}

                                                <Col>
                                                    <div className="eq-event-type">
                                                        {training.accessType ===
                                                            EqEventAccessType.Private &&
                                                            training.disciplineType ===
                                                            EqDisciplineType.RidingCourse &&
                                                            training?.lessonNumber &&
                                                            training?.product && (
                                                                <EqTags
                                                                    text={
                                                                        training?.lessonNumber
                                                                            ? t("lesson") + (" ") +
                                                                            training?.lessonNumber +
                                                                            "/" +
                                                                            training?.product?.lessons
                                                                            : ""
                                                                    }
                                                                    dicipline={true}
                                                                />
                                                            )}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <span className="title">{training.title}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="action-col">
                                    <Row justify="end">
                                        {/* // Show Delete if user is owner here  */}
                                        {isOwner && (
                                            <Col span={24}>
                                                <div className="eq-training-actions">
                                                    <div className="eq-training-actions-trash">
                                                        <span>
                                                            <Popconfirm
                                                                placement="bottomRight"
                                                                title={"Are you sure you want to delete?"}
                                                                okText="Yes"
                                                                cancelText="No"
                                                                // onConfirm={removeEvent}
                                                                onConfirm={handleDeleteTraining}
                                                                onCancel={(e: any) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            // onConfirm={() => setCreateActivityList([])}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    // src="/trash-alt.svg"
                                                                    src="/trash-alt-red.svg"
                                                                    onClick={(e: any) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                />
                                                            </Popconfirm>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        )}
                                        {isStudent && (
                                            <>
                                                {subscriptionStatus.buttonTextSubscribe == "Join" ? (
                                                    <>
                                                        <Col span={24}>
                                                            <Tooltip
                                                                title={
                                                                    training.startDate < todayTime
                                                                        ? "Date Passed"
                                                                        : ""
                                                                }
                                                            >
                                                                <EqButton
                                                                    // {...{size:"small"}}
                                                                    type="button"
                                                                    onClick={subscribeTraining}
                                                                    disabled={
                                                                        (isStudentUnderAge && !studentCanBook) ||
                                                                            training.startDate < todayTime
                                                                            ? true
                                                                            : false
                                                                    }
                                                                // disabled={
                                                                //   training.startDate > todayTime
                                                                //   ? subscriptionStatus.buttonDisabled
                                                                //     : true
                                                                // }
                                                                >
                                                                    {subscriptionStatus.buttonTextSubscribe}
                                                                </EqButton>
                                                            </Tooltip>
                                                        </Col>
                                                    </>
                                                ) : !isPaidByUser ? (
                                                    training.startTime > dateTomorrow() ? (
                                                        <>
                                                            <Col span={18}>
                                                                <div className="right">
                                                                    <div>
                                                                        <EqButton
                                                                            disabled={
                                                                                !studentCanPay ||
                                                                                    training.startDate < todayTime
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onClick={CreateCheckoutSession}
                                                                        >
                                                                            Pay Now
                                                                        </EqButton>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col
                                                                span={4}
                                                                className="eq-training-actions-trash"
                                                            >
                                                                <span>
                                                                    <Popconfirm
                                                                        placement="bottomRight"
                                                                        title={
                                                                            "Are you sure you want to Cancel your Training?"
                                                                        }
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                        onConfirm={removeAttendee}
                                                                        onCancel={(e: any) => {
                                                                            e.stopPropagation();
                                                                        }}
                                                                    // onConfirm={() => setCreateActivityList([])}
                                                                    >
                                                                        <img
                                                                            alt=""
                                                                            // src="/trash-alt.svg"
                                                                            src="/trash-alt-red.svg"
                                                                            onClick={(e: any) => {
                                                                                e.stopPropagation();
                                                                            }}
                                                                        />
                                                                    </Popconfirm>
                                                                </span>
                                                            </Col>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Col span={24}>
                                                                <div className="right">
                                                                    <EqButton
                                                                        disabled={
                                                                            !studentCanPay ||
                                                                                training.startDate < todayTime
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        onClick={CreateCheckoutSession}
                                                                    >
                                                                        Pay Now
                                                                    </EqButton>
                                                                </div>
                                                            </Col>
                                                        </>
                                                    )
                                                ) : (
                                                    <Col span={24}>
                                                        <EqButton
                                                            type="button"
                                                            onClick={subscribeTraining}
                                                            disabled={subscriptionStatus.buttonDisabled}
                                                        >
                                                            {subscriptionStatus.buttonTextSubscribe}
                                                        </EqButton>
                                                    </Col>
                                                )}
                                            </>
                                        )}

                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </>
                }
                childInnerHeight={collapseInnerHeight}
                children={
                    <div ref={collapseInnerRef}>
                        {isSchool || isTrainer ? (
                            <CreateOrEditSchoolTraining
                                onActivityTitleChange={(e: any) => {
                                    handleChangeActivityTitle(e);
                                }}
                                eventData={training}
                                eventKey={training.key}
                                eventTypes={eventTypes}
                                trainersOptions={trainersOptions}
                                productOptions={productOptions}
                                products={products}
                                areas={areas}
                                weekDaysData={weekDays}
                                // population={population}
                                groupPopulation={groupPopulation}
                                schoolStudents={schoolStudents}
                                studentHorsesList={studentHorsesList}
                                onCreateOrUpdateSchoolTraining={onCreateOrUpdateSchoolTraining}
                                isStudent={false}
                            />
                        ) : (
                            ""
                            // <EqActivityStudentView
                            //   training={training}
                            //   areas={areas}
                            //   weekDays={weekDays}
                            //   population={population}
                            //   groupPopulation={groupPopulation}
                            //   trainerStudents={trainerStudents}
                            //   // studentHorsesList={studentHorsesList}
                            // ></EqActivityStudentView>
                        )}
                    </div>
                }
            ></EqCollapsePanel>
            {/* modal Context */}
            {contextRefundPayment}
        </div>
    );
};
export default EqSchoolTrainingCollapseDashBoard;
