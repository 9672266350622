import * as React from 'react';
import { Row, Col } from 'antd';
import './EqJoiningRequest.scss';
import { Checkbox, Tag } from 'antd';
import { IStudentDto } from '../../shared/interfaces/IStudentDto';
import { FrontEndPoints } from '../../shared/Common';
import history from '../../shared/history';

export interface IEqJoiningRequest {
    request: IStudentDto,
    deleteFunc?: any,
    acceptFunc?: any,
    selected?: boolean,
    checkChangeFunc?: any,
}
const EqJoiningRequest = ({ request, deleteFunc, acceptFunc, checkChangeFunc, selected }: IEqJoiningRequest) => {
    //const [requestData, setRequest] = React.useState({ ...request });
    const [check, setCheck] = React.useState(selected);
    function checkChange(selected: boolean) {
        setCheck(selected);
    }
    const acceptClicked = () => {
        if (acceptFunc != undefined) {
            acceptFunc(request);
        }
    }
    const deleteClicked = (data: any) => {
        if (deleteFunc != undefined) {
            deleteFunc(request);
        }
    }
    function onChange() {
        setCheck(!check);
        checkChangeFunc(request, !check);
    }
    React.useEffect(() => {
        if (selected != undefined)
        checkChange(selected);
    }, [selected]);

    return (
        <>
            <Row className="eq-jr-tr" justify="start" align="middle">
                <Col className="eq-jr-td gutter-row" xs={2} sm={2} md={1} lg={1} xl={1} xxl={1}>
                    <Checkbox onChange={onChange} checked={check}></Checkbox>
                </Col>
                <Col className="eq-jr-td gutter-row" xs={9} sm={9} md={7} lg={7} xl={7} xxl={7}>
                    {request.userName}
                </Col>
                <Col className="eq-jr-td gutter-row" xs={0} sm={0} md={7} lg={7} xl={7} xxl={7}>
                    {request.location ? request.location :
                        ((request.locations?.location?.street) ? (request.locations?.location?.street + ' ') : '')
                        + ((request.locations?.location?.zipCode) ? (request.locations?.location?.zipCode + ', ') : '')
                        + ((request.locations?.location?.city) ? (request.locations?.location?.city) : '')
                    }
                </Col>
                <Col className="eq-jr-td gutter-row" xs={9} sm={9} md={7} lg={7} xl={7} xxl={7}>
                    <Tag className="eq-jr-group" >Assign Group
                    </Tag>
                </Col>
                <Col className="eq-jr-td gutter-row" xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                    <div className="" >
                        <span className="eq-action-invite" onClick={acceptClicked}><img alt="" src="/JoiningRequest.svg" /></span>
                        <span className="eq-action-delete" onClick={deleteClicked}><img alt="" src="/Delete.svg" /></span>
                    </div>
                    <div className="" >
                    </div>
                </Col>
            </Row>
        </>
        );
}
export default EqJoiningRequest;