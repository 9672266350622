import React, { useEffect, useState } from "react";
import "./EqGroup.scss";
import EqStudent from "../eqStudent/EqStudent";
import { Row, Col, Checkbox, Radio, Popconfirm, Empty } from "antd";
import { useTranslation, Trans } from "react-i18next";
import { IStudentDto } from "../../shared/interfaces/IStudentDto";
import { IEqGroupDto } from "../../shared/interfaces/IEqGroup";
import Api from "../../shared/Api";
import {
    ApiEndpoints,
    EqMessageType,
    GetEventMessage,
} from "../../shared/Common";
import { FrontEndPoints } from "../../shared/Common";
import history from "../../shared/history";
import EqActivityCollapse from "../eqActivityCollapse/EqActivityCollapse";
import EqMessage, { IEqMessage, IEqMessageData } from "../eqMessage/EqMessage";
import { Link } from "react-router-dom";
import EqGroupMessages from "./eqGroupMessages/EqGroupMessages";
import EqGroupCreateOrEdit from "../eqGroupCreateOrEdit/EqGroupCreateOrEdit";
import { IStudentGroupDto } from "../../shared/interfaces/IStudentGroupDto";
import { EqGroupType } from "../../shared/enums";

export enum selectActionTabType {
    info = 1,
    messages = 2,
    trainings = 3,
    delete = 4,
}

export interface IEqGroup {
    creatorUser?: any;
    areas?: any;
    weekDays?: any;
    groupPopulation?: any;
    trainerStudents?: any;
    studentHorsesList?: any;
    groupData: IEqGroupDto;
    editGroupFunc?: (data: IEqGroupDto) => void;
    removeGroupFunc?: (data: IEqGroupDto) => void;
    studentsList: Array<IStudentGroupDto>;
    searchStudentsList: any;
}
const EqGroup = ({
    creatorUser,
    areas,
    weekDays,
    trainerStudents,
    studentHorsesList,
    groupPopulation,
    groupData,
    editGroupFunc,
    removeGroupFunc,
    studentsList,
    searchStudentsList,
}: IEqGroup) => {
    const { t, i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isError, setIsError] = React.useState(false);

    const {
        groupKey,
        groupIcon,
        groupName,
        numberOfMessages,
        numberOfTrainings,
        sessionFrom,
        sessionTo,
        students,
        display,
    } = groupData;
    const [show, setShow] = useState(display);
    const [eventsList, setEventsList] = React.useState([] as Array<any>);
    const [messageString, setMessageString] = useState("");
    const [messages, setMessages] = useState([] as Array<any>);
    const [keys, setKeys] = useState(new Array<any>());
    var selectedMessages = new Array<any>();
    const [misCheckAll, msetIsCheckAll] = useState(false);

    const createGroupProps = {
        header: t("editGroup"),
        groupHeading: "Please select type of group",
        nameHeading: t("groupName"),
        messageHeading: "Message",
        colorAssign: t("groupColor"),
        groupData: groupData,
        cancelFunc: removeGroupFunc,
        sendFunc: editGroupFunc,
        studentsList: studentsList,
        searchStudentsList: searchStudentsList,
    };

    const toggleShow = () => {
        setShow(!show);
    };
    const [isCheckAll, setIsCheckAll] = useState(false);
    const checkAll = () => {
        setIsCheckAll(!isCheckAll);

        students.map((s: IStudentDto) => {
            s.selected = !isCheckAll;
        });
    };
    const editClicked = (e: any) => {
        e.stopPropagation();
        history.push(FrontEndPoints.trainer.createGroup, {
            group: JSON.parse(JSON.stringify(groupData)),
        });
        if (editGroupFunc != null) {
            editGroupFunc(groupData);
        }
    };
    const deleteClicked = (e: any) => {
        e.stopPropagation();
        if (removeGroupFunc != null) {
            removeGroupFunc(groupData);
        }
    };
    const messageClicked = (e: any) => {
        e.stopPropagation();
        var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${groupKey}`;
        history.push(out1);
    };

    // Messages
    const GetMessages = () => {
        setMessageString("");
        var messagesFromDB = [] as any;
        Api.get(ApiEndpoints.getMessages).then(
            (response) => {

                if (response && response.status == 200) {
                    response.data?.result?.map((d: any) => {
                        messagesFromDB.push({
                            message: {
                                key: d.message.key,
                                messageOn: new Date(d.message.sentOn),
                                type: EqMessageType[d.message.messageType],
                                from: d.message.sender.userName,
                                to: "trainer",
                                subject: d.message.subject,
                                text: d.message.message,
                                isRead: d.isRead,
                                isSent: d.isSent,
                                isFlaged: d.isFlaged,
                            },
                            checkBoxBordered: false,
                            clickedReplyFun: replyEmail,
                            clickedFlagFun: mardFlagedEmail,
                            clickedDeleteFunc: deleteEmail,
                            onCheckChangeFunc: onCheckChange,
                            selected: false,
                        });
                    });
                    messagesFromDB = messagesFromDB?.sort((obj1: any, obj2: any) => {
                        if (obj1.message.isRead > obj2.message.isRead) {
                            return 1;
                        } else if (obj1.message.isRead < obj2.message.isRead) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });

                    setMessages(messagesFromDB);
                } else {
                    setMessageString("Not found");
                }
            },
            (err) => {
                setMessageString("Not found");
            }
        );
    };
    const replyEmail = (message: IEqMessageData) => {
        GetMessages();
    };
    const mardFlagedEmail = (message: IEqMessageData) => {
        if (message != null) {
            Api.post(ApiEndpoints.saveMessage, {
                Message: { Key: message.key },
                IsFlaged: message.isFlaged,
                IsRead: message.isRead,
            }).then(
                (response) => {
                    if (response && response.status == 200) {
                        return true;
                    } else {
                        return false;
                    }
                },
                (err) => {
                    return false;
                }
            );
        } else {
            return false;
        }
    };
    const deleteEmail = (message: IEqMessageData) => {
        if (message != null) {
            Api.post(ApiEndpoints.deleteMessage, {
                Message: { Key: message.key },
            }).then(
                (response) => {
                    if (response && response.status == 200) {
                        GetMessages();
                        return true;
                    } else {
                        return false;
                    }
                },
                (err) => {
                    return false;
                }
            );
        } else {
            return false;
        }
    };
    const onCheckChange = (message: IEqMessageData, checked: boolean) => {
        if (checked) {
            selectedMessages.push({ Key: message.key });
        } else {
            if (selectedMessages != null && selectedMessages.length > 0) {
                const index = selectedMessages.findIndex((x) => x.Key == message.key);
                if (index > -1) {
                    selectedMessages.splice(index, 1);
                }
            }
        }

        setKeys(selectedMessages);
    };
    const deleteSelectedMessages = () => {
        msetIsCheckAll(false);

        if (keys?.length > 0) {
            Api.post(ApiEndpoints.deleteSelectedMessages, keys).then(
                (response) => {
                    if (response && response.status == 200) {
                        GetMessages();

                        return true;
                    } else {
                        return false;
                    }
                },
                (err) => {
                    return false;
                }
            );
        } else {
            return false;
        }
    };
    const readSelectedMessages = () => {
        msetIsCheckAll(false);
        selectedMessages = [];

        keys.map((opt: any) => {
            selectedMessages.push({ Message: { Key: opt.Key }, IsRead: true });
        });
        if (keys?.length > 0) {
            Api.post(ApiEndpoints.saveSelectedMessages, selectedMessages).then(
                (response) => {
                    if (response && response.status == 200) {
                        GetMessages();
                        selectedMessages = [];
                        return true;
                    } else {
                        return false;
                    }
                },
                (err) => {
                    return false;
                }
            );
        } else {
            return false;
        }
    };

    const messagesCheckAll = () => {
        msetIsCheckAll(!misCheckAll);

        messages.map((m: IEqMessage) => {
            if (m.selected == false) {
                m.selected = !misCheckAll;
                selectedMessages.push({ Key: m.message.key });
            } else {
                m.selected = !misCheckAll;
                if (selectedMessages != null && selectedMessages.length > 0) {
                    const index = selectedMessages.findIndex(
                        (x) => x.Key == m.message.key
                    );
                    if (index > -1) {
                        selectedMessages.splice(index, 1);
                    }
                }
            }
            setKeys(selectedMessages);
        });
        const modifiedList = [...messages];
        setMessages(modifiedList);
    };
    // Trainings
    const GetAllTrainingsByTrainerGroup = async () => {
        
        Api.post(ApiEndpoints.GetAllTrainingsByTrainerGroup, {
            key: groupKey,
        }).then(
            (response) => {
                if (response && response.status == 200) {
                    var data = response.data ? [...response.data] : new Array<any>();
                    setEventsList(data);
                } else {
                    setIsError(true);
                    setErrorMessage(GetEventMessage(response.status));
                }
            },
            (error) => {
                setIsError(true);
                setErrorMessage(GetEventMessage(error.response.status));
            }
        );
    };

    const removeEvent = (data: any) => {
        Api.post(ApiEndpoints.deleteEvent, { Key: data.key }).then(
            (response) => {
                if (response && response.status == 200) {
                    //   removeItem(response.data);
                    GetAllTrainingsByTrainerGroup();
                } else {
                    setIsError(true);
                    setErrorMessage(GetEventMessage(response.status));
                }
            },
            (error) => {
                setIsError(true);
                setErrorMessage(GetEventMessage(error.response.status));
            }
        );
    };

    useEffect(() => {
    }, [groupKey]);

    const [selectedActionTab, setSelectedActionTab] = useState(
        selectActionTabType.info
    ) as any;
    const selectActionTab = (param: any) => {
        setSelectedActionTab(param.target.value);
        if (param.target.value === 3 && eventsList.length === 0) {
            GetAllTrainingsByTrainerGroup();
        } else if (param.target.value === 2 && messages.length === 0) {
            GetMessages();
        }
    };

    return (
        <>
            <div className="eq-group" style={{ borderLeftColor: groupData.color, borderLeftWidth:"thick" }}>
                <Row
                    className="eq-group-header"
                    justify="start"
                    align="middle"
                    onClick={toggleShow}
                >
                    <Col className="eq-group-header-name" flex="auto">
                        {groupIcon}
                        {groupName}
                    </Col>
                    <Col flex="auto">
                        <div
                        // onClick={toggleShow}
                        >
                            <Row justify="end">
                                <Col>
                                    <div className="eq-action-tab">
                                        <Radio.Group
                                            value={selectedActionTab}
                                            onChange={selectActionTab}
                                        >
                                            {groupData.type == EqGroupType.Horse ?
                                                "" :<>
                                                <Radio.Button value={selectActionTabType.info}>
                                                <span className="gh-icon">
                                                    <img alt="" src="/images/icons/groups.svg" />
                                                </span>
                                                <span className="eq-gh-total">{students?.length}</span>
                                            </Radio.Button>
                                            <Radio.Button value={selectActionTabType.messages}>
                                                <span className="gh-icon">
                                                    <img alt="" src="/orange-message.svg" />
                                                </span>
                                                <span className="eq-gh-total">{numberOfMessages}</span>
                                                </Radio.Button>
                                           
                                            <Radio.Button value={selectActionTabType.trainings}>
                                                <span className="gh-icon">
                                                    <img alt="" src="/images/icons/events.svg" />
                                                </span>
                                                <span className="eq-gh-total">{numberOfTrainings}</span>{" "}
                                                    </Radio.Button>
                                                </>}
                                            <Radio.Button value={selectActionTabType.delete}>
                                                <Popconfirm
                                                    placement="bottomRight"
                                                    title={"Are you sure to delete this?"}
                                                    onConfirm={deleteClicked}
                                                    // onConfirm={() => setCreateActivityList([])}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <span className="gh-icon">
                                                        <img alt="" src="/group-trash.svg" />
                                                    </span>
                                                </Popconfirm>
                                                {/* <span className="gh-icon" onClick={deleteClicked}><img alt="" src="/group-trash.svg" /></span>              */}
                                            </Radio.Button>
                                        </Radio.Group>
                                    </div>
                                </Col>

                                <Col className="group-header-students">
                                    <div className="eq-gh-addUser-icon">
                                        <span className="gh-icon">
                                            {groupData.type == EqGroupType.Horse ?
                                                <img alt="" src="/horse.svg" /> :
                                                <img alt="" src="/addUser.svg" />}
                                        </span>
                                    </div>
                                    <div className="eq-gh-students">
                                        |&nbsp;
                                        {groupData.type == EqGroupType.Horse ?
                                            (groupData.horses?.length || "0 ") + ` ${t('horses')}` :
                                            students.length + ` ${t('students')}`}
                                    </div>
                                </Col>
                                <Col className="group-header-arrow" xs={24} sm={24} md={1}>
                                    {show ? (
                                        <span className="gh-arrow-icon">
                                            <img alt="" src="/caret-up.svg" />
                                        </span>
                                    ) : (
                                        <span className="gh-arrow-icon">
                                            <img alt="" src="/caret-down.svg" />
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                {show && (
                    <>
                        {selectActionTabType.info == selectedActionTab && (
                            <>
                                <EqGroupCreateOrEdit
                                    {...createGroupProps}
                                ></EqGroupCreateOrEdit>

                                {/* <div className="eq-tab-container eq-studentList-container">
                <Row className="eq-sl-row" justify="start" align="middle">
                  <Col className="" xs={2} sm={2} md={1}>
                    <Checkbox
                      onChange={checkAll}
                      checked={isCheckAll}
                      ></Checkbox>
                  </Col>
                  <Col className="" xs={7} sm={8} md={6}>
                    {t("name")}
                  </Col>
                  <Col className="" md={5} xs={0} sm={0}>
                    Area
                  </Col>
                  <Col className="" flex="auto">
                    Payments
                  </Col>
                  <Col className="" md={5} xs={10} sm={10}>
                    {t("Groups")}
                  </Col>
                  <Col className="" md={2} xs={0} sm={0}>
                    Actions
                  </Col>
                </Row>
                {students.length > 0 && (
                  <>
                    {students.map((item: IStudentDto, index: number) => (
                      <EqStudent key={index} {...{ student: item }}></EqStudent>
                      ))}
                  </>
                )}
              </div> */}
                            </>
                        )}
                        {selectActionTabType.messages == selectedActionTab && (
                            <>
                                <div className="eq-tab-container eq-messages-container">
                                    {numberOfMessages ? (
                                        <EqGroupMessages groupKey={groupKey}></EqGroupMessages>
                                    )
                                        : (<Empty></Empty>)}
                                </div>
                            </>
                        )}
                        {selectActionTabType.trainings == selectedActionTab && (

                            <div className="eq-tab-container eq-trainings-container">

                                {eventsList?.length > 0 ? (
                                    <>
                                        {eventsList.map((item: any, index: number) => (
                                            <EqActivityCollapse
                                                key={index}
                                                creatorUser={creatorUser}
                                                event={item}
                                                areas={areas}
                                                weekDays={weekDays}
                                                trainerStudents={trainerStudents}
                                                studentHorsesList={studentHorsesList}
                                                removeEventFunc={removeEvent}
                                                groupPopulation={groupPopulation}
                                            ></EqActivityCollapse>
                                        ))}
                                    </>
                                ) : (
                                    <Empty></Empty>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
export default EqGroup;
