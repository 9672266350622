import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Row, Col } from 'antd';
import axios from 'axios';
import { EqInput } from '../../../custom/eqInput/EqInput';
import EqButton from '../../../custom/eqButton/EqButton';
import { useTranslation } from 'react-i18next';

const EqResponseTab: React.FC = () => {
  const [allData, setAllData] = useState([]); // State to store all response data
  const [filteredData, setFilteredData] = useState([]); // State to store filtered response data
  const [search, setSearch] = useState(''); // State for search input
  const { t } = useTranslation();
  // Function to fetch all response data from backend
  const fetchAllResponseData = async () => {
    try {
      const response = await axios.get('/api/sendGrid/GetResponses'); // Assuming your API is hosted at /api/EmailResponse
      
      setAllData(response.data);
      setFilteredData(response.data); // Initialize filtered data with all data initially
    } catch (error) {
      console.error('Error fetching response data:', error);
    }
  };

  useEffect(() => {
    fetchAllResponseData();
  }, []); // Fetch data on component mount

  // Function to handle search
  const handleSearch = () => {
    const searchTerm = search.toLowerCase();
    const filtered = allData.filter(
      (item:any) =>
        item.email.toLowerCase().includes(searchTerm) || item.messageId.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filtered);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'message ID',
      dataIndex: 'messageId',
      key: 'messageId',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Status Code',
      dataIndex: 'statusCode',
      key: 'statusCode',
    },
    {
      title: 'Response',
      dataIndex: 'response',
      key: 'response',
      // ellipsis: true,
    },
    // Add any other relevant columns based on your data structure
  ];

  return (
    <div>
    
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <EqInput
            placeholder={t("Search by email or MessageId")}
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </Col>
        <Col span={6}>
          <EqButton type="primary" onClick={handleSearch}>
            {t("search")}
          </EqButton>
        </Col>
      </Row>
  
        <Table columns={columns} dataSource={filteredData} 
    scroll={{ x: true }} 
    style={{ marginTop: '16px' }} />

    </div>
  );
};

export default EqResponseTab;
