import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./EqStripeSubscriptionForm.scss";
import EqButton from "../custom/eqButton/EqButton";
import Api from "../shared/Api";
import { ApiEndpoints, getEmailFromToken, openNotificationWithIcon } from "../shared/Common";
import { eqNotificationTypes } from "../shared/enums";
import { Col, Row } from "antd";
import { loadState, saveState } from "../shared/LocalStorage";
import { useAuthDispatch } from "../context/Context";
import { loginUser } from "../context/Actions";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const CARD_OPTIONS: any = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "black",
      fontWeight: "500",
      fontFamily: "Roboto,Open Sans,Segeo UI,sans-serif",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      ":-placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "red",
    },
  },
};

// const EqStripeSubscriptionForm = (stripePromise: any) => {
export interface IEqStripeSubscriptionForm {
  selectedPlan: any;
  isEdit?: any;
  onCreate?: any;
}
const EqStripeSubscriptionForm = ({
  selectedPlan,
  isEdit,
  onCreate,
}: IEqStripeSubscriptionForm) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe() as any;
  const elements = useElements() as any;
  const currentUserEmail = getEmailFromToken();
  var currentLocalState = loadState() as any;
  const dispatch = useAuthDispatch();

  React.useEffect(() => {}, [selectedPlan]);

  const handleSubscription = async (paymentMethodId: any) => {
    try {
      Api.post("/api/Subscription/subscribe", {
        email:currentUserEmail,
        paymentMethodId: paymentMethodId,
        subscriptionSettingId: selectedPlan.id,
      }).then(
        (response) => {
          setProcessing(false);
          if (response && response.status == 200) {
            
            console.log(currentLocalState)
            currentLocalState.isSubscribed=true;
            saveState(currentLocalState);
            loginUser(dispatch, currentLocalState);

            onCreate();
          } else {
            openNotificationWithIcon(
              t("serverError"),
              t("errorCreatingSubscription")
            );
          }
        },
        (error) => {
          setProcessing(false);

          openNotificationWithIcon(
            t("serverError"),
            t("errorCreatingSubscription")
          );
          console.error(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event: any) => {
    
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement, {
        theme: "night",
        labels: "floating",
      }),
    });

    if (error) {
      //   console.error(error.message);
      setError(error.message);
      setProcessing(false);
    } else {
      console.log(paymentMethod);
      // You can send the paymentMethod.id to your server to save the customer's card details
      await handleSubscription(paymentMethod.id);
    }
  };

  return (
    <Row className="eq-stripeCard">
      <Col xs={22} md={20} xl={18}>
        <form onSubmit={handleSubmit}>
          <div className="eq-cardElement">
            <CardElement options={CARD_OPTIONS} />
          </div>

          {error && <div>{error}</div>}
          <EqButton htmlType="submit" disabled={processing}>
            {t("subscribe")}
          </EqButton>
        </form>
      </Col>
    </Row>
  );
};

export default EqStripeSubscriptionForm;
