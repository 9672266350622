import React, { useState, useEffect } from 'react';
import EqSentInvitesList, { IEqSentInvitesList } from '../../eqTrainer/eqSentInvitesList/EqSentInvitesList';
import './EqStudentBoard.scss';
import { Row, Col, Input } from 'antd';
import EqSelect from '../../custom/eqSelect/EqSelect';
import EqButton from '../../custom/eqButton/EqButton';
import { useTranslation, Trans } from 'react-i18next';
import EqInviteStudent from '../../eqTrainer/eqInviteStudent/EqInviteStudent';
import Api from '../../shared/Api';
import { EqStatusCode, GetInvitesMessage, ApiEndpoints } from '../../shared/Common';
import { ISelectProps } from '../../shared/Interfaces';
import { EqLocationType } from '../../shared/enums';
import { IEqSentInvites } from '../../eqTrainer/eqSentInvites/EqSentInvites';

const EqStudentBoard = () => {
    const { t, i18n } = useTranslation();
    const { Search } = Input;
    const [addNew, setAddNew] = useState(false);
    const [inviteslist, setInvitesList] = React.useState([]) as any;
    const [messageString, setMessageString] = useState('');

    var sortedList: Array<IEqSentInvites>;
    const invitesList: IEqSentInvitesList = { sentInvitesList: inviteslist };

    const showAddNew = () => {
        setAddNew(true);
    }
    const cancelFunction = (e: any) => {
        setAddNew(false);
    }
    const SendInviteClicked = (success: boolean) => {
        if (success == true) {
            GetInvitations();
        }
    }
    const onInvitesSortChange = (event: any) => {
        switch (event) {
            case "Sort A-Z":
                sortedList = [...inviteslist].sort((obj1, obj2) => {
                    if ((obj1.student?.userName).toLowerCase() > (obj2.student?.userName).toLowerCase()) { return 1; }
                    else if ((obj1.student.userName).toLowerCase() < (obj2.student.userName).toLowerCase()) { return -1 } else { return 0 }
                });
                setInvitesList(sortedList);
                break;
            case "Sort Z-A":
                sortedList = [...inviteslist].sort((obj1, obj2) => {
                    if ((obj1.student?.userName).toLowerCase() < (obj2.student?.userName).toLowerCase()) { return 1; }
                    else if ((obj1.student.userName).toLowerCase() > (obj2.student.userName).toLowerCase()) { return -1 } else { return 0 }
                });
                setInvitesList(sortedList);
                break;
        }
    }

    const deleteInvitation = (student: any) => {
        Api.post(ApiEndpoints.deleteInvitation, student).then(response => {
            if (response && response.status == 200) {
                GetInvitations();
                return true;
            } else {
                return false;
            }
        }, err => {
            return false;
        });
    }
    const GetInvitations = () => {
        setMessageString('');
        setInvitesList([] as any);
        Api.get(ApiEndpoints.getInvites).then(response => {
            var students = [] as any;
            if (response && response.status == 200) {

                var invitedStudents = response.data?.result ? [...response.data.result] : [] as any;
                invitedStudents.map((d: any) => {
                    students.push({
                        student: {
                            firstName: d.firstName,
                            lastName: d?.lastName,
                            userName: d?.userName,
                            email: d.email,
                            locations: d.locations?.filter((opt: any) => (opt.location?.type == EqLocationType.Home)).shift(),
                            payment: d.paymentStatus,
                            groups: d.groups,
                            selected: false
                        },
                        deleteFunc: deleteInvitation
                    }
                    )
                });
                setInvitesList(students);
            } else {
                setMessageString(GetInvitesMessage(EqStatusCode.serverException502));
            }
        }, err => {
            setMessageString(GetInvitesMessage(err.response.status));
        });
    }
    const studentInviteProps = {
        header: t("Invite-Student"), emailHeading: "Please add your student email address", messageHeading: "Message",
        cancelFunc: cancelFunction, sendFunc: SendInviteClicked
    };
    const invitesSortProps: ISelectProps = {
        border: false, allowClear: false, classNames: "eq-select-sort", defaultSelectedValue: "Sort A-Z", changeFunc: onInvitesSortChange,
        options: [{ key: "Sort A-Z", value: "Sort A-Z" }, { key: "Sort Z-A", value: "Sort Z-A" }]
    };
    useEffect(() => {
        GetInvitations();
    }, []);
    return (
        <>
            <div className="eq-sb-container">
                <Row className="eq-sb-heading-row">
                    <Col className="eq-sb-col" flex="auto" sm={5} xs={4}>
                        <h2 className="eq-sb-heading">Students</h2>
                    </Col>
                    <Col className="eq-sb-col" flex="auto" sm={19} xs={20}>
                        <Row className="eq-sb-student-header" justify="end" align="bottom">
                            <Col className="eq-sb-followers hidden-sm">
                            {t("sentInvites")} {inviteslist.length}
                            </Col>
                            <Col className="eq-sb-student-sort hidden-sm">
                                <EqSelect {...invitesSortProps}></EqSelect>
                            </Col>
                            {/*<Col className="eq-sb-student-search" xs={14} sm={14} md={6} lg={6} xl={6} xxl={6}>*/}
                            {/*    <Search placeholder="inputSearchText" />*/}
                            {/*</Col>*/}
                            <Col>
                                <div className="eq-sb-student-btn">
                                    <EqButton type="button" bordered="true" classNames="btn-border-text-orange">
                                        <span className="hidden-md"><img alt="" src="/filter-black.svg" /></span>
                                        <span className="hidden-sm">Filters</span></EqButton>
                                </div>
                            </Col>
                            <Col>
                                <div className="eq-sb-student-btn" >
                                    <EqButton type="button" onClick={showAddNew} classNames="btn-background-orange">
                                        <img alt="" src="/plus.svg" />
                                        <span className="hidden-sm"><span className="icon-space"></span>{t("addNew")}</span></EqButton>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div>
                    {addNew &&
                        <EqInviteStudent {...studentInviteProps}></EqInviteStudent>
                    }
                </div>

                <div className="eq-student-list">
                    <EqSentInvitesList {...invitesList}></EqSentInvitesList>
                </div>
            </div>
        </>
    );
}
export default EqStudentBoard;