import React from "react";
import { useEffect, useState } from "react";
import "./EqNotificationSideBar.scss";
import { Row, Col, Badge, Popover, Space } from "antd";
import { useTranslation } from "react-i18next";
import {
  IEqMessage,
  IEqMessageData,
} from "../../eqTrainer/eqMessage/EqMessage";
import Api from "../../shared/Api";
import { EqMessageType } from "../../shared/enums";
import { IStudentDto } from "../../shared/interfaces/IStudentDto";
import { ApiEndpoints, ValidateIsUser } from "../../shared/Common";
import { useAuthState } from "../../context/Context";
import EqNotification from "../../eqTrainer/eqNotification/EqNotification";
import { BellFilled } from "@ant-design/icons";
import EqButton from "../../custom/eqButton/EqButton";
import { Link } from "react-router-dom";

const EqNotificationSideBar = (props: any) => {
  const userDetails: any = useAuthState();
  const isValidUser: boolean = ValidateIsUser(userDetails);

  const { t, i18n } = useTranslation();
  const [messageString, setMessageString] = useState("");
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [messages, setMessages] = useState([] as Array<any>);
  const [unReadCount, setUnReadCount] = useState(0);
  // const [showCount, setShowCount] = useState(false);

  const [misCheckAll, msetIsCheckAll] = useState(false);
  const [activities, setActivities] = React.useState([] as Array<any>);
  const [trainings, setTrainings] = React.useState([] as Array<any>);
  const [events, setEvents] = React.useState([] as Array<any>); //Array<IEqEventData>

  const [currentStudentslist, setCurrentStudentsList] = React.useState(
    [] as Array<IStudentDto>
  );
  const [keys, setKeys] = useState(new Array<any>());
  const [eventList, setEventList] = useState(true);
  var selectedMessages = new Array<any>();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

  const replyEmail = (message: IEqMessageData) => {
    GetMessages();
  };
  const mardFlagedEmail = (message: IEqMessageData) => {
    if (message != null) {
      Api.post(ApiEndpoints.saveMessage, {
        Message: { Key: message.key },
        IsFlaged: message.isFlaged,
        IsRead: message.isRead,
      }).then(
        (response) => {
          if (response && response.status == 200) {
            // setUnReadCount(unReadCount-1)
            GetMessages();

            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };

  const toggleEventList = () => {
    setEventList(!eventList);
  };

  const deleteEmail = (message: IEqMessageData) => {
    if (message != null) {
      Api.post(ApiEndpoints.deleteMessage, {
        Message: { Key: message.key },
      }).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const onCheckChange = (message: IEqMessageData, checked: boolean) => {
    if (checked) {
      selectedMessages.push({ Key: message.key });
      setUnReadCount(0);

    } else {
      if (selectedMessages != null && selectedMessages.length > 0) {
        const index = selectedMessages.findIndex((x) => x.Key == message.key);
        if (index > -1) {
          selectedMessages.splice(index, 1);
        }
      }
    }
    setKeys(selectedMessages);
  };
  const deleteSelectedMessages = () => {
    msetIsCheckAll(false);

    if (keys?.length > 0) {
      Api.post(ApiEndpoints.deleteSelectedMessages, keys).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();

            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const readSelectedMessages = () => {
    msetIsCheckAll(false);
    selectedMessages = [];
    keys.map((opt: any) => {
      selectedMessages.push({ Message: { Key: opt.Key }, IsRead: true });
    });
    if (keys?.length > 0) {
      Api.post(ApiEndpoints.saveSelectedMessages, selectedMessages).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();
            selectedMessages = [];
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const GetMessages = async () => {
    setMessageString("");

    var messagesFromDB = [] as any;
    var unReadCount = 0;

    // const {data} =await Api.get(ApiEndpoints.getMessages);
    // console.log(data)

    Api.get(ApiEndpoints.GetNotifications).then(
      (response) => {
        if (response && response.status == 200) {
          response.data?.result?.map((d: any) => {
            if (d.isRead == false) {
              ++unReadCount;
            }

            messagesFromDB.push({
              message: {
                key: d.message.key,
                messageOn: new Date(d.message.sentOn),
                type: EqMessageType[d.message.messageType],
                from: d.message?.sender?.userName,
                to: "trainer",
                subject: d.message.subject,
                text: d.message.message,
                isRead: d.isRead,
                isSent: d.isSent,
                isFlaged: d.isFlaged,
              },
              checkBoxBordered: false,
              clickedReplyFun: replyEmail,
              clickedFlagFun: mardFlagedEmail,
              clickedDeleteFunc: deleteEmail,
              onCheckChangeFunc: onCheckChange,
              selected: false,
            });
          });
          messagesFromDB = messagesFromDB?.sort((obj1: any, obj2: any) => {
            if (obj1.message.isRead > obj2.message.isRead) {
              return 1;
            } else if (obj1.message.isRead < obj2.message.isRead) {
              return -1;
            } else {
              return 0;
            }
          });

          setMessages(messagesFromDB);
          setUnReadCount(unReadCount);
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };

  const messagesCheckAll = () => {
    msetIsCheckAll(!misCheckAll);

    messages.map((m: IEqMessage) => {
      if (m.selected == false) {
        m.selected = !misCheckAll;
        selectedMessages.push({ Key: m.message.key });
      } else {
        m.selected = !misCheckAll;
        if (selectedMessages != null && selectedMessages.length > 0) {
          const index = selectedMessages.findIndex(
            (x) => x.Key == m.message.key
          );
          if (index > -1) {
            selectedMessages.splice(index, 1);
          }
        }
      }
      setKeys(selectedMessages);
    });
    const modifiedList = [...messages];
    setMessages(modifiedList);
  };

  useEffect(() => {
    if (isValidUser) {
      GetMessages();
    }
  }, []);
  return (
    <Popover
      // placement="bottomRight"
      content={
        <div className="eq-nf-list-container">
          <div className="eq-section">
            <Row className="eq-msg-header" align="bottom">
              <Col flex="auto">
                <div className="eq-db-h2">NOTIFICATIONS</div>
              </Col>
              <Col md={2}>
                <Row></Row>
              </Col>
            </Row>
            {messages.length > 0 && (
              <>
                {messages.map((item: IEqMessage, index: number) => (
                  <EqNotification key={index} {...item}></EqNotification>
                ))}
              </>
            )}
          </div>
        </div>
      }
      trigger="click"
    >
      <Row className="notification-bar"
        onClick={() => {
          // setShowCount(true);
          setUnReadCount(0);
        }}
      >
        <Space >
          <Col>
            <BellFilled
              style={{ fontSize: "17px", color: "#ff8838" }}
              onClick={() => {
                // setShowCount(true);
                setUnReadCount(0);
              }}
            />
          </Col>
          <Col>{t("notifications")}</Col>
          <Col>
            <Badge

              size="small"
              count={unReadCount}
            // dot={showCount}
            ></Badge>
          </Col>
        </Space>
      </Row>
    </Popover>
  );
};
export default EqNotificationSideBar;
