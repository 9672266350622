import React, { useState, useEffect } from 'react';
import './EqStudentProfileBoard.scss';
import { Row, Col } from 'antd';
import { Rate } from 'antd';
import EqScheduleList, { IEqScheduleList } from '../../eqTrainer/eqScheduleList/EqScheduleList';
import EqSchedule from '../../eqTrainer/eqSchedule/EqSchedule';
import { addDays, getEmailFromToken, getDefaultImage, imageType } from '../../shared/Common';
import Api from '../../shared/Api';
import { EqStatusCode, GetEventMessage, ApiEndpoints } from '../../shared/Common';
import { EqDisciplineType, EqLocationType, EqTrainingLevels } from '../../shared/enums';
import { IProfileDto } from '../../shared/interfaces/IProfileDto';
import EqButton from '../../custom/eqButton/EqButton';
import { IEqEventData } from '../../shared/interfaces/IEqEventDto';
import EqBookSession from '../../eqTrainer/eqBookSession/EqBookSession';
import history from '../../shared/history';
import { useAuthState, useAuthDispatch } from '../../context/Context';
import {
    FrontEndPoints, ValidateIsUser,
} from '../../shared/Common';
import { useTranslation, Trans } from "react-i18next"
const EqStudentProfileBoard = (props: any) => {
    const { t, i18n } = useTranslation();
    const userDetails: any = useAuthState();
    const [messageString, setMessageString] = useState('');
    const [schedules, setSchedules] = useState([] as any);
    const [isError, setIsError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [eventsList, setEventsList] = React.useState([] as Array<IEqEventData>);
    const [profileData, setProfile] = useState<IProfileDto>();
    const [bookingButtonState, setBookingButtonState] = React.useState({ buttonDisabled: false, buttonText: t("bookASession") } as any);
    const [userEmail, setUserEmail] = React.useState('');

    const [openEventList, setOpenEventList] = React.useState([] as Array<IEqEventData>);
    const [selectedUserKey, setSelectedUserKey] = useState() as any;

    const currentUserKey = getEmailFromToken();

    schedules.map(
        (f: IEqEventData, index: number) => {
            f.display = (index == 0 ? true : false);
        }
    );

    const messageClicked = () => {
        if (ValidateIsUser(userDetails)) {
            var out1 = FrontEndPoints.trainer.composeMessage;
            history.push(out1);
        }
        else {
            var out1 = FrontEndPoints.login;
            history.push(out1);
        }
    }

    const GetProfessionalEvents = (key: any) => {
        setErrorMessage("");
        Api.post(ApiEndpoints.GetProfessionalEvents, { Key: key }).then(response => {
            if (response && response.status == 200) {
                var data = response.data ? [...response.data] : new Array<IEqEventData>();
                setEventsList(data);
            }
            else {
                setIsError(true);
                setErrorMessage(GetEventMessage(response.status));
            }
        },
            error => {
                setIsError(true);
                setErrorMessage(GetEventMessage(error.response.status));
            });
    }

    const GetProfileDetails = (key: any) => {
        setMessageString('');
        Api.post(ApiEndpoints.getProfessioanalProfile, { Key: key }).then(response => {
            if (response && response.status == 200) {
                var data = response.data ? { ...response.data } : null;
                if (data != null) {
                    setSelectedUserKey(data.Key);
                    setUserEmail(data.email);

                    var profList = [] as any;
                    data.disciplines?.map(
                        (item: any) => profList.push(EqDisciplineType[Number(item.discipline)])
                    );

                    var profile: any = {
                        email: data.email,
                        about: data.aboutMe,
                        name: data.firstName ? data.firstName : '' + ' ' + data.lastName ? data.lastName : '',
                        rating: 4.5,
                        experience: "10 Years",
                        area: data.locations?.filter((opt: any) => (opt.location?.type == EqLocationType.Home)).shift(),
                        radius: "20Km",
                        disciplineList: data.disciplines,
                        imagePath: data.imagePath,
                        display: false,
                        userImage: data.userImage,
                        professionsList: profList,
                        trainingLevels: data.trainingLevels,
                        serviceAreaList: data.locations?.filter((opt: any) => (opt.location?.type == EqLocationType.Work)),
                    };
                    setProfile(profile);
                }

            } else {
                setMessageString("Not found");
            }
        }, err => {
            setMessageString("Not found");
        });
    }

    const getOpenEvents = () => {
        let list = [] as any;
        eventsList?.map((item: IEqEventData) => {
            if (item.disciplineType == EqDisciplineType.Open) {
                list.push(item);
            }
        });
        setOpenEventList(list);
    }

    useEffect(() => {
        const params = new URLSearchParams(props.location?.search);
        var key = params.get("key");
        var userKey
        if (key) {
            userKey = Number(key);
            setSelectedUserKey(userKey);
        } else {
            userKey = 0;
            setSelectedUserKey(0);
        }

        GetProfileDetails(userKey);
        GetProfessionalEvents(userKey);

    }, []);


    const [sessionBookingProps, setSessionBookingProps] = React.useState({
        title: t("bookASession"),
        visible: false,
        email: userEmail
    });


    const changeBookSessionView = () => {
        setSessionBookingProps({ ...sessionBookingProps, visible: true, email: userEmail });
    }

    return (
        <>
            <EqBookSession {...sessionBookingProps} />
            <div className="eq-board-container">
                <Row>
                    <Col md={6} xs={24} sm={24} className="trainer-info">
                        <Row align="middle" justify="center">
                            <Col className="profile-img">
                                <img src={profileData?.userImage?.url || getDefaultImage(imageType.partner)}  className="user" alt="user" />
                            </Col>
                        </Row>
                        <Row className="profile-name">
                            <Col flex="auto">{profileData?.name}</Col>
                            {/*<Col>*/}
                            {/*    <span><img alt="" src="/DateEdit.svg" /></span>*/}
                            {/*</Col>*/}
                        </Row>
                        <Row>
                            <Col className="profile-certified" flex="auto">{t("student")}</Col>
                        </Row>
                        {/*<Row align="middle">*/}
                        {/*    <Col md={10} className="profile-info-h">Rating:</Col>*/}
                        {/*    <Col md={14} className="profile-info-i">*/}
                        {/*        <Rate allowHalf defaultValue={profileData?.rating} />*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row>
                            <Col md={10} className="profile-info-h">Experience:</Col>
                            <Col md={14} className="profile-info-i">{profileData?.experience}</Col>
                        </Row>
                        <Row>
                            <Col md={10} className="profile-info-h">Area:</Col>
                            <Col md={14} className="profile-info-i">{profileData?.area?.location?.city}</Col>
                        </Row>
                        <Row>
                            <Col md={10} className="profile-info-h">Radius:</Col>
                            <Col md={14} className="profile-info-i">{profileData?.radius}</Col>
                        </Row>
                        <Row className="section-divider">
                            <Col flex="auto">
                            </Col>
                        </Row>
                        <Row>
                            <Col className="section-heading">Discipline</Col>
                        </Row>

                        {profileData && profileData.disciplineList && profileData?.disciplineList?.length > 0 &&
                            <>
                                {
                                    profileData?.disciplineList?.map((item: any, index: number) =>
                                        <Row>
                                            <Col className="eq-col-discipline">
                                                {t(EqDisciplineType[item.discipline?.type])}
                                            </Col>
                                        </Row>
                                    )
                                }
                            </>
                        }
                        <Row className="section-divider">
                            <Col flex="auto">
                            </Col>
                        </Row>
                        <Row>
                            <Col className="section-heading">Offered Trainings</Col>
                        </Row>
                        {profileData && profileData.trainingLevels && profileData?.trainingLevels?.length > 0 &&
                            <>
                                <div className="eq-list">
                                    <ul className="list">
                                        {
                                            profileData.trainingLevels.map((item: any, index: number) =>
                                                <li>
                                                    <Row>
                                                        <Col className="">
                                                            {EqTrainingLevels[item.trainingLevel?.type]}
                                                        </Col>
                                                    </Row>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </>
                        }
                        <Row className="section-divider">
                            <Col flex="auto">
                            </Col>
                        </Row>
                        <Row>
                            <Col className="section-heading">Areas</Col>
                        </Row>

                        {profileData && profileData.serviceAreaList && profileData?.serviceAreaList?.length > 0 &&
                            <>
                                <div className="eq-list">
                                    <ul className="list">
                                        {
                                            profileData.serviceAreaList.map((item: any, index: number) =>
                                                <li>
                                                    <Row>
                                                        <Col className="">
                                                            {item.location?.city}
                                                        </Col>
                                                    </Row>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </>
                        }
                    </Col>
                    <Col md={18} xs={24} sm={24} className="col-right">
                        <div className="profile-about">
                            <Row className="eq-board-heading" align="bottom">
                                <Col className="eq-board-col" flex="auto">
                                    Profile
                                </Col>
                                <Col className="loc-icon">
                                    <span className=""><img alt="" src="/chart-pie.svg" /></span>
                                </Col>
                                <Col className="my-loc">
                                    {profileData && profileData.serviceAreaList && profileData?.serviceAreaList?.length > 0 &&
                                        <>
                                            <Row>
                                                {
                                                    profileData.serviceAreaList.map((item: any, index: number) =>
                                                        (index == 0) ?
                                                            <Col className="">
                                                                {item.location?.city}
                                                            </Col>
                                                            :
                                                            <><Col>,
                                                            </Col>
                                                                <Col className="">
                                                                    {item.location?.city}
                                                                </Col></>

                                                    )
                                                }
                                            </Row>
                                        </>
                                    }
                                </Col>
                            </Row>
                            <Row className="eq-board-line">
                            </Row>
                            <Row className="about-info">
                                <Col xs={24} sm={24} md={18} className="about-left-col">
                                    <Row>
                                        <h3 className="about-heading">About the Student</h3>
                                    </Row>
                                    <Row>
                                        {profileData?.about}
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={6} className="about-right-col">
                                    <Row>
                                        <h3 className="about-heading">Contact</h3>
                                    </Row>
                                    <div onClick={messageClicked} className="about-message">
                                        <Row align="middle">
                                            <Col className="msg-icon">
                                                <span><img alt="" src="/Envelope.svg" /></span>
                                            </Col>
                                            <Col flex="auto" className="msg-text">Send a message
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {/* <EqScheduleList {...{ eventList: [...eventsList] }}></EqScheduleList> */}
                    </Col>
                </Row>
            </div>
        </>
    );
}
export default EqStudentProfileBoard;