import React, { useState } from "react";
import "./EqPopup.scss";
import EqButton, { IEqButton } from '../eqButton/EqButton';
import { Row, Col } from 'antd';

interface PopupProps{
    displayPopup: any,
    action: any,
    title: string,
    description: string,
    type: any,
    popupButtons: Array<IEqButton>;
}
const EqPopup = (props: PopupProps) => {
    //Popup with changes
    const [popup, setPopup] = useState(props.displayPopup);
    const popupType = () => {
        switch (props.type) {
            case "information":
                setTimeout(togglePopup, 5000);
            case "notification":
            default:

        }
    }
    const togglePopup = () => {
        setPopup(!popup);
        props.action(popup);
    };

    React.useEffect(() => {
        if (props) {
            setPopup(props.displayPopup);
            popupType();
        }
    }, [props.displayPopup])

    return (
        <>
            {popup &&
                <div className="eq-popup-container" >
                    <Row>
                        <Col>
                <div className="eq-popup-notification">
                    <div className="eq-popup-check">
                        <span className=""><img alt="" src="/green-Check.svg" /></span>
                    </div>
                <div className="eq-popup-content">
                        <div className="eq-popup-title">
                            {props.title}
                            </div>
                                <div className="eq-popup-description">
                                    {props.description}
                        </div>
                </div>
                <div className="eq-popup-close">
                        <button className="eq-popup-vector" onClick={togglePopup}>
                            <span className=""><img alt="" src="/Close.svg" /></span>
                        </button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="eq-popup-footer">
                            {props.popupButtons.length > 0 &&
                                <>
                                {props.popupButtons.map((item: IEqButton, index: number) =>
                                    <EqButton key={index} {...item}>{item.value}</EqButton>
                                )}
                                </>
                            }
                        </div>
                        </Col>
                </Row>
                </div>
            }

        </>
    );

}
export default EqPopup;