import React, { useState } from 'react';
import './EqInviteStudentBoard.scss';
import { Row, Col } from 'antd';
import EqInviteStudent from '../eqInviteStudent/EqInviteStudent';
import { useTranslation, Trans } from 'react-i18next';
import Api from '../../shared/Api';
import { EqStatusCode, GetInvitesMessage, ApiEndpoints, GetSentInviteMessage } from '../../shared/Common';


const EqInviteStudentBoard = () => {
    const { t, i18n } = useTranslation();
    const cancelFunction = (e: any) => { }
    const SendInviteClicked = (success: boolean) => {
        if (success == true) {

        }
    }
    const studentInviteProps = {
        header: t("Invite-Student"), emailHeading: "Please add your student email address", messageHeading: "Message",
        cancelFunc: cancelFunction, sendFunc: SendInviteClicked
    };
    return (
        <>

            <div className="eq-sb-container">
                <Row className="eq-sb-heading-row">
                    <Col className="eq-sb-col" flex="auto" sm={5} xs={4}>
                        <h2 className="eq-sb-heading">{t("students")}</h2>
                    </Col>
                </Row>

                <div>
                        <EqInviteStudent {...studentInviteProps}></EqInviteStudent>
                </div>
                </div>
        </>
    );
}
export default EqInviteStudentBoard;