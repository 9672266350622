import React, { useState, useEffect } from 'react';
import './EqComposeMessageInline.scss';
import EqComposeMessage from '../eqComposeMessage/EqComposeMessage';
import Api from '../../shared/Api';
import EqButton, { IEqButton } from '../../custom/eqButton/EqButton';
import { IEqMessageData } from '../eqMessage/EqMessage';
import { ApiEndpoints, GetSendMessage, EqStatusCode } from '../../shared/Common';
import { EqMessageType } from '../../shared/enums';
import { Row, Col, Form, Typography } from 'antd';
import { EqInput } from '../../custom/eqInput/EqInput';

export const EqComposeMessageInline = ({ messageDetail, showComponent, setShowComponent, sendReplyClicked, ...rest }: any) => {

    //const [toggleComponent, setToggleComponent] = useState(false);
    const [messageString, setMessageString] = React.useState('');
    const [formData, setFormData] = useState(messageDetail)
    const [message, setMessage] = useState("");
    const { Text } = Typography;
    const submitButton: IEqButton = { classNames: "btn-border-orange btn-background-orange btn-float-right", centered: false, bordered: true, type: 'submit' };

    React.useEffect(() => {
    }, []);

    const sendMessage = () => {
        setFormData({ ...formData, text: message });
        if (message.length > 0) {
            Api.post(ApiEndpoints.sendReply, { Message: { Key: formData.key, Message: message } }).then(response => {
                if (response && response.status == 200) {
                    setMessageString(GetSendMessage(response.status));
                    sendReplyClicked({ ...formData, text: message });
                    setMessage("");
                    setShowComponent(false);
                } else {
                    setMessageString(GetSendMessage(response.status));
                    setShowComponent(true);
                }
            }, err => {
                setMessageString(GetSendMessage(err.status));
                setShowComponent(true);
            });
        } else {
            setMessageString(GetSendMessage(EqStatusCode.inCompleteCredentials406));
            setShowComponent(true);
            document.getElementById('returnMessage')?.classList.remove('hide', 'valid');
            document.getElementById('returnMessage')?.classList.add('show', 'invalid')
        }
    }

    const onFinishFailed = (errorInfo: any) => { };
    return (
        /*add message popup component*/
        <>
            {
                showComponent &&
                <>
                    <Row className="eq-compose-message-inline">
                        <Col span={1}></Col>
                        <Col flex="auto">
                            <Row className="eq-message-in-subject-container">
                                <Col>Subject: </Col>
                                <Col className="eq-message-in-subject">{messageDetail.subject}</Col>
                            </Row>
                            <Row className="eq-message-in-time-container">
                                <Col>Date: </Col>
                                <Col className="eq-message-in-time"><Col>
                                    <Col>
                                        {messageDetail && messageDetail.messageOn && messageDetail.messageOn.toLocaleString()}
                                    </Col>

                                </Col>
                                </Col>
                            </Row>
                            <Row className="eq-message-in-message-text-container">
                                <Col>{messageDetail.text}</Col>
                            </Row>

                            <Form
                                layout="vertical"
                                size="small"
                                onFinish={sendMessage}
                                onFinishFailed={onFinishFailed}
                            >
                                <Row>
                                    <Col flex="auto">
                                        <EqInput type="text" className="eq-is-input" value={message} onChange={(e: any) => setMessage(e.target.value)}></EqInput>

                                    </Col>
                                </Row>
                                <Row><Col>
                                    <Text id="returnMessage" className="hide">
                                        {messageString}
                                    </Text>
                                </Col>
                                </Row>
                                <Row justify="start" align="bottom" className="eq-is-btn">
                                    <Col>
                                        <EqButton type="button" onClick={sendMessage} >Send Reply</EqButton>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </>
            }
        </>
    );
}

export default EqComposeMessageInline;