import React, { Fragment } from "react";
import "./EqProfessionalsBoard.scss";
import EqProfessionalsList, {
  IEqProfessionalsList,
} from "../eqProfessionalsList/EqProfessionalsList";
import EqProfessionalsGrid from "../eqProfessionalsGrid/EqProfessionalsGrid";
import { IEqProfessionals } from "../eqProfessionals/EqProfessionals";
import EqButton from "../../custom/eqButton/EqButton";
import EqSearch, { IEqSearch } from "../../custom/eqSearch/EqSearch";
import { Row, Col, Input, Skeleton, Spin } from "antd";
import { useTranslation, Trans } from "react-i18next";
import {
  ApiEndpoints,
  FrontEndPoints,
  getEmailFromToken,
  GetProfessionalsMessage,
} from "../../shared/Common";
import Api from "../../shared/Api";
import { EqLocationType, TrainersFilters } from "../../shared/enums";
import history from "../../shared/history";
import EqToggle, { IEqToggle } from "../../custom/eqToggle/EqToggle";
import { debug } from "console";
import { useNavigate } from "react-router-dom";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";
import { motion } from 'framer-motion';
import Reveal from "../../custom/Reveal/Reveal";
import RevealList from "../../custom/RevealList/RevealList";
const EqProfessionalsBoard = () => {
  // const currentUserKey = getEmailFromToken();
  const navigate = useNavigate();
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [search, setSearch] = React.useState("") as any;
  const [listView, setListView] = React.useState(true);
  const [isTrainersLoading, setIsTrainersLoading] = React.useState(false);
  const [trainersList, setTrainersList] = React.useState({
    allTrainers: [],
    myTrainers: [],
    selectedTrainers: [],
    filteredTrainers: [],
  }) as any;
  const [isCollapseAll, setIsCollapseAll] = React.useState(false);
  const [toggleFilters, setToggleFilters] = React.useState([] as any);

  const { t, i18n } = useTranslation();
  const { Search } = Input;
  const sendInvite = () => {
    navigate(FrontEndPoints.trainer.inviteStudent);
  };

  const filterData = (searchFilter: string | undefined) => {

    if (searchFilter != "") {
      var filterResult = trainersList.allTrainers;
      var areafilterResult = [] as any;
      var namefilterResult = [] as any;
      var homeAddressResult = [] as any;
      if (searchFilter != null && searchFilter.length > 0) {
        areafilterResult = filterResult.filter((item: any) => {
          let res = item?.userProfile?.serviceAreaList?.some((area: any) => {
            return area?.location?.city
              ?.toLowerCase()
              .includes(searchFilter.toLowerCase());
          });
          return res;
        });
      }
      // console.log(areafilterResult);
      if (searchFilter != null && searchFilter.length > 0) {
        namefilterResult = filterResult.filter((item: any) => {
          return item?.userProfile?.name.includes(searchFilter);
        });
      }

      if (searchFilter != null && searchFilter.length > 0) {
        namefilterResult = filterResult.filter((item: any) => {
          return item?.userProfile?.homeAddress?.city?.toLowerCase()
            .includes(searchFilter.toLowerCase());;
        });
      }


      filterResult = [...areafilterResult, ...namefilterResult, ...homeAddressResult]
      // filterResult = areafilterResult.concat(namefilterResult);
      console.log(filterResult);
      var uniqueFilterResult = [] as any;
      if (filterResult.length > 1) {
        uniqueFilterResult = filterResult.filter((item: any, index: any) => {
          return filterResult.indexOf(item) == index;
        });
      } else {
        uniqueFilterResult = [...filterResult];
      }
      //console.log(uniqueFilterResult);

      setTrainersList({
        ...trainersList,
        filteredTrainers: uniqueFilterResult,
      });
    } else {
      setTrainersList({
        ...trainersList,
        filteredTrainers: trainersList.allTrainers,
      });
    }
  };
  const eqSearchProps: IEqSearch = {
    groupClassNames: "round-search",
    inputClassNames: "round-search-input",
    style: {},
    buttonClick: (selectedVal, searchVal) => {
      setSearch(searchVal);
    },
    searchValueFun: (searchVal) => {
      setSearch(searchVal);
    },
  };
  const searchClicked = () => {
    filterData(search);
  };
  const getTrainers = () => {
    setIsTrainersLoading(true)
    // Api.get(ApiEndpoints.getTrainers).then(
    Api.get(ApiEndpoints.GetAllTrainers).then(
      (response) => {

        if (response && response.status == 200) {
          var dto = [] as any;

          response.data?.map((item: any) => {
            var itmLoc = item?.locations
              ?.filter((opt: any) => opt?.location?.type == EqLocationType.Home)
              ?.shift()?.location;
            dto.push({
              userProfile: {
                key: item.key,
                email: item.email,
                about: item.aboutMe,
                name: item?.firstName
                  ? item.firstName
                  : "" + " " + item.lastName
                    ? item.lastName
                    : "",
                homeAddress: itmLoc,
                disciplineList: item.disciplines,
                img: <img src="images/partners/partner.png" alt="trainer" />,
                display: false,
                userImage: item.userImage,
                trainingLevels: item.trainingLevels,
                trainerStudents: item.trainerStudents,
                isMyTrainer: item.isMyTrainer,
                serviceAreaList: item.locations?.filter(
                  (opt: any) => opt.location?.type == EqLocationType.Work
                ),
              },
            });
          });
          console.log(dto);


          var myTrainers = [...dto].filter(
            (item: any) => {
              return item.userProfile.isMyTrainer == true;
            }

          );
          //   var myTrainers = new Array<any>();
          //   [...dto].map((item: any) => {
          //     if (item.isMyTrainer) {
          //         myTrainers.push(item);
          //     }
          //   });

          //   var myTrainers = new Array<any>();
          //   myTrainers = [...dto].filter((item: any) => {
          //     if (item.trainerStudents && item.trainerStudents.length > 0) {
          //       [...item.trainerStudents].some((st: any) => {
          //         if (st.student.email == currentUserKey) {
          //           return item;
          //         }
          //       });
          //     }
          //   });
          console.log(myTrainers);
          setTrainersList({
            allTrainers: dto,
            selectedTrainers: dto,
            myTrainers: myTrainers,
            filteredTrainers: dto,
          });

          setIsError(false);
          setMessage(GetProfessionalsMessage(response.status));
          setIsTrainersLoading(false)

        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetProfessionalsMessage(error.response.status));
        setIsTrainersLoading(false)
      }
    );
  };

  const toggleView = () => {
    setListView(!listView);
  };
  const handleIsCollapseAll = (props: boolean) => {
    setIsCollapseAll(props);
  };
  const collapseAll = () => {
    handleIsCollapseAll(!isCollapseAll);
    trainersList.filteredTrainers.map((f: IEqProfessionals) => {
      if (f.userProfile != undefined) {
        f.userProfile.display = !isCollapseAll;
      }
    });
  };
  const createToggleFilters = () => {
    var filters = [];
    for (var x in TrainersFilters) {
      if (isNaN(Number(x)) == false) {
        filters.push({ key: Number(x), value: TrainersFilters[x] });
      }
    }
    setToggleFilters(filters);
  };

  const accessTogglerProps: IEqToggle = {
    items: toggleFilters.map((filter: any) => ({
      key: filter.key,
      value: t(filter.value), // Translate the filter label
    })),
    defaultSelectedValue: toggleFilters[0],
    classNames: "eq-toggle-orange-border eq-toggle-create-event",
    changeFunc: (value: string) => {
      console.log(trainersList.selectedTrainers);
      let filter: number = +value;
      //   setSelectedToggle(filter);

      if (filter === TrainersFilters.AllTrainers) {
        setTrainersList({
          ...trainersList,
          filteredTrainers: trainersList.allTrainers,
        });
        // setSelectedTrainingsList(filteredTrainingsList.available);
      } else if (filter === TrainersFilters.MyTrainers) {
        setTrainersList({
          ...trainersList,
          filteredTrainers: trainersList.myTrainers,
        });
        // setSelectedTrainingsList(filteredTrainingsList.joined);
      }
    },
  };

  React.useEffect(() => {

    createToggleFilters();
    getTrainers();
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: "100%", opacity: 1, transition: { duration: 1, ease: 'linear' } }}
      exit={{ height: 0, opacity: 0, transition: { duration: 1, ease: 'linear' } }}
    >
      <div className="eq-pf-board">
        <div className="eq-container-fluid">
          <Row className="eq-pf-heading-row" align="middle" justify="start">
            <Col className="eq-pf-heading-col" flex="auto">
              <h3 className="eq-pf-heading">{t("professionals")}</h3>
            </Col>
            <Col>
              <Row>
                <EqToggle {...accessTogglerProps} />
              </Row>
            </Col>
          </Row>
          <Row className="eq-pf-heading-line"></Row>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={{ span: 8, push: 16 }}
              className="eq-pb-right"
            >
              <Row>
                <h5 className="eq-pb-search-heading">{t("search")}</h5>
              </Row>
              <Row>
                <Col flex="auto">
                  <div className="eq-pb-search">
                    <EqSearch {...eqSearchProps}></EqSearch>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col flex="auto">
                  <div className="eq-pb-btn">
                    <EqButton
                      type="button"
                      block={true}
                      onClick={searchClicked}
                      classNames="btn-height"
                    >
                      <span className="eq-btn-icon">
                        <img alt="" src="/search.svg" />
                      </span>
                      <span className="eq-sb-clear-btn">{t("search")}</span>
                    </EqButton>
                  </div>
                </Col>
              </Row>
              <Row>
                <h5 className="eq-pb-invite">{t("inviteAProfessional")}</h5>
              </Row>
              <Row>
                <div className="eq-pb-invite-info">
                  {t("KnowAProfessional")}
                </div>
              </Row>
              <Row>
                <Col flex="auto">
                  <div className="eq-pb-btn">
                    <EqButton
                      type="button"
                      bordered="true"
                      block={true}
                      onClick={sendInvite}
                      classNames="btn-height btn-border-text-orange"
                    >
                      {t("sendInvite")}
                    </EqButton>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={{ span: 16, pull: 8 }}
              className="eq-pb-left col-xs-push-16"
            >
              <div className="eq-pl-row">
                <Row>
                  <Col flex="auto">
                    <Row className="eq-pl-heading" justify="start">
                      {t("professionals")}
                    </Row>
                  </Col>
                  <Col flex="auto" className="hidden-sm">
                    <Row className="eq-pl-icons" justify="end">
                      {listView ? (
                        <>
                          <span className="eq-pl-icon">
                            <img
                              alt=""
                              src="/orange-th-list.svg"
                              onClick={toggleView}
                            />
                          </span>
                          <span className="eq-pl-icon">
                            <img
                              alt=""
                              src="/th-large.svg"
                              onClick={toggleView}
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="eq-pl-icon">
                            <img alt="" src="/th-list.svg" onClick={toggleView} />
                          </span>
                          <span className="eq-pl-icon">
                            <img
                              alt=""
                              src="/orange-th-large.svg"
                              onClick={toggleView}
                            />
                          </span>
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col className="hidden-md">
                    <EqButton
                      type="button"
                      classNames="btn-background-orange"
                      onClick={collapseAll}
                    >
                      {isCollapseAll ? "Collapse All" : "Expand All"}
                    </EqButton>
                  </Col>
                </Row>
              </div>
              {/* <Skeleton  active={isTrainersLoading} /> */}
              <Row justify="center">
                {Array.from({ length: 5 }).map((_, index) => (
                    <Skeleton loading={isTrainersLoading} avatar paragraph={{ rows: 3 }} />

                ))}
              </Row>

              {listView ? (
                <Reveal>
                  <EqProfessionalsList
                    {...{ professionalsList: trainersList.filteredTrainers }}
                  ></EqProfessionalsList>
                </Reveal>
              ) : (
                <Row>
                  <EqProfessionalsGrid
                    {...{ professionalsList: trainersList.filteredTrainers }}
                  ></EqProfessionalsGrid>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </motion.div>


  );
};
export default EqProfessionalsBoard;
