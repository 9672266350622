import * as React from "react";
import EqEventCard from "../eqEventCard/EqEventCard";
import { Row, Col, Empty } from "antd";
import { addDays, addDaysUTC, dateFormat } from "../../shared/Common";
import "./EqEventGrid.scss";
import EqButton from "../../custom/eqButton/EqButton";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";
import { IEqEventList } from "../../shared/Interfaces";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import EqCard, { IEqCard } from "../../custom/eqCard/EqCard";


export interface IEqEventSplit {
  today: Array<IEqEventData>;
  tomorrow: Array<IEqEventData>;
  later: Array<IEqEventData>;
}

const EqEventGrid = ({
  eventList,
  removeEventFunc,
  editEventFunc,
  removeAttendeeFunc,
  ...rest
}: IEqEventList) => {
  const [eventData, setEventData] = React.useState({} as IEqEventSplit);
  const { t, i18n } = useTranslation();

  const options: Intl.DateTimeFormatOptions = {
    weekday: "long", // Correctly typed as "long" | "short" | "narrow" | undefined
    day: "2-digit",  // Correctly typed as "numeric" | "2-digit" | undefined
    month: "short",  // Correctly typed as "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined
    year: "numeric", // Correctly typed as "numeric" | "2-digit" | undefined
};
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

  const tomorrow = new Date();
  tomorrow.setHours(24, 0, 0, 0);
  const tomorrowTime = tomorrow.getTime();

  const dayAfterTomorrow = new Date();
  dayAfterTomorrow.setHours(48, 0, 0, 0);
  const dayAfterTomorrowTime = dayAfterTomorrow.getTime();

  React.useEffect(() => {
    var formedData = {} as IEqEventSplit;
    //formedData.today = eventList;
    formedData.today = eventList?.filter(
      (opt: any) => opt.startDate < tomorrowTime && opt.startDate >= todayTime
    );
    formedData.tomorrow = eventList?.filter(
      (opt: any) =>
        opt.startDate < dayAfterTomorrowTime && opt.startDate >= tomorrowTime
    );
    formedData.later = eventList?.filter(
      (opt: any) => opt.startDate >= dayAfterTomorrowTime
    );
    setEventData(formedData);
    console.log(eventList);
  }, [eventList]);

  return (
    <>
      <div className="eq-el-container eq-el-container-today">
        <Row>
          <Col flex="auto">
            <h4 className="eq-el-heading">{t("today")}</h4>
          </Col>
          <Col className="eq-el-dateHeading">
            <h5 className="eq-el-date">
            {new Date().toLocaleDateString(t("dateLang"),options as any)}
            </h5>

            <div className="eq-el-date-icon hidden-sm">
              <span>
                <img alt="" src="/DateEdit.svg" />
              </span>
            </div>
          </Col>
        </Row>
        {eventData?.today?.length > 0 ? (
          <Row>
            <>
              {eventData.today.map((item: IEqEventData, index: number) => (
                <>
                  <Col md={8} xs={24} sm={24}>
                    <EqEventCard
                      {...{
                        event: { ...item },
                        removeEventFunc: removeEventFunc,
                        editEventFunc: editEventFunc,
                        removeAttendeeFunc: removeAttendeeFunc,
                      }}
                    ></EqEventCard>
                  </Col>
                </>
              ))}
            </>
          </Row>
        ) : (
          <Row justify="center">
            <Empty description={t("noEventsAvailable")} />
          </Row>
        )}
      </div>
      <div className="eq-el-container">
        <Row>
          <Col flex="auto">
            <h4 className="eq-el-heading">{t("tomorrow")}</h4>
          </Col>
          <Col className="eq-el-dateHeading">
            <h5 className="eq-el-date">
            {addDays(new Date(), 1).toLocaleDateString(t("dateLang"), options)}
            </h5>

            <div className="eq-el-date-icon hidden-sm">
              <span>
                <img alt="" src="/DateEdit.svg" />
              </span>
            </div>
          </Col>
        </Row>
        {eventData?.tomorrow?.length > 0 ? (
          <Row>
            <>
              {eventData.tomorrow.map((item: IEqEventData, index: number) => (
                <Col md={8} xs={24} sm={24}>
                  <EqEventCard
                    {...{
                      event: { ...item },
                      removeEventFunc: removeEventFunc,
                      editEventFunc: editEventFunc,
                      removeAttendeeFunc: removeAttendeeFunc,
                    }}
                  ></EqEventCard>
                </Col>
              ))}
            </>
          </Row>
        ) : (
          <Row justify="center">
            <Empty description={t("noEventsAvailable")} />
          </Row>
        )}
      </div>
      <div className="eq-el-container">
        <h4 className="eq-el-heading">{t("later")}</h4>
          {eventData?.later?.length > 0 ? (
        <Row>
            <>
              {eventData.later.map((item: IEqEventData, index: number) => (
                <Col md={8} xs={24} sm={24}>
                  <EqEventCard
                    {...{
                      event: { ...item },
                      removeEventFunc: removeEventFunc,
                      editEventFunc: editEventFunc,
                      removeAttendeeFunc: removeAttendeeFunc,
                    }}
                  ></EqEventCard>
                </Col>
              ))}
            </>
        </Row>
          ):<Row justify="center">
          <Empty description={t("noEventsAvailable")} />
        </Row>
          }
      </div>
    </>
  );
};
export default EqEventGrid;
