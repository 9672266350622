import { Badge, Calendar, Col, Modal, Row, Select, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarMinus,
  faCalendarXmark,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "./EqOverviewTrainerProfile.scss";
import Api from "../../shared/AApi";
import {
  ApiEndpoints,
  FrontEndPoints,
  GetEventMessage,
  ValidateIsSchool,
  ValidateIsTrainer,
  getEmailFromToken,
  openNotificationWithIcon,
} from "../../shared/Common";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import {
  EqDisciplineType,
  EqMessageType,
  eqNotificationTypes,
} from "../../shared/enums";
import {
  CalendarOutlined,
  CaretRightOutlined,
  ClockCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import EqSelect from "../../custom/eqSelect/EqSelect";
import { ISelectProps } from "../../shared/Interfaces";
import { useTranslation, Trans } from "react-i18next";
export interface IEqTrainerOverView {
  stat: any;
  trainingList: any;
}
const EqOverviewTrainerProfile = ({
  stat,
  trainingList,
}: IEqTrainerOverView) => {
  const navigate = useNavigate();
  const userDetails: any = useAuthState();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const isSchool: boolean = ValidateIsSchool(userDetails);
  const currentUserEmail = getEmailFromToken();
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reminderText, setReminderText] = useState({
    heading: "",
    subject: "",
    message: "",
    senderEmail: currentUserEmail,
    messageType: EqMessageType.Notification,
    recipientEmails: [],
  });
  const [emails, setEmails] = useState<string[]>([]);
  const [optionEmails, setOptionEmails] = React.useState([] as any);
  const [selectedEmails, setSelectedEmails] = React.useState([] as any);

  const showModal = (reminderType: string) => {
    setIsModalOpen(true);
    let heading = "";
    let uniqueEmails: Set<string> = new Set();
    let message = "";
    let subject = "";

    switch (reminderType) {
      case "unpaidTraining":
        stat.unpaidTraining.forEach((invite: any) =>
          uniqueEmails.add(invite.userInfo.email)
        );
        heading = t("paymentReminder");
        subject = `Payment Reminder`;
        message = `You have unpaid trainings that you have accepted.
                Please log in to Equinet to pay.
                `;
        break;
      case "pendingTraining":
        stat.pendingTraining.forEach((invite: any) =>
          uniqueEmails.add(invite.userInfo.email)
        );
        heading = t("invitationReminder");
        subject = `Invitation Reminder`;
        message = `
                You have invites to activities that you need to respond to.
                Please log in to Equinet to accept or reject your invites.
                `;
        break;
      case "unpaidProduct":
        stat.unpaidProduct.forEach((invite: any) =>
          uniqueEmails.add(invite.userInfo.email)
        );
        heading = t("paymentReminder");
        subject = `Payment Reminder`;
        message = `You have unpaid products that you have accepted.
                Please log in to Equinet to pay.
                `;
        break;
      case "pendingProduct":
        stat.pendingProduct.forEach((invite: any) =>
          uniqueEmails.add(invite.userInfo.email)
        );
        heading = t("invitationReminder");
        subject = `Invitation Reminder`;
        message = `
                You have invites to products that you need to respond to.
                Please log in to Equinet to accept or reject your invites.
                `;
        break;
      default:
        break;
    }

    setEmails(Array.from(uniqueEmails));
    setSelectedEmails(Array.from(uniqueEmails));

    setReminderText({
      ...reminderText,
      subject: subject,
      message: message,
      heading: heading,
    });
  };
  const handleOk = () => {
    sendReminder();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedEmails([]);
  };

  const SendReminder = () => {
    var data = reminderText;
    data.recipientEmails = selectedEmails;

    if (data.recipientEmails.length > 0) {
    }
    Api.post(ApiEndpoints.SendReminder, data).then(
      (response) => {
        if (response && response.status == 200) {
          openNotificationWithIcon(
            "Reminder Sent!",
            "you have Send Reminder To Students",
            eqNotificationTypes.success
          );
        } else {
          openNotificationWithIcon(
            "Server error",
            "Error occurred while Sending Reminder. Please try again or contact administrator."
          );
        }
      },
      (error) => {
        openNotificationWithIcon(
          "Server error",
          "Error occurred while Sending Reminder. Please try again or contact administrator."
        );
      }
    );
  };

  const sendReminder = async () => {
    try {
      const data = {
        ...reminderText,
        recipientEmails: selectedEmails,
      };

      if (data.recipientEmails.length === 0) {
        return; // No need to proceed if no recipients
      }

      const response = await Api.post(ApiEndpoints.SendReminder, data);

      if (response && response.status === 200) {
        openNotificationWithIcon(
          "Reminder Sent!",
          "You have sent a reminder to students",
          eqNotificationTypes.success
        );
      } else {
        throw new Error("Server error");
      }
    } catch (error) {
      openNotificationWithIcon(
        "Server error",
        "Error occurred while sending a reminder. Please try again or contact the administrator."
      );
    }
  };

  const dateCellRender = (value: any) => {
    const date = moment(value).format("YYYY-MM-DD");
    const trainings = trainingList?.filter(
      (training: any) =>
        moment(training.startDate).format("YYYY-MM-DD") === date
    );

    return (
      <div>
        {trainings &&
          trainings.map((event: any, index: number) => (
            <Badge key={index} status="warning" />
          ))}
      </div>
    );
  };

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const currentDate = new Date();
  const currentMonth = months[currentDate.getMonth()];

  const createEmailList = () => {
    var eventTypes: any[] = [];

    emails.forEach((email, index) => {
      eventTypes.push({
        key: email,
        value: email,
      });
    });
    setOptionEmails(eventTypes);
  };

  const searchProps: ISelectProps = {
    classNames: `eq-multiSelect eq-searchSelect`,
    // placeholder: t("training"),
    extraHeight: true,
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedEmails ? selectedEmails : null,
    options: optionEmails,
    changeFunc: (value: any) => {
      console.log(value);
      setSelectedEmails(value);
    },
  };

  useEffect(() => {
    createEmailList();
  }, [emails]);

  return (
    <div>
      <Row gutter={[16, 16]} className="eq-tr-view">
        <Col sm={24} md={12} lg={16}>
          <Row gutter={[20, 20]} className="eq-overview">
            <Col md={24} lg={12} className="eq-col-view">
              <div className="eq-training-overview">
                <div className="overview-info">
                  <div>
                    <h4 className="heading">{t("unpaidTrainings")}</h4>
                    <p className="text">
                      {t("studentsWhoAcceptedTrainingInvites")}
                    </p>
                  </div>
                  <FontAwesomeIcon
                    className="basic-icon"
                    icon={faCalendarXmark}
                  />
                </div>
                <div className="dec-overview">
                  <div className="overview-status status-unpaid">
                    <div className="count-overview">
                      {stat.unpaidTraining.length}
                    </div>
                    <span className="status-std">{t("unpaidStudents")}</span>
                  </div>
                  <div
                    className="overview-msg"
                   
                  >
                    <Row  onClick={() => showModal("unpaidTraining")}>
                      <img src="/Envelope.svg" alt="env" />
                      <span className="msg">{t("sendReminder")}</span>
                    </Row>
                    <Row>
                      <img src="/list.svg" alt="list" />
                      <Link to={`${FrontEndPoints.trainer.activityBoard}?tab=5`}>
                      <span className="msg">{t("viewAll")}</span>
                    </Link>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={24} lg={12} className="eq-col-view">
              <div className="eq-training-overview">
                <div className="overview-info">
                  <div>
                    <h4 className="heading">{t("nonRespondentTraining")}</h4>
                    <p className="text">
                      {t("studentsWhoNotRespondYourTrainingInvites")}
                    </p>
                  </div>
                  <FontAwesomeIcon
                    className="basic-icon"
                    icon={faCalendarMinus}
                  />
                </div>
                <div className="dec-overview">
                  <div className="overview-status status-non-response">
                    <div className="count-overview">
                      {stat.pendingTraining.length}
                    </div>
                    <span className="status-std">
                      {t("nonRespondentStudents")}
                    </span>
                  </div>
                  <div
                    className="overview-msg"
                  >

                    <Row  onClick={() => showModal("pendingTraining")}>
                      <img src="/Envelope.svg" alt="env" />
                      <span className="msg">{t("sendReminder")}</span>
                    </Row>
                    <Row>
                      <img src="/list.svg" alt="list" />
                      <Link to={`${FrontEndPoints.trainer.invitations}`}>
                      <span className="msg">{t("viewAll")}</span>
                    </Link>
                    </Row>

                  </div>


                </div>
              </div>
            </Col>
            <Col md={24} lg={12} className="eq-col-view">
              <div className="eq-training-overview">
                <div className="overview-info">
                  <div>
                    <h4 className="heading">{t("unpaidProducts")}</h4>
                    <p className="text">
                      {t("studentsWhoAcceptedProductsInvites")}
                    </p>
                  </div>
                  <FontAwesomeIcon
                    className="basic-icon"
                    icon={faCalendarXmark}
                  />
                </div>
                <div className="dec-overview">
                  <div className="overview-status status-unpaid">
                    <div className="count-overview">
                      {stat.unpaidProduct.length}
                    </div>
                    <span className="status-std">{t("unpaidStudents")}</span>
                  </div>
                  <div
                    className="overview-msg"
                    onClick={() => showModal("unpaidProduct")}
                  >
                    <img src="/Envelope.svg" alt="env" />
                    <span className="msg">{t("sendReminder")}</span>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={24} lg={12} className="eq-col-view">
              <div className="eq-training-overview">
                <div className="overview-info">
                  <div>
                    <h4 className="heading">{t("nonRespondentProducts")}</h4>
                    <p className="text">
                      {t("studentsWhoNotRespondYourProductInvites")}
                    </p>
                  </div>
                  <FontAwesomeIcon
                    className="basic-icon"
                    icon={faCalendarMinus}
                  />
                </div>
                <div className="dec-overview">
                  <div className="overview-status status-non-response">
                    <div className="count-overview">
                      {stat.pendingProduct.length}
                    </div>
                    <span className="status-std">
                      {t("nonRespondentStudents")}
                    </span>
                  </div>
                  <div
                    className="overview-msg"
                  >
                 


                    <Row  onClick={() => showModal("pendingProduct")}>
                      <img src="/Envelope.svg" alt="env" />
                      <span className="msg">{t("sendReminder")}</span>
                    </Row>
                    <Row>
                      <img src="/list.svg" alt="list" />
                      <Link to={`${FrontEndPoints.trainer.invitations}`}>
                      <span className="msg">{t("viewAll")}</span>
                    </Link>
                    </Row>

                  </div>
                </div>
              </div>
            </Col>
            <Col span={24} className="eq-col-view">
              <div className="eq-training-overview">
                <div className="overview-info">
                  <div>
                    <h4 className="heading">{t("monthlyEarning")}</h4>
                    <p className="text">
                      {t("total")} {currentMonth} {t("monthEarning")}
                    </p>
                  </div>
                  <FontAwesomeIcon
                    className="total-money-icon"
                    icon={faMoneyBill}
                  />
                </div>
                <div className="dec-overview">
                  <div className="overview-status status-earning">
                    <div className="count-overview">
                      Kr. {stat.monthlySales}
                    </div>
                    <span className="status-std ">
                      {t("totalMonthlyEarning")}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <Row gutter={[16, 16]} className="eq-overview">
            <Col span={24}>
              <div className="eq-training-overview training-overview">
                <h4 className="count-heading">{t("upcomingTrainings")}</h4>
                <div className="training-count">{stat.upcomingTraining}</div>
              </div>
            </Col>
            <Col>
              <div className="training-overview-cal">
                <Calendar fullscreen={false} dateCellRender={dateCellRender} />

                <>
                  <div className="calendar-event-list">
                    {trainingList &&
                      trainingList
                        .slice(0, 1)
                        .map((training: any, index: number) => (
                          <div key={index} className="event-list">
                            <div className="date-event">
                              {moment(training.startDate).format("YYYY-MM-DD")}
                            </div>
                            <div className="name-event">{training.title}</div>
                          </div>
                        ))}

                    <div className="overview-msg">
                      <Link
                        to={`${FrontEndPoints.trainer.profileBoard}?key=${userDetails.key}`}
                      >
                        <span className="msg">{t("viewAll")}</span>
                      </Link>
                    </div>
                  </div>
                </>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        className="send-reminder-modal"
        title={reminderText.heading}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t("sendReminder")}
        cancelText={t("cancel")}
        width={"85vw"}
      >
        <h4>{t("to")}</h4>
        <EqSelect {...searchProps}></EqSelect>
        {/* <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Select email addresses"
                    onChange={handleEmailSelectionChange}
                    value={selectedEmails}
                >
                    {emails.map((email: string, index: number) => (
                        <Option  value={email}>
                            {email}
                        </Option>
                    ))}
                </Select> */}

        <h4>{t("message")}</h4>
        <textarea
          className="send-mail-input"
          value={reminderText.message}
          onChange={(e) =>
            setReminderText({ ...reminderText, message: e.target.value })
          }
          rows={4}
        />
      </Modal>
    </div>
  );
};

export default EqOverviewTrainerProfile;
