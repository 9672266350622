import { Col, Row, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import './ProfessionalConnections.scss'
import { useTranslation } from "react-i18next";
import EqButton from '../../custom/eqButton/EqButton';
import EqSearch, { IEqSearch } from '../../custom/eqSearch/EqSearch';
import { ApiEndpoints } from '../../shared/Common';
import Api from '../../shared/AApi';
import RevealList from '../../custom/RevealList/RevealList';
import EqSubscribedProfessional from '../EqSubscribedProfessional/EqSubscribedProfessional';
import EqSubscribedProfessionalGrid from '../EqSubscribedProfessionalGrid/EqSubscribedProfessionalGrid';

const ProfessionalConnections = () => {
    const { t, i18n } = useTranslation();
    const [messageString, setMessageString] = useState("");

    const [isTrainersLoading, setIsTrainersLoading] = React.useState(false);


    const [professionalsList, setProfessionalsList] = useState({
        all: [],
        filtered: []
    }) as any;

    const [myConnectionsList, setMyConnectionsList] = useState({
        all: [],
        filtered: []
    }) as any;
    const [search, setSearch] = React.useState("") as any;

    const [activeTab, setActiveTab] = useState("MyConnections");
    const [listView, setListView] = React.useState(true);


    const toggleView = () => {
        setListView(!listView);
    };
    const GetAllProfessionals = () => {
        setMessageString("");
        setIsTrainersLoading(true)
        Api.get(ApiEndpoints.allProfessionals).then(
            (response) => {
                if (response && response.status == 200) {
                    const data = response.data ? [...response.data] : [];
                    if (data.length > 0) {
                        const connections = data.filter((opt) => opt.isMyTrainer || opt.isMySchool === true);


                        setMyConnectionsList({
                            all: connections,
                            filtered: connections
                        })

                        console.log("conection", connections)

                    }
                    setProfessionalsList({
                        all: data,
                        filtered: data
                    })
                    setIsTrainersLoading(false)
                    console.log("professionals", data)
                } else {
                    setMessageString("Not found");
                    setIsTrainersLoading(false)

                }
            },
            (err) => {
                setMessageString("Not found");
                setIsTrainersLoading(false)

            }
        );
    }

    const filterProfessional = (searchFilter: string | undefined) => {

        if (searchFilter != "") {
            var filterResult: any[] = professionalsList.all;
            var areaFilterResult = [] as any;
            var nameFilterResult = [] as any;


            if (searchFilter != null && searchFilter.length > 0) {
                areaFilterResult = filterResult.filter((items: any) =>
                    items?.locations.some((e: any) =>
                        e.location.city?.toLowerCase().includes(searchFilter.toLowerCase())
                    )
                );
            }


            if (searchFilter != null && searchFilter.length > 0) {
                nameFilterResult = filterResult.filter((item: any) => {
                    const firstName = item?.firstName?.toLowerCase() || '';
                    return firstName.includes(searchFilter.toLowerCase());
                });
            }

            filterResult = [...areaFilterResult, ...nameFilterResult];
            console.log("fiterresul", filterResult);
            var uniqueFilterResult = [] as any;
            if (filterResult.length > 1) {
                uniqueFilterResult = filterResult.filter((item: any, index: any) => {
                    return filterResult.indexOf(item) == index;
                });
            } else {
                uniqueFilterResult = [...filterResult];
            }
            //console.log(uniqueFilterResult);

            setProfessionalsList({
                ...professionalsList,
                filtered: uniqueFilterResult,
            });

            setMyConnectionsList({
                ...myConnectionsList,
                filtered: uniqueFilterResult,
            });
        } else {
            setProfessionalsList({
                ...professionalsList,
                filtered: professionalsList.all,
            });

            setMyConnectionsList({
                ...myConnectionsList,
                filtered: myConnectionsList.all,
            });

        }
    };

    const eqSearchProps: IEqSearch = {
        groupClassNames: "round-search",
        inputClassNames: "round-search-input",
        style: {},
        buttonClick: (selectedVal, searchVal) => {
            setSearch(searchVal);
        },
        searchValueFun: (searchVal) => {
            setSearch(searchVal);
        },
    };

    const searchClicked = () => {
        filterProfessional(search);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        GetAllProfessionals()

    }, []);


    return (
        <div className="eq-sc-board">
            <Row className="eq-pf-heading-row" align="middle" justify="start">
                <Col className="eq-pf-heading-col" flex="auto">
                    <h3 className="eq-pf-heading">{t("professionals")}</h3>
                </Col>
                <Col>
                    <Row className="eq-pf-toggle-btn">
                        <EqButton classNames="eq-pf-toggle" onClick={() => setActiveTab("AllProfessionals")}>{t("AllProfessionals")}</EqButton>
                        <EqButton className="" onClick={() => setActiveTab("MyConnections")}>{t("MyConnections")}</EqButton>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col
                    xs={24}
                    sm={24}
                    md={8}
                    className="eq-pb-right"
                >
                    <Row>
                        <div className="eq-pb-search-heading">{t("search")}</div>
                    </Row>
                    <Row>
                        <Col flex="auto">
                            <div className="eq-pb-search">
                                <EqSearch {...eqSearchProps}></EqSearch>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col flex="auto">
                            <div className="eq-pb-btn">
                                <EqButton
                                    type="button"
                                    block={true}
                                    onClick={searchClicked}
                                    classNames="btn-height"
                                >
                                    <span className="eq-btn-icon">
                                        <img alt="" src="/search.svg" />
                                    </span>
                                    <span className="eq-sb-clear-btn">{t("search")}</span>
                                </EqButton>
                            </div>
                        </Col>
                    </Row>

                </Col>
                <Col sm={24} md={16}>
                    {activeTab === "AllProfessionals" ? (
                        <RevealList>
                            <>
                                <div className="eq-section-divider"></div>

                                <div className="eq-section">
                                    <Row justify='space-between' >
                                        <h5 className='eq-pf-sub-heading'>{t("AllProfessionals")}</h5>

                                        <Col className="eq-pl-icons" >
                                            {listView ? (
                                                <>
                                                    <span className="eq-pl-icon">
                                                        <img
                                                            alt=""
                                                            src="/orange-th-list.svg"
                                                            onClick={toggleView}
                                                        />
                                                    </span>
                                                    <span className="eq-pl-icon">
                                                        <img
                                                            alt=""
                                                            src="/th-large.svg"
                                                            onClick={toggleView}
                                                        />
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <span className="eq-pl-icon">
                                                        <img alt="" src="/th-list.svg" onClick={toggleView} />
                                                    </span>
                                                    <span className="eq-pl-icon">
                                                        <img
                                                            alt=""
                                                            src="/orange-th-large.svg"
                                                            onClick={toggleView}
                                                        />
                                                    </span>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                    {professionalsList.filtered && professionalsList.filtered.length > 0 ? (
                                        <>
                                            {isTrainersLoading ? (
                                                <Skeleton avatar paragraph={{ rows: 3 }} active />
                                            ) : listView ? (
                                                <>
                                                    {professionalsList.filtered.map((item: any, index: number) => (
                                                        <EqSubscribedProfessional key={index} professional={item} />
                                                    ))}
                                                </>
                                            ) : (
                                                <Row gutter={[16, 16]} justify="space-around">
                                                    {professionalsList.filtered.map((item: any, index: number) => (
                                                        <EqSubscribedProfessionalGrid key={index} trainer={item} />
                                                    ))}
                                                </Row>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <p>No Professionals Available</p>
                                        </>
                                    )}
                                </div>
                            </>
                        </RevealList>
                    ) : activeTab === "MyConnections" ? (
                        <RevealList>
                            <>
                                <div className="eq-section-divider"></div>
                                <div className="eq-section">

                                    <Row justify='space-between' >
                                        <h5 className='eq-pf-sub-heading'>{t("MyConnections")}</h5>
                                        <Col className="eq-pl-icons" >
                                            {listView ? (
                                                <>
                                                    <span className="eq-pl-icon">
                                                        <img
                                                            alt=""
                                                            src="/orange-th-list.svg"
                                                            onClick={toggleView}
                                                        />
                                                    </span>
                                                    <span className="eq-pl-icon">
                                                        <img
                                                            alt=""
                                                            src="/th-large.svg"
                                                            onClick={toggleView}
                                                        />
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <span className="eq-pl-icon">
                                                        <img alt="" src="/th-list.svg" onClick={toggleView} />
                                                    </span>
                                                    <span className="eq-pl-icon">
                                                        <img
                                                            alt=""
                                                            src="/orange-th-large.svg"
                                                            onClick={toggleView}
                                                        />
                                                    </span>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                    {isTrainersLoading ? (
                                         Array.from({ length: 5 }).map((_, index) => (
                                            <Skeleton key={index} avatar paragraph={{ rows: 3 }} active />
                                        ))
                                    ) : (
                                        <>
                                            {myConnectionsList.filtered && myConnectionsList.filtered.length > 0 ? (
                                                listView ? (
                                                    <>
                                                        {myConnectionsList.filtered.map((item: any, index: number) => (
                                                            <EqSubscribedProfessional key={index} professional={item} />
                                                        ))}
                                                    </>
                                                ) : (
                                                    <Row gutter={[16, 16]} justify="space-around">
                                                        {myConnectionsList.filtered.map((item: any, index: number) => (
                                                            <EqSubscribedProfessionalGrid key={index} trainer={item} />
                                                        ))}
                                                    </Row>
                                                )
                                            ) : (
                                                <p>No Subscribed Trainer Available</p>
                                            )}
                                        </>
                                    )}

                                </div>
                            </>
                        </RevealList>
                    ) : null}
                </Col>
            </Row>
            
        </div>
    );
};

export default ProfessionalConnections;
