// EqSubscriptionProductTable.tsx

import React from 'react';
import { Table, Button, Popconfirm } from 'antd';
// import { SubscriptionProduct } from './YourApiTypes'; // Import your API types

interface EqSubscriptionProductTableProps {
  products: any[];
  onDelete: (productId: string) => void;
}

const EqSubscriptionProductTable: React.FC<EqSubscriptionProductTableProps> = ({ products, onDelete }) => {
  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'Price', dataIndex: 'price', key: 'price' },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: any) => (
        <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record.id)}>
          <Button type="link">Delete</Button>
        </Popconfirm>
      ),
    },
  ];

  return <Table dataSource={products} columns={columns} />;
};

export default EqSubscriptionProductTable;
