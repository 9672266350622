import * as React from "react";
import "./EqMessageBoard.scss";
import { IEqMessageData } from "../eqMessage/EqMessage";
import { eqNotificationTypes } from "../../shared/enums";
import { useState, useEffect } from "react";

import Api from "../../shared/Api";
import {
    ApiEndpoints,
    GetEventMessage,
    getEmailFromToken,
    openNotificationWithIcon,
} from "../../shared/Common";
import { Col, Input, Row, Skeleton, message, Badge } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import EqSendMessageForm from "./eqSendMessageForm/EqSendMessageForm";
import EqMessageListItem from "./EqMessageListItem/EqMessageListItem";
import EqMessageReply from "./eqMessageReply/EqMessageReply";
import { useAuthState } from "../../context/Context";
const MessageBoard = () => {
    const { t, i18n } = useTranslation();
    const { Search } = Input;
    const userDetails: any = useAuthState();
    const currentUserEmail = getEmailFromToken();

    const [replyBox, setReplyBox] = useState([]) as any;
    const [messageBtn, setMessageBtn] = useState(false);
    const [loading, setLoading] = useState(true);

    const [userMessages, setUserMessages] = useState<any>(null);

    const [error, setError] = useState<string | null>(null);
    const [userAndGroupOptions, setUserAndGroupOptions] = React.useState(
        []
    ) as any;
    const [errorMessage, setErrorMessage] = React.useState("");

    const [show, setShow] = useState(false);

    const [search, setSearch] = React.useState({
        searchVal: "",
    });

    const [searchEmail, setSearchEmail] = React.useState("");

    var selectedMessages = new Array<any>();

  const filterUserMessageList = (messageList: any[]) => {
    let filteredList = messageList;
    
    if (searchEmail !== "") {
      filteredList = filteredList.filter((item) => {
        let email = item?.sender?.email;

                return email && email.toLowerCase().includes(searchEmail.toLowerCase());
            });
        }

        console.log("filteredList:", filteredList);
        return filteredList;
    };

    const toggleMessage = () => {
        setMessageBtn(!messageBtn);
    };

    const onHandleShowMessage = (message: any) => {
        if (show) {
            if (message.id == replyBox.id) {
                setReplyBox(null);
                setShow(false);
            } else {
                setReplyBox(message);
                setShow(true);
            }
        } else {
            setReplyBox(message);
            setShow(true);
        }

        // check if user is recepient
        const msgRecipient = message.messageRecipients.find(
            (res: any) => res.recipient?.key === userDetails.key
        );
        if (msgRecipient) {
            if (!msgRecipient.isRead) {
                handleUpdateMessageStatus(message);
            }
        }
    };
    const handleUpdateMessageStatus = async (message: any) => {
        try {
            const response = await Api.put(
                `${ApiEndpoints.UserMessage.UpdateStatus}/${message.id}`
            );

            if (response.status === 200) {
                updateRecipientStatusToRead(message.id);
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            //   openNotificationWithIcon(
            //     "Error!",
            //     "Error occurred while sending. Please try again or contact the administrator.",
            //     eqNotificationTypes.error
            //   );
        }
    };

    const updateRecipientStatusToRead = (messageId: number) => {
        const updatedMessages = [...userMessages];

        // Find the index of the message with the specified ID
        const messageIndex = updatedMessages.findIndex(
            (msg) => msg.id === messageId
        );

        if (messageIndex !== -1) {
            const message = { ...updatedMessages[messageIndex] };

            // Find the index of the recipient within the message's recipients array
            const recipientIndex = message.messageRecipients.findIndex(
                (r: any) => r.recipient.key === userDetails.key
            );

            // If the recipient is found, update its status to "read"
            if (recipientIndex !== -1) {
                const updatedRecipient = {
                    ...message.messageRecipients[recipientIndex],
                };
                updatedRecipient.isRead = true;

                // Update the recipient within the message's recipients array
                message.messageRecipients[recipientIndex] = updatedRecipient;

                // Update the message within the messages list
                updatedMessages[messageIndex] = message;

                // Update the state with the modified array
                setUserMessages(updatedMessages);
            } else {
                console.error("Recipient not found in the specified message");
            }
        } else {
            console.error("Message not found with ID:", messageId);
        }
    };

    const handleDeleteMessage = async (message: any) => {
        try {
            const response = await Api.delete(
                `${ApiEndpoints.UserMessage.Delete}/${message.id}`
            );

            if (response.status === 200) {
                // Remove deleted inviteIds from the local state
                setUserMessages((prev: any) =>
                    prev.filter((msg: any) => msg.id !== message.id)
                );
                if (message.id == replyBox.id) {
                    setReplyBox(null);
                    setShow(false);
                }
                openNotificationWithIcon(
                    t("deleted"),
                    t("sucessFullyRemovedInvites"),
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error deleting invites:", error);

            openNotificationWithIcon(
                t("error"),
                t("unableToDelete"),
                eqNotificationTypes.error
            );
        }
    };

    const filterEmail = (emailList: any[]) => {
        let filteredList = emailList;

        if (searchEmail && searchEmail.length > 0) {
            const searchName = searchEmail.toLowerCase();
            filteredList = filteredList.filter((item) => {
                const username = item?.props.message?.from;

                return username && username.toLowerCase().includes(searchName);
            });
        }

        console.log("filteredList:", filteredList);
        return filteredList;
    };

    const searchEmailClick = (searchStr: string) => {
        setSearchEmail(searchStr);
    };

    useEffect(() => {
        // GetMessages();
        GetUserMessageRecipientsAsOptions();
        GetUserMessages();
    }, []);

    const handleCreateMessage = async (data: any) => {
        try {
            const response = await Api.post(ApiEndpoints.UserMessage.Create, data);

            if (response.status === 200) {
                addItems(response.data);
                openNotificationWithIcon(
                    t("sent"),
                    t("successfullySentMessage"),
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            openNotificationWithIcon(
                t("error"),
                t("errorOccurredSending"),
                eqNotificationTypes.error
            );
        }
    };

    const addItems = (messages: any) => {
        const updatedMessages = [...userMessages];
        messages.forEach((msg: any) => {
            updatedMessages.push(msg);
        });
        setUserMessages(updatedMessages);
    };

    const GetUserMessages = async () => {
        setLoading(true);

        try {
            const response = await Api.get(ApiEndpoints.UserMessage.GetAll);
            let sorted = sortMessages(response.data);
            setUserMessages(sorted);
            setError(null);
        } catch (error) {
            setError(t("errorFetchData"));
            console.error("Error fetching data:", error);
        }
        setLoading(false);
    };

    // const sortMessages = (messages: any[]): any[] => {
    //     // Sort the filtered messages based on some criteria (e.g., createdOn timestamp)
    //     messages.sort((a: any, b: any): number => {
    //         const recipientA = a.messageRecipients.find(
    //             (recipient: any) => recipient.recipient.email === currentUserEmail
    //         );
    //         const recipientB = b.messageRecipients.find(
    //             (recipient: any) => recipient.recipient.email === currentUserEmail
    //         );

    //         const isReadA: boolean = recipientA && recipientA.isRead;
    //         const isReadB: boolean = recipientB && recipientB.isRead;

    //         // Check if both recipients exist
    //         if (recipientA && recipientB) {
    //             if (isReadA !== isReadB) {
    //                 return isReadA ? 1 : -1; // Prioritize unread messages
    //             }
    //         }

    //         // If only one recipient exists, prioritize unread messages
    //         if (recipientA || recipientB) {
    //             if(recipientA)
    //             return recipientA && isReadA ? 1 : b.createdOn - a.createdOn;
    //             else
    //             return recipientB && isReadB ? -1 : b.createdOn - a.createdOn;

    //         }

    //         // If neither recipient exists or both messages are unread/read, sort by createdOn timestamp
    //         return b.createdOn - a.createdOn;
    //     });

    //     console.log(messages);

    //     return messages;
    // };

    const sortMessages = (messages: any[]): any[] => {
        // Sort the filtered messages based on some criteria (e.g., createdOn timestamp)
        messages.sort((a: any, b: any): number => {
            const recipientA = a.messageRecipients.find(
                (recipient: any) => recipient.recipient.email === currentUserEmail
            );
            const recipientB = b.messageRecipients.find(
                (recipient: any) => recipient.recipient.email === currentUserEmail
            );

            const isReadA: boolean = recipientA && recipientA.isRead;
            const isReadB: boolean = recipientB && recipientB.isRead;

            // Prioritize unread messages
            if (!isReadA && isReadB) {
                return -1; // 'a' comes before 'b' (sorted ascending)
            } else if (isReadA && !isReadB) {
                return 1; // 'a' comes after 'b' (sorted ascending)
            }

            // If one message has a recipient and it's unread, prioritize it
            if (recipientA && !isReadA && (!recipientB || (recipientB && isReadB))) {
                return -1; // 'a' comes before 'b' (sorted ascending)
            } else if (
                (!recipientA || (recipientA && isReadA)) &&
                recipientB &&
                !isReadB
            ) {
                return 1; // 'a' comes after 'b' (sorted ascending)
            }

            // If both messages are unread or both are read, maintain their relative order
            // return 0;
            return b.createdOn - a.createdOn;
        });

        console.log(messages);

        return messages;
    };

    const GetUserMessageRecipientsAsOptions = async () => {
        try {
            const response = await Api.get(ApiEndpoints.UserMessageRecipients);
            if (response && response.status === 200) {
                setUserAndGroupOptions(response.data);
            } else {
                setErrorMessage(GetEventMessage(response.status));
            }
        } catch (error: any) {
            setError(t("errorFetchData"));
            setErrorMessage(GetEventMessage(error.response.status));
        }
    };

    const handleCreateMessageReply = async (message: any, reply: any) => {
        try {
            const messageReplyDto = {
                messageId: message.id,
                reply: reply,
            };

            const response = await Api.post(
                ApiEndpoints.UserMessageReply.Create,
                messageReplyDto
            );

            if (response.status === 200) {
                addReplyToMessage(message, response.data);
                openNotificationWithIcon(
                    t("sent"),
                    t("successfullySentMessage"),
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            openNotificationWithIcon(
                t("error"),
                t("errorOccurredSending"),
                eqNotificationTypes.error
            );
        }
    };
    const addReplyToMessage = (message: any, reply: any) => {
        const updatedMessages = [...userMessages];

        // Find the index of the message in the updatedMessages array
        const messageIndex = updatedMessages.findIndex(
            (msg) => msg.id === message.id
        );

        if (messageIndex !== -1) {
            // Create a copy of the message to avoid mutating the state directly
            const updatedMessage = { ...updatedMessages[messageIndex] };

            // Add the new reply to the reply array
            updatedMessage.messageReplies = [...updatedMessage.messageReplies, reply];

            // Update the message at the specific index in the array
            updatedMessages[messageIndex] = updatedMessage;

            // Update the state with the modified array
            setUserMessages(updatedMessages);

            // add in opened reply
            setReplyBox(updatedMessage);
        } else {
            console.error("Message not found in userMessages array");
        }
    };

    return (
        <div className="eq-message-board">
            <Row className="eq-messageBoard-row" justify="space-between">
                <Col className="eq-messageBoard-col">
                    <div className="eq-messageBoard-heading">
                        {t("messages")}
                    </div>
                </Col>
                <Col>
                    <EqButton
                        type="primary"
                        className="compose-btn"
                        onClick={toggleMessage}
                    >
                        {t("composeMessage")}
                    </EqButton>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="eq-message-board-container">
                <Col className="messages-list" sm={24} md={8}>
                    <div className="links">
                        <div className="top-actions">
                            <Search
                                placeholder={t("searchByEmail")}
                                onChange={(e: any) => searchEmailClick(e.target.value)}
                            />
                        </div>

                        <div className="messages">
                            {loading ? (
                                <Skeleton active avatar />
                            ) : userMessages?.length > 0 ? (
                                <>
                                    {filterUserMessageList(userMessages)?.map(
                                        (item: any, index: number) => (
                                            <EqMessageListItem
                                                key={index}
                                                message={item}
                                                deleteMessage={handleDeleteMessage}
                                                showMessage={onHandleShowMessage}
                                            />
                                        )
                                    )}
                                </>
                            ) : (
                                <p className="no-message">
                                    {t("nothingToShow")}
                                </p>
                            )}
                        </div>
                    </div>
                </Col>
                {messageBtn === false && show === false ? (
                    <div className="composer-container-empty">
                        <FontAwesomeIcon
                            className="icon-message"
                            icon={faEnvelopeOpenText}
                        />
                        <p className="container-empty-text">
                            {t("nothingToShow")} <br />
                            {/* {t("noMsgSelected")} */}
                        </p>
                        <EqButton
                            type="primary"
                            className="compose-btn"
                            onClick={toggleMessage}
                        >
                            {t("composeMessage")}
                        </EqButton>
                    </div>
                ) : (
                    <>
                        <Col className="" sm={24} md={16}>
                            <div>
                                {messageBtn && (
                                    <>
                                        <EqSendMessageForm
                                            studentsAndGroups={userAndGroupOptions}
                                            onCreate={handleCreateMessage}
                                            onCancel={() => setMessageBtn(false)}
                                        />
                                    </>
                                )}
                                {show && (
                                    <div className="eq-message-reply-container">
                                        <EqMessageReply
                                            message={replyBox}
                                            onCreateReply={handleCreateMessageReply}
                                        />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </>
                )}
            </Row>
        </div>
    );
};

export default MessageBoard;
