import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Tag, Space, Row, Col } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import "./EqCreateSubscriptionProduct.scss";
import { EqInput, EqInputNumber } from "../../custom/eqInput/EqInput";
import EqButton from "../../custom/eqButton/EqButton";
import { QuestionType } from "../../shared/enums";
import { openNotificationWithIcon } from "../../shared/Common";
import Api from "../../shared/Api";
import EqSelect from "../../custom/eqSelect/EqSelect";
import { ISelectProps } from "../../shared/Interfaces";
import { useTranslation } from "react-i18next";

export interface IEqCreateSubscriptionProduct {
  visible?: any;
  onCreate?: any;
  onCancel?: any;
  subscriptionTypeOptions?: any;
}
const EqCreateSubscriptionProduct = ({
  visible,
  onCreate,
  onCancel,
  subscriptionTypeOptions,
}: IEqCreateSubscriptionProduct) => {
  const [formData, setFormData] = useState({
    name: "",
    price: 0,
    description: "",
  }) as any;

  const handleNameTextChange = (e: any) => {
    setFormData({ ...formData, name: e.target.value });
  };

  const handlePriceChange = (e: any) => {
    setFormData({ ...formData, price: e });
  };
  const handleDescriptionChange = (e: any) => {
    setFormData({ ...formData, description: e.target.value });
  };

  const { t } = useTranslation();


  const SubscriptionTypeSelectProps: ISelectProps = {
    label: t("subscriptionType"),
    options: subscriptionTypeOptions,
    defaultSelectedValue: formData.type,
    classNames: "extra-height",
    placeholder: t("selectType"),
    extraHeight: true,
    changeFunc: (value: string) => {
      
      let type: number = +value;
      setFormData({ ...formData, type: type });
    },
  };
  useEffect(() => {
console.log(subscriptionTypeOptions)
    

  }, [subscriptionTypeOptions,visible]);


  const handleCreate = () => {
    let validate = validateData(formData);
    if (validate) {
      onCreate(formData);
   
    }
  };

  const validateData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    //same for public and private
    if (data.name === "") {
      openNotificationWithIcon(msg, "Name" + desc);
      isComplete = false;
    } else if (data.price === 0 || data.price === null) {
      openNotificationWithIcon(msg, "Price" + desc);
      isComplete = false;
    } else if (data.description == "") {
      openNotificationWithIcon(msg, "Description" + desc);
      isComplete = false;
    }
    return isComplete;
  };
  return (
    <div className="eq-subscription-modal">
      <Modal
        open={visible}
        title={"Add Subscription Product"}
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={handleCreate}
        footer={[
          <Row justify="center">
            <Space>
              <Col>
                <EqButton
                  block
                  type="button"
                  classNames="btn-background-orange"
                  onClick={handleCreate}
                >
                  Create
                </EqButton>
              </Col>
              <Col>
                <EqButton
                  type="button"
                  bordered="true"
                  classNames="btn-light-gray"
                  onClick={onCancel}
                >
                  Cancel
                </EqButton>
              </Col>{" "}
            </Space>
          </Row>,
        ]}
      >
        <EqInput
          label="Name"
          value={formData.name}
          onChange={handleNameTextChange}
        />
        <EqInputNumber
          label="Price"
          value={formData.price}
          onChange={handlePriceChange}
        />
        <EqInput
          label="Description"
          value={formData.description}
          onChange={handleDescriptionChange}
        />

      <EqSelect {...SubscriptionTypeSelectProps} />
      </Modal>


    </div>
  );
};
export default EqCreateSubscriptionProduct;
