import React, { useState, useEffect, ChangeEvent } from "react";
import { Modal, Input, Row, Col } from "antd";
import { EqInput } from "../../../custom/eqInput/EqInput";
import EqButton from "../../../custom/eqButton/EqButton";
import { EqMessageType, openNotificationWithIcon } from "../../../shared/Common";
import "./EqSendMessageForm.scss";

interface EqSendMessageModalProps {
  visible: boolean;
  recipientEmail: string;
  onCreate: (values: any) => void;
  onCancel: () => void;
}

const EqSendMessageModal: React.FC<EqSendMessageModalProps> = ({
  visible,
  recipientEmail,
  onCreate,
  onCancel,
}) => {
  const initialMessage = {
    subject: "",
    message: "",
    messageType: EqMessageType.Message,

  };

  const [formData, setFormData] = useState<any>({ ...initialMessage });

  useEffect(() => {
    if (visible) {
      setFormData({ ...initialMessage });
    }
  }, [visible]);

  const handleInputChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    let validate = validateData(formData);
    if (validate) {
      let data = { ...formData, recipientEmail };
      
      onCreate(data);
    }
  };

  const validateData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    if (data.subject === "") {
      openNotificationWithIcon(msg, "subject" + desc);
      isComplete = false;
    } else if (data.message === "") {
      openNotificationWithIcon(msg, "message" + desc);
      isComplete = false;
    }
    return isComplete;
  };

  return (
    <Modal
      title="Send Message"
      visible={visible}
      footer={null}
      onCancel={onCancel}
      width={"85vw"}
    >
      <div className="eq-sendMessage">
        <Row gutter={16} justify="space-between" align="middle">
          <Col>
            <h2 className="msg-title">New Private Message</h2>
          </Col>
        </Row>
        <Row>
          <EqInput
            classNames="eq-bottom-border"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleInputChange}
          />
        </Row>
        <Input.TextArea
          className="eq-text-area-border"
          name="message"
          placeholder="Write Message Here ..."
          value={formData.message}
          onChange={handleInputChange}
          rows={4}
        />
        <Row justify="space-between" align="middle" className="action-row">
          <Col>
            <EqButton type="primary" onClick={handleSubmit}>
              Send Message
            </EqButton>
          </Col>
          <Col>
            <span onClick={onCancel}>
              <img alt="" src="/trash-alt.svg" />
            </span>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default EqSendMessageModal;
