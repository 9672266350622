import * as React from 'react';
import './EqComposeMessage.scss';
import { Row, Col, Select } from 'antd';
import EqButton, { IEqButton } from '../../custom/eqButton/EqButton';
import EqPopup from '../../custom/eqPopup/EqPopup';
import { useState, useEffect } from 'react'
import { Input } from 'antd';
import { EqInput } from '../../custom/eqInput/EqInput';
import { useTranslation, Trans } from 'react-i18next';
import {
    Form,
    Typography,
} from 'antd';
import EqSelect from '../../custom/eqSelect/EqSelect';
import { ISelectProps } from '../../shared/Interfaces';
import Api from '../../shared/Api';
import { ApiEndpoints, EqStatusCode, GetSentInviteMessage } from '../../shared/Common';

const EqComposeMessage = ({ header, to, message, cancelFunc, sendFunc, selectedSender, ...rest }: any) => {
    const cancelButton: IEqButton = { classNames: "btn-border-orange btn-background-white btn-float-right", centered: false, bordered: true, type: 'button' };
    const submitButton: IEqButton = { classNames: "btn-border-orange btn-background-orange btn-float-right", centered: false, bordered: true, type: 'submit' };

    const { t, i18n } = useTranslation();
    const { TextArea } = Input;
    const [emails, setEmails] = useState([] as Array<any>);

    
    const [recipientsPrivate, setRecipientsPrivate] = useState([] as any[]);
    const [recipientsGroup, setRecipientsGroup] = useState([] as any[]);


    const [text, setText] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [errorString, setErrorString] = useState('');
    const { Text } = Typography;
    const [formData, setFormData] = useState({ MessageRecipients: [] as Array<any>, Message: "" }) as any;
    const [messageString, setMessageString] = useState('');

    const [selectedOption, setSelectedOption] = useState("group"); // Default to "private"

    var filteredRecipients: any;
    var desc = "Your event for the day new [DATE] has been created.";

    const togglePopup = () => {
        //setShowPopup(!showPopup);
    };
    const showPopupHandler = (props: any) => {
        setShowPopup(props.value);
    }
    const submit = (e: any) => {
        e.preventDefault()
    }
    const clickedOkay = () => {
        togglePopup();
    }
    const onFinishFailed = (errorInfo: any) => { };
    const GetAllRecipients = () => {
        setMessageString('');
        var recipients = [] as any;
        Api.get(ApiEndpoints.getAllRecipients).then(response => {
            if (response && response.status === 200) {
                const privateRecipients: any[] = [];
                const groupRecipients: any[] = [];

                response.data.forEach((d: any) => {
                    const recipient = {
                        key: d.key,
                        value: d.user == null ? d.group.name : d.user.userName,
                        type: d.userType,
                        reciever: d
                    };

                    if (d.userType === 1) {
                        privateRecipients.push(recipient);
                    } else if (d.userType === 2) {
                        groupRecipients.push(recipient);
                    }
                });

                // Set private and group recipients separately
                setRecipientsPrivate(privateRecipients);
                setRecipientsGroup(groupRecipients);

                // Set all recipients
                setSelectedSender(privateRecipients.concat(groupRecipients));

                // Set options based on the selected option
                setEmails(selectedOption === 'private' ? privateRecipients : groupRecipients);

                console.log("Private Recipients:", privateRecipients);
                console.log("Group Recipients:", groupRecipients);
            } else {
                setMessageString("Not found");
            }
        }, err => {
            setMessageString("Not found");
        });
    }
    const setSelectedSender = (recipients: any) => {
        
        filteredRecipients = recipients.filter((itemA: any) => {
            return itemA.key == selectedSender;
        });
        filteredRecipients = filteredRecipients.map((a: any) => a.reciever);
        setFormData({ ...formData, MessageRecipients: filteredRecipients });
    }
    const onSearchChange = (value: any) => {
        const selectedMembers = value;
        filteredRecipients = emails.filter((itemA: any) => {
            return selectedMembers.includes(itemA.key)
        });
        filteredRecipients = filteredRecipients.map((a: any) => a.reciever);
        setFormData({ ...formData, MessageRecipients: filteredRecipients });
    }
    const sendMessage = () => {
        if (formData.MessageRecipients && formData.Message) {
            setErrorString("");
            var res = sendFunc(formData, setFormData, setErrorString);
        }
        else {
            setErrorString(GetSentInviteMessage(EqStatusCode.inCompleteCredentials406));
            document.getElementById('returnMessage')?.classList.remove('hide', 'valid');
            document.getElementById('returnMessage')?.classList.add('show', 'invalid')
        }
    }
    const searchProps: ISelectProps = {
        mode: "multiple", showSearch: true, optionFilterProp: "children", filterOption: true, isTag: false,
        showArrow: false, allowClear: true, changeFunc: onSearchChange, classNames: "eq-email-search extra-height", ddClassName: "eq-select-dropdown",
        icon: [<span className="eq-search-icon"><img alt="" src="/search-icon.svg" /></span>],
        selectedIcon: [<span className=""><img alt="" src="/check-square.svg" /></span>],
        options: emails, defaultSelectedValue: selectedSender ? selectedSender : undefined
    }
    const popupBtns: Array<IEqButton> = [
        {
            classNames: "eqButton eq-popup-btn", value: 'OK', onClick: clickedOkay
        }
    ];
    useEffect(() => {
        GetAllRecipients();
    }, [selectedOption]);
    return (
        <>
            <Form
                layout="vertical"
                size="small"
                onFinish={sendMessage}
                onFinishFailed={onFinishFailed}
            >
                <Row className="eq-is-container">
                    <Col flex="auto">
                        <Row justify='space-between'>
                            <Col>
                                <h5 className="eq-is-heading">{header}</h5>
                            </Col>
                            <Col>
                            <Select value={selectedOption} onChange={(value) => setSelectedOption(value)}>
                                    <Select.Option value="private">Private</Select.Option>
                                    <Select.Option value="group">Group</Select.Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row className="eq-message-to">
                            <Col span={2}>
                                <h3 className="eq-input-heading">{to}</h3></Col>
                            <Col flex="auto">
                                <EqSelect {...searchProps} ></EqSelect>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                <h3 className="eq-input-heading">{message}</h3></Col>
                            <Col flex="auto">
                                <EqInput type="text" className="eq-is-email" value={formData.Message} onChange={(e: any) => setFormData({ ...formData, Message: e.target.value })}></EqInput>

                            </Col>
                        </Row>
                        <Row><Col>
                            <Text id="returnMessage" className="hide">
                                {errorString}
                            </Text>
                        </Col>
                        </Row>
                        <Row justify="end" align="bottom" className="eq-is-btn">
                            <Col className="eq-col-btn" >
                                <EqButton type="button" onClick={sendMessage} >Send</EqButton>
                            </Col>
                            <Col className="eq-col-btn" >
                                <EqButton type="button" bordered="true" classNames="btn-light-gray" onClick={togglePopup} >
                                    {t("cancel")}</EqButton>
                            </Col>
                            <EqPopup displayPopup={showPopup} action={showPopupHandler} type="notification" title="Event Created" description={desc} popupButtons={popupBtns}></EqPopup >
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default EqComposeMessage;