import * as React from "react";
import "./EqTrainerInvite.scss";
import { Row, Col, Space, Button } from "antd";
import {
  ShoppingCartOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  getDefaultImage,
  imageType,
  ValidateIsTrainer,
} from "../../../shared/Common";
import Text from "antd/lib/typography/Text";
import { useAuthState } from "../../../context/Context";
import { useTranslation } from "react-i18next";
export interface IEqTrainerInvite {
  trainer?: any;
  acceptClicked: (trainer: any) => void;
  rejectClicked: (trainer: any) => void;
  props?: any;
  viewEventDetail?: any;
  step?: number;
}
const EqTrainerInvite = ({
  trainer,
  acceptClicked,
  rejectClicked,
  props,
  viewEventDetail,
  step,
}: IEqTrainerInvite) => {
  // const userDetails: any = useAuthState();
  // const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const { t } = useTranslation();

  console.log(props);
  // const { trainer, user, events } = props;
  const getAllTrainerEvents = (trainerKey: any) => {
    console.log(trainerKey);
    if (trainerKey > 0) {
    }
  };
  const rowClicked = (item: any) => {
    console.log(step);
    if (step == 1) {
      if (viewEventDetail != null) {
        console.log("view pending invites");
        viewEventDetail(props);
      }
    }
  };

  const accept = (event: any) => {
    
    acceptClicked(event);
  };
  const reject = (event: any) => {
    rejectClicked(event);
  };
  React.useEffect(() => {
    
    // console.log(step);
  }, [trainer]);
  return (
    <>
      <Row
        className="eq-invite-row"
        justify="space-around"
        // onClick={rowClicked}
      >
        <Col
          xs={{ span: 4, offset: 0 }}
          sm={{ span: 4, offset: 0 }}
          md={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          xl={{ span: 2, offset: 0 }}
          xxl={{ span: 2, offset: 0 }}
        >
          <img
            src={trainer?.userImage?.url || getDefaultImage(imageType.partner)}
            className="user-img"
            alt="user"
          />
        </Col>

        <Col xs={17} sm={17} md={20} lg={20}>
          <Row>
            <Col xs={24} sm={24} md={5}>
              <Text className="eq-invite-row-subTitle" type="secondary">
              {t("team")} {trainer?.firstName}
              </Text>
            </Col>

            <Col xs={24} sm={24} md={5}>
              <Text className="eq-invite-row-title">
                {trainer?.firstName + " " + trainer?.lastName}
              </Text>
            </Col>
            <Col className="eq-invite-row-dis" xs={24} sm={24} md={8}>
            {t("from")}: {trainer?.userName}
            </Col>
            <Col className="eq-invite-row-dis" xs={24} sm={24} md={6}>
              {t("invitesYou")}
              {/* Invites you to: {events?.length==1?(events.length+"activity"):(events.length+" activities")} */}
            </Col>
          </Row>
        </Col>
        <Col
               xs={{ span: 3, offset: 0 }}
               sm={{ span: 3, offset: 0 }}
               md={{ span: 2, offset: 0 }}
               lg={{ span: 2, offset: 0 }}
               xl={{ span: 2, offset: 0 }}
               xxl={{ span: 2, offset: 0 }}
        >
        
          <Space>
            <Button
              onClick={() => accept(trainer)}
              className="invite-accept-btn"
              size="small"
              shape="circle"
              icon={<CheckOutlined />}
            />
            <Button
              onClick={() => reject(trainer)}
              className="invite-reject-btn"
              size="small"
              shape="circle"
              icon={<CloseOutlined />}
            />
          </Space>
        </Col>
        {/* <Col
          xs={{ span: 3, offset: 0 }}
          sm={{ span: 3, offset: 0 }}
          md={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          xl={{ span: 2, offset: 0 }}
          xxl={{ span: 2, offset: 0 }}
        >
          {(step === 1 || step === 3) && (
            <>
              <ShoppingCartOutlined style={{ fontSize: "125%" }} />
            </>
          )}
          <div>
            {step === 3 ||
              (step === 5 && (
                <span>
                  <CheckCircleTwoTone twoToneColor="#03dac5" />
                </span>
              ))}
            {step === 5 && (
              <span>
                <CloseCircleTwoTone twoToneColor="red" />
              </span>
            )}
          </div>
        </Col> */}
      </Row>
    </>
  );
};
export default EqTrainerInvite;
