import {
  faCalendarMinus,
  faCalendarXmark,
  faMoneyBill,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "./StudentOverViewProfile.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Calendar, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  ApiEndpoints,
  FrontEndPoints,
  GetEventMessage,
} from "../../shared/Common";
import Api from "../../shared/AApi";
import moment from "moment";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { useTranslation, Trans } from "react-i18next";

import { StudentProductFilters } from "../../shared/enums";
export interface IEqStudentOverView {
  stat: any;
  joinedTraining: any;
}
const StudentOverViewProfile = ({
  stat,
  joinedTraining,
}: IEqStudentOverView) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const navigateToStudentProducts = () => {
    const profileBoardPath = FrontEndPoints.student.productBoard;
    // Set up query parameters
    const queryParams = `filter=${StudentProductFilters.UnPaid}`;
    const fullPath = `${profileBoardPath}?${queryParams}`;
    navigate(fullPath);
  };

  const dateCellRender = (value: any) => {
    const date = moment(value).format("YYYY-MM-DD");
    const trainings = joinedTraining?.filter(
      (training: any) =>
        moment(training.event.startDate).format("YYYY-MM-DD") === date
    );

    return (
      <div>
        {trainings &&
          trainings.map((event: any, index: number) => (
            <Badge key={index} status="warning" />
          ))}
      </div>
    );
  };

  useEffect(() => {}, []);
  return (
    <div>
      <Row gutter={[16, 16]} className="eq-st-view">
        <Col sm={24} md={12} lg={16}>
          <Row gutter={[20, 20]} className="eq-overview">
            <Col span={24} className="eq-col-view">
              <div className="eq-training-overview">
                <div className="overview-info">
                  <div>
                    <h4 className="heading">{t("totalProfessionals")}</h4>
                    <p className="text">{t("allYourSubscribedUsers")}</p>
                  </div>
                  <FontAwesomeIcon className="total-prof-icon" icon={faUser} />
                </div>
                <div className="dec-overview">
                  <div className="overview-status status-earning">
                    <div className="count-overview">
                      {stat.totalProfessionals}
                    </div>

                    <span className="status-std ">{t("connections")}</span>
                  </div>
                  <div className="overview-msg">
                    <Link to={FrontEndPoints.student.connection}>
                      <span className="msg">{t("viewAll")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={24} lg={12} className="eq-col-view">
              <div className="eq-training-overview">
                <div className="overview-info">
                  <div>
                    <h4 className="heading">{t("unpaidTrainings")}</h4>
                    <p className="text">{t("totalUnpaidTrainings")}</p>
                  </div>
                  <FontAwesomeIcon
                    className="basic-icon"
                    icon={faCalendarXmark}
                  />
                </div>
                <div className="dec-overview">
                  <div className="overview-status status-unpaid">
                    <div className="count-overview">{stat.unpaidTraining}</div>
                    <span className="status-std">{t("unpaidTrainings")}</span>
                  </div>
                  <div className="overview-msg">
                    <Link to={FrontEndPoints.student.allTrainings}>
                      <span className="msg">{t("viewAll")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={24} lg={12} className="eq-col-view">
              <div className="eq-training-overview">
                <div className="overview-info">
                  <div>
                    <h4 className="heading">{t("unpaidProducts")}</h4>
                    <p className="text">{t("totalUnpaidProducts")}</p>
                  </div>
                  <FontAwesomeIcon
                    className="basic-icon"
                    icon={faCalendarXmark}
                  />
                </div>
                <div className="dec-overview">
                  <div className="overview-status status-unpaid">
                    <div className="count-overview">{stat.unpaidProduct}</div>
                    <span className="status-std">{t("unpaidProducts")}</span>
                  </div>
                  <div className="overview-msg">
                    {/* <Link to={FrontEndPoints.student.productBoard}>
                                            <span className="msg">{t("viewAll")}</span>
                                        </Link> */}
                    <span
                      className="msg"
                      onClick={() => {
                        navigateToStudentProducts()
                      }}
                    >
                      {t("viewAll")}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <Row gutter={[16, 16]} className="eq-overview">
                    <Col span={24}>
                    <Link to={FrontEndPoints.student.allTrainings}>
              <div className="eq-training-overview training-overview">
                <h4 className="count-heading">{t("upcomingTrainings")}</h4>
                <div className="training-count">{stat.upcomingTraining}</div>
              </div>
                    </Link>
            </Col>
            <Col>
              <div className="training-overview-cal">
                <Calendar fullscreen={false} dateCellRender={dateCellRender} />
                <div className="calendar-event-list">
                  <>
                    {joinedTraining &&
                      joinedTraining
                        .slice(0, 1)
                        .map((training: any, index: number) => (
                          <div key={index} className="event-list">
                            <div className="date-event">
                              {moment(training.event.startDate).format(
                                "YYYY-MM-DD"
                              )}
                            </div>
                            <div className="name-event">
                              {training.event.title}
                            </div>
                          </div>
                        ))}
                  </>

                  <div className="overview-msg">
                    <Link to={FrontEndPoints.student.allTrainings}>
                      <span className="msg">{t("viewAll")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default StudentOverViewProfile;
