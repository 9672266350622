import * as React from 'react';
import './EqBookSession.scss';
import { ApiEndpoints, dateFormat, GetBookSessionMessage, getEmailFromToken, openNotificationWithIcon } from '../../shared/Common';
import {
    EqDisciplineType, EqEventAccessType,
    EqEventHorseAvailabilityStatus, EqEventUserType, eqNotificationTypes, EqPaymentSourceType,
    EqSlotStatusEnum,
    EqTrainingLevels
} from '../../shared/enums';
import { Row, Col, Input, Modal, notification } from 'antd';
import Api from '../../shared/Api';
import moment from 'moment';
import EqDatePicker, { IDatePickerOptions } from '../../custom/eqDatePicker/EqDatePicker';
import EqSelect from '../../custom/eqSelect/EqSelect';
import EqToggle, { IEqToggle } from '../../custom/eqToggle/EqToggle';
import { ISubscribeEventDto } from '../../shared/interfaces/ISubscribeEventDto';
import EqButton from '../../custom/eqButton/EqButton';
import { IEqEventData } from '../../shared/interfaces/IEqEventDto';
import { useTranslation, Trans } from 'react-i18next';
import { IGetTrainingSlotDto } from '../../shared/interfaces/IGetTrainingSlotDto';
import { loadState } from '../../shared/LocalStorage';


export interface IEqBookSessionProps {
    title: string;
    email: string;
    visible: boolean;
    trainerKey: number;
}


const EqBookSession = (props: any) => {
    const { t, i18n } = useTranslation();

    const [bookSessionProps, setBookSessionProps] = React.useState(props as IEqBookSessionProps);

    const [isError, setIsError] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const { TextArea } = Input;
    const gutter = { xs: 24, sm: 24, md: 24, lg: 24 };


    const [isModalVisible, setIsModalVisible] = React.useState(bookSessionProps.visible);

    const [openEvents, setOpenEvents] = React.useState([] as Array<IEqEventData>);
    const [slotRange, setSlotRange] = React.useState({ allSlotRanges: [], selectedSlotRange: [], currentUserSlots: [], currentSelection: {}, isSlotSelected: false } as any);
    const [userMessage, setUserMessage] = React.useState('');
    const [selectedDate, setSelectedDate] = React.useState(new Date()) as any;
    const [selectedEvent, setSelectedEvent] = React.useState({} as IEqEventData);

    const [bookingButtonStatus, setBookingButtonStatus] = React.useState({ buttonDisabled: false, buttonText: 'Send Request' } as any);

    const currentUserKey = getEmailFromToken();

    const openCheckOutNotificationBox = (
        msg: any,
        desc: any,
        eventKey: any
      ) => {
        const btn = (
            <div className="right">
            <EqButton onClick={()=>CreateCheckoutSession(eventKey)}>Pay Now</EqButton>
        </div>
        );
        notification["info"]({
          message: msg,
          btn,
          description: desc,
        });
      };

      const CreateCheckoutSession = (eventKey:any) => {
        
        Api.post(ApiEndpoints.StudentTrainingStripeCheckout.Create, {
          key: eventKey,
        })
          .then((response) => {
            console.log(response);
            window.location.href = response.data.sessionUrl;
          })
          .catch((error) => {
            openNotificationWithIcon(
              "Error!",
              "Unable to Create checkout",
              eqNotificationTypes.error
            );
          });
      };

    const setDataObject = (): ISubscribeEventDto => {
        let newDto: ISubscribeEventDto = {
            ownerKey: bookSessionProps.email,
            subscriberKey: currentUserKey,
            eventKey: slotRange?.currentSelection?.key,
            slotStartTime: slotRange?.currentSelection?.key,
            slotStatus: EqSlotStatusEnum.Booked,
            eventUserType: EqEventUserType.Attendee,
            message: userMessage
        }

        return newDto;
    }

    const resetModel = () => {
        setUserMessage('');
    }

    const updateBookingButtonState = (isSlotSelected: boolean, currentUserSlots: any, allSlots: any) => {
        if (allSlots?.length == 0) {
            setBookingButtonStatus({ buttonDisabled: true, buttonText: 'Join' });
        }
        else if (currentUserKey?.length > 0) {
            if (selectedEvent?.disciplineType == EqDisciplineType.Open) {
                if (isSlotSelected == false) {
                    setBookingButtonStatus({ buttonDisabled: true, buttonText: 'Join' });
                }
                else {
                    setBookingButtonStatus({ buttonDisabled: false, buttonText: 'Join' });
                }
            }
            else {
                setBookingButtonStatus({ buttonDisabled: true, buttonText: 'Join' });
            }
        }
        else {
            setBookingButtonStatus({ buttonDisabled: true, buttonText: 'Join' });
        }
    }

    const updateUiData = (selectedSlot: any, subscriptionData: any) => {
        let currentUserList = [...slotRange.currentUserSlots, selectedSlot];
        let selectedList = [...slotRange.selectedSlotRange, selectedSlot];
        let remaingAllList = slotRange?.allSlotRanges?.filter((x: any) => x?.key != selectedSlot?.key);

        setSlotRange({ allSlotRanges: remaingAllList, selectedSlotRange: selectedList, currentUserSlots: currentUserList, currentSelection: null, isSlotSelected: false });
        updateBookingButtonState(true, currentUserList, slotRange.allSlotRanges);

        //update event
        let eventToBeUpdated = openEvents.filter(item => {
            let eventDate = moment(item.startDate).local();
            let selectedDate = moment(selectedSlot?.key).local();

            eventDate = eventDate.startOf('day');
            selectedDate = selectedDate.startOf('day');

            return selectedDate.valueOf() == eventDate.valueOf();
        });

        let remainingEvents = openEvents.filter(item => {
            let eventDate = moment(item.startDate).local();
            let selectedDate = moment(selectedSlot.key).local();

            eventDate = eventDate.startOf('day');
            selectedDate = selectedDate.startOf('day');

            return selectedDate.valueOf() != eventDate.valueOf();
        });

        if (eventToBeUpdated && eventToBeUpdated.length > 0) {
            if (eventToBeUpdated[0].attendees == undefined) {
                eventToBeUpdated[0].attendees = [] as any;
                eventToBeUpdated[0].attendees?.push(subscriptionData);
            }
            else {
                eventToBeUpdated[0].attendees.push(subscriptionData);
            }

            remainingEvents = [...remainingEvents, eventToBeUpdated[0]];
            setOpenEvents(remainingEvents);
        }

        resetModel();
    }

    const bookEvent = () => {

        let selected = slotRange.currentSelection;
        let data = setDataObject();

        // Api.post(ApiEndpoints.eventSubscribe, data).then(response => {
        Api.post(ApiEndpoints.StudentSessionBookingRequest, data).then(response => {
            if (response && response.status == 200) {
                setIsError(false);
                setMessage("");

                if (response.data) {
                    openCheckOutNotificationBox("Pay for the Booked Slot","",data.eventKey)
                    updateUiData(selected, response.data);
                }
                else {
                    setMessage("User already has booked this time slot.");
                }
            }
            else {
                setIsError(true);
                setMessage(GetBookSessionMessage(response.status));
            }
        },
            error => {
                setIsError(true);
                setMessage(GetBookSessionMessage(error.response.status));
            });
    }



    const handleCancel = () => {
        setIsModalVisible(false);
    }

 
    React.useEffect(() => {

        if (props != undefined) {
            setBookSessionProps(props);
            setIsModalVisible(props.visible);
            // if (props?.email != '') {
            //     getEvents(props.email);
            // }
            if (props?.trainerKey != undefined) {
                getEvents(props.trainerKey);
            }
        }

    }, [props]);

    // const getEvents = (userKey: string) => {
    //     Api.post(ApiEndpoints.eventGetTrainerOpenEvents, { userEmail: userKey }).then(response => {
    //         if (response && response.status == 200) {
    //             var data = response.data ? [...response.data] : new Array<IEqEventData>();
    //             setOpenEvents(data);
    //             updateEventSlots(moment().valueOf());
    //         }

    //     },
    //         error => {
    //             setIsError(true);
    //             setMessage("Bad request");
    //         });
    // }
    const getEvents = (trainerKey: string) => {
        
        Api.get(ApiEndpoints.TrainerTraining.GetAllTrainerOpenSlotsByTrainerKey +`?trainerKey=${trainerKey}`).then(response => {
            if (response && response.status == 200) {
                var data = response.data ? [...response.data] : new Array<IGetTrainingSlotDto>();
                setOpenEvents(data);
                updateEventSlots(moment().valueOf());
            }

        },
            error => {
                setIsError(true);
                setMessage("Bad request");
            });
    }


    const updateEventSlots = (startDate: number) => {
        
                let filteredEvents = openEvents.filter(item => {
                    let eventDate = moment(item.startDate).local().startOf('date');
                    let selectedDate = moment(startDate).local().startOf('date');
                    return selectedDate.valueOf() == eventDate.valueOf();
        
                    // let eventDate = moment(item.startDate).local();
                    // let selectedDate = moment(startDate).local();
                    // eventDate = eventDate.startOf('day');
                    // selectedDate = selectedDate.startOf('day');
                    // return eventDate.valueOf()==selectedDate.valueOf();
                });
        
                if (filteredEvents?.length > 0) {
                    setSelectedEvent(filteredEvents[0]);
                    setTimeSlots(filteredEvents);
                }
            }
        

  
            const setTimeSlots = (filteredEvents: any) => {
                var allSlots = [] as any;
                let currentUserRange = [] as any;
                let selectedRange = [] as any;
        
                filteredEvents.forEach((event:any) => {
                    let slotStartTime = moment(event.startTime);
                    let slotEndTime = moment(event.endTime);
                    let slot = {
                        key: event.key,
                        value: slotStartTime.format("HH:mm") + "-" + slotEndTime.format("HH:mm")
                    };

                    if( event?.attendees?.length>0){
                        selectedRange.push(slot);
                        if (event?.attendees[0].user?.email == currentUserKey) {
                            currentUserRange.push(slot);
                        }

                    }
                    else{
                        allSlots.push(slot);
                    }
          
            
                    // if (event?.disciplineType == EqDisciplineType.Open) {
            
                    //     let slotStartTime = moment(event.startTime);
                    //     let slotEndTime = moment(event.startTime).hours(moment(event.startTime).hours() + 1);
            
                    //     let totalHours = Math.floor((event.endTime - event.startTime) / (3600 * 1000));
            
                    //     let currentTags = currentUserRange;
            
                    //     for (var hour = 0; hour < totalHours; hour++) {
            
                    //         let slot = {
                    //             key: slotStartTime.valueOf(),
                    //             value: slotStartTime.format("HH:mm") + "-" + slotEndTime.format("HH:mm")
                    //         };
            
                    //         if (!selectedRange?.some((x: any) => x.key == slot.key)) {
                    //             allSlots.push(slot);
                    //         }
            
                    //         slotStartTime = slotStartTime.hours(slotStartTime.hours() + 1);
                    //         slotEndTime = slotEndTime.hours(slotStartTime.hours() + 1);
                    //     }
                    // }
                        });


        
                setSlotRange({ ...slotRange, allSlotRanges: allSlots, selectedSlotRange: selectedRange, currentUserSlots: currentUserRange });
                updateBookingButtonState(false, currentUserRange, allSlots);
            }



            // const setTimeSlots = (event: any) => {
            //     var allSlots = [] as any;
            //     let currentUserRange = [] as any;
            //     let selectedRange = [] as any;
        
            //     event?.attendees?.map((attendee: any) => {
            //         if (attendee.slotStartTime > 0) {
            //             let slotStartTime = moment(attendee.slotStartTime);
            //             let slotEndTime = moment(attendee.slotStartTime).hours(moment(attendee.slotStartTime).hours() + 1);
        
            //             let slot = {
            //                 key: slotStartTime.valueOf(),
            //                 value: slotStartTime.format("HH:mm") + "-" + slotEndTime.format("HH:mm")
            //             };
        
            //             selectedRange.push(slot);
        
            //             if (attendee.user?.email == currentUserKey) {
            //                 currentUserRange.push(slot);
            //             }
            //         }
            //     });
        
            //     if (event?.disciplineType == EqDisciplineType.Open) {
        
            //         let slotStartTime = moment(event.startTime);
            //         let slotEndTime = moment(event.startTime).hours(moment(event.startTime).hours() + 1);
        
            //         let totalHours = Math.floor((event.endTime - event.startTime) / (3600 * 1000));
        
            //         let currentTags = currentUserRange;
        
            //         for (var hour = 0; hour < totalHours; hour++) {
        
            //             let slot = {
            //                 key: slotStartTime.valueOf(),
            //                 value: slotStartTime.format("HH:mm") + "-" + slotEndTime.format("HH:mm")
            //             };
        
            //             if (!selectedRange?.some((x: any) => x.key == slot.key)) {
            //                 allSlots.push(slot);
            //             }
        
            //             slotStartTime = slotStartTime.hours(slotStartTime.hours() + 1);
            //             slotEndTime = slotEndTime.hours(slotStartTime.hours() + 1);
            //         }
            //     }
        
            //     setSlotRange({ ...slotRange, allSlotRanges: allSlots, selectedSlotRange: selectedRange, currentUserSlots: currentUserRange });
            //     updateBookingButtonState(false, currentUserRange, allSlots);
            // }


    function disabledDate(current: any) {
        // Can not select days except ones have open event
        if (openEvents != undefined) {
            let today = moment().startOf('day');
            let threeMonths = moment().add(3, 'M');
            threeMonths = threeMonths.endOf('day');

            let result = openEvents.some((x: any) => {
                let startDate = moment(x.startDate).local();
                startDate = startDate.startOf('day');
                let startMilliseconds = startDate.valueOf();

                let currentDate = current;
                currentDate = currentDate.startOf('day');
                let currentMilliseconds = currentDate.valueOf();

                return startMilliseconds == currentMilliseconds;
            });

            return !(current > today && current < threeMonths && result);
        }
        return false;
    }

    const startOnProps: IDatePickerOptions = {
        isSuffixIcon: true,
        isBordered: true,
        defaultValue: selectedDate,
        disabledDateFunc: disabledDate,
        placeHolder: t("date"),
        classNames: "eq-event",
        onChangeFunc: (date: any, dateString: string) => {
            let startDate = moment(date).utc().valueOf();
            updateEventSlots(startDate);
            setSelectedDate(date)
        }
    }

    const togglerProps: IEqToggle = {
        items: slotRange.allSlotRanges,
        defaultSelectedValue: "",
        classNames: "eq-toggle-orange-border eq-toggle-create-event",
        changeFunc: (value: any) => {
            
            let selected = slotRange?.allSlotRanges?.filter((x: any) => x.key == value);
            setSlotRange({ ...slotRange, currentSelection: (selected?.length > 0 ? selected[0] : {}), isSlotSelected: true });
            updateBookingButtonState(true, slotRange.currentUserSlots, slotRange.allSlotRanges);
        }
    }

    return (
        <Modal
            title={bookSessionProps.title}
            open={isModalVisible}
            onOk={bookEvent}
            onCancel={handleCancel}
            footer={[ // Define a custom footer with only the OK button
                <EqButton
                    key="ok"
                    type="button"
                    onClick={bookEvent}
                    disabled={bookingButtonStatus.buttonDisabled}
                    {...{ classNames: "btn-full-width", bordered: false, centered: false }}
                >
                    {bookingButtonStatus.buttonText}
                </EqButton>
            ]}
        >
            <div className="eq-book-session">
                {message.length > 0 &&
                    <Row align="middle" className="input-row">
                        <Col flex="auto">
                            <div className="error-message">
                                {message}
                            </div>
                        </Col>
                    </Row>
                }

                <div>
                    <Row gutter={gutter}>
                        <Col className="eq-book-session-header">
                            {/*<p className="eq-model-title">{t("bookASession")}</p>*/}
                        </Col>
                        <Col className="eq-book-session-header-divider">
                            <Row>
                                <Col flex="auto" className="divider">
                                </Col>
                            </Row>
                        </Col>
                        <Col className="eq-book-session-body">
                            <Row className="eq-input-group">
                                <Col span={24}>
                                    <p>Please add your training details</p>
                                </Col>
                                <Col span={24}>
                                    <EqDatePicker {...startOnProps}></EqDatePicker>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <h6 className="eq-section-title">Subscribed</h6>
                                    <Row className="eq-input-group">
                                        {slotRange?.currentUserSlots?.length > 0 ?
                                            <>
                                                {
                                                    slotRange?.currentUserSlots?.map((item: any, index: number) =>
                                                        <Col className="eq-pl-discipline-list" key={item?.key}>
                                                            <span className="eq-pl-discipline-list-active">{item?.value}
                                                            </span>
                                                        </Col>
                                                    )
                                                }
                                            </>
                                            :
                                            <p>
                                                No slot subscribed yet
                                            </p>
                                        }
                                    </Row>

                                    <h6 className="eq-section-title">Select a time slot</h6>
                                    <Row className="eq-input-group">
                                        {slotRange?.allSlotRanges?.length > 0 ?
                                            <Col span={24}>
                                                <EqToggle {...togglerProps} />
                                            </Col>
                                            :
                                            <Col span={24}>
                                                <p>No slot available</p>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col flex="auto" className="divider">
                                </Col>
                            </Row>
                            <Row className="eq-input-group">
                                <Col className="input-label">
                                    Booking type: {EqDisciplineType[selectedEvent?.disciplineType]}
                                </Col>
                            </Row>
                            <Row className="eq-input-group">
                                <Col className="input-label">
                                {t("areaOfService")}: {selectedEvent?.eventLocation != undefined ?
                                        (selectedEvent.eventLocation.locationName + ", " + selectedEvent.eventLocation.zipCode + " " + selectedEvent.eventLocation.city)
                                        :
                                        selectedEvent?.location}
                                </Col>
                            </Row>
                            <Row className="eq-input-group">
                                <Col className="input-label" xs={24} sm={24} md={24}>
                                    Message
                                </Col>
                                <Col flex="auto" xs={24} sm={24} md={24}>
                                    <TextArea rows={4} className="eq-is-textarea" value={userMessage}
                                        onChange={(event: any) => setUserMessage(event?.target?.value)}></TextArea>
                                </Col>
                            </Row>
                            {/* <Row className="eq-input-group">
                                <Col flex="auto">
                                    <div className="actions">
                                        <div className="right">
                                            <EqButton type="button" onClick={bookEvent} disabled={bookingButtonStatus.buttonDisabled}
                                                {...{ classNames: "btn-full-width", bordered: false, centered: false }} >
                                                {bookingButtonStatus.buttonText}
                                            </EqButton>
                                        </div>
                                    </div>
                                </Col>
                            </Row> */}
                        </Col>
                    </Row>
                </div>
            </div>
        </Modal>
    );
}

export default EqBookSession;