import * as React from 'react';
import './EqAdminMessageBoard.scss';
import { IEqMessage, IEqMessageData } from '../../eqTrainer/eqMessage/EqMessage';
import { EqMessageType } from '../../shared/Common';
import { EmailFilter } from '../../shared/enums';
import EqMessageList, { IEqMessageList } from '../../eqTrainer/eqMessageList/EqMessageList';
import { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import EqComposeMessage from '../../eqTrainer/eqComposeMessage/EqComposeMessage';
import Api from '../../shared/Api';
import { EqStatusCode, ApiEndpoints, GetSendMessage } from '../../shared/Common';

const EqAdminMessageBoard = () => {

    const [messageString, setMessageString] = useState('');
    const [misCheckAll, msetIsCheckAll] = useState(false);

    const [messages, setMessages] = React.useState([]) as any;
    const [keys, setKeys] = useState(new Array<any>());
    var selectedMessages = new Array<any>();

    const replyEmail = (message: IEqMessageData) => {
        GetMessages();
    }
    const mardFlagedEmail = (message: IEqMessageData) => {
        if (message != null) {
            Api.post(ApiEndpoints.saveMessage, { Message: { Key: message.key }, IsFlaged: message.isFlaged, IsRead: message.isRead }).then(response => {

                if (response && response.status == 200) {
                    return true;
                } else {
                    return false;
                }
            }, err => {
                return false;
            });
        } else {
            return false;
        }
    }
    const deleteEmail = (message: IEqMessageData) => {

        if (message != null) {
            Api.post(ApiEndpoints.deleteMessage, { Message: { Key: message.key } }).then(response => {

                if (response && response.status == 200) {
                    GetMessages();
                    return true;
                } else {
                    return false;
                }
            }, err => {
                return false;
            });
        } else {
            return false;
        }
    }

    const onCheckChange = (message: IEqMessageData, checked: boolean) => {
        if (checked) {
            selectedMessages.push({ Key: message.key });
        }
        else {
            if (selectedMessages != null && selectedMessages.length > 0) {
                const index = selectedMessages.findIndex(x => x.Key == message.key);
                if (index > -1) {
                    selectedMessages.splice(index, 1);
                }
            }
        }
        setKeys(selectedMessages);
    }
    const messagesCheckAll = () => {
        msetIsCheckAll(!misCheckAll);

        messages.map(
            (m: IEqMessage) => {
                if (m.selected == false) {
                    m.selected = !misCheckAll;
                    selectedMessages.push({ Key: m.message.key });
                }
                else {
                    m.selected = !misCheckAll;
                    if (selectedMessages != null && selectedMessages.length > 0) {
                        const index = selectedMessages.findIndex(x => x.Key == m.message.key);
                        if (index > -1) {
                            selectedMessages.splice(index, 1);
                        }
                    }
                }
                setKeys(selectedMessages);
            }
        );
        const modifiedList = [...messages];
        setMessages(modifiedList);
    }

    const deleteSelectedMessages = () => {
        msetIsCheckAll(false);

        if (keys?.length > 0) {
            Api.post(ApiEndpoints.deleteSelectedMessages, keys).then(response => {

                if (response && response.status == 200) {
                    GetMessages();

                    return true;
                } else {
                    return false;
                }
            }, err => {
                return false;
            });
        } else {
            return false;
        }
    }
    const readSelectedMessages = () => {
        msetIsCheckAll(false);
        selectedMessages = [];

        keys.map((opt: any) => {
            selectedMessages.push({ Message: { Key: opt.Key }, IsRead: true })
        });
        if (keys?.length > 0) {
            Api.post(ApiEndpoints.saveSelectedMessages, selectedMessages).then(response => {

                if (response && response.status == 200) {
                    GetMessages();
                    selectedMessages = [];
                    return true;
                } else {
                    return false;
                }
            }, err => {
                return false;
            });
        } else {
            return false;
        }
    }
    const messageList: IEqMessageList = { filter: EmailFilter['day'], messageList: messages, checkAllFun: messagesCheckAll };

    const cancelFunction = (e: any) => { }
    const SendMessage = (formData: any, setFormData: any, setErrorString: any) => {
        Api.post(ApiEndpoints.sendMessage, { UsersGroups: formData.MessageRecipients, Message: formData.Message }).then(response => {
            if (response && response.status == 200) {
                setFormData({ MessageRecipients: [] as Array<any>, Message: "" });
                setErrorString(GetSendMessage(EqStatusCode.ok));
                document.getElementById('returnMessage')?.classList.remove('hide');
                document.getElementById('returnMessage')?.classList.add('show', 'valid');
                GetMessages();
                return true;
            } else {
                setErrorString(GetSendMessage(EqStatusCode.badRequest400));
                document.getElementById('returnMessage')?.classList.remove('hide', 'valid');
                document.getElementById('merrorMsg')?.classList.add('show', 'invalid');
                return false;
            }
        }, err => {
            //
            setErrorString(GetSendMessage(EqStatusCode.badRequest400));
            document.getElementById('returnMessage')?.classList.remove('hide', 'valid');
            document.getElementById('merrorMsg')?.classList.add('show', 'invalid');
            return false;
        });
    }

    const GetMessages = () => {
        setMessageString('');
        var messagesFromDB = [] as any;
        Api.get(ApiEndpoints.getMessages).then(response => {
            if (response && response.status == 200) {
                response.data?.result?.map((d: any) => {
                    //console.log(d);
                    messagesFromDB.push({
                        message: {
                            key: d.message.key, messageOn: new Date(d.message.sentOn), type: EqMessageType[d.message.messageType],
                            from: d.message.sender?.userName, to: d.recipient?.userName, subject: d.message.subject, text: d.message.message,
                            isRead: d.isRead, isSent: d.isSent, isFlaged: d.isFlaged,
                        },
                        checkBoxBordered: false,
                        clickedReplyFun: replyEmail,
                        clickedFlagFun: mardFlagedEmail,
                        clickedDeleteFunc: deleteEmail,
                        onCheckChangeFunc: onCheckChange,
                        selected: false
                    });
                });
                messagesFromDB = messagesFromDB?.sort((obj1: any, obj2: any) => {
                    if (obj1.message.isRead > obj2.message.isRead) { return 1; }
                    else if (obj1.message.isRead < obj2.message.isRead) { return -1 }
                    else { return 0 }
                });

                setMessages(messagesFromDB);
            } else {
                setMessageString("Not found");
            }
        }, err => {
            setMessageString("Not found");
        });
    }

    const composeMessageProps = {
        header: "Compose Message", to: "TO:", message: "TEXT",
        cancelFunc: cancelFunction, sendFunc: SendMessage
    };

    useEffect(() => {
        GetMessages();
    }, []);

    return (
        <div className="eq-message-board">
                <div className="eq-message-composer-container">
                    <EqComposeMessage {...composeMessageProps}></EqComposeMessage>
                </div>
                <div className="eq-message-list-container">
                    <EqMessageList {...messageList}></EqMessageList>
                </div>
        </div>
    );
}
export default EqAdminMessageBoard;