import { useEffect, useState } from "react";
import "./EqEventClass.scss";
import {
  IClassTimes,
  IEventClassDto,
} from "../../../shared/interfaces/ICreateEventDto";
import { IStudentGroupDto } from "../../../shared/interfaces/IStudentGroupDto";
import { Col, Collapse, Row } from "antd";
import { t } from "i18next";
import { useTranslation, Trans } from "react-i18next";

interface Props {
  event: any;
}

const EventClass: React.FC<Props> = ({ event }) => {
  const [selectedClassIndex, setSelectedClassIndex] = useState<number>(0);
  const { t, i18n } = useTranslation();
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    console.log(event);
  }, [event]);

  const handleClassSelection = (classIndex: number) => {
    setSelectedClassIndex(classIndex);
  };

  const renderClass = (eventClass: any, classIndex: number) => {
    return (
      <div key={classIndex}>
        <div className="heading">
          {new Date(eventClass.startDate).toLocaleDateString(
            t("dateLang"),
            options as any
          )}
        </div>
        <Row className="tb-heading">
          <Col xs={8} span={3}>
            {t("StartTime")}
          </Col>
          <Col xs={8} span={3}>
            {/* Height */}
            {t("class")}
          </Col>
          <Col xs={8} span={3}>
            {t("price")}
          </Col>
        </Row>
        {eventClass.classTimes?.map((classTime: any, i: any) => (
          <div key={i}>
            <Row className={i % 2 === 0 ? "tr-details" : "tr-odd"} key={i}>
              <Col xs={8} span={3}>
                {i == 0
                  ? new Date(classTime.startTime).toLocaleTimeString()
                  : "-"}
              </Col>
              <Col xs={8} span={3}>
                {classTime.height}
              </Col>
              <Col xs={8} span={3}>
                {classTime.price}
              </Col>
            </Row>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="event-class" style={{ width: "100%" }}>
      {event?.eventClasses?.map(renderClass)}
    </div>
  );
};

export default EventClass;
