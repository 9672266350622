// working code 2
import Config from "./Config";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ApiEndpoints, FrontEndPoints } from "./Common";
import { loadState, saveState } from "./LocalStorage";
import { loginUser } from "../context/Actions";
import { useAuthDispatch } from "../context/Context";
var currentState = loadState() as any;

const instance = axios.create({
  baseURL: Config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

const AxiosInterceptor = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useAuthDispatch();
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    currentState = loadState() as any;

    // Request interceptor
    instance.interceptors.request.use(
      (config: any) => {
        if (currentState) {
          const accessToken = currentState.accessToken;
          if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
          }
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );


    
  // Response interceptor
    const resInterceptor = (response: any) => {
      return response;
    };

    const errInterceptor = async (err: any) => {
      
      debugger;
      const originalConfig = err.config;
      if (originalConfig.url !== ApiEndpoints.UserAccount.Login && err.response) {
        const rememberMe = localStorage.getItem("rememberMe");
        const isRefreshing = localStorage.getItem("isRefreshing");
           
        if (rememberMe) {
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            if (!isRefreshing) {
              localStorage.setItem("isRefreshing", "true");
              console.log("--------is Refreshing");
              try {
                const response = await instance.post(
                  Config.apiUrl + ApiEndpoints.RefreshToken,
                  {
                    AccessToken: currentState?.accessToken,
                    refreshToken: currentState?.refreshToken,
                  }
                );

                if (response.data.refreshToken !== null) {
                  saveState(response.data);
                  // loginUser(dispatch,response.data);
                  originalConfig.headers.Authorization = `Bearer ${response.data?.accessToken}`;
                  currentState = loadState() as any;
                  originalConfig._retry = false;
                  if (response.data?.accessToken == currentState?.accessToken) {
                    const currentPathname = location.pathname;
                    const searchPath=location.search

                    localStorage.removeItem("isRefreshing");
                    if (currentPathname == FrontEndPoints.login) {
                      navigate(FrontEndPoints.all);
                    } else {
                      // navigate(currentPathname);
                      window.location.replace(Config.baseUrl + currentPathname +searchPath);
                    }
                  }
                }
                if (response.data.status && response.data.status === 401) {
                  navigate(FrontEndPoints.login);
                  // window.location.replace(Config.apiUrl + "login");
                }
              } catch (_error) {
                localStorage.removeItem("isRefreshing");
                return Promise.reject(_error);
              }
              localStorage.removeItem("isRefreshing");
            } else {
              // await new Promise((resolve) => setTimeout(resolve, 100));
              console.log("-------- axios(originalConfig)");

              // return axios(originalConfig);
            }
          }
          
          originalConfig._retry = false;
          console.log("--------1", err)       
          return err;
          // return Promise.reject();
        }
         else {
          if(err.response.status === 401 && !originalConfig._retry){
          // window.location.replace(Config.apiUrl + "login");
          navigate(FrontEndPoints.login);

          }
          // window.location.replace(Config.apiUrl + "login");
          // check that
          // navigate(FrontEndPoints.login);
          console.log("--------2", err)       
          // navigate(FrontEndPoints.login);

          throw err;
          // return Promise.reject(err);

        }
      } else {
        console.log("----Promise.reject()",)       
        // return Promise.reject();
        // throw err;
        return Promise.reject(err);

      }
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    setIsSet(true);
    return () => instance.interceptors.response.eject(interceptor);
  }, [location.pathname, navigate]);

  return isSet && children;
};

export default instance;
export { AxiosInterceptor };




// My Working code 1
//
// import Config from "./Config";
// import axios from "axios";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { ApiEndpoints, FrontEndPoints } from "./Common";
// import { loadState, saveState } from "./LocalStorage";
// import { useAuthDispatch, useAuthState } from "../context/Context";
// var currentState = loadState() as any;

// const instance = axios.create({
//   // timeout: 10000,
//   baseURL: Config.apiUrl,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// const AxiosInterceptor = ({ children }: any) => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   // const authState = useAuthState();
//   // console.log("interceptor");
//   // const navigate = useNavigate();
//   const [isSet, setIsSet] = useState(false);
//   useEffect(() => {
//     console.log("useEffect children", children);
//     //

//     instance.interceptors.request.use(
//       (config: any) => {
//         currentState = loadState() as any;
//         console.log("Api", currentState);
//         console.log("path:",location.pathname)
//         if (currentState) {
//           const accessToken = currentState.accessToken;
//           if (accessToken) {
//             // config.headers.Authorization = `Bearer ${currentState.accessToken}`;
//             config.headers.Authorization = `Bearer ${accessToken}`;
//           } else {
//             console.log("------- token");
//             console.log(accessToken);
//             console.log(currentState.accessToken);
//           }
//         }

//         return config;
//       },
//       (error) => {
//         return Promise.reject(error);
//       }
//     );

//     const resInterceptor = (response: any) => {
//       // console.log("resInterceptor");
//       return response;
//     };

//     const errInterceptor = async (err: any) => {
//       // console.log("errInterceptor");
//       const currentPathname = location.pathname;
//       
//       const originalConfig = err.config;
//       if (originalConfig.url !== FrontEndPoints.login && err.response) {
//         // Access Token was expired
//         const rememberMe = localStorage.getItem("rememberMe");
//         const isRefreshing = localStorage.getItem("isRefreshing");

//         if (rememberMe) {
//           if (err.response.status === 401 && !originalConfig._retry) {
//             originalConfig._retry = true;

//             // if (!isRefreshingToken) {
//             if (!isRefreshing) {
//               localStorage.setItem("isRefreshing","true");
//               try {
//                 const response = await instance.post(
//                   Config.apiUrl + ApiEndpoints.RefreshToken,
//                   {
//                     AccessToken: currentState?.accessToken,
//                     refreshToken: currentState?.refreshToken,
//                   }
//                 );

//                 if (response.data.refreshToken !== null) {
//                   saveState(response.data);
//                   // retrigger old request
//                   originalConfig.headers.Authorization = `Bearer ${response.data?.accessToken}`;
//                   currentState = loadState() as any;
//                   originalConfig._retry = false;
//                   if (response.data?.accessToken == currentState?.accessToken) {
//                   localStorage.removeItem("isRefreshing");

//                     console.log(Config.apiUrl);
//                     if(currentPathname==FrontEndPoints.login){
//                       navigate(FrontEndPoints.all)
//                     }else{

//                       navigate(currentPathname);
//                     }
//                     // return axios(originalConfig);
//                   }
//                 }
//                 if (response.data.status && response.data.status === 401) {
//                   // history.push(FrontEndPoints.logout)
//                   // navigate(FrontEndPoints.logout);
//                   // window.location.replace(Config.apiUrl + FrontEndPoints.login);
//                   window.location.replace(Config.apiUrl + "login");
//                 }
//               } catch (_error) {
//                 localStorage.removeItem("isRefreshing");

//                 return Promise.reject(_error);
//               }
//               localStorage.removeItem("isRefreshing");

//             } else {
//               await new Promise((resolve) => setTimeout(resolve, 100));
//               return axios(originalConfig);
//             }
//           }
//           originalConfig._retry = false;
//           throw err;
//         } else {
//           // return Promise.reject();
//           
//           // originalConfig.url=FrontEndPoints.login;
//           window.location.replace(Config.apiUrl + "login");
//         }
//       } else {
//         return Promise.reject();
//       }

//       // console.log("errInterceptor");
//       //   if (error.response.status === 401) {
//       //     //redirect logic here
//       //   }

//       //  return Promise.reject();
//     };

//     const interceptor = instance.interceptors.response.use(
//       resInterceptor,
//       errInterceptor
//     );
//     // setIsSet(true);
//     return () => instance.interceptors.response.eject(interceptor);
//   }, []);

//   return children;
//   // return isSet && children;
// };

// export default instance;
// export { AxiosInterceptor };

// new
// import axios from "axios";
// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { ApiEndpoints, FrontEndPoints } from "./Common";
// import Config from "./Config";
// import { loadState, saveState } from "./LocalStorage";

// const instance = axios.create({
//   baseURL: Config.apiUrl,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// const AxiosInterceptor = ({ children }: any) => {
//   const [isRefreshingToken, setIsRefreshingToken] = useState(false);
//   const navigate = useNavigate();
//   const currentState = loadState() || {} as any;

//   useEffect(() => {
//     
//     const requestInterceptor = instance.interceptors.request.use((config:any) => {
//       const accessToken = currentState.accessToken;
//       if (accessToken) {
//         config.headers.Authorization = `Bearer ${accessToken}`;
//       }
//       return config;
//     });

//     const responseInterceptor = instance.interceptors.response.use(
//       (response) => response,
//       async (error) => {
//         const originalConfig = error.config;
//         if (
//           originalConfig.url !== FrontEndPoints.login &&
//           error.response &&
//           error.response.status === 401 &&
//           !originalConfig._retry
//         ) {
//           originalConfig._retry = true;

//           if (!isRefreshingToken) {
//             setIsRefreshingToken(true);

//             try {
//               const response = await instance.post(
//                 ApiEndpoints.RefreshToken,
//                 {
//                   accessToken: currentState.accessToken,
//                   refreshToken: currentState.refreshToken,
//                 }
//               );

//               if (response.data.refreshToken !== null) {
//                 saveState(response.data);
//                 originalConfig.headers.Authorization = `Bearer ${response.data?.accessToken}`;
//                 originalConfig._retry = false;

//                 if (response.data?.accessToken == currentState?.accessToken) {
//                   setIsRefreshingToken(false);
//                   return axios(originalConfig);
//                 }
//               }

//               if (response.data.status && response.data.status === 401) {
//                 window.location.replace(ApiEndpoints.login);
//               }
//             } catch (error) {
//               setIsRefreshingToken(false);
//               return Promise.reject(error);
//             }
//             setIsRefreshingToken(false);
//           } else {
//             await new Promise((resolve) => setTimeout(resolve, 100));
//             return axios(originalConfig);
//           }
//         }
//         originalConfig._retry = false;
//         return Promise.reject(error);
//       }
//     );

//     return () => {
//       instance.interceptors.request.eject(requestInterceptor);
//       instance.interceptors.response.eject(responseInterceptor);
//     };
//   }, [currentState.accessToken, currentState.refreshToken, isRefreshingToken]);

//   return children;
// };

// export default instance;
// export { AxiosInterceptor };

//------------------------------------
// chat gpt code 2

