import * as React from "react";
import "./EqTrainerTrainingsPrevious.scss";
import { Row, Empty, Skeleton, Input } from "antd";
import {
  ApiEndpoints,
  GetEventMessage,
  FrontEndPoints,
  GenericMessage,
  openNotificationWithIcon,
} from "../../shared/Common";
import Api from "../../shared/Api";
import "./EqTrainerTrainingsPrevious.scss";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import EqPreviousTrainingCollapse from "./previousTrainingCollapse/EqPreviousTrainingCollapse";
import EqButton from "../../custom/eqButton/EqButton";

export interface IEqTrainerTrainingsPreviousProps {
  updateCount?: (count: number) => void;
}
const EqTrainerTrainingsPrevious: React.FC<IEqTrainerTrainingsPreviousProps>  = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [isError, setIsError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

   const [monthCount, setMonthCount] = React.useState<number>(1);
   const [loading, setLoading] = React.useState<boolean>(false);
   // Add this state


  const [areas, setAreas] = React.useState([]) as any;
  const [trainerData, setTrainerData] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState([]) as any;

  const [population, setPopulation] = React.useState([]) as any;
  const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;


  const [trainingsList, setTrainingsList] = React.useState(null) as any;
  
  const [studentHorsesList, setStudentHorsesList] = React.useState([]);
  

  const handleLoadMore = () => {
    setLoading(true);
    let newMonthCount = monthCount+1;
    setMonthCount(newMonthCount)

  };
  React.useEffect(() => {
    GetTrainerPreviousTrainingsOfMonths();

  }, [monthCount]);

  
  React.useEffect(() => {
    // console.log("--Trainer Previous Trainings--", location);

    // improve that get specific Data
    GetTrainerProfile();

    GetTrainerPreviousTrainingsOfMonths();

    GetTrainerStudents();
    getAreas();
    getWeekDays();
    getPopulation();
    getGroupPopulation();

    window.scrollTo(0, 0);
 
  }, []);

  const GetTrainerStudents = () => {
    Api.get(ApiEndpoints.GetTrainerStudents).then(
      (response) => {
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var trainerStudents = response.data;
          setIsError(false);

          // console.log(userDetails);
          setErrorMessage("");
          setTrainerStudents(response.data);
          // console.log(trainerHorsesList);
          [...trainerStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.trainer.horses.length > 0) {
              [...item.trainer.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
          console.log("studentHorses:", studentHorsesListOptions);
          // console.log("TrainerStudents:",response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setPopulation(response.data);
          // console.log("population:",response.data);
          // console.table(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetTrainerProfile = () => {
    Api.get(ApiEndpoints.GetTrainerProfile).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setTrainerData(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getAreas = () => {
    Api.get(ApiEndpoints.eventGetAreas).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const editTraining = () => {};

  const handleOnUpdateTraining = (newData: any, oldData: any) => {
    if (trainingsList != undefined) {
      const trainings = [...trainingsList];
      var foundIndex = trainings.findIndex((x) => x.key == oldData.key);
      trainings[foundIndex] = newData;
      setTrainingsList(trainings);
    }
  };

  const GetTrainerPreviousTrainingsOfMonths = async () => {
    // let path = ApiEndpoints.TrainerTraining.GetTrainerPreviousTrainingsOfMonths;

    let path = `${
      ApiEndpoints.TrainerTraining.GetTrainerPreviousTrainingsOfMonths
    }?monthCount=${monthCount}`;

    // let path =
    // ApiEndpoints.TrainerTraining.GetTrainerTrainings + `?ExcludeDisciple=${EqDisciplineType.Open}`;

    Api.get(path).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          setTrainingsList(data);
          console.log(data);
          console.log("GetTrainerPreviousTrainingsOfMonths", data);
          // onloadSendCount(data.length)
          // setTrainingsList(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
        setLoading(false)
      },
      (error) => {
        setLoading(false)

        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const handleOnRemoveTraining = (trainingData: any, refund?: boolean) => {
    
    let path =
      ApiEndpoints.TrainerTraining.Delete +
      `?key=${trainingData.key}&refund=${refund}`;
    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(trainingData);
          // GetTrainerPreviousTrainingsOfMonths();
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
        openNotificationWithIcon(
          GenericMessage(error.response.status),
          "Error occurred while Deleting. Please try again or contact administrator."
        );
      }
    );
  };

  const removeItem = (data: any) => {
    if (trainingsList != undefined) {
      var updatedList =
        trainingsList != undefined
          ? trainingsList.filter((item: any) => item.key !== data.key)
          : new Array<any>();
      setTrainingsList(updatedList);

  
    }
  };

  //${(!isUserValid || location.pathname == FrontEndPoints.trainer.allEventsBoard) ? "container" : ""}
  return (
    <div className={`eq-previous-training-board`}>
      <div className="eq-container-fluid">
        <Row>
          {/* <Skeleton loading={!trainingsList} active></Skeleton> */}
          {[...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              loading={!trainingsList}
              paragraph={{ rows: 2 }}
              active
            />
          ))}
        </Row>
        {trainingsList && (
          <>
            {trainingsList.length > 0 ? (
              <>
                {trainingsList.map((item: any, index: number) => (
                  <EqPreviousTrainingCollapse
                    key={index}
                    event={item}
                    creatorUser={trainerData}
                    areas={areas}
                    weekDays={weekDays}
                    studentHorsesList={studentHorsesList}
                    trainerStudents={trainerStudents}
                    eventList={trainingsList}
                    population={population}
                    groupPopulation={groupPopulation}
                    removeEventFunc={handleOnRemoveTraining}
                    editEventFunc={editTraining}
                    onCreateOrUpdateActivity={handleOnUpdateTraining}
                  ></EqPreviousTrainingCollapse>
                ))}

                <Row justify="center" className="section-padding">
                  <EqButton
                    type="submit"
                    classNames="btn-background-orange"
                    onClick={handleLoadMore}
                    loading={loading}
                  >
                    Load More
                  </EqButton>
                </Row>
              </>
            ) : (
              <Row justify="center">
                <Empty description={t("noTrainingsAvailable")} />
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default EqTrainerTrainingsPrevious;
