import React, { useState, useEffect, useRef } from "react";
import "./EqCollapsePanel.scss";
import { Row, Col } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

export interface IEqCollapse {
  // setDisplay?: any;
  // showArrow?: boolean;
  // classNames?: any;
  defaultOpen?: boolean;
  header: any;
  childInnerHeight?: any;
  children?: React.ReactNode;
  classNames? : string;
}
const EqCollapsePanel = ({
  defaultOpen,
  header,
  children,
  childInnerHeight,
  classNames
}: 
// setDisplay,
// showArrow,
// classNames,
IEqCollapse) => {
  const [show, setShow] = useState(defaultOpen);
  const [innerHeight, setInnerHeight] = useState(0);
  const ref = useRef<any>();
  const innerStyle = {
    // height:  `${show? innerHeight?innerHeight:880 : 0}px`
    // height:  `${show? innerHeight?innerHeight:880 : 0}px`
    // height: `${show ? innerHeight : 0}rem`,
    // height: `${show ? childInnerHeight : '0px'}`,
    height: `${show ? 'auto' : '0px'}`,
  };
  useEffect(() => {
    
    // console.log(childInnerHeight)
    if (ref.current.clientHeight) {
      setInnerHeight(ref.current.clientHeight);
      // console.log("height: ", ref.current.clientHeight);
    }
    // showChange(display);
  }, [childInnerHeight]);

  const toggleShow = (e: any) => {
    setShow(!show);
    e.stopPropagation();
    // showChange(!show);
  };
  return (
    <div  className="eq-collapse-panel">
      <div onClick={toggleShow}>
        <Row
          className={`panel-header ${classNames}`}
          // className={`eq-card-header ${classNames ? classNames : ""}`}
          // justify="center"
          // align="middle"
        >
          <Col span={23}>{header}</Col>

          <Col className="arrow" xs={1} sm={1} md={1}>
            {show ? (
              <span className="icon">
              <UpOutlined />
              </span>
            ) : (
              <span className="icon">
                {/* <img alt="" src="/caret-down.svg" /> */}
                <DownOutlined />
              </span>
            )}
          </Col>
        </Row>
      </div>

      <div
         ref={ref}
        // className={`panel__inner ${show? "active":"inactive"}` }
        className="panel-inner"
        style={innerStyle}
        aria-hidden={!show}
      >
        <div className="panel-content">
          <div >{children}</div>
        </div>
      </div>
    </div>
  );
};
export default EqCollapsePanel;
