import React, { useState , useEffect} from 'react';
import './EqComposeMessageBoard.scss';
import { Row, Col } from 'antd';
import EqComposeMessage from '../eqComposeMessage/EqComposeMessage';
import { useTranslation, Trans } from 'react-i18next';
import Api from '../../shared/Api';
import { EqStatusCode, ApiEndpoints, GetSendMessage, FrontEndPoints } from '../../shared/Common';
import { useLocation } from "react-router-dom";

const EqComposeMessageBoard = () => {
    const { t, i18n } = useTranslation();
    var user = window.location.search;
    user = user.substr(user.indexOf('=') + 1);
    const [sender, setSender] = useState(user) as any;

    const { pathname } = useLocation();


    const cancelFunction = (e: any) => {
        
    }
    const getSelectedSender = () => {
    }
    const SendMessage = (formData: any, setFormData: any, setErrorString: any) => {
        Api.post(ApiEndpoints.sendMessage, { UsersGroups: formData.MessageRecipients, Message: formData.Message }).then(response => {
            if (response && response.status == 200) {
                setFormData({ MessageRecipients: [] as Array<any>, Message: "" });
                setErrorString(GetSendMessage(EqStatusCode.ok));
                document.getElementById('returnMessage')?.classList.remove('hide');
                document.getElementById('returnMessage')?.classList.add('show', 'valid');
                return true;
            } else {
                setErrorString(GetSendMessage(EqStatusCode.badRequest400));
                document.getElementById('returnMessage')?.classList.remove('hide', 'valid');
                document.getElementById('merrorMsg')?.classList.add('show', 'invalid');
                return false;
            }
        }, err => {
            setErrorString(GetSendMessage(EqStatusCode.badRequest400));
            document.getElementById('returnMessage')?.classList.remove('hide', 'valid');
            document.getElementById('merrorMsg')?.classList.add('show', 'invalid');
            return false;
        });
    }
    const composeMessageProps = {
        header: "Compose Message", to: "TO:", message: "TEXT:",
        cancelFunc: cancelFunction, sendFunc: SendMessage, selectedSender: sender
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        getSelectedSender();
    }, [pathname]);
    return (
        <>

            <div className="eq-sb-container">
                <Row className="eq-sb-heading-row">
                    <Col className="eq-sb-col" flex="auto" sm={5} xs={4}>
                        <h2 className="eq-sb-heading">Messages</h2>
                    </Col>
                </Row>

                <div>
                    <EqComposeMessage {...composeMessageProps}></EqComposeMessage>
                </div>
            </div>
        </>
    );
}
export default EqComposeMessageBoard;