import * as React from "react";
import "./EqInviteToJoin.scss";
import { Row, Col } from "antd";
import { EqInput } from "../eqInput/EqInput";
import { Input } from "antd";
import EqButton from "../eqButton/EqButton";
import { useTranslation } from "react-i18next";
import Api from "../../shared/Api";
import {
  EqStatusCode,
  GetSentInviteMessage,
  ApiEndpoints,
} from "../../shared/Common";
import { Form, Typography, Tag } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const EqInviteToJoin = ({
  header,
  emailHeading,
  messageHeading,
  cancelFunc,
  sendFunc,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { TextArea } = Input;
  const [errorString, setErrorString] = React.useState("");
  const [formData, setFormData] = React.useState({
    email: "",
    emailList: [] as any,
    message: "",
  }) as any;
  const [emailTags, setEmailTags] = React.useState([] as any);
  const { Text } = Typography;
  const validateEmail = (email: any): boolean => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };
  const validateData = (): boolean => {
    let validate: boolean = true;
    setErrorString("");
    if (
      formData.email == "" ||
      formData.message == "" ||
      formData.emailList.length <= 0
    ) {
      setErrorString("Invalid data");
      validate = false;
    } else if (formData.emailList.length > 0) {
      formData.emailList.map((s: any) => {
        validate = validateEmail(s);
        setErrorString("Invalid email");
        return validate;
      });
    }
    return validate;
  };
  const sendInvite = () => {
    let res = validateData();
    if (res) {
      setErrorString("");
      let path = "";
      if (location.pathname == "/school/students") {
        path=ApiEndpoints.SchoolStudentInvite;
      }else if(location.pathname == "/school/trainers"){
        path=ApiEndpoints.SchoolTrainerInvite;
      }
      Api.post(path, {
        InviteTo: formData.email,
        Recievers: formData.emailList,
        InviteMessage: formData.message,
      }).then(
        (response) => {
          if (response && response.status == 200) {
            setFormData({ email: "", message: "" });
            setErrorString(GetSentInviteMessage(EqStatusCode.ok));
            document.getElementById("returnMessage")?.classList.remove("hide");
            document
              .getElementById("returnMessage")
              ?.classList.add("show", "valid");
            sendFunc(true);
          } else {
            setErrorString(GetSentInviteMessage(EqStatusCode.badRequest400));
            document
              .getElementById("returnMessage")
              ?.classList.remove("hide", "valid");
            document
              .getElementById("returnMessage")
              ?.classList.add("show", "invalid");
            sendFunc(false);
          }
        },
        () => {
          setErrorString(GetSentInviteMessage(EqStatusCode.badRequest400));
          document
            .getElementById("returnMessage")
            ?.classList.remove("hide", "valid");
          document
            .getElementById("returnMessage")
            ?.classList.add("show", "invalid");
          sendFunc(false);
        }
      );
    } else {
      setErrorString(
        GetSentInviteMessage(EqStatusCode.inCompleteCredentials406)
      );
      document
        .getElementById("returnMessage")
        ?.classList.remove("hide", "valid");
      document
        .getElementById("returnMessage")
        ?.classList.add("show", "invalid");
      sendFunc(false);
    }
  };

  const onFinishFailed = () => {};
  const addTags = (input: any) => {
    var inputTxt = input.target.value;
    let arr = inputTxt.split(/[' ',',']/);
    //console.log(arr);
    let tagsArr = [] as any;
    arr.map((s: any) => {
      if (tagsArr && tagsArr.length > 0 && tagsArr.includes(s)) {
        setErrorString("Repeat Email");
      } else {
        if (s.length > 0) {
          tagsArr.push(s);
        }
      }
    });
    if (tagsArr.length > 0) {
      //console.log(tagsArr);
      setFormData({ ...formData, email: inputTxt, emailList: tagsArr });
      setEmailTags(tagsArr);
    } else {
      setFormData({ ...formData, email: inputTxt });
    }
  };
  return (
    <>
      <Form
        layout="vertical"
        size="small"
        onFinish={sendInvite}
        onFinishFailed={onFinishFailed}
      >
        <Row className="eq-is-container">
          <Col flex="auto">
            <Row>
              <h5 className="eq-is-heading">{header}</h5>
            </Row>
            <Row>
              <p className="eq-is-input-heading">{emailHeading}</p>
            </Row>
            <Row>
              <Col flex="auto">
                <EqInput
                  type="text"
                  className="eq-is-email"
                  placeholder={t("emailAddress")}
                  value={formData.email}
                  onChange={addTags}
                ></EqInput>
              </Col>
            </Row>
            <Row>
              <Col flex="auto">
                {formData.emailList && formData.emailList.length > 0 && (
                  <>
                    {formData.emailList.map((tag: any) => (
                      <Tag key={tag} className="eq-email-tag" closable>
                        {tag}
                      </Tag>
                    ))}
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <p className="eq-is-input-heading">{messageHeading}</p>
            </Row>
            <Row>
              <Col flex="auto">
                <TextArea
                  rows={4}
                  className="eq-is-textarea"
                  value={formData.message}
                  onChange={(e: any) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                ></TextArea>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text id="returnMessage" className="hide">
                  {errorString}
                </Text>
              </Col>
            </Row>
            <Row justify="end" align="bottom" className="eq-is-btn">
              <Col className="eq-col-btn">
                <EqButton
                  type="button"
                  bordered="true"
                  classNames="btn-light-gray"
                  onClick={cancelFunc}
                >
                  {t("cancel")}
                </EqButton>
              </Col>
              <Col className="" sm={16} xs={16} md={3}>
                <EqButton type="button" htmlType="submit" block={true}>
                  Send Invite
                </EqButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default EqInviteToJoin;
