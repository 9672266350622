import { useEffect } from 'react';
import * as React from 'react';
import { FrontEndPoints } from '../shared/Common';
import { useAuthDispatch } from '../context/Context';
import { logout } from '../context/Actions';
import { Navigate } from 'react-router-dom';

const Logout = (props: any) => {
    const dispatch = useAuthDispatch();

    useEffect(() => {
        
        logout(dispatch);
    }, []);

    return (<Navigate to={FrontEndPoints.all} />);
}

export default Logout;