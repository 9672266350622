import * as React from 'react';
import './EqEventCard.scss';
import moment from 'moment';
import { IEqEvent } from '../../shared/Interfaces';
import { EqDisciplineType } from '../../shared/enums';
import { useAuthState } from '../../context/Context';
import { FrontEndPoints, ValidateIsTrainer, getDefaultImage, imageType } from '../../shared/Common';
import {  useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import EqCardEvent from '../../custom/eqCardEvent/EqCardEvent';
// import EqCardEvent from '../../custom/eqCardEvent/EqCardEvent';
//IEqEvent
const EqEventCard = ({ event, removeEventFunc, editEventFunc, removeAttendeeFunc, ...rest }: any) => {

    const navigate = useNavigate();
    const userDetails: any = useAuthState();
    const isTrainer: boolean = ValidateIsTrainer(userDetails);
    const [isOwner, setIsOwner] = React.useState(false);
    const [isAttendee, setIsAttendee] = React.useState(false);
    const [studentGroups, setStudentGroups] = React.useState([]) as any;
    const [isStudentTrainings, setIsStudentTrainings] = React.useState(false);
    const [showActions, setShowActions] = React.useState(false);
    const location = useLocation();
    const checkStudentTrainings = () => {

        let pathname = location.pathname;

        if (pathname == FrontEndPoints.student.dashboard) {
            setIsStudentTrainings(true);
        }
        else {
            setIsStudentTrainings(false);
        }
    }
    const checkEventOwner = () => {
        var owner = event?.organizers?.filter((o: any) => {
            return o.key == userDetails.key
        }).shift();
        console.log(owner);
        if (owner != null || undefined) {
            setIsOwner(true);
        } else {
            setIsAttendee(false);
        }
    }
    const getEventGroups = () => {
        //console.log(event);
        var studentGroups = event?.attendees?.filter((x: any) => x.group != null);
        var groups = studentGroups?.map((d: any) => d.group);
        const result = [];
        const map = new Map();
        if (groups != null && groups.length > 0) {
            for (const item of groups) {
                if (!map.has(item.key)) {
                    map.set(item.key, true);    // set any value to Map
                    result.push(item);
                }
            }
        }
        setStudentGroups(result);
    }
    const rowClick = () => {
// 
        // saveEvent(event);
        navigate(`${FrontEndPoints.student.viewEvent}?key=${event.key}`, { state: { event: event } });
    }
    const removeEvent = (eventArgs: any) => {
        eventArgs.stopPropagation();
        if (isOwner) {
            if (removeEventFunc != null) {
                removeEventFunc(event);
            }
        } else if (isAttendee) {
            if (removeAttendeeFunc != null) {
                removeAttendeeFunc(event);
            }

        }
    }

    const editEvent = (eventArgs: any) => {
        eventArgs.stopPropagation();
        if (editEventFunc != null) {
            editEventFunc(event);
        } else {
            navigate(FrontEndPoints.trainer.createEvent, { state: { event: event } });
        }
    }

    const checkEventAttendee = () => {
        var attendee = event?.attendees?.filter((a: any) => {
            return a.user.key == userDetails.key
        }).shift();
        //console.log(attendee);
        if (attendee != null || undefined) {
            setIsAttendee(true);
        } else {
            setIsAttendee(false);
        }
    }
    
    React.useEffect(() => {
        checkEventOwner();
        getEventGroups();
        checkEventAttendee();
        checkStudentTrainings();
        console.log(event);
        
    }, [event])
    return (
        <>
            <div className="eq-card-container" onClick={rowClick}>
                <EqCardEvent
                key={1}
                alt={event.title}
                type={event.disciplineType}
                description={event.description}
                price={event.price}
                trainer= {event.organizers}
                stutents= {event.attendees}
                startTime= {event.startDate}
                endTime={event.endDate}
                currency={event.currency}
                duration={event.duration}
                imgSrc={event.eqImage
                    ? event.eqImage.url
                    : getDefaultImage(imageType.events)}
                    title={event.title || EqDisciplineType[event.disciplineType]}

                    date={<>
                        {/* {moment(event.startTime).format("hh:mm A")} - {moment(event.startTime).minutes(event.duration).format("hh:mm A")} */}
                        {moment(event??event.multipleStartDates[0]).format("hh:mm A")} 
                    </>}
                    // description={
                    //     <>
                    //         <Row>
                    //             <Col>
                    //                 Type: &nbsp;</Col>
                    //             <Col>
                    //                 {EqDisciplineType[event.disciplineType]}</Col>
                    //         </Row>
                    //         <Row>
                    //             {/*<Col className="loc-icon">*/}
                    //             {/*    <span className=""><img alt="" src="/chart-pie.svg" /></span>*/}
                    //             {/*</Col>*/}
                    //             <Col>
                    //                 {event.location?.city}
                    //             </Col>
                    //         </Row>
                    //         <Row>
                    //             {
                    //                 studentGroups?.map((item: IGroupDto, index: number) =>
                    //                     <Tag className={`eq-group-color-${(index % 4) + 1}`} >{item.name}</Tag>
                    //                 )}
                    //         </Row>
                    //         <Row>
                    //             <Col>
                    //                 Trainer: &nbsp;</Col>
                    //             <Col>
                    //                 {event.organizers && event.organizers.length > 0 &&
                    //                     event.organizers.map((item: IUserProfileDto, index: number) =>
                    //                         <Link to={`${FrontEndPoints.trainer.profileBoard}?key=${item.key}`} onClick={(e: any) => { e.stopPropagation() }} className="trainer-link">
                    //                             {item.firstName || item.userName}
                    //                         </Link>
                    //                     )
                    //                 }
                    //             </Col>
                    //         </Row>
                    //         <Row>
                    //             <Col>
                    //                 Students: &nbsp;</Col>
                    //             <Col>
                    //                 {event.attendees?.length}</Col>
                    //         </Row>
                    //         <Row>
                    //             <Col>
                    //                 Kr. </Col><Col className="eq-event-price">{event.price}
                    //             </Col>
                    //         </Row>
                    //     </>
                    // }
                    actionsList={
                        showActions && (isOwner || (isAttendee && isStudentTrainings)) &&
                        [
                            isTrainer ?
                                    <span> <img alt="" src="/edit.svg" onClick={editEvent} /></span>:"",
                                    <span><img alt="" src="/trash-alt.svg" onClick={removeEvent} /></span>
                        ]

                    }

                />
            </div>
        </>
    );
}
export default EqEventCard;