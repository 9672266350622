import React from 'react'
import { useAuthState } from '../../context/Context';
import './EqActivityTrainingStudentDashboard.scss';
import { FrontEndPoints, ValidateIsEventHolder, ValidateIsSchool, ValidateIsStudent, ValidateIsTrainer, addDays } from '../../shared/Common';
import { useTranslation, Trans } from "react-i18next";
import { Badge, Col, Empty, Row, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { Link } from 'react-router-dom';
import EqButton from '../../custom/eqButton/EqButton';
import moment from 'moment';
import EqActivityCollapse from '../../eqTrainer/eqActivityCollapse/EqActivityCollapse';
import EqCollapsePanel from '../../custom/eqCollapsePanel/EqCollapsePanel';
import { EqIconAttendee, EqIconCalendar, EqIconLocation, EqIconPrice, EqIconUser } from '../../custom/eqIcon/EqIcons';
import { EqDisciplineType, EqEventUserType, EqProfessionType, EqTrainingType } from '../../shared/enums';
import EqTags from '../../custom/eqTags/EqTags';
import { IUserProfileDto } from '../../shared/interfaces/IUserProfileDto';
import { addMonths } from "date-fns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
export interface IEqEventSplit {
    today: any;
    tomorrow: any;
    later: any;
}
export interface IEqActivityTrainingList {

    trainingList: any;

}
const EqActivityTrainingStudentDashboard = ({
    trainingList
}: IEqActivityTrainingList) => {
    const { t, i18n } = useTranslation();
    const [eventData, setEventData] = React.useState({} as IEqEventSplit);
    const [tabCounts, setTabCounts] = React.useState({
        today: 0,
        tomorrow: 0,
        later: 0,
    });

    const userDetails: any = useAuthState();
    const isStudent: boolean = ValidateIsStudent(userDetails);
    const isTrainer: boolean = ValidateIsTrainer(userDetails);
    const isSchool: boolean = ValidateIsSchool(userDetails);
    const isEventHolder: boolean = ValidateIsEventHolder(userDetails);

    const options: Intl.DateTimeFormatOptions = {
        weekday: "long", // Correctly typed as "long" | "short" | "narrow" | undefined
        day: "2-digit",  // Correctly typed as "numeric" | "2-digit" | undefined
        month: "short",  // Correctly typed as "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined
        year: "numeric", // Correctly typed as "numeric" | "2-digit" | undefined
    };
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayTime = today.getTime();

    const tomorrow = new Date();
    tomorrow.setHours(24, 0, 0, 0);
    const tomorrowTime = tomorrow.getTime();

    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setHours(48, 0, 0, 0);
    const dayAfterTomorrowTime = dayAfterTomorrow.getTime();

    const currentDate = addDays(new Date(), 2).toLocaleDateString(t("dateLang"), options)
    const futureDate = addMonths(new Date(), 1).toLocaleDateString(t("dateLang"), options);

    const dateRange = `${currentDate} to ${futureDate}`;

    const [todayIsCollapseAll, setIsTodayCollapseAll] = React.useState(false);

    const [tomorrowIsCollapseAll, tomorrowSetIsCollapseAll] =
        React.useState(false);
    const tomorrowCollapseAll = () => {
        tomorrowSetIsCollapseAll(!tomorrowIsCollapseAll);
        eventData?.tomorrow.map((item: any) => {
            item.display = !tomorrowIsCollapseAll;
        });
    };

    const [laterIsCollapseAll, laterSetIsCollapseAll] = React.useState(false);

    const laterCollapseAll = () => {
        laterSetIsCollapseAll(!laterIsCollapseAll);
        eventData?.later.map((item: any) => {
            item.display = !laterIsCollapseAll;
        });
    };

    React.useEffect(() => {
        var formedData = {} as IEqEventSplit;
        
        if (trainingList?.length > 0) {
            formedData.today = trainingList?.filter(
                (opt: any) => opt.event.startDate < tomorrowTime && opt.event.startDate >= todayTime
                // (opt: any) => opt.startDate < tomorrowTime 
            );
            formedData.tomorrow = trainingList?.filter(
                (opt: any) =>
                    opt.event.startDate < dayAfterTomorrowTime && opt.event.startDate >= tomorrowTime
            );
            formedData.later = trainingList?.filter(
                (opt: any) => opt.event.startDate > dayAfterTomorrowTime
            );
            setEventData(formedData);
            // Update tab counts
            setTabCounts({
                today: formedData.today.length,
                tomorrow: formedData.tomorrow.length,
                later: formedData.later.length,
            });
        }
        else {
            setEventData({} as any);

        }
        //formedData.today = eventList;
    }, [trainingList]);



    const renderEqCollapsePanel = (item: any) => {
        return (
            <EqCollapsePanel
                defaultOpen={false}
                header={
                    <>
                        <div className="eq-activity-row">
                            <Row className="eq-status-row-border" align="middle">
                                <Col className="content-col">
                                    <Row>
                                        <Col>
                                            <div className="date-icon">
                                                <EqIconCalendar
                                                    eqWidth={34}
                                                    eqHeight={34}
                                                    eqClass="eq-cal-icon"
                                                    eqFill="#083b49"
                                                />
                                                <div className="day">
                                                    {moment(item.event.startDate).format("DD")}
                                                </div>
                                                <div className="month">
                                                    {moment(item.event.startDate).format("MMM")}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="header-details">
                                            <Row>
                                                <Col>
                                                    <span className="time">
                                                        {moment(item.event.startTime).format("hh:mm A")} -{" "}
                                                        {item.event.disciplineType != EqDisciplineType.Open
                                                            ? moment(item.event.startTime)
                                                                .add(item.event.duration, "minutes")
                                                                .format("hh:mm A")
                                                            : moment(item.event.endTime).format("hh:mm A")}
                                                    </span>
                                                </Col>
                                                <Col>
                                                    <div className="eq-event-type">
                                                        {item.event.accessType === 1 ? (
                                                            <EqTags
                                                                text={t(EqTrainingType[item.event.disciplineType])}
                                                                dicipline={true}
                                                            />
                                                        ) : (
                                                            <EqTags
                                                                text={t(EqDisciplineType[item.event.disciplineType])}
                                                                dicipline={true}
                                                            />
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col className="col-trancated">
                                                    <span className="location">
                                                        <EqIconLocation
                                                            eqWidth={13}
                                                            eqHeight={16}
                                                            eqFill="#b5b5b5"
                                                        />
                                                        {item.event?.location?.city}
                                                    </span>
                                                </Col>
                                                <Col>
                                                    <span className="trainer">
                                                        {item.event.organizers &&
                                                            item.event.organizers.length > 0 &&
                                                            item.event.organizers.map(
                                                                (item: any, index: number) => (
                                                                    item.eventUserType === EqEventUserType.Owner && (
                                                                        <span className="trainer" key={index}>
                                                                            <EqIconUser
                                                                                eqWidth="16"
                                                                                eqHeight="16"
                                                                                eqFill="#b5b5b5"
                                                                            />
                                                                            {item.user.professionType === EqProfessionType.Trainer ?
                                                                                (
                                                                                    <Link
                                                                                        to={`${FrontEndPoints.trainer.profileBoard}?key=${item.user.key}`}
                                                                                        onClick={(e: any) => {
                                                                                            e.stopPropagation();
                                                                                        }}
                                                                                        className="trainer-link"
                                                                                    >
                                                                                        {item.user.firstName || item.userName}
                                                                                    </Link>
                                                                                ) : (
                                                                                    <Link
                                                                                        to={`${FrontEndPoints.school.profileBoard}?key=${item.user.key}`}
                                                                                        onClick={(e: any) => {
                                                                                            e.stopPropagation();
                                                                                        }}
                                                                                        className="trainer-link"
                                                                                    >
                                                                                        {item.user.firstName || item.userName}
                                                                                    </Link>
                                                                                )}

                                                                        </span>
                                                                    )
                                                                )
                                                            )}
                                                    </span>
                                                </Col>


                                                {/* <Col>
                                                    <span className="attendees">
                                                        <EqIconAttendee
                                                            eqWidth="15"
                                                            eqHeight="15"
                                                            eqFill="#b5b5b5"
                                                        />
                                                        {item.event.attendees?.length || "0"}/
                                                        {item.event.maxAvailableSpace}
                                                    </span>
                                                </Col> */}
                                                <Col>
                                                    <span className="price">
                                                        <EqIconPrice
                                                            eqWidth="15"
                                                            eqHeight="13"
                                                            eqFill="#b5b5b5"
                                                        />{" "}
                                                        {item.event.price}
                                                    </span>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <span className="title">{item.event.title}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                    </>
                }
            ></EqCollapsePanel>
        )
    }


    return (
        <>
            <div className="eq-st-dashboard-list">
                {eventData?.later?.length > 0 ||
                    eventData?.tomorrow?.length > 0 ||
                    eventData?.today?.length > 0 ? (
                    <>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={
                                <span>{t("today")}{" "}
                                    <Badge
                                        count={tabCounts.today}
                                        offset={[0, -15]}
                                        className="tab-badge"
                                        size="default"
                                    >
                                    </Badge>
                                </span>}

                                key="1">
                                <div>
                                    {eventData?.today?.length > 0 ? (
                                        <div className="eq-el-container">
                                            <Row justify='end'>
                                                <Col className="eq-el-dateHeading">
                                                    <h5 className="eq-el-date">
                                                    {new Date().toLocaleDateString(t("dateLang"),options as any)}
                                                    </h5>

                                                    <div className="eq-el-date-icon hidden-sm">
                                                        <span>
                                                            <FontAwesomeIcon icon={faCalendar} />
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {eventData.today.map((item: any, index: number) => (
                                                <>
                                                    {renderEqCollapsePanel(item)}
                                                </>
                                            ))}
                                        </div>
                                    ) : (
                                        <Empty description={t("noTrainingsAvailable")} />
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab={
                                <span>{t("tomorrow")}{" "}
                                    <Badge
                                        count={tabCounts.tomorrow}
                                        offset={[0, -15]}
                                        className="tab-badge"
                                        size="default"
                                    >
                                    </Badge>
                                </span>} key="2">
                                <div>
                                    {eventData?.tomorrow?.length > 0 ? (
                                        <div className="eq-el-container">
                                            <Row justify='end'>
                                                <Col className="eq-el-dateHeading">
                                                    <h5 className="eq-el-date">
                                                    {addDays(new Date(), 1).toLocaleDateString(t("dateLang"), options)}
                                                    </h5>
                                                    <div className="eq-el-date-icon hidden-sm">
                                                        <span>
                                                            <FontAwesomeIcon icon={faCalendar} />
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {eventData.tomorrow.map((item: any, index: number) => (
                                                <>
                                                    {renderEqCollapsePanel(item)}
                                                </>
                                            ))}
                                        </div>
                                    ) : (
                                        <Empty description={t("noTrainingsAvailable")} />
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab={
                                <span>{t("later")}{" "}
                                    <Badge
                                        count={tabCounts.later}
                                        offset={[0, -15]}
                                        className="tab-badge"
                                        size="default"
                                    >
                                    </Badge>
                                </span>} key="3">
                                <div>

                                    {eventData?.later?.length > 0 ? (
                                        <div className="eq-el-container">
                                            <Row justify='end'>
                                                <Col className="eq-el-dateHeading">
                                                    <h5 className="eq-el-date">
                                                        {dateRange}
                                                    </h5>

                                                    <div className="eq-el-date-icon hidden-sm">
                                                        <span>
                                                            <FontAwesomeIcon icon={faCalendar} />
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {eventData.later.map((item: any, index: number) => (
                                                <>
                                                    {renderEqCollapsePanel(item)}
                                                </>
                                            ))}
                                        </div >
                                    ) : (
                                        <Empty description={t("noTrainingsAvailable")} />
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>

                    </>
                ) : (
                    <div className="eq-el-container">
                        <Empty description={t("noTrainingsAvailable")} />
                    </div>
                )}

                <>
                    <Link to={FrontEndPoints.student.allTrainings}>
                        <EqButton classNames="eq-el-btn">{t("showMore")}</EqButton>
                    </Link>
                </>

            </div>
        </>
    )
}

export default EqActivityTrainingStudentDashboard