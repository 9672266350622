import * as React from 'react';
import './eqGoogleMap.scss';
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useEffect } from 'react';
import { googleMap_API_Key } from '../../shared/Common';

const EqGoogleMap = ({ selected, containerStyle }: any) => {
    //console.log(selected);
    const [mapProps, setMapProps] = React.useState() as any;
    useEffect(() => {
        if (selected != null) {
            setMapProps({ center: selected, zoom: 14 });
        }
    }, [selected])
    return (
        <>
            <div>
                {
                    
                    <GoogleMap {...mapProps} mapContainerStyle={containerStyle}>
                        {selected &&
                            <Marker position={selected}></Marker>
                        }
                    </GoogleMap>
                }
            </div >
        </>
    )
}
export default EqGoogleMap;

