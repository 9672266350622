import * as React from "react";
import "./EqCreateOrEditPublicEvent.scss";
import { Row, Col, Input } from "antd";
import { useTranslation } from "react-i18next";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsStudent,
} from "../../shared/Common";
import EqToggle, { IEqToggle } from "../../custom/eqToggle/EqToggle";
import Api from "../../shared/Api";
import { IStudentGroupDto } from "../../shared/interfaces/IStudentGroupDto";
import { EqCurrency, EqDisciplineType, EqEventAccessType } from "../../shared/enums";

import { useAuthState } from "../../context/Context";
import { ArrowLeftOutlined, StepBackwardOutlined } from "@ant-design/icons";
import OCreateOrEditEvent from "../createActivity/ocreateOrEditEvent/OCreateOrEditEvent";
import CreateOrEditEvent from "../createActivity/createOrEditEvent/CreateOrEditEvent";

export interface IEqCreateOrEditPublicEvent {
  event?: any;
  areas?: any;
  weekDays?: any;
  trainerStudents?: any;
  studentHorsesList?: any;

  population?: any;
  groupPopulation?: any;

  imagesData?: any;
  onCreateOrUpdateActivity?: any;
  onCancelActivity?: any;
}
const EqCreateOrEditPublicEvent = ({
  areas,
  weekDays,
  event,
  trainerStudents,
  studentHorsesList,

  population,
  groupPopulation,
  imagesData,
  onCreateOrUpdateActivity,
  onCancelActivity,
}: IEqCreateOrEditPublicEvent) => {
  const { TextArea } = Input;
  const gutter = { xs: 8, sm: 16, md: 24, lg: 32 };
  const userDetails: any = useAuthState();
  const isStudent: boolean = ValidateIsStudent(userDetails);
  const [activityTitle, setActivityTitle] = React.useState("");
  const [showBackButton, setShowBackButton] = React.useState(false);
  const [isTrainingSaved, setIsTrainingSaved] = React.useState(false);

  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { t } = useTranslation();
  const [value, setValue] = React.useState(1);

  // const [population, setPopulation] = React.useState([]) as any;
  // const [groupPopulation, setGroupPopulation] = React.useState([]) as any;

  const [eventTypes, setEventTypes] = React.useState({
    privateDiscipline: [],
    publicDiscipline: [],
    editOpenDiscipline: [],
    toggleOptions: [],
  } as any);
  const [currencyOptions, setCurrencyOptions] = React.useState([] as any);

  const [eventAccessTypes, setEventAccessTypes] = React.useState({
    allAccessTypes: [],
    privateOnly: [],
    toggleOptions: [],
  } as any);
  const [eventKey, setEventKey] = React.useState(0);

  const [isPublic, setIsPublic] = React.useState() as any;
  const [isOpenEvent, setIsOpenEvent] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isPaymentDeadline, setIsPaymentDeadline] = React.useState(true);
  //for private event

  const [formData, setFormData] = React.useState({}) as any;

  const setEditFormData = (data: any) => {
    if (data != undefined) {
      return {
        accessType: data.accessType as EqEventAccessType,
      };
    } else {
      return {
        accessType: EqEventAccessType.Private + "",
      };
    }
  };

  /////////////

  const setEventDefaults = () => {
    setFormData({
      accessType: EqEventAccessType.Private + "",
    });

    setEventAccessAndDisciplineTypes(
      false,
      EqEventAccessType.Private,
      EqDisciplineType.Dressage
    );
    setIsPublic(false);
    setIsPaymentDeadline(true);
    setIsOpenEvent(false);
  };

  const createPublicEventTypes = () => {
    var publicTypes = [];

    for (var x in EqDisciplineType) {
      if (isNaN(Number(x)) == false && Number(x) != EqDisciplineType.Open) {
        publicTypes.push({ key: Number(x), value: EqDisciplineType[x] });
      }
    }

    setEventTypes({
      privateDiscipline: [],
      publicDiscipline: [...publicTypes],
      editOpenDiscipline: [],
      toggleOptions: [...publicTypes],
    });
  };

  const createEventTypes = () => {
    var privatetypes = [];
    var editPrivatTypes = [];
    var publictypes = [];

    if (isEdit == false) {
      privatetypes.push({
        key: EqDisciplineType.Dressage,
        value: EqDisciplineType[EqDisciplineType.Dressage],
      });
      privatetypes.push({
        key: EqDisciplineType.Jumping,
        value: EqDisciplineType[EqDisciplineType.Jumping],
      });
      privatetypes.push({
        key: EqDisciplineType.Open,
        value: EqDisciplineType[EqDisciplineType.Open],
      });
    } else {
      privatetypes.push({
        key: EqDisciplineType.Dressage,
        value: EqDisciplineType[EqDisciplineType.Dressage],
      });
      privatetypes.push({
        key: EqDisciplineType.Jumping,
        value: EqDisciplineType[EqDisciplineType.Jumping],
      });
    }

    editPrivatTypes.push({
      key: EqDisciplineType.Open,
      value: EqDisciplineType[EqDisciplineType.Open],
    });

    for (var x in EqDisciplineType) {
      if (isNaN(Number(x)) == false && Number(x) != EqDisciplineType.Open) {
        publictypes.push({ key: Number(x), value: EqDisciplineType[x] });
      }
    }
    // console.log(formData.accessType);
    if (isEdit == true) {
      if (formData.accessType == EqEventAccessType.Private) {
        setEventTypes({
          privateDiscipline: [...privatetypes],
          publicDiscipline: [...publictypes],
          editOpenDiscipline: [...editPrivatTypes],
          toggleOptions: [...privatetypes],
        });
      } else {
        setEventTypes({
          privateDiscipline: [...privatetypes],
          publicDiscipline: [...publictypes],
          editOpenDiscipline: [...editPrivatTypes],
          toggleOptions: [...publictypes],
        });
      }
    } else {
      setEventTypes({
        privateDiscipline: [...privatetypes],
        publicDiscipline: [...publictypes],
        editOpenDiscipline: [...editPrivatTypes],
        toggleOptions: [...privatetypes],
      });
    }
  };

  const createEventAccessTypes = () => {
    var accessTypes = [];
    var pAccess = [];
    for (var x in EqEventAccessType) {
      let label;
      if (x == EqEventAccessType.Private.toString()) {
        label = "Training";
      } else if (x == EqEventAccessType.Public.toString()) {
        label = "Event";
      }
      if (isNaN(Number(x)) == false) {
        accessTypes.push({ key: x, value: label });

        if (Number(x) == EqEventAccessType.Private) {
          pAccess.push({ key: Number(x), value: label });
        }
      }
    }
    // console.log(formData?.eventType);
    if (isEdit == true) {
      if (formData?.eventType == EqDisciplineType.Open) {
        setEventAccessTypes({
          allAccessTypes: [...accessTypes],
          privateOnly: [...pAccess],
          toggleOptions: [...pAccess],
        });
      } else {
        setEventAccessTypes({
          allAccessTypes: [...accessTypes],
          privateOnly: [...pAccess],
          toggleOptions: [...accessTypes],
        });
      }
    } else {
      setEventAccessTypes({
        allAccessTypes: [...accessTypes],
        privateOnly: [...pAccess],
        toggleOptions: [...accessTypes],
      });
    }
  };

  const sortAscending = (unsorted: Array<IStudentGroupDto>) => {
    const sorted = [...unsorted].sort((obj1, obj2) => {
      if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() >
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return 1;
      } else if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() <
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    //console.log(sorted);
    return sorted;
  };

  ////////////////

  const accessTogglerProps: IEqToggle = {
    items: eventAccessTypes.toggleOptions,
    defaultSelectedValue: formData?.accessType,
    classNames: "eq-toggle-orange-border eq-toggle-create-event",
    changeFunc: (value: string) => {
      let type: number = +value;
      setFormData({ ...formData, accessType: type });
      if (type == EqEventAccessType.Private) {
        setIsPublic(false);
        var privateEventTypes = [...eventTypes.privateDiscipline];
        console.log(eventTypes);
        setEventTypes({ ...eventTypes, toggleOptions: privateEventTypes });
      } else {
        if (imagesData.length == 0) {
          // getImages();
        }
        setIsPublic(true);
        var publicEventTypes = [...eventTypes.publicDiscipline];
        console.log(eventTypes);
        setEventTypes({ ...eventTypes, toggleOptions: publicEventTypes });
      }
    },
  };

  const GenerateEventAccessAndDisciplineTypes = (
    isEdit: boolean,
    accessType: EqEventAccessType,
    eventType: EqDisciplineType
  ) => {
    let allAccessTypes = [];
    let privateAccessTypes = [];
    let privateOpenEventType = [];
    let privateEventTypes = [];
    let publicEventTypes = [];
    createEventAccessTypes();
    // createEventTypes();
    createPublicEventTypes();

    //console.log(eventAccessTypes);
    //console.log(eventTypes);

    for (var x in EqEventAccessType) {
      let label;
      if (x == EqEventAccessType.Private.toString()) {
        label = "Training";
      } else if (x == EqEventAccessType.Public.toString()) {
        label = "Event";
      }
      if (isNaN(Number(x)) == false) {
        allAccessTypes.push({ key: x, value: label });

        if (Number(x) == EqEventAccessType.Private) {
          privateAccessTypes.push({ key: Number(x), value: label });
        }
      }
    }

    privateEventTypes.push({
      key: EqDisciplineType.Dressage,
      value: EqDisciplineType[EqDisciplineType.Dressage],
    });
    privateEventTypes.push({
      key: EqDisciplineType.Jumping,
      value: EqDisciplineType[EqDisciplineType.Jumping],
    });
    privateEventTypes.push({
      key: EqDisciplineType.Open,
      value: EqDisciplineType[EqDisciplineType.Open],
    });

    privateOpenEventType.push({
      key: EqDisciplineType.Open,
      value: EqDisciplineType[EqDisciplineType.Open],
    });

    for (var x in EqDisciplineType) {
      if (isNaN(Number(x)) == false && Number(x) != EqDisciplineType.Open) {
        publicEventTypes.push({ key: Number(x), value: EqDisciplineType[x] });
      }
    }

    setIsOpenEvent(false);
    if (isEdit) {
      if (
        accessType == EqEventAccessType.Private &&
        eventType == EqDisciplineType.Open
      ) {
        setIsOpenEvent(true);
        setIsPublic(false);
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...privateAccessTypes],
        });
        //setEventTypes({ ...eventTypes, toggleOptions: [...privateOpenEventType] });
        setEventTypes({
          privateDiscipline: [...privateEventTypes],
          publicDiscipline: [...publicEventTypes],
          editOpenDiscipline: [...privateOpenEventType],
          toggleOptions: [...privateOpenEventType],
        });
      } else if (accessType == EqEventAccessType.Private) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        //setEventTypes({ ...eventTypes, toggleOptions: [...privateEventTypes] });
        setEventTypes({
          privateDiscipline: [...privateEventTypes],
          publicDiscipline: [...publicEventTypes],
          editOpenDiscipline: [...privateOpenEventType],
          toggleOptions: [...privateEventTypes],
        });
        setIsPublic(false);
      } else {
        setIsPublic(true);
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        //setEventTypes({ ...eventTypes, toggleOptions: [...publicEventTypes] });
        setEventTypes({
          privateDiscipline: [...privateEventTypes],
          publicDiscipline: [...publicEventTypes],
          editOpenDiscipline: [...privateOpenEventType],
          toggleOptions: [...publicEventTypes],
        });
      }
    } else {
      if (accessType == EqEventAccessType.Private) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        setEventTypes({ ...eventTypes, toggleOptions: [...privateEventTypes] });
      } else if (accessType == EqEventAccessType.Public) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        setEventTypes({ ...eventTypes, toggleOptions: [...publicEventTypes] });
      }
    }
  };

  const setEventAccessAndDisciplineTypes = (
    isEdit: boolean,
    accessType: EqEventAccessType,
    eventType: EqDisciplineType
  ) => {
    let allAccessTypes = [...eventAccessTypes.allAccessTypes];
    let privateAccessTypes = [...eventAccessTypes.privateOnly];
    let privateOpenEventType = [...eventTypes.editOpenDiscipline];
    let privateEventTypes = [...eventTypes.privateDiscipline];
    let publicEventTypes = [...eventTypes.publicDiscipline];

    if (isEdit) {
      if (
        accessType == EqEventAccessType.Private &&
        eventType == EqDisciplineType.Open
      ) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...privateAccessTypes],
        });
        setEventTypes({
          ...eventTypes,
          toggleOptions: [...privateOpenEventType],
        });
      } else if (accessType == EqEventAccessType.Private) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        setEventTypes({ ...eventTypes, toggleOptions: [...privateEventTypes] });
      } else {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        setEventTypes({ ...eventTypes, toggleOptions: [...publicEventTypes] });
      }
    } else {
      if (accessType == EqEventAccessType.Private) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        setEventTypes({ ...eventTypes, toggleOptions: [...privateEventTypes] });
      } else if (accessType == EqEventAccessType.Public) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        setEventTypes({ ...eventTypes, toggleOptions: [...publicEventTypes] });
      }
    }
  };
  const createCurrencyOptions = () => {
    var types = [];
    for (var x in EqCurrency) {
      if (isNaN(Number(x)) == false) {
        types.push({ key: Number(x), value: EqCurrency[x] });
      }
    }
    setCurrencyOptions(types);

  };
  React.useEffect(() => {
    // getPopulation();
    createCurrencyOptions()
    if (population) {
      sortAscending(population);
    }

    if (event != undefined) {
      setIsEdit(true);
      setActivityTitle(event.title);

      if (event.accessType === 1) {
        setIsPublic(false);
      } else {
        setIsPublic(true);
      }
      setEventKey(event.key);
      GenerateEventAccessAndDisciplineTypes(
        true,
        event.accessType,
        event.disciplineType
      );
      var formObj = setEditFormData(event);
      setFormData(formObj);
    } else {
      setIsEdit(false);
      setEventDefaults();
      setEventKey(0);
      // createEventTypes();
      createPublicEventTypes();
      createEventAccessTypes();
    }
  }, [areas, event, population]);

  //console.log("Data", attendeeCardData);

  return (
    <div className="eq-ce-public-events-main">
      {/* <Row className="create-title">
        {isTrainingSaved && eventKey == 0 && (
          <Col className="back-icon">
            <StepBackwardOutlined span={0} onClick={()=>setShowBackButton(false)} />
            <ArrowLeftOutlined onClick={() => setIsTrainingSaved(false)} />
          </Col>
        )}
        <Col className="title-text gutter-row">
          {activityTitle === ""
            ? eventKey == 0
              ? t("Add New Event")
              : t("Edit Event")
            : activityTitle}
        </Col>
        <Col className="action-group"></Col>
      </Row> */}

      <div className="eq-create-activity-container">
        <div className="">
          <Row gutter={gutter} className="event-info-input">
            <Col className="gutter-row" span={24}>
              <CreateOrEditEvent
                onActivityTitleChange={(e: any) => {
                  setActivityTitle(e);
                }}
                imagesData={imagesData}
                areas={areas}
                weekDaysData={weekDays}
                population={population}
                groupPopulation={groupPopulation}
                trainerStudents={trainerStudents}
                studentHorsesList={studentHorsesList}
                eventTypes={eventTypes}
                eventKey={eventKey}
                eventData={event}
                onCreateOrUpdateActivity={onCreateOrUpdateActivity}
                onCancelActivity={onCancelActivity}
                isStudent={isStudent}
                onSaveSetBackButton={(e: any) => {
                  setIsTrainingSaved(e);
                }}
                isTrainingSaved={isTrainingSaved}
                currencyOptions={currencyOptions}
              />

          
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default EqCreateOrEditPublicEvent;
