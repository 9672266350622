import * as React from "react";
import { Row, Col, Popconfirm } from "antd";
import "./EqStudentEventCard.scss";
import { Tag } from "antd";
import moment from "moment";
import { IStudentGroupDto } from "../../shared/interfaces/IStudentGroupDto";
import { IEqEvent } from "../../shared/Interfaces";
import { EqDisciplineType, EventSlotStatus } from "../../shared/enums";
import EqCard from "../../custom/eqCard/EqCard";
import { useAuthState } from "../../context/Context";
import { useTranslation, Trans } from "react-i18next";

import {
  FrontEndPoints,
  ValidateIsTrainer,
  ValidateStudentCanBook,
  ValidateStudentCanPay,
  addDays,
  dateFormat,
  getDefaultImage,
  getEmailFromToken,
  imageType,
} from "../../shared/Common";
import { Link, useNavigate } from "react-router-dom";
import { loadState, saveEvent } from "../../shared/LocalStorage";
import { IUserProfileDto } from "../../shared/interfaces/IUserProfileDto";
import { IGroupDto } from "../../shared/interfaces/IGroupDto";
import { useLocation } from "react-router-dom";
import EqButton from "../../custom/eqButton/EqButton";
import EqTags from "../../custom/eqTags/EqTags";
import EqCardEvent from "../../custom/eqCardEvent/EqCardEvent";
//IEqEvent

export interface IEqStudentEventCard {
  eventClass: any;
  removeEventFunc?: any;
}
const EqStudentEventCard = ({
  eventClass,
  removeEventFunc,

  ...rest
}: IEqStudentEventCard) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = useAuthState();
  const currentUserKey = getEmailFromToken();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const [isOwner, setIsOwner] = React.useState(false);
  const [isAttendee, setIsAttendee] = React.useState(false);
  const [studentGroups, setStudentGroups] = React.useState([]) as any;
  const [isStudentTrainings, setIsStudentTrainings] = React.useState(false);
  const [showActions, setShowActions] = React.useState(false);
  const studentCanBook: boolean = ValidateStudentCanBook(userDetails);
  const studentCanPay: boolean = ValidateStudentCanPay(userDetails);
  const { t, i18n } = useTranslation();

  const [subscriptionStatus, setSubscriptionStatus] = React.useState({
    buttonDisabled: false,
    buttonTextSubscribe: "Join",
    buttonTextSubscribeAll: "Join all",
  } as any);
  const [isEventSubscribed, setIsEventSubscribed] = React.useState(false);
  const [isPaidByUser, setIsPaidByUser] = React.useState(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

  const checkStudentTrainings = () => {
    let pathname = location.pathname;

    if (pathname == FrontEndPoints.student.dashboard) {
      setIsStudentTrainings(true);
    } else {
      setIsStudentTrainings(false);
    }
  };
  //   const checkEventOwner = () => {
  //     var owner = event?.organizers
  //       ?.filter((o: any) => {
  //         return o.key == userDetails.key;
  //       })
  //       .shift();
  //     console.log(owner);
  //     if (owner != null || undefined) {
  //       setIsOwner(true);
  //     } else {
  //       setIsAttendee(false);
  //     }
  //   };
  //   const getEventGroups = () => {
  //     //console.log(event);
  //     var studentGroups = event?.attendees?.filter((x: any) => x.group != null);
  //     var groups = studentGroups?.map((d: any) => d.group);
  //     const result = [];
  //     const map = new Map();
  //     if (groups != null && groups.length > 0) {
  //       for (const item of groups) {
  //         if (!map.has(item.key)) {
  //           map.set(item.key, true); // set any value to Map
  //           result.push(item);
  //         }
  //       }
  //     }
  //     setStudentGroups(result);
  //   };

  //   const rowClick = () => {
  //     saveEvent(event);
  //     navigate(`${FrontEndPoints.student.viewEvent}?key=${event.key}`, {
  //       state: { event: event },
  //     });
  //   };

  //   const checkEventAttendee = () => {
  //     var attendee = event?.attendees
  //       ?.filter((a: any) => {
  //         return a.user.key == userDetails.key;
  //       })
  //       .shift();
  //     //console.log(attendee);
  //     if (attendee != null || undefined) {
  //       setIsAttendee(true);
  //     } else {
  //       setIsAttendee(false);
  //     }
  //   };

  const setupEvent = (evt: any) => {
    // console.log(evt, evt?.attendees?.length, evt.maxAvailableSpace);

    // replacing userKey with Key
    if (evt?.attendees?.some((item: any) => item.key == currentUserKey)) {
      if (
        evt?.attendees?.some(
          (item: any) => item.key == currentUserKey && item.userStatus != 1
        )
      ) {
        setIsEventSubscribed(false);
        setSubscriptionStatus({
          ...subscriptionStatus,
          buttonDisabled: false,
          buttonTextSubscribe: t("Join"),
        });
      } else {
        setIsEventSubscribed(true);
        setSubscriptionStatus({
          ...subscriptionStatus,
          buttonDisabled: true,
          buttonTextSubscribe: t("Joined"),
        });

        if (
          evt?.payments?.some((item: any) => item.user?.email == currentUserKey)
        ) {
          setIsPaidByUser(true);
        }
      }
    } else if (
      evt?.maxAvailableSpace &&
      evt?.maxAvailableSpace > 0 &&
      evt?.attendees &&
      evt?.attendees?.length > 0 &&
      evt?.attendees?.length > evt.maxAvailableSpace
    ) {
      setSubscriptionStatus({
        ...subscriptionStatus,
        buttonDisabled: true,
        buttonTextSubscribe: t("Join"),
      });
    } else {
      setSubscriptionStatus({
        ...subscriptionStatus,
        buttonDisabled: false,
        buttonTextSubscribe: t("Join"),
      });
    }
  };

  const subscribeTraining = (e: any) => {
    e.stopPropagation();
  };

  const removeAttendee = (eventArgs: any) => {
    eventArgs.stopPropagation();
    // removeAttendeeFunc(event);
  };
  const dateTomorrow = () => {
    var result = addDays(new Date(), 1).getTime();
    return result;
  };
  React.useEffect(() => {
    // checkEventOwner();
    // getEventGroups();
    // checkEventAttendee();
    checkStudentTrainings();

    setupEvent(eventClass.event);
    // console.log(event);
  }, [eventClass]);
  return (
    <>
      <div
        className="eq-card-container"
        // onClick={rowClick}
      >
        <EqCardEvent
          key={1}
          alt={eventClass.title}
          type={eventClass.event.disciplineType}
          description={eventClass.event.description}
          price={eventClass.event.price}
          trainer={eventClass.event.organizers}
          stutents={eventClass.event.attendees}
          startTime={eventClass.event.startDate}
          endTime={eventClass.event.endDate}
          currency={eventClass.event.currency}
          duration={eventClass.event.duration}
          imgSrc={
            eventClass.event.eqImage
              ? eventClass.event.eqImage.url
              : getDefaultImage(imageType.events)
          }
          title={
            eventClass.event.title ||
            EqDisciplineType[eventClass.event.disciplineType]
          }
          date={
            <>
              {moment(eventClass.event.startTime).format("hh:mm A")} -{" "}
              {moment(eventClass.event.startTime)
                .minutes(eventClass.event.duration)
                .format("hh:mm A")}
            </>
          }
          actionsList={[
            eventClass.slotStatus == EventSlotStatus.Booked ? (
              <EqButton disabled>Joined</EqButton>
            ) : eventClass.slotStatus == EventSlotStatus.TrainerCancelled ? (
              <EqButton disabled>Cancelled</EqButton>
            ) : (
              <div className="right">
                <form
                  action="/StudentEvent/CreateCheckoutSessionClass"
                  method="POST"
                >
                  <input type="hidden" name="classId" value={eventClass.id} />
                  <input
                    type="hidden"
                    name="token"
                    value={loadState()?.accessToken}
                  />
                  <EqButton htmlType="submit">Pay Now</EqButton>
                </form>
              </div>
            ),
          ]}
        ></EqCardEvent>
      </div>
    </>
  );
};
export default EqStudentEventCard;
