import React, { useState } from "react";
import "./EqSubscriptionSetting.scss";

import { Button, Col, Divider, Row, Space } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import EqSubscriptionSettingForm from "./eqSubscriptionSettingForm/EqSubscriptionSettingForm";
import Api from "../../shared/Api";
import { ApiEndpoints, openNotificationWithIcon } from "../../shared/Common";
import { ISubscriptionSetting } from "../../shared/interfaces/subsctiption/ISubscriptionSetting";
import EqSubscriptionSettingTable from "./eqSubscriptionSettingTable/EqSubscriptionSettingTable";
import { EqProfessionType } from "../../shared/enums";
// import EqSubscriptionSettingTable from "./eqSubscriptionSettingTable/EqSubscriptionSettingTable";

const EqSubscriptionSetting = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState<ISubscriptionSetting | undefined>(undefined);
  const [settings, setSettings] = useState<ISubscriptionSetting[]>([]); // Fetch settings from your API
  const [subscriptionProducts, setSubscriptionProducts] = useState([]) as any; // Fetch products from your API

  const [professionalList, setProfessionalList] = React.useState([] as any);
  const [professionalOptions, setProfessionalOptions] = React.useState({
    trainerOptions: [],
    eventHolderOptions: [],
    schoolOptions: [],
  } as any);

  React.useEffect(() => {
    GetSubscriptionProducts();
    GetSubscriptionSettings();
  }, []);


  const GetAllProfessionals = () => {
    Api.get(ApiEndpoints.ProfessionalUser).then(
      (response) => {
        var professionalsFromDB = [] as any;
    
        var eventHolderOptions = [] as any;
        var schoolsOptions = [] as any;
        var trainerOptions = [] as any;

        if (response && response.status == 200) {
          response.data?.map((d: any) => {
            console.log(d);
            let obj = {
              key: d.key,
              value: d.firstName ? d.firstName + " " + d.lastName : d.email,
            };
            if (d.professionType === EqProfessionType.Trainer) {
              trainerOptions.push(obj);
            } else if (d.professionType === EqProfessionType.EventHolder) {
              eventHolderOptions.push(obj);
            } else {
              schoolsOptions.push(obj);
            }

            // let professional: IProfileDto = d;
            // professional.display = false;
            // professional.name = d.firstName
            //   ? d.firstName
            //   : "" + " " + d.lastName
            //   ? d.lastName
            //   : "" || d.userName;

            // professionalsFromDB.push(professional);
          });

          setProfessionalOptions({
            trainerOptions: trainerOptions,
            eventHolderOptions: eventHolderOptions,
            schoolsOptions: schoolsOptions,
          });
          console.log(professionalsFromDB);
          setProfessionalList(professionalsFromDB);
        } else {
          //setMessageString('');
        }
      },
      (err) => {
        //setMessageString('');
      }
    );
  };


  const GetSubscriptionProducts = () => {
    Api.get("SubscriptionProduct").then(
      (response) => {
        if (response && response.status == 200) {
          console.log(response);
          setSubscriptionProducts(response.data);
        }
      },
      (error) => {
        throw error;
      }
    );
  };

  const GetSubscriptionSettings = () => {
    Api.get("SubscriptionSetting").then(
      (response) => {
        if (response && response.status == 200) {
          console.log(response);
          setSettings(response.data);
        }
      },
      (error) => {
        throw error;
      }
    );
  };

  const handleCreateSubscriptionSetting = (data: any) => {
 

    Api.post("SubscriptionSetting", data).then(
      (result) => {
        console.log(result);
        setFormVisible(false);
        addItem(result.data);
      },
      (error) => {
        setFormVisible(false);
        if (error.response && error.response.status === 409) {
          // Conflict error
          openNotificationWithIcon(
            "Conflict",
            "A similar subscription setting already exists."
          );
        } else {
          // Other errors
          openNotificationWithIcon(
            "Server error",
            "Error occurred while creating. Please try again or contact the administrator."
          );
        }
        // throw error;
      }
    );
  };
  const addItem = (product: any) => {
    const products = [...settings];
    products.push(product);
    setSettings(products);
  };


  const handleEdit = (setting: ISubscriptionSetting) => {
    setSelectedSetting(setting);
    setFormVisible(true);
  };

  const handleUpdateSubscriptionSetting = (e: any) => {
    let data = e;

    Api.put("SubscriptionSetting", data).then(
      (result) => {
        console.log(result);
        setFormVisible(false);
        updateItem(result.data);
      },
      (error) => {
        setFormVisible(false);
        if (error.response && error.response.status === 409) {
          // Conflict error
          openNotificationWithIcon(
            "Conflict",
            "A similar subscription setting already exists."
          );
        } else {
          // Other errors
          openNotificationWithIcon(
            "Server error",
            "Error occurred while creating. Please try again or contact the administrator."
          );
        }
      }
    );
  };
  const updateItem = (updatedSetting: ISubscriptionSetting) => {
    const updatedList = settings.map((item) =>
      item.id === updatedSetting.id ? updatedSetting : item
    );
    setSettings(updatedList);
  };


  const handleDelete = (subscriptionSettingId: any) => {
    Api.delete("SubscriptionSetting" + `/${subscriptionSettingId}`).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(subscriptionSettingId);
        } else {
          // error
          // 404 means not found
        }
      },
      (error) => {
        if (error.response && error.response.status === 409) {
          // Conflict error
          openNotificationWithIcon(
            "Conflict",
            "Subscription Settings is already in used in Subscription"
          );
        } else {
          // Other errors
          openNotificationWithIcon(
            "Server error",
            "Error occurred while Deleting. Please try again or contact the administrator."
          );
        }
      }
    );
  };

  const removeItem = (subscriptionSettingId: any) => {
    if (settings != undefined) {
      var updatedList =
      settings != undefined
          ? settings.filter((item: any) => item.id !== subscriptionSettingId)
          : new Array<any>();
      // setProductList(updatedList);
      setSettings(updatedList);
    }
  };


  return (
    <div className="eq-subscription-product">
      <Row gutter={[10, 10]}>
        <Col
          sm={{ span: 22, offset: 1 }}
          md={{ span: 22, offset: 1 }}
          lg={{ span: 20, offset: 2 }}
        >
          <Row>
            <Col span={24}>
              <h2 className="eq-heading-row"> Subscription Settings</h2>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <h4>Add Subscription Setting</h4>
            </Col>
            <Col
              style={{
                display: "flex",
                flex: "1",
              }}
            >
              <EqButton
                classNames="btn-create"
                type="button"
                onClick={() => setFormVisible(true)}
              >
                Create Setting
              </EqButton>
            </Col>
            <EqSubscriptionSettingForm
              visible={formVisible}
              onCreate={handleCreateSubscriptionSetting}
              onUpdate={handleUpdateSubscriptionSetting}
              onCancel={() => setFormVisible(false)}
              subscriptionProducts={subscriptionProducts}
              setting={selectedSetting}
            />
          </Row>
          <Divider
            dashed
            
          />

          <EqSubscriptionSettingTable
          subscriptionProducts={subscriptionProducts}
            settings={settings}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EqSubscriptionSetting;
