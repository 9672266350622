import React, { useState, useEffect } from 'react';
import './EqMessageList.scss';
import { EmailFilter } from '../../shared/enums';
import EqMessage, { IEqMessage, IEqMessageData } from '../eqMessage/EqMessage';
import { addDays, subtractDays } from '../../shared/Common';
import { Checkbox, Typography, Row, Col } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

export interface IEqMessageList {
    filter: number;
    messageList: Array<IEqMessage>;
    checkAllFun: (value: Array<IEqMessageData>) => void;
}
const EqMessageList = ({ filter, messageList, checkAllFun, ...rest }: IEqMessageList) => {

    const { Title } = Typography;
    const { t, i18n } = useTranslation();

    //day filter
    const todayMails = messageList.filter(opt => (opt.message.messageOn.toDateString() == new Date().toDateString()))
        .sort((obj1, obj2) => { if (obj1 > obj2) { return 1; } else if (obj1 < obj2) { return -1 } else { return 0 } });

    const yesterdayMails = messageList.filter(opt => opt.message.messageOn.toDateString() == subtractDays(new Date(), 1).toDateString())
        .sort((obj1, obj2) => { if (obj1 > obj2) { return 1; } else if (obj1 < obj2) { return -1 } else { return 0 } });

    const lastWeekMails = messageList.filter(opt => opt.message.messageOn < subtractDays(new Date(), 2) && opt.message.messageOn > subtractDays(new Date(), 7))
        .sort((obj1, obj2) => { if (obj1 > obj2) { return 1; } else if (obj1 < obj2) { return -1 } else { return 0 } });

    const olderMails = messageList.filter(opt => opt.message.messageOn < subtractDays(new Date(), 7))
        .sort((obj1, obj2) => { if (obj1 > obj2) { return 1; } else if (obj1 < obj2) { return -1 } else { return 0 } });

    const [olderisCheckAll, oldersetIsCheckAll] = useState(false);
    const [todayisCheckAll, todaysetIsCheckAll] = useState(false);
    const [yesterdayisCheckAll, yesterdaysetIsCheckAll] = useState(false);
    const [lastWeekisCheckAll, lastWeeksetIsCheckAll] = useState(false);
    const olderMessagesCheckAll = () => {
        oldersetIsCheckAll(!olderisCheckAll);

        olderMails.map(
            (m: IEqMessage) => {
                m.selected = !olderisCheckAll;
            }
        );
    }
    const todayMessagesCheckAll = () => {
        todaysetIsCheckAll(!todayisCheckAll);

        todayMails.map(
            (m: IEqMessage) => {
                m.selected = !todayisCheckAll;
            }
        );
    }
    const yesterdayMessagesCheckAll = () => {
        yesterdaysetIsCheckAll(!yesterdayisCheckAll);

        yesterdayMails.map(
            (m: IEqMessage) => {
                m.selected = !yesterdayisCheckAll;
            }
        );
    }
    const lastWeekMessagesCheckAll = () => {
        lastWeeksetIsCheckAll(!lastWeekisCheckAll);

        lastWeekMails.map(
            (m: IEqMessage) => {
                m.selected = !lastWeekisCheckAll;
            }
        );
    }
    return (
        <div className="eq-message-list">
            <div className="eq-messages-body">
                <div className="eq-messages-filter-time">
                    {filter === EmailFilter['day'] && todayMails.length > 0 &&
                        <>
                            <h5 className="eq-message-filter-day">TODAY</h5>
                            <Row className="eq-messages-header hidden-sm">
                                <Col md={22}>
                                    <Row>
                                    <Col xs={2} sm={2} md={1} className="" >
                                        <Checkbox onChange={todayMessagesCheckAll} checked={todayisCheckAll}></Checkbox>
                                        </Col>
                                        <Col span={3} className="" >{t("type")}</Col>
                                        <Col span={5} className="" >{t("from")}</Col>
                                        <Col flex="auto" className="" >{t("messageTitle")}</Col>
                                    </Row>
                                </Col>
                                <Col xs={4} sm={4} md={2} className="" >{t("action")}</Col>
                            </Row>
                            {todayMails.map((item: IEqMessage, index: number) =>
                                <EqMessage key={index} {...item} ></EqMessage>
                            )}
                        </>
                    }
                    {filter === EmailFilter['day'] && yesterdayMails.length > 0 &&
                        <>
                            <h5 className="eq-message-filter-day">{t("yesterday")}</h5>
                            <Row className="eq-messages-header hidden-sm">
                                <Col md={22} flex="auto">
                                    <Row>
                                    <Col xs={2} sm={2} md={1} className="" >
                                        <Checkbox onChange={yesterdayMessagesCheckAll} checked={yesterdayisCheckAll}></Checkbox>
                                        </Col>
                                        <Col span={3} className="" >{t("type")}</Col>
                                        <Col span={5} className="" >{t("from")}</Col>
                                        <Col flex="auto" className="" >{t("messageTitle")}</Col>
                                    </Row>
                                </Col>
                                <Col xs={4} sm={4} md={2} className="" >{t("action")}</Col>
                            </Row>
                            {yesterdayMails.map((item: IEqMessage, index: number) =>
                                <EqMessage key={index} {...item}></EqMessage>
                            )}
                        </>
                    }
                    {filter === EmailFilter['day'] && lastWeekMails.length > 0 &&
                        <>
                            <h5 className="eq-message-filter-day">{t("lastWeek")}</h5>
                            <Row className="eq-messages-header hidden-sm">
                                <Col md={22} flex="auto">
                                    <Row>
                                    <Col xs={2} sm={2} md={1} className="" >
                                        <Checkbox onChange={lastWeekMessagesCheckAll} checked={lastWeekisCheckAll}></Checkbox>
                                        </Col>
                                        <Col span={3} className="" >{t("type")}</Col>
                                        <Col span={5} className="" >{t("from")}</Col>
                                        <Col flex="auto" className="" >{t("messageTitle")}</Col>
                                    </Row>
                                </Col>
                                <Col xs={4} sm={4} md={2} className="" >{t("action")}</Col>
                            </Row>
                            {lastWeekMails.map((item: IEqMessage, index: number) =>
                                <EqMessage key={index} {...item}></EqMessage>
                            )}
                        </>
                    }
                    {filter === EmailFilter['day'] && olderMails.length > 0 &&
                        <>
                            <h5 className="eq-message-filter-day">{t("older")}</h5>
                            <Row className="eq-messages-header hidden-sm">
                                <Col md={22} flex="auto">
                                    <Row>
                                    <Col xs={2} sm={2} md={1} className="" >
                                        <Checkbox onChange={olderMessagesCheckAll} checked={olderisCheckAll}></Checkbox>
                                        </Col>
                                        <Col span={3} className="" >{t("type")}</Col>
                                        <Col span={5} className="" >{t("from")}</Col>
                                        <Col flex="auto" className="" >{t("messageTitle")}</Col>
                                    </Row>
                                </Col>
                                <Col xs={4} sm={4} md={2} className="" >{t("action")}</Col>
                            </Row>
                            {olderMails.map((item: IEqMessage, index: number) =>
                                <EqMessage key={index} {...item}></EqMessage>
                            )}
                        </>
                    }

                </div>
            </div>
        </div>
    );
}

export default EqMessageList;