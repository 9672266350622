import { CheckCircleFilled, CheckCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { Col, Empty, Input, List, Modal, Row, Steps } from "antd";
import React, { useState } from "react";
import EqButton from "../eqButton/EqButton";
import "./EqSubscriptionsPlan.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import {
  ValidateIsEventHolder,
  ValidateIsParent,
  ValidateIsSchool,
  ValidateIsStudent,
  ValidateIsTrainer,
} from "../../shared/Common";
import Api from "../../shared/Api";
import { EqSubscriptionRoleType, SubscriptionStatusEnum } from "../../shared/enums";
import { ISubscriptionSetting } from "../../shared/interfaces/subsctiption/ISubscriptionSetting";
import { Elements } from "@stripe/react-stripe-js";
import EqStripeSubscriptionForm from "../../eqStripSubscriptionForm/EqStripeSubscriptionForm";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import { useTranslation } from "react-i18next";

const { Step } = Steps;

const EqSubscriptionsPlan = () => {
  const stripeTestPromise = loadStripe(
    "pk_test_51MHTljJWnNjo1nE6OprHn3QFaM5siqA8GeEB1NF8MUje05lIdr6cTSLqVLCHEr3Ci3bhkrI42cZE0E9r1O71rfse00I42bPvCp"
  );

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userDetails: any = useAuthState();
  const isParent: boolean = ValidateIsParent(userDetails);
  const isStudent: boolean = ValidateIsStudent(userDetails);
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const isSchool: boolean = ValidateIsSchool(userDetails);
  const isEventHolder: boolean = ValidateIsEventHolder(userDetails);
  const [subscriptionSettings, setSubscriptionSettings] = useState<
    ISubscriptionSetting[]
  >([]); // Fetch subscription settings from API

  const [subscriptionPlans, setSubscriptionPlans] = useState([]) as any;
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState() as any;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  React.useEffect(() => {
    // GetSubscriptionSettings();
    GetUserSubscriptionSettings();
  }, []);

  const GetSubscriptionSettings = () => {
    Api.get("SubscriptionSetting").then(
      (response) => {
        if (response && response.status == 200) {
          console.log(response);
          setSubscriptionSettings(response.data);
          // create Plan
          createPlan(response.data);
        }
      },
      (error) => {
        throw error;
      }
    );
  };

  const GetUserSubscriptionSettings = () => {
    Api.get("UserSubscriptionSetting").then(
      (response) => {

        var data = [];
        if (response && response.status == 200) {
          console.log(response);
          data.push(response.data);
          setSubscriptionSettings(data);
          // create Plan
          createPlan(data);
        }
        else if (response && response.status == 204) {
          // no content
        }
      },
      (error) => {

        throw error;
      }
    );
  };

  const createPlan = (settings: any) => {
    let subscriptionPlans: any = [];
    if (isStudent) {
      // let studentSettings = settings.filter(
      //   (opt: any) => opt.roleType === EqSubscriptionRoleType.Student
      // );

      subscriptionPlans = settings.map((ss: any) => {
        let isSubscribed = ss.subscription ? (ss.subscription.status == SubscriptionStatusEnum.Active ? true : false) : false;

        return {
          title: ss.title ? ss.title : "Default",
          startDate: isSubscribed ? moment(ss?.subscription?.date).format("DD/MM/YYYY") : "",
          id: ss.id,
          price: ss.subscriptionProduct ? ss.subscriptionProduct.price : 0,
          description: t("allTheBasicFeatures"),
          benefits: [
            t("fullAccessToViewSchoolTraining"),
            t("fullAccessToViewEvents"),
            t("fullAccessToBuyPublicProducts"),
            t("registrationForEvents"),
          ],
          isSubscribed: isSubscribed

        };
      });
    } else if (isTrainer) {
      // let studentSettings = settings.filter(
      //   (opt: any) => opt.roleType === EqSubscriptionRoleType.Trainer
      // );

      subscriptionPlans = settings.map((ss: any) => {
        let isSubscribed = ss.subscription ? (ss.subscription.status == SubscriptionStatusEnum.Active ? true : false) : false;

        return {
          title: ss.title ? ss.title : t("default"),
          startDate: isSubscribed ? moment(ss?.subscription?.date).format("DD/MM/YYYY") : "",

          id: ss.id,
          price: ss.subscriptionProduct ? ss.subscriptionProduct.price : 0,
          description:
            t("allBasicFeaturesForTrainer"),
          benefits: [
            t("fullAccessToCreateEvents"),
            t("fullAccessToCreateProducts"),
            // `Up to ${ss.minCapacity} - ${ss.maxCapacity} students`,
            t("inviteStudents"),
            t("allOtherExistingFeatures"),

          ],
          isSubscribed: isSubscribed
        };
      });
    } else if (isSchool) {

      // let studentSettings = settings.filter(
      //   (opt: any) => opt.roleType === EqSubscriptionRoleType.School
      // );

      subscriptionPlans = settings.map((ss: any) => {
        let isSubscribed = ss.subscription ? (ss.subscription.status == SubscriptionStatusEnum.Active ? true : false) : false;

        return {

          id: ss.id,
          title: ss.title ? ss.title : t("default"),
          startDate: isSubscribed ? moment(ss?.subscription?.date).format("DD/MM/YYYY") : "",

          price: ss.subscriptionProduct ? ss.subscriptionProduct.price : 0,

          description:
            t("allBasicFeaturesForSchool"),
          benefits: [
            t("fullAccessToCreateEvents"),
            t("fullAccessToCreateProducts"),
            t("fullAccessToCreateRidingCourses"),

            t("allOtherExistingFeatures"),
          ],
          isSubscribed: isSubscribed

        };
      });
    }

    setSubscriptionPlans(subscriptionPlans);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showSuccessModal = () => {
    setIsSuccessModalVisible(true);
  };

  const hideSuccessModal = () => {
    setIsSuccessModalVisible(false);
  };

  const handleConfirm = () => {
    if (currentStep < 2) {
      nextStep(); // Move to the next step
    } else {
      showSuccessModal(); // Display the confirmation modal on the last step
    }
  };

  const handleBackToMain = () => {
    GetUserSubscriptionSettings();
    setCurrentStep(0);
  };
  const handlePlanSelect = (plan: any, moveToNextStep = false) => {
    setSelectedPlan(plan);

    if (moveToNextStep) {
      if (currentStep < 2) {
        setCurrentStep(currentStep + 1);
      } else {
        // Set current step to a "Confirm" step when on the last step
        setCurrentStep(3);
      }
    }
  };

  const nextStep = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const steps = [
    {
      title: t("selectPlan"),
      content: (
        <div className="1st-step">

          {subscriptionPlans?.map((plan: any, index: any) => (
            <>
              {plan.isSubscribed && <h3 className="subscriptions-section-heading">{t("currentSubscription")}</h3>}
              <Row className="subscriptions-plans">
                <Col key={index} className="subscriptions-plan">
                  <Row className="plan-details">
                    <h5 className="plan">{plan.title} </h5>
                    {plan.isSubscribed && <h5 className="plan-date">{t("startDate") + ": " + plan.startDate}</h5>}

                    <div className="plan-price">
                      <span className="unit">Kr.</span>
                      <span className="price">{plan.price}</span>
                      <span className="plan-span">/{t("month")}</span>
                    </div>
                  </Row>

                  <p className="plan-dec">{plan.description}</p>


                  <List className="subscription-benifits">
                    {plan.benefits.map((benefit: any, idx: any) => (
                      <div key={idx} className="plan-list">
                        <CheckCircleFilled className="icon" />
                        {benefit}
                      </div>
                    ))}
                  </List>
                  <Row
                    className={`subscription-btn subscription-btn${plan.title === "Free" ? " free-plan-btn" : ""
                      }`}
                  >

                    {plan.isSubscribed
                      ? (
                        <>
                          <div className="subscription-btn-paid">
                          <CheckOutlined /> {t("paid")}
                          </div>
                        </>
                      ) : (
                        <>
                          <EqButton
                            className=""
                            onClick={() => handlePlanSelect(plan, true)}
                          >
                            {t("getPlan")}
                          </EqButton>
                        </>
                      )}
                    {/* <EqButton
                      className=""
                      onClick={() => handlePlanSelect(plan, true)}
                      disabled={plan.isSubscribed}
                    >
                      {plan.isSubscribed ? t("joined") : t("getPlan")}
                    </EqButton> */}
                  </Row>
                  <div className="stripe-powerBy">{t("serviceProvidedBy")}
                    <img className="stripe-img" src="icons/stripe.png" />
                  </div>
                </Col>
              </Row>
              {plan.isSubscribed && (
                <Row className="subscription-more-info">

                  <Col className="subscription-cancel">{t("contactAdministrationToCancel")}{(" ")}
                    <span className="cancel-email">
                      <a href="mailto:Sophie@equinet.no">Sophie@equinet.no</a>
                    </span>
                  </Col>
                </Row>
              )

              }
            </>
          ))}

        </div>
      ),
    },
    {
      title: t("addInformation"),
      content: (
        <div className="2nd-step">
          <h3 className="section-heading">{t("addInformation")}</h3>
          {/* <Row gutter={[40, 24]} className="Card-details"> */}
          <Elements stripe={stripeTestPromise}>
            <EqStripeSubscriptionForm
              selectedPlan={selectedPlan}
              isEdit={false}
              onCreate={() => {
                handleConfirm();
              }}
            // onCancel={() => setAddStripCard(false)}
            ></EqStripeSubscriptionForm>
          </Elements>
          {/* </Row> */}
        </div>
      ),
    },
    {
      title: t("reviewSummary"),
      content: (
        <div className="3rd-step">
          <h4 className="section-heading">{t("thankYou")}</h4>
          <p>
            {" "}
            {t("yourPaymentHasBeenSuccessfullyProcessedAndConfirmed")}
          </p>

          <h3 className="section-heading">{t("reviewSummary")}</h3>
          {selectedPlan && (
            <Row className="Summary-details">
              <Col className="subscribed-plan">
                <h4 className="subscribed-plan-title">
                  {selectedPlan?.title} {t("plan")}
                </h4>
                <Row className="subscribed-plan-details">
                  {selectedPlan?.benefits.map((benefit: any, index: any) => (
                    <Col key={index} className="plan-list">
                      <CheckCircleFilled className="icon" />
                      {benefit}
                    </Col>
                  ))}
                </Row>
                <Row className="subscribed-payment">
                  <h3 className="payment">{t("bill")}</h3>
                  <div className="plan-price">
                    <span className="unit">Kr.</span>
                    <span className="price">{selectedPlan.price}</span>
                    <span className="plan-span"> /{t("month")}</span>
                  </div>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="subscriptions">
      {subscriptionSettings && subscriptionSettings.length > 0 ? (
        <>

          <Steps current={currentStep} size="small">
            {steps.map((item, index) => (
              <Step key={index} title={item.title} />
            ))}
          </Steps>
          {steps[currentStep].content}
          <div className="subscription-btns">
            {currentStep === 1 && (
              <Col className="back-btn">
                <EqButton onClick={prevStep}>{t("back")}</EqButton>
              </Col>
            )}

            {currentStep === 2 && (
              <Col className="next-btn confirm">

                <EqButton onClick={handleBackToMain}>{t("back")}</EqButton>

                {/* <EqButton onClick={handleConfirm}>Back
              {currentStep === 2 ? "Confirm" : "Next"}
            </EqButton> */}
              </Col>
            )}
          </div>

        </>
      ) :
        <Empty description={t("noSubscriptionsAvailable")} />
      }

      <Modal
        title={t("paymentPaid")}
        visible={isSuccessModalVisible}
        onOk={hideSuccessModal}
        onCancel={hideSuccessModal}
        footer={[
          <EqButton key="ok" onClick={hideSuccessModal}>
            {t("oK")}
          </EqButton>,
        ]}
      >
        {t("yourPaymentHasBeenSuccessfullyProcessedAndConfirmed")}
      </Modal>
    </div>
  );
};

export default EqSubscriptionsPlan;
