import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import './EqSentInvites.scss';
import { Checkbox, Tag } from 'antd';
import { IStudentDto } from '../../shared/interfaces/IStudentDto';
import { FrontEndPoints } from '../../shared/Common';
import history from '../../shared/history';

export interface IEqSentInvites {
    student: IStudentDto,
    deleteFunc?: any,
    checkChangeFunc?:any,
    selected?: boolean
}
const EqSentInvites = ({ student, deleteFunc, checkChangeFunc, selected }: IEqSentInvites) => {
    //const [studentData, setStudentData] = React.useState({ ...student });
    const [sent, setSent] = useState(true);
    const [check, setCheck] = useState(selected);
    function checkChange(selected: boolean) {
        setCheck(selected);
    }
    const messageClicked = () => {
        var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${student.email}`;
        history.push(out1);
    }
    const deleteClicked = (data: any) => {
        if (deleteFunc != undefined) {
            deleteFunc(student);
        }
    }
    function onChange() {
        setCheck(!check);
        checkChangeFunc(student, !check);
    }
    useEffect(() => {
        if (selected != undefined)
        checkChange(selected);
    }, [student]);
    return (
        <>
            <Row className="eq-jr-tr" justify="start" align="middle">
                <Col className="eq-jr-td gutter-row" xs={2} sm={2} md={1}>
                    <Checkbox onChange={onChange} checked={check}></Checkbox>
                </Col>
                {/*<Col className="eq-jr-td gutter-row" xs={11} sm={11} md={10} lg={10} xl={10} xxl={10}>*/}
                {/*    {name}*/}
                {/*</Col>*/}
                <Col className="eq-jr-td gutter-row" flex="auto">
                    {student?.userName}
                </Col>
                <Col className="eq-jr-td gutter-row" xs={0} sm={0} md={2}>
                    <div>
                        {/*{sent ?*/}
                        {/*    <span className="eq-action-invite"><img alt="" src="/JoiningRequest.svg" /></span> :*/}

                        {/*    <span className="eq-action-invite"><img alt="" src="/check.svg" /></span>*/}
                        {/*    }*/}
                        {/*<span className="eq-action-delete"><img alt="" src="/user-check.svg" /></span>*/}
                        <span className="eq-action-invite" onClick={messageClicked}><img alt="" src="/Invite.svg" /></span>
                        <span className="eq-action-delete" onClick={deleteClicked}><img alt="" src="/Delete.svg" /></span>
                    </div>
                </Col>
            </Row>
        </>
    );
}
export default EqSentInvites;