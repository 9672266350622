import React from "react";
import { Col, Popconfirm, Row, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faClock,
  faTag,
  faChessKnight,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";

import AsyncImage from "../../asyncImage/AsyncImage";
import {
  ApiEndpoints,
  FrontEndPoints,
  GetEventMessage,
  IsStudentUnderAge,
  ValidateIsStudent,
  ValidateIsUser,
  ValidateStudentCanBook,
  ValidateStudentCanPay,
  addDays,
  getDefaultImage,
  getEmailFromToken,
  imageType,
  openNotificationWithIcon,
} from "../../../shared/Common";
import EqButton from "../../eqButton/EqButton";
import { useAuthState } from "../../../context/Context";
import { IUserProfileDto } from "../../../shared/interfaces/IUserProfileDto";
import { loadState } from "../../../shared/LocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EqEventAttendeeStatus,
  EqEventUserType,
  EqSlotStatusEnum,
  eqNotificationTypes,
} from "../../../shared/enums";
import { ISubscribeEventDto } from "../../../shared/interfaces/ISubscribeEventDto";
import Api from "../../../shared/Api";

interface IEqCalenderEvent {
  view: { type: string };
  event: any;
  isOwner: boolean;
  handleEditEvent: (key: string) => void;
  handleJoinEvent: (key: string) => void;
  onStudentJoin: () => void;
}

const EqCalenderEvent: React.FC<IEqCalenderEvent> = ({
  view,
  event,
  isOwner,
  handleEditEvent,
  handleJoinEvent,
  onStudentJoin,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = useAuthState();
  const isLoggedInUser: boolean = ValidateIsUser(userDetails);
  const { t, i18n } = useTranslation();

  const isStudent: boolean = ValidateIsStudent(userDetails);
  const isStudentUnderAge: boolean = IsStudentUnderAge(userDetails);
  const studentCanBook: boolean = ValidateStudentCanBook(userDetails);
  const studentCanPay: boolean = ValidateStudentCanPay(userDetails);
  const currentUserKey = getEmailFromToken();

  const [organizers, setOrganizers] = React.useState(event?.organizer as any[]);
  const [subscriptionStatus, setSubscriptionStatus] = React.useState({
    buttonDisabled: false,
    buttonTextSubscribe: "Join",
  } as any);
  const [isPaidByUser, setIsPaidByUser] = React.useState(false);

  const [isLatePayment, setIsLatePayment] = React.useState(false);
  const [canCancelEvent, setCanCancelEvent] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

  //   if (view.type === 'timeGridDay') {
  const style1 = {
    color: `${event.location.color}`,
    border: `1px solid ${event.location.color}`,
    background: `${event.location.color}20`,
  }; // Define your style1 object
  const iconType1 = event.type === "training" ? faChessKnight : faCalendarDays;
  const color1 = {
    color: `${event.location.color}`,
  };
  const background = {
    background: `${event.location.color}`,
  };

  const dateTomorrow = () => {
    var result = addDays(new Date(), 1).getTime();
    return result;
  };

  //   const openNotificationWithIcon = (msg: any, desc: any, event: any) => {
  //     const key = `open${Date.now()}`;
  //     const btn = (
  //       <EqButton
  //         type="button"
  //         // onClick={() => viewEvent(event)}
  //       >
  //         Pay Now
  //       </EqButton>
  //     );
  //     notification["error"]({
  //       message: msg,
  //       btn,
  //       key,
  //       description: desc,
  //     });
  //   };

  const subscribeTraining = (e: any) => {
    e.stopPropagation();

    if (isLoggedInUser == false) {
      const params = new URLSearchParams(window.location.search);
      var key = params.get("key");
      navigate(FrontEndPoints.login, {
        state: {
          prevPath: location.pathname,
          joinTrainingKey: event.key,
          trainerKey: key,
        },
      });
      //   navigate(FrontEndPoints.login);
    } else {
      if (isStudent) {
        if (studentCanBook) {
          StudentJoinTrainingRequest(e);
        } else {
          openNotificationWithIcon(
            "Error!",
            "Add Parent to join Trainings",
            eqNotificationTypes.error
          );
        }
      } else {
        openNotificationWithIcon(
          "Error!",
          "Only Students can Join",
          eqNotificationTypes.error
        );
      }
    }
  };

  const StudentJoinTrainingRequest = (e: any) => {
    const ownerOrganizer = event.organizers.find(
      (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
    );

    let data: ISubscribeEventDto = {
      ownerKey: organizers != undefined ? ownerOrganizer.user.email : "",
      subscriberKey: currentUserKey,
      eventKey: event.key,
      slotStartTime: 0,
      slotStatus: EqSlotStatusEnum.Booked,
      eventUserType: EqEventUserType.Attendee,
    };

    Api.post(ApiEndpoints.StudentJoinTraining, data).then(
      (response) => {
        setSubscriptionStatus({
          ...subscriptionStatus,
          buttonDisabled: true,
          buttonTextSubscribe: t("Joined"),
        });

        if (response && response.data != null) {
          if (response.data.slotStatus != 3) {
            setIsPaidByUser(false);
            if (isStudentUnderAge && studentCanPay) {
              openNotificationCheckOutNotification(
                "Pay for the accepted Activity",
                "",
                event
              );
            }
          }
          //console.log(response);

          onStudentJoin();
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const CreateCheckoutSession = () => {
    var eventKey = event?.key;
    Api.post(ApiEndpoints.StudentTrainingStripeCheckout.Create, {
      key: eventKey,
    })
      .then((response) => {
        console.log(response);
        window.location.href = response.data.sessionUrl;
      })
      .catch((error) => {
        openNotificationWithIcon(
          "Error!",
          "Unable to Create checkout",
          eqNotificationTypes.error
        );
      });
  };

  const openNotificationCheckOutNotification = (
    msg: any,
    desc: any,
    event: any
  ) => {
    const btn = (
      <div className="right">
        <EqButton onClick={CreateCheckoutSession}>Pay Now</EqButton>
      </div>
    );
    notification["info"]({
      message: msg,
      btn,
      description: desc,
    });
  };
  const setupEvent = (event: any) => {
    if (!event) return;

    const { organizers, startTime, payments } = event;
    let attendees = event.trainingTimes[0].attendees;

    // Set organizers
    if (organizers) {
      setOrganizers(organizers);
    }

    const ownerOrganizer = event.organizers.find(
      (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
    );
    // Calculate time difference
    const currentDate = new Date();
    const timeDifferenceInMS = startTime - currentDate.getTime();

    if (ownerOrganizer?.cancellationDeadline) {
      const cancellationDeadlineMS =
        ownerOrganizer.cancellationDeadline * 60 * 60 * 1000; // hours in milliseconds

      if (timeDifferenceInMS > cancellationDeadlineMS) {
        // The event can be canceled
        setCanCancelEvent(true);
        console.log("You can cancel the event.");
      } else {
        // Cancellation is not allowed at this time
        console.log("Cancellation is not allowed at this time.");
        setCanCancelEvent(false);
      }
    }

    // Check if the user is an attendee

    const userAttendee = attendees?.find(
      (item: any) => item.key === currentUserKey
    );

    if (userAttendee) {
      const userStatus = userAttendee.userStatus;

      if (userStatus === EqEventAttendeeStatus.Requested) {
        setSubscriptionStatus({
          ...subscriptionStatus,
          buttonDisabled: true,
          buttonTextSubscribe: "Requested",
        });
      } else if (userStatus === EqEventAttendeeStatus.Accept) {
        const slotStatus = userAttendee.slotStatus;

        if (
          slotStatus === EqSlotStatusEnum.StudentCancelled ||
          slotStatus === EqSlotStatusEnum.TrainerCancelled
        ) {
          setSubscriptionStatus({
            ...subscriptionStatus,
            buttonDisabled: true,
            buttonTextSubscribe: "Canceled",
          });
        } else if (slotStatus === EqSlotStatusEnum.Booked) {
          // Check if the user has made a payment

          // const userPaid = payments?.some(
          //   (item: any) => item.user?.email === currentUserKey
          // );
          // if (userPaid) {
          // }
          // now with products can be joined with no payment data
          setIsPaidByUser(true);
          // setIsEventSubscribed(true);
          setSubscriptionStatus({
            ...subscriptionStatus,
            buttonDisabled: true,
            buttonTextSubscribe: t("Joined"),
          });
        } else {
          if (event.paymentDeadline < currentDate.getTime()) {
            setIsLatePayment(true);
          }
          setSubscriptionStatus({
            ...subscriptionStatus,
            buttonDisabled: false,
            buttonTextSubscribe: t("Joined"), // should be Pay Now
          });
        }
      } else {
        // setIsEventSubscribed(false);
        setSubscriptionStatus({
          ...subscriptionStatus,
          buttonDisabled: false,
          buttonTextSubscribe: t("Join"),
        });
      }
    } else if (
      event.trainingTimes[0].maxAvailableSpace > 0 &&
      event.trainingTimes[0].maxAvailableSpace > (attendees?.length || 0)
    ) {
      setSubscriptionStatus({
        ...subscriptionStatus,
        buttonDisabled: false,
        buttonTextSubscribe: t("Join"),
      });
    } else {
      setSubscriptionStatus({
        ...subscriptionStatus,
        buttonDisabled: true,
        buttonTextSubscribe: "Full",
      });
    }
  };

  React.useEffect(() => {
    console.log(event);
    if (event) {
      
      setupEvent(event);
    }
  }, [event]);

  const removeAttendee = (eventArgs: any) => {
    eventArgs.stopPropagation();
    // removeAttendeeFunc(event);
  };
  const renderActionButton = () => {
    if (subscriptionStatus.buttonTextSubscribe === t("Joined")) {
      if (!isPaidByUser) {
        return (
          <Col className="book-btn-joined" span={24}>
            <div className="right">
              <EqButton
                disabled={!studentCanPay || event.startDate < todayTime}
                onClick={CreateCheckoutSession}
              >
                Pay Now
              </EqButton>
            </div>
          </Col>
        );
      } else {
        if (canCancelEvent) {
          return (
            <Row>
              <Col className="book-btn-joined" span={18}>
                <EqButton
                  type="button"
                  onClick={subscribeTraining}
                  disabled={subscriptionStatus.buttonDisabled}
                >
                  {subscriptionStatus.buttonTextSubscribe}
                </EqButton>
              </Col>
              <Col span={4} className="eq-event-actions-trash">
                <span>
                  <Popconfirm
                    placement="bottomRight"
                    title="Are you sure you want to Cancel your Training?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={removeAttendee}
                    onCancel={(e: any) => e.stopPropagation()}
                  >
                    <img alt="" src="/trash-alt-red.svg" />
                  </Popconfirm>
                </span>
              </Col>
            </Row>
          );
        } else {
          return (
            <Col span={24}>
              <EqButton
                type="button"
                onClick={subscribeTraining}
                disabled={subscriptionStatus.buttonDisabled}
              >
                {subscriptionStatus.buttonTextSubscribe}
              </EqButton>
            </Col>
          );
        }
      }
    } else if (subscriptionStatus.buttonTextSubscribe === t("Join")) {
      return (
        <EqButton
          type="button"
          onClick={subscribeTraining}
          disabled={event.startDate < todayTime}
        >
          {subscriptionStatus.buttonTextSubscribe}
        </EqButton>
      );
    } else {
      return (
        <Col span={24}>
          <EqButton
            type="button"
            onClick={subscribeTraining}
            disabled={subscriptionStatus.buttonDisabled}
          >
            {subscriptionStatus.buttonTextSubscribe}
          </EqButton>
        </Col>
      );
    }
  };

  return (
    <div className="my-event timeGridDay-event" style={style1}>
      <div className="img-container">
        <div className="image-size">
          <AsyncImage
            className="imge-training"
            src={event.eqImage?.url || getDefaultImage(imageType.training)}
          />
        </div>
      </div>
      <div className="training-info">
        <Row className="training-row">
          <Col xs={24} sm={12} md={12}>
            <Row className="content-row">
              <Col className="icon">
                <FontAwesomeIcon icon={iconType1} style={color1} />
              </Col>
              <Col>
                <div className="training-content">
                  <span className="heading" style={color1}>
                    Training:
                  </span>{" "}
                  <span className="info" style={color1}>
                    {event.title}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Row className="content-row">
              <Col className="icon">
                <FontAwesomeIcon icon={faLocationDot} style={color1} />
              </Col>
              <Col>
                <div className="training-content">
                  <span className="heading" style={color1}>
                    Area:
                  </span>{" "}
                  <span className="info" style={color1}>
                    {event.location?.city}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Row className="content-row">
              <Col className="icon">
                <FontAwesomeIcon icon={faClock} style={color1} />
              </Col>
              <Col>
                <div className="training-content">
                  <span className="heading" style={color1}>
                    Time:
                  </span>{" "}
                  <span className="info info-time" style={color1}>
                    At {moment(event.start).format("h:mm a")}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Row className="content-row">
              <Col className="icon">
                <FontAwesomeIcon icon={faTag} style={color1} />
              </Col>
              <Col>
                <div className="training-content">
                  <span className="heading" style={color1}>
                    Price:
                  </span>{" "}
                  <span className="info" style={color1}>
                    Kr. {`${event.price.toFixed(2)}`}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="book-btn">
        {isOwner ? (
          <>
            <EqButton
              className="btn bordered"
              onClick={() => handleEditEvent(event.key)}
            >
              Edit
            </EqButton>
          </>
        ) : isStudent ? (
          // event && <></>
          event && renderActionButton()
        ) : (
          <>
            {" "}
            <EqButton
              type="button"
              onClick={subscribeTraining}
              disabled={subscriptionStatus.buttonDisabled}
            >
              {subscriptionStatus.buttonTextSubscribe}
            </EqButton>
          </>
        )}
      </div>
    </div>
  );
};

export default EqCalenderEvent;
