import React, { useEffect, useState } from "react";
import "./EqStudentGroupsBoard.scss";
import { Row, Col, Input } from "antd";
import EqSelect from "../../custom/eqSelect/EqSelect";
import { ISelectProps } from "../../shared/Interfaces";
import EqButton from "../../custom/eqButton/EqButton";
import EqGroupList, { IEqGroupList } from "../eqGroupList/EqGroupList";
import { IEqGroup } from "../eqGroup/EqGroup";
import Api from "../../shared/Api";
import { ApiEndpoints } from "../../shared/Common";
import moment from "moment";
import { IUserGroupDto } from "../../shared/interfaces/IUserGroupDto";
import { EqLocationType, EqGroupUserType } from "../../shared/enums";
import { IEqGroupDto } from "../../shared/interfaces/IEqGroup";
import { useTranslation, Trans } from "react-i18next";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";

const EqStudentGroupsBoard = () => {
  const [messageString, setMessageString] = useState("");
  const { t, i18n } = useTranslation();
  const [delMessage, setDelMessage] = useState("");
  const [groups, setGroups] = useState({
    allGroups: [],
    filteredGroups: [],
  }) as any;
  const { Search } = Input;
  const [searchStr, setSearchStr] = useState("");
  var sortedList: Array<IEqGroup>;

  const searchClicked = (e: any) => {
    if (searchStr != "") {
      var filteredResult = groups.allGroups.filter((g: any) => {
        return g?.groupData?.groupName.includes(searchStr);
      });
      setGroups({ ...groups, filteredGroups: filteredResult });
    }
  };
  const sortAscending = (unsorted: Array<any>) => {
    try {
      const sorted = [...unsorted].sort((obj1, obj2) => {
        if (
          (obj1?.groupData?.groupName).toLowerCase() >
          (obj2?.groupData?.groupName).toLowerCase()
        ) {
          return 1;
        } else if (
          (obj1?.groupData?.groupName).toLowerCase() <
          (obj2?.groupData?.groupName).toLowerCase()
        ) {
          return -1;
        } else {
          return 0;
        }
      });
      return sorted;
    } catch {
      return unsorted;
    }
  };

  const sortDescending = (unsorted: Array<any>) => {
    try {
      const sorted = [...unsorted].sort((obj1, obj2) => {
        if (
          (obj1?.groupData?.groupName).toLowerCase() <
          (obj2?.groupData?.groupName).toLowerCase()
        ) {
          return 1;
        } else if (
          (obj1?.groupData?.groupName).toLowerCase() >
          (obj2?.groupData?.groupName).toLowerCase()
        ) {
          return -1;
        } else {
          return 0;
        }
      });
      return sorted;
    } catch {
      return unsorted;
    }
  };
  const getGroupIcon = (type: any) => {
    switch (type) {
      case 1:
        return [
          <span className="eq-btn-icon">
            <img alt="" src="/home.svg" />
          </span>,
        ];
      case 2:
        return [
          <span className="eq-btn-icon">
            <img alt="" src="/stable.svg" />
          </span>,
        ];
      case 3:
        return [
          <span className="eq-btn-icon">
            <img alt="" src="/user-friends.svg" />
          </span>,
        ];
      case 4:
        return [
          <span className="eq-btn-icon">
            <img alt="" src="/award.svg" />
          </span>,
        ];
      case 5:
        return [
          <span className="eq-btn-icon">
            <img alt="" src="/horse.svg" />
          </span>,
        ];
      default:
        return [
          <span className="eq-btn-icon">
            <img alt="" src="/user-friends.svg" />
          </span>,
        ];
    }
  };

  const delFunc = (data: IEqGroupDto) => {
    setDelMessage("");
    Api.post(ApiEndpoints.deleteUserGroup, { Key: data.groupKey }).then(
      (response) => {
        if (response && response.status == 200) {
          var e = response.data ? response.data : "";
          var msg =
            "Group cannot be deleted because of the event " +
            e.event?.title +
            " on " +
            new Date(e.event?.startDate).toDateString() +
            " at " +
            new Date(e.event?.startTime).toTimeString();
          setDelMessage(msg);
        } else if (response && response.status == 204) {
          GetGroups();
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };
  const GetGroups = () => {
    setMessageString("");

    Api.get(ApiEndpoints.GetStudentGroups).then(
      (response) => {
        if (response && response.status == 200) {
          var groups = [] as any;
          
          response.data?.map((d: any, index: number) => {
            var attendees = d.userGroups?.filter(
              (opt: any) => opt.userType == EqGroupUserType.Attendee
            );
            groups.push({
              groupData: {
                key: d.key,
                groupKey: d.key,
                groupIcon: getGroupIcon(d.type),
                groupName: d.name,
                type: d.type,
                message: d.message,
                userGroups: d.userGroups,
                numberOfMessages: d.messages?.length,
                numberOfTrainings: d.events?.length,
                sessionFrom:
                  d.events != undefined && d.events.length > 0
                    ? moment(d.events[0].startTime).format("hh:mm")
                    : "00:00",
                sessionTo:
                  d.events != undefined && d.events.length > 0
                    ? moment(d.events[0].startTime)
                      .minutes(d.events[0].duration)
                      .format("hh:mm")
                    : "00:00",
                display: index == 0 ? true : false,
                owner: d.userGroups
                  ?.filter((opt: any) => opt.userType == EqGroupUserType.Owner)
                  .shift(),
                students: d.joinedUserGroups?.map((s: any, index: number) => {
                  return {
                    email: s.user?.email,
                    firstName: s.user?.firstName,
                    lastName: s.user?.lastName,
                    userName: s.user?.userName,
                    userType: s.user?.professionType,
                    discipline: s.user?.discipline,
                    locationKey: s.user?.locationKey,
                    location: s.user?.location,
                    locations: s.user?.locations
                      ?.filter(
                        (opt: any) => opt.location?.type == EqLocationType.Home
                      )
                      .shift(),
                    payment: s.user?.payment ? s.user?.payment : "-",
                    groups: s.user?.groups,
                    selected: false,
                  };
                }),
              },
              delFun: delFunc,
            });
          });

          setGroups({ allGroups: [...groups], filteredGroups: [...groups] });
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };

  const onSortChange = (event: any) => {
    switch (event) {
      case "Sort A-Z":
        sortedList = sortAscending(groups.filteredGroups);
        setGroups({ ...groups, filteredGroups: sortedList });
        break;
      case "Sort Z-A":
        sortedList = sortDescending(groups.filteredGroups);
        setGroups({ ...groups, filteredGroups: sortedList });
        break;
      default:
        sortedList = sortAscending(groups.filteredGroups);
        setGroups({ ...groups, filteredGroups: sortedList });
    }
  };
  const sortProps: ISelectProps = {
    border: false,
    allowClear: false,
    classNames: "eq-select-sort",
    defaultSelectedValue: "Sort A-Z",
    changeFunc: onSortChange,
    options: [
      { key: "Sort A-Z", value: "Sort A-Z" },
      { key: "Sort Z-A", value: "Sort Z-A" },
    ],
  };

  const groupListData: IEqGroupList = {
    groupList: [...groups.filteredGroups],
  };

  useEffect(() => {
    GetGroups();
  }, []);
  return (
    <>
      <AnimationDiv>

        <div className="eq-gb-container">
          <Row className="eq-gb-heading-row" align="bottom">
            <Col className="eq-gb-col" flex="auto" sm={5} xs={4}>
              <h3 className="eq-gb-heading">{t("groups")}</h3>
            </Col>
            <Col className="eq-gb-col" flex="auto" sm={19} xs={20}>
              <Row className="eq-gb-student-header" justify="end" align="bottom">
                <Col className="eq-gb-student-sort hidden-sm">
                  <EqSelect {...sortProps}></EqSelect>
                </Col>
                <Col
                  className="eq-gb-student-search"
                  xs={14}
                  sm={14}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <Search
                    placeholder={t("inputSearchText")}
                    onChange={(e: any) => setSearchStr(e.target.value)}
                    onSearch={searchClicked}
                  />
                </Col>
                <Col>
                  <div className="eq-gb-student-btn">
                    <EqButton
                      type="button"
                      bordered="true"
                      classNames="btn-border-text-orange"
                    >
                      <span className="hidden-md">
                        <img alt="" src="/filter-black.svg" />
                      </span>
                      <span className="hidden-sm">{t("filters")}</span>
                    </EqButton>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="delete-message">
            <Col>{delMessage}</Col>
          </Row>
          <div>{<EqGroupList {...groupListData}></EqGroupList>}</div>
        </div>
      </AnimationDiv>

    </>
  );
};

export default EqStudentGroupsBoard;
