import * as React from 'react';
import './EqJoiningRequestList.scss'
import EqJoiningRequest, { IEqJoiningRequest } from '../eqJoiningRequest/EqJoiningRequest';
import { Row, Col, Checkbox } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { IStudentGroupDto } from '../../shared/interfaces/IStudentGroupDto';

export interface IEqJoiningRequestList {
    joiningRequestList: Array<IEqJoiningRequest>;
    checkAllFun?: any;
    checkAll?: boolean;
}

const EqJoiningRequestList = ({ joiningRequestList, checkAllFun, checkAll, ...rest }: IEqJoiningRequestList) => {
    const { t, i18n } = useTranslation();
    //const [isCheckAll, setIsCheckAll] = React.useState(checkAll);
    //const [requests, setRequests] = React.useState(joiningRequestList);
    //console.log(checkAll);
    const checkAllFunc = () => {
        //setIsCheckAll(!isCheckAll);
        checkAllFun(joiningRequestList, checkAll);
        //joiningRequestList.map(
        //    (j: IEqJoiningRequest) => {
        //        j.selected= !isCheckAll;
        //    }
        //);

    }
    const gutter = { xs: 8, sm: 16, md: 24, lg: 32 };

    //React.useEffect(() => {
    //    setIsCheckAll(checkAll);
    //}, [checkAll]);

    return (
        <>
            <div className="eq-list-container">
                <Row className="eq-jrl-row" justify="start" align="middle">
                    <Col className="eq-jrl-th  gutter-row" xs={2} sm={2} md={1} lg={1} xl={1} xxl={1}>
                        <Checkbox onChange={checkAllFunc} checked={checkAll}></Checkbox>
                    </Col>
                    <Col className="eq-jrl-th gutter-row" xs={9} sm={9} md={7} lg={7} xl={7} xxl={7}>
                        {t("name")}
                    </Col>
                    <Col className="eq-jrl-th gutter-row" xs={0} sm={0} md={7} lg={7} xl={7} xxl={7}>
                        Area
                    </Col>
                    <Col className="eq-jrl-th gutter-row" xs={9} sm={9} md={7} lg={7} xl={7} xxl={7}>{t("groups")}
                    </Col>
                    <Col className="eq-jrl-th gutter-row" xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>Actions
                    </Col>
                </Row>
                {joiningRequestList.length > 0 &&
                    <>

                    {
                        joiningRequestList.map((item: any, index: number) =>
                                <EqJoiningRequest {...item} ></EqJoiningRequest>
                            )
                        }
                    </>
                }
            </div>
        </>
    );
}
export default EqJoiningRequestList;