// SubscriptionSettingForm.tsx

import React, { useState, useEffect, ChangeEvent } from "react";
import { Modal, Button } from "antd";

import {
  EqSubscriptionRoleType,
  SubscriptionTypeEnum,
} from "../../../shared/enums";
import { ISubscriptionSetting } from "../../../shared/interfaces/subsctiption/ISubscriptionSetting";
import { EqInput, EqInputNumber } from "../../../custom/eqInput/EqInput";
import EqSelect from "../../../custom/eqSelect/EqSelect";
import { openNotificationWithIcon } from "../../../shared/Common";

interface SubscriptionSettingFormProps {
  subscriptionProducts: any;
  visible: boolean;
  onCreate: (values: ISubscriptionSetting) => void;
  onUpdate: (values: ISubscriptionSetting) => void;
  onCancel: () => void;
  setting?: ISubscriptionSetting; // Make setting optional for creation
}

const SubscriptionSettingForm: React.FC<SubscriptionSettingFormProps> = ({
  subscriptionProducts,
  visible,
  onCreate,
  onUpdate,
  onCancel,
  setting,
}) => {
  const initialFormData: ISubscriptionSetting = {
    id: "",
    title:"",
    subscriptionProductId: null,
    transactionsFee: 0,
    minCapacity: 0,
    maxCapacity: 0,
    freeDays: 0,
    subscriptionType: SubscriptionTypeEnum.Default,
    roleType: EqSubscriptionRoleType.Student,
  };

  const [formData, setFormData] = useState<ISubscriptionSetting>(
    setting || initialFormData
  );

  useEffect(() => {
    if (setting) {
      setFormData(setting);
    } else {
      // Reset form data if creating a new setting
      setFormData(initialFormData);
    }
  }, [setting,visible]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNumberInputChange =
    (name: string) => (value: number | string) => {
      setFormData((prevData) => ({ ...prevData, [name]: +value || 0 }));
    };

  const handleSelectChange = (name: string) => (value: string) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = () => {
    let validate = validateData(formData);
    if(validate){
      if (formData.id) {
        // If the setting has an id, it means it's an existing setting, update it
        onUpdate(formData);
      } else {
        // If the setting doesn't have an id, it means it's a new setting, create it
        onCreate(formData);
      }
    }
 
    // Reset form data after saving
    setFormData(initialFormData);

  };


  const handleCancel = () => {
    setFormData(initialFormData);
    onCancel();
  };
  const validateData = (data: ISubscriptionSetting): boolean => {
    
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    if (data.title === "") {
      openNotificationWithIcon(msg, "Title" + desc);
      isComplete = false;
    }
    else if (data.subscriptionType!=SubscriptionTypeEnum[SubscriptionTypeEnum.Free] && data.subscriptionProductId === null) {
      openNotificationWithIcon(msg, "Subscription Product" + desc);
      isComplete = false;
    } else if (
      data.transactionsFee === "" || 
      data.transactionsFee === null) {
      openNotificationWithIcon(msg, "Transactions Fee" + desc);
      isComplete = false;
    } else if (
      data.minCapacity === "" ||
       data.minCapacity === null) {
      openNotificationWithIcon(msg, "Min Capacity" + desc);
      isComplete = false;
    } else if (
      data.maxCapacity === "" ||
       data.maxCapacity === null) {
      openNotificationWithIcon(msg, "Max Capacity" + desc);
      isComplete = false;
    } else if (
      data.freeDays === "" ||
       data.freeDays === null) {
      openNotificationWithIcon(msg, "Free Days" + desc);
      isComplete = false;
    } else if (
      !Object.values(SubscriptionTypeEnum).includes(data.subscriptionType)
    ) {
      openNotificationWithIcon(msg, "Invalid Subscription Type" + desc);
      isComplete = false;
    } else if (!Object.values(EqSubscriptionRoleType).includes(data.roleType)) {
      openNotificationWithIcon(msg, "Invalid Role Type" + desc);
      isComplete = false;
    }

    return isComplete;
  };

  return (
    <Modal
      visible={visible}
      title={
        setting ? "Edit Subscription Setting" : "Create Subscription Setting"
      }
      okText="Save"
      cancelText="Cancel"
      onCancel={() => {
        handleCancel();
      }}
      onOk={() => {
        handleSave();
      }}
    >
      {/* <EqInput
        label="Subscription Product"
        value={formData.subscriptionProductId}
        onChange={handleInputChange}
        name="subscriptionProductId"
      /> */}

      <EqInput
        label="Title"
        value={formData.title}
        onChange={handleInputChange}
        // type="number"
        name="title"
      />
      <EqSelect
        label="Subscription Product"
        options={subscriptionProducts.map((product: any) => ({
          key: product.id,
          value: product.name,
          label: product.name,
        }))}
        defaultSelectedValue={formData.subscriptionProductId}
        changeFunc={handleSelectChange("subscriptionProductId")}
      />

      <EqInput
        label="Transactions Fee"
        value={formData.transactionsFee.toString()}
        onChange={handleInputChange}
        type="number"
        name="transactionsFee"
        min={0}
      />
      <EqInput
        label="Min Capacity"
        value={formData.minCapacity.toString()}
        onChange={handleInputChange}
        type="number"
        name="minCapacity"
        min={0}
      />
      <EqInput
        label="Max Capacity"
        value={formData.maxCapacity.toString()}
        onChange={handleInputChange}
        type='number'
        name="maxCapacity"
        min={0}
      />
      <EqInput
        label="Free Days"
        value={formData.freeDays}
        onChange={handleInputChange}
        type="number"
        name="freeDays"
        min={0}
      />
      <EqSelect
        label="Subscription Type"
        options={Object.entries(SubscriptionTypeEnum).map(([key, value]) => ({
          key,
          value,
          label: value,
        }))}
        defaultSelectedValue={formData.subscriptionType}
        classNames="extra-height"
        changeFunc={handleSelectChange("subscriptionType")}
      />
      <EqSelect
        label="Role Type"
        isTag={false}
        
        // options={Object.entries(EqSubscriptionRoleType).map(([key, value]) => ({ key, value, label: value }))}
        options={Object.entries(EqSubscriptionRoleType)
          .filter(([key, value]) => !isNaN(Number(key)))
          .map(([key, value]) => ({
            key: Number(key),
            value,
            label: value as string,
          }))}
        defaultSelectedValue={formData.roleType}
        changeFunc={handleSelectChange("roleType")}
      />
    </Modal>
  );
};

export default SubscriptionSettingForm;
