import React from "react";
import "./EqProductList.scss";
import EqCollapsePanel from "../eqCollapsePanel/EqCollapsePanel";
import EqCard from "../eqCard/EqCard";
import AsyncImage from "../asyncImage/AsyncImage";
import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import { BellOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EqButton from "../eqButton/EqButton";
import EqProductCard from "../eqProductCard/EqProductCard";
// const { Meta } = Card;

export interface IEqProductList {
  productList: any;
  removeProduct?: any;
  editProduct?: any;
  studentsList?: any;
  studentsAndGroups?: any;
  onProductSend?: any;
}

const EqProductList = ({
  productList,
  removeProduct,
  editProduct,
  studentsList,
  studentsAndGroups,
  onProductSend,
}: IEqProductList) => {
  const ProductSend = (item: any) => {
    onProductSend(item);
  };

  React.useEffect(() => {
    console.log(productList);
  }, [productList, studentsList]);
  return (
    <div className="prod-list">
      <Row className="eq-sl-row" align="middle">
        <Row justify="space-around" className="row">
          {productList &&
            productList.length > 0 &&
            productList.map((item: any, index: any) => (
              <Col className="col-md-4 link-category">
                <EqProductCard
                  key={index}
                  product={item}
                  studentsAndGroups={studentsAndGroups}
                  studentsList={studentsList}
                  removeProduct={removeProduct}
                  editProduct={editProduct}
                  onProductSend={ProductSend}
                />
              </Col>
            ))}
        </Row>
      </Row>
    </div>
  );
};

export default EqProductList;
