import { Col, Row, FloatButton, Checkbox } from "antd-v5";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EqButton from "../eqButton/EqButton";
import RevealList from "../RevealList/RevealList";
import Search from "antd-v5/es/input/Search";
import EqSelect from "../eqSelect/EqSelect";
import { ISelectProps } from "../../shared/Interfaces";
import "./EqTrainingInvitations.scss";
import EqCollapse from "../eqCollapse/EqCollapse";
import { EqCurrency } from "../../shared/enums";
import moment from "moment";

interface EqTrainingInvitationsProps {
  data: any[]; // Replace 'any' with the actual type of your training invite
  onDelete: (inviteId: string) => void;
  onDeleteMultiple: (inviteIds: string[]) => void;
}

const EqTrainingInvitations: React.FC<EqTrainingInvitationsProps> = ({
  data,
  onDelete,
  onDeleteMultiple,
}) => {
  const { t, i18n } = useTranslation();

  //data
  const [trainingInvitations, setTrainingInvitations] = useState() as any;

  const [filteredTrainingInvitations, setFilteredTrainingInvitations] =
    useState() as any;

  // selection
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([] as any);
  const [isCheckAll, setIsCheckAll] = useState(false);

 

  const [searchName, setSearchName] = React.useState("");
  const [selectedTrainings, setSelectedTrainings] = React.useState([] as any);

  const [trainingOptions, setTrainingOptions] = React.useState([] as any);

  const filterTrainingOrderList = (trainingOrderList: any[]) => {
    let filteredList = trainingOrderList;

    if (searchName !== "") {
      filteredList = filteredList.filter((item) => {
        let firstName = item?.user?.firstName;
        let lastName = item?.user?.lastName;
        let username = item?.user?.email;

        return (
          (firstName &&
            (firstName + " " + lastName)
              .toLowerCase()
              .includes(searchName.toLowerCase())) ||
          (username &&
            username.toLowerCase().includes(searchName.toLowerCase()))
        );
      });
    }

    if (selectedTrainings && selectedTrainings.length > 0) {
      filteredList = filteredList.filter((item) => {
        return selectedTrainings.includes(item?.event?.title + "");
      });
    }
    console.log("filteredList:", filteredList);
    return filteredList;
  };

  useEffect(() => {
    if (data) {
      CreateAvailableTrainingList(data);
      setTrainingInvitations(data);
      setFilteredTrainingInvitations(data);
    }
  }, [data]);

  const CreateAvailableTrainingList = (items: any[]) => {
    const trainingTitlesMap = new Map();

    items.forEach((item) => {
      const trainingTitle = item?.event.title;

      if (trainingTitle) {
        trainingTitlesMap.set(trainingTitle, {
          key: trainingTitle,
          value: trainingTitle,
          label: trainingTitle,
        });
      }
    });
    const uniqueTrainingTitles = Array.from(trainingTitlesMap.values());
    setTrainingOptions(uniqueTrainingTitles);
    console.log("titles", uniqueTrainingTitles);
  };
  const checkAll = () => {
    const isChecked = !isCheckAll;

    setFilteredTrainingInvitations((prevOrders: any) => {
      const updatedList = prevOrders.map((order: any) => ({
        ...order,
        selected: isChecked,
      }));

      setIsCheckAll(isChecked);
      const updatedSelectedCheckboxes = updatedList.filter(
        (x: any) => x.selected
      );
      setSelectedCheckboxes(updatedSelectedCheckboxes);

      return updatedList;
      
    });
  };

  const searchNameClick = (searchStr: string) => {
    setSearchName(searchStr);
  };

  const trainingProps: ISelectProps = {
    classNames: `eq-multiSelect eq-searchSelect`,
    placeholder: t("training"),
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedTrainings ? selectedTrainings : [],
    options: trainingOptions,
    changeFunc: (value: any) => {
      console.log(value);
      setSelectedTrainings(value);
    },
  };

  const handleOnCheck = (order: any) => {
    
    setFilteredTrainingInvitations((prevOrders: any) => {
      const list = [...prevOrders];
      const updatedList = list.map((item) =>
        item.id === order.id ? { ...item, selected: !item.selected } : item
      );
      const updatedSelectedCheckboxes = updatedList.filter((x) => x.selected);
      setSelectedCheckboxes(updatedSelectedCheckboxes);
      
      return updatedList;
    });
  };



  const handleMultipleDelete = () => {
    const selectedInviteIds = selectedCheckboxes.map(
      (invite: any) => invite.id
    );
    
 onDeleteMultiple(selectedInviteIds)

  };
  return (
    <div className="eqTrainingInvitation">
      {/* <div className="eq-container-fluid"> */}
      <div>
        <Row>
          <Col span={24} className="filters">
            <Row gutter={[16, 16]} className="filter-type">
              <Col className="filter-col" xs={24} sm={24} md={12} lg={11}>
                <Row className="filter-selector">
                  <Col xs={6} sm={5} md={6} lg={5}>
                    <label className="picker-label">{t("student")}</label>
                  </Col>
                  <Col className="search-input" flex="auto">
                    <Search
                      placeholder={t("searchNameMail")}
                      onChange={(e: any) => searchNameClick(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="filter-col" xs={24} sm={24} md={12} lg={11}>
                <Row className="filter-selector">
                  <Col xs={6} sm={5} md={6} lg={5}>
                    <label className="picker-label">{t("title")}</label>
                  </Col>
                  <Col className="search-input" flex="auto">
                    <EqSelect {...trainingProps}></EqSelect>
                  </Col>
                </Row>
              </Col>
              <Col className="search-col" xs={24} sm={24} md={12} lg={11}>
                <div className="search">
                  <EqButton
                    classNames="eq-input-info"
                    type="primary"
                    htmlType="button"
                  >
                    {t("search")}
                  </EqButton>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={24}>
          <Row justify="end" className="action-all-btn hidden-sm">
            {selectedCheckboxes.length > 1 && (
              <>
                <FloatButton
                        description={t("Delete All")}
                        shape="square"
                        onClick={() => handleMultipleDelete()}
                      />    
              </>
            )}
          </Row>
        </Col>

        <Col span={24}>
          <Row className="eq-fl-row hidden-sm">
            <Col className="eq-fl-th " span={2}>
              <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
            </Col>
            <Col className="eq-fl-th" span={5}>
              {t("student")}
            </Col>
            <Col className="eq-fl-th" span={5}>
              {t("title")}
            </Col>
            
            <Col className="eq-fl-th" span={4}>
              {t("startDate")}
            </Col>
            <Col className="eq-fl-th" span={4}>
              {t("amount")}
            </Col>
            <Col className="eq-fl-th" span={4}>
              {t("actions")}
            </Col>
          </Row>
          <Row justify="space-between" className="eq-fl-row hidden-md">
            <Col className="eq-fl-th">
              <Checkbox onChange={checkAll} checked={isCheckAll}>
                {" "}
                Check all
              </Checkbox>
            </Col>
            <Col>
              <Row justify="end" className="action-delete-all">
                {selectedCheckboxes.length > 1 && (
                  <>
                    <EqButton onClick={() => handleMultipleDelete()}>
                      {t("Delete All")}
                    </EqButton>
                  </>
                )}
              </Row>
            </Col>
          </Row>
          <RevealList>
            <Row className="">
              {filterTrainingOrderList(filteredTrainingInvitations)?.map(
                (trainingOrder: any, index: number) => (
                  <div className="training-invite">
                    <Row className="eq-f-row hidden-sm">
                      <Col className="eq-f-td" span={2}>
                        <Checkbox
                          onChange={() => {
                            handleOnCheck(trainingOrder);
                          }}
                          checked={trainingOrder.selected}
                        ></Checkbox>
                      </Col>
                      <Col className="eq-fl-th" span={5}>
                        {`${trainingOrder.user.firstName}  ${trainingOrder.user.lastName}`}
                      </Col>
                      <Col className="eq-f-td" span={5}>
                        {trainingOrder.event.title}
                      </Col>
                      <Col className="eq-fl-th" span={4}>
                        {moment(trainingOrder.event.startTime).format(
                          "DD MMMM, YYYY HH:mm"
                        )}
                      </Col>
                      <Col className="eq-fl-th" span={4}>
                        {trainingOrder.event.price +" "}
                        {EqCurrency[trainingOrder.event.currency]}
                      </Col>
                      <Col className="eq-fl-th product-action" span={4}>
                        <div
                          className="action"
                          onClick={() =>
                            onDelete(trainingOrder.id)
                          }
                        >
                          <span>
                            <img alt="" src="/trash-alt-red.svg" />
                          </span>
                        </div>
                        {/* {ReceiptModal()} */}
                      </Col>
                    </Row>

                    <div className="hidden-md">
                      <EqCollapse
                        header={
                          <Row justify="start" align="middle">
                            <Col className="" span={2}>
                              <Checkbox
                                onChange={() => {
                                  handleOnCheck(trainingOrder);
                                }}
                                checked={trainingOrder.selected}
                              ></Checkbox>
                            </Col>
                            <Col className="" flex="auto">
                              {trainingOrder?.event.title}
                            </Col>
                          </Row>
                        }
                      >
                        <>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("student")}
                            </Col>
                            <Col className="" span={24}>
                              {`${trainingOrder.user.firstName}  ${trainingOrder.user.lastName}`}
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                          </Row>
                          <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("title")}
                            </Col>
                            <Col className="" span={24}>
                              {trainingOrder.event.title}
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                          </Row>
                       
                          <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("startTime")}
                            </Col>
                            <Col className="product-status" span={24}>
                            {moment(trainingOrder.event.startTime).format(
                          "DD MMMM, YYYY HH:mm"
                        )}
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                          </Row>
                          <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("amount")}
                            </Col>
                            <Col className="" span={24}>
                              {trainingOrder.event.price}{" "}
                              {EqCurrency[trainingOrder.event.currency]}
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                          </Row>
                          <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("action")}
                            </Col>
                            <Col className="product-action" span={24}>
                              <div
                                className="action"
                                onClick={() =>
                                  onDelete(trainingOrder.id)
                                }
                              >
                                <span>
                                  <img alt="" src="/trash-alt-red.svg" />
                                </span>
                              </div>

                           
                            </Col>
                          </Row>
                        </>
                      </EqCollapse>
                    </div>
                  </div>
                )
              )}

         
            </Row>
          </RevealList>
        </Col>
      </Row>
    </div>
  );
};

export default EqTrainingInvitations;
