import NavMenu from './NavMenu';
import React, { useEffect } from 'react';
import './EqDashboardLayout.scss';
import { Layout } from 'antd';

export const EqDashboardLayout = (props: any) => {

    const { Sider, Content } = Layout;
   
    return (
        <Layout style={{ minHeight: '100vh' }} className="layout">
            <NavMenu />
            <Layout style={{ flex: '1' }}>
                <Content>{props.children}</Content>
            </Layout>
        </Layout>
    );

}
