import { Col, Row, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { useEffect, useState } from "react";
import { ApiEndpoints, openNotificationWithIcon, ValidateIsSchool } from "../../shared/Common";
import Api from "../../shared/Api";
import { eqNotificationTypes } from "../../shared/enums";
import "./EqTrainerRequestsBoard.scss";
import { useTranslation } from "react-i18next";

import EqProductRequests from "../../custom/EqProductRequests/EqProductRequests";
import { Badge, Modal } from "antd-v5";
import { EqInput, EqInputNumber } from "../../custom/eqInput/EqInput";
import EqTrainingRequests from "../../custom/EqTrainingRequests/EqTrainingRequests";
import { useAuthState } from "../../context/Context";

const EqTrainerRequestsBoard = () => {
    const [error, setError] = useState<string | null>(null);
    const { t, i18n } = useTranslation();
    const userDetails: any = useAuthState();
    const isSchool: boolean = ValidateIsSchool(userDetails);
    const [startedCourseRequests, setStartedCourseRequests] = useState<any>(null);
    const [productRequests, setProductRequests] = useState<any>(null);

    const [selectedRequestId, setSelectedRequestId] = useState<string | null>(null);
    const [price, setPrice] = useState<number | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [trainingsRequestsCount, setTrainingsRequestsCount] = useState();


    useEffect(() => {
        // GetAllTrainerTrainingRequest();
        GetAllProductJoinRequestsByStudents();
    }, []);

    // Product
    const GetAllProductJoinRequestsByStudents = async () => {
        try {
            const response = await Api.get(ApiEndpoints.UserProductRequest.GetAll);
            // setProductRequests(response.data);
            var result = response.data;
            // Lists to store the data
            const upcomingCourse: any = [];
            const startedCourses: any = [];
            const currentDate = new Date().getTime();
            result.forEach((element: any) => {
                // Check the condition and add to respective list

                if (element.firstLessonStartDate == null) {
                    upcomingCourse.push(element.productOrder);
                } else if (element.firstLessonStartDate > currentDate) {
                    upcomingCourse.push(element.productOrder);
                } else {
                    startedCourses.push(element.productOrder);
                }
            });
            setProductRequests(upcomingCourse);
            setStartedCourseRequests(startedCourses);

            setError(null);
        } catch (error) {
            setError("An error occurred while fetching data.");
            console.error("Error fetching data:", error);
        }
    };

    const handleAcceptProductRequest = async (requestId: string, price: number | null) => {
        try {
            const response = await Api.put(
                `${ApiEndpoints.UserProductRequest.Accept}/${requestId}`,
                { price }
            );
            if (response.status === 200) {
                setProductRequests((prevRequests: any) =>
                    prevRequests.filter((invite: any) => invite.id !== requestId)
                );

                setStartedCourseRequests((prevRequests: any) =>
                    prevRequests.filter((invite: any) => invite.id !== requestId)
                );
                openNotificationWithIcon(
                    "Accepted!",
                    "Successfully Accepted Request",
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error: any) {
            if (error.response.status === 409) {
                openNotificationWithIcon(
                    "Error!",
                    `Unable to Accept Request for ${error.response.data.product.title}`,
                    eqNotificationTypes.error
                );
            } else {
                console.error("Error Accepting invite:", error);
                openNotificationWithIcon("Error!", "Unable to Accept", eqNotificationTypes.error);
            }
        }
    };

    const handleRejectProductRequest = async (requestId: string) => {
        try {
            const response = await Api.put(
                `${ApiEndpoints.UserProductRequest.Reject}/${requestId}`
            );

            if (response.status === 200) {
                setProductRequests((prevRequests: any) =>
                    prevRequests.filter((invite: any) => invite.id !== requestId)
                );

                setStartedCourseRequests((prevRequests: any) =>
                    prevRequests.filter((invite: any) => invite.id !== requestId)
                );
                openNotificationWithIcon(
                    "Rejected!",
                    "Successfully Rejected Request",
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error Reject invite:", error);
            openNotificationWithIcon("Error!", "Unable to Reject", eqNotificationTypes.error);
        }
    };

    const handleAcceptProductMultipleRequests = async (requestIds: any) => {
        try {
            const response = await Api.put(
                ApiEndpoints.UserProductRequest.AcceptMultipleRequests,
                requestIds
            );

            if (response.status === 200) {
                // Remove deleted requestIds from the local state
                setProductRequests((prevRequests: any) =>
                    prevRequests.filter((invite: any) => !requestIds.includes(invite.id))
                );

                openNotificationWithIcon(
                    "Accepted!",
                    "SucessFully Accepted Requests",
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error: any) {
            if (error.response.status === 409) {
                openNotificationWithIcon(
                    "Error!",
                    `Unable to Accept Request for ${error.response.data.product.title}`,
                    eqNotificationTypes.error
                );
            } else {
                console.error("Error Accepting invite:", error);
                openNotificationWithIcon("Error!", "Unable to Accept", eqNotificationTypes.error);
            }
        }
    };

    const handleRejectProductMultipleRequests = async (requestIds: any) => {
        try {
            const response = await Api.put(
                ApiEndpoints.UserProductRequest.RejectMultipleRequests,
                requestIds
                // {
                //   data: [...requestIds] ,
                // }
            );

            if (response.status === 200) {
                // Remove deleted requestIds from the local state
                setProductRequests((prevRequests: any) =>
                    prevRequests.filter((invite: any) => !requestIds.includes(invite.id))
                );

                openNotificationWithIcon(
                    "Rejected!",
                    "SucessFully Rejected Requests",
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error deleting requests:", error);

            openNotificationWithIcon("Error!", "Unable to Reject", eqNotificationTypes.error);
        }
    };

    const handleOpenModal = (requestId: string) => {
        // let selectedRequest = productRequests.find(
        // 	(r: any) => r.id === requestId
        // );

        setSelectedRequestId(requestId);
        setIsModalVisible(true);
        // if (selectedRequest.product.isUsed) {
        // 	setSelectedRequestId(requestId);
        // 	setIsModalVisible(true);
        // } else {
        // 	// normal flow just accept
        // 	handleAcceptProductRequest(requestId, null);
        // }
    };

    const handleModalOk = () => {
        if (selectedRequestId && price) {
            handleAcceptProductRequest(selectedRequestId, price);
            setIsModalVisible(false);
            setPrice(null);
            setSelectedRequestId(null);
        }
    };
    const handleModalCancel = () => {
        setIsModalVisible(false);
        setPrice(null);
        setSelectedRequestId(null);
    };
    return (
        <div className="eqTrRequest">
            <div className="eq-container-fluid">
                <Row className="eq-heading-row" align="middle" justify="start">
                    <Col className="eq-heading-col" flex="auto">
                        <h3 className="eq-heading">{t("requests")}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Tabs className="ab-tabs" defaultActiveKey="1">
                            {/* <TabPane tab="Trainings" key="1">
                <EqTrainingInvitations
                  data={trainingRequests}
                  onDelete={handleDeleteTrainingRequest}
                  onDeleteMultiple={handleDeleteTrainingRequests}
                />
              </TabPane> */}
                            <TabPane 
                            
                            tab={
                                <span>
                                {t("trainings")}{" "}
                                <Badge
                                  count={trainingsRequestsCount}
                                  offset={[0, -15]}
                                  className="tab-badge"
                                  size="default"
                                ></Badge>
                              </span>
                            }
                            // tab={t("trainings")}
                             key="1">
                                <EqTrainingRequests
                                onLoadRequests={setTrainingsRequestsCount}
                                />
                            </TabPane>

{isSchool && <>
    <TabPane 
                            tab={
                                <span>
                                {t("upcomingCourses")}{" "}
                                <Badge
                                  count={productRequests?.length}
                                  offset={[0, -15]}
                                  className="tab-badge"
                                  size="default"
                                ></Badge>
                              </span>
                            }
                            // tab="Upcoming Courses" 
                            key="2">
                                {/* <EqProductInvitations /> */}
                                <EqProductRequests
                                    // multipleSelect={true}
                                    data={productRequests}
                                    onAccept={(requestId: any) => {
                                        handleAcceptProductRequest(requestId, null);
                                    }}
                                    // onAccept={handleOpenModal}
                                    OnReject={handleRejectProductRequest}
                                    OnAcceptMultiple={handleAcceptProductMultipleRequests}
                                    OnRejectMultiple={handleRejectProductMultipleRequests}
                                />
                            </TabPane>
                            <TabPane
                                tab={
                                    <span>
                                    {t("startedCourses")}{" "}
                                    <Badge
                                      count={startedCourseRequests?.length}
                                      offset={[0, -15]}
                                      className="tab-badge"
                                      size="default"
                                    ></Badge>
                                  </span>
                                }
                            // tab="Started Courses"
                            
                            key="3">
                                <EqProductRequests
                                    data={startedCourseRequests}
                                    onAccept={handleOpenModal}
                                    OnReject={handleRejectProductRequest}
                                />
                            </TabPane>
</>}
                       
                        </Tabs>
                    </Col>
                </Row>
            </div>

            <Modal
                className=""
                title="Enter Custom Price For Product Order"
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <EqInputNumber
                    placeholder="Enter price"
                    value={price}
                    onChange={(e: any) => {
                        setPrice(e);
                    }}
                />
            </Modal>
        </div>
    );
};

export default EqTrainerRequestsBoard;
