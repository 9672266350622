import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./EqStripeCardForm.scss";
import EqButton from "../custom/eqButton/EqButton";
import Api from "../shared/Api";
import { ApiEndpoints, openNotificationWithIcon } from "../shared/Common";
import { eqNotificationTypes } from "../shared/enums";
import { Col, Row } from "antd";

const CARD_OPTIONS: any = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "black",
      fontWeight: "500",
      fontFamily: "Inter,Open Sans,Segeo UI,sans-serif",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      ":-placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "red",
    },
  },
};

// const EqStripeCardForm = (stripePromise: any) => {
export interface IEqStripeCardForm {
  isEdit?: any;
  onCreate?:any
}
const EqStripeCardForm = ({ isEdit,onCreate }: IEqStripeCardForm) => {
  const [isError, setIsError] = React.useState(false);

  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe() as any;
  const elements = useElements() as any;

  const savePaymentMethod = async (paymentMethodId: any) => {
    try {
      Api.post(ApiEndpoints.StripeCard, {
        paymentMethodId: paymentMethodId,
      }).then(
        (response) => {
          if (response && response.status == 200) {
            openNotificationWithIcon(
              "Created!",
              "Payment Method have been created",
              eqNotificationTypes.success
            );
            onCreate()
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating Payment Method"
            );
            setIsError(true);
          }
        },
        (error) => {
          openNotificationWithIcon(
            "Server error",
            "Error occurred while creating the event(s). Please try again or contact administrator."
          );
          console.error(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  const UpdatePaymentMethod = async (paymentMethodId: any) => {
    try {
      Api.put(ApiEndpoints.StripeCard, {
        paymentMethodId: paymentMethodId,
      }).then(
        (response) => {
          if (response && response.status == 200) {
            openNotificationWithIcon(
              "Updated!",
              "Payment Method have been Updated",
              eqNotificationTypes.success
            );
            onCreate()

          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while updating Payment Method"
            );
            setIsError(true);
          }
        },
        (error) => {
          openNotificationWithIcon(
            "Server error",
            "Error occurred while updating the event(s). Please try again or contact administrator."
          );
          console.error(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event: any) => {
    
    event.preventDefault();
    setProcessing(true);

    //
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      //   console.error(error.message);
      setError(error.message);
      setProcessing(false);
    } else {
      console.log(paymentMethod);
      // You can send the paymentMethod.id to your server to save the customer's card details

      if (isEdit) {
        await UpdatePaymentMethod(paymentMethod.id);
      } else {
        await savePaymentMethod(paymentMethod.id);
      }
    }

    // Create a token with the customer's card details
    // const { error, token } = await stripe.createToken(
    //   elements.getElement(CardElement)
    // );

    // if (error) {
    //   setError(error.message);
    //   setProcessing(false);
    // } else {
    //   // Send the token to your C# API to create a customer object in your database
    //   const response = await fetch("/api/create-customer", {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       tokenId: token.id,
    //       email: "customer@example.com",
    //     }),
    //   });
    //   if (response.ok) {
    //     const data = await response.json();
    //     console.log("Customer ID:", data.customerId);
    //   } else {
    //     const error = await response.json();
    //     console.error(error);
    //   }
    //   setProcessing(false);
    // }
  };
  React.useEffect(() => {
    
  }, []);

  return (
    <Row className="eq-stripeCard">
      <Col xs={22} md={20} xl={18}>
        <form onSubmit={handleSubmit}>
          <div className="eq-cardElement">
            <CardElement options={CARD_OPTIONS} />
          </div>

          {error && <div>{error}</div>}
          <EqButton htmlType="submit" disabled={processing}>
           {isEdit?"Update Card":"Save Card"} 
          </EqButton>
        </form>
      </Col>
    </Row>

    // <form
    //   onSubmit={handleSubmit}
    // //   style={{
    // //     display: "flex",
    // //     justifyContent: "flex-end",
    // //     flexWrap: "wrap",
    // //   }}
    // >
    //   {/* <fieldset style={{ width: "100%" }}> */}
    //     <div
    //       style={{
    //         backgroundColor: "white",
    //         padding: "20px",
    //         borderRadius: "10px",
    //       }}
    //     >
    //       <CardElement options={CARD_OPTIONS} />
    //     </div>
    //   {/* </fieldset> */}
    //   {error && <span>{error}</span>}

    //   <EqButton htmlType="submit">Save Card</EqButton>

    // </form>

    // <form onSubmit={handleSubmit}>

    //   <label>
    //     Card details
    //     <CardElement className="StripeElement" options={CARD_OPTIONS}/>
    //   </label>
    //   {error && <div>{error}</div>}
    //   <button type="submit" disabled={processing}>
    //     Pay
    //   </button>
    // </form>
  );
};

export default EqStripeCardForm;
