import * as React from "react";
import { useEffect } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { EqCurrency } from "../../shared/enums";

// Create styles for the PDF document
const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        paddingTop: 50,
        paddingBottom: 50,
        paddingHorizontal: 50,
    },
    heading: {
        fontSize: 20,
        marginBottom: 5,
    },
    subHeading: {
        fontSize: 12,
    },
    section: {
        marginBottom: 20,
        textAlign: "left",
    },
    sectionDescription: {
        fontSize: 12,
        marginBottom: 5,
    },
    tableContainer: {
        border: "1px solid #f0f0f0",
        marginBottom: 10,
    },
    sectionTitle: {
        fontSize: 16,
        fontWeight: 600,
        margin: "5px 0px",
    },
    dateSection: {
        marginBottom: 20,
    },
    listContainer: {
        marginBottom: 10,
    },
    listItem: {
        border: "1px solid #d9d9d9",
        padding: "10px",
        // marginBottom: "5px",
        fontSize: 11,
        backgroundColor: "#f8f8f8", // Light background color
    },
    questionText: {
        fontWeight: 600,
        marginBottom: 5,
    },
    answerText: {
        fontStyle: "italic",
        color: "#666666",
    },
    attendeeRow: {
        flexDirection: "row",
        // alignItems: "center",
        marginBottom: 5,
    },
    attendeeNumber: {
        marginRight: 5,
        fontWeight: 600,
    },
    attendeeDetails: {
        flexDirection: "column",
        flex: 1,
    },
    attendeeName: {
        fontSize: 16,
        fontWeight: 600,
    },
    attendeeEmail: {
        marginVertical: 1,
        fontSize: 10,
        color: "#666666",
    },

    priceText: {
        fontSize: 10,
        color: "#666666",
        fontWeight: 600,
        marginVertical: 1,
    },
    divider: {
        borderBottom: "1px solid #d9d9d9",
        marginVertical: 5,
    },
 
    classRow: {
        flexDirection: "row",
        // alignItems: "center",
        marginBottom: 5,
    },
    classNumber: {
        fontSize: 14,
        color: "#666666",
        marginRight: 5,
        fontWeight: 600,
    },
    classDetails: {
        flexDirection: "column",
        flex: 1,
    },
    classTitle: {

        fontSize: 14,
        color: "#666666",
        fontWeight: 600,
        // marginVertical: 5,
    },

});

// React component to render the PDF document
export interface IEqEventPdfGenerateListView {
    event: any;
    selectedDates: any;
}

const EqEventPdfGenerateListView = ({ event, selectedDates }: IEqEventPdfGenerateListView) => {
    const { t } = useTranslation();

    const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
    };

    const renderAnswers = (questions: any, answers: any) => {
        return questions.map((question: any, index: any) => {
            const correspondingAnswer = answers.find(
                (answer: any) => answer?.questionId === question.id
            );
            return (
                <View style={styles.listItem} key={index}>
                    <Text style={styles.questionText}>{question.questionText}</Text>
                    <Text style={styles.answerText}>
                        {correspondingAnswer ? `${correspondingAnswer.answer}` : "--/--"}
                    </Text>
                </View>
            );
        });
    };

    useEffect(() => {
        console.log("event:", event);
    }, [event]);

    const renderAttendees = (eventClasses: any) => {
        const attendees = [] as any;

        // Gather all unique attendees from all event classes
        eventClasses.forEach((eventClass: any) => {
            eventClass.attendees?.forEach((attendee: any) => {
                const existingAttendee = attendees.find(
                    (a: any) => a.user.email === attendee.user.email
                );
                if (existingAttendee) {
                    existingAttendee.classes.push(eventClass);
                } else {
                    attendees.push({
                        ...attendee,
                        classes: [eventClass],
                    });
                }
            });
        });

        // Render each attendee with their classes and answers
        return attendees.map((attendee: any, attIdx: any) => (
            <View
                key={attIdx}
                style={{
                    border: "1.5px solid #FF8838",
                    padding: 10,
                    marginBottom: 5,
                }}
            >
                <View style={styles.attendeeRow}>
                    <Text style={styles.attendeeNumber}>{attIdx + 1}.</Text>
                    <View style={styles.attendeeDetails}>
                        <Text style={styles.attendeeName}>
                            {attendee.attendUsingName
                                ? attendee.attendUsingName
                                : attendee.user?.firstName + " " + attendee.user?.lastName}
                        </Text>
                        <Text style={styles.attendeeEmail}>{attendee.user?.email}</Text>
                    </View>
                </View>

                <View style={styles.divider} />

                {attendee.classes.map((eventClass: any, classIdx: any) => (
                    <View key={classIdx} style={{ marginBottom: 10 }}>
                        <View style={styles.classRow}>
                            <Text style={styles.classNumber}>{classIdx + 1}.</Text>
                        

                        <View style={styles.classDetails}>
                            <Text style={styles.classTitle}>{eventClass.height}</Text>
                            <Text style={styles.priceText}>Price: {eventClass.price +" " + EqCurrency[event.currency]}</Text>
                        </View>
                        </View>
                        <View style={styles.listContainer}>
                            {renderAnswers(event?.questions, attendee.answers)}
                        </View>
                    </View>
                ))}
            </View>
        ));
    };

    return (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <Document>
                <Page style={styles.page}>
                    {event?.eventClasses?.map((eventDate: any, index: any) => (
                        <View key={index} style={styles.dateSection}>
                            <View style={styles.section}>
                                <Text style={styles.heading}>
                                    {event?.title}{" "}
                                    {"(" +
                                        new Date(eventDate.startDate).toLocaleDateString(
                                            t("dateLang"),
                                            options as any
                                        ) +
                                        ")"}
                                </Text>
                            </View>

                            {renderAttendees(eventDate.classTimes)}
                        </View>
                    ))}
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default EqEventPdfGenerateListView;
