import * as React from "react";
import { useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from "@react-pdf/renderer";
import moment from "moment";
import { QuestionType } from "../../shared/enums";
import { ApiEndpoints, getDefaultImage, imageType } from "../../shared/Common";
import Api from "../../shared/AApi";
import { Col, Row } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Create styles for the PDF document
const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        paddingTop: 50,
        paddingBottom: 50,
        paddingHorizontal: 50,
    },
    heading: {
        fontSize: 20,
        marginBottom: 5,
    },
    subHeading: {
        fontSize: 12,
    },
    // leftAlign: {
    //   textAlign: "left",
    // },
    // rightAlign: {
    //   textAlign: "right",
    // },
    section: {
        marginBottom: 20,
        textAlign: "left",
    },
    sectionUnderline: {
        textDecoration: "underline",
    },
    table: {
        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#d9d9d9",
        flexDirection: "row",
    },
    tableHeader: {
        flexDirection: "row",
        backgroundColor: "#ff883873",
        borderBottom: "1px solid #d9d9d9",
        padding: "5px 5px",
        color: "#ff8838",
    },

    tableHeaderCell: {
        flex: 1,
        textAlign: "left", // Align text to the left
        fontSize: 15, // Set the font size to 15px
        fontWeight: 600, // Set the font weight to 600
    },
    tableRowOdd: {
        flexDirection: "row",
        borderBottom: "1px solid #d9d9d9",
        padding: "5px 5px",
        backgroundColor: "#f0f0f0",
    },

    tableRowEven: {
        flexDirection: "row",
        borderBottom: "1px solid #d9d9d9",
        padding: "5px 5px",
    },
    tableRow: {
        flexDirection: "column",
        borderBottom: "1px solid #d9d9d9",
        padding: "5px 5px",
    },
    tableCellFirst: {
        flex: 1,
        textAlign: "left",
        flexDirection: "row", // Add this line
        alignItems: "center", // Add this line
        fontSize: 13, // Set the font size to 15px
        fontWeight: 400, // Set the font weight to 600
    },
    tableCell: {
        flex: 1,
        textAlign: "left",
        fontSize: 13, // Set the font size to 15px
        fontWeight: 400, // Set the font weight to 600
        justifyContent: "center",
    },
    tableCellforQA: {
        border: "1px solid #bdbdbd",
        padding: "5px 5px",
        fontSize: 12,
    },

    tableContainer: {
        border: "1px solid #f0f0f0",
        marginBottom: 10,
    },
    dateSection: {
        marginBottom: 20,
    },
    sectionTitle: {
        fontSize: 16,
        fontWeight: 600,
        margin: "5px 0px",
    },
    sectionDescription: {
        fontSize: 12,
        marginBottom: 5,
    },
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    avatar: {
        border: "1px solid #f0f0f0",
        width: 30, // Set the width of the avatar image
        height: 30, // Set the height of the avatar image
        marginRight: 5, // Set the right margin for spacing
        borderRadius: "50%", // Make the image round
    },
});

// Data for the table
const tableData = [
    { studentName: "John Doe", horseName: "Black Beauty" },
    { studentName: "Jane Smith", horseName: "White Lightning" },
    // Add more data as needed
];

// React component to render the PDF document
export interface IEqEventPdfGenerate {
    // key:number;
    event: any;
    selectedDates: any;
}
const EqEventPdfGenerate = ({ event, selectedDates }: IEqEventPdfGenerate) => {
    const { t } = useTranslation();

    const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
    };

    const renderAnswers = (questions: any, answers: any) => {
        return (
            <>
                {questions.map((question: any, index: any) => {
                    const correspondingAnswer = answers.find(
                        (answer: any) => answer?.questionId === question.id
                    );
                    return (
                        <View style={styles.tableCell}>
                            <Text>
                                {correspondingAnswer ? `${correspondingAnswer.answer}` : "--/--"}
                            </Text>
                        </View>
                    );
                })}
            </>
        );
    };

    const getTotalAttendees = (classTimes: any) => {
        let attendeeCount = 0;
        classTimes.forEach((classTime: any) => {
            attendeeCount = attendeeCount + classTime?.attendees?.length;
        });

        return attendeeCount;
    };

    useEffect(() => {
        // Perform any necessary side effects or data fetching here
        // You can access the 'date' prop within this useEffect hook
        console.log("event:", event);
    }, [event]);
    return (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <Document>
                <Page style={styles.page}>
                    {/* <Text style={styles.heading}>`{event?.title} `</Text> */}

                    {event?.eventClasses?.map((eventDate: any, index: any) => (
                        <>
                            <View key={index} style={styles.dateSection}>
                                <View style={styles.section}>
                                    <Text style={[styles.heading]}>
                                        {event?.title}
                                        {" (" +
                                            new Date(eventDate.startDate).toLocaleDateString(
                                                t("dateLang"),
                                                options as any
                                            ) +
                                            ")"}
                                    </Text>
                                    <View style={styles.row}>
                                        <Text style={styles.subHeading}>
                                            {t("Starting")}:
                                            {moment(eventDate?.classTimes[0]?.startTime).format(
                                                "hh:mm A"
                                            )}
                                        </Text>
                                        <Text style={styles.subHeading}>
                                            {t("Total Number of Attendees")}:
                                            {getTotalAttendees(eventDate?.classTimes)}
                                        </Text>
                                    </View>
                                </View>
                                {eventDate?.classTimes?.map((eventClass: any, cIdx: any) => (
                                    <View
                                        style={{
                                            border: "1.5px solid #FF8838",
                                            padding: 10,
                                            marginBottom: 5,
                                        }}
                                    >
                                        <View style={{ border: "1px solid #f0f0f0", padding: 10 }}>
                                            <Text style={styles.sectionTitle}>
                                                {cIdx + 1 + ". " + eventClass.height}
                                            </Text>
                                        </View>
                                        {/* <Text style={styles.sectionDescription}>
                                            {eventClass.classDescription}
                                        </Text> */}

                                        <View
                                        // style={styles.section}
                                        >
                                            <View style={styles.tableContainer}>
                                                <View style={styles.tableHeader}>
                                                    <View style={styles.tableHeaderCell}>
                                                        <Text>{t("rider")}</Text>
                                                    </View>
                                                    {/* <View style={styles.tableHeaderCell}>
                                                    <Text>Horse </Text>
                                                  </View> */}
                                                    {event?.questions.map(
                                                        (question: any, index: any) => (
                                                            <View style={styles.tableHeaderCell}>
                                                                <Text key={index}>
                                                                    {question.questionText}
                                                                </Text>
                                                            </View>
                                                        )
                                                    )}
                                                </View>

                                                {eventClass?.attendees?.map(
                                                    (attendee: any, IdxAtt: any) => (
                                                        <>
                                                            <View
                                                                style={
                                                                    IdxAtt % 2 === 0
                                                                        ? styles.tableRowOdd
                                                                        : styles.tableRowEven
                                                                }
                                                            >
                                                                <View
                                                                    style={[styles.tableCellFirst]}
                                                                >
                                                                    <Image
                                                                        src={
                                                                            attendee.user?.userImage
                                                                                ?.url ||
                                                                            getDefaultImage(
                                                                                imageType.partner
                                                                            )
                                                                        } // Replace with the appropriate property for avatar URL
                                                                        style={styles.avatar}
                                                                    />

                                                                    <Text>
                                                                        {attendee.attendUsingName
                                                                            ? attendee.attendUsingName
                                                                            : attendee.user
                                                                                  ?.firstName +
                                                                              " " +
                                                                              attendee.user
                                                                                  ?.lastName}
                                                                    </Text>
                                                                </View>

                                                                {renderAnswers(
                                                                    event?.questions,
                                                                    attendee.answers
                                                                )}
                                                            </View>
                                                        </>
                                                    )
                                                )}
                                            </View>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </>
                    ))}
                </Page>
            </Document>
        </PDFViewer>
    );
};

// const EventDetails = () => (
//   <PDFViewer style={{ width: "100%", height: "100vh" }}>
//     <EqEventPdfGenerate />
//   </PDFViewer>
// );

export default EqEventPdfGenerate;
