import React, { useState, useEffect } from "react";
import "./EqMessageListItem.scss";
import { Row, Col, Checkbox, Tooltip } from "antd";

import { EqMessageType, getDefaultImage, getEmailFromToken, imageType } from "../../../shared/Common";
import { Avatar } from "antd-v5";
import { useAuthState } from "../../../context/Context";
import moment from "moment";
import EqTags from "../../../custom/eqTags/EqTags";
import { useTranslation } from "react-i18next";

export interface IEqMessageListItem {
    message: any;
    showMessage: (value: any) => void;
    deleteMessage: (value: any) => void;
}

const EqMessageListItem = ({ message, showMessage, deleteMessage }: IEqMessageListItem) => {
    const userDetails: any = useAuthState();
    const currentUserEmail = getEmailFromToken();
    const [messageView, setMessageView] = useState() as any;

    // const [check, setCheck] = useState(selected);
    const [show, setShow] = useState(false);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        // setCheck(selected);
        if (message) {
            // check if sender or receiver
            setupMessage(message);
        }
    }, [message]);

    const setupMessage = (message: any) => {
        
        let displayMessage = { ...message };
        displayMessage.isSender = false;
        displayMessage.isRead = true;

        // check isSender
        if (displayMessage.sender) {
            if (userDetails.key === message.sender.key) {
                displayMessage.isSender = true;
            } else {
                displayMessage.isSender = false;
            }
        }

        // check is read for recipient
        if (!displayMessage.isSender) {
            const msgRecipient = displayMessage.messageRecipients.find(
                (res: any) => res.recipient?.key === userDetails.key
            );
            displayMessage.isRead = msgRecipient.isRead;
        }

        setMessageView(displayMessage);
    };


    const formatRecipient = (param: any) => {
        const isPrivateMessage = param.messageType === EqMessageType.Message;
        const isSenderCurrentUser = currentUserEmail === param.sender?.email
        if(isPrivateMessage){
            if(isSenderCurrentUser && param.messageRecipients.length > 0){
                let messageRecipient=param.messageRecipients[0].recipient
                return `${messageRecipient?.firstName} ${messageRecipient?.lastName}`;

            }else{
                return `${param.sender?.firstName} ${param.sender?.lastName}`;
            }
        }else{
            return param.messageRecipients.map((item: any) => `${item?.recipient?.firstName} ${item?.recipient?.lastName}`).join(', ');
        }

        // if (param.messageType === EqMessageType.Message) {
        //     return `${param.sender?.firstName} ${param.sender?.lastName}`;
        // } else {
        //     return param.messageRecipients.map((item: any) => `${item?.recipient?.firstName} ${item?.recipient?.lastName}`).join(', ');
        // }
    };
    return (
        <div className="eq-message-list-item">
            <div className="eq-message-container">
                {messageView && (
                    <>
                        <Row
                            className={
                                messageView.isRead == true ? "eq-message-read" : "eq-message-unread"
                            }
                            id="message-container"
                            onClick={() => showMessage(message)}
                        >
             
                            <Col span={4}>
                                {messageView.messageType === EqMessageType.Message ? (
                                    <>
                                    {currentUserEmail === message.sender?.email?<>
                                    
                                        <Avatar.Group maxCount={5} maxStyle={{ color: '#FF8838', backgroundColor: '#fde3cf' }}>
                        <Tooltip title={`${message.messageRecipients[0]?.recipient?.firstName} ${message.messageRecipients[0]?.recipient?.lastName}`}>
                                    <Avatar
                                        size={40}
                                        src={
                                            message.messageRecipients[0]?.recipient
                                                ? message.messageRecipients[0]?.recipient.userImage?.url
                                                : getDefaultImage(imageType.trainer)
                                        }
                                    />
                                </Tooltip>
                        </Avatar.Group>
                                    </>:<>
                                    
                                        <Avatar.Group
                                        maxCount={2}
                                        maxStyle={{
                                            color: "#FF8838",
                                            backgroundColor: "#fde3cf",
                                        }}
                                    >
                                        <Tooltip
                                            title={`${messageView?.sender?.firstName} ${messageView?.sender?.lastName}`}
                                        >
                                            <Avatar
                                                size={30}
                                                src={
                                                    messageView?.sender
                                                        ? messageView?.sender?.userImage?.url
                                                        : getDefaultImage(imageType.trainer)
                                                }
                                            />
                                        </Tooltip>
                                    </Avatar.Group>
                                    </>
                                    }
                                    </>
                                
                                ) : (
                                    <Avatar.Group
                                        maxCount={2}
                                        maxStyle={{
                                            color: "#FF8838",
                                            backgroundColor: "#fde3cf",
                                        }}
                                    >
                                        {message.messageRecipients.length &&
                                            message.messageRecipients?.map((recep: any) => (
                                                <Tooltip
                                                    title={`${recep?.recipient?.firstName} ${recep?.recipient?.lastName}`}
                                                >
                                                    <Avatar
                                                        size={30}
                                                        src={
                                                            recep?.recipient
                                                                ? recep?.recipient?.userImage?.url
                                                                : getDefaultImage(imageType.trainer)
                                                        }
                                                    />
                                                </Tooltip>
                                            ))}
                                    </Avatar.Group>
                                )}
                            </Col>
                            <Col span={20}>
                                <Row justify="space-between">
                                    <Col
                                        // span={19}
                                        className="eq-message-item eq-message-item-action"
                                    >
                                        {formatRecipient(messageView)}
                                    </Col>
                                    <Col
                                        span={24}
                                        // span={5}
                                        onClick={(e: any) => e.stopPropagation()}
                                        className="eq-message-item eq-message-item-action"
                                    >
                                        {messageView.messageType === EqMessageType.Message ? (
                                            <EqTags borderColor text={t("private")}></EqTags>
                                        ) : (
                                            <EqTags borderColor text={t("groupMessage")}></EqTags>

                                        )}
                                    </Col>
                                </Row>
                                <Row className="eq-message-item eq-message-item-action">
                                    <span className="subject">{messageView.subject}</span>
                                </Row>
                                {/*<Row className="eq-message-item eq-message-item-action">*/}
                                {/*  {messageView.message}*/}
                                {/*</Row>*/}
                                <Row justify="space-between">
                                    <Col>
                                        {/* {messageView.createOn.toLocaleString()} */}
                                        <span
                                            className={
                                                messageView.isRead
                                                    ? "msg-date-time read"
                                                    : "msg-date-time"
                                            }
                                        >
                                            {" "}
                                            {moment(messageView.createdOn).format(
                                                "DD/MM/YYYY HH:mm"
                                            )}
                                        </span>
                                    </Col>
                                    <Col
                                        id="mActions"
                                        className="eq-message-item eq-message-item-action"
                                    >
                                        <Row className="eq-message-action">
                                            {/* {message.type == EqMessageType[EqMessageType.Message] && (
                        <Col
                          className="eq-message-action-reply"
                          onClick={replyClicked}
                        >
                          <span>
                            <img alt="" src="/reply.svg" />
                          </span>
                        </Col>
                      )} */}
                                            {messageView.isSender && (
                                                <Col
                                                    className="eq-message-action-delete"
                                                    onClick={(e: any) => {
                                                        e.stopPropagation();
                                                        deleteMessage(message);
                                                    }}
                                                >
                                                    <span className="">
                                                        <img alt="" src="/trash-alt-red.svg" />
                                                    </span>
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </div>
    );
};

export default EqMessageListItem;
