import * as React from "react";
import { Row, Col, notification, Popconfirm, Badge, Checkbox, Modal } from "antd";
import "./EqStudentActivityCollapse.scss";
import { IGroupDto } from "../../shared/interfaces/IGroupDto";
import {
    EqDisciplineType,
    EqEventAccessType,
    EqEventAttendeeStatus,
    EqEventUserType,
    eqNotificationTypes,
    EqProfessionType,
    EqRefundType,
    EqSlotStatusEnum,
    EqTrainingType,
    StudentProductFilters,
} from "../../shared/enums";
import moment from "moment";
import {
    addDays,
    ApiEndpoints,
    checkDuePayment,
    FrontEndPoints,
    getEmailFromToken,
    GetEventMessage,
    IsStudentUnderAge,
    openNotificationWithIcon,
    ValidateIsParent,
    ValidateIsTrainer,
    ValidateIsUser,
    ValidateStudentCanBook,
    ValidateStudentCanPay,
} from "../../shared/Common";
import { useTranslation } from "react-i18next";

import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { saveEvent } from "../../shared/LocalStorage";
import { useAuthState } from "../../context/Context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import EqActivityStudentView from "../../eqStudent/eqActivityStudentView/EqActivityStudentView";
import EqButton from "../../custom/eqButton/EqButton";
import { ISubscribeEventDto } from "../../shared/interfaces/ISubscribeEventDto";
import Api from "../../shared/Api";
import {
    EqIconAttendee,
    EqIconCalendar,
    EqIconLocation,
    EqIconPrice,
    EqIconUser,
} from "../../custom/eqIcon/EqIcons";
import EqTags from "../../custom/eqTags/EqTags";
// import { Modal } from "antd-v5";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export interface IEqStudentActivityCollapse {
    event: any;
    areas: any;
    weekDays: any;
    population?: any;
    groupPopulation?: any;
    trainerStudents?: any;
    studentHorsesList?: any;
    removeAttendeeFunc?: (data: any) => void;
    onCreateOrUpdateActivity?: any;
    onCheck?: (data: any) => void;
    userCredits?: any;
    onPayWithCredit?: (data: any) => void;
}
//IEqEvent
const EqStudentActivityCollapse = ({
    event,
    areas,
    weekDays,
    population,
    groupPopulation,
    trainerStudents,
    studentHorsesList,
    removeAttendeeFunc,
    onCreateOrUpdateActivity,
    onCheck,
    userCredits,
    onPayWithCredit,
    ...rest
}: any) => {
    const userDetails: any = useAuthState();
    const isLoggedInUser: boolean = ValidateIsUser(userDetails);
    const isTrainer: boolean = ValidateIsTrainer(userDetails);
    const isParent: boolean = ValidateIsParent(userDetails);
    const isStudentUnderAge: boolean = IsStudentUnderAge(userDetails);
    const studentCanBook: boolean = ValidateStudentCanBook(userDetails);
    const studentCanPay: boolean = ValidateStudentCanPay(userDetails);

    const currentUserKey = getEmailFromToken();

    const collapseInnerRef = React.useRef<any>(null);
    const [collapseInnerHeight, setCollapseInnerHeight] = React.useState(0);

    const [isError, setIsError] = React.useState(false);
    const [message, setMessage] = React.useState("");
    // subscription Related
    const [slotRange, setSlotRange] = React.useState({
        allSlotRanges: [],
        selectedSlotRange: [],
        currentUserSlots: [],
        currentSelection: {},
        isSlotSelected: false,
    } as any);
    const [subscriptionStatus, setSubscriptionStatus] = React.useState({
        buttonDisabled: false,
        buttonTextSubscribe: "",
        buttonTextSubscribeAll: "Join all",
    } as any);
    const [isEventSubscribed, setIsEventSubscribed] = React.useState(false);
    const [organizers, setOrganizers] = React.useState(event?.organizer as any);
    const [isPaidByUser, setIsPaidByUser] = React.useState(false);
    const [isLatePayment, setIsLatePayment] = React.useState(false);
    const [isLatePaymentAllowed, setIsLatePaymentAllowed] = React.useState(false);
    const [canCancelEvent, setCanCancelEvent] = React.useState(false);

    const [confirmModal, contextHolderConfirm] = Modal.useModal();
    const [paymentModal, contextHolderPaymentModal] = Modal.useModal();
    const [reDirectModal, contextHolderReDirectModal] = Modal.useModal();
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayTime = today.getTime();
    //
    //console.log(userDetails);
    //console.log(event.organizers);
    const [isAttendee, setIsAttendee] = React.useState(false);
    const [studentGroups, setStudentGroups] = React.useState([]) as any;
    const [isOwner, setIsOwner] = React.useState(false);
    const [isStudentTrainings, setIsStudentTrainings] = React.useState(false);
    const [toggleRow, setToggleRow] = React.useState(false);
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();
    const [path, setPath] = React.useState(location.pathname);

    const openNotificationCheckOutNotification = (msg: any, desc: any, event: any) => {
        const btn = (
            <div className="right">
                <EqButton onClick={CreateCheckoutSession}>Pay Now</EqButton>
            </div>
        );
        notification["info"]({
            message: msg,
            btn,
            description: desc,
        });
    };

    const checkStudentTrainings = () => {
        let pathname = location.pathname;

        if (pathname == FrontEndPoints.student.allTrainings) {
            setIsStudentTrainings(true);
        } else {
            setIsStudentTrainings(false);
        }
    };
    const checkEventOwner = () => {
        //console.log(event?.organizers);
        var owner = event?.organizers
            ?.filter((o: any) => {
                return o.key == userDetails.key;
            })
            .shift();
        //console.log(owner);
        if (owner != null || undefined) {
            setIsOwner(true);
        } else {
            setIsOwner(false);
        }
    };
    // const checkEventAttendee = () => {
    //   //console.log(event?.attendees);
    //

    //   var attendee = event?.attendees
    //     ?.filter((a: any) => {
    //       return a.user.key == userDetails.key;
    //     })
    //     .shift();
    //   //console.log(attendee);
    //   if (attendee != null || undefined) {
    //     setIsAttendee(true);
    //   }
    // };
    //console.log(event);
    const getEventGroups = () => {
        //console.log(event);
        var studentGroups = event?.attendees?.filter((x: any) => x.group != null);
        studentGroups = studentGroups?.map((d: any) => d.group);
        const result = [];
        const map = new Map();
        if (studentGroups != null && studentGroups.length > 0) {
            for (const item of studentGroups) {
                if (!map.has(item.key)) {
                    map.set(item.key, true); // set any value to Map
                    result.push(item);
                }
            }
        }
        setStudentGroups(result);
    };

    const handleDeleteEvent = (e: any) => {
        e.stopPropagation();
        // delFun(groupData);
    };

    const confirmRemove = (message: any) => {
        confirmModal.confirm({
            title: "Confirm",
            icon: <ExclamationCircleOutlined />,
            content: message,
            okText: "Yes",
            cancelText: "NO",
            onOk: () => removeAttendeeFunc(event),
            //   onCancel: () => AcceptProductOrderInvite(productOrder),
        });
    };

    const checkCancellationAndRefund = () => {
        //  if Owner has selected cancellation Deadline
        // if yes check check if its time is not passed yet from startTime
        // if not passed check which refund type owner has selected from (no refund, refund, credit)
        // user will get message on popup accordingly
        // if date has passed -- no refund
        // if no cancellation  deadline selected -- no refund
        const ownerOrganizer = organizers.find(
            (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
        );
        if (ownerOrganizer.user.cancellationDeadline) {
            const currentDate = new Date();
            const cancellationDeadlineMS =
                ownerOrganizer.user.cancellationDeadline * 60 * 60 * 1000; // hours in milliseconds
            const startTime = event.trainingTimes[0].startTime;
            const deadlineDate = startTime - cancellationDeadlineMS;
            if (currentDate.getTime() < deadlineDate) {
                switch (ownerOrganizer.user.refundType) {
                    case EqRefundType.NoRefund:
                        return "No refund available.";
                    case EqRefundType.Refund:
                        return "You are eligible for a refund.";
                    case EqRefundType.Credit:
                        return "You are eligible for a credit.";
                    default:
                        return "Invalid refund type.No refund available";
                }
            } else {
                // Deadline has passed
                return "Cancellation deadline has passed. No refund available.";
            }
        } else {
            return "No refund available.";
        }
    };

    const checkCancellationAndRefundForProductTraining = () => {
        const ownerOrganizer = organizers.find(
            (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
        );
        if (ownerOrganizer.user.allowProductTrainingCancel) {
            const currentDate = new Date();
            const cancellationDeadlineMS =
                ownerOrganizer.user.cancellationDeadline * 60 * 60 * 1000; // hours in milliseconds
            const startTime = event.trainingTimes[0].startTime;
            const deadlineDate = startTime - cancellationDeadlineMS;
            if (currentDate.getTime() < deadlineDate) {
                switch (ownerOrganizer.user.productRefundType) {
                    case EqRefundType.Refund:
                        return "You are eligible for a refund.";
                    case EqRefundType.Credit:
                        return "You are eligible for a credit.";
                    default:
                        return "No refund or Credit available.";
                }
            } else {
                // Deadline has passed
                return "Cancellation deadline has passed. No refund or Credit available.";
            }
        } else {
            return "No refund or Credit available.";
        }
    };
    const removeAttendee = (e: any) => {
        e.stopPropagation();

        //check Lesson is from Product Training or Normal Training
        const isProductTraining = event.productId;
        if (isProductTraining) {
            if (isPaidByUser) {
                const message =
                    checkCancellationAndRefundForProductTraining() + " Would you like to Cancel";
                confirmRemove(message);
            } else {
                removeAttendeeFunc(event);
            }
        } else {
            if (isPaidByUser) {
                const message = checkCancellationAndRefund() + " Would you like to Cancel";
                confirmRemove(message);
            } else {
                removeAttendeeFunc(event);
            }
        }
    };

    // // Subscription Related
    // const viewEvent = (event: IEqEventData) => {
    // 	saveEvent(event);
    // 	const key = `open${Date.now()}`;
    // 	notification.close(key);
    // 	navigate(`${FrontEndPoints.student.viewEvent}?key=${event.key}`, {
    // 		state: {
    // 			event: event,
    // 		},
    // 	});
    // };

    // When user Come from Trainer Profile to Join The Training
    //------//
    const JoinTrainingFromTrainerProfile = (e: any) => {
        const evt = e;

        if (e.attendees == null) {
            evt.attendees = [];
        }
        const isUserAttending = evt?.attendees?.some((item: any) => item.key === currentUserKey);
        if (isUserAttending) {
            const isUserConfirmed = evt?.attendees?.some(
                (item: any) => item.key === currentUserKey && item.userStatus === 1
            );
            if (!isUserConfirmed) {
                if (studentCanBook) {
                    subscribeTrainingFromTrainerProfile(evt);
                } else {
                    openNotificationWithIcon(
                        "Error!",
                        "Add Parent to join Trainings",
                        eqNotificationTypes.error
                    );
                }
            }
        } else {
            if (studentCanBook) {
                subscribeTrainingFromTrainerProfile(evt);
            } else {
                openNotificationWithIcon(
                    "Error!",
                    "Add Parent to join Trainings",
                    eqNotificationTypes.error
                );
            }
        }
    };

    const subscribeTrainingFromTrainerProfile = (e: any) => {
        if (isLoggedInUser == false) {
            navigate(FrontEndPoints.login);
        } else {
            let selected = slotRange.currentSelection;

            let data: ISubscribeEventDto = {
                ownerKey: event?.organizers != undefined ? event?.organizers[0].email : "",
                subscriberKey: currentUserKey,
                eventKey: event.key,
                slotStartTime: selected?.key,
                slotStatus: EqSlotStatusEnum.Booked,
                eventUserType: EqEventUserType.Attendee,
            };

            checkDuePayment(openNotificationWithIcon).then(
                (success: any) => {
                    //    console.log("success", success);
                    if (success == false) {
                        // Api.post(ApiEndpoints.eventSubscribe, data).then(
                        Api.post(ApiEndpoints.StudentJoinTraining, data).then(
                            (response) => {
                                setIsEventSubscribed(true);
                                setSubscriptionStatus({
                                    ...subscriptionStatus,
                                    buttonDisabled: true,
                                    buttonTextSubscribe: t("Joined"),
                                });

                                if (response && response.data != null) {
                                    if (isStudentUnderAge && studentCanPay) {
                                        openNotificationCheckOutNotification(
                                            "Pay for the accepted Activity",
                                            "",
                                            event
                                        );
                                    }
                                    //console.log(response);

                                    onCreateOrUpdateActivity();
                                }
                            },
                            (error) => {
                                setIsError(true);
                                setMessage(GetEventMessage(error.response.status));
                                setIsEventSubscribed(false);
                            }
                        );
                    }
                },
                (err: any) => {
                    console.log("error", err);
                    setIsError(true);
                    setMessage(GetEventMessage(err.response.status));
                    setIsEventSubscribed(false);
                }
            );
        }
    };
    //------//

    const dateTomorrow = () => {
        var result = addDays(new Date(), 1).getTime();
        return result;
    };

    const StudentJoinTrainingRequest = (e: any) => {
        let selected = slotRange.currentSelection;

        const ownerOrganizer = event.organizers.find(
            (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
        );

        let data: ISubscribeEventDto = {
            ownerKey: organizers != undefined ? ownerOrganizer.user.email : "",
            subscriberKey: currentUserKey,
            eventKey: event.key,
            slotStartTime: selected?.key,
            slotStatus: EqSlotStatusEnum.Booked,
            eventUserType: EqEventUserType.Attendee,
        };

        checkDuePayment(openNotificationWithIcon).then(
            (success: any) => {
                //    console.log("success", success);
                if (success == false) {
                    // Api.post(ApiEndpoints.eventSubscribe, data).then(
                    Api.post(ApiEndpoints.StudentJoinTraining, data).then(
                        (response) => {
                            if (response && response.data != null) {
                                if (response.data.userStatus == EqEventAttendeeStatus.Accept) {
                                    setIsEventSubscribed(true);
                                    setSubscriptionStatus({
                                        ...subscriptionStatus,
                                        buttonDisabled: true,
                                        buttonTextSubscribe: t("Joined"),
                                    });
                                    if (response.data.slotStatus != EqSlotStatusEnum.Booked) {
                                        setIsPaidByUser(false);
                                        if (isStudentUnderAge && studentCanPay) {
                                            openNotificationCheckOutNotification(
                                                "Pay for the accepted Activity",
                                                "",
                                                event
                                            );
                                        }
                                    }
                                } else if(response.data.userStatus == EqEventAttendeeStatus.Requested){
                                    setSubscriptionStatus({
                                        ...subscriptionStatus,
                                        buttonDisabled: true,
                                        buttonTextSubscribe: "Requested",
                                    });
                                    openNotificationWithIcon(
                                        "Requested!",
                                        "you have requested to book the training. You will be notified when the training is accepted",
                                        eqNotificationTypes.success
                                    ); 
                                } else {
                                    setSubscriptionStatus({
                                        ...subscriptionStatus,
                                        buttonDisabled: true,
                                        buttonTextSubscribe: "Waiting",
                                    });
                                    openNotificationWithIcon(
                                        "Waiting!",
                                        "you have requested to book the training. You will be notified when the training is accepted",
                                        eqNotificationTypes.success
                                    );
                                }

                                //console.log(response);

                                onCreateOrUpdateActivity();

                                let currentUserList = [...slotRange.currentUserSlots, selected];
                                let selectedList = [...slotRange.selectedSlotRange, selected];
                                let remaingAllList = slotRange.allSlotRanges.filter(
                                    (x: any) => x.key != selected.key
                                );
                            }
                        },
                        (error) => {
                            setIsError(true);
                            setMessage(GetEventMessage(error.response.status));
                            setIsEventSubscribed(false);
                        }
                    );
                }
            },
            (err: any) => {
                console.log("error", err);
                setIsError(true);
                setMessage(GetEventMessage(err.response.status));
                setIsEventSubscribed(false);
            }
        );
    };

    const subscribeTraining = (e: any) => {
        e.stopPropagation();

        if (isLoggedInUser == false) {
            navigate(FrontEndPoints.login);
        } 
        // else if (!userDetails.isSubscribed) {
        //     openNotificationWithIcon(
        //         "Error!",
        //         "Kindly Subscribe to join Trainings",
        //         eqNotificationTypes.error
        //     );
        // } 
        else {
            if (studentCanBook) {
                if (isAttendee) {
                    // if Owner Allowed late
                    if (isLatePaymentAllowed) {
                        StudentJoinTrainingRequest(e);
                    } else {
                        PayNow();
                    }
                } else {
                    // Request To Join Lesson
                    StudentJoinTrainingRequest(e);
                }
            } else {
                openNotificationWithIcon(
                    "Error!",
                    "Add Parent to join Trainings",
                    eqNotificationTypes.error
                );
            }
        }
    };

    //

    React.useEffect(() => {
        // console.log("path", path);
        if (event) {
            setupEvent(event);
        }
        if (userCredits) {
        }
        setCollapseInnerHeight(collapseInnerRef.current.clientHeight);

        getEventGroups();
        checkEventOwner();
        // checkEventAttendee();
        checkStudentTrainings();
        // copied from ViewEvent

        if (location.state?.trainingKey) {
            if (location.state?.trainingKey == event.key) {
                JoinTrainingFromTrainerProfile(event);
            }
        }

        return () => {
            // Perform cleanup tasks here
            setIsLatePayment(false);
        };
    }, [event, userCredits]);

    const setupEvent = (event: any) => {
        if (!event) return;
        const { organizers, trainingTimes, payments } = event;
        let attendees = event.trainingTimes[0].attendees;
        let startTime = trainingTimes[0].startTime;
        // Set organizers
        if (organizers) {
            setOrganizers(organizers);
        }

        const ownerOrganizer = organizers.find(
            (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
        );

        // check if organizer has Allowed Late Payment To the Student
        if (ownerOrganizer.user.allowLatePayments) {
            setIsLatePaymentAllowed(true);
        }

        // check if student can cancel or not
        // Calculate time difference
        const currentDate = new Date();
        const timeDifferenceInMS = startTime - currentDate.getTime();

        // if date has no passed yet student can cancel
        if (startTime > currentDate.getTime()) {
            setCanCancelEvent(true);
        }

        // if cancellation deadline

        if (ownerOrganizer.user?.cancellationDeadline) {
            const cancellationDeadlineMS =
                ownerOrganizer.user.cancellationDeadline * 60 * 60 * 1000; // hours in milliseconds

            if (timeDifferenceInMS > cancellationDeadlineMS) {
                // The event can be canceled
                setCanCancelEvent(true);
                console.log("You can cancel the event.");
            } else {
                // Cancellation is not allowed at this time
                console.log("Cancellation is not allowed at this time.");
                setCanCancelEvent(false);
            }
        }

        // Check if the user is an attendee
        const userAttendee = attendees?.find((item: any) => item.key === currentUserKey);

        if (userAttendee) {
            setIsAttendee(true);
            const userStatus = userAttendee.userStatus;

            if (userStatus === EqEventAttendeeStatus.Requested) {
                setSubscriptionStatus({
                    ...subscriptionStatus,
                    buttonDisabled: true,
                    buttonTextSubscribe: "Requested",
                });
            } else if (userStatus === EqEventAttendeeStatus.Waiting) {
                setSubscriptionStatus({
                    ...subscriptionStatus,
                    buttonDisabled: true,
                    buttonTextSubscribe: "Waiting",
                });
                
            } else if (userStatus === EqEventAttendeeStatus.Cancelled) {
                setSubscriptionStatus({
                    ...subscriptionStatus,
                    buttonDisabled: true,
                    buttonTextSubscribe: "Cancelled",
                });
                
            }  else if (userStatus === EqEventAttendeeStatus.Accept) {
                const slotStatus = userAttendee.slotStatus;

                if (
                    slotStatus === EqSlotStatusEnum.StudentCancelled ||
                    slotStatus === EqSlotStatusEnum.TrainerCancelled
                ) {
                    setSubscriptionStatus({
                        ...subscriptionStatus,
                        buttonDisabled: true,
                        buttonTextSubscribe: "Cancelled",
                    });
                } else if (slotStatus === EqSlotStatusEnum.Booked) {
                    // Check if the user has made a payment

                    // const userPaid = payments?.some(
                    //   (item: any) => item.user?.email === currentUserKey
                    // );
                    // if (userPaid) {
                    // }
                    // now with products can be joined with no payment data
                    setIsPaidByUser(true);
                    setIsEventSubscribed(true);
                    setSubscriptionStatus({
                        ...subscriptionStatus,
                        buttonDisabled: true,
                        buttonTextSubscribe: t("Joined"),
                    });
                } else {
                    if (event.paymentDeadline < currentDate.getTime()) {
                        setIsLatePayment(true);
                    }
                    setSubscriptionStatus({
                        ...subscriptionStatus,
                        buttonDisabled: false,
                        buttonTextSubscribe: t("Joined"), // should be Pay Now
                    });
                }
            } else {
                setIsEventSubscribed(false);
                setSubscriptionStatus({
                    ...subscriptionStatus,
                    buttonDisabled: false,
                    buttonTextSubscribe: t("Join"),
                });
            }
        } else if (
            event.trainingTimes[0].maxAvailableSpace > 0 &&
            event.trainingTimes[0].maxAvailableSpace > (attendees?.length || 0)
        ) {
            setSubscriptionStatus({
                ...subscriptionStatus,
                buttonDisabled: false,
                buttonTextSubscribe: t("Join"),
            });
        } else {
            // Full will Send Request in Waiting
            setSubscriptionStatus({
                ...subscriptionStatus,
                buttonDisabled: false, // false
                buttonTextSubscribe: t("Join"), //"Full"
            });
        }
    };

    const PayNowModal = () => {
        paymentModal.confirm({
            title: "Confirm",
            icon: <ExclamationCircleOutlined />,
            content: "You have Credits Available.\n Would you like to Use Credit Or Go to Checkout",
            okText: "Credit",
            cancelText: "Checkout",
            onOk: () => onPayWithCredit(event),
            onCancel: () => CreateCheckoutSession(),
        });
    };
    const PayNow = (e?: any) => {
        e && e.stopPropagation();

        if (userCredits && userCredits.length > 0) {
            // Show popup that would you like to use credit or pay

            const ownerOrganizer = organizers.find(
                (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
            );

            let creditExist=userCredits.some((u: any) => u.fromUserId === ownerOrganizer.userId);
            if (creditExist) {
                PayNowModal();
            } else {
                CreateCheckoutSession();
            }
        } else {
            // go to the checkOut Directly
            CreateCheckoutSession();
        }
    };


    const navigateToStudentProducts = () => {
        const profileBoardPath = FrontEndPoints.student.productBoard;
        // Set up query parameters
        const queryParams = `filter=${StudentProductFilters.UnPaid}`;
        const fullPath = `${profileBoardPath}?${queryParams}`;
        navigate(fullPath);
      };
    const ReDirectModal = () => {
        reDirectModal.confirm({
            title: "Information",
            icon: <ExclamationCircleOutlined />,
            content: t("popUpTextForRedirectToProduct"),
            okText: "OK",
            cancelText: "Cancel",
            onOk: () => navigateToStudentProducts(),
            onCancel: () => {},
        });
    };

    const CreateCheckoutSession = () => {
        var eventKey = event?.key;
        Api.post(ApiEndpoints.StudentTrainingStripeCheckout.Create, {
            key: eventKey,
        })
            .then((response) => {
                
                console.log(response);
                window.location.href = response.data.sessionUrl;
            })
            .catch((error) => {
                if(error.response.status===409){
                    ReDirectModal();

                }else{
                    openNotificationWithIcon(
                        "Error!",
                        "Unable to Create checkout",
                        eqNotificationTypes.error
                    );

                }
                
            });
    };

    //action button Render
    const renderActionButton = () => {
        if (subscriptionStatus.buttonTextSubscribe === t("Joined")) {
            if (!isPaidByUser) {
                if (canCancelEvent) {
                    return (
                        <>
                            <Col span={18}>
                                <div className="right">
                                    <EqButton
                                        disabled={!studentCanPay || event.startDate < todayTime}
                                        onClick={PayNow}
                                    >
                                       {t("payNow")}
                                    </EqButton>
                                </div>
                            </Col>
                            <Col span={4} className="eq-event-actions-trash">
                                <span>
                                    <Popconfirm
                                        placement="bottomRight"
                                        title="Are you sure you want to Cancel your Training?"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={removeAttendee}
                                        onCancel={(e: any) => e.stopPropagation()}
                                    >
                                        <img
                                            alt=""
                                            src="/trash-alt-red.svg"
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </Popconfirm>
                                </span>
                            </Col>
                        </>
                    );
                } else {
                    return (
                        <Col span={24}>
                            <div className="right">
                                <EqButton
                                    disabled={!studentCanPay}
                                    // disabled={!studentCanPay || event.startDate < todayTime}
                                    onClick={PayNow}
                                >
                                    {t("payNow")}
                                </EqButton>
                            </div>
                        </Col>
                    );
                }
            } else {
                if (canCancelEvent) {
                    return (
                        <>
                            <Col span={18}>
                                <EqButton
                                    type="button"
                                    onClick={subscribeTraining}
                                    disabled={subscriptionStatus.buttonDisabled}
                                >
                                    {subscriptionStatus.buttonTextSubscribe}
                                </EqButton>
                            </Col>
                            <Col span={4} className="eq-event-actions-trash">
                                <span>
                                    <Popconfirm
                                        placement="bottomRight"
                                        title="Are you sure you want to Cancel your Training?"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={removeAttendee}
                                        onCancel={(e: any) => e.stopPropagation()}
                                    >
                                        <img
                                            alt=""
                                            src="/trash-alt-red.svg"
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </Popconfirm>
                                </span>
                            </Col>
                        </>
                    );
                } else {
                    return (
                        <Col span={24}>
                            <EqButton
                                type="button"
                                onClick={subscribeTraining}
                                disabled={subscriptionStatus.buttonDisabled}
                            >
                                {subscriptionStatus.buttonTextSubscribe}
                            </EqButton>
                        </Col>
                    );
                }
            }
        } else {
            return (
                <Col span={24}>
                    <EqButton
                        type="button"
                        onClick={subscribeTraining}
                        disabled={subscriptionStatus.buttonDisabled || event.startDate < todayTime}
                    >
                        {subscriptionStatus.buttonTextSubscribe}
                    </EqButton>
                </Col>
            );
        }
    };

    return (
        <div className="eq-student-activity-collapse">
            {subscriptionStatus.buttonTextSubscribe === t("Joined") && isLatePayment && (
                <Badge.Ribbon
                    // style={{ display: isLatePayment ? "" : "none" }}
                    color="#e44444"
                    text={t("late")}
                >
                    {" "}
                </Badge.Ribbon>
            )}
            <EqCollapsePanel
                header={
                    <>
                        <div className="eq-activity-row">
                            <Row className="eq-status-row-border" align="middle">
                                <Col className="content-col">
                                    <Row>
                                        {onCheck &&
                                            subscriptionStatus.buttonTextSubscribe ===
                                                t("Joined") &&
                                            !isPaidByUser && (
                                                <Col
                                                    className="check"
                                                    onClick={(e: any) => {
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <Checkbox
                                                        onChange={(e: any) => {
                                                            e.stopPropagation();
                                                            onCheck(event);
                                                        }}
                                                        checked={event.selected}
                                                    ></Checkbox>
                                                </Col>
                                            )}
                                        <Col>
                                            <div className="date-icon">
                                                <EqIconCalendar
                                                    eqWidth={34}
                                                    eqHeight={34}
                                                    eqClass="eq-cal-icon"
                                                    eqFill="#083b49"
                                                />
                                                <div className="day">
                                                    {moment(event.startDate).format("DD")}
                                                </div>
                                                <div className="month">
                                                    {moment(event.startDate).format("MMM")}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="header-details">
                                            <Row>
                                                <Col></Col>
                                                <Col>
                                                    <span className="time">
                                                        {moment(event.startTime).format("hh:mm A")}{" "}
                                                        -{" "}
                                                        {event.disciplineType !=
                                                        EqDisciplineType.Open
                                                            ? moment(event.startTime)
                                                                  .add(event.duration, "minutes")
                                                                  .format("hh:mm A")
                                                            : moment(event.endTime).format(
                                                                  "hh:mm A"
                                                              )}
                                                    </span>
                                                </Col>
                                                <Col>
                                                    <div className="eq-event-type">
                                                        {event.accessType === 1 ? (
                                                            <EqTags
                                                                text={t(
                                                                    EqTrainingType[
                                                                        event.disciplineType
                                                                    ]
                                                                )}
                                                                dicipline={true}
                                                            />
                                                        ) : (
                                                            <EqTags
                                                                text={t(
                                                                    EqDisciplineType[
                                                                        event.disciplineType
                                                                    ]
                                                                )}
                                                                dicipline={true}
                                                            />
                                                        )}
                                                    </div>
                                                </Col>
                                                {event.location && (
                                                    <Col className="col-trancated">
                                                        <span className="location">
                                                            <EqIconLocation
                                                                eqWidth={13}
                                                                eqHeight={16}
                                                                eqFill="#b5b5b5"
                                                            />
                                                            {event.location?.city}
                                                        </span>
                                                    </Col>
                                                )}

                                                <Col className="hidden-sm col-trancated">
                                                    {studentGroups?.map(
                                                        (item: IGroupDto, index: number) => (
                                                            <EqTags
                                                                className=""
                                                                key={index}
                                                                text={item.name}
                                                                color={item.color}
                                                            />
                                                        )
                                                    )}
                                                </Col>
                                                <Col>
                                                    <span className="trainer">
                                                        {event.organizers &&
                                                            event.organizers.length > 0 &&
                                                            event.organizers.map(
                                                                (item: any, index: number) =>
                                                                    item.eventUserType ===
                                                                        EqEventUserType.Owner && (
                                                                        <span className="trainer">
                                                                            <EqIconUser
                                                                                eqWidth="16"
                                                                                eqHeight="16"
                                                                                eqFill="#b5b5b5"
                                                                            />
                                                                            {item.user
                                                                                ?.professionType ===
                                                                            EqProfessionType.Trainer ? (
                                                                                <Link
                                                                                    to={`${FrontEndPoints.trainer.profileBoard}?key=${item.user?.key}`}
                                                                                    onClick={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                    }}
                                                                                    className="trainer-link"
                                                                                >
                                                                                    {item.user
                                                                                        ?.firstName ||
                                                                                        item.userName}
                                                                                </Link>
                                                                            ) : (
                                                                                <Link
                                                                                    to={`${FrontEndPoints.school.profileBoard}?key=${item.user?.key}`}
                                                                                    onClick={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                    }}
                                                                                    className="trainer-link"
                                                                                >
                                                                                    {item.user
                                                                                        ?.firstName ||
                                                                                        item.userName}
                                                                                </Link>
                                                                            )}
                                                                        </span>
                                                                    )
                                                            )}
                                                    </span>
                                                </Col>

                                                <Col>
                                                    <span className="attendees">
                                                        <EqIconAttendee
                                                            eqWidth="15"
                                                            eqHeight="15"
                                                            eqFill="#b5b5b5"
                                                        />
                                                        {event.trainingTimes[0].attendees?.length ||
                                                            "0"}
                                                        /{event.trainingTimes[0].maxAvailableSpace}
                                                    </span>
                                                </Col>
                                                <Col>
                                                    <span className="price">
                                                        <EqIconPrice
                                                            eqWidth="15"
                                                            eqHeight="13"
                                                            eqFill="#b5b5b5"
                                                        />{" "}
                                                        {event.trainingTimes[0].price}
                                                    </span>
                                                </Col>

                                                <Col>
                                                    <div className="eq-event-type">
                                                        {event.accessType ===
                                                            EqEventAccessType.Private &&
                                                            event.disciplineType ===
                                                                EqDisciplineType.RidingCourse &&
                                                            event?.lessonNumber &&
                                                            event?.product && (
                                                                <EqTags
                                                                    text={
                                                                        event?.lessonNumber
                                                                            ? t("lesson") + (" ") +
                                                                              event?.lessonNumber +
                                                                              "/" +
                                                                              event?.product
                                                                                  ?.lessons
                                                                            : ""
                                                                    }
                                                                    dicipline={true}
                                                                />
                                                            )}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <span className="title">{event.title}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="action-col">
                                    <Row justify="end">
                                        {renderActionButton()}
                                        {contextHolderConfirm}
                                        {contextHolderPaymentModal}
                                        {contextHolderReDirectModal}
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </>
                }
                childInnerHeight={collapseInnerHeight}
                children={
                    <div ref={collapseInnerRef}>
                        <EqActivityStudentView
                            event={event}
                            areas={areas}
                            weekDays={weekDays}
                            population={population}
                            groupPopulation={groupPopulation}
                            trainerStudents={trainerStudents}
                            // studentHorsesList={studentHorsesList}
                        ></EqActivityStudentView>
                    </div>
                }
            ></EqCollapsePanel>
        </div>
    );
};
export default EqStudentActivityCollapse;
