import * as React from 'react';
import './EqMessageModal.scss';
import { Col, Modal, Row, Typography } from 'antd';
import { IEqUserMessageModal } from '../../shared/Interfaces';

const EqMessageModal = (props: IEqUserMessageModal) => {

    const { visible, okClicked, cancelClicked, title, message } = props;

    const { Text } = Typography;

    const [isModalVisible, setIsModalVisible] = React.useState(visible);
    const [errorMessage, setErrorMessage] = React.useState("");
    const errorText = "No message";

    const handleOk = () => {
        setErrorMessage("");
        okClicked && okClicked(undefined);
        setIsModalVisible(false);
    }

    const handleCancel = () => {
        cancelClicked && cancelClicked(undefined);
        setIsModalVisible(false);
    }

    React.useEffect(() => {
        setIsModalVisible(visible);
    }, [props]);

    return (
        <div className="eq-message-modal">
            <Modal title={title} open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                {errorText.length > 0 &&
                    <Row align="middle" className="input-row">
                        <Col flex="auto">
                            <div className="error-message">
                                {errorMessage}
                            </div>
                        </Col>
                    </Row>
                }
                <Row align="middle" className="eq-message-container">
                    <Col flex="auto">
                        <Text id="message" className="">{ message }</Text>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
}

export default EqMessageModal;