import { useEffect, useState } from "react";
import * as React from "react";
import "./EqAccountCreated.scss";
import Api from "../../shared/Api";
import { Link, Route, useNavigate } from "react-router-dom";
import {
  ApiEndpoints,
  FrontEndPoints,
  ValidateIsEventHolder,
  ValidateIsParent,
  ValidateIsSchool,
  ValidateIsTrainer,
} from "../../shared/Common";
import { useAuthState } from "../../context/Context";

const EqAccountCreated = (props: any) => {
  const navigate = useNavigate();
  const userDetails: any = useAuthState();

  const isParent: boolean = ValidateIsParent(userDetails);
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const isSchool: boolean = ValidateIsSchool(userDetails);
  const isEventHolder: boolean = ValidateIsEventHolder(userDetails);

  const handleRedirect = () => {
    if (isTrainer) {
      navigate(FrontEndPoints.trainer.editTrainer);
    } else if (isEventHolder) {
      navigate(FrontEndPoints.eventHolder.editEventHolder);
    } else if (isSchool) {
      navigate(FrontEndPoints.school.editSchool);
    }
  };

  useEffect(() => {
    // console.log(userDetails);
    setTimeout(() => {
      Api.get("Payment/SetAccountInfo").then(
        (response: any) => {
          handleRedirect();
        },
        (error: any) => {
          console.log(error);
        }
      );
    }, 2000);
    // navigate(FrontEndPoints.trainer.accountCreated);
  }, []);

  return <div className="eq-account-created">Redirecting...</div>;
};

export default EqAccountCreated;
