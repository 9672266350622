// SubscriptionSettingTable.tsx

import React from "react";
import { Table, Button, Popconfirm } from "antd";
import { ISubscriptionSetting } from "../../../shared/interfaces/subsctiption/ISubscriptionSetting";
import { EqSubscriptionRoleType, SubscriptionStatusEnum } from "../../../shared/enums";
import { ISubscription } from "../../../shared/interfaces/subsctiption/ISubscription";

interface EqSubscriptionTableProps {
  subscriptions: ISubscription[];
  onDelete: (settingId: string) => void;
  onEdit: (setting: ISubscription) => void;
}

const EqSubscriptionTable: React.FC<EqSubscriptionTableProps> = ({
  subscriptions,
  onDelete,
  onEdit,
}) => {
  const columns = [
    
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (user: any) => {
        
        if(user)
        return user.firstName? (user.firstName + user.lastName) :user.email
      },
    },
    {
      title: "SubscriptionSetting",
      dataIndex: "subscriptionSetting",
      key: "subscriptionSetting",
      render: (subscriptionSetting: any) => {
        if(subscriptionSetting)
        return subscriptionSetting.title;
      },
    },
  
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: any) => {
        const role = SubscriptionStatusEnum[status];
        return role;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: ISubscription) => (
        <>
          <Button type="link" onClick={() => onEdit(record)}>
            Update
          </Button>
          {/* <Popconfirm
            title="Sure to delete?"
            onConfirm={() => onDelete(record.id)}
          >
            <Button type="link">Delete</Button>
          </Popconfirm> */}
        </>
      ),
    },
  ];

  return <Table dataSource={subscriptions} columns={columns} />;
};

export default EqSubscriptionTable;
