import * as React from "react";
import "./EqProductOrderInviteDetail.scss";
import { Row, Col, Popconfirm, Space, Button } from "antd";
import {
  ShoppingCartOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  CheckCircleFilled,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useTranslation, Trans } from 'react-i18next';

import {
  getDefaultImage,
  imageType,
  ValidateIsTrainer,
} from "../../../shared/Common";
import { IEventUserGroupDto } from "../../../shared/interfaces/IEventUserGroupDto";
import moment from "moment";
import Text from "antd/lib/typography/Text";
import { useAuthState } from "../../../context/Context";
export interface IEqProductOrderInviteDetail {
  props: any;
  acceptClicked: (productOrder: any) => void;
  acceptAllClicked: (invites: any) => void;
  rejectAllClicked: (invites: any) => void;
  rejectClicked: (productOrder: any) => void;
  step: number;
  setProductOrderDetail?: any;
}
const EqProductOrderInviteDetail = ({
  props,
  acceptClicked,
  acceptAllClicked,
  rejectAllClicked,
  rejectClicked,
  setProductOrderDetail,
  step,
}: IEqProductOrderInviteDetail) => {
  const userDetails: any = useAuthState();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  console.log(props);
  const { t, i18n } = useTranslation();

  const { trainer, invites } = props;
  const accept = (productOrder: any) => {
    acceptClicked(productOrder);
  };
  const reject = (productOrder: any) => {
    rejectClicked(productOrder);
  };
  const acceptAll = () => {
    acceptAllClicked(invites);
  };
  const rejectAll = () => {
    rejectAllClicked(invites);
  };

  const rowClicked = (item: any) => {
    console.log(step);
    if (step == 2) {
      if (setProductOrderDetail != null) {
        console.log("view pending invites");
        setProductOrderDetail(props);
      }
    }
  };

  React.useEffect(() => {

console.log(props);

  }, [props]);
  return (
    <div className="eq-productOrder-invite">
      <Row
        className="eq-invite-row"
        // justify="space-around"
        onClick={rowClicked}
      >
        <Col
          xs={{ span: 4, offset: 0 }}
          sm={{ span: 4, offset: 0 }}
          md={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          xl={{ span: 2, offset: 0 }}
          xxl={{ span: 2, offset: 0 }}
        >
          <img
            src={
               trainer?.userImage?.url ||
                  getDefaultImage(imageType.partner)
            }
            className="user-img"
            alt="user"
          />
        </Col>

        <Col xs={17} sm={17} md={20} lg={20}>
          <Row>
            {/* {!isTrainer && (
              <Col xs={24} sm={24} md={5}>
                <Text className="eq-invite-row-subTitle" type="secondary">
                  Team {trainer?.firstName}
                </Text>
              </Col>
            )} */}
            <Col xs={24} sm={24} md={5}>
              <Text className="eq-invite-row-title">
                { trainer?.firstName + " " + trainer?.lastName}
              </Text>
            </Col>
            <Col className="eq-invite-row-dis" xs={24} sm={24} md={8}>
              From: {trainer?.userName}
            </Col>
            <Col className="eq-invite-row-dis" xs={24} sm={24} md={6}>
            {t("invitesYouTo")}:{" "}
              {invites?.length == 1
                ? invites.length + t("product")
                : invites.length + t("products")}
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ span: 3, offset: 0 }}
          sm={{ span: 3, offset: 0 }}
          md={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          xl={{ span: 2, offset: 0 }}
          xxl={{ span: 2, offset: 0 }}
        >
          <div>
            {/* <ShoppingCartOutlined style={{ fontSize: "125%" }} /> */}
          </div>
        </Col>
      </Row>
      {invites &&
        invites.length > 0 &&
        invites.map((item: any) => (
          <>
            <Row className="eq-invites-list">
              <Col flex="auto">
                {item.product.title + " || " + item.product.price +"Kr"}
                {/* {moment(item.productOrder?.startDate).format("dddd DD/MM") +
                  " at " +
                  moment(item.productOrder?.startTime).format("HH:MM")} */}
              </Col>
              <Col>
                <Space>
                  <Button
                    onClick={() => accept(item)}
                    className="invite-accept-btn"
                    size="small"
                    shape="circle"
                    icon={<CheckOutlined />}
                  />
                  <Button
                    onClick={() => reject(item)}
                    className="invite-reject-btn"
                    size="small"
                    shape="circle"
                    icon={<CloseOutlined />}
                  />

                  {/* <Popconfirm
                    title={"This is Paid Activity. \n Do you want to Proceed?"}
                    onConfirm={() => accept(item)}
                    // onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <span>
                      <CheckCircleTwoTone twoToneColor="#03dac5" />
                    </span>{" "}
                  </Popconfirm>

                  <span onClick={() => reject(item)}>
                    <CloseCircleTwoTone twoToneColor="red" />
                  </span> */}
                </Space>
              </Col>
            </Row>
          </>
        ))}
      <Row className="invite-action" justify="space-between">
        <Col><b> {t("Accept/RejectAll")}</b></Col>
        <Col>
          <Space>
            <Button
              onClick={acceptAll}
              className="invite-accept-btn"
              shape="circle"
              icon={<CheckOutlined />}
            />
            <Button
              onClick={rejectAll}
              className="invite-reject-btn"
              shape="circle"
              icon={<CloseOutlined />}
            />

            {/*          
              <span onClick={acceptAll}>
                <CheckCircleTwoTone  twoToneColor="#03dac5" />
                <CheckCircleFilled twoToneColor="#03dac5"  />
              </span> */}
            {/* 
              <span onClick={rejectAll}>
                <CloseCircleTwoTone twoToneColor="red" />
                RejectAll
              </span> */}
          </Space>
        </Col>
      </Row>
    </div>
  );
};
export default EqProductOrderInviteDetail;
