import { Col, Row, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { useEffect, useState } from "react";
import { ApiEndpoints, openNotificationWithIcon } from "../../shared/Common";
import Api from "../../shared/Api";
import { eqNotificationTypes } from "../../shared/enums";
import "./EqTrainerInvitationsBoard.scss";
import { useTranslation } from "react-i18next";
import EqTrainingInvitations from "../../custom/EqTrainingInvitations/EqTrainingInvitations";
import EqProductInvitations from "../../custom/EqProductInvitations/EqProductInvitations";

const EqTrainerInvitationsBoard = () => {
  const [trainingInvites, setTrainingInvites] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const { t, i18n } = useTranslation();


  const [productInvites, setProductInvites] = useState<any>(null);


  useEffect(() => {
    
    GetAllTrainerTrainingInvite();
    GetAllProductInvitesByUser()
  }, []);


  // Training

  const GetAllTrainerTrainingInvite = async () => {
    try {
      const response = await Api.get(ApiEndpoints.TrainerTrainingInvite.GetAll); 
      setTrainingInvites(response.data);
      setError(null);
    } catch (error) {
      setError("An error occurred while fetching data.");
      console.error("Error fetching data:", error);
    }
  };

  const handleDeleteTrainingInvite = async (inviteId: string) => {
    try {
      const response = await Api.delete(
        `${ApiEndpoints.TrainerTrainingInvite.DeleteInvite}/${inviteId}`
      );

      if (response.status === 200) {
        setTrainingInvites((prevInvites: any) =>
          prevInvites.filter((invite: any) => invite.id !== inviteId)
        );
        openNotificationWithIcon(
          "Deleted!",
          "Successfully Removed Invite",
          eqNotificationTypes.success
        );
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error deleting invite:", error);
      openNotificationWithIcon(
        "Error!",
        "Unable to Delete",
        eqNotificationTypes.error
      );
    }
  };

  const handleDeleteTrainingInvites = async (inviteIds: any) => {
    
    try {
      const response = await Api.delete(
        ApiEndpoints.TrainerTrainingInvite.DeleteMultipleInvites,
        {
          data: [...inviteIds] ,
        }
      );

      if (response.status === 200) {
        // Remove deleted inviteIds from the local state
        setTrainingInvites((prevInvites: any) =>
          prevInvites.filter((invite: any) => !inviteIds.includes(invite.id))
        );

        openNotificationWithIcon(
          "Deleted!",
          "SucessFully Removed Invites",
          eqNotificationTypes.success
        );
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error deleting invites:", error);

      openNotificationWithIcon(
        "Error!",
        "Unable to Delete",
        eqNotificationTypes.error
      );
    }
  };

// Product
  const GetAllProductInvitesByUser = async () => {
    try {
      const response = await Api.get(ApiEndpoints.UserProductInvite.GetAll); 
      setProductInvites(response.data);
      setError(null);
    } catch (error) {
      setError("An error occurred while fetching data.");
      console.error("Error fetching data:", error);
    }
  };

  const handleDeleteProductInvite = async (inviteId: string) => {
    try {
      const response = await Api.delete(
        `${ApiEndpoints.UserProductInvite.DeleteInvite}/${inviteId}`
      );

      if (response.status === 200) {
        setProductInvites((prevInvites: any) =>
          prevInvites.filter((invite: any) => invite.id !== inviteId)
        );
        openNotificationWithIcon(
          "Deleted!",
          "Successfully Removed Invite",
          eqNotificationTypes.success
        );
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error deleting invite:", error);
      openNotificationWithIcon(
        "Error!",
        "Unable to Delete",
        eqNotificationTypes.error
      );
    }
  };

  const handleDeleteProductInvites = async (inviteIds: any) => {
    
    try {
      const response = await Api.delete(
        ApiEndpoints.UserProductInvite.DeleteMultipleInvites,
        {
          data: [...inviteIds] ,
        }
      );

      if (response.status === 200) {
        // Remove deleted inviteIds from the local state
        setProductInvites((prevInvites: any) =>
          prevInvites.filter((invite: any) => !inviteIds.includes(invite.id))
        );

        openNotificationWithIcon(
          "Deleted!",
          "SucessFully Removed Invites",
          eqNotificationTypes.success
        );
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error deleting invites:", error);

      openNotificationWithIcon(
        "Error!",
        "Unable to Delete",
        eqNotificationTypes.error
      );
    }
  };

  return (
    <div className="eqTrInvite">
      <div className="eq-container-fluid">
        <Row className="eq-heading-row" align="middle" justify="start">
        <Col className="eq-heading-col" flex="auto">
              <h3 className="eq-heading">{t("invitations")}</h3>
            </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs className="ab-tabs" defaultActiveKey="1">
              <TabPane tab={t("trainings")} key="1">
                <EqTrainingInvitations
                  data={trainingInvites}
                  onDelete={handleDeleteTrainingInvite}
                  onDeleteMultiple={handleDeleteTrainingInvites}
                />
              </TabPane>
              <TabPane tab={t("products")} key="2">
                {/* <EqProductInvitations /> */}
                <EqProductInvitations
                  data={productInvites}
                  onDelete={handleDeleteProductInvite}
                  onDeleteMultiple={handleDeleteProductInvites}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EqTrainerInvitationsBoard;
