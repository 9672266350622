import React, { useEffect, useState } from 'react'
import './EqCardEvent.scss'
import AsyncImage from '../asyncImage/AsyncImage';
import {
    Card, Col, Row, Skeleton
} from 'antd';
import moment from 'moment';
import { IEqEventData } from '../../shared/interfaces/IEqEventDto';
import Meta from 'antd/lib/card/Meta';
import { ArrowUpOutlined, CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { IUserProfileDto } from '../../shared/interfaces/IUserProfileDto';
import { Link } from 'react-router-dom';
import { FrontEndPoints } from '../../shared/Common';
import { EqCurrency, EqDisciplineType } from '../../shared/enums';
import { useTranslation, Trans } from "react-i18next";

export interface IEqCard {
    key?: number;
    imgSrc: any;
    title: string;
    description: string,
    actionsList?: any,
    date: any;
    alt?: string
    price: number;
    trainer: any;
    stutents: any;
    type: any;
    startTime: number;
    endTime: number;
    duration: number;
    currency: any;


}


const EqCardEvent = ({
    key,
    imgSrc,
    title,
    description,
    actionsList,
    date,
    alt,
    price,
    trainer,
    stutents,
    type,
    startTime,
    endTime,
    duration,
    currency,
}: IEqCard) => {


    const truncateText = (text: string, maxLength: number) => {
        if (!title) {
            return ''; // Handle case where title is undefined or null
        }
        if (text && text.length <= maxLength) {
            return text;
        }
        return text.substr(0, maxLength) + '...';
    };

    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(true);


    useEffect(() => {
        
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    return (
        <>
            <div className="eq-card">
                <Card
                    hoverable
                    style={{ width: '100%' }}
                    actions={actionsList}
                >
                    <Meta
                        description={

                            loading ? (
                                // Render skeleton loading state
                                <>
                                    <div>
                                        {/* First Row */}
                                        <Skeleton.Image
                                            active
                                            style={{
                                                maxWidth: '100%',
                                                width: '300px',
                                                height: '200px',
                                            }}
                                        />
                                    </div>
                                    <div className='skeleton-type'>
                                        <Skeleton.Button
                                            active
                                            size="small"
                                            shape="round"
                                            style={{
                                                maxWidth: '94px',
                                                height: '20px',
                                            }}
                                        />
                                        {/* Right Skeleton */}
                                        <Skeleton.Button
                                            active
                                            size="small"
                                            shape="round"
                                            style={{
                                                maxWidth: '94px',
                                                height: '20px',
                                            }}
                                        />
                                    </div>
                                    <Skeleton
                                        active
                                        style={{
                                            maxWidth: '100%',
                                            marginTop: '5px',
                                        }}
                                    />
                                    {/* Left Skeleton */}

                                </>
                            ) : (
                                <>
                                    <Row className=''>
                                        <Col>
                                            <AsyncImage className='event-img' alt={alt} src={imgSrc} />
                                        </Col>
                                    </Row>
                                    <Row className='event-detail'>
                                        <Col className='event-type'>
                                            {t(EqDisciplineType[type])}
                                        </Col>
                                        <Col className='event-price'>
                                            <span>{price}</span> <span>{EqCurrency[currency]}</span>
                                        </Col>
                                    </Row>
                                    <Row className='event-detail'>
                                        <Col className='event-title'>
                                            {truncateText(title, 18)}
                                        </Col>
                                        <Col className='icon'>
                                            <ArrowUpOutlined />
                                        </Col>
                                    </Row>
                                    <Row className='event-description'>
                                        <Col >
                                            <div className="description-text">{description}</div>
                                        </Col>
                                    </Row>

                                    <Row className='event-detail'>

                                        {/* keep that in Comment */}
                                        {/* <Col className='event-trainer'>
                                            <span className='trainer' >{t("trainer")}</span> <span className='trainer-name'>
                                                {trainer && trainer.length > 0 &&
                                                    trainer.map((item: IUserProfileDto, index: number) =>
                                                        <Link to={`${FrontEndPoints.trainer.profileBoard}?key=${item.key}`} onClick={(e: any) => { e.stopPropagation() }} className="trainer-link">
                                                            {item.firstName || item.userName}
                                                        </Link>
                                                    )
                                                }
                                            </span>
                                        </Col> */}
                                        {/* keep that in Comment */}
                                        {/* <Col className='event-student'>
                                            <span className='students'>{t("students")}</span> <span className='no-students'>{stutents?.length}</span>
                                        </Col> */}
                                    </Row>
                                    <Row >
                                        <Col className='event-time'>
                                            <CalendarOutlined />
                                            <span className='time'>
                                                {new Date(startTime).toLocaleDateString()}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='event-time'>
                                            <ClockCircleOutlined />
                                            <span className='time'>
                                                {<>
                                                    {/* {moment(startTime).format("hh:mm A")} - {moment(startTime).minutes(duration).format("hh:mm A")} */}
                                                    {moment(startTime).format("hh:mm A")} 
                                                </>}
                                            </span>
                                        </Col>
                                    </Row>


                                </>
                            )
                        }

                    ></Meta>

                </Card>
            </div>
        </>
    )
}

export default EqCardEvent