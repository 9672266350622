import { useEffect} from 'react';
import NavMenu from './NavMenu';
import React from 'react';
import { useAuthDispatch, useAuthState } from '../context/Context';
import { loadState } from './LocalStorage';
import { loginUser } from '../context/Actions';
import { EqFooter } from '../eqFooter/EqFooter';
import './EqLayout.scss';
import { Layout, Menu } from 'antd';
import { Outlet } from 'react-router-dom';

export const EqLayout = (props: any) => {

    const userDetails: any = useAuthState();
    const dispatch: any = useAuthDispatch();
    const localData = loadState();
    const { Footer, Sider, Content } = Layout;

    //this is just added to minimize the lefthand sidebar & manu fluctuation when logged in and refreshing
    //useEffect(() => {
    //    if (!userDetails.isSignedin && localData && localData.accessToken && localData.accessToken.length) {
    //        loginUser(dispatch, localData);
    //    }

    //}, []);

    return (
        <Layout style={{ minHeight: '100vh' }} className="layout">
            <NavMenu />
            <Layout 
            // style={{ flex: '1' }}
            >
                {/*{props?.sidebar && <Sider>Sider</Sider>}*/}
                <Content> <Outlet {...props} /></Content>
            </Layout>
            <Footer style={{ backgroundColor: '#fff' }}><EqFooter /></Footer>
        </Layout>
    );

}
