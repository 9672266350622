import React, { useEffect, useState } from 'react'
import './EqReview.scss'
import AsyncImage from '../asyncImage/AsyncImage';
import { Carousel, Col, Rate } from 'antd';


const EqReview = () => {
    //     const [currentIndex, setCurrentIndex] = useState(0);
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //       setCurrentIndex((prevIndex) => (prevIndex + 1) % review.length);
    //     }, 5000);

    //     return () => clearInterval(interval);
    //   }, []);

    const review = [
        {
            id: 1,
            name: "Sophi",
            text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, to",
            designation: "Professional certificate",
            image: "https://images.unsplash.com/photo-1567532939604-b6b5b0db2604?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2OTU5MDg5Mzh8&ixlib=rb-4.0.3&q=85",
            rating: 2
        },
        {
            id: 2,
            name: "Nadeem",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
            designation: "Professional certificate",
            image: "https://images.unsplash.com/photo-1528892952291-009c663ce843?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2OTU5MDg5NTh8&ixlib=rb-4.0.3&q=85",
            rating: 2.5
        },
        {
            id: 3,
            name: "Eddy",
            text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, to",
            designation: "Professional certificate",
            image: "https://images.unsplash.com/photo-1499996860823-5214fcc65f8f?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2OTU5MDg5ODl8&ixlib=rb-4.0.3&q=85",
            rating: 4.5
        },
    ]
    const contentStyle: React.CSSProperties = {
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };
    return (


        <div className="review-section" style={{ display: "none" }}>
            <h3 className="heading">What our customers says</h3>

            <Carousel autoplay slidesToShow={2} className='review-carousel hidden-sm'>
                {review.map((item) => (
                    <Col key={item.id} className="review">
                        <div className='logo'> Equinet</div>
                        <div className='text-col'>
                            <p className="review-text">
                                {item.text}
                            </p>
                        </div>

                        <div className='review-image'>
                            <AsyncImage className="image" src={item.image} />
                        </div>
                        <h4 className="review-name">{item.name}</h4>
                        <p className='professional'>{item.designation}</p>
                        <Rate allowHalf defaultValue={item.rating} />
                    </Col>
                ))}

            </Carousel>
            <Carousel autoplay slidesToShow={1} className='review-carousel hidden-md'>
                {review.map((item) => (
                    <Col key={item.id} className="review">
                        <div className='logo'> Equinet</div>
                        <div className='text-col'>
                            <p className="review-text">
                                {item.text}
                            </p>
                        </div>
                        <div className='review-image'>
                            <AsyncImage className="image" src={item.image} />
                        </div>
                        <h4 className="review-name">{item.name}</h4>
                        <p className='professional'>{item.designation}</p>
                        <Rate allowHalf defaultValue={item.rating} />
                    </Col>
                ))}

            </Carousel>
        </div>
    )
}

export default EqReview