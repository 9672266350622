import React, { useState, useEffect } from "react";
import EqFinancial, { IEqFinancialDetail } from "../eqFinancial/EqFinancial";
import { Checkbox, DatePicker, Select, Tabs } from "antd";
import { Row, Col } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import "./EqFinancialsList.scss";
import { useTranslation, Trans } from "react-i18next";
import TabPane from "antd/lib/tabs/TabPane";
import { EqEventAccessType } from "../../shared/enums";

export interface IEqFinancialsList {
  financialsList: Array<any>;
  accessType: EqEventAccessType;
  // financialsList: Array<IEqFinancialDetail>;
}

const EqFinancialsList = ({ financialsList, accessType }: any) => {
  const { t, i18n } = useTranslation();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const checkAll = () => {
    setIsCheckAll(!isCheckAll);

    financialsList.map((f: IEqFinancialDetail) => {
      f.selected = !isCheckAll;
    });
  };
  const [isCollapseAll, setIsCollapseAll] = useState(false);
  const handleIsCollapseAll = (props: boolean) => {
    setIsCollapseAll(props);
  };
  const collapseAll = () => {
    handleIsCollapseAll(!isCollapseAll);
    financialsList.map((f: IEqFinancialDetail) => {
      f.display = !isCollapseAll;
    });
  };

  useEffect(() => {
    console.log(financialsList);
  }, [financialsList]);
  return (
    <div className="payment-board">
      {/* filter */}
      
      <div className="all-payments payment-board">
        <Row className="eq-fl-row hidden-sm">
          <Col className="eq-fl-th " span={1}>
            <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
          </Col>
          <Col className="eq-fl-th" span={3}>
            {t("name")}
          </Col>
          <Col className="eq-fl-th" span={3}>
            {accessType == EqEventAccessType.Private
              ? t("training")
              : t("event")}
          </Col>
          <Col className="eq-fl-th" span={4}>
            {t("paymentDate")}
          </Col>
          {accessType == EqEventAccessType.Public && (
            <Col className="eq-fl-th" span={3}>
              {t("duration")}
            </Col>
          )}
          <Col className="eq-fl-th" span={3}>
            {t("amount")}
          </Col>
          <Col className="eq-fl-th" span={2}>
            Fee
          </Col>
          <Col className="eq-fl-th" span={3}>
            {t("status")}
          </Col>
          <Col className="eq-fl-th" span={2}>
            {t("action")}
          </Col>
          
        </Row>
        <div className="hidden-md">
          <Row justify="start" align="middle" className="eq-list-checkAll">
            <Col span={2}>
              <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
            </Col>
            <Col flex="auto">{t("checkAll")}</Col>
            <Col>
              <EqButton
                type="button"
                classNames="btn-background-orange"
                onClick={collapseAll}
              >
                {isCollapseAll ? "Collapse All" : "Expand All"}
              </EqButton>
            </Col>
          </Row>
        </div>
        {financialsList.length > 0 && (
          <>
            {
              // financialsList.map((item: IEqFinancialDetail, index: number) =>
              financialsList.map((item: any, index: number) => (
                <EqFinancial key={index} financial={item}></EqFinancial>
              ))
            }
          </>
        )}
      </div>
    </div>
  );
};
export default EqFinancialsList;
