import React from 'react'
import { motion } from 'framer-motion';

interface IEAnimation {
  children: any

}
const TransitionLoad = ({ children }: IEAnimation) => {
  return (
    <div>
      <motion.div

        initial={{  opacity: 0 }}
        animate={{ opacity: 1}}
        exit={{opacity: 0}}
transition={{ duration: 0.5, ease: 'linear', delay: 0.25 }}
      >
        {children}
      </motion.div>
    </div>
  )
}

export default TransitionLoad