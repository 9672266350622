import React, { useState, useEffect } from 'react';
import './EqEditStudentList.scss';
import EqEditStudent, { IEqEditStudent } from '../eqEditStudent/EqEditStudent';
import EqButton from '../../custom/eqButton/EqButton';
import { Row, Col, Checkbox } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

export interface IEqEditStudentList {
    studentList: Array<IEqEditStudent>;
}
const EqEditStudentList = ({ studentList }: IEqEditStudentList) => {
    const { t, i18n } = useTranslation();
    const [isCheckAll, setIsCheckAll] = useState(false);
    //const [list, setList] = useState(studentList);
    const checkAll = () => {
        setIsCheckAll(!isCheckAll);

        studentList.map(
            (s: IEqEditStudent) => {
                s.selected = !isCheckAll;
            }
        );

    }
    const [isCollapseAll, setIsCollapseAll] = useState(false);
    const handleIsCollapseAll = (props: boolean) => {
        setIsCollapseAll(props);
    }
    const collapseAll = () => {
        handleIsCollapseAll(!isCollapseAll);
        studentList.map(
            (s: IEqEditStudent) => {
                s.display = !isCollapseAll;
            }
        );
    }
    return (
        <>
            <Row className="eq-list-header">
                <Col flex="auto">
                    <h3 className="eq-list-heading">{t("Professionals")}</h3>
                </Col>
                <Col flex="auto">
                    <Row justify="end">
                        <Col>
                            <span className="eq-list-icon"><img alt="" src="/Table.svg" /></span>
                        </Col>
                        <Col>
                            <span className="eq-list-icon"><img alt="" src="/th-list.svg" /></span>
                        </Col>
                        <Col>
                            <span className="eq-list-icon"><img alt="" src="/th-large.svg" /></span>
                        </Col>
                        </Row>
                </Col>
            </Row>

            <Row className="eq-list-th hidden-sm" justify="start" align="middle">
                <Col className="" span={1}>
                    <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                </Col>
                <Col className="" span={5}>
                    {t("name")}
                </Col>
                <Col className="" span={5}>
                    Area
                </Col>
                <Col className="" flex="auto">Disciplines
                </Col>
                <Col className="" span={2}>Action
                </Col>
            </Row>
            <div className="hidden-md">
                <Row justify="start" align="middle" className="eq-list-checkAll">
                    <Col span={2}>
                        <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                    </Col>
                    <Col flex="auto">
                    {t("checkAll")}
                    </Col>
                    <Col>
                        <EqButton type="button" classNames="btn-background-orange" onClick={collapseAll}>
                            {isCollapseAll ? "Collapse All" : "Expand All"}</EqButton>
                        </Col>
                    </Row>
                </div>
            {studentList.length > 0 &&
                <>

                    {
                    studentList.map((item: IEqEditStudent, index: number) =>
                        <>
                            <EqEditStudent key={index} {...item} ></EqEditStudent>
                            </>
                        )
                    }
                </>
            }
            </>
        );
}
export default EqEditStudentList;