import * as React from "react";
import "./EqSchoolTrainings.scss";
import { Row, Col, Avatar, Skeleton, Empty } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsUser,
  FrontEndPoints,
  ValidateIsSchool,
  getDefaultImage,
  imageType,
  GenericMessage,
  openNotificationWithIcon,
} from "../../shared/Common";
import Api from "../../shared/Api";
import "./EqSchoolTrainings.scss";
import { useTranslation, Trans } from "react-i18next";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import {
  EqDisciplineType,
  EqTrainingType,
  ProductType,
} from "../../shared/enums";

import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import CreateOrEditSchoolTraining from "../createOrEditSchoolTraining/CreateOrEditSchoolTraining";
import SchoolTrainingList from "../SchoolTrainingList/SchoolTrainingList";
import { IGetSchoolTrainingDto } from "../../shared/interfaces/schoolTraining/IGetSchoolTrainingDto";
export interface IEqSchoolTrainings {
  updateCount?: (count: number) => void;
}
const EqSchoolTrainings = (
{updateCount}: IEqSchoolTrainings
) => {
  const userDetails: any = useAuthState();
  const navigate = useNavigate();
  const location = useLocation();

  const isUserValid: boolean = ValidateIsUser(userDetails);
  const { t, i18n } = useTranslation();

  //

  // const [isSuccess, setSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [toggle, setToggle] = React.useState(false);
  const [createSchoolTrainingHeader, setCreateSchoolTrainingHeader] =
    React.useState([]) as any;
  const [trainersList, setTrainersList] = React.useState([]) as any;

  const [areas, setAreas] = React.useState([]) as any;
  const [trainersOptions, setTrainersOptions] = React.useState([]) as any;

  const [productOptions, setProductOptions] = React.useState([]) as any;
  const [products, setProducts] = React.useState([]) as any;

  const [weekDays, setWeekDays] = React.useState([]) as any;
  const [eventTypes, setEventTypes] = React.useState([]) as any;

  // const [population, setPopulation] = React.useState([]) as any;
  const [schoolStudents, setSchoolStudents] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;
  const [studentHorsesList, setStudentHorsesList] = React.useState([]);

  const [showEventList, setShowEventList] = React.useState(true);
  const [createSchoolTrainingShow, setCreateSchoolTrainingShow] =
    React.useState(false);
  const [createSchoolTrainingList, setCreateSchoolTrainingList] =
    React.useState([] as Array<any>);
  const [trainingsList, setTrainingsList] = React.useState(null) as Array<any>;
  const [activityTitle, setActivityTitle] = React.useState("");

  const [trainerHorsesList, setTrainerHorsesList] = React.useState({});

  const GetSchoolStudents = () => {
    // Api.get(ApiEndpoints.GetSchoolStudents).then(
    Api.get(ApiEndpoints.SchoolStudents.GetSchoolStudentsGroupDto).then(
      (response) => {
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var schoolStudents = response.data;
          setIsError(false);
          setMessage("");
          setSchoolStudents(response.data);

          // getting both student and School Horses and making list
          // and adding school horse in each student horse list

          [...schoolStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.school.horses.length > 0) {
              [...item.school.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  // const getPopulation = () => {
  //   Api.get(ApiEndpoints.eventGetPopulation).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         setIsError(false);
  //         setMessage("");
  //         setPopulation(response.data);
  //         // console.log("population:",response.data);
  //         // console.table(response.data);
  //       } else {
  //         setIsError(true);
  //         setMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };

  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getAreas = () => {
    Api.get(ApiEndpoints.eventGetAreas).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };


  const GetSchoolTrainers = async () => {
    try {
      const response = await Api.get(ApiEndpoints.SchoolGetTrainers);
  
      if (response && response.status === 200) {
        const trainers = response.data;
        setTrainersList(trainers);
      
  
        const trainerObjList = [
          {
            key: userDetails.key,
            value: userDetails.name,
            label: userDetails.name,
            icon: (
              <Avatar
                size={30}
                src={
                  userDetails.userImage
                    ? userDetails.userImage.url
                    : getDefaultImage(imageType.trainer)
                }
              />
            ),
          },
          ...trainers.map((item:any) => ({
            key: item.key,
            value: item.firstName + item.lastName,
            label: item.firstName,
            icon: (
              <Avatar
                size={30}
                src={
                  item.userImage
                    ? item.userImage.url
                    : getDefaultImage(imageType.trainer)
                }
              />
            ),
          })),
        ];
  
        setTrainersOptions(trainerObjList);
      }
    } catch (error) {
      throw error;
    }
  };
  


  const GetProductList = () => {
    Api.get(ApiEndpoints.UserProduct).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          let data = response.data;

          var productOptions = [] as any;

          if (data) {
            var publicProducts = data?.filter(
              (opt: any) => opt.type == ProductType.Public
            );

            publicProducts.map((item: any, Idx: any) => {
              let obj = {
                key: item.id,
                value: item.title,
                label: item.title,
                limit: item.limit,
              };
              productOptions.push(obj);
            });
            setProductOptions(productOptions);
            setProducts(publicProducts);
          }
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const createEventTypes = () => {
    var eventTypes = [];

    eventTypes.push({
      key: EqDisciplineType.Dressage,
      value: EqTrainingType[EqDisciplineType.Dressage],
    });
    eventTypes.push({
      key: EqDisciplineType.Jumping,
      value: EqTrainingType[EqDisciplineType.Jumping],
    });
    // eventTypes.push({
    //   key: EqDisciplineType.Open,
    //   value: EqTrainingType[EqDisciplineType.Open],
    // });
    // eventTypes.push({
    //   key: EqDisciplineType.Product,
    //   value: EqDisciplineType[EqDisciplineType.Product],
    // });

    setEventTypes(eventTypes);
  };

  React.useEffect(() => {
    console.log("School Trainings", location);
    GetSchoolTrainers();
    GetSchoolStudents();
    createEventTypes();
    getWeekDays();
    GetProductList();
    getAreas();
    // getPopulation();
    getGroupPopulation();
    GetAllSchoolTrainingsByWithLessons();

    window.scrollTo(0, 0);
    if (location.state) {
      if (
        location.state.prevPath == FrontEndPoints.trainer.dashboard ||
        location.state.prevPath == FrontEndPoints.trainer.groupsBoard
      ) {
        setCreateSchoolTrainingShow(true);
      }
    }
  }, []);

  const handleChangeActivityTitle = (e: any) => {
    setActivityTitle(e);
  };

  const handleOnCreateTraining = (data: any) => {
    setCreateSchoolTrainingList([]);
    console.log(createSchoolTrainingList);
    const trainings = [...trainingsList];
    data.forEach((e: any) => {
      trainings.push(e);
    });
    setTrainingsList(trainings);
    if (updateCount) {
      updateCount(trainingsList.length); 
    }
  };

  const handleOnUpdateTraining = (newData: any, oldData: any) => {
    if (trainingsList != undefined) {
      const trainings = [...trainingsList];
      var foundIndex = trainings.findIndex((x) => x.key == oldData.key);
      trainings[foundIndex] = newData;
      setTrainingsList(trainings);
    }
  };

  const editTraining = (data: IEqEventData) => {
    navigate(FrontEndPoints.trainer.createEvent, { state: data });
  };

  const removeTraining = (
    trainingData: IGetSchoolTrainingDto,
    refund?: boolean
  ) => {
    // const refundParam= refund?`?refund=${refund}`:'';
    
    // let path=ApiEndpoints.DeleteSchoolTraining+ `/${trainingData.key}`
    // if(refund){
    //   path=ApiEndpoints.DeleteSchoolTraining+ `?key=${trainingData.key}&refund=${refund}`
    // }
    let path =
      ApiEndpoints.SchoolTraining.Delete +
      `?key=${trainingData.key}&refund=${refund}`;
    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(trainingData);
        } else {
          setIsError(true);
          setErrorMessage(GenericMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GenericMessage(error.response.status));
        openNotificationWithIcon(
          GenericMessage(error.response.status),
          "Error occurred while Deleting. Please try again or contact administrator."
        );
      }
    );
    if (updateCount) {
      updateCount(trainingsList.length); 
    }
  };

  const GetAllSchoolTrainingsByWithLessons = async () => {
    setErrorMessage("");
    let path = ApiEndpoints.SchoolTraining.GetSchoolTrainingsOfMonths;
    Api.get(path).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          setTrainingsList(data);
          
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const removeItem = (data: any) => {
    if (trainingsList != undefined) {
      var updatedList =
        trainingsList != undefined
          ? trainingsList.filter((item: any) => item.key !== data.key)
          : new Array<any>();
      setTrainingsList(updatedList);

      trainingsList.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  return (
    <div className={`eq-school-training`}>
      <div className="eq-container-fluid">
        <Row align="middle" justify="end">
          {!createSchoolTrainingShow && (
            <>
              {ValidateIsSchool(userDetails) ? (
                <Row align="middle" justify="end">
                  <Col className="create-button">
                    <EqButton
                      type="button"
                      classNames="btn-background-orange"
                      onClick={() => setCreateSchoolTrainingShow(true)}
                    >
                      {t("createTraining")}
                    </EqButton>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </>
          )}
        </Row>

        <Row className="eq-event-heading-line"></Row>

        {/* singleCreateSchoolTrainingComponent */}

        {createSchoolTrainingShow && (
          <EqCollapsePanel
            defaultOpen={true}
            header={t("createTraining")}
            children={
              <CreateOrEditSchoolTraining
                onActivityTitleChange={(e: any) => {
                  handleChangeActivityTitle(e);
                }}
                eventKey={0}
                eventTypes={eventTypes}
                trainersOptions={trainersOptions}
                products={products}
                productOptions={productOptions}
                areas={areas}
                weekDaysData={weekDays}
                // population={population}
                groupPopulation={groupPopulation}
                schoolStudents={schoolStudents}
                studentHorsesList={studentHorsesList}
                onCreateOrUpdateSchoolTraining={handleOnCreateTraining}
                onCancelSchoolTraining={() =>
                  setCreateSchoolTrainingShow(false)
                }
                isStudent={false}
              />
            }
          />
        )}

        <Row>
          {[...Array(5)].map((_, index) => (
          <Skeleton key={index} loading={!trainingsList} paragraph={{ rows: 2 }} active />
        ))}
        </Row>
        {trainingsList && (
          <>
            {trainingsList.length > 0 ? (
              <SchoolTrainingList
                trainingList={[...trainingsList]}
                trainersOptions={trainersOptions}
                eventTypes={eventTypes}
                productOptions={productOptions}
                products={products}
                areas={areas}
                weekDays={weekDays}
                // population={population}
                groupPopulation={groupPopulation}
                schoolStudents={schoolStudents}
                studentHorsesList={studentHorsesList}
                removeEventFunc={removeTraining}
                editEventFunc={editTraining}
                onCreateOrUpdateSchoolTraining={handleOnUpdateTraining}
              ></SchoolTrainingList>
            ) : (
              <Row justify="center">
                <Empty description={t("noTrainingsAvailable")} />
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default EqSchoolTrainings;
