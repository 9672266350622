import { Col, Row } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React from "react";
import { useTranslation } from "react-i18next";
import { ProductOrderStatus, ProductType } from "../../shared/enums";
import "./EqProductsBoardOrderList.scss";

export const EqProductsBoardOrderList = ({ products }: any) => {
  const [isCheckAll, setIsCheckAll] = React.useState(false);
  const { t, i18n } = useTranslation();

  const checkAll = () => {
    setIsCheckAll(!isCheckAll);

    //setStudents(studentsList);
  };
  React.useEffect(() => {
    
    console.log(products);
  }, [products]);
  return (
    <div className="eq-pbo-container">
      <Row className="eq-pbo-h-row"
      //  justify="start" align="middle"
      //  gutter={{
      //   xs: 8,
      //   sm: 16,
      //   md: 24,
      //   lg: 32,
      // }}
      >
        {/* <Col className="eq-sl-th" xs={2} sm={2} md={1}>
          <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
        </Col> */}
        <Col className="eq-sl-th" span={5} >
          {t("student")}
        </Col>
        <Col className="eq-sl-th" span={5} >
          {t("email")}
        </Col>
        <Col className="eq-sl-th" span={5}>
          {t("title")}
        </Col>
        <Col className="eq-sl-th" span={5}>
          {t("type")}
        </Col>
        <Col className="eq-sl-th eq-sl-th-status" span={4}>
          {t("Status")}
        </Col>
        {/* <Col className="eq-sl-th" xs={3} sm={3} md={2} >Actions
                    </Col> */}
      </Row>
      {products &&
        products.length > 0 &&
        products.map(
          (p: any) =>
            p.productOrders.length > 0 &&
            p.productOrders.map((o: any) => (
              <Row className="eq-pbo-row"
              // gutter={{
              //   xs: 8,
              //   sm: 16,
              //   md: 24,
              //   lg: 32,
              // }}
              >
                <Col className="eq-sl-th" span={5}>
                  {o.student?.firstName + " "+ o.student?.lastName }
                </Col>
                <Col className="eq-sl-th" span={5}>
                  {o.student?.email }
                </Col>
                <Col className="eq-sl-th" span={5}>
                  {p.title}
                </Col>
                <Col className="eq-sl-th" span={5}>
                  {ProductType[p.type]}
                </Col>
                <Col className="eq-sl-th eq-sl-th-status" span={4}>
                  {ProductOrderStatus[o.status]}
                </Col>
                {/* <Col className="eq-sl-th" xs={3} sm={3} md={2} >Actions
                    </Col> */}
              </Row>
            ))
        )}

      {/* {studentsList?.length > 0 &&
                    <>
                        {
                        studentsList.map((item: any, index: number) =>
                                <EqStudent key={index} {...item} ></EqStudent>
                            )
                        }
                    </>
                } */}
    </div>
  );
};
