import { Col, Row, FloatButton, Checkbox, Space, Button } from "antd-v5";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EqButton from "../eqButton/EqButton";
import RevealList from "../RevealList/RevealList";
import Search from "antd-v5/es/input/Search";
import EqSelect from "../eqSelect/EqSelect";
import { ISelectProps } from "../../shared/Interfaces";
import "./EqProductRequests.scss";
import EqCollapse from "../eqCollapse/EqCollapse";
import { EqCurrency, ProductOrderStatus } from "../../shared/enums";
import moment from "moment";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";

interface EqProductRequestsProps {
  data: any[]; // Replace 'any' with the actual type of your product invite
  onAccept: (requestId: string) => void;
  OnReject: (requestId: string) => void;
  multipleSelect?: boolean;
  OnRejectMultiple?: (requestIds: string[]) => void;
  OnAcceptMultiple?: (requestIds: string[]) => void;
}

const EqProductRequests: React.FC<EqProductRequestsProps> = ({
  data,
  onAccept,
  OnReject,
  multipleSelect,
  OnRejectMultiple,
  OnAcceptMultiple
}) => {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = React.useState("1");

  //data
  const [productRequests, setProductRequests] = useState() as any;

  const [filteredProductRequests, setFilteredProductRequests] =
    useState() as any;

  // selection
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([] as any);
  const [isCheckAll, setIsCheckAll] = useState(false);

 

  const [searchName, setSearchName] = React.useState("");
  const [selectedProducts, setSelectedProducts] = React.useState([] as any);

  const [productOptions, setProductOptions] = React.useState([] as any);

  const filterProductOrderList = (productOrderList: any[]) => {
    let filteredList = productOrderList;

    if (searchName !== "") {
      filteredList = filteredList.filter((item) => {
        let firstName = item?.student?.firstName;
        let lastName = item?.student?.lastName;
        let username = item?.student?.email;

        return (
          (firstName &&
            (firstName + " " + lastName)
              .toLowerCase()
              .includes(searchName.toLowerCase())) ||
          (username &&
            username.toLowerCase().includes(searchName.toLowerCase()))
        );
      });
    }

    if (selectedProducts && selectedProducts.length > 0) {
      filteredList = filteredList.filter((item) => {
        return selectedProducts.includes(item?.product?.title + "");
      });
    }
    console.log("filteredList:", filteredList);
    
    if(activeTab==="1"){
     return filteredList?.filter(req=>req.status === ProductOrderStatus.Requested).sort((a, b) => a.createdOn - b.createdOn)
    }else{
      return filteredList?.filter(req=>req.status === ProductOrderStatus.Waiting).sort((a, b) => a.createdOn - b.createdOn)
    }

    

      // return filteredList;
    



  };

  useEffect(() => {
    if (data) {
      CreateAvailableProductList(data);
      setProductRequests(data);
      setFilteredProductRequests(data);
    }
  }, [data]);

  const CreateAvailableProductList = (items: any[]) => {
    const productTitlesMap = new Map();

    items.forEach((item) => {
      const productTitle = item?.product.title;

      if (productTitle) {
        productTitlesMap.set(productTitle, {
          key: productTitle,
          value: productTitle,
          label: productTitle,
        });
      }
    });
    const uniqueProductTitles = Array.from(productTitlesMap.values());
    setProductOptions(uniqueProductTitles);
    console.log("titles", uniqueProductTitles);
  };
  const checkAll = () => {
    const isChecked = !isCheckAll;

    setFilteredProductRequests((prevOrders: any) => {
      const updatedList = prevOrders.map((order: any) => ({
        ...order,
        selected: isChecked,
      }));

      setIsCheckAll(isChecked);
      const updatedSelectedCheckboxes = updatedList.filter(
        (x: any) => x.selected
      );
      setSelectedCheckboxes(updatedSelectedCheckboxes);

      return updatedList;
      
    });
  };

  const searchNameClick = (searchStr: string) => {
    setSearchName(searchStr);
  };

  const productProps: ISelectProps = {
    classNames: `eq-multiSelect eq-searchSelect`,
    placeholder: t("product"),
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedProducts ? selectedProducts : [],
    options: productOptions,
    changeFunc: (value: any) => {
      console.log(value);
      setSelectedProducts(value);
    },
  };

  const handleOnCheck = (order: any) => {
    
    setFilteredProductRequests((prevOrders: any) => {
      const list = [...prevOrders];
      const updatedList = list.map((item) =>
        item.id === order.id ? { ...item, selected: !item.selected } : item
      );
      const updatedSelectedCheckboxes = updatedList.filter((x) => x.selected);
      setSelectedCheckboxes(updatedSelectedCheckboxes);
      
      return updatedList;
    });
  };



  const handleMultipleReject = () => {
    const selectedInviteIds = selectedCheckboxes.map(
      (invite: any) => invite.id
    );
    
    OnRejectMultiple && OnRejectMultiple(selectedInviteIds)
setSelectedCheckboxes([])
  };


  const handleMultipleAccept = () => {
    const selectedInviteIds = selectedCheckboxes.map(
      (invite: any) => invite.id
    );
    
    OnAcceptMultiple &&  OnAcceptMultiple(selectedInviteIds)
    setSelectedCheckboxes([])

  };

  const handleOnchangeTab = (e:any) => {
setActiveTab(e)

setFilteredProductRequests((prevOrders: any) => {
  const updatedList = prevOrders.map((order: any) => ({
    ...order,
    selected: false,
  }));

  setIsCheckAll(false);
  const updatedSelectedCheckboxes = updatedList.filter(
    (x: any) => x.selected
  );
  setSelectedCheckboxes(updatedSelectedCheckboxes);

  return updatedList;
  
});
  };

  return (
    <div className="eqProductRequest">
      {/* <div className="eq-container-fluid"> */}
      <div>
        <Row>
          <Col span={24} className="filters">
            <Row gutter={[16, 16]} className="filter-type">
              <Col className="filter-col" xs={24} sm={24} md={12} lg={11}>
                <Row className="filter-selector">
                  <Col xs={6} sm={5} md={6} lg={5}>
                    <label className="picker-label">{t("student")}</label>
                  </Col>
                  <Col className="search-input" flex="auto">
                    <Search
                      placeholder={t("searchNameMail")}
                      onChange={(e: any) => searchNameClick(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="filter-col" xs={24} sm={24} md={12} lg={11}>
                <Row className="filter-selector">
                  <Col xs={6} sm={5} md={6} lg={5}>
                    <label className="picker-label">{t("title")}</label>
                  </Col>
                  <Col className="search-input" flex="auto">
                    <EqSelect {...productProps}></EqSelect>
                  </Col>
                </Row>
              </Col>
              <Col className="search-col" xs={24} sm={24} md={12} lg={11}>
                <div className="search">
                  <EqButton
                    classNames="eq-input-info"
                    type="primary"
                    htmlType="button"
                  >
                    {t("search")}
                  </EqButton>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    

      <Tabs 
                      activeKey={activeTab}
                      onChange={handleOnchangeTab}  
                    >
<TabPane tab={t("requests")} key="1">   
  <div>

 
  <Row>

        <Col span={24}>
          <Row justify="end" className="action-all-btn hidden-sm">
            {selectedCheckboxes.length > 1 && (
              <div className="float-btn">
                {/* <Space > */}
 

                <FloatButton
                className="accept-btn"
                description={t("acceptAll")}
                        shape="square"
                        onClick={() => handleMultipleAccept()}
                      />    
                <FloatButton
                className="reject-btn"
                        description={t("rejectAll")}
                        shape="square"
                        onClick={() => handleMultipleReject()}
                        />    
                        {/* </Space> */}
              </div>
            )}
          </Row>
        </Col>

        <Col span={24}>
          <Row className="eq-fl-row hidden-sm">
            {multipleSelect &&
            <Col className="eq-fl-th " span={2}>
              <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
            </Col>
            } 
            <Col span={2}>{t("number")} </Col>
            <Col className="eq-fl-th" span={5}>
              {t("student")}
            </Col>
            <Col className="eq-fl-th" span={6}>
              {t("title")}
            </Col>
            
            {/* <Col className="eq-fl-th" span={4}>
              {t("startDate")}
            </Col> */}
            <Col className="eq-fl-th" span={5}>
              {t("amount")}
            </Col>
            <Col className="eq-fl-th" span={4}>
              {t("actions")}
            </Col>
          </Row>
          <Row justify="space-between" className="eq-fl-row hidden-md">
          {multipleSelect &&
            <Col className="eq-fl-th">
              <Checkbox onChange={checkAll} checked={isCheckAll}>
                {" "}
                Check all
              </Checkbox>
            </Col>
            }
            <Col>
              <Row justify="end" className="action-delete-all">
                {selectedCheckboxes.length > 1 && (
                  <>
                    <EqButton onClick={() => handleMultipleAccept()}>
                      {t("acceptAll")}
                    </EqButton>
                    <EqButton onClick={() => handleMultipleReject()}>
                      {t("rejectAll")}
                    </EqButton>
                  </>
                )}
              </Row>
            </Col>
          </Row>
          <RevealList>
            <Row className="">
              {filterProductOrderList(filteredProductRequests)?.map(
                (productOrder: any, index: number) => (
                  <div className="product-invite">
                    <Row className="eq-f-row hidden-sm">
                      {multipleSelect &&
                      <Col className="eq-f-td" span={2}>
                        <Checkbox
                          onChange={() => {
                            handleOnCheck(productOrder);
                          }}
                          checked={productOrder.selected}
                          ></Checkbox>
                      </Col>
                        }
                        <Col span={2}>{index+1}</Col>
                      <Col className="eq-fl-th" span={5}>
                        {`${productOrder.student.firstName}  ${productOrder.student.lastName}`}
                      </Col>
                      <Col className="eq-f-td" span={6}>
                        {productOrder.product.title}
                      </Col>
                      {/* <Col className="eq-fl-th" span={4}>
                        {moment(productOrder.product.startTime).format(
                          "DD MMMM, YYYY HH:mm"
                        )}
                      </Col> */}
                      <Col className="eq-fl-th" span={5}>
                        {productOrder.product.price +" "}
                        {EqCurrency[productOrder.product.currency]}
                      </Col>
                      <Col className="eq-fl-th product-action" span={4}>
                        {/* <div
                          className="action"
                          onClick={() =>
                            OnReject(productOrder.id)
                          }
                        >
                          <span>
                            <img alt="" src="/trash-alt-red.svg" />
                          </span>
                        </div> */}
                     
                        <Space>
                          <Button
                            onClick={() => onAccept(productOrder.id)}
                            className="invite-accept-btn"
                            size="small"
                            shape="circle"
                            icon={<CheckOutlined />}
                          />
                          <Button
                            onClick={() => OnReject(productOrder.id)}
                            className="invite-reject-btn"
                            size="small"
                            shape="circle"
                            icon={<CloseOutlined />}
                          />
                        </Space>
                      </Col>
                    </Row>

                    <div className="hidden-md">
                      <EqCollapse
                        header={
                          <Row justify="start" align="middle">
                             {multipleSelect &&
                            <Col className="" span={2}>
                              <Checkbox
                                onChange={() => {
                                  handleOnCheck(productOrder);
                                }}
                                checked={productOrder.selected}
                              ></Checkbox>
                            </Col>
                            }
                            <Col className="" flex="auto">
                              {productOrder?.product.title}
                            </Col>
                          </Row>
                        }
                      >
                        <>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("student")}
                            </Col>
                            <Col className="" span={24}>
                              {`${productOrder.student.firstName}  ${productOrder.student.lastName}`}
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                          </Row>
                          <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("title")}
                            </Col>
                            <Col className="" span={24}>
                              {productOrder.product.title}
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                          </Row>
                       
                          <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("startTime")}
                            </Col>
                            <Col className="product-status" span={24}>
                            {moment(productOrder.product.startTime).format(
                          "DD MMMM, YYYY HH:mm"
                        )}
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                          </Row>
                          <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("amount")}
                            </Col>
                            <Col className="" span={24}>
                              {productOrder.product.price}{" "}
                              {EqCurrency[productOrder.product.currency]}
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                          </Row>
                          <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("action")}
                            </Col>
                            <Col className="product-action" span={24}>

                            <Space>
                          <Button
                            onClick={() => onAccept(productOrder.id)}
                            className="invite-accept-btn"
                            size="small"
                            shape="circle"
                            icon={<CheckOutlined />}
                          />
                          <Button
                            onClick={() => OnReject(productOrder.id)}
                            className="invite-reject-btn"
                            size="small"
                            shape="circle"
                            icon={<CloseOutlined />}
                          />
                        </Space>
                              {/* <div
                                className="action"
                                onClick={() =>
                                  OnReject(productOrder.id)
                                }
                              >
                                <span>
                                  <img alt="" src="/trash-alt-red.svg" />
                                </span>
                              </div> */}

                           
                            </Col>
                          </Row>
                        </>
                      </EqCollapse>
                    </div>
                  </div>
                )
              )}

         
            </Row>
          </RevealList>
        </Col>
        </Row>
        </div>
        </TabPane>
<TabPane tab={t("waitingList")} key="2">   <Col span={24}>
          <Row justify="end" className="action-all-btn hidden-sm">
            {selectedCheckboxes.length > 1 && (
              <div className="float-btn">
                {/* <Space > */}

                <FloatButton
                className="accept-btn"
                description={t("Accept All")}
                        shape="square"
                        onClick={() => handleMultipleAccept()}
                      />    
                <FloatButton
                className="reject-btn"
                        description={t("Reject All")}
                        shape="square"
                        onClick={() => handleMultipleReject()}
                        />    
                        {/* </Space> */}
              </div>
            )}
          </Row>
        </Col>

        <Col span={24}>
          <Row className="eq-fl-row hidden-sm">
            {multipleSelect &&
            <Col className="eq-fl-th " span={2}>
              <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
            </Col>
            } 
            <Col className="eq-fl-th" span={2}>
              {t("number")}
            </Col>
            <Col className="eq-fl-th" span={5}>
              {t("student")}
            </Col>
            <Col className="eq-fl-th" span={6}>
              {t("title")}
            </Col>
            
            {/* <Col className="eq-fl-th" span={4}>
              {t("startDate")}
            </Col> */}
            <Col className="eq-fl-th" span={5}>
              {t("amount")}
            </Col>
            <Col className="eq-fl-th" span={4}>
              {t("actions")}
            </Col>
          </Row>
          <Row justify="space-between" className="eq-fl-row hidden-md">
          {multipleSelect &&
            <Col className="eq-fl-th">
              <Checkbox onChange={checkAll} checked={isCheckAll}>
                {" "}
                Check all
              </Checkbox>
            </Col>
            }
            <Col>
              <Row justify="end" className="action-delete-all">
                {selectedCheckboxes.length > 1 && (
                  <>
                    <EqButton onClick={() => handleMultipleAccept()}>
                      {t("acceptAll")}
                    </EqButton>
                    <EqButton onClick={() => handleMultipleReject()}>
                      {t("rejectAll")}
                    </EqButton>
                  </>
                )}
              </Row>
            </Col>
          </Row>
          <RevealList>
            <Row className="">
              {filterProductOrderList(filteredProductRequests)?.map(
                (productOrder: any, index: number) => (
                  <div className="product-invite">
                    <Row className="eq-f-row hidden-sm">
                      {multipleSelect &&
                      <Col className="eq-f-td" span={2}>
                        <Checkbox
                          onChange={() => {
                            handleOnCheck(productOrder);
                          }}
                          checked={productOrder.selected}
                          ></Checkbox>
                      </Col>
                        }
                        <Col span={2}>{index+1}</Col>
                      <Col className="eq-fl-th" span={5}>
                        {`${productOrder.student.firstName}  ${productOrder.student.lastName}`}
                      </Col>
                      <Col className="eq-f-td" span={6}>
                        {productOrder.product.title}
                      </Col>
                      {/* <Col className="eq-fl-th" span={4}>
                        {moment(productOrder.product.startTime).format(
                          "DD MMMM, YYYY HH:mm"
                        )}
                      </Col> */}
                      <Col className="eq-fl-th" span={5}>
                        {productOrder.product.price +" "}
                        {EqCurrency[productOrder.product.currency]}
                      </Col>
                      <Col className="eq-fl-th product-action" span={4}>
                        {/* <div
                          className="action"
                          onClick={() =>
                            OnReject(productOrder.id)
                          }
                        >
                          <span>
                            <img alt="" src="/trash-alt-red.svg" />
                          </span>
                        </div> */}
                     
                        <Space>
                          <Button
                            onClick={() => onAccept(productOrder.id)}
                            className="invite-accept-btn"
                            size="small"
                            shape="circle"
                            icon={<CheckOutlined />}
                          />
                          <Button
                            onClick={() => OnReject(productOrder.id)}
                            className="invite-reject-btn"
                            size="small"
                            shape="circle"
                            icon={<CloseOutlined />}
                          />
                        </Space>
                      </Col>
                    </Row>

                    <div className="hidden-md">
                      <EqCollapse
                        header={
                          <Row justify="start" align="middle">
                             {multipleSelect &&
                            <Col className="" span={2}>
                              <Checkbox
                                onChange={() => {
                                  handleOnCheck(productOrder);
                                }}
                                checked={productOrder.selected}
                              ></Checkbox>
                            </Col>
                            }
                            <Col className="" flex="auto">
                              {productOrder?.product.title}
                            </Col>
                          </Row>
                        }
                      >
                        <>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("student")}
                            </Col>
                            <Col className="" span={24}>
                              {`${productOrder.student.firstName}  ${productOrder.student.lastName}`}
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                          </Row>
                          <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("title")}
                            </Col>
                            <Col className="" span={24}>
                              {productOrder.product.title}
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                          </Row>
                       
                          <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("startTime")}
                            </Col>
                            <Col className="product-status" span={24}>
                            {moment(productOrder.product.startTime).format(
                          "DD MMMM, YYYY HH:mm"
                        )}
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                          </Row>
                          <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("amount")}
                            </Col>
                            <Col className="" span={24}>
                              {productOrder.product.price}{" "}
                              {EqCurrency[productOrder.product.currency]}
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                          </Row>
                          <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                              {t("action")}
                            </Col>
                            <Col className="product-action" span={24}>

                            <Space>
                          <Button
                            onClick={() => onAccept(productOrder.id)}
                            className="invite-accept-btn"
                            size="small"
                            shape="circle"
                            icon={<CheckOutlined />}
                          />
                          <Button
                            onClick={() => OnReject(productOrder.id)}
                            className="invite-reject-btn"
                            size="small"
                            shape="circle"
                            icon={<CloseOutlined />}
                          />
                        </Space>
                              {/* <div
                                className="action"
                                onClick={() =>
                                  OnReject(productOrder.id)
                                }
                              >
                                <span>
                                  <img alt="" src="/trash-alt-red.svg" />
                                </span>
                              </div> */}

                           
                            </Col>
                          </Row>
                        </>
                      </EqCollapse>
                    </div>
                  </div>
                )
              )}

         
            </Row>
          </RevealList>
        </Col></TabPane>

    </Tabs>
     
    
    </div>
  );
};

export default EqProductRequests;
