import React, { useState, useEffect } from "react";
import "./EqCollapse.scss";
import { Row, Col } from "antd";
export interface IEqCollapse {
  display?: boolean;
  setDisplay?: any;
  showArrow?: boolean;
  header: any;
  classNames?: any;
  children?: React.ReactNode;
}
const EqCollapse = ({
  display,
  setDisplay,
  header,
  children,
  showArrow,
  classNames,
}: IEqCollapse) => {
  const [show, setShow] = useState(display);
  useEffect(() => {
    showChange(display);
  }, [display]);
  function showChange(display?: boolean) {
    setShow(display);
    if (setDisplay != null) {
      setDisplay(display);
    }
  }
  const toggleShow = (e: any) => {
    e.stopPropagation();
    showChange(!show);
  };
  return (
    <>
      <div onClick={toggleShow}>
        <Row
          className={`eq-card-header ${classNames ? classNames : ""}`}
          justify="center"
          align="middle"
        >
          
          <Col span={23} >{header}</Col>
          {showArrow != null || undefined ? (
            <Col></Col>
          ) : (
            <Col className="group-header-arrow" span={1}>
              {show ? (
                <span className="gh-arrow-icon">
                  <img alt="" src="/caret-up.svg" />
                </span>
              ) : (
                <span className="gh-arrow-icon">
                  <img alt="" src="/caret-down.svg" />
                </span>
              )}
            </Col>
          )}
        </Row>
      </div>
      {show && <div className="eq-card-body">{children}</div>}
    </>
  );
};
export default EqCollapse;
