import { Upload, Modal } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./EqUpload.scss";
import { Row, Col, Alert } from "antd";
import { Image } from "antd";
import Compressor from "compressorjs";
import Api from "../../shared/Api";

export interface IEqUpload {
  selected: any;
  imagesData: any;
  defaultSelected?: any;
  preview?: boolean;
}

const EqUpload = ({
  selected,
  imagesData,
  defaultSelected,
  preview,
}: IEqUpload) => {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const getBase64 = (img: any) => {
    setLoading(true);
    new Compressor(img, {
      quality: 0.7,
      success: (compressedResult) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          var srcStr = reader.result as any;
          var apiImgSrc = srcStr.substr(srcStr.indexOf(",") + 1);

          var form = new FormData();
          form.append("imageFile", img);
          form.append("imageSrc", apiImgSrc);
          form.append("imageName", img.name);
          setError("");

          Api.post("image/uploadImage", form).then(
            (response: any) => {
              var lst: any = uploadState.fileList;
              lst.push(response.data);
              setUploadState({
                ...uploadState,
                fileList: lst,
                selectedKey: response.data.key,
              });
              selected && selected(response.data);
              setLoading(false);
            },
            (error) => {
              setLoading(false);
              setError("Error Occurred while uploading image");
            }
          );

          //updateImage(dispatch, reader.result);
        });
        reader.readAsDataURL(compressedResult);
      },
    });
  };

  const [uploadState, setUploadState] = React.useState({
    selectedKey: "",
    fileList: [],
  });

  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.originFileObj) {
      getBase64(info.file.originFileObj);
    } else {
      getBase64(info.file);
    }
  };

  const { fileList }: any = uploadState;

  const handleClick = (item: any) => {
    setUploadState({ ...uploadState, selectedKey: item.key });
    selected && selected(item);
  };

  React.useEffect(() => {
    //
    //console.log("get event images");
    if (imagesData && imagesData.length) {
      if (defaultSelected) {
        var imageObj = imagesData.find(
          (item: any) => item.key === defaultSelected.key
        );
        if (imageObj) {
          setUploadState({
            selectedKey: imageObj?.key,
            fileList: imagesData,
          });
          selected(imageObj);
        }
        else{
            setUploadState({
                selectedKey: imagesData[0].key,
                fileList: imagesData,
              });
              selected(imagesData[0]);
        }

      } else {
        setUploadState({
          selectedKey: imagesData[0].key,
          fileList: imagesData,
        });
        selected(imagesData[0]);
      }
    }
    // Api.get("image/getEventImages").then(response => {
    //     if (response.data && response.data.length) {
    //         setUploadState({
    //             selectedKey: response.data[0].key,
    //             fileList: response.data
    //         });
    //         selected(response.data[0]);
    //     }
    // }, error => {
    //     setError("Error Occurred while fetching images from server");
    // });
  }, [imagesData]);

  return (
    <>
      <Row>
        <Col>
          <div className="feedback">
            {error?.length > 0 && (
              <Alert
                message="Error"
                description={error}
                type="error"
                showIcon
                banner
              />
            )}
          </div>
        </Col>
      </Row>

      <Row>
        {fileList?.map((d: any) => (
          <Col>
            <div
              className={`eq-upload ${
                uploadState.selectedKey == d.key ? "selected" : ""
              }`}
              onClick={() => handleClick(d)}
            >
              <Image className="eq-image" preview={preview} src={d.url} />
            </div>
          </Col>
        ))}
        <Col>
          <Upload
            listType="picture-card"
            showUploadList={false}
            onChange={handleChange}
            className="eq-upload-img"
          >
            <div>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Col>
      </Row>
    </>
  );
};

export default EqUpload;
