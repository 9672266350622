import * as React from "react";
import "./EqGroupList.scss";
import EqGroup, { IEqGroup } from "../eqGroup/EqGroup";
import { Row, Col } from "antd";
import { IEqGroupDto } from "../../shared/interfaces/IEqGroup";
import { IGroupDto } from "../../shared/interfaces/IGroupDto";
import { IStudentGroupDto } from "../../shared/interfaces/IStudentGroupDto";

export interface IEqGroupList {
    creatorUser?: any;
    areas: any;
    trainerStudents?: any;
  studentHorsesList?: any;
  weekDays: any;
  groupPopulation: any;
  groupList: Array<IEqGroupDto>;
  removeGroupFunc?: (data: IEqGroupDto) => void;
  editGroupFunc?: (data: IEqGroupDto) => void;
  studentsList: Array<IStudentGroupDto>;
  searchStudentsList: any;
}

const EqGroupList = ({
    creatorUser,
    areas,
    trainerStudents,
    studentHorsesList,
    weekDays,
    groupPopulation,
  groupList,
  removeGroupFunc,
  editGroupFunc,
  studentsList,
  searchStudentsList,
}: IEqGroupList) => {
  //const [groups, setGroups] = React.useState(groupList);

    React.useEffect(() => {        
  }, [groupList]);

  return (
    <>
      {groupList && groupList.length > 0 && (
        <>
          {groupList?.map((item: IEqGroupDto, index: number) => (
            <EqGroup
              {...{
                creatorUser: creatorUser,
                areas: areas,
                weekDays: weekDays,
                groupPopulation:groupPopulation,
                studentHorsesList: studentHorsesList,
                trainerStudents: trainerStudents,
                groupData: item,
                editGroupFunc: editGroupFunc,
                removeGroupFunc: removeGroupFunc,
                studentsList,
                searchStudentsList,
              }}
              key={index}
            ></EqGroup>
          ))}
        </>
      )}
    </>
  );
};
export default EqGroupList;
