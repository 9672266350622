import * as React from "react";
import "./EqProductOrderInvite.scss";
import { Row, Col } from "antd";
import { useTranslation, Trans } from 'react-i18next';

import {
  ShoppingCartOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import {
  getDefaultImage,
  imageType,
  ValidateIsTrainer,
} from "../../../shared/Common";
import Text from "antd/lib/typography/Text";
import { useAuthState } from "../../../context/Context";
export interface IEqProductOrderInvite {
  invitesList: any;
  setProductOrderDetail?: any;
  step: number;
}
const EqProductOrderInvite = ({ invitesList, setProductOrderDetail, step }: IEqProductOrderInvite) => {
  const userDetails: any = useAuthState();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const { t, i18n } = useTranslation();

  console.log(invitesList);
  const { trainer, invites } = invitesList;
  const getAllTrainerEvents = (trainerKey: any) => {
    console.log(trainerKey);
    if (trainerKey > 0) {
    }
  };
  const rowClicked = (item: any) => {
    console.log(step);
    if (step == 1) {
      if (setProductOrderDetail != null) {
        console.log("view pending invites");
        setProductOrderDetail(invitesList);
      }
    }
  };
  React.useEffect(() => {
    
    console.log(invitesList);
    console.log(step);
  }, [invitesList]);
  return (
    <>
      <Row
        className="eq-invite-row"
        justify="space-around"
        onClick={rowClicked}
      >
        <Col
          xs={{ span: 4, offset: 0 }}
          sm={{ span: 4, offset: 0 }}
          md={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          xl={{ span: 2, offset: 0 }}
          xxl={{ span: 2, offset: 0 }}
        >
          <img
            src={
              trainer?.userImage?.url || getDefaultImage(imageType.partner)
              
            }
            className="user-img"
            alt="user"
          />
        </Col>

        <Col xs={17} sm={17} md={20} lg={20}>
          <Row>
            {/* <Col xs={24} sm={24} md={5}>
              <Text className="eq-invite-row-subTitle" type="secondary">
              {t("team")}  {trainer?.firstName}
               
              </Text>
            </Col> */}
            <Col xs={24} sm={24} md={5}>
              <Text className="eq-invite-row-title" >
              {trainer?.firstName + " " + trainer?.lastName}
              </Text>
            </Col>
            <Col className="eq-invite-row-dis" xs={24} sm={24} md={8}>
            {t("from")}: { trainer?.userName}
      
            </Col>
            <Col className="eq-invite-row-dis" xs={24} sm={24} md={6}>
            {t("invitesYouTo")}: {invites?.length==1?(invites.length+ t("product")):(invites.length+ t("products"))}
             
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ span: 3, offset: 0 }}
          sm={{ span: 3, offset: 0 }}
          md={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          xl={{ span: 2, offset: 0 }}
          xxl={{ span: 2, offset: 0 }}
        >
          {(step === 1 || step === 3) && (
            <>
              {/* <ShoppingCartOutlined style={{ fontSize: "125%" }} /> */}
            </>
          )}
          {/* <div>
            {step === 3 ||
              (step === 5 && (
                <span>
                  <CheckCircleTwoTone twoToneColor="#03dac5" />
                </span>
              ))}
            {step === 5 && (
              <span>
                <CloseCircleTwoTone twoToneColor="red" />
              </span>
            )}
          </div> */}
        </Col>
      </Row>
    </>
  );
};
export default EqProductOrderInvite;
