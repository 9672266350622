import * as React from "react";
import "./EqEventInvite.scss";
import { Row, Col } from "antd";
import { useTranslation, Trans } from 'react-i18next';

import {
  ShoppingCartOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import {
  getDefaultImage,
  imageType,
  ValidateIsStudent,
  ValidateIsTrainer,
} from "../../shared/Common";
import Text from "antd/lib/typography/Text";
import { useAuthState } from "../../context/Context";
export interface IEqEventInvite {
  props: any;
  viewEventDetail?: any;
  step: number;
  requestText?: string;
}
const EqEventInvite = ({ props, viewEventDetail, step,requestText }: IEqEventInvite) => {
  const userDetails: any = useAuthState();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const isStudent: boolean = ValidateIsStudent(userDetails);
  console.log(props);
  const { t, i18n } = useTranslation();
  const { organizer, user, events } = props;
  const getAllTrainerEvents = (trainerKey: any) => {
    console.log(trainerKey);
    if (trainerKey > 0) {
    }
  };
  const rowClicked = (item: any) => {
    console.log(step);
    if (step == 1) {
      if (viewEventDetail != null) {
        console.log("view pending invites");
        viewEventDetail(props);
      }
    }
  };
  React.useEffect(() => {
    console.log(step);
  }, []);
  return (
    <>
      <Row
        className="eq-invite-row"
        justify="space-around"
        onClick={rowClicked}
      >
        <Col
          xs={{ span: 4, offset: 0 }}
          sm={{ span: 4, offset: 0 }}
          md={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          xl={{ span: 2, offset: 0 }}
          xxl={{ span: 2, offset: 0 }}
        >
          <img
            src={
              !isStudent?(user?.userImage?.url || getDefaultImage(imageType.partner)):organizer?.userImage?.url || getDefaultImage(imageType.partner)
              
            }
            className="user-img"
            alt="user"
          />
        </Col>

        <Col xs={17} sm={17} md={20} lg={20}>
          <Row>
              { isStudent && 
            <Col xs={24} sm={24} md={5}>
              <Text className="eq-invite-row-subTitle" type="secondary">
              {t("team")}  {organizer?.firstName}
               
              </Text>
            </Col>
              }
            <Col xs={24} sm={24} md={5}>
              <Text className="eq-invite-row-title" >
              { !isStudent ? (user?.firstName + " " + user?.lastName):(organizer?.firstName + " " + organizer?.lastName)}
              </Text>
            </Col>
            <Col className="eq-invite-row-dis" xs={24} sm={24} md={8}>
            {t("from")}: { !isStudent ? (user?.userName ):(organizer?.userName)}
      
            </Col>
            <Col className="eq-invite-row-dis" xs={24} sm={24} md={6}>
           {requestText?requestText:t("invitesYouTo") +":"}  
           {events?.length==1?(events.length+t("activity")):(events.length+ t("activities"))}
             
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ span: 3, offset: 0 }}
          sm={{ span: 3, offset: 0 }}
          md={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          xl={{ span: 2, offset: 0 }}
          xxl={{ span: 2, offset: 0 }}
        >
          {(step === 1 || step === 3) && (
            <>
              <ShoppingCartOutlined style={{ fontSize: "125%" }} />
            </>
          )}
          <div>
            {step === 3 ||
              (step === 5 && (
                <span>
                  <CheckCircleTwoTone twoToneColor="#03dac5" />
                </span>
              ))}
            {step === 5 && (
              <span>
                <CloseCircleTwoTone twoToneColor="red" />
              </span>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};
export default EqEventInvite;
