import React, { useEffect } from 'react'
import { faCalendarDays, faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { FrontEndPoints } from '../../shared/Common';
import AsyncImage from '../../custom/asyncImage/AsyncImage';
import EqButton from '../../custom/eqButton/EqButton';
import { Card, Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckCircleOutlined } from '@ant-design/icons';
import EqReview from '../../custom/eqReview/EqReview';
import "./EqFeatureEventHolder.scss"

import { motion } from 'framer-motion';
import Reveal from '../../custom/Reveal/Reveal';
const EqFeatureEventHolder = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const gutter = { xs: 8, sm: 16, md: 24, lg: 32 };

    const advertise = [
        {
            id: 1,
            text: t("advertiseYouEventsOnPlatform"),
        },
        {
            id: 2,
            text: t("selectYourDiscipline"),
        },
        {
            id: 3,
            text: t("peopleInterestedInDiscipline"),
        },
    ]

    const createEvent = [
        {
            id: 1,
            text: t("createYourOwnInformation"),
        },
        {
            id: 2,
            text: t("limitSpacesOfPeople"),
        },
        {
            id: 3,
            text: t("getAutomaticallyGeneratedStartingListOnPerson"),
        },
        {
            id: 4,
            text: t("easyToSignUP"),
        },
        {
            id: 5,
            text: t("financialOverview"),
        },
        {
            id: 6,
            text: t("uploadYourTerms"),
        },
    ]





    const featuresData = [
        {
            icon: faCalendarDays,
            heading: t("optimizeYourHorseEvents"),
            description: t("optimizeYourHorseEventsInfo"),
        },
        {
            icon: faChartSimple,
            heading: t("saveTime"),
            description: t("saveTimeInfoEventHolder")
        },

    ];

    const callRegister = () => {
        navigate(FrontEndPoints.register);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "100%", opacity: 1, transition: { duration: 0.5, ease: 'easeInOut' } }}
                exit={{ height: 0, opacity: 0, transition: { duration: 0.5, ease: 'easeInOut' } }}
            >
                <div className='eq-types-features eq-event-holder-features'>
                    <div className="page-banner">
                        <AsyncImage className="hero-image" src="feature/eventholderBanner.jpg" />
                        <div className="overlay">
                            <h1 className="welcome-heading">
                                {t("fasterSmootherBooking")}
                                <br />
                                {t("andAdministration")}
                            </h1>
                            <div className="create-profile-btn">
                                <EqButton
                                    type="button"
                                    centered="true"
                                    onClick={callRegister}>
                                    {t("getStarted")}
                                </EqButton>
                            </div>
                        </div>
                    </div>

                    <div className='features-section'>
                        <Reveal>
                            <div className='feature-sub-section'>
                                <div className='feature-img-section'>
                                    <AsyncImage className="feature-img feature-img-1" src="feature/Ellipse1.png" />
                                    <AsyncImage className="feature-img feature-img-2" src="feature/Ellipse2.png" />
                                    <AsyncImage className="feature-img feature-img-3" src="feature/Ellipse3.png" />
                                    <AsyncImage className="feature-img feature-img-4" src="feature/Ellipse4.png" />
                                    <AsyncImage className="feature-img feature-img-5" src="feature/Ellipse5.png" />
                                    <AsyncImage className="feature-img feature-img-6" src="feature/Ellipse6.png" />
                                </div>

                                <div className='basic-feature'>
                                    <h3 className="heading">{t("whyChooseUs")}</h3>
                                    {/* <Row  className='feature-container'> */}
                                    <p className='heading-text'>{t("spendLessTimeInOffice")}
                                        <br />
                                        {t("lessAdministrationMoreHorsing")}
                                        <AsyncImage className="emoji" src="feature/25518.jpg" />
                                    </p>
                                    <Row className='feature-row' gutter={[gutter, gutter]}>
                                        {featuresData.map((feature, index) => (

                                            <Col className='feature-col' md={12} lg={12} sm={24}>
                                                <Card
                                                    key={index}
                                                    // hoverable
                                                    className="basic-content"
                                                // style={{ width: '100%' }}
                                                >
                                                    <Col>
                                                        <FontAwesomeIcon className="basic-icon" icon={feature.icon} />
                                                        <h4 className="content-heading">{feature.heading}</h4>
                                                        <p className='content-text'>{feature.description}</p>
                                                    </Col>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </Reveal>
                    </div>
                    <div className='offer-section'>
                        <h3 className="heading">{t("ourSolution")}</h3>
                        <p className='heading-text'>{t("saveTimeAndMoneyForYourBusiness")}</p>
                        <div className="offer-block">
                            <div className="container">
                                <Reveal>
                                    <Row className="offer-col offer-right-col">
                                        <Col className="offer-image" sm={24} md={10}>
                                            <AsyncImage className="image" src="feature/AdvertiseEvent.png" />

                                        </Col>
                                        <Col className="offer-content" sm={24} md={12}>
                                            <div className="offer-desc">
                                                <h4 className="sub-heading">
                                                    {t("advertiseYourEvent")}
                                                </h4>
                                                <p className='sub-text'>
                                                    {t("advertiseYourEventInfo")}
                                                </p>

                                                <div className='sub-text-points'>
                                                    <ul>
                                                        {advertise.map(event => (
                                                            <li key={event.id}>
                                                                <div >
                                                                    <CheckCircleOutlined className='icon' />
                                                                </div>
                                                                <span className="event-heading">{event.text}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Reveal>
                                <Reveal>
                                    <Row className="offer-col offer-left-col">

                                        <Col className="offer-content" sm={24} md={12}>
                                            <div className="offer-desc">
                                                <h4 className="sub-heading">
                                                    {t("createYourEvent")}
                                                </h4>

                                                <p className='sub-text'>
                                                    {t("createYourEventInfo")}
                                                </p>

                                                <div className='sub-text-points'>
                                                    <ul>
                                                        {createEvent.map(event => (
                                                            <li key={event.id}>
                                                                <div >
                                                                    <CheckCircleOutlined className='icon' />
                                                                </div>
                                                                <span className="event-heading">{event.text}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="offer-image" sm={24} md={10}>
                                            <AsyncImage className="image" src="feature/createEvent.png" />
                                        </Col>
                                    </Row>
                                </Reveal>
                            </div>
                        </div>
                    </div>
                    <div className="contact-us">
                        <Reveal>
                            <Row className="contact-container">
                                <Col className="contact-info" sm={24} md={12}>
                                    <h1 className="contact-heading">{t("peopleCanFindYourEvent")}</h1>
                                    <EqButton classNames="contact-btn">{t("contactUs")}</EqButton>
                                </Col>
                                <Col className="contact-img" sm={24} md={12}>
                                    <AsyncImage className="image" src="feature/contactEventHolder.png" />
                                </Col>
                            </Row>
                        </Reveal>
                    </div>
                    <EqReview />

                </div>
            </motion.div>
        </>
    )
}

export default EqFeatureEventHolder