import React, { useState, useEffect } from 'react';
import './EqSentInvitesList.scss'
import EqSentInvites, { IEqSentInvites } from '../eqSentInvites/EqSentInvites';
import { Row, Col, Checkbox } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

export interface IEqSentInvitesList {
    sentInvitesList: Array<IEqSentInvites>;
    checkAllFun?: any;
    checkAll?: boolean;
}
const EqSentInvitesList = ({ sentInvitesList, checkAllFun, checkAll, ...rest }: IEqSentInvitesList) => {
    const { t, i18n } = useTranslation();
    //const [isCheckAll, setIsCheckAll] = React.useState(checkAll);
    //console.log(checkAll);
    const checkAllFunc = () => {
        //setIsCheckAll(!isCheckAll);
        checkAllFun(sentInvitesList, checkAll);

        //sentInvitesList.map(
        //    (item: IEqSentInvites) => {
        //        item.selected = !isCheckAll;
        //    }
        //);

    }
    React.useEffect(() => {
       console.log(sentInvitesList);
    }, [sentInvitesList]);
    return (
        <>
            <div className="eq-list-container">

            <Row className="eq-jrl-row" justify="start" align="middle">
                    <Col className="eq-jrl-th  gutter-row" xs={2} sm={2} md={1}>
                        <Checkbox onChange={checkAllFunc} checked={checkAll}></Checkbox>
                </Col>
                {/*    <Col className="eq-jrl-th gutter-row" xs={11} sm={11} md={10} lg={10} xl={10} xxl={10}>*/}
                {/*        {t("name")}*/}
                    {/*</Col>*/}
                    <Col className="eq-jrl-th gutter-row" flex="auto">
                    {t("e-mail")}
                </Col>
                    <Col className="eq-jrl-th gutter-row" xs={0} sm={0} md={2}>{t("actions")}
                </Col>
            </Row>
            {sentInvitesList.length > 0 &&
                <>

                    {
                    sentInvitesList.map((item: IEqSentInvites, index: number) =>
                            <EqSentInvites key={index} {...item} ></EqSentInvites>
                        )
                    }
                </>
                }
                </div>
        </>
    );
}
export default EqSentInvitesList;