import React, { useEffect } from "react";
import "./EqStudentSchedule.scss";
import { Row, Col, Tabs, Space, Badge, Empty } from "antd";
import EqCalendar, { ICalendarProps } from "../../custom/eqCalendar/EqCalendar";
import { useTranslation, Trans } from 'react-i18next';

import EqTimePicker, {
  ITimePickerOptions,
} from "../../custom/eqTimePicker/EqTimePicker";
import { ValidateIsTrainer } from "../../shared/Common";

import moment from "moment";
import history from "../../shared/history";
import { ISelectProps } from "../../shared/Interfaces";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useAuthState } from "../../context/Context";
import TabPane from "antd/lib/tabs/TabPane";
import EqProductList from "../../custom/eqProductList/EqProductList";
import { AnyARecord } from "dns";
import { EqProfessionType } from "../../shared/enums";
import { useLocation, useNavigate } from "react-router-dom";
import EqEventGrid from "../../eqTrainer/eqEventGrid/EqEventGrid";
import EqActivityTrainingList from "../../eqTrainer/eqActivityTrainingList/EqActivityTrainingList";
import EqStudentEventGrid from "../../eqTrainer/eqStudentEventGrid/EqStudentEventGrid";
import { EqStudentProductOrderCard } from "../eqStudentProductOrderCard/EqStudentProductOrderCard";
import EqStudentCalendar, { IStudentCalendarProps } from "../../custom/eqStudentCalendar/EqStudentCalendar";
import RevealList from "../../custom/RevealList/RevealList";

//testgit
export interface IEqStudentSchedule {
  isOwner: any;
  eventList: Array<IEqEventData>;
  trainings: Array<any>;
  studentProductOrders: Array<any>;
  isStudent: boolean;
  // onStudentJoin?: any;
  // onCreateOrUpdateTraining?: any;
  // onCreateOrUpdateEvent?: any;
  // onRemoveTraining?: any;
  // onRemoveEvent?: any;
  // imagesData?: any;
  // areas?: any;
  // weekDays?: any;
  // population?: any;
  // groupPopulation?: any;
  // trainerStudents?: any;
  // studentHorsesList?: any;
  // profileData?: any;
  // activitiesList?: any;
  // publicProducts?: any;
  // isSubscribed: boolean;
  // trainersOptions?: any;
  // schoolEventTypes?: any;
  // productOptions?: any;
  // schoolStudents?: any;
}

const EqStudentSchedule = ({
  isOwner,
  eventList,
  trainings,
  isStudent,
  studentProductOrders,
  // onStudentJoin,
  // onCreateOrUpdateTraining,
  // onRemoveTraining,
  // onCreateOrUpdateEvent,
  // onRemoveEvent,
  // areas,
  // imagesData,
  // weekDays,
  // population,
  // groupPopulation,
  // trainerStudents,
  // studentHorsesList,
  // profileData,
  // activitiesList,
  // publicProducts,
  // isSubscribed,
  // trainersOptions,
  // schoolEventTypes,
  // productOptions,
  // schoolStudents,
  ...rest
}: IEqStudentSchedule) => {
  const userDetails: any = useAuthState();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const navigate = useNavigate();
  const location = useLocation();
  const [eventData, setEventData] = React.useState(eventList);
  const [gridView, setGridView] = React.useState(false);
  const toggleGridView = () => {
    setGridView(!gridView);
  };
  const { t, i18n } = useTranslation();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

  const tomorrow = new Date();
  tomorrow.setHours(24, 0, 0, 0);
  const tomorrowTime = tomorrow.getTime();

  const dayAfterTomorrow = new Date();
  dayAfterTomorrow.setHours(48, 0, 0, 0);
  const dayAfterTomorrowTime = dayAfterTomorrow.getTime();

  // activities
  // const todayActivities = activitiesList?.all?.filter(
  //   (opt: any) => opt.startDate < tomorrowTime && opt.startDate > today
  // );

  // const todayTraining = activitiesList?.trainings?.filter(
  //   (opt: any) => opt.startDate < tomorrowTime && opt.startDate > today
  // );

  // const todayEvents = activitiesList?.events?.filter(
  //   (opt: any) => opt.startDate < tomorrowTime && opt.startDate > today
  // );

  // const [selectedActivities, setSelectedActivities] =
  //   React.useState(todayEvents);
  const [selectedActivitiesList, setSelectedActivitiesList] = React.useState(
    {}
  ) as any;

  const [selectedDate, setSelectedDate] = React.useState(
    moment(new Date()).format("dddd, DD MMM YYYY")
  );

  const timeProps: ITimePickerOptions = {
    classNames: "eq-schedule",
    isBordered: false,
    isSuffixIcon: false,
    onChangeFunc: (time: any) => { },
  };

  const dateChanged = (date: any, dateString: string) => { };

  const DateProps = {
    classNames: "eq-schedule",
    isBordered: false,
    isSuffixIcon: false,
    defaultValue: new Date(),
    placeHolder: "",
    onChangeFunc: dateChanged,
  };

  const dateSelected = (e: any) => {
    const selectedDate = e._d;
    var date = moment(selectedDate).format("MM/DD/YYYY");
    // var out1 = `${FrontEndPoints.trainer.createEvent}?date=${date}`;
    // if (isTrainer) {
    //   history.push(out1);
    // }
    setSelectedDate(moment(selectedDate).format("dddd, DD MMM YYYY"));
    selectActivities(selectedDate);
  };

  const selectActivities = (value: any) => {
    const currentDate = new Date(value);
    currentDate.setHours(0, 0, 0, 0);
    const currentTime = currentDate.getTime();

    const nextDate = new Date(value);
    nextDate.setHours(24, 0, 0, 0);
    const nextDateTime = nextDate.getTime();

    // const currentDateActivities = activitiesList?.all?.filter(
    //   (opt: any) => opt.startDate < nextDateTime && opt.startDate > currentTime
    // );

    // const currentDateTraining = activitiesList?.trainings?.filter(
    //   (opt: any) => opt.startDate < nextDateTime && opt.startDate > currentTime
    // );

    // const currentDateEvents = activitiesList?.events?.filter(
    //   (opt: any) => opt.startTime < nextDateTime && opt.startTime > currentTime
    // );

    // setSelectedActivitiesList({
    //   all: [...currentDateActivities],
    //   events: [...currentDateEvents],
    //   trainings: [...currentDateTraining],
    // });
    // setSelectedActivities(currentDateEvents);
  };

  const handleOnCreateOrUpdateTraining = (oldData: any, newData: any) => {
    console.log(oldData);

    // onCreateOrUpdateTraining(oldData, newData);
  };
  // const handleRemoveTraining = (data: any) => {
  //
  //   console.log(onRemoveTraining);

  //   onRemoveTraining(data);
  // };

  const calendarProps: IStudentCalendarProps = {
    isOwner: isOwner,
    selectFun: dateSelected,
    events: eventList,
    trainings: trainings,
    // products: publicProducts,
    // imagesData: imagesData,
    // areas: areas,
    // weekDays: weekDays,
    // population: population,
    // groupPopulation: groupPopulation,
    // trainerStudents: trainerStudents,
    // studentHorsesList: studentHorsesList,
    // onCreateOrUpdateTraining: handleOnCreateOrUpdateTraining,
    // onCreateOrUpdateEvent: onCreateOrUpdateEvent,
    // onRemoveTraining: onRemoveTraining,
    // onStudentJoin:onStudentJoin,
  };
  const onChange = (key: any) => {
    console.log(key);
  };

  React.useEffect(() => {
    console.log(location.pathname);
    console.log(isStudent);
    // console.log(isSubscribed);
    setEventData(eventList);
    // if (activitiesList.all.length > 0) {
    //   setSelectedActivitiesList({
    //     all: [...todayActivities],
    //     events: [...todayEvents],
    //     trainings: [...todayTraining],
    //   });
    // }
  }, [
    eventList,
    trainings
    //  profileData,
    //   activitiesList
    //   , isSubscribed
  ]);
  return (
    <div className="st-shl-tabs">
      <Tabs defaultActiveKey="1" onChange={onChange}>
        <TabPane tab={t("schedules")} key="1">
          <RevealList>
            <Row className="eq-schedule-calendar">
              {/* <Col md={5} className="eq-schedule-range">
              <Row className="calendar-heading">
                <Col className="range-caption">Locations</Col>
              </Row>

              <Row></Row>
            </Col> */}
              <Col xs={24} sm={24} md={24} className="eq-Calendar">
                <div className="cal-main">
                  <EqStudentCalendar {...calendarProps} />
                </div>
              </Col>
            </Row>
          </RevealList>
        </TabPane>

        <TabPane tab={t("events")} key="2">
          <RevealList>
            {eventList?.length > 0 ? (
              <div className="eq-section">
                <EqStudentEventGrid
                  {...{
                    studentEventClasses: [...eventList],
                    // removeEventFunc: removeEvent,
                    // editEventFunc: editEvent,
                  }}
                ></EqStudentEventGrid>
              </div>
            ) : (
              <Empty description={t("noEventsAvailable")} />
            )}
          </RevealList>
        </TabPane>

        {location.pathname !== "/eventHolder/profile" && (
          <>
            <TabPane tab={t("trainings")} key="3">
              <RevealList>
                <>
                  {trainings?.length > 0 && (
                    <Col span={24}>
                      <EqActivityTrainingList
                        {...{
                          eventList: [...trainings],
                          // onCreateOrUpdateActivity: GetUserAcceptedEvents,
                        }}
                      ></EqActivityTrainingList>
                    </Col>
                  )}
                </>
              </RevealList>
            </TabPane>

            <TabPane tab={t("products")} key="4">
              <RevealList>
                <>
                  {studentProductOrders?.length > 0 && (
                    <Row >
                      <Col span={24}>
                        <Row className="eq-db-joinProducts">
                          {studentProductOrders.map((item: any, index: number) => (
                            <Row >
                              <EqStudentProductOrderCard productOrder={item} />
                            </Row>

                            // <EqMessage key={index} {...item}></EqMessage>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  )}
                </>
              </RevealList>
            </TabPane>
          </>
        )}
      </Tabs>
    </div>
  );
};
export default EqStudentSchedule;
