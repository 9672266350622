import * as React from "react";
import "./EqSchoolTrainingsPrevious.scss";
import { Row, Avatar, Skeleton, Empty } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsUser,
  getDefaultImage,
  imageType,
  GenericMessage,
  openNotificationWithIcon,
  ValidateIsTrainer,
} from "../../shared/Common";
import Api from "../../shared/Api";
import "./EqSchoolTrainingsPrevious.scss";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import {
  EqDisciplineType,
  EqTrainingType,
  ProductType,
} from "../../shared/enums";

import { IGetSchoolTrainingDto } from "../../shared/interfaces/schoolTraining/IGetSchoolTrainingDto";
import EqSchoolTrainingCollapse from "../eqSchoolTrainingCollapse/EqSchoolTrainingCollapse";
export interface IEqSchoolTrainingsPrevious {
  updateCount?: (count: number) => void;
}
const EqSchoolTrainingsPrevious = ({ updateCount }: IEqSchoolTrainingsPrevious) => {
  const userDetails: any = useAuthState();
  const navigate = useNavigate();
  const location = useLocation();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);

  const isUserValid: boolean = ValidateIsUser(userDetails);
  const { t, i18n } = useTranslation();

  const [monthCount, setMonthCount] = React.useState<number>(1);
  const [loading, setLoading] = React.useState<boolean>(false);

  //

  // const [isSuccess, setSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [toggle, setToggle] = React.useState(false);
  const [createSchoolTrainingHeader, setCreateSchoolTrainingHeader] =
    React.useState([]) as any;
  const [trainersList, setTrainersList] = React.useState([]) as any;

  const [areas, setAreas] = React.useState([]) as any;
  const [trainersOptions, setTrainersOptions] = React.useState([]) as any;

  const [productOptions, setProductOptions] = React.useState([]) as any;
  const [products, setProducts] = React.useState([]) as any;

  const [weekDays, setWeekDays] = React.useState([]) as any;
  const [eventTypes, setEventTypes] = React.useState([]) as any;

  // const [population, setPopulation] = React.useState([]) as any;
  const [schoolStudents, setSchoolStudents] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;
  const [studentHorsesList, setStudentHorsesList] = React.useState([]);

  const [showEventList, setShowEventList] = React.useState(true);
  const [createSchoolTrainingShow, setCreateSchoolTrainingShow] =
    React.useState(false);
  const [createSchoolTrainingList, setCreateSchoolTrainingList] =
    React.useState([] as Array<any>);
  const [trainingsList, setTrainingsList] = React.useState(null) as Array<any>;
  const [activityTitle, setActivityTitle] = React.useState("");

  const [trainerHorsesList, setTrainerHorsesList] = React.useState({});

  const GetSchoolStudents = () => {
    Api.get(ApiEndpoints.GetSchoolStudents).then(
      (response) => {
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var schoolStudents = response.data;
          setIsError(false);
          setMessage("");
          setSchoolStudents(response.data);

          // getting both student and School Horses and making list
          // and adding school horse in each student horse list

          [...schoolStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.school.horses.length > 0) {
              [...item.school.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getAreas = () => {
    Api.get(ApiEndpoints.eventGetAreas).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetSchoolTrainers = async () => {
    try {
      const response = await Api.get(ApiEndpoints.SchoolGetTrainers);

      if (response && response.status === 200) {
        const trainers = response.data;
        setTrainersList(trainers);

        const trainerObjList = [
          {
            key: userDetails.key,
            value: userDetails.name,
            label: userDetails.name,
            icon: (
              <Avatar
                size={30}
                src={
                  userDetails.userImage
                    ? userDetails.userImage.url
                    : getDefaultImage(imageType.trainer)
                }
              />
            ),
          },
          ...trainers.map((item: any) => ({
            key: item.key,
            value: item.firstName + item.lastName,
            label: item.firstName,
            icon: (
              <Avatar
                size={30}
                src={
                  item.userImage
                    ? item.userImage.url
                    : getDefaultImage(imageType.trainer)
                }
              />
            ),
          })),
        ];

        setTrainersOptions(trainerObjList);
      }
    } catch (error) {
      throw error;
    }
  };

  const GetProductList = () => {
    Api.get(ApiEndpoints.UserProduct).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          let data = response.data;

          var productOptions = [] as any;

          if (data) {
            var publicProducts = data?.filter(
              (opt: any) => opt.type == ProductType.Public
            );

            publicProducts.map((item: any, Idx: any) => {
              let obj = {
                key: item.id,
                value: item.title,
                label: item.title,
                limit: item.limit,
              };
              productOptions.push(obj);
            });
            setProductOptions(productOptions);
            setProducts(publicProducts);
          }
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const createEventTypes = () => {
    var eventTypes = [];

    eventTypes.push({
      key: EqDisciplineType.Dressage,
      value: EqTrainingType[EqDisciplineType.Dressage],
    });
    eventTypes.push({
      key: EqDisciplineType.Jumping,
      value: EqTrainingType[EqDisciplineType.Jumping],
    });
    eventTypes.push({
      key: EqDisciplineType.Open,
      value: EqTrainingType[EqDisciplineType.Open],
    });
    // eventTypes.push({
    //   key: EqDisciplineType.Product,
    //   value: EqDisciplineType[EqDisciplineType.Product],
    // });

    setEventTypes(eventTypes);
  };

  const handleLoadMore = () => {
    setLoading(true);
    let newMonthCount = monthCount + 1;
    setMonthCount(newMonthCount);
  };
  React.useEffect(() => {
    GetSchoolPreviousTrainingsOfMonths();
  }, [monthCount]);

  React.useEffect(() => {
    console.log("School Trainings", location);
    GetSchoolTrainers();
    GetSchoolStudents();
    createEventTypes();
    getWeekDays();
    GetProductList();
    getAreas();
    // getPopulation();
    getGroupPopulation();
    GetSchoolPreviousTrainingsOfMonths();

    window.scrollTo(0, 0);
  }, []);

  const GetSchoolPreviousTrainingsOfMonths = async () => {
    setErrorMessage("");

    let path = `${ApiEndpoints.SchoolTraining.GetSchoolPreviousTrainingsOfMonths}?monthCount=${monthCount}`;
    Api.get(path).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          setTrainingsList(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
        setLoading(false);
      },
      (error) => {
        setLoading(false);

        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const handleOnUpdateTraining = (newData: any, oldData: any) => {
    if (trainingsList != undefined) {
      const trainings = [...trainingsList];
      var foundIndex = trainings.findIndex((x) => x.key == oldData.key);
      trainings[foundIndex] = newData;
      setTrainingsList(trainings);
    }
  };

  const editTraining = () => {};

  const removeTraining = (
    trainingData: IGetSchoolTrainingDto,
    refund?: boolean
  ) => {
    let path =
      ApiEndpoints.SchoolTraining.Delete +
      `?key=${trainingData.key}&refund=${refund}`;
    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(trainingData);
        } else {
          setIsError(true);
          setErrorMessage(GenericMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GenericMessage(error.response.status));
        openNotificationWithIcon(
          GenericMessage(error.response.status),
          "Error occurred while Deleting. Please try again or contact administrator."
        );
      }
    );
    if (updateCount) {
      updateCount(trainingsList.length);
    }
  };
  const removeItem = (data: any) => {
    if (trainingsList != undefined) {
      var updatedList =
        trainingsList != undefined
          ? trainingsList.filter((item: any) => item.key !== data.key)
          : new Array<any>();
      setTrainingsList(updatedList);

      trainingsList.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  // selections in case of Trainer
  return (
    <div className={`eq-school-training`}>
      <div className="eq-container-fluid">
        <Row>
          {[...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              loading={!trainingsList}
              paragraph={{ rows: 2 }}
              active
            />
          ))}
        </Row>
        {/* {trainingsList && (
          <>
            {trainingsList.length > 0 ? (
              <SchoolTrainingList
                trainingList={[...trainingsList]}
                trainersOptions={trainersOptions}
                eventTypes={eventTypes}
                productOptions={productOptions}
                products={products}
                areas={areas}
                weekDays={weekDays}
                // population={population}
                groupPopulation={groupPopulation}
                schoolStudents={schoolStudents}
                studentHorsesList={studentHorsesList}
                removeEventFunc={removeTraining}
                editEventFunc={editTraining}
                onCreateOrUpdateSchoolTraining={handleOnUpdateTraining}
              ></SchoolTrainingList>
            ) : (
              <Row justify="center">
                <Empty description={t("noTrainingsAvailable")} />
              </Row>
            )}
          </>
        )} */}

        {trainingsList && (
          <>
            {trainingsList.length > 0 ? (
              <>
                {trainingsList.map((item: any, index: number) => (
                  <EqSchoolTrainingCollapse
                    key={index}
                    training={item}
                    trainersOptions={trainersOptions}
                    productOptions={productOptions}
                    products={products}
                    eventTypes={eventTypes}
                    areas={areas}
                    weekDays={weekDays}
                    // population={population}
                    groupPopulation={groupPopulation}
                    schoolStudents={schoolStudents}
                    studentHorsesList={studentHorsesList}
                    removeEventFunc={removeTraining}
                    editEventFunc={editTraining}
                    // removeAttendeeFunc={removeAttendeeFunc}
                    onCreateOrUpdateSchoolTraining={handleOnUpdateTraining}
                    isPreviousTraining={true}
                  ></EqSchoolTrainingCollapse>
                ))}

                <Row justify="center" className="section-padding">
                  <EqButton
                    type="submit"
                    classNames="btn-background-orange"
                    onClick={handleLoadMore}
                    loading={loading}
                  >
                    Load More
                  </EqButton>
                </Row>
              </>
            ) : (
              <Row justify="center">
                <Empty description={t("noTrainingsAvailable")} />
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default EqSchoolTrainingsPrevious;
