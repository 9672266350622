import React, { useEffect, useState } from 'react';
import './EqUserOption.scss';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { Professions } from '../../shared/enums';
export interface IEqUserOption {
    formData: any
	setFormData: any
}
const EqUserOption = ({formData, setFormData}: IEqUserOption) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(Professions.trainer.toString());

    const togglerProps = {
        items: [
            { key: "2", value: t("trainer") },
            { key: "8", value: t("ridingSchool") },
            { key: "7", value: t("eventHolder") },
            { key: "3", value: t("student") },
        ],
        changeFunc: (e: any) => {
            
            const selectedValue = e.target.value;
            setSelected(selectedValue);
            setFormData({ ...formData, userType: +selectedValue });
        },
        defaultSelectedValue: formData.userType,
    };


    useEffect(()=>{
    if(formData.userType){
        setSelected(formData.userType+"")
    }
    },[formData])

    return (
        <div className='eq-userOption'>
            <Radio.Group
                className='eq-toggle-group'
                onChange={togglerProps.changeFunc}
                value={selected}
                optionType="button"
                buttonStyle="solid"
            >
                {togglerProps.items.map(item => (
                    <Radio.Button
                    className='radio-btn'
                        key={item.key}
                        value={item.key}
                    >
                        {item.value}
                    </Radio.Button>
                ))}
            </Radio.Group>
        </div>
    );
}

export default EqUserOption;
