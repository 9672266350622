import React, { useEffect, useState } from 'react';
import './EqCard.scss';
import AsyncImage from '../asyncImage/AsyncImage';
import {
    Card
} from 'antd';

export interface IEqCard {
    key?: number;
    imgSrc: string;
    title: any;
    description: any,
    actionsList?:any,
    date: any;
    alt?: string
}





const EqCard = ({ imgSrc, title, description, date, alt, actionsList }: IEqCard) => {
    const { Meta } = Card;

    return (
        <div className="eq-card">
            <Card
                hoverable
                style={{ width: '100%' }}
                cover={<AsyncImage className='event-img' alt={alt} src={imgSrc} />}
                actions={actionsList}
            >
                <Meta title={title} description={description} />
                <div className="time">{date}</div>
            </Card>
        </div>
    );
}

export default EqCard;
