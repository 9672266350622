import * as React from 'react';
import EqStudent, { IEqStudent } from '../eqStudent/EqStudent';
import './EqStudentList.scss'
import { Row, Col, Checkbox, message } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { IStudentGroupDto } from '../../shared/interfaces/IStudentGroupDto';
import EqButton from '../../custom/eqButton/EqButton';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useAuthState } from '../../context/Context';
import { ValidateIsTrainer } from '../../shared/Common';
import { useLocation } from 'react-router-dom';

export interface IEqStudentList {
    studentsList: Array<IEqStudent>;
    selected?: boolean;
}
const EqStudentList = ({ studentsList, selected }: IEqStudentList) => {
    const { t, i18n } = useTranslation();
    const userDetails: any = useAuthState();
    const location = useLocation();
    const isTrainer: boolean = ValidateIsTrainer(userDetails);
    const [isCheckAll, setIsCheckAll] = React.useState(false);
    const [students, setStudents] = React.useState(studentsList) as any;
    const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([] as any);
    const [copyMessage, contextCopyMessage] = message.useMessage();
    const checkAll = (e: any) => {

        setIsCheckAll(!isCheckAll);
        // setIsCheckAll(e.target.checked);

        const updatedStudentsList = students.map(
            (item: any) => {
                return {
                    ...item,
                    student: { ...item.student, selected: e.target.checked },
                    // selected: !isCheckAll

                }
            });
        setStudents(updatedStudentsList);
        const updatedSelectedCheckboxes = updatedStudentsList.filter((x: any) => x.student.selected);
        setSelectedCheckboxes(updatedSelectedCheckboxes);

    }

    const handleOnCheck = (s: any) => {

        const list = [...students];
        console.log(list)
        const foundIndex = list.findIndex((x: any) => x.student.email === s.email);
        console.log(foundIndex)
        const prevStudent = list.find((x: any) => x.student.email === s.email);
        console.log(prevStudent)
        prevStudent.student.selected = !prevStudent.student.selected;
        list[foundIndex] = prevStudent;
        setStudents(list);

        const updatedSelectedCheckboxes = list.filter((x) => x.student.selected);
        setSelectedCheckboxes(updatedSelectedCheckboxes);
    };


    const checkPathFortrainerList = (path: string): boolean => {
        if (
            path === "/school/trainers"
        ) {
            return false;
        } else {
            return true;
        }
    };
    const getEmailsText = () => {

        const emails = selectedCheckboxes.map((item: any) => item?.student.email || "")
        console.log(selectedCheckboxes);
        return emails;
    };

    const getNumbersText = () => {
        const numbers = selectedCheckboxes.map((item: any) => item?.student?.locations?.user?.phoneNumber || "")
        console.log(numbers);
        return numbers
    };
    const handleCopyLink = () => {
        copyMessage.open({
            type: "success",
            content: "Copied",
        });
    };
    React.useEffect(() => {
        //setStudents(studentsList);
        console.log(studentsList)
        console.log("selext", selectedCheckboxes)
        setStudents(studentsList);

    }, [studentsList]);
    return (
        <>
            <div className="eq-list-container">
                <Col className=" hidden-sm">
                    {contextCopyMessage}
                    {selectedCheckboxes.length > 1 && (
                        <>
                            <Col className='copy-data-stList-btn'>
                                <CopyToClipboard text={getEmailsText()}>
                                    <EqButton classNames="mail-copy" onClick={handleCopyLink}>{t("copyEmails")}</EqButton>
                                </CopyToClipboard>
                                <CopyToClipboard text={getNumbersText()}>
                                    <EqButton onClick={handleCopyLink}>{t("copyNumbers")}</EqButton>
                                </CopyToClipboard>
                            </Col>
                        </>
                    )}
                </Col>
                <Row className="eq-sl-row  hidden-sm" align="middle">
                    <Col className="eq-sl-th" span={1}>
                        <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                    </Col>
                    <Col className="eq-sl-th" xs={7} sm={6} md={4} lg={4}>
                        {t("name")}
                    </Col>
                    <Col className="eq-sl-th" xs={7} sm={6} md={5} lg={5}>
                        {t("e-mail")}
                    </Col>


                    <Col className="eq-sl-th" xs={0} sm={3} md={4} lg={4}>
                        {t("phoneNumber")}
                    </Col>

                    {checkPathFortrainerList(location.pathname) ?
                        (
                            <Col className="eq-sl-th" xs={0} sm={0} md={4} lg={4}>
                                {t("payments")} {t("status")}
                            </Col>
                        ) : (
                            ""

                        )
                    }

                    {checkPathFortrainerList(location.pathname) ?
                        (
                            <Col className="eq-sl-th" xs={7} sm={6} md={4} lg={4}>
                                {t("groups")}
                            </Col>
                        ) : (
                            <Col className="eq-sl-th" xs={10} sm={8} md={6} lg={7}>
                                {t("groups")}
                            </Col>

                        )
                    }


                    <Col className="eq-sl-th" span={2} >
                        {t("actions")}
                    </Col>
                </Row>
                <div className="hidden-md">
                    <Row justify="start" align="middle" className="eq-list-checkAll">
                        <Col span={2}>
                            <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                        </Col>
                        <Col flex="auto">
                        {t("checkAll")}
                        </Col>
                        <Col className="copy-data-btn-xs">

                            {selectedCheckboxes.length > 1 && (
                                <>
                                    <CopyToClipboard text={getEmailsText()}>
                                        <EqButton style={{ marginLeft: "7px" }}
                                            classNames="mail-copy" onClick={handleCopyLink}>{t("copyEmails")}</EqButton>
                                    </CopyToClipboard>
                                    <CopyToClipboard text={getNumbersText()}>
                                        <EqButton onClick={handleCopyLink}>{t("copyNumbers")}</EqButton>

                                    </CopyToClipboard>

                                </>
                            )}
                        </Col>

                        <Col>
                            <EqButton
                                type="button"
                                classNames="btn-background-orange"
                            >

                            </EqButton>
                        </Col>
                    </Row>
                </div>
                {students && students?.length > 0 &&
                    <>
                        <Col span={24}>
                            {
                                students.map((item: IEqStudent, index: number) =>
                                    <EqStudent
                                        key={index}
                                        student={item.student}
                                        deleteFunc={item.deleteFunc}
                                        onCheck={handleOnCheck}
                                        selected={item.selected}

                                    ></EqStudent>
                                )
                            }
                        </Col>
                    </>
                }
            </div>
        </>
    );
}
export default EqStudentList;