import React, { useState } from "react";
import "./EqSubscriptionProduct.scss";

import { Button, Col, Divider, Row, Space } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import EqSubscriptionProductForm from "./eqSubscriptionProductForm/EqSubscriptionProductForm";
import Api from "../../shared/Api";
import { openNotificationWithIcon } from "../../shared/Common";
import EqSubscriptionProductTable from "./eqSubscriptionProductTable/EqSubscriptionProductTable";

const EqSubscriptionProduct = () => {
  const [productFormVisible, setProductFormVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [subscriptionProducts, setSubscriptionProducts] = useState([]) as any; // Fetch products from your API

  const handleCreateSubscriptionProduct = (e: any) => {
    let data = e;

    Api.post("SubscriptionProduct", data).then(
      (result) => {
        console.log(result);
        
        setProductFormVisible(false);
        addItem(result.data);
      },
      (error) => {
        setProductFormVisible(false);
        openNotificationWithIcon(
          "Server error",
          "Error occurred while creating. Please try again or contact administrator."
        );
        // throw error;
      }
    );
  };
  React.useEffect(() => {
    GetSubscriptionProducts();
  }, []);

  const GetSubscriptionProducts = () => {
    Api.get("SubscriptionProduct").then(
      (response) => {
        if (response && response.status == 200) {
          console.log(response);
          setSubscriptionProducts(response.data);
        }
      },
      (error) => {
        throw error;
      }
    );
  };

  const handleDelete = (productId: any) => {
    
    Api.delete("SubscriptionProduct" + `/${productId}`).then(
      (response) => {
        if (response && response.status == 200) {
          
          removeItem(productId);
        } else {
          // error
          // 404 means not found
        }
      },
      (error) => {
        if (error.response && error.response.status === 409) {
          // Conflict error
          openNotificationWithIcon(
            "Conflict",
            "Product is already in used in Subscription Settings"
          );
        } else {
          // Other errors
          openNotificationWithIcon(
            "Server error",
            "Error occurred while creating. Please try again or contact the administrator."
          );
        }

       
      }
    );
  };

  const removeItem = (productId: any) => {
    if (subscriptionProducts != undefined) {
      var updatedList =
        subscriptionProducts != undefined
          ? subscriptionProducts.filter((item: any) => item.id !== productId)
          : new Array<any>();
      // setProductList(updatedList);
      setSubscriptionProducts(updatedList);
    }
  };
  const addItem = (product: any) => {
    const products = [...subscriptionProducts];
    products.push(product);
    setSubscriptionProducts(products);
  };

  return (
    <div className="eq-subscription-product">
      <Row gutter={[16, 16]}>
        <Col
          sm={{ span: 22, offset: 1 }}
          md={{ span: 20, offset: 2 }}
          lg={{ span: 18, offset: 3 }}
        >
          <Row>
            <Col span={24}>
              <h2 className="eq-heading-row"> Subscription Products</h2>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <h4>Add Subscription Product</h4>
            </Col>
            <Col
              style={{
                display: "flex",
                flex: "1",
              }}
            >
              <EqButton
                classNames="btn-create"
                type="button"
                onClick={() => setProductFormVisible(true)}
              >
                Create Product
              </EqButton>
            </Col>
            <EqSubscriptionProductForm
              visible={productFormVisible}
              onCreate={handleCreateSubscriptionProduct}
              onCancel={() => setProductFormVisible(false)}
              // product={selectedProduct} to implement Edit
            />
          </Row>
          <Divider
            dashed
            
          />

          <EqSubscriptionProductTable
            products={subscriptionProducts}
            onDelete={handleDelete}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EqSubscriptionProduct;
