import React, { Children } from 'react'
import { motion } from 'framer-motion';
import { useLocation } from 'react-router';


 interface IEAnimation {
  children: JSX.Element

}
const AnimationDiv = ({ children }: IEAnimation) => {

  return (
    <div>
      <motion.div
        initial={{ opacity: 0, y: '-100%' }}
        animate={{ opacity: 1, y: '0%' }}
        exit={{ opacity: 0, y: '100%'}}
        transition={{ duration: 0.5,delay: 0.5, ease: 'easeInOut' }}
      >
        {children}
      </motion.div>
    </div>
  )
}

export default AnimationDiv