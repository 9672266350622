import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Tag, Space, Row, Col } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import EqButton from "../../../../custom/eqButton/EqButton";
import { EqInput } from "../../../../custom/eqInput/EqInput";
import "./EqQuestionModal.scss";
import { QuestionType } from "../../../../shared/enums";
import { useTranslation } from "react-i18next";

export interface IEqQuestionModal {
  visible?: any;
  type?: QuestionType;
  onCreate?: any;
  onCancel?: any;
}
const EqQuestionModal = ({
  visible,
  onCreate,
  onCancel,
  type,
}: IEqQuestionModal) => {
  const { t } = useTranslation();

    const [questionText, setQuestionText] = useState('');
    const [optionsText, setOptionsText] = useState('');
    const [options, setOptions] = useState([]) as any;
  
    const handleQuestionTextChange = (e:any) => {
      setQuestionText(e.target.value);
    };
  
    const handleOptionsTextChange = (e:any) => {
      setOptionsText(e.target.value);
    };
  
    const addOption = () => {
      const newOption = optionsText.trim();
      if (newOption !== '') {
        setOptions([...options, newOption]);
        setOptionsText('');
      }
    };
  
    const handleCreate = () => {
      if (type === QuestionType.Text) {
        onCreate(questionText,QuestionType.Text, null);
      } else if (type === QuestionType.MultipleChoice) {
        onCreate(questionText,QuestionType.MultipleChoice, options);
      }
      setQuestionText('');
      setOptionsText('');
      setOptions([]);
    };
  // Reset options and optionsText when modal is closed or reopened

  useEffect(() => {
    if (!visible) {
      setOptions([]);
      setOptionsText('');
    }
  }, [visible]);

  return (
    <div className="eq-question-modal">
      <Modal
        open={visible}
        title={type === QuestionType.Text ? t("addField") : t("addMultipleChoice")}
        okText={t("create")}
        cancelText={t("cancel")}
        onCancel={onCancel}
        onOk={handleCreate}
        footer={[
          <Row justify="center">
            <Space>
              <Col>
                <EqButton
                  block
                  type="button"
                  classNames="btn-background-orange"
                  onClick={handleCreate}
                >
                  {t("create")}
                </EqButton>
              </Col>
              <Col>
                <EqButton
                  type="button"
                  bordered="true"
                  classNames="btn-light-gray"
                  onClick={onCancel}
                >
                  {t("cancel")}
                </EqButton>
              </Col>{" "}
            </Space>
          </Row>,
        ]}
      >
        <h3 className="input-label">{t("addLabel")}</h3>
        <EqInput
          placeholder={
            type === QuestionType.Text ? t("enterLabel") : t("enterLabelOptions")
          }
          value={questionText}
          onChange={handleQuestionTextChange}
        />

        {type === QuestionType.MultipleChoice && (
          <div className="question-modal">
            <h3 style={{ marginTop: 16 }} className="input-label">
              {t("addOptions")}
            </h3>

            <div className="options-container">
              {options.map((option: any, index: any) => (
                <Tag
                  key={index}
                  closable
                  onClose={() =>
                    setOptions(options.filter((_: any, i: any) => i !== index))
                  }
                >
                  {option}
                </Tag>
              ))}
              <input
                type="text"
                value={optionsText}
                onChange={handleOptionsTextChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === ",") {
                    e.preventDefault();
                    addOption();
                  }
                }}
                placeholder="Add option"
              />
            </div>
          </div>
        )}

      </Modal>
    </div>
  );
};
export default EqQuestionModal;
