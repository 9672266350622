import { Tag } from 'antd'
import React from 'react'
import './EqTags.scss'
import Item from 'antd/lib/list/Item'
import { group } from 'console';


export interface IEqTags {
    //changeFunc?: (e: any) => void;
    text?: string;
    centered?: any;
    borderColor?: boolean;
    type?: any;
    className?: string;
    color?: any;
    style?: any;
    group? : boolean
    dicipline? : boolean
}
const EqTags = ({ text, className, borderColor, color, style , group, dicipline}: IEqTags) => {
    const tagStyle = {
        backgroundColor: `${color}15`,
        color: color,
    };

 

    const afterStyle = {
        color: color,
        borderBottom:  `13px solid #transparent`,
        borderLeft: `10px solid ${color}15 ${className || ''}`,
        borderTop: `13px solid transparent`,
    }
    

    return (
        <div className={`tag-body ${borderColor ? 'borderColor' : ''} `}>
            <Tag className={`tag  ${className || ''}  ${dicipline ? 'dicipline' : ''}`}
                style={tagStyle}
            >
                {text}
            </Tag>
            <div className={`after  ${className || ''} ${dicipline ? 'dicipline' : ''}`} style={afterStyle} ></div>
        </div>
    );
}

export default EqTags