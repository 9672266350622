import React, { useEffect, useState } from "react";
import "./eqToggleBadge.scss";
import { Badge, Button, Radio } from "antd";
//test
export interface IEqToggleBadge {
  items: Array<IEqToggleBadgeItem>;
  changeFunc: (selectedValue: string) => void;
  classNames?: string;
  defaultSelectedValue?: any;
  radioGroupClasses?: string;
  isFlex?: boolean;
  name?: string;
}

export interface IEqToggleBadgeItem {
  key: string;
  value: any;
  icon?: any;
  count?: any;
}

const EqToggleBadge = ({
  items,
  changeFunc,
  classNames,
  defaultSelectedValue,
  radioGroupClasses,
  isFlex,
  name,
}: IEqToggleBadge) => {
  const [selectedValue, setSelectedValue] = useState(
    defaultSelectedValue?.key ?? defaultSelectedValue
  );

  //console.log(defaultSelectedValue);

  React.useEffect(() => {
    setSelectedValue(
      defaultSelectedValue?.key?.toString() ?? defaultSelectedValue?.toString()
    );
  }, [defaultSelectedValue]);

  const onChange = (e: any) => {
    setSelectedValue(e.target.value);
    //console.log(e.target.value);
    changeFunc(e.target.value);
  };

  return (
    <div
      className={`eq-toggle-badge ${
        classNames && classNames.length ? classNames : ""
      }`}
    >
      <Radio.Group
        name={name && name.length ? name : ""}
        onChange={onChange}
        value={selectedValue}
        className={`${
          radioGroupClasses && radioGroupClasses.length ? radioGroupClasses : ""
        } ${isFlex ? " radio-group-flex" : ""}`}
      >
        {items.map((item: IEqToggleBadgeItem) => (
          <Badge showZero={false} count={item.count} offset={[-5, 3]}>
         <Radio.Button
            key={item.key}
            value={item.key.toString()}
            className={`${isFlex ? " " : ""}`}
          >
           
            {item.icon}
            {item.value}
          </Radio.Button>
          </Badge>
        ))}
      </Radio.Group>
    </div>
  );
};

export default EqToggleBadge;
