import * as React from 'react';
import './EqTrainerEventInvitationBoard.scss';
import Api from '../../shared/Api';
import { ApiEndpoints } from '../../shared/Common';
import { Row, Col } from 'antd';
import { EqEventAttendeeStatus } from '../../shared/enums';
import EqEventInvitesList from '../../eqStudent/EqEventinviteList/EqEventinviteList';
import EqEventInviteDetail from '../../eqStudent/EqEventInviteDetail/EqEventInviteDetail';

export interface IEqTrainerEventInvitationBoard {
   onloadActivity?: any;
  }

const EqTrainerEventInvitationBoard = ({onloadActivity}:IEqTrainerEventInvitationBoard) => {
    const [messageString, setMessageString] = React.useState('');
    const [eventInvites, setEventInvites] = React.useState([] as any);
    const [pendingInvites, setPendingInvites] = React.useState([] as any);
    const [acceptedInvites, setAcceptedInvites] = React.useState([] as any);
    const [steps, setSteps] = React.useState(1 as number);
    const [eventDetail, setEventDetail] = React.useState({} as any);
    
    
    function AcceptEventInvite(e:any) {
        // e.stopPropagation();
        // setStatus("accept");
        Api.post(ApiEndpoints.AcceptStudentEventInvite,{key:e.eventKey,email: e.user.email} ).then(response => {
            if (response && response.status == 200) {
                GetAllPendingTrainerEventInvites();
                setSteps(1)
            } else {
                setMessageString('');
            }
        }, err => {
            setMessageString('');
        });
    }
    function AcceptAllEventInvites(e:any) {
       
        Api.post(ApiEndpoints.AcceptAllStudentEventInvites, e ).then(response => {
            if (response && response.status == 200) {
                GetAllPendingTrainerEventInvites();
                setSteps(1)
            } else {
                setMessageString('');
            }
        }, err => {
            setMessageString('');
        });
    }

    function RejectAllEventInvites(e:any) {

        Api.post(ApiEndpoints.RejectAllStudentEventInvites, e).then(response => {
            if (response && response.status == 200) {
                GetAllPendingTrainerEventInvites();
                setSteps(1)
            } else {
                setMessageString('');
            }
        }, err => {
            setMessageString('');
        });
    }
    function RejectEventInvite(e:any) {
        // e.stopPropagation();
        // setStatus("accept");
        Api.post(ApiEndpoints.RejectStudentEventInvite, {key:e.eventKey,email: e.user.email}).then(response => {
            if (response && response.status == 200) {
                GetAllPendingTrainerEventInvites();
                setSteps(1)
            } else {
                setMessageString('');
            }
        }, err => {
            setMessageString('');
        });
    }
   
   
    // function AcceptEventInvite(e:any) {
    //     // e.stopPropagation();
    //     // setStatus("accept");
    //     Api.post(ApiEndpoints.AcceptEventInvite, { eventKey: e }).then(response => {
    //         if (response && response.status == 200) {
    //             GetAllPendingTrainerEventInvites();
    //         } else {
    //             setMessageString('');
    //         }
    //     }, err => {
    //         setMessageString('');
    //     });
    // }

    const GetAllPendingTrainerEventInvites = () => {
        setMessageString("");
        Api.get(ApiEndpoints.GetAllPendingTrainerEventInvites).then(response => {
            if (response && response.status == 200) {
                
                var pendingInvites = [] as any;
                var data = response?.data ? response?.data : [] as any;
                setPendingInvites(data);
            

                if(data.length)
                {
                    onloadActivity(true);
                }
                else{
                    onloadActivity(false);

                }
                //data.map((d: any, index: number) => {
                //    pendingInvites.push(d);
                //});
                //console.log(pendingInvites);
            } else {
                setMessageString("Not found");
            }
        }, err => {
            setMessageString("Not found");
        });
    }
    const getAcceptedInvitations = () => {
        setMessageString("");
        Api.get(ApiEndpoints.getAcceptedUserEventsInvites).then(response => {
            if (response && response.status == 200) {
                var acceptedInvites = [] as any;
                var data = response?.data ? response?.data : [] as any;
                setAcceptedInvites(data);
                //data.map((d: any, index: number) => {
                //    acceptedInvites.push(d);
                //});
            } else {
                setMessageString("Not found");
            }
        }, err => {
            setMessageString("Not found");
        });
    }
    const getAllInvitations = () => {
        setMessageString("");
        Api.get(ApiEndpoints.getAllUserEventsInvites).then(response => {
            if (response && response.status == 200) {
                console.log(response);

                var allInvites = [] as any;
                var data = response?.data ? response?.data : [] as any;
                setEventInvites(data);
            } else {
                setMessageString("Not found");
            }
        }, err => {
            setMessageString("Not found");
        });
    }
    const viewEventDetail = (detail: any) => {
        
        if(steps===1){
           setSteps(2);
            console.log(detail);
            setEventDetail(detail);
        }
        else{
            setSteps(1);

        }
    }
    const acceptClickedFun = (event: any) => {  
        AcceptEventInvite(event)
        // setSteps(3);
        console.log(event);
    }
    const acceptAllClickedFun = (events: any) => {
        let result = events.map((item:any) => {return {key:item.eventKey,email:item.user.email}});
        AcceptAllEventInvites(result);
        console.log(events);
        // setSteps(3);
    }
    const rejectClickedFun = (event: any) => {
        
        RejectEventInvite(event)
        console.log(event);
        // setSteps(5);
    }
    const rejectAllClickedFun = (events: any) => {
        let result = events.map((item:any) => {return {key:item.eventKey,email:item.user.email}});
        RejectAllEventInvites(result)
        // setSteps(5);
    }
    React.useEffect(() => {
        
        GetAllPendingTrainerEventInvites();

    },[])
    return (
        <>
            <div className="eq-trainerEventInvitationBoard-container">
                {/* <Row className="eq-eventInvitationBoard-row">
                    <Col className="eq-eventInvitationBoard-col">
                        <div className="eq-eventInvitationBoard-heading">Activity Invitations</div>
                    </Col>
                </Row> */}
                <div className="">
                    {/*Pending Invites*/}
                    {
                        steps === 1 &&
                        <EqEventInvitesList {... { invitesList: [...pendingInvites], setEventDetail: viewEventDetail, step:steps }}></EqEventInvitesList>
                    }
                </div>
                <div className="">
                    {/*All Invites*/}
                    {
                        steps === 2 &&
                        <EqEventInviteDetail {... {
                            props: eventDetail,
                            acceptClicked: acceptClickedFun,
                            acceptAllClicked: acceptAllClickedFun,
                            rejectClicked: rejectClickedFun,
                            rejectAllClicked: rejectAllClickedFun,
                            setEventDetail: viewEventDetail,
                            step:steps
                        }}></EqEventInviteDetail>
                    }
                </div>
                
                <div className="">
                    {/*Accepted Invites*/}
                    {
                        steps === 3 &&
                        <EqEventInvitesList {... { invitesList: [...acceptedInvites], step: steps }}></EqEventInvitesList>
                    }
                </div>
                <div className="">
                    {/*Remaining Invites*/}
                    {
                        steps === 5 &&
                        <EqEventInvitesList {... { invitesList: [...pendingInvites], step: steps }}></EqEventInvitesList>
                    }
                </div>

            </div>

    </>
    );
}
export default EqTrainerEventInvitationBoard;