import { ActionTypes, initialState } from './Reducer';
import { removeState } from '../shared/LocalStorage';



export function loginUser(dispatch: any, loginPayload: any) {
    dispatch({ type: ActionTypes.LoginSuccess, payload: loginPayload });
}

export function updateImage(dispatch: any, imagepayload: any) {
    dispatch({ type: ActionTypes.UpdateImage, payload: imagepayload });
}

export function updateKey(dispatch: any, keyPayload: any) {
    dispatch({ type: ActionTypes.UpdateKey, payload: keyPayload });
}

export function logout(dispatch: any) {
    dispatch({ type: ActionTypes.Logout });
    removeState();
}

export function updateActivityData(dispatch: any, activityPayload: any) {
    dispatch({ type: ActionTypes.updateActivityData, payload: activityPayload });
}
