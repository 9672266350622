import React from "react";

export const EqIconCalendar = ({ eqHeight, eqWidth, eqFill, eqClass, ...props }: any) => {

    const getViewBox = (): string => { return `0 0 ${eqHeight} ${eqWidth}`; };
    return (
        <svg width={eqWidth} height={eqHeight} className={eqClass} viewBox="199.223 112.748 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M 214.499 114.985 L 212.67 114.985 L 212.67 113.167 C 212.67 112.958 212.441 112.748 212.212 112.748 L 210.688 112.748 C 210.421 112.748 210.23 112.958 210.23 113.167 L 210.23 114.985 L 205.352 114.985 L 205.352 113.167 C 205.352 112.958 205.123 112.748 204.894 112.748 L 203.37 112.748 C 203.103 112.748 202.912 112.958 202.912 113.167 L 202.912 114.985 L 201.083 114.985 C 200.053 114.985 199.223 114.891 199.223 115.8 L 199.253 128.963 C 199.253 129.907 200.053 130.641 201.083 130.641 L 214.499 130.641 C 215.49 130.641 216.329 129.907 216.329 128.963 L 216.479 115.8 C 216.479 114.891 215.49 114.985 214.499 114.985 Z M 214.271 129.794 L 201.221 129.889 C 201.069 129.889 200.09 128.957 200.09 128.817 L 200 116.04 L 215.582 115.944 L 215.371 128.658 C 215.371 128.798 214.415 129.889 214.271 129.794 Z" fill={eqFill}></path>
        </svg>

        );
};

export const EqIconLocation = ({ eqHeight, eqWidth, eqFill, ...props }: any) => {

    const getViewBox = (): string => { return `0 0 ${eqHeight} ${eqWidth}`; };
    return (
        <svg width={eqWidth} height={eqHeight} viewBox={getViewBox()} fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M5.08789 14.4766C5.33398 14.8594 5.9082 14.8594 6.1543 14.4766C10.1465 8.73438 10.8848 8.13281 10.8848 6C10.8848 3.10156 8.5332 0.75 5.63477 0.75C2.70898 0.75 0.384766 3.10156 0.384766 6C0.384766 8.13281 1.0957 8.73438 5.08789 14.4766ZM5.63477 8.1875C4.4043 8.1875 3.44727 7.23047 3.44727 6C3.44727 4.79688 4.4043 3.8125 5.63477 3.8125C6.83789 3.8125 7.82227 4.79688 7.82227 6C7.82227 7.23047 6.83789 8.1875 5.63477 8.1875Z" fill={eqFill} />
        </svg>
        );
};


export const EqIconPrice = ({ eqHeight, eqWidth, eqFill, ...props }: any) => {

    const getViewBox = (): string => { return `0 0 ${eqHeight} ${eqWidth}`; };
    return (

        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="15" width="20"><path fill={eqFill} d="M233.24,512a5.67,5.67,0,0,1-4-1.67L1.67,282.78a5.69,5.69,0,0,1,0-8L274.73,1.67a5.68,5.68,0,0,1,4-1.67H415.29a5.68,5.68,0,0,1,4,1.67l91,91a5.68,5.68,0,0,1,1.67,4V233.24a5.68,5.68,0,0,1-1.67,4L237.27,510.33A5.67,5.67,0,0,1,233.24,512ZM13.73,278.76,233.24,498.27,500.62,230.89V99.07L412.93,11.38H281.11ZM381.16,170.67A39.82,39.82,0,1,1,421,130.84,39.87,39.87,0,0,1,381.16,170.67Zm0-68.27a28.44,28.44,0,1,0,28.44,28.44A28.47,28.47,0,0,0,381.16,102.4Z" /><path fill={eqFill} d="M309.24,276.72a5.68,5.68,0,0,1-4-1.67L237,206.72a5.69,5.69,0,0,1,8.06-8L313.27,267a5.69,5.69,0,0,1-4,9.71Z" /><path fill={eqFill} d="M244.88,341a5.68,5.68,0,0,1-4-1.67l-68.22-68.32a5.69,5.69,0,0,1,8.06-8l68.22,68.32a5.69,5.69,0,0,1-4,9.71Z" /></svg>
        
        );
};

export const EqIconUser = ({ eqHeight, eqWidth, eqFill, ...props }: any) => {

    
    return (

        <svg width={eqWidth} height={eqHeight} viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.125 7.75C8.03906 7.75 9.625 6.19141 9.625 4.25C9.625 2.33594 8.03906 0.75 6.125 0.75C4.18359 0.75 2.625 2.33594 2.625 4.25C2.625 6.19141 4.18359 7.75 6.125 7.75ZM8.55859 8.625H8.09375C7.49219 8.92578 6.83594 9.0625 6.125 9.0625C5.41406 9.0625 4.73047 8.92578 4.12891 8.625H3.66406C1.64062 8.625 0 10.293 0 12.3164V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H10.9375C11.6484 14.75 12.25 14.1758 12.25 13.4375V12.3164C12.25 10.293 10.582 8.625 8.55859 8.625ZM17.3906 5.125L16.625 4.35938C16.5156 4.22266 16.2969 4.22266 16.1875 4.35938L13.3164 7.20312L12.0586 5.94531C11.9492 5.80859 11.7305 5.80859 11.5938 5.94531L10.8281 6.71094C10.7188 6.82031 10.7188 7.03906 10.8281 7.17578L13.0703 9.41797C13.207 9.55469 13.3984 9.55469 13.5352 9.41797L17.3906 5.58984C17.5273 5.45312 17.5273 5.26172 17.3906 5.125Z" fill={eqFill} />
        </svg>

        
        );
};

export const EqIconAttendee = ({ eqHeight, eqWidth, eqFill, ...props }: any) => {

    
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 59" x="0" y="0" width={eqWidth} height={eqHeight}>
            <line fill="none" stroke={eqFill}  x1="15.5" y1="30" x2="15.5" y2="11" />
            <line fill="none" stroke={eqFill} x1="19.5" y1="30" x2="19.5" y2="11" />
            <line fill="none" stroke={eqFill} x1="23.5" y1="30" x2="23.5" y2="11" />
            <line fill="none" stroke={eqFill} x1="27.5" y1="30" x2="27.5" y2="11" />
            <line fill="none" stroke={eqFill} x1="31.5" y1="30" x2="31.5" y2="11" />
            <line fill="none" stroke={eqFill} x1="35.5" y1="30" x2="35.5" y2="11" />
            <line fill="none" stroke={eqFill} x1="39.5" y1="30" x2="39.5" y2="11" />
            <line fill="none" stroke={eqFill} x1="43.5" y1="30" x2="43.5" y2="11" />
            <line fill="none" stroke={eqFill} x1="14.5" y1="34" x2="14.5" y2="58" />
            <line fill="none" stroke={eqFill} x1="44.5" y1="34" x2="44.5" y2="58" />
            <line fill="none" stroke={eqFill} x1="18.5" y1="34" x2="18.5" y2="56" />
            <line fill="none" stroke={eqFill} x1="40.5" y1="34" x2="40.5" y2="56" />
            <line fill="none" stroke={eqFill} x1="14.5" y1="40" x2="44.5" y2="40" />
            <rect x="11.5" y="30" fill={eqFill} width="36" height="4" />
            <path fill={eqFill} d="M13.5,0v11h32V0H13.5z M35.5,7h-12V4h12V7z" />
        </svg>);
};