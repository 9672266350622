import React, { useEffect, useState } from "react";
import "./Contact.scss";
import Api from "../shared/Api";
import Config from "../shared/Config";
import { saveState } from "../shared/LocalStorage";
import {
    ApiEndpoints,
    FrontEndPoints,
    GetLoginError,
    FrontendRoles,
    getRoleFromToken,
} from "../shared/Common";
import { loginUser, logout } from "../context/Actions";
import { useAuthDispatch } from "../context/Context";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import {
    Form,
    Typography,
    Layout,
    Image,
    Anchor,
    Tooltip,
    Progress,
    Checkbox,
    Modal,
    Row,
    Col,
} from "antd";
import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    UserOutlined,
    UnlockFilled,
    ExclamationCircleOutlined,
    InfoOutlined,
} from "@ant-design/icons";
import { EqButtonToggle } from "../custom/eqButtonToggle/EqButtonToggle";
import { EqInput, EqInputPassword } from "../custom/eqInput/EqInput";
import { EqSpace } from "../custom/eqSpace/EqSpace";
import EqButton, { IEqButton } from "../custom/eqButton/EqButton";
import { useTranslation, Trans } from "react-i18next";
import EmailIcon from "../../../public/images/icons/open-envelope.png"
import { Im } from "react-flags-select";


export const Contact = (props: any) => {
    const { t, i18n } = useTranslation();
    const [rememberMe, setRememberMe] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [trainerKey, setTrainerKey] = useState();
    const [joinTrainingKey, setJoinTrainingKey] = useState();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isValidUser, setIsValidUser] = useState(false);
    const [isResendEmail, setIsResendEmail] = useState(false);
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const dispatch = useAuthDispatch();
    const { Text, Title } = Typography;
    const { Content } = Layout;
    const { Link } = Anchor;
    const navigate = useNavigate();
    const location = useLocation();
    // const { state } = useLocation();

    const tailLayout = {
        wrapperCol: { offset: 4, span: 16 },
    };

    const _returnUrl = "";

    React.useEffect(() => {


    }, []);

    return (
        <div className="contact">
            <Row>
                <Col span={24}>
                    <div className="main">
                        <div className="heading-parent">
                            <h2 className="header">{t("contactUs")}</h2>
                            <img className="lines" src='contact/contact-lines.png' />
                        </div>
                        <p className="description">
                            {t("contactUsDesc")}
                        </p>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <div className="about">
                        <img className="person" src='contact/aboutSophie.png' />
                        <div className="section-heading">
                            <h6 className="top-tag">{t("founderText")}</h6>
                            <h2 className="header">{t("founderHeading")}</h2>
                            <p className="description">
                                {t("founderDesc")}
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="about equinet">
                        <div className="section-heading">
                            <h6 className="top-tag">{t("aboutHeading")}</h6>
                            <h2 className="header">{t("aboutText")}</h2>
                            <p className="description">
                                {t("aboutDescP1")}
                                <br />
                                {t("aboutDescP3")}
                                <br />
                                {t("aboutDescP3")}
                            </p>
                        </div>
                        <div className="promo-img-parent">
                            <img className="promo-img" src='contact/about-promo.png' />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>

                    <div className="contact-form-parent">
                        <div className="contact-form">
                            <img className="no-se" src='contact/no-se.png' />
                            <h3 className="section-heading">{t("contactUs")}</h3>
                            <p className="section-desc">{t("contactUsDesc")}</p>
                            <div className="contact-details">
                                <div className="detail phone">
                                    <img src="contact/phone.png" className="contact-img phone" />
                                    <p>+46 702 229 499</p>
                                </div>
                                <div className="detail email">
                                    <img src="contact/email.png" className="contact-img email" />
                                    <p>sophie@equinet.no</p>
                                </div>
                                <div className="detail phone">
                                    <img src="contact/location.png" className="contact-img location" />
                                    <p>Gamle konsgvingerveg 4, 2040 Kløfta</p>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    );
};
