import React from "react";
import { useEffect, useState } from "react";
import "./EqStudentDashboard.scss";
import {
  Row,
  Col,
  Checkbox,
  Skeleton,
  DatePicker,
} from "antd";
import { useTranslation } from "react-i18next";
import EqMessage, {
  IEqMessage,
  IEqMessageData,
} from "../../eqTrainer/eqMessage/EqMessage";

import Api from "../../shared/Api";
import { EqDisciplineType, EqMessageType, EqTrainingType } from "../../shared/enums";
import { IStudentDto } from "../../shared/interfaces/IStudentDto";
import { ApiEndpoints, GetEventMessage } from "../../shared/Common";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useAuthState } from "../../context/Context";
import EqEventInvitationBoard from "../eventInvitationBoard/EqEventInvitationBoard";
import EqTrainerInvitationBoard from "../trainerInvitationBoard/EqTrainerInvitationBoard";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import EqProductInvitationBoard from "../productInvitationBoard/EqProductInvitationBoard";
import EqSchoolInvitationBoard from "../../schoolInvitation/EqSchoolInvitationBoard";
import StudentOverViewProfile from "../studentOverViewProfile/StudentOverViewProfile";
import EqSelect from "../../custom/eqSelect/EqSelect";
import EqButton from "../../custom/eqButton/EqButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { ISelectProps } from "../../shared/Interfaces";
import EqActivityTrainingStudentDashboard from "../eqActivityTrainingStudentDashboard/EqActivityTrainingStudentDashboard";
import moment from "moment";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";
import EqAcceptedTrainingRequests from "../EqAcceptedTrainingRequests/EqAcceptedTrainingRequests";

const EqStudentDashboard = (props: any) => {
  const userDetails: any = useAuthState();
  const { t, i18n } = useTranslation();
  const [messageString, setMessageString] = useState("");
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [messages, setMessages] = useState([] as Array<any>);
  const [misCheckAll, msetIsCheckAll] = useState(false);
  const [activities, setActivities] = React.useState([] as Array<any>);

  const [trainings, setTrainings] = React.useState(null) as any;
  const [events, setEvents] = React.useState([] as Array<any>); //Array<IEqEventData>
  const [studentEventClasses, setStudentEventClasses] = React.useState(
    null
  ) as any; //Array<IEqEventData>
  const [studentProductOrders, setStudentProductsOrders] = React.useState(
    [] as Array<any>
  ); //Array<IEqEventData>
  const [activityInvitation, setActivityInvitation] = useState(true);
  const [acceptedRequests, setAcceptedRequests] = useState(true);
  const [productOrderInvitation, setProductOrderInvitation] = useState(true);
  const [trainerInvitation, setTrainerInvitation] = useState(true);
  const [schoolInvitation, setSchoolInvitation] = useState(true);
  const [subscribedTrainersList, setSubscribedTrainersList] = useState([]);
  const [subscribedSchoolsList, setSubscribedSchoolsList] = useState([]);

  const [currentStudentslist, setCurrentStudentsList] = React.useState(
    [] as Array<IStudentDto>
  );
  const [keys, setKeys] = useState(new Array<any>());
  const [eventList, setEventList] = useState(true);
  const [products, setProducts] = React.useState([] as Array<any>);
  var selectedMessages = new Array<any>();

  const [joinedTrainings, setJoinedTrainings] = React.useState(null) as any;
  const [stateView, setStateView] = useState({
    totalProfessionals: [],
    unpaidTraining: [],
    unpaidProduct: [],
    upcomingTraining: [],
  });
  //filter
  const [selectedTraining, setSelectedTraining] = React.useState([] as any);
  const [trainingOptions, setTrainingOptions] = React.useState([] as any);
  const [fromDate, setFromDate] = useState<moment.Moment | null>(null);


  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

  const replyEmail = (message: IEqMessageData) => {
    GetMessages();
  };
  const mardFlagedEmail = (message: IEqMessageData) => {
    if (message != null) {
      Api.post(ApiEndpoints.saveMessage, {
        Message: { Key: message.key },
        IsFlaged: message.isFlaged,
        IsRead: message.isRead,
      }).then(
        (response) => {
          if (response && response.status == 200) {
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };

  const toggleEventList = () => {
    setEventList(!eventList);
  };

  const deleteEmail = (message: IEqMessageData) => {
    if (message != null) {
      Api.post(ApiEndpoints.deleteMessage, {
        Message: { Key: message.key },
      }).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const onCheckChange = (message: IEqMessageData, checked: boolean) => {
    if (checked) {
      selectedMessages.push({ Key: message.key });
    } else {
      if (selectedMessages != null && selectedMessages.length > 0) {
        const index = selectedMessages.findIndex((x) => x.Key == message.key);
        if (index > -1) {
          selectedMessages.splice(index, 1);
        }
      }
    }
    setKeys(selectedMessages);
  };
  const deleteSelectedMessages = () => {
    msetIsCheckAll(false);

    if (keys?.length > 0) {
      Api.post(ApiEndpoints.deleteSelectedMessages, keys).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();

            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const readSelectedMessages = () => {
    msetIsCheckAll(false);
    selectedMessages = [];
    keys.map((opt: any) => {
      selectedMessages.push({ Message: { Key: opt.Key }, IsRead: true });
    });
    if (keys?.length > 0) {
      Api.post(ApiEndpoints.saveSelectedMessages, selectedMessages).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();
            selectedMessages = [];
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const GetMessages = async () => {
    setMessageString("");

    var messagesFromDB = [] as any;
    // const {data} =await Api.get(ApiEndpoints.getMessages);
    // console.log(data)

    Api.get(ApiEndpoints.getMessages).then(
      (response) => {
        if (response && response.status == 200) {
          response.data?.result?.map((d: any) => {
            if (d.message.messageType !== EqMessageType.Notification) {
              messagesFromDB.push({
                message: {
                  key: d.message.key,
                  messageOn: new Date(d.message.sentOn),
                  type: EqMessageType[d.message.messageType],
                  from: d.message.sender?.userName,
                  to: "trainer",
                  subject: d.message.subject,
                  text: d.message.message,
                  isRead: d.isRead,
                  isSent: d.isSent,
                  isFlaged: d.isFlaged,
                },
                checkBoxBordered: false,
                clickedReplyFun: replyEmail,
                clickedFlagFun: mardFlagedEmail,
                clickedDeleteFunc: deleteEmail,
                onCheckChangeFunc: onCheckChange,
                selected: false,
              });
            }
          });
          messagesFromDB = messagesFromDB?.sort((obj1: any, obj2: any) => {
            if (obj1.message.isRead > obj2.message.isRead) {
              return 1;
            } else if (obj1.message.isRead < obj2.message.isRead) {
              return -1;
            } else {
              return 0;
            }
          });

          setMessages(messagesFromDB);
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };
  const GetAllStudentTrainers = () => {
    setMessageString("");

    Api.get(ApiEndpoints.GetStudentTrainers).then(
      (response) => {
        if (response && response.status == 200) {
          // var pendingInvites = [] as any;
          var data = response?.data ? response?.data : ([] as any);
          setSubscribedTrainersList(data);
          // if(data.length)
          // {
          //     onloadActivity(true);
          // }
          // else{
          //     onloadActivity(false);

          // }
          //data.map((d: any, index: number) => {
          //    pendingInvites.push(d);
          //});
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };
  const GetAllStudentSchools = () => {
    setMessageString("");

    Api.get(ApiEndpoints.GetStudentSchools).then(
      (response) => {
        if (response && response.status == 200) {
          // var pendingInvites = [] as any;
          var data = response?.data ? response?.data : ([] as any);
          setSubscribedSchoolsList(data);


        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };
  const GetUserAcceptedEvents = () => {
    Api.get(ApiEndpoints.GetUserAcceptedEvents).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data
            ? [...response.data]
            : new Array<IEqEventData>();
          data.map((f: any, index: number) => {
            f.display = index == 0 ? true : false;
          });
          var event = data?.filter((opt: any) => opt.accessType == 2);
          var trainings = data?.filter((opt: any) => opt.accessType == 1);

          setActivities(data);
          setEvents(event);
          setTrainings(trainings);
        } else {
          setIsError(true);
        }
      },
      (error) => {
        setIsError(true);
      }
    );
  };

  const GetStudentJoinedEventClasses = () => {
    Api.get(ApiEndpoints.GetStudentJoinedClasses).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          data.map((f: any, index: number) => {
            f.display = index == 0 ? true : false;
          });
          setStudentEventClasses(data);
        } else {
          setIsError(true);
        }
      },
      (error) => {
        setIsError(true);
      }
    );
  };
  const removeEvent = (data: IEqEventData) => {
    //
  };
  const editEvent = (data: IEqEventData) => {
    //
  };
  const removeItem = (data: IEqEventData) => {
    if (events != undefined) {
      var updatedList =
        events != undefined
          ? events.filter((item) => item.key !== data.key)
          : new Array<IEqEventData>();
      setEvents([...events]);

      events.map((item: IEqEventData, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  const messagesCheckAll = () => {
    msetIsCheckAll(!misCheckAll);

    messages.map((m: IEqMessage) => {
      if (m.selected == false) {
        m.selected = !misCheckAll;
        selectedMessages.push({ Key: m.message.key });
      } else {
        m.selected = !misCheckAll;
        if (selectedMessages != null && selectedMessages.length > 0) {
          const index = selectedMessages.findIndex(
            (x) => x.Key == m.message.key
          );
          if (index > -1) {
            selectedMessages.splice(index, 1);
          }
        }
      }
      setKeys(selectedMessages);
    });
    const modifiedList = [...messages];
    setMessages(modifiedList);
  };

  const GetStudentProductOrders = () => {
    Api.get(ApiEndpoints.ProductOrder).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();

          if (data.length > 0) {
            var joinedProducts = data?.filter(
              (opt: any) => opt.status == 1 || opt.status == 3
            );

            setStudentProductsOrders(joinedProducts);
          }
        } else {
          setIsError(true);
        }
      },
      (error) => {
        setIsError(true);
      }
    );
  };

  const dashboardState = () => {

    Api.get(ApiEndpoints.GetStudentDashboardState)
      .then((response) => {

        if (response && response.status === 200) {
          // var result = response.data;
          const { totalProfessionals,
            unPaidProductOrders,
            unPaidTrainingOrders,
            upcomingTrainings,
          } =
            response.data;
          setStateView({
            totalProfessionals: totalProfessionals,
            unpaidTraining: unPaidTrainingOrders,
            unpaidProduct: unPaidProductOrders,
            upcomingTraining: upcomingTrainings,
          });

        } else {
          setIsError(true);
          GetEventMessage(response.status);
        }
      })
      .catch((error) => {
        GetEventMessage(error.response.status);
      });
  };

  const studentJoinedLessons = () => {
    Api.get(ApiEndpoints.StudentAllJoinedLessons)
      .then((response) => {
        if (response && response.status === 200) {
          var data = response.data
          setJoinedTrainings(data);
          console.log('training', data)
        } else {
          setIsError(true);
          GetEventMessage(response.status);
        }
      })
      .catch((error) => {
        GetEventMessage(error.response.status);
      });
  };


  const GetAllStudentProductOrders = () => {
    setMessageString("");

    Api.get(ApiEndpoints.ProductOrderStudentInvites).then(
      (response) => {
        if (response && response.status == 200) {
          var pendingInvites = [] as any;
          var data = response?.data ? response?.data : ([] as any);

          if (data.length > 0) {
            var grouped = data.reduce((acc: any, obj: any) => {
              const key = obj.product.user.email;
              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(obj);
              return acc;
            }, {});



            pendingInvites = Object.keys(grouped).map((key) => ({
              trainer: grouped[key][0].product.user,
              invites: grouped[key],
            }));
          }
          // setProductOrderInvites(data);
          // setPendingInvites(pendingInvites);
          if (data.length) {
            // onloadActivity(true);
          } else {
            // onloadActivity(false);
          }
          //data.map((d: any, index: number) => {
          //    pendingInvites.push(d);
          //});

        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };


  const filterTrainings = (trainingList: any[]) => {
    let filteredList = trainingList;
    if (selectedTraining && selectedTraining.length > 0) {
      filteredList = filteredList.filter((item) => {
        return selectedTraining.some((selectedType: any) => item?.event.disciplineType === selectedType);
      }
      );
    }

    if (fromDate) {
      const FromDate = moment(fromDate).startOf("day").valueOf();
      filteredList = filteredList.filter((item) => {
        const startDate = moment(item?.event.startDate).startOf("day").valueOf();
        return startDate === FromDate;
      });
    }


    return filteredList;
  }

  const trainingTypeProps: ISelectProps = {
    classNames: `eq-multiSelect eq-searchSelect`,
    placeholder: t("training"),
    extraHeight: true,
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedTraining ? selectedTraining : [],
    options: trainingOptions,
    changeFunc: (value: any) => {
      ;
      setSelectedTraining(value);
    },
  };
  const createTrainingList = () => {
    var eventTypes = [];
    eventTypes.push({
      key: EqDisciplineType.Dressage,
      value: EqTrainingType[EqTrainingType.PrivateLesson],
    });
    eventTypes.push({
      key: EqDisciplineType.Jumping,
      value: EqTrainingType[EqTrainingType.GroupLesson],
    });
    eventTypes.push({
      key: EqDisciplineType.Open,
      value: EqTrainingType[EqTrainingType.AvailableTimeSlots],
    });
    eventTypes.push({
      key: EqDisciplineType.RidingCourse,
      value: EqDisciplineType[EqDisciplineType.RidingCourse],
    });
    setTrainingOptions(eventTypes);
  };

  useEffect(() => {
    createTrainingList();
    GetMessages();
    GetUserAcceptedEvents();
    GetStudentProductOrders();
    GetStudentJoinedEventClasses();
    GetAllStudentTrainers();
    GetAllStudentSchools();
    dashboardState()
    studentJoinedLessons()
  }, []);
  return (
    <>

      <AnimationDiv>

        <div className="eq-stb-container">
          <Row className="eq-stb-heading-row">
            <Col className="eq-stb-col" flex="auto" md={5} xs={24} sm={24}>
              <h3 className="eq-stb-heading">{t("myDashboard")}</h3>
            </Col>
          </Row>
          {/* <div className="eq-section">
          <Row className="eq-db-row" align="stretch">
            <Col flex="auto" className="eq-db-icons">
              <Row>
                <h5 className="eq-db-h2">{t("quickLinks")}</h5>
              </Row>
              <Row align="bottom" justify="start" className="eq-db-icon-row">
                <Col className="eq-db-icon" xs={8} sm={8} md={4}>
                  <Link to={FrontEndPoints.student.allTrainings}>
                    <span>
                      <img alt="" src="/training.svg" />
                    </span>
                    <div className="eq-db-icon-text">{t("myTrainings")}</div>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div> */}
          <div className="eq-section">
            <StudentOverViewProfile
              stat={stateView}
              joinedTraining={joinedTrainings}
            />
          </div>


          {/* {subscribedTrainersList && subscribedTrainersList.length > 0 ? (
          <>
            <div className="eq-section-divider"></div>
            <div className="eq-section">
              <EqCollapsePanel
                defaultOpen={true}
                header={t("subscribedTrainers")}
                children={[...subscribedTrainersList].map(
                  (item: any, index: number) => (
                    <EqSubscribedTrainer
                      key={index}
                      trainer={item}
                    ></EqSubscribedTrainer>
                  )
                )}
              />
            </div>
          </>
        ) : (
          ""
        )} */}

          {/* {subscribedSchoolsList && subscribedSchoolsList.length > 0 ? (
          <>
            <div className="eq-section-divider"></div>
            <div className="eq-section">
              <EqCollapsePanel
                defaultOpen={true}
                header={t("subscribedSchools")}
                children={[...subscribedSchoolsList].map(
                  (school: any, index: number) => (
                    <EqSubscribedUser
                      key={index}
                      user={school}
                    ></EqSubscribedUser>
                  )
                )}
              />
            </div>
          </>
        ) : (
          ""
        )} */}

          {productOrderInvitation ? (
            <>
              <div className="eq-section-divider"></div>
              <div className="eq-section">
                <EqCollapsePanel
                  defaultOpen={true}
                  header={t("productOrderInvitations")}
                  children={
                    <EqProductInvitationBoard
                      onloadActivity={(e: any) => {

                        setProductOrderInvitation(e);
                      }}
                    />
                  }
                />
              </div>
            </>
          ) : (
            ""
          )}

          {activityInvitation ? (
            <>
              <div className="eq-section-divider"></div>
              <div className="eq-section">
                <EqCollapsePanel
                  defaultOpen={true}
                  header={t("trainingInvitations")}
                  children={
                    <EqEventInvitationBoard
                      onloadActivity={(e: any) => {

                        setActivityInvitation(e);
                      }}
                    />
                  }
                />
              </div>
            </>
          ) : (
            ""
          )}

{acceptedRequests ? (
            <>
              <div className="eq-section-divider"></div>
              <div className="eq-section">
                <EqCollapsePanel
                  defaultOpen={true}
                  header={t("acceptedRequest")}
                  children={
                    <EqAcceptedTrainingRequests
                      onloadActivity={(e: any) => {

                        setAcceptedRequests(e);
                      }}
                    />
                  }
                />
              </div>
            </>
          ) : (
            ""
          )}
          {/* <div className="eq-section">
          <Row className="eq-msg-header" align="bottom">
            <Col flex="auto">
              <div className="eq-db-h2"> Activity Invitations</div>
            </Col>
          </Row>

          <EqEventInvitationBoard />
        </div> */}

          {trainerInvitation ? (
            <>
              <div className="eq-section-divider"></div>
              <div className="eq-section">
                <EqCollapsePanel
                  defaultOpen={true}
                  header={t("trainerInvitations")}
                  children={
                    <EqTrainerInvitationBoard
                      onloadActivity={(e: any) => {
                        setTrainerInvitation(e);
                      }}
                    />
                  }
                />
              </div>
            </>
          ) : (
            ""
          )}

          {schoolInvitation ? (
            <>
              <div className="eq-section-divider"></div>
              <div className="eq-section">
                <EqCollapsePanel
                  defaultOpen={true}
                  header={t("schoolInvitations")}
                  children={
                    <EqSchoolInvitationBoard
                      onloadActivity={(e: any) => {
                        setSchoolInvitation(e);
                      }}
                    />
                  }
                />
              </div>
            </>
          ) : (
            ""
          )}

          {/* <div className="eq-section-divider"></div>
          <div className="eq-section">
            <EqCollapsePanel
              header={t("newMessages")}
              children={
                <>
                  <Row className="eq-msg-header" align="bottom">
                    <Col flex="auto">
                      <div className="eq-db-h2-collapse">{t("newMessages")}</div>
                    </Col>
                    <Col md={2}>
                      <Row>
                        <Col
                          className="eq-msg-icons"
                          onClick={readSelectedMessages}
                        >
                          <span>
                            <img alt="" src="/black-check.svg" />
                          </span>
                        </Col>
               
                        <Col
                          className="eq-msg-icons"
                          onClick={deleteSelectedMessages}
                        >
                          <span>
                            <img alt="" src="/trash-alt.svg" />
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {messages.length > 0 && (
                    <>
                      {
                        <Row className="eq-list-header hidden-sm">
                          <Col md={22} flex="auto">
                            <Row>
                              <Col xs={2} sm={2} md={1} className="">
                                <Checkbox
                                  onChange={messagesCheckAll}
                                  checked={misCheckAll}
                                ></Checkbox>
                              </Col>
                              <Col span={3} className="">
                                {t("type")}
                              </Col>
                              <Col span={5} className="">
                                {t("from")}
                              </Col>
                              <Col flex="auto" className="">
                                {t("messageTitle")}
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={4} sm={4} md={2} className="">
                            {t("action")}
                          </Col>
                        </Row>
                      }

                      {messages.map((item: IEqMessage, index: number) => (
                        <EqMessage key={index} {...item}></EqMessage>
                      ))}
                    </>
                  )}
                </>
              }
            />
          </div> */}
          <div className="eq-section-divider"></div>
          <div className="eq-section eq-section-training">
            <Skeleton loading={!joinedTrainings} active></Skeleton>
            <Row>
              {joinedTrainings && (
                <Col span={24}>
                  <EqCollapsePanel
                    defaultOpen
                    header={t("trainings")}
                    children={
                      <div>
                        <Row gutter={[16, 16]} className="eq-section-training-filter">
                          <Col xs={24} md={8}>
                            <EqSelect {...trainingTypeProps}></EqSelect>
                          </Col>
                          <Col xs={24} md={8}>
                            <DatePicker
                              placeholder={t("selectDate")}
                              className="eq-filter-date eq-input-info"
                              onChange={(date) => setFromDate(date)}
                              value={fromDate && fromDate.isValid() ? fromDate : null}

                            />
                          </Col>
                          <Col xs={24} md={8}>
                            <EqButton classNames="btn-background-white btn-border-text-orange eq-filter-btn">
                              <FontAwesomeIcon icon={faFilter} className="eq-filter-btn-icon" />
                              {t("filter")}
                            </EqButton>
                          </Col>
                        </Row>
                        <EqActivityTrainingStudentDashboard
                          trainingList={filterTrainings(joinedTrainings)}
                        ></EqActivityTrainingStudentDashboard>

                      </div>
                    }
                  />
                </Col>
              )}
            </Row>

          </div>

          {/* student Schedule */}
          {/* <EqStudentSchedule
          isOwner={false}
          isStudent={true}
          eventList={studentEventClasses}
          trainings={trainings}
          studentProductOrders={studentProductOrders}
        // onCreateOrUpdateTraining: handleOnUpdateTraining,
        // onCreateOrUpdateEvent: handleOnUpdateEvent,
        // onStudentJoin: GetAllTrainerTrainingsLessonsByTrainerKey,
        // onRemoveTraining: handleRemoveTraining,
        // onRemoveEvent: handleRemoveEvent,
        // areas: areas,
        // imagesData: imagesData,
        // weekDays: weekDays,
        // population: population,
        // groupPopulation: groupPopulation,
        // trainerStudents: trainerStudents,
        // studentHorsesList: studentHorsesList,
        // profileData: profileData,
        // activitiesList: activitiesList,
        // publicProducts: [...productsList?.public],
        // isSubscribed: subscribeButtonState.buttonDisabled,
        // subscribeTrainer: SubscribeTrainer,
        ></EqStudentSchedule> */}


        </div>

      </AnimationDiv>

    </>
  );
};
export default EqStudentDashboard;
