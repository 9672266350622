import * as React from "react";
import "./EqSchoolInvitationBoard.scss";
import Api from "../shared/Api";
import { ApiEndpoints } from "../shared/Common";
import { Row, Col } from "antd";

import modal from "antd/lib/modal";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import EqInviteByUser from "../custom/EqInviteByUser/EqInviteByUser";
import { useLocation } from "react-router-dom";

export interface IEqSchoolInvitationBoard {
  onloadActivity?: any;
}
const EqSchoolInvitationBoard = ({
  onloadActivity,
}: IEqSchoolInvitationBoard) => {
  const location = useLocation();
  const [messageString, setMessageString] = React.useState("");
  const [schoolPendingInvites, setSchoolPendingInvites] = React.useState(
    [] as any
  );

  function AcceptSchoolInvite(e: any) {
    let path = "";
    if (location.pathname == "/student/home") {
      path = ApiEndpoints.StudentSchoolAcceptInvite;
    } else if (location.pathname == "/trainer/home") {
      path = ApiEndpoints.TrainerSchoolAcceptInvite;
    }
    Api.post(path, { key: e }).then(
      (response) => {
        if (response && response.status == 200) {
          GetAllPendingSchoolInvites();
        } else {
          setMessageString("");
        }
      },
      (err) => {
        setMessageString("");
      }
    );
  }

  function RejectSchoolInvite(e: any) {
    let path = "";
    if (location.pathname == "/student/home") {
      path = ApiEndpoints.StudentSchoolRejectInvite;
    } else if (location.pathname == "/trainer/home") {
      path = ApiEndpoints.TrainerSchoolRejectInvite;
    }
    Api.post(path, { key: e }).then(
      (response) => {
        if (response && response.status == 200) {
          GetAllPendingSchoolInvites();
        } else {
          setMessageString("");
        }
      },
      (err) => {
        setMessageString("");
      }
    );
  }
  const confirmSchoolTerms = (school: any) => {
    modal.confirm({
      title: "School Terms",
      width: 1000,
      icon: <ExclamationCircleOutlined />,
      
      content: (
        <p style={{ whiteSpace: "pre-wrap" }}>  <div dangerouslySetInnerHTML={{ __html: school?.trainerTerms }} /></p>
      ),
      okText: "Accept",
      onOk: () => AcceptSchoolInvite(school.key),
    });
  };
  const acceptClickedFun = (school: any) => {
    confirmSchoolTerms(school);

  };

  const rejectClickedFun = (e: any) => {
    RejectSchoolInvite(e.key);
  };

  const GetAllPendingSchoolInvites = () => {
    setMessageString("");
    let path = "";
    if (location.pathname == "/student/home") {
      path = ApiEndpoints.StudentSchoolGetAllPendingInvites;
    } else if (location.pathname == "/trainer/home") {
      path = ApiEndpoints.TrainerSchoolGetAllPendingInvites;
    }
    Api.get(path).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response?.data ? response?.data : ([] as any);
          setSchoolPendingInvites(data);
          if (data.length) {
            onloadActivity(true);
          } else {
            onloadActivity(false);
          }
       
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };

  React.useEffect(() => {
    GetAllPendingSchoolInvites();
  }, []);
  return (
    <>
      <div className="eq-schoolInvitationBoard-container">
        <div className="">
          {schoolPendingInvites && schoolPendingInvites.length > 0
            ? [...schoolPendingInvites].map((item: any, index: number) => (
                <EqInviteByUser
                  key={index}
                  user={item}
                  acceptClicked={acceptClickedFun}
                  rejectClicked={rejectClickedFun}
                ></EqInviteByUser>
              ))
            : ""}
        </div>
      </div>
    </>
  );
};
export default EqSchoolInvitationBoard;
