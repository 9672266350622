import * as React from "react";
import { Row, Col, Popconfirm, Modal } from "antd";
import "./EqPreviousTrainingCollapse.scss";

import { useTranslation } from "react-i18next";

import moment from "moment";
import {
  FrontEndPoints,
  getEmailFromToken,
  ValidateIsEventHolder,
  ValidateIsSchool,
  ValidateIsTrainer,
} from "../../../shared/Common";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  EqIconCalendar,
  EqIconLocation,
  EqIconUser,
} from "../../../custom/eqIcon/EqIcons";
import {
  EqDisciplineType,
  EqEventUserType,
  EqProfessionType,
  EqTrainingType,
} from "../../../shared/enums";
import { useAuthState } from "../../../context/Context";
import EqTags from "../../../custom/eqTags/EqTags";
import { IGroupDto } from "../../../shared/interfaces/IGroupDto";
import CreateActivity from "../../createActivity/CreateActivity";
import EqCollapsePanel from "../../../custom/eqCollapsePanel/EqCollapsePanel";

export interface IEqPreviousTrainingCollapse {
  creatorUser?: any;
  event: any;
  areas: any;
  weekDays: any;
  population?: any;
  groupPopulation?: any;
  trainerStudents?: any;
  studentHorsesList?: any;
  removeEventFunc?: (data: any) => void;
  removeAttendeeFunc?: (data: any) => void;
  editEventFunc?: (data: any) => void;
  onCreateOrUpdateActivity?: any;
  duplicateEvent?: (data: any) => void;
}
//IEqEvent
const EqPreviousTrainingCollapse = ({
  creatorUser,
  event,
  areas,
  weekDays,
  population,
  groupPopulation,
  trainerStudents,
  studentHorsesList,
  removeEventFunc,
  editEventFunc,
  removeAttendeeFunc,
  onCreateOrUpdateActivity,
  duplicateEvent,
  ...rest
}: any) => {
  const { t, i18n } = useTranslation();
  const userDetails: any = useAuthState();
  const currentUserKey = getEmailFromToken();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const isEventHolder: boolean = ValidateIsEventHolder(userDetails);
  const isSchool: boolean = ValidateIsSchool(userDetails);

  //Modal
  const [modalRefundPayment, contextRefundPayment] = Modal.useModal();

  const collapseInnerRef = React.useRef<any>(null);
  const [collapseInnerHeight, setCollapseInnerHeight] = React.useState(0);

  const [isAttendee, setIsAttendee] = React.useState(false);
  const [studentGroups, setStudentGroups] = React.useState([]) as any;
  const [isOwner, setIsOwner] = React.useState(false);
  const [isStudentTrainings, setIsStudentTrainings] = React.useState(false);
  const [toggleRow, setToggleRow] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [path, setPath] = React.useState(location.pathname);

  const checkEventOwner = () => {
    const ownerOrganizer = event.organizers.find(
      (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
    );

    if (ownerOrganizer && ownerOrganizer.user.key === userDetails.key) {
      setIsOwner(true);
    } else {
      setIsOwner(false);
    }
  };
  const checkEventAttendee = () => {
    //console.log(event?.attendees);
    var attendee = event?.attendees
      ?.filter((a: any) => {
        return a.user.key == userDetails.key;
      })
      .shift();
    //console.log(attendee);
    if (attendee != null || undefined) {
      setIsAttendee(true);
    }
  };
  //console.log(event);
  const getEventGroups = () => {
    //console.log(event);
    var studentGroups = event?.attendees?.filter((x: any) => x.group != null);
    studentGroups = studentGroups?.map((d: any) => d.group);
    const result = [];
    const map = new Map();
    if (studentGroups != null && studentGroups.length > 0) {
      for (const item of studentGroups) {
        if (!map.has(item.key)) {
          map.set(item.key, true); // set any value to Map
          result.push(item);
        }
      }
    }
    setStudentGroups(result);
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    if (isOwner) {
      if (removeEventFunc != null) {
        confirmPaymentRefund(event);
      }
    }
  };

  const confirmPaymentRefund = (event: any) => {
    modalRefundPayment.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Would you like to Refund Attendee",
      okText: "Refund",
      cancelText: "Pay Later",
      onOk: () => removeEventFunc(event, true),
      onCancel: () => removeEventFunc(event, false),
    });
  };

  const removeEvent = (eventArgs: any) => {
    eventArgs.stopPropagation();

    if (isOwner) {
      if (removeEventFunc != null) {
        removeEventFunc(event);
      }
    } else if (isAttendee) {
      if (removeAttendeeFunc != null) {
        removeAttendeeFunc(event);
      }
    }
  };
  const removeAttendee = (eventArgs: any) => {
    eventArgs.stopPropagation();
    removeAttendeeFunc(event);
  };
  // Subscription Related

  React.useEffect(() => {
    // console.log("path", path);
    setCollapseInnerHeight(collapseInnerRef.current.clientHeight);

    getEventGroups();
    checkEventOwner();
    checkEventAttendee();
  }, [event]);
  return (
    <div className="eq-activity-collapse">
      <EqCollapsePanel
        header={
          <>
            <div className="eq-activity-row">
              <Row className="eq-status-row-border" align="middle">
                <Col className="content-col">
                  <Row>
                    <Col>
                      <div className="date-icon">
                        <EqIconCalendar
                          eqWidth={34}
                          eqHeight={34}
                          eqClass="eq-cal-icon"
                          eqFill="#083b49"
                        />
                        <div className="day">
                          {moment(event.startDate).format("DD")}
                        </div>
                        <div className="month">
                          {moment(event.startDate).format("MMM")}
                        </div>
                      </div>
                    </Col>
                    <Col className="header-details">
                      <Row>
                        <Col></Col>
                        <Col>
                          <span className="time">
                            {moment(event.startTime).format("hh:mm A")} -{" "}
                            {event.disciplineType != EqDisciplineType.Open
                              ? moment(event.startTime)
                                  .add(event.duration, "minutes")
                                  .format("hh:mm A")
                              : moment(event.endTime).format("hh:mm A")}
                          </span>
                        </Col>
                        <Col>
                          <div className="eq-event-type">
                            {event.accessType === 1 ? (
                              <EqTags
                                text={t(EqTrainingType[event.disciplineType])}
                                dicipline={true}
                              />
                            ) : (
                              <EqTags
                                text={t(EqDisciplineType[event.disciplineType])}
                                dicipline={true}
                              />
                            )}
                          </div>
                        </Col>
                        <Col className="col-trancated">
                          <span className="location">
                            <EqIconLocation
                              eqWidth={13}
                              eqHeight={16}
                              eqFill="#b5b5b5"
                            />
                            {event.location?.city}
                          </span>
                        </Col>
                        <Col className="hidden-sm col-trancated">
                          {studentGroups?.map(
                            (item: IGroupDto, index: number) => (
                              <EqTags
                                className=""
                                key={index}
                                text={item.name}
                                color={item.color}
                              />
                            )
                          )}
                        </Col>
                        <Col>
                          <span className="trainer">
                            {event.organizers &&
                              event.organizers.length > 0 &&
                              event.organizers.map(
                                (item: any, index: number) =>
                                  item.eventUserType ===
                                    EqEventUserType.Owner && (
                                    <span className="trainer" key={index}>
                                      <EqIconUser
                                        eqWidth="16"
                                        eqHeight="16"
                                        eqFill="#b5b5b5"
                                      />
                                      {item.user.professionType ===
                                      EqProfessionType.Trainer ? (
                                        <Link
                                          to={`${FrontEndPoints.trainer.profileBoard}?key=${item.user.key}`}
                                          onClick={(e: any) => {
                                            e.stopPropagation();
                                          }}
                                          className="trainer-link"
                                        >
                                          {item.user.firstName || item.userName}
                                        </Link>
                                      ) : (
                                        <Link
                                          to={`${FrontEndPoints.school.profileBoard}?key=${item.user.key}`}
                                          onClick={(e: any) => {
                                            e.stopPropagation();
                                          }}
                                          className="trainer-link"
                                        >
                                          {item.user.firstName || item.userName}
                                        </Link>
                                      )}
                                    </span>
                                  )
                              )}
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <span className="title">{event.title}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col className="action-col">
                  <Row justify="end">
                    {/* // add is owner here and change condition */}
                    {/* {isOwner ? ( */}
                    {/* <Col>
                      <div className="right">
                        <div>
                          <EqButton
                          onClick={()=>duplicateEvent(event)}
                          >
                            {t("duplicate")}
                          </EqButton>
                        </div>
                      </div>
                    </Col> */}

                    <Col span={24}>
                      <div className="eq-event-actions">
                        <div className="eq-event-actions-trash">
                          <span>
                            <Popconfirm
                              placement="bottomRight"
                              title={"Are you sure you want to delete?"}
                              okText="Yes"
                              cancelText="No"
                              // onConfirm={removeEvent}
                              onConfirm={handleDelete}
                              onCancel={(e: any) => {
                                e.stopPropagation();
                              }}
                              // onConfirm={() => setCreateActivityList([])}
                            >
                              <img
                                alt=""
                                // src="/trash-alt.svg"
                                src="/trash-alt-red.svg"
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                }}
                              />
                            </Popconfirm>
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </>
        }
        childInnerHeight={collapseInnerHeight}
        children={
          <div ref={collapseInnerRef}>
            {/* {isTrainer || isSchool || isEventHolder ? ( */}

            {/* change it to create or edit private Training */}

            <CreateActivity
              creatorUser={creatorUser}
              event={event}
              areas={areas}
              weekDays={weekDays}
              population={population}
              groupPopulation={groupPopulation}
              trainerStudents={trainerStudents}
              studentHorsesList={studentHorsesList}
              onCreateOrUpdateActivity={onCreateOrUpdateActivity}
              isPreviousTraining={true}
            />
            {/* ) : (
             <></>
            )} */}
            {/* modal Context */}
            {contextRefundPayment}
          </div>
        }
      ></EqCollapsePanel>
    </div>
  );
};
export default EqPreviousTrainingCollapse;
