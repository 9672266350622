import * as React from 'react';
import { IEqToggleItem } from '../eqToggle/EqToggle';
import {
    Input,
    Button,
    Select
} from 'antd';
import './EqSearch.scss';
import { SearchOutlined } from '@ant-design/icons';

export interface IEqSearch {
    defaultValue?: string;
    selectAfterValues?: Array<ISelectItem>;
    selectDefaultValue?: ISelectItem,
    buttonTitle?: string | undefined;
    buttonClick: (selectedVal: string | undefined, searchVal: string | undefined) => void,
    searchValueFun?: (searchVal: string | undefined) => void,
    groupClassNames?: string,
    inputClassNames?: string,
    style?:any
}

export interface ISelectItem  extends IEqToggleItem {
    icon: React.ReactNode
}

const EqSearch = ({ defaultValue, selectAfterValues, selectDefaultValue, buttonTitle, buttonClick, groupClassNames, inputClassNames, style, searchValueFun }: IEqSearch) => {

    const { Option } = Select;
    const [searchVal, setSearchVal] = React.useState(defaultValue);
    const [selectSelectedVal, setSelectSelectedVal] = React.useState(selectDefaultValue?.key);

    React.useEffect(() => {

    }, []);

    const setSearchText = (val:any) => {
        setSearchVal(val);
        if (searchValueFun != undefined) {
            searchValueFun(val);
        }
    }

    const selectAfter = (
        <Select defaultValue={selectDefaultValue?.key} className="select-after" onChange={(value) => setSelectSelectedVal(value)} >
            {selectAfterValues && selectAfterValues.map((item: ISelectItem) => <Option value={item.key} key={item.key}>
                <span className="item-icon">{item.icon}</span>
                &nbsp;
                {item.value}
            </Option>)}
        </Select>
    );

    return (
        <Input.Group compact className={`eq-search ${groupClassNames ? groupClassNames : ""}`} >
            <Input style={style ? style : {width: 'calc(100% - 75px)'}}
                className={`round-input ${inputClassNames ? inputClassNames : ""}`}
                onChange={(evt) => setSearchText(evt.target.value)}
                prefix={<SearchOutlined />}
                defaultValue={defaultValue}
                addonAfter={selectAfterValues ? selectAfter : undefined}
            />
            {buttonTitle ? <Button type="primary" onClick={() => buttonClick(selectSelectedVal, searchVal)} >{buttonTitle}</Button>:""}
            
        </Input.Group>
    );

}

export default EqSearch;


