import * as React from "react";
import "./EqJoinEvent.scss";

import {
  Row,
  Col,
  notification,
  Modal,
  Select,
  Input,
  Collapse,
  Steps,
} from "antd";
import { useTranslation } from "react-i18next";
import EqButton from "../../custom/eqButton/EqButton";
import { EqInput, EqInputNumber } from "../../custom/eqInput/EqInput";
import EqSelect from "../../custom/eqSelect/EqSelect";
import { Form, Typography, Image, Tooltip, Progress, Checkbox } from "antd";
import { useAuthState } from "../../context/Context";
import {
  ApiEndpoints,
  FrontEndPoints,
  GetEventMessage,
  ValidateIsStudent,
  ValidateIsTrainer,
  getEmailFromToken,
  openNotificationWithIcon,
} from "../../shared/Common";
import { ISelectProps } from "../../shared/Interfaces";
import config from "../../shared/Config";
import { ISubscribeEventDto } from "../../shared/interfaces/ISubscribeEventDto";
import { IUserProfileDto } from "../../shared/interfaces/IUserProfileDto";
import {
  EqCurrency,
  EqEventUserType,
  EqSlotStatusEnum,
  QuestionType,
  eqNotificationTypes,
} from "../../shared/enums";
import Api from "../../shared/Api";
import PhoneInputField from "../../custom/eqPhoneInput/EqPhoneInput";
import { PlusCircleTwoTone } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
const { Panel } = Collapse;
export interface IEqJoinEvent {
  event?: any;
}

interface IClass {
  lessonKey: string;
  title: string;
  horse: string;
  capacity: number;
  answers: [];
}
const EqJoinEvent = ({ event }: IEqJoinEvent) => {
  const userDetails: any = useAuthState();
  const isStudent: boolean = ValidateIsStudent(userDetails);
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  // const options = {
  //   weekday: "long",
  //   month: "long",
  //   day: "numeric",
  // };
  const options = {
    day: "2-digit", // Two-digit day (e.g., "04")
    month: "2-digit", // Two-digit month (e.g., "11")
  };
  const [lessonOptions, setLessonOptions] = React.useState([]) as any;
  const [selectedLessonsList, setSelectedLessonsList] = React.useState(
    []
  ) as any;
  const [questions, setQuestions] = React.useState([]) as any;

  const [activePanels, setActivePanels] = React.useState([]) as Array<any>;
  const [organizers, setOrganizers] = React.useState(
    event?.organizers as IUserProfileDto[]
  );
  const { Option } = Select;

  const [currentStep, setCurrentStep] = React.useState(0);
  const [hasValidationErrors, setHasValidationErrors] = React.useState(false);
  const [joinClassClicked, setJoinClassClicked] = React.useState(false);
  const { Step } = Steps;
  const steps = [
    {
      title: event?.customClassName
        ? t("select") + " " + event.customClassName
        : t("selectEventClass"),
    },
    {
      title: event?.customClassName
        ? t("add") + " " + event.customClassName
        : t("addClass"),
    },
    { title: t("joinEvent") },
  ];

  const [countryCode, setCountryCode] = React.useState("+1"); // set the initial country code

  const handleCountryCodeChange = (value: string) => {
    setCountryCode(value);
  };
  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [value, setValue] = React.useState(1);

  const { Text, Title } = Typography;

  const formInitial = {
    attendUsingName:"",
    firstName: "",
    surName: "",
    studentEmail: "",
    trainerEmail: event?.organizers[0]?.email,
    termsCheck: false,
    eventKey: "",
    phoneNumber: "",
    selectedLessons: {},
  };

  const [formData, setFormData] = React.useState({
    ...formInitial,
  }) as any;

  const [selectedClass, setSelectedClass] = React.useState() as any;
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  // const [questionAnswers, setQuestionAnswers] = React.useState<{ [questionId: string]: string }>({});
  const [questionAnswers, setQuestionAnswers] = React.useState() as any;

  const gutter = { xs: 8, sm: 16, md: 24, lg: 32 };

  const NavigateToLogin = () => {
    // e.stopPropagation();

    navigate(FrontEndPoints.login, {
      state: {
        // prevPath: location.href,
        prevPath: location.pathname + location.search,
      },
    });
  };

  const createClassLessonOptions = (eventData: any) => {
    let lessonOptionsObj = [] as any;
    eventData?.eventClasses?.forEach((classObj: any, index: number) => {
      classObj?.classTimes?.map((lesson: any, i: any) => {
        let isFull =
          lesson.attendees?.length >= lesson.maxAvailableSpace ? true : false;
        let capacity = isFull
          ? 0
          : lesson.maxAvailableSpace -
            (lesson.attendees ? lesson.attendees.length : 0);

        const title1 = isFull ? t("full") + "-" : "";
        const title =
          title1 +
          lesson.height +
          " - " +
          new Date(lesson.startDate).toLocaleDateString(
            t("dateLang"),
            options as any
          ) +
          " - " +
          // new Date(lesson.startTime).toLocaleTimeString() +
          // " - " +
          lesson.price +
          " " +
          EqCurrency[event.currency] +
          " - " +
          lesson.classDescription;

        let obj = {
          key: "" + lesson.key,
          value: title,
          disabled: isFull,
          capacity: capacity,
        };
        lessonOptionsObj.push(obj);
      });
    });
    console.log(lessonOptionsObj);
    setLessonOptions(lessonOptionsObj);
  };

  const createQuestionOptions = (questions: any) => {
    let lessonOptionsObj = [] as any;
    questions?.map((lesson: any, i: any) => {
      const title =
        "Class" +
        " - " +
        lesson.height +
        " - " +
        new Date(lesson.startDate).toLocaleDateString(
          t("dateLang"),
          options as any
        ) +
        " - " +
        // new Date(lesson.startTime).toLocaleTimeString() +
        // " - " +
        lesson.price +
        " " +
        EqCurrency[event.currency];

      let obj = {
        key: "" + lesson.key,
        value: title,
      };
      lessonOptionsObj.push(obj);
    });

    console.log(lessonOptionsObj);
    setLessonOptions(lessonOptionsObj);
  };

  const handleSelectClass = (selectedClassKey: any) => {
    // Update selected class and automatically proceed to the next step
    setSelectedClass(selectedClassKey);
    setCurrentStep(1); // Proceed to the next step (Add Class)
  };

  const handleOnClickAddLesson = () => {
    const selectedOption = lessonOptions.find(
      (option: any) => option.key === selectedClass
    );

    // check how many times class is selected
    let existingSelection = selectedLessonsList.filter(
      (obj: any) => obj.lessonKey === selectedClass
    );

    if (existingSelection.length === selectedOption.capacity) {
      // can't add more
      openNotificationWithIcon(
        "Can't Add more",
        "You Can't Add this Class more then that"
      );
    } else {
      let newSelectedLessonsList = [...selectedLessonsList];

      let newSelectedObj: IClass = {
        lessonKey: selectedOption.key,
        title: selectedOption.value,
        horse: "",
        capacity: 1,
        answers: [], // Add an array to store answers
      };
      newSelectedLessonsList.push(newSelectedObj);

      setSelectedLessonsList(newSelectedLessonsList);
      let panels = activePanels;
      panels.push(newSelectedLessonsList.length - 1 + "");
      setActivePanels(panels);

      // Proceed to the next step
      setCurrentStep(2);
    }

    setJoinClassClicked(true);
    setFormData({ ...formData, eventKey: "" });
  };

  const handleOnClickRemoveLesson = (index: any) => {
    let newSelectedLessonsList = [...selectedLessonsList];

    // var removedObj = newSelectedLessonsList.splice(1, 1);
    // Remove the element at the specified index
    var removedObj = newSelectedLessonsList.splice(index, 1);
    setSelectedLessonsList(newSelectedLessonsList);
  };

  // const handleOnChangeHorse = (e: any, index: number) => {
  //   const newSelectedLessonsList = [...selectedLessonsList];
  //   newSelectedLessonsList[index].horse = e.target.value;
  //   setSelectedLessonsList(newSelectedLessonsList);
  // };

  const handleOnChangeCapacity = (value: number, index: number) => {
    const newSelectedLessonsList = [...selectedLessonsList];
    newSelectedLessonsList[index].capacity = value;
    setSelectedLessonsList(newSelectedLessonsList);
  };

  const handleCollapseChange = (panelKey: string | string[]) => {
    setActivePanels(panelKey);
  };

  // Question Answers

  const handleOnChangeAnswer = (
    questionId: string,
    selectedAnswer: any,
    lessonIndex: number
  ) => {
    // Make a copy of the selectedLessonsList
    const newSelectedLessonsList = [...selectedLessonsList];
    // Find the lesson by its index
    const selectedLesson = newSelectedLessonsList[lessonIndex];

    // Find the question by its ID
    const question = questions.find((q: any) => q.id === questionId);

    if (selectedLesson && question) {
      // Check if the question type is numeric (questionType 0)

      // Check if the question already exists in the answers array
      const existingAnswerIndex = selectedLesson.answers.findIndex(
        (answer: any) => answer.questionId === questionId
      );

      // Create a new answer object
      let newAnswer: any = {
        questionId: questionId,
        questionType: question.questionType,
        // answer: "",
        // optionId: null,
      };

      if (question.questionType === 0) {
        newAnswer.answer = selectedAnswer;
      } else {
        newAnswer.answer = selectedAnswer.value;
        newAnswer.optionId = selectedAnswer.key;
      }

      // Update the answers array based on whether it exists or not
      if (existingAnswerIndex !== -1) {
        // If the question ID already exists, replace the existing answer
        selectedLesson.answers[existingAnswerIndex] = newAnswer;
      } else {
        // If the question ID doesn't exist, add the new answer
        selectedLesson.answers.push(newAnswer);
      }

      // Update the selectedLessonsList with the modified lesson
      newSelectedLessonsList[lessonIndex] = selectedLesson;

      // Update the state with the new selectedLessonsList
      setSelectedLessonsList(newSelectedLessonsList);
    }
  };

  const handleChange = (questionId: string, answer: string) => {
    
    setQuestionAnswers((prevAnswers: any) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const lessonSelectProps: ISelectProps = {
    // mode: null,
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    placeholder: "Select availbility of horse",
    classNames: "eq-addSelect extra-height",
    defaultSelectedValue: formData?.eventKey ? formData.eventKey + "" : "",
    options: lessonOptions,
    changeFunc: (value: string) => {
      // let eKey: number = +value;
      setFormData({ ...formData, eventKey: +value });
      const selectedLesson = lessonOptions.find((ls: any) => ls.key === value);
      if (selectedLesson.disabled) {
        setSelectedClass("");
      } else {
        setSelectedClass(value);
      }
      setCurrentStep(currentStep + 1); // Move to the next step
    },
  };

  const validateFormData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";

    if (isStudent) {
      if (selectedLessonsList.length == 0) {
        openNotificationWithIcon(msg, "Class" + desc);
        isComplete = false;
      }
    } else {
      if (data.firstName == "") {
        openNotificationWithIcon(msg, "FirstName" + desc);
        isComplete = false;
      } else if (data.surName == "") {
        openNotificationWithIcon(msg, "SurName" + desc);
        isComplete = false;
      } else if (data.studentEmail == "") {
        openNotificationWithIcon(msg, "Student Email" + desc);
        isComplete = false;
      } else if (selectedLessonsList.length == 0) {
        openNotificationWithIcon(msg, "Class" + desc);
        isComplete = false;
      } else if (data.phoneNumber == "") {
        openNotificationWithIcon(msg, "Phone Number" + desc);
        isComplete = false;
      } else if (data.termsCheck == false) {
        openNotificationWithIcon(msg, "Terms not checked");
        isComplete = false;
      }
    }

    if (questions && questions.length > 0) {
      if (selectedLessonsList.length > 0) {
        selectedLessonsList.forEach((lesson: any, sLIdx: any) => {
          questions.forEach((question: any) => {
            if (question.questionType === QuestionType.MultipleChoice) {
              // Check if there's a matching question in the lesson's answers array
              const matchingAnswer = lesson.answers.find(
                (answer: any) =>
                  answer.questionId === question.id &&
                  answer.questionType === question.questionType
              );

              if (!matchingAnswer) {
                // matching question is found not in the lesson
                openNotificationWithIcon(
                  msg,
                  `select ${question.questionText} at Lesson ${sLIdx}`
                );
                isComplete = false;
              }
            }
          });
          console.log(questions);
        });
      }
    }
    if (joinClassClicked) {
      setHasValidationErrors(!isComplete);
      setCurrentStep(2);
    }
    return isComplete;
  };

  const GuestStudentJoinEventRequest = (e: any) => {
    let validate = validateFormData(formData);
    if (validate == true) {
      let data = { ...formData };
      data.attendUsingName= formData.firstName+" "+formData.surName;
      data.eventKey = 0;
      data.phoneNumber = countryCode + formData.phoneNumber;
      data.selectedLessons = selectedLessonsList;

      Api.post(ApiEndpoints.GuestStudentJoinEventClasses, data).then(
        (response) => {
          if (response.status == 200) {
            window.location.href = response.headers.location;
          }
          window.location.href = response.headers.location;
        },
        (error) => {
          setIsError(true);
          setMessage(GetEventMessage(error.response.status));
          if (error.response.status === 409) {
            // already exist
            openNotificationWithIcon(
              "Error!",
              error.response.data,
              eqNotificationTypes.error
            );
            setTimeout(() => window.location.reload(), 3000);
            // setIsEventSubscribed(false);
          }
        }
      );
    }
  };

  const StudentJoinEventClassesWithCheckout = (e: any) => {
    let validate = validateFormData(formData);
    if (validate == true) {
      let data = { ...formData };
      data.eventKey = 0;
      data.phoneNumber = countryCode + formData.phoneNumber;
      data.selectedLessons = selectedLessonsList;

      Api.post(ApiEndpoints.StudentJoinEventClassesWithCheckout, data).then(
        (response) => {
          if (response.status == 200) {
            window.location.href = response.headers.location;
          }
          window.location.href = response.headers.location;
        },
        (error) => {
          setIsError(true);
          setMessage(GetEventMessage(error.response.status));
          if (error.response.status == 409) {
            // already exist
            openNotificationWithIcon(
              "Error!",
              error.response.data,
              eqNotificationTypes.error
            );
            // setIsEventSubscribed(false);
          }
        }
      );
    }
  };
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const onlyNums = value.replace(/[^0-9]/g, ""); // allow only numbers

    // set the formatted phone number as the input value
    e.target.value = onlyNums;
    setFormData({ ...formData, phoneNumber: onlyNums });

    // setInputNumber(onlyNums);
  };
  React.useEffect(() => {
    // history.push(FrontEndPoints.trainer.createEvent);

    if (event) {
      createClassLessonOptions(event);
      setQuestions(event.questions);
      // set Form Defaults
      if (userDetails && isStudent) {
        const currentUserEmail = getEmailFromToken();
        const name = userDetails.name?.split(" ");
        const fName = name[0] ? name[0] : "";
        const lName = name[1] ? name[1] : "";
        setFormData({
          ...formData,
          firstName: fName,
          surName: lName,
          studentEmail: currentUserEmail,
          trainerEmail: event?.organizers[0]?.email,
          termsCheck: false,
          eventKey: "",
        });

        console.log(userDetails);
      } else {
        setFormData({
          ...formData,
          firstName: "",
          surName: "",
          studentEmail: "",
          trainerEmail: event?.organizers[0]?.email,
          termsCheck: false,
          eventKey: "",
        });
      }
    }
  }, [event]);

  //console.log("Data", attendeeCardData);

  return (
    <div className="eq-join-event">
      <div className="eq-join-event-container">
        <h4 className="custom-title"> {t("join")}</h4>

        <div className="join-class-steps">
          <Steps current={currentStep}>
            {steps.map((step, index) => (
              <Step
                key={index}
                title={step.title}
                status={
                  currentStep === 2 && index === 2
                    ? "process"
                    : currentStep >= index
                    ? "process"
                    : "wait"
                }
              />
            ))}
          </Steps>
        </div>

        {/* Selected Classes*/}

        <div className="section event-info-input ">
          {selectedLessonsList.length > 0 && (
            <>
              {/* <div className="section-heading">
                {" "}
                {event?.customClassName
                  ? t("selected") + " " + event?.customClassName
                  : t("selectedClasses")}
              </div> */}
              <Collapse
                activeKey={activePanels}
                onChange={handleCollapseChange}
              >
                {selectedLessonsList.map((lesson: any, index: number) => (
                  <Panel
                    header={lesson.title}
                    key={index}
                    extra={
                      <div className="trash-bin">
                        <span>
                          <img
                            alt=""
                            src="/trash-alt-red.svg"
                            onClick={() => handleOnClickRemoveLesson(index)}
                            // onClick={handleOnClickRemoveLesson}
                          />
                        </span>
                      </div>
                    }
                  >
                    {/* <Row>
                      <Col xs={24} sm={24} md={12}>
                        <div className="eq-input-group">
                          <div className="input-label">Horse</div>
                          <EqInput
                            className="name-input"
                            value={lesson.horse}
                            onChange={(e: any) => handleOnChangeHorse(e, index)}
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <div className="eq-input-group">
                          <div className="input-label">{t("count")}</div>
                          <EqInputNumber
                            min={1}
                            value={lesson.capacity}
                            onChange={(value: any) =>
                              handleOnChangeCapacity(value, index)
                            }
                          />
                        </div>
                      </Col>
                    </Row> */}
                    {/* Add a section for questions */}
                    {questions && questions.length > 0 && (
                      <>
                        {/* <div className="section-heading"></div> */}
                        <Row>
                          {questions.map((question: any) => (
                            <Col xs={24} sm={24} md={12}>
                              <div key={question.id}>
                                {/* Render questions based on their type */}
                                {question.questionType === 0 ? (
                                  <div className="eq-input-group">
                                    <div className="input-label">
                                      {question.questionText}
                                    </div>
                                    <EqInput
                                      className="name-input"
                                      // Handle input change here and store the answers in state
                                      onChange={(e: any) =>
                                        handleOnChangeAnswer(
                                          question.id,
                                          e.target.value,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <div className="eq-input-group">
                                      <div className="input-label">
                                        {question.questionText}*
                                      </div>
                                      <Select
                                        className="input-select"
                                        suffixIcon={[
                                          <span className="">
                                            <img alt="" src="/caret-down.svg" />
                                          </span>,
                                        ]}
                                        // Handle select change here and store the answers in state

                                        onChange={(value: any, option: any) =>
                                          handleOnChangeAnswer(
                                            question.id,
                                            option,
                                            index
                                          )
                                        }

                                        // question.options to populate select options
                                      >
                                        {question.options.map((option: any) => (
                                          <Option
                                            key={option.id}
                                            value={option.optionText}
                                          >
                                            {option.optionText}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  </>
                                )}
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </>
                    )}
                    {/* End of questions section */}
                  </Panel>
                ))}
              </Collapse>
            </>
          )}
        </div>

        {/* Select Class To Join */}
        <div className="section">
          <div className="section-heading">
            {event?.customClassName
              ? t("select") + " " + event?.customClassName
              : t("selectClass")}
          </div>

          <Row className="add-class">
            <Col xs={24} md={12}>
              <div className="eq-input-group">
                {/* <div className="input-label">Select Class</div> */}
                <EqSelect
                  {...lessonSelectProps}
                  //  onChange={handleSelectClass}
                />
              </div>
            </Col>

            <Col>
              <EqButton
                className="add-class-btn"
                type="dashed"
                icon={<PlusCircleTwoTone />}
                onClick={handleOnClickAddLesson}
                disabled={!selectedClass}
                style={{
                  background: selectedClass ? "" : "#979797",
                  borderColor: selectedClass ? "" : "#979797",
                }}
              >
                {t("add")}
                {/* {event?.customClassName
                  ? t("add") + " " + event?.customClassName
                  : t("addClass")} */}
              </EqButton>

              {/* <img alt="" className="add" src="/plus-black.svg" /> */}
            </Col>
          </Row>
        </div>

        {/* Registration From */}
        <div className="section event-info-input">
          {selectedLessonsList.length > 0 && (
            <Row className="register-event">
              <Col className="gutter-row" span={24}>
                {isStudent ? (
                  <Row className="register-event-gust">
                    <Col className="section-heading">
                      {t("Join Event")}
                    </Col>
                    <Col className="col-1" xs={24} sm={24} md={12}>
                    <div className="eq-input-group">
                        <div className="input-label">Attend Using Name</div>
                        <EqInput
                          className="name-input"
                          value={formData.attendUsingName}
                          // disabled={isStudent}
                          onChange={(e: any) =>
                            setFormData({
                              ...formData,
                              attendUsingName: e?.target?.value,
                            })
                          }
                        />
                      </div>
                    <div className="eq-input-group">
                      <div className="event-type-btn">
                        <EqButton
                          className="event-type-btn"
                          type="button"
                          onClick={StudentJoinEventClassesWithCheckout}
                          >
                          {t("joinEvent")}
                        </EqButton>
                      </div>
                    </div>
                        </Col>
                  </Row>
                ) : (
                  <Row className="register-event-gust">
                    <Col className="section-heading">
                      {" "}
                      {t("registrationForm")}
                    </Col>
                    <Col className="col-1" xs={24} sm={24} md={12}>
                      <div className="eq-input-group">
                        <div className="input-label">{t("firstName")}*</div>
                        <EqInput
                          className="name-input"
                          value={formData.firstName}
                          disabled={isStudent}
                          onChange={(e: any) =>
                            setFormData({
                              ...formData,
                              firstName: e?.target?.value,
                            })
                          }
                        />
                      </div>
                      <div className="eq-input-group">
                        <div className="input-label">{t("surName")}*</div>
                        <EqInput
                          className="name-input"
                          value={formData.surName}
                          disabled={isStudent}
                          onChange={(e: any) =>
                            setFormData({
                              ...formData,
                              surName: e?.target?.value,
                            })
                          }
                        />
                      </div>
                      <div className="eq-input-group">
                        <div className="input-label">{t("e-mail")}*</div>
                        <EqInput
                          className="name-input"
                          type="email"
                          disabled={isStudent}
                          value={formData.studentEmail}
                          onChange={(e: any) =>
                            setFormData({
                              ...formData,
                              studentEmail: e?.target?.value,
                            })
                          }
                        />
                      </div>

                      <div className="eq-input-group">
                        <div className="event-type">
                          <div className="input-label">{t("phoneNumber")}*</div>
                          <div className="toggler">
                            <EqInput
                              disabled={isStudent}
                              type="text"
                              value={formData.phoneNumber}
                              addonBefore={
                                <>
                                  {/* <Select options={options}></Select> */}
                                  <Select
                                    value={countryCode}
                                    onChange={handleCountryCodeChange}
                                    bordered={false}
                                  >
                                    <Option value="+47">
                                      <span role="img" aria-label="Norway">
                                        🇳🇴
                                      </span>{" "}
                                      +47
                                    </Option>
                                    <Option value="+46">
                                      <span role="img" aria-label="Sweden">
                                        🇸🇪
                                      </span>{" "}
                                      +46
                                    </Option>
                                    <Option value="+1">
                                      <span role="img" aria-label="USA">
                                        🇺🇸
                                      </span>{" "}
                                      +1
                                    </Option>
                                    <Option value="+45">
                                      <span role="img" aria-label="Denmark">
                                        🇩🇰
                                      </span>{" "}
                                      +45
                                    </Option>
                                  </Select>
                                </>
                              }
                              placeholder="Enter your phone number"
                              onChange={handlePhoneNumberChange}
                              // value={inputNumber}
                            />
                          </div>
                        </div>
                      </div>

                      {/* <PhoneInputField></PhoneInputField> */}

                      <Modal
                        title="Trainer Terms"
                        open={isModalOpen}
                        onOk={() => {
                          setIsModalOpen(false);
                        }}
                        onCancel={() => {
                          setIsModalOpen(false);
                        }}
                      >
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {event?.organizers[0]?.trainerTerms}
                        </p>
                      </Modal>
                      <div className="eq-input-group">
                        <div className="event-type">
                          <Checkbox
                            checked={formData.termsCheck}
                            onChange={(e: any) =>
                              setFormData({
                                ...formData,
                                termsCheck: e.target.checked,
                              })
                            }
                          >
                            {" "}
                            <Text>
                              {t("iAccept")}{" "}
                              <a
                                className="eq-anchor"
                                href={
                                  event?.eventTermsFile
                                    ? config.apiUrl + event?.eventTermsFile
                                    : ""
                                }
                              >
                                <u>{t("eventTerms")}</u>
                              </a>{" "}
                              {t("ofThisEvent")}
                            </Text>
                          </Checkbox>
                        </div>
                      </div>

                      <div className="eq-input-group">
                        <div className="event-type-btn">
                          <EqButton
                            disabled={isTrainer}
                            type="button"
                            onClick={GuestStudentJoinEventRequest}
                            // disabled={subscriptionStatus.buttonDisabled}
                          >
                            {t("joinEvent")}
                          </EqButton>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};
export default EqJoinEvent;
