import React, { useState, useEffect } from "react";
import "./EqNotification.scss";
import { Row, Col, Checkbox, Tag } from "antd";
import EqComposeMessageInline from "../eqComposeMessageInline/EqComposeMessageInline";
import Api from "../../shared/Api";
import { ApiEndpoints, EqMessageType } from "../../shared/Common";

export interface IEqMessage {
  message: IEqMessageData;
  selected: boolean;
  checkBoxBordered: boolean;
  clickedReplyFun: (value: IEqMessageData) => void;
  clickedFlagFun: (value: IEqMessageData) => void;
  clickedDeleteFunc: (value: IEqMessageData) => void;
  onCheckChangeFunc: (value: IEqMessageData, checked: boolean) => void;
}

export interface IEqMessageData {
  key: string;
  isRead?: boolean;
  isSent?: boolean;
  isFlaged?: boolean;
  messageOn: Date;
  type: string;
  from: string;
  to: string;
  subject: string;
  text: string;
}

const EqNotification = ({
  message,
  selected,
  clickedReplyFun,
  clickedFlagFun,
  clickedDeleteFunc,
  onCheckChangeFunc,
  ...rest
}: IEqMessage) => {
  const [receivedMessage, setReceivedMessage] = useState({ ...message });
  const [check, setCheck] = useState(selected);
  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState(receivedMessage.isFlaged);

  const replyClicked = (e: any) => {
    toggleShow(true);
    e.stopPropagation();
  };

  const flagClicked = (e: any) => {
    e.stopPropagation();
    setFlag(!flag);
    setReceivedMessage({ ...receivedMessage, isFlaged: !flag });
    clickedFlagFun({ ...receivedMessage, isFlaged: !flag });
  };

  const deleteClicked = (e: any) => {
    e.stopPropagation();
    clickedDeleteFunc(message);
  };
  const toggleShow = (value: any) => {
    
    setShow(value);
    if (receivedMessage.isRead == false) {
      // saveMessage();
      const message=receivedMessage;
      message.isRead=true;
    setReceivedMessage( message);

      clickedFlagFun(message);
    }
    return null;
  };
  function onChange(e: any) {
    setCheck(!check);
    e.stopPropagation();
    onCheckChangeFunc(message, !check);
  }
  const saveMessage = () => {
    if (receivedMessage != null) {
      Api.post(ApiEndpoints.saveMessage, {
        Message: { Key: receivedMessage.key },
        IsFlaged: receivedMessage.isFlaged,
        IsRead: true,
      }).then(
        (response) => {
          if (response && response.status == 200) {
            setReceivedMessage({ ...receivedMessage, isRead: true });
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const inlineMessageProps = {
    messageDetail: receivedMessage,
    showComponent: show,
    setShowComponent: toggleShow,
    sendReplyClicked: clickedReplyFun,
  };
  useEffect(() => {
    
    console.log(message);
    setCheck(selected);
    setReceivedMessage({ ...message });
  }, [message, selected]);
  return (
    <div className="eq-notification">
      <div className="eq-nf-container">
        {receivedMessage && (
          <>
            <Row
              className={
                receivedMessage.isRead == true
                  ? "eq-message-read"
                  : "eq-message-unread"
              }
              id="message-container"
              onClick={() => toggleShow(!show)}
            >
              <Col span={22}>
                <Row>
                  <Col 
                //   span={12}
                    id="mFrom"
                    className="eq-message-item eq-message-item-from eq-message-body-text"
                  >
                   <Tag>{receivedMessage.from}</Tag> 
                  </Col>
                  <Col  
                //   span={12}
                    id="mSubject"
                    className="eq-message-item eq-message-item-title eq-message-body-text"
                  >
                    {receivedMessage.subject}
                  </Col>
                </Row>
                <Row>
                  <Col
                    // xs={24}
                    // sm={24}
                    // md={11}
                    id="mSubject"
                    className="eq-message-item eq-message-item-title eq-message-body-text"
                  >
                    {receivedMessage.text}
                  </Col>
                </Row>
              </Col>
              <Col
                xs={2}
                sm={2}
                md={2}
                id="mActions"
                className="eq-message-item eq-message-item-action"
              >
                <Row className="eq-message-action">
                  <Col
                    className="eq-message-action-delete"
                    onClick={deleteClicked}
                  >
                    <span className="">
                      <img alt="" src="/trash-alt.svg" />
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default EqNotification;
