import * as React from "react";
import { Row, Col, Popover, notification, Popconfirm, Modal } from "antd";
import "./EqActivityCollapseDashboard.scss";
import { Tag } from "antd";
import EqCollapse, { IEqCollapse } from "../../custom/eqCollapse/EqCollapse";
import { IGroupDto } from "../../shared/interfaces/IGroupDto";
import { useTranslation, Trans } from "react-i18next";

import {
    EqDisciplineType,
    EqEventAccessType,
    EqEventUserType,
    EqSlotStatusEnum,
    EqTrainingType,
    eqNotificationTypes,
} from "../../shared/enums";
import moment from "moment";
import { IStudentGroupDto } from "../../shared/interfaces/IStudentGroupDto";
import {
    addDays,
    ApiEndpoints,
    checkDuePayment,
    dateFormat,
    FrontEndPoints,
    getDefaultImage,
    getEmailFromToken,
    GetEventMessage,
    imageType,
    IsStudentUnderAge,
    ValidateIsEventHolder,
    ValidateIsParent,
    ValidateIsSchool,
    ValidateIsStudent,
    ValidateIsTrainer,
    ValidateIsUser,
    ValidateStudentCanBook,
    ValidateStudentCanPay,
} from "../../shared/Common";
import { IEventUserGroupDto } from "../../shared/interfaces/IEventUserGroupDto";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { IUserProfileDto } from "../../shared/interfaces/IUserProfileDto";
import { loadState, saveEvent } from "../../shared/LocalStorage";
import { useAuthState } from "../../context/Context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CreateActivity from "../createActivity/CreateActivity";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import EqActivityStudentView from "../../eqStudent/eqActivityStudentView/EqActivityStudentView";
import EqButton from "../../custom/eqButton/EqButton";
import { ISubscribeEventDto } from "../../shared/interfaces/ISubscribeEventDto";
import Api from "../../shared/Api";
import {
    EqIconAttendee,
    EqIconCalendar,
    EqIconLocation,
    EqIconPrice,
    EqIconUser,
} from "../../custom/eqIcon/EqIcons";
import Tooltip from "antd/es/tooltip";
import EqTags from "../../custom/eqTags/EqTags";

export interface IEqActivityCollapse {
    event: any;
    creatorUser?: any;
    areas: any;
    weekDays: any;
    population?: any;
    groupPopulation?: any;
    trainerStudents?: any;
    studentHorsesList?: any;
    removeEventFunc?: (data: any) => void;
    removeAttendeeFunc?: (data: any) => void;
    editEventFunc?: (data: any) => void;
    onCreateOrUpdateActivity?: any;
}
//IEqEvent
const EqActivityCollapseDashboard = ({
    creatorUser,
    event,
    areas,
    weekDays,
    population,
    groupPopulation,
    trainerStudents,
    studentHorsesList,
    removeEventFunc,
    editEventFunc,
    removeAttendeeFunc,
    onCreateOrUpdateActivity,
    ...rest
}: any) => {
    const userDetails: any = useAuthState();
    const isLoggedInUser: boolean = ValidateIsUser(userDetails);
    const isTrainer: boolean = ValidateIsTrainer(userDetails);
    const isEventHolder: boolean = ValidateIsEventHolder(userDetails);
    const isSchool: boolean = ValidateIsSchool(userDetails);
    const isParent: boolean = ValidateIsParent(userDetails);

    const isStudent: boolean = ValidateIsStudent(userDetails);
    const isStudentUnderAge: boolean = IsStudentUnderAge(userDetails);
    const studentCanBook: boolean = ValidateStudentCanBook(userDetails);
    const studentCanPay: boolean = ValidateStudentCanPay(userDetails);
    const { t, i18n } = useTranslation();

    //Modal
    const [modalRefundPayment, contextRefundPayment] = Modal.useModal();

    const currentUserKey = getEmailFromToken();

    const collapseInnerRef = React.useRef<any>(null);
    const [collapseInnerHeight, setCollapseInnerHeight] = React.useState(0);


    const collapseRef = React.useRef(null) as any;


    const [isError, setIsError] = React.useState(false);
    const [message, setMessage] = React.useState("");
    // subscription Related
    const [slotRange, setSlotRange] = React.useState({
        allSlotRanges: [],
        selectedSlotRange: [],
        currentUserSlots: [],
        currentSelection: {},
        isSlotSelected: false,
    } as any);
    const [subscriptionStatus, setSubscriptionStatus] = React.useState({
        buttonDisabled: false,
        buttonTextSubscribe: "Join",
        buttonTextSubscribeAll: "Join all",
    } as any);
    const [isEventSubscribed, setIsEventSubscribed] = React.useState(false);
    const [organizers, setOrganizers] = React.useState(
        event?.organizer as IUserProfileDto[]
    );
    const [isPaidByUser, setIsPaidByUser] = React.useState(false);

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayTime = today.getTime();
    //
    //console.log(userDetails);
    //console.log(event.organizers);
    const [isAttendee, setIsAttendee] = React.useState(false);
    const [studentGroups, setStudentGroups] = React.useState([]) as any;
    const [isOwner, setIsOwner] = React.useState(false);
    const [isStudentTrainings, setIsStudentTrainings] = React.useState(false);
    const [toggleRow, setToggleRow] = React.useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const [path, setPath] = React.useState(location.pathname);

    const openNotificationCheckOutNotification = (
        msg: any,
        desc: any,
        event: any
    ) => {
        const btn = (
            <div className="right">
                <EqButton onClick={CreateCheckoutSession}>Pay Now</EqButton>
            </div>
        );
        notification["info"]({
            message: msg,
            btn,
            description: desc,
        });
    };

    const checkStudentTrainings = () => {
        let pathname = location.pathname;

        if (pathname == FrontEndPoints.student.allTrainings) {
            setIsStudentTrainings(true);
        } else {
            setIsStudentTrainings(false);
        }
    };
    const checkEventOwner = () => {
        //console.log(event?.organizers);

        var owner = event?.organizers
            ?.filter((o: any) => {
                return o.user.key == userDetails.key;
            })
            .shift();
        //console.log(owner);
        if (owner != null || undefined) {
            setIsOwner(true);
        } else {
            setIsOwner(false);
        }
    };
    const checkEventAttendee = () => {
        //console.log(event?.attendees);
        var attendee = event?.attendees
            ?.filter((a: any) => {
                return a.user.key == userDetails.key;
            })
            .shift();
        //console.log(attendee);
        if (attendee != null || undefined) {
            setIsAttendee(true);
        }
    };
    //console.log(event);
    const getEventGroups = () => {
        //console.log(event);
        var studentGroups = event?.attendees?.filter((x: any) => x.group != null);
        studentGroups = studentGroups?.map((d: any) => d.group);
        const result = [];
        const map = new Map();
        if (studentGroups != null && studentGroups.length > 0) {
            for (const item of studentGroups) {
                if (!map.has(item.key)) {
                    map.set(item.key, true); // set any value to Map
                    result.push(item);
                }
            }
        }
        setStudentGroups(result);
    };

    const handleDelete = (e: any) => {
        e.stopPropagation();
        if (isOwner) {
            if (removeEventFunc != null) {
                confirmPaymentRefund(event);
            }
        }
    };

    const confirmPaymentRefund = (event: any) => {
        modalRefundPayment.confirm({
            title: "Confirm",
            icon: <ExclamationCircleOutlined />,
            content: "Would you like to Refund Attendee",
            okText: "Refund",
            cancelText: "Pay Later",
            onOk: () => removeEventFunc(event, true),
            onCancel: () => removeEventFunc(event, false),
        });
    };

    const removeEvent = (eventArgs: any) => {
        eventArgs.stopPropagation();

        if (isOwner) {
            if (removeEventFunc != null) {
                removeEventFunc(event);
            }
        } else if (isAttendee) {
            if (removeAttendeeFunc != null) {
                removeAttendeeFunc(event);
            }
        }
    };
    const removeAttendee = (eventArgs: any) => {
        eventArgs.stopPropagation();
        removeAttendeeFunc(event);
    };
    // Subscription Related
    const viewEvent = (event: IEqEventData) => {
        saveEvent(event);
        const key = `open${Date.now()}`;
        notification.close(key);
        navigate(`${FrontEndPoints.student.viewEvent}?key=${event.key}`, {
            state: {
                event: event,
            },
        });
    };
    const openNotificationWithIcon = (msg: any, desc: any, event: any) => {
        const key = `open${Date.now()}`;
        const btn = (
            <EqButton
                type="button"
            // onClick={() => viewEvent(event)}
            >
                Pay Now
            </EqButton>
        );
        notification["error"]({
            message: msg,
            btn,
            key,
            description: desc,
        });
    };

    const updateSubscribeButtonState = (
        isSlotSelected: boolean,
        currentUserSlots: any,
        allSlots: any
    ) => {
        // console.log(currentUserSlots);
        if (event?.disciplineType != EqDisciplineType.Open) {
            if (isEventSubscribed) {
                console.log("Joined");
                setSubscriptionStatus({
                    ...subscriptionStatus,
                    buttonDisabled: true,
                    buttonTextSubscribe: t("Joined"),
                });
            } else {
                setSubscriptionStatus({
                    ...subscriptionStatus,
                    buttonDisabled: false,
                    buttonTextSubscribe: t("Join"),
                });
            }
        } else if (
            allSlots?.length == 0 &&
            event?.disciplineType == EqDisciplineType.Open
        ) {
            setSubscriptionStatus({
                ...subscriptionStatus,
                buttonDisabled: true,
                buttonTextSubscribe: t("Join"),
            });
        } else if (currentUserKey?.length > 0) {
            if (event?.disciplineType == EqDisciplineType.Open) {
                if (isSlotSelected == false) {
                    setSubscriptionStatus({
                        ...subscriptionStatus,
                        buttonDisabled: true,
                        buttonTextSubscribe: t("Join"),
                    });
                } else {
                    setSubscriptionStatus({
                        ...subscriptionStatus,
                        buttonDisabled: false,
                        buttonTextSubscribe: t("Join"),
                    });
                }
            } else {
                if (currentUserSlots?.length > 0) {
                    setSubscriptionStatus({
                        ...subscriptionStatus,
                        buttonDisabled: true,
                        buttonTextSubscribe: t("Joined"),
                    });
                } else {
                    setSubscriptionStatus({
                        ...subscriptionStatus,
                        buttonDisabled: false,
                        buttonTextSubscribe: t("Join"),
                    });
                }
            }
        } else {
            setSubscriptionStatus({
                ...subscriptionStatus,
                buttonDisabled: true,
                buttonTextSubscribe: t("Joined"),
            });
        }
    };
    const setTimeSlots = (event: any) => {
        var allSlots = [] as any;
        let currentUserRange = [] as any;
        let selectedRange = [] as any;

        event?.attendees?.map((attendee: any) => {
            if (attendee.slotStartTime > 0) {
                let slotStartTime = moment(attendee.slotStartTime);
                let slotEndTime = moment(attendee.slotStartTime).hours(
                    moment(attendee.slotStartTime).hours() + 1
                );

                let slot = {
                    key: slotStartTime.valueOf(),
                    value:
                        slotStartTime.format("HH:mm") + "-" + slotEndTime.format("HH:mm"),
                };

                selectedRange.push(slot);

                if (attendee.user?.email == currentUserKey) {
                    currentUserRange.push(slot);
                }
            }
        });

        if (event?.disciplineType == EqDisciplineType.Open) {
            let slotStartTime = moment(event.startTime);
            let slotEndTime = moment(event.startTime).hours(
                moment(event.startTime).hours() + 1
            );

            let totalHours = Math.floor(
                (event.endTime - event.startTime) / (3600 * 1000)
            );

            let currentTags = currentUserRange;

            for (var hour = 0; hour < totalHours; hour++) {
                let slot = {
                    key: slotStartTime.valueOf(),
                    value:
                        slotStartTime.format("HH:mm") + "-" + slotEndTime.format("HH:mm"),
                };

                if (!selectedRange?.some((x: any) => x.key == slot.key)) {
                    allSlots.push(slot);
                }

                slotStartTime = slotStartTime.hours(slotStartTime.hours() + 1);
                slotEndTime = slotEndTime.hours(slotStartTime.hours() + 1);
            }
        }

        setSlotRange({
            ...slotRange,
            allSlotRanges: allSlots,
            selectedSlotRange: selectedRange,
            currentUserSlots: currentUserRange,
        });
        updateSubscribeButtonState(false, currentUserRange, allSlots);
    };

    const setupEvent = (evt: any) => {
        // console.log(evt, evt?.attendees?.length, evt.maxAvailableSpace);
        setTimeSlots({ ...evt });
        if (evt && evt.organizers) {
            setOrganizers(evt.organizers);
        }
        // replacing userKey with Key
        if (evt?.attendees?.some((item: any) => item.key == currentUserKey)) {
            if (
                evt?.attendees?.some(
                    (item: any) => item.key == currentUserKey && item.userStatus != 1
                )
            ) {
                setIsEventSubscribed(false);
                setSubscriptionStatus({
                    ...subscriptionStatus,
                    buttonDisabled: false,
                    buttonTextSubscribe: t("Join"),
                });
            } else {
                setIsEventSubscribed(true);
                setSubscriptionStatus({
                    ...subscriptionStatus,
                    buttonDisabled: true,
                    buttonTextSubscribe: t("Joined"),
                });

                if (
                    evt?.payments?.some((item: any) => item.user?.email == currentUserKey)
                ) {
                    setIsPaidByUser(true);
                }
            }
        } else if (
            evt?.maxAvailableSpace &&
            evt?.maxAvailableSpace > 0 &&
            evt?.attendees &&
            evt?.attendees?.length > 0 &&
            evt?.attendees?.length > evt.maxAvailableSpace
        ) {
            setSubscriptionStatus({
                ...subscriptionStatus,
                buttonDisabled: true,
                buttonTextSubscribe: t("join"),
            });
        } else {
            setSubscriptionStatus({
                ...subscriptionStatus,
                buttonDisabled: false,
                buttonTextSubscribe: t("join"),
            });
        }
    };

    const dateTomorrow = () => {
        var result = addDays(new Date(), 1).getTime();
        return result;
    };

    const CreateCheckoutSession = () => {

        var eventKey = event?.key;
        Api.post(ApiEndpoints.StudentTrainingStripeCheckout.Create, {
            key: eventKey,
        })
            .then((response) => {
                console.log(response);
                window.location.href = response.data.sessionUrl;
            })
            .catch((error) => {
                openNotificationWithIcon(
                    "Error!",
                    "Unable to Create checkout",
                    eqNotificationTypes.error
                );
            });
    };

    const subscribeTraining = (e: any) => {
        e.stopPropagation();

        console.log(userDetails);
        console.log(isParent);
        if (isLoggedInUser == false) {
            navigate(FrontEndPoints.login);
        } else {
            let selected = slotRange.currentSelection;

            let data: ISubscribeEventDto = {
                ownerKey: organizers != undefined ? organizers[0].email : "",
                subscriberKey: currentUserKey,
                eventKey: event.key,
                slotStartTime: selected?.key,
                slotStatus: EqSlotStatusEnum.Booked,
                eventUserType: EqEventUserType.Attendee,
            };

            checkDuePayment(openNotificationWithIcon).then(
                (success: any) => {
                    //    console.log("success", success);
                    if (success == false) {
                        Api.post(ApiEndpoints.eventSubscribe, data).then(
                            (response) => {
                                setIsEventSubscribed(true);
                                setSubscriptionStatus({
                                    ...subscriptionStatus,
                                    buttonDisabled: true,
                                    buttonTextSubscribe: t("Joined"),
                                });

                                if (response && response.data != null) {
                                    //console.log(response);
                                    openNotificationCheckOutNotification(
                                        "Pay for the accepted Activity",
                                        "",
                                        event
                                    );

                                    onCreateOrUpdateActivity();

                                    let currentUserList = [
                                        ...slotRange.currentUserSlots,
                                        selected,
                                    ];
                                    let selectedList = [...slotRange.selectedSlotRange, selected];
                                    let remaingAllList = slotRange.allSlotRanges.filter(
                                        (x: any) => x.key != selected.key
                                    );

                                    setSlotRange({
                                        allSlotRanges: remaingAllList,
                                        selectedSlotRange: selectedList,
                                        currentUserSlots: currentUserList,
                                        currentSelection: {},
                                        isSlotSelected: false,
                                    });
                                    updateSubscribeButtonState(
                                        false,
                                        currentUserList,
                                        remaingAllList
                                    );
                                }
                            },
                            (error) => {
                                setIsError(true);
                                setMessage(GetEventMessage(error.response.status));
                                setIsEventSubscribed(false);
                            }
                        );
                    }
                },
                (err: any) => {
                    console.log("error", err);
                    setIsError(true);
                    setMessage(GetEventMessage(err.response.status));
                    setIsEventSubscribed(false);
                }
            );
        }
    };

    //

    const editEvent = (eventArgs: any) => {
        eventArgs.stopPropagation();
        if (editEventFunc != null) {
            editEventFunc(event);
        } else {
            navigate(FrontEndPoints.trainer.createEvent, { state: { event: event } });
        }
    };

    const rowClick = () => {
        console.log(event);
        setToggleRow(!toggleRow);
    };
    const collapsableProps: IEqCollapse = {
        display: event.display,
        header: [
            <Row className="eq-event-card-header">
                <Col span={19}>
                    {event.title || EqDisciplineType[event.disciplineType]}
                </Col>
            </Row>,
        ],
    };
    React.useEffect(() => {
        // console.log("path", path);
        setCollapseInnerHeight(collapseInnerRef.current.clientHeight);

        if (event.collapseOpen) {
            collapseRef.current.scrollIntoView({ behavior: 'smooth' });

        }
        getEventGroups();
        checkEventOwner();
        checkEventAttendee();
        checkStudentTrainings();
        // copied from ViewEvent
        setupEvent(event);
    }, [event]);
    return (
        <div className="eq-activity-collapse" ref={collapseRef}>
            <EqCollapsePanel
                defaultOpen={event.collapseOpen}
                header={
                    <>
                        <div
                            style={{ display: "none" }}
                            className="eq-event-card hidden-md"
                        // onClick={rowClick}
                        >
                            <Row className="eq-event-card-header" align="middle">
                                <Col span={6}>{moment(event.startTime).format("hh:mm A")}</Col>
                                <Col span={11}>
                                    {event.title || t(EqDisciplineType[event.disciplineType])}
                                </Col>
                                <Col span={5}>
                                    {isStudent && (
                                        <EqButton
                                            type="button"
                                            onClick={subscribeTraining}
                                            disabled={subscriptionStatus.buttonDisabled}
                                        >
                                            {subscriptionStatus.buttonTextSubscribe}
                                        </EqButton>
                                    )}
                                </Col>
                                <Col className="eq-event-actions-trash">
                                    <span>
                                        <img
                                            alt=""
                                            src="/trash-alt-red.svg"
                                            onClick={removeEvent}
                                        />
                                    </span>
                                </Col>
                                <Col xs={2} md={1}>
                                    <Popover
                                        placement="bottomRight"
                                        content={
                                            isOwner || (isAttendee && isStudentTrainings) ? (
                                                <div className="eq-event-action">
                                                    <Row>
                                                        <Col flex="auto" className="eq-card-divider"></Col>
                                                    </Row>
                                                    <div className="eq-card-row">
                                                        <Row>
                                                            <Col className="actions-btn">
                                                                <Row>
                                                                    <Col className="eq-event-actions-trash">
                                                                        <span>
                                                                            <img
                                                                                alt=""
                                                                                src="/trash-alt-red.svg"
                                                                                // src="/trash-alt.svg"
                                                                                onClick={removeEvent}
                                                                            // onClick={handleDelete}
                                                                            />
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                                // <EqButton
                                                //   type="button"
                                                //   // onClick={subscribe}
                                                // >
                                                //   {" "}
                                                //   {"Join"}
                                                // </EqButton>
                                            )
                                        }
                                        trigger="click"
                                    >
                                        <MoreOutlined
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                            }}
                                            className="more-icon"
                                        />
                                        {/* <Button>BR</Button> */}
                                    </Popover>
                                </Col>
                            </Row>
                        </div>

                        <div className="eq-activity-row">
                            <Row className="eq-status-row-border" align="middle">
                                <Col className="content-col">
                                    <Row>
                                        <Col>
                                            <div className="date-icon">
                                                <EqIconCalendar
                                                    eqWidth={34}
                                                    eqHeight={34}
                                                    eqClass="eq-cal-icon"
                                                    eqFill="#083b49"
                                                />
                                                <div className="day">
                                                    {moment(event.startDate).format("DD")}
                                                </div>
                                                <div className="month">
                                                    {moment(event.startDate).format("MMM")}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="header-details">
                                            <Row>
                                                <Col></Col>
                                                <Col>
                                                    <span className="time">
                                                        {moment(event.startTime).format("hh:mm A")} -{" "}
                                                        {event.disciplineType != EqDisciplineType.Open
                                                            ? moment(event.startTime)
                                                                .add(event.duration, "minutes")
                                                                .format("hh:mm A")
                                                            : moment(event.endTime).format("hh:mm A")}
                                                    </span>
                                                </Col>
                                                <Col>
                                                    <div className="eq-event-type">
                                                        {event.accessType === 1 ? (
                                                            <EqTags
                                                                text={t(EqTrainingType[event.disciplineType])}
                                                                dicipline={true}
                                                            />
                                                        ) : (
                                                            <EqTags
                                                                text={t(EqDisciplineType[event.disciplineType])}
                                                                dicipline={true}
                                                            />
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col className="col-trancated">
                                                    <span className="location">
                                                        <EqIconLocation
                                                            eqWidth={13}
                                                            eqHeight={16}
                                                            eqFill="#b5b5b5"
                                                        />
                                                        {/* {event.eventLocation.city} */}
                                                        {event?.location?.city}
                                                    </span>
                                                </Col>
                                                <Col className="hidden-sm col-trancated">
                                                    {studentGroups?.map(
                                                        (item: IGroupDto, index: number) => (
                                                            <EqTags
                                                                className=""
                                                                key={index}
                                                                text={item.name}
                                                                color={item.color}
                                                            />
                                                        )
                                                    )}
                                                </Col>
                                                <Col>
                                                    <span className="trainer">
                                                        {event.organizers &&
                                                            event.organizers.length > 0 &&
                                                            event.organizers.map(
                                                                (item: any, index: number) => (
                                                                    <span className="trainer">
                                                                        <EqIconUser
                                                                            eqWidth="16"
                                                                            eqHeight="16"
                                                                            eqFill="#b5b5b5"
                                                                        />
                                                                        <Link
                                                                            to={`${FrontEndPoints.trainer.profileBoard}?key=${item.user.key}`}
                                                                            onClick={(e: any) => {
                                                                                e.stopPropagation();
                                                                            }}
                                                                            className="trainer-link"
                                                                        >
                                                                            {item.user.firstName || item.user.email}
                                                                        </Link>
                                                                    </span>
                                                                )
                                                            )}
                                                    </span>
                                                </Col>
                                                {isStudent ? (
                                                    <>
                                                        <Col>
                                                            <span className="attendees">
                                                                <EqIconAttendee
                                                                    eqWidth="15"
                                                                    eqHeight="15"
                                                                    eqFill="#b5b5b5"
                                                                />
                                                                {event.attendees?.length || "0"}/
                                                                {event.maxAvailableSpace}
                                                            </span>
                                                        </Col>
                                                        <Col>
                                                            <span className="price">
                                                                <EqIconPrice
                                                                    eqWidth="15"
                                                                    eqHeight="13"
                                                                    eqFill="#b5b5b5"
                                                                />{" "}
                                                                {event.price}
                                                            </span>
                                                        </Col>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <span className="title">{event.title}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="action-col">
                                    <Row justify="end">
                                        {/* // add is owner here and change condition */}
                                        {/* {isOwner ? ( */}
                                        {isTrainer || isSchool || isEventHolder ? (
                                            <Col span={24}>
                                                <div className="eq-event-actions">
                                                    <div className="eq-event-actions-trash">
                                                        <span>
                                                            <Popconfirm
                                                                placement="bottomRight"
                                                                title={"Are you sure you want to delete?"}
                                                                okText="Yes"
                                                                cancelText="No"
                                                                // onConfirm={removeEvent}
                                                                onConfirm={handleDelete}
                                                                onCancel={(e: any) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            // onConfirm={() => setCreateActivityList([])}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    // src="/trash-alt.svg"
                                                                    src="/trash-alt-red.svg"
                                                                    onClick={(e: any) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                />
                                                            </Popconfirm>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        ) : subscriptionStatus.buttonTextSubscribe == t("Join") ? (
                                            <>
                                                <Col span={24}>
                                                    <Tooltip
                                                        title={
                                                            event.startDate < todayTime ? "Date Passed" : ""
                                                        }
                                                    >
                                                        <EqButton
                                                            // {...{size:"small"}}
                                                            type="button"
                                                            onClick={subscribeTraining}
                                                            disabled={
                                                                (isStudentUnderAge && !studentCanBook) ||
                                                                    event.startDate < todayTime
                                                                    ? true
                                                                    : false
                                                            }
                                                        // disabled={
                                                        //   event.startDate > todayTime
                                                        //   ? subscriptionStatus.buttonDisabled
                                                        //     : true
                                                        // }
                                                        >
                                                            {subscriptionStatus.buttonTextSubscribe}
                                                        </EqButton>
                                                    </Tooltip>
                                                </Col>
                                            </>
                                        ) : !isPaidByUser ? (
                                            event.startTime > dateTomorrow() ? (
                                                <>
                                                    <Col span={18}>
                                                        <div className="right">
                                                            <EqButton
                                                                disabled={
                                                                    !studentCanPay || event.startDate < todayTime
                                                                        ? true
                                                                        : false
                                                                }
                                                                onClick={CreateCheckoutSession}
                                                            >
                                                                Pay Now
                                                            </EqButton>
                                                        </div>
                                                    </Col>
                                                    <Col span={4} className="eq-event-actions-trash">
                                                        <span>
                                                            <Popconfirm
                                                                placement="bottomRight"
                                                                title={
                                                                    "Are you sure you want to Cancel your Training?"
                                                                }
                                                                okText="Yes"
                                                                cancelText="No"
                                                                onConfirm={removeAttendee}
                                                                onCancel={(e: any) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            // onConfirm={() => setCreateActivityList([])}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    // src="/trash-alt.svg"
                                                                    src="/trash-alt-red.svg"
                                                                    onClick={(e: any) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                />
                                                            </Popconfirm>
                                                        </span>
                                                    </Col>
                                                </>
                                            ) : (
                                                <>
                                                    <Col span={24}>
                                                        <div className="right">
                                                            <div>
                                                                <EqButton
                                                                    disabled={
                                                                        !studentCanPay ||
                                                                            event.startDate < todayTime
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onClick={CreateCheckoutSession}
                                                                >
                                                                    Pay Now
                                                                </EqButton>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </>
                                            )
                                        ) : (
                                            <Col span={24}>
                                                <EqButton
                                                    type="button"
                                                    onClick={subscribeTraining}
                                                    disabled={subscriptionStatus.buttonDisabled}
                                                >
                                                    {subscriptionStatus.buttonTextSubscribe}
                                                </EqButton>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </>
                }
                childInnerHeight={collapseInnerHeight}
                children={
                    <div ref={collapseInnerRef}>
                        {isTrainer || isSchool || isEventHolder ? (
                            <CreateActivity
                                creatorUser={creatorUser}
                                event={event}
                                areas={areas}
                                weekDays={weekDays}
                                population={population}
                                groupPopulation={groupPopulation}
                                trainerStudents={trainerStudents}
                                studentHorsesList={studentHorsesList}
                                onCreateOrUpdateActivity={onCreateOrUpdateActivity}
                            />
                        ) : (
                            <EqActivityStudentView
                                event={event}
                                areas={areas}
                                weekDays={weekDays}
                                population={population}
                                groupPopulation={groupPopulation}
                                trainerStudents={trainerStudents}
                            // studentHorsesList={studentHorsesList}
                            ></EqActivityStudentView>
                        )}
                        {/* modal Context */}
                        {contextRefundPayment}
                    </div>
                }
            ></EqCollapsePanel>
        </div>
    );
};
export default EqActivityCollapseDashboard;
