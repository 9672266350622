import React, { useEffect, useState } from "react";
import "./EqStudentProductBoard.scss";
import { Checkbox, Col, Input, Modal, Row } from "antd";
import EqToggle, { IEqToggle } from "../../custom/eqToggle/EqToggle";
import {
  ProductOrderStatus,
  ProductType,
  StudentProductFilters,
  eqNotificationTypes,
} from "../../shared/enums";
import Api from "../../shared/AApi";
import {
  ApiEndpoints,
  GetEventMessage,
  openNotificationWithIcon,
} from "../../shared/Common";
import { EqStudentProductOrderCard } from "../eqStudentProductOrderCard/EqStudentProductOrderCard";
import EqStudentProductOrderList from "../eqStudentProductOrderList/EqStudentProductOrderList";
import { useTranslation, Trans } from "react-i18next";
import EqButton from "../../custom/eqButton/EqButton";
import { FloatButton, Popconfirm } from "antd-v5";

import EqSelect from "../../custom/eqSelect/EqSelect";
import { ISelectProps } from "../../shared/Interfaces";
import PdfProductView from "../eqStudent/PdfProductView/PdfProductView";
import EqProductCard from "../../custom/eqProductCard/EqProductCard";
import { useNavigate } from "react-router-dom";
import EqToggleBadge, { IEqToggleBadge } from "../../custom/eqToggleBadge/eqToggleBadge";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";
import RevealList from "../../custom/RevealList/RevealList";
import Reveal from "../../custom/Reveal/Reveal";

// type StudentProductOrderType = {
//   available: any[];
//   paid: any[];
//   unPaid: any[];
// };

const EqStudentProductBoard = () => {
  const { Search } = Input;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = React.useState("");

  const [productListToggle, setProductListToggle] =
    useState<StudentProductFilters>(StudentProductFilters.Available);
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [productToggleOptions, setProductToggleOptions] = useState([]) as any;
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [studentProductOrders, setStudentProductsOrders] = useState({
    all: [] as any,
    available: [] as any,
    paid: [] as any,
    unPaid: [] as any,
    filterProduct: [] as any,
  });
  const [availablePublicProducts, setAvailablePublicProducts] = React.useState(
    []
  ) as any;

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([] as any);

  ///filter
  const [selectedProducts, setSelectedProducts] = React.useState([] as any);
  const [productOptions, setProductOptions] = React.useState([] as any);

  const [productAvailableOptions, setProductAvailableOptions] = React.useState(
    [] as any
  );
  const [selectedAvailableProducts, setSelectedAvailableProducts] =
    React.useState([] as any);

  const [search, setSearch] = React.useState({
    searchVal: "",
  });

  const [organizer, setOrganizer] = React.useState([] as any);

  const productListToggleMap = {
    [StudentProductFilters.Available]: "available" as const,
    [StudentProductFilters.Paid]: "paid" as const,
    [StudentProductFilters.UnPaid]: "unPaid" as const,
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const filterString = params.get("filter");
    if (filterString) {
      const filterValue = parseInt(filterString, 10);
      setProductListToggle(filterValue as StudentProductFilters);
    }
    fetchData()
    createProductTypeOptions();
  }, []);


  const fetchData = async () => {
    GetPublicProductsByConnections();
    GetStudentProductOrders();
  };
  const GetPublicProductsByConnections = async () => {
    try {
      const response = await Api.get(
        ApiEndpoints.StudentProduct.GetPublicProductsByConnections
      );
      if (response && response.status === 200) {
        setAvailablePublicProducts(response.data);
       
        CreateAvailableProductList(response.data);
      } else {
        setMessage(GetEventMessage(response.status));
      }
    } catch (error: any) {
      setIsError(true);
      setMessage(GetEventMessage(error.response.status));
    }
  };

  const GetStudentProductOrders = () => {
    Api.get(ApiEndpoints.ProductOrder).then(
      (response) => {
        if (response && response.status === 200) {
          const data = response.data ? [...response.data] : [];

          if (data.length > 0) {
            const unPaidProducts = data.filter(
              (opt) =>
                // opt.status === ProductOrderStatus.Accepted &&
                // opt.product.type == ProductType.Private
                opt.status === ProductOrderStatus.Accepted 
            );
            const paidProducts = data.filter(
              (opt) => opt.status === ProductOrderStatus.Paid
            );

            console.log(unPaidProducts);
            setStudentProductsOrders({
              all: data,
              available: data,
              unPaid: unPaidProducts,
              paid: paidProducts,
              filterProduct: data,
            });
            // Options for toggle Filter
            var options = [
              {
                key: StudentProductFilters.Available,
                value: t(StudentProductFilters[StudentProductFilters.Available]),
                count: 0,
              },
              {
                key: StudentProductFilters.Paid,
                value: t(StudentProductFilters[StudentProductFilters.Paid]),
                count: 0,
              },
              {
                key: StudentProductFilters.UnPaid,
                value: t(StudentProductFilters[StudentProductFilters.UnPaid]),
                count: unPaidProducts.length,
              },

            ];
            setProductToggleOptions(options)
          }
          createProductList(data);
          console.log("list", createProductList(data));
        } else {
          setIsError(true);
        }
      },
      (error) => {
        setIsError(true);
      }
    );
  };

  const productListToggleProps: IEqToggleBadge = {
    items: (productToggleOptions),
    isFlex: true,
    defaultSelectedValue: productListToggle,
    classNames: "eq-toggle-orange-border ",
    changeFunc: (value: string) => {
      let type: StudentProductFilters = +value as StudentProductFilters;
      setProductListToggle(type);
      setSelectedCheckboxes([]);
    },
  };

  const createProductTypeOptions = () => {
    var types = [] as any;
    for (var x in StudentProductFilters) {
      if (isNaN(Number(x)) == false) {
        types.push({ key: Number(x), value: StudentProductFilters[x] });
      }
    }
    setProductTypeOptions(types);
  };



  const checkAll = () => {
    const isChecked = !isCheckAll;

    setStudentProductsOrders((prevOrders) => {
      const updatedList = prevOrders[
        productListToggleMap[productListToggle]
      ].map((order: any) => ({
        ...order,
        selected: isChecked,
      }));

      setIsCheckAll(isChecked);
      const updatedSelectedCheckboxes = updatedList.filter(
        (x: any) => x.selected
      );
      setSelectedCheckboxes(updatedSelectedCheckboxes);

      return {
        ...prevOrders,
        [productListToggleMap[productListToggle]]: updatedList,
      };
    });
  };

  const handleOnCheck = (order: any) => {
    setStudentProductsOrders((prevOrders) => {
      const list = [...prevOrders[productListToggleMap[productListToggle]]];
      const updatedList = list.map((item) =>
        item.id === order.id ? { ...item, selected: !item.selected } : item
      );

      const updatedSelectedCheckboxes = updatedList.filter((x) => x.selected);
      setSelectedCheckboxes(updatedSelectedCheckboxes);

      return {
        ...prevOrders,
        [productListToggleMap[productListToggle]]: updatedList,
      };
    });
  };

  const checkIfSameOwner = (selectedProducts: any) => {
    // check if selected products user id is same
    // Assuming selectedProducts is an array of objects with a 'product' property
    const firstOwnerId = selectedProducts[0]?.product?.userId;

    // Check if all selected products have the same owner
    const isSameOwner = selectedProducts.every(
      (product: any) => product.product.userId === firstOwnerId
    );

    return isSameOwner;
  };

  const handleMultiplePayments = (data: any) => {
    const selectedProductIds = selectedCheckboxes.map(
      (product: any) => product.id
    );

    Api.post(
      ApiEndpoints.ProductPayment.GetProductOrderCheckoutSessionForMultiple,
      selectedProductIds
    )
      .then((response) => {
        console.log(response);
        window.location.href = response.data.sessionUrl;
      })
      .catch((error) => {
        openNotificationWithIcon(
          "Error!",
          "Unable to Create checkout Session",
          eqNotificationTypes.error
        );
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const ReceiptModal = () => (
    <Modal
      title="Receipt"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      width={1050}
    >
      <PdfProductView productOrder={selectedCheckboxes} />
    </Modal>
  );

  const filterProduct = (productList: any[]) => {
    let filteredList = productList;

    if (organizer && organizer.length > 0) {
      const searchName = organizer.toLowerCase();
      filteredList = filteredList.filter((item) => {
        let firstName = item?.user?.firstName;
        let lastName = item?.user?.lastName;
        let username = item?.user?.email;

        return (
          (firstName &&
            (firstName + " " + lastName).toLowerCase().includes(searchName)) ||
          (username && username.toLowerCase().includes(searchName))
        );
      });
    }

    if (selectedAvailableProducts && selectedAvailableProducts.length > 0) {
      filteredList = filteredList.filter((item) => {
        return selectedAvailableProducts.includes(item?.title + "");
      });
    }
    console.log("filteredList:", filteredList);
    return filteredList;
  };

  //filter

  const filterProductOrderList = (productOrderList: any[]) => {
    let filteredList = productOrderList;

    if (organizer && organizer.length > 0) {
      const searchName = organizer.toLowerCase();
      filteredList = filteredList.filter((item) => {
        let firstName = item?.product?.user?.firstName;
        let lastName = item?.product?.user?.lastName;
        let username = item?.product?.user?.email;

        return (
          (firstName &&
            (firstName + " " + lastName).toLowerCase().includes(searchName)) ||
          (username && username.toLowerCase().includes(searchName))
        );
      });
    }

    if (selectedProducts && selectedProducts.length > 0) {
      filteredList = filteredList.filter((item) => {
        return selectedProducts.includes(item?.product?.title + "");
      });
    }
    console.log("filteredList:", filteredList);
    return filteredList;
  };

  const searchNameClick = (searchStr: string) => {
    setSearch({ ...search, searchVal: searchStr });
    setOrganizer(searchStr);
  };

  const productProps: ISelectProps = {
    classNames: `eq-multiSelect eq-searchSelect`,
    placeholder: t("product"),
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedProducts ? selectedProducts : [],
    options: productOptions,
    changeFunc: (value: any) => {
      console.log(value);
      setSelectedProducts(value);
    },
  };

  const availableProductProps: ISelectProps = {
    classNames: `eq-multiSelect eq-searchSelect`,
    placeholder: t("product"),
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedAvailableProducts
      ? selectedAvailableProducts
      : [],
    options: productAvailableOptions,
    changeFunc: (value: any) => {
      console.log(value);

      setSelectedAvailableProducts(value);
    },
  };

  const createProductList = (items: any[]) => {
    const productTitlesMap = new Map();

    items.forEach((item) => {
      const productTitle = item?.product.title;

      if (productTitle) {
        productTitlesMap.set(productTitle, {
          key: productTitle,
          value: productTitle,
          label: productTitle,
        });
      }
    });

    const uniqueProductTitles = Array.from(productTitlesMap.values());

    setProductOptions(uniqueProductTitles);
    console.log("titles", uniqueProductTitles);
  };

  const CreateAvailableProductList = (items: any[]) => {

  // create list of titles for select to filter
    const productTitlesMap = new Map();

    items.forEach((item) => {
      const productTitle = item?.title;

      if (productTitle) {
        productTitlesMap.set(productTitle, {
          key: productTitle,
          value: productTitle,
          label: productTitle,
        });
      }
    });
    const uniqueProductTitles = Array.from(productTitlesMap.values());
    setProductAvailableOptions(uniqueProductTitles);
    console.log("titles", uniqueProductTitles);
  };

  return (
    <AnimationDiv>

      <div className="eq-all-products-board">
        <div className="eq-container-fluid">
          <Row className="eq-event-heading-row" align="middle" justify="start">
            <Col className="eq-event-heading-col" flex="auto">
              <h3 className="eq-event-heading">{t("productsAndCourses")}</h3>
            </Col>
            <Col>
              <Row>
                <EqToggleBadge {...productListToggleProps} />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="filters">
              <Row gutter={[16, 16]} className="filter-type">
                <Col className="filter-col" xs={24} sm={24} md={12} lg={11}>
                  <Row className="filter-selector">
                    <Col xs={6} sm={5} md={6} lg={5}>
                      <label className="picker-label">{t("from")}</label>
                    </Col>
                    <Col className="search-input" flex="auto">
                      <Search
                        placeholder={t("searchNameMail")}
                        onChange={(e: any) => searchNameClick(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="filter-col" xs={24} sm={24} md={12} lg={11}>
                  <Row className="filter-selector">
                    <Col xs={6} sm={5} md={6} lg={5}>
                      <label className="picker-label">{t("title")}</label>
                    </Col>
                    <Col className="search-input" flex="auto">
                      {productListToggle === StudentProductFilters.Available ? (
                        <EqSelect {...availableProductProps}></EqSelect>
                      ) : (
                        <EqSelect {...productProps}></EqSelect>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col className="search-col" xs={24} sm={24} md={12} lg={11}>
                  <div className="search">
                    <EqButton
                      classNames="eq-input-info"
                      type="primary"
                      htmlType="button"
                    >
                      {t("search")}
                    </EqButton>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {productListToggle === StudentProductFilters.Available ? (

            <Col span={24}>
              <RevealList>
              <Row gutter={[16, 16]} className="eq-db-joinProducts">
                {filterProduct(availablePublicProducts).map(
                  (item: any, index: number) => (
                    <Row key={index}>
                      <EqProductCard product={item} />
                    </Row>
                  )
                )}
                {/* {filterProduct(studentProductOrders.available).map((item: any, index: number) => (
                <Row key={index}>
                  <EqStudentProductOrderCard productOrder={item} />
                </Row>
              ))} */}
              </Row>
              </RevealList>
            </Col>
          ) : productListToggle === StudentProductFilters.Paid ? (
            //Paid
            <Row>
              <Col span={24}>
                <Row justify="end" className="action-all-btn hidden-sm">
                  {selectedCheckboxes.length > 1 && (
                    <>
                      <FloatButton
                        description={t("Receipt")}
                        shape="square"
                        // style={{ right: 50, bottom: "30%" }}
                        onClick={() => setIsModalVisible(true)}
                      />
                      {/* <EqButton onClick={() => setIsModalVisible(true)}>
                      {t("Receipt")}
                    </EqButton> */}
                      {ReceiptModal()}
                    </>
                  )}
                </Row>
              </Col>

              <Col span={24}>
                <Row className="eq-fl-row hidden-sm">
                  <Col className="eq-fl-th " span={2}>
                    <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                  </Col>
                  <Col className="eq-fl-th" span={5}>
                    {t("name")}
                  </Col>
                  <Col className="eq-fl-th" span={5}>
                    {t("from")}
                  </Col>
                  <Col className="eq-fl-th" span={4}>
                    {t("status")}
                  </Col>
                  <Col className="eq-fl-th" span={4}>
                    {t("amount")}
                  </Col>
                  <Col className="eq-fl-th" span={4}>
                    {t("actions")}
                  </Col>
                </Row>
                <Row justify="space-between" className="eq-fl-row hidden-md">
                  <Col className="eq-fl-th">
                    <Checkbox onChange={checkAll} checked={isCheckAll}>
                      {" "}
                      Check all
                    </Checkbox>
                  </Col>
                  <Col>
                    <Row justify="end" className="action-delete-all">
                      {selectedCheckboxes.length > 1 && (
                        <>
                          <EqButton onClick={() => setIsModalVisible(true)}>
                            {t("Receipt")}
                          </EqButton>
                          {ReceiptModal()}
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>
                <RevealList>
                  <Row className="">
                    {filterProductOrderList(studentProductOrders.paid).map(
                      (item: any, index: number) => (
                        <EqStudentProductOrderList
                          key={index}
                          productOrder={item}
                          onCheck={() => handleOnCheck(item)}
                        />
                      )
                    )}
                  </Row>
                </RevealList>
              </Col>
            </Row>
          ) : (
            //Unpaid
            <Row>
              <Col span={24}>
                <Row justify="end" className="action-all-btn hidden-sm">
                  {selectedCheckboxes.length > 1 &&
                    checkIfSameOwner(selectedCheckboxes) && (
                      <Popconfirm
                        title="Payment"
                        description="Pay for All the selected Products"
                        onConfirm={handleMultiplePayments}
                        // onCancel={Oncancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <FloatButton description={t("payNow")} shape="square" />
                        {/* <EqButton></EqButton> */}
                      </Popconfirm>
                    )}
                </Row>
              </Col>

              <Col span={24}>
                <Row className="eq-fl-row hidden-sm">
                  <Col className="eq-fl-th " span={2}>
                    <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                  </Col>
                  <Col className="eq-fl-th" span={5}>
                    {t("name")}
                  </Col>
                  <Col className="eq-fl-th" span={5}>
                    {t("from")}
                  </Col>
                  <Col className="eq-fl-th" span={4}>
                    {t("status")}
                  </Col>
                  <Col className="eq-fl-th" span={4}>
                    {t("amount")}
                  </Col>
                  <Col className="eq-fl-th" span={4}>
                    {t("actions")}
                  </Col>
                </Row>
                <Row justify="space-between" className="eq-fl-row hidden-md">
                  <Col className="eq-fl-th">
                    <Checkbox onChange={checkAll} checked={isCheckAll}>
                      {" "}
                      Check all
                    </Checkbox>
                  </Col>
                  <Col>
                    <Row justify="end" className="action-delete-all">
                      {selectedCheckboxes.length > 1 &&
                        checkIfSameOwner(selectedCheckboxes) && (
                          <Popconfirm
                            title="Payment"
                            description="Pay for All the selected Products"
                            onConfirm={handleMultiplePayments}
                            // onCancel={Oncancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <EqButton>{t("payNow")}</EqButton>
                          </Popconfirm>
                        )}
                    </Row>
                  </Col>
                </Row>
                  <Reveal>
                <Row className="">
                    <>
                      {filterProductOrderList(studentProductOrders.unPaid).map(
                        (item: any, index: number) => (
                          <EqStudentProductOrderList
                            key={index}
                            productOrder={item}
                            onCheck={() => handleOnCheck(item)}
                          />
                        )
                      )}
                    </>
                </Row>
                </Reveal>
              </Col>
            </Row>
          )}
        </div>
      </div>

    </AnimationDiv>
  );
};

export default EqStudentProductBoard;
