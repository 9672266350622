import React, { useEffect, useState } from 'react';
import './EqProfessionalsList.scss';
import EqProfessionals, { IEqProfessionals } from '../eqProfessionals/EqProfessionals';
import { Row, Col } from 'antd';
import EqButton from '../../custom/eqButton/EqButton';
import { useTranslation, Trans } from 'react-i18next';

export interface IEqProfessionalsList {
    professionalsList: Array<IEqProfessionals>;
}
const EqProfessionalsList = ({ professionalsList }: IEqProfessionalsList) => {
    const { t, i18n } = useTranslation();

    // React.useEffect(() => {
    //   
    // }, [professionalsList]);
    return (
        <div className="prf-list">
            {professionalsList.length > 0 &&
                <>
                    {
                        professionalsList.map((item: IEqProfessionals, index: number) =>
                            <EqProfessionals key={index} {...item} ></EqProfessionals>
                        )
                    }
                </>
            }
        </div>
    );
}
export default EqProfessionalsList;