import React, { useState } from "react";
import "./EqStudentProductOrderList.scss";
import { Button, Card, Checkbox, Col, Modal, Row } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AsyncImage from "../../custom/asyncImage/AsyncImage";
import Meta from "antd/lib/card/Meta";
import { EqCurrency, ProductOrderStatus, eqNotificationTypes } from "../../shared/enums";
import EqButton from "../../custom/eqButton/EqButton";
import { loadState } from "../../shared/LocalStorage";
import { useTranslation, Trans } from "react-i18next";
import EqCollapse from "../../custom/eqCollapse/EqCollapse";
import {
    Document,
    Page,
    StyleSheet,
    Text,
    View,
    Image,
    PDFViewer,
} from "@react-pdf/renderer";
import Api from "../../shared/AApi";
import { ApiEndpoints, openNotificationWithIcon } from "../../shared/Common";
import PdfProductView from "../eqStudent/PdfProductView/PdfProductView";

export interface IEqStudentProductOrderList {
    productOrder: any;
    selected?: boolean;
    onCheck?: any;

}

const EqStudentProductOrderList = ({
    productOrder,
    selected = false,
    onCheck

}: // removeProduct,
    // trainerStudents,
    IEqStudentProductOrderList) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t, i18n } = useTranslation();

    const [currentPage, setCurrentPage] = useState(1); // Add state for current page
    const itemsPerPage = 5;


    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const CreateCheckoutSession = (orderId: any) => {
       
        Api.get(
            ApiEndpoints.ProductPayment.GetProductOrderCheckoutSession + `/${orderId}`
        )
            .then((response) => {
                window.location.href = response.data.sessionUrl;
            })
            .catch((error) => {
                openNotificationWithIcon(
                    "Error!",
                    "Unable to Create checkout Session",
                    eqNotificationTypes.error
                );
            });
    };

    const ReceiptModal = () => (
        <Modal
        title="Receipt"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null} // Hide the footer if you don't need it
        width={1050}
        >
            <PdfProductView productOrder={productOrder} />
        </Modal>
    );

    React.useEffect(() => {
        ;
        console.log(productOrder);
    }, [productOrder]);
    return (
        <div className="student-product-List">
            
            <Row className="eq-f-row hidden-sm">
                <Col className="eq-f-td" span={2}>
                    <Checkbox
                        onChange={() => {
                            onCheck(productOrder);
                        }}
                        checked={productOrder.selected}

                    ></Checkbox>
                </Col>
                <Col className="eq-f-td" span={5}>
                    {productOrder.product.title}
                </Col>
                <Col className="eq-fl-th" span={5}>
                    {`${productOrder.product.user.firstName}  ${productOrder.product.user.lastName}`}
                </Col>
                <Col className="eq-fl-th product-status" span={4}>
                    {productOrder.status === 1 && <span className="unpaid-product">UnPaid</span>}
                    {productOrder.status === 3 && <span className="paid-product">Paid</span>}
                </Col>
                <Col className="eq-fl-th" span={4}>
                    {productOrder.customPrice?productOrder.customPrice:productOrder.product.price} 
                    {/* {EqCurrency[productOrder.product.currency]} */}
                </Col>
                <Col className="eq-fl-th product-action" span={4}>
                    {productOrder.status === 1 &&
                        <div className="action"
                            onClick={() =>CreateCheckoutSession(productOrder.id)}>
                            {t("payNow")}
                        </div>}
                    {productOrder.status === 3 &&
                        <div className="action"
                            onClick={() => setIsModalVisible(true)}>
                            Receipt
                        </div>
                    }
                    {ReceiptModal()}
                </Col>
            </Row>

            <div className="hidden-md">

                <EqCollapse header={
                    <Row justify="start" align="middle">
                        <Col className="" span={2}>
                            <Checkbox
                                onChange={() => {
                                    onCheck(productOrder);
                                }}
                                checked={productOrder.selected}

                            ></Checkbox>
                        </Col>
                        <Col className="" flex="auto">
                            {productOrder?.product.title}
                        </Col>
                    </Row>


                } >
                    <>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("title")}
                            </Col>
                            <Col className="" span={24}>
                                {productOrder.product.title}
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("from")}
                            </Col>
                            <Col className="" span={24}>
                            {`${productOrder.product.user.firstName}  ${productOrder.product.user.lastName}`}
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("status")}
                            </Col>
                            <Col className="product-status" span={24}>
                                {productOrder.status === 1 && <span className="unpaid-product">UnPaid</span>}
                                {productOrder.status === 3 && <span className="paid-product">Paid</span>}
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("amount")}
                            </Col>
                            <Col className="" span={24}>
                                {productOrder.customPrice?productOrder.customPrice:productOrder.product.price} {EqCurrency[productOrder.product.currency]}
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("action")}
                            </Col>
                            <Col className="product-action" span={24}>
                                {productOrder.status === 1 &&
                                    <div className="action"
                                        onClick={() =>CreateCheckoutSession(productOrder.id)}>
                                        {t("payNow")}
                                    </div>}

                                {productOrder.status === 3 && <span className="action">Receipt</span>}
                            </Col>
                        </Row>
                    </>
                </EqCollapse>
            </div>

        </div>
    )
}

export default EqStudentProductOrderList