import React, { Fragment } from "react";
import "./EqRidingSchools.scss";
import EqButton from "../../custom/eqButton/EqButton";
import EqSearch, { IEqSearch } from "../../custom/eqSearch/EqSearch";
import { Row, Col, Input, Skeleton, Spin } from "antd";
import { useTranslation, Trans } from "react-i18next";
import {
  ApiEndpoints,
  FrontEndPoints,
  getEmailFromToken,
  GetProfessionalsMessage,
} from "../../shared/Common";
import Api from "../../shared/Api";
import { EqLocationType, SchoolFilters } from "../../shared/enums";
import history from "../../shared/history";
import EqToggle, { IEqToggle } from "../../custom/eqToggle/EqToggle";
import { debug } from "console";
import { useNavigate } from "react-router-dom";
import { IEqProfessionals } from "../../eqTrainer/eqProfessionals/EqProfessionals";
import EqProfessionalsList from "../../eqTrainer/eqProfessionalsList/EqProfessionalsList";
import EqProfessionalsGrid from "../../eqTrainer/eqProfessionalsGrid/EqProfessionalsGrid";
import { motion } from 'framer-motion';
import TransitionLoad from "../../custom/TransitionLoad/TransitionLoad";

const EqRidingSchools = () => {

  // const currentUserKey = getEmailFromToken();
  const navigate = useNavigate();
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [search, setSearch] = React.useState("") as any;
  const [listView, setListView] = React.useState(true);
  const [isSchoolsLoading, setIsSchoolsLoading] = React.useState(false);
  const [schoolsList, setSchoolsList] = React.useState({
    allSchools: [],
    mySchools: [],
    selectedSchools: [],
    filteredSchools: [],
  }) as any;
  const [isCollapseAll, setIsCollapseAll] = React.useState(false);
  const [toggleFilters, setToggleFilters] = React.useState([] as any);

  const { t, i18n } = useTranslation();
  const { Search } = Input;
  const sendInvite = () => {
    navigate(FrontEndPoints.trainer.inviteStudent);
  };

  const filterData = (searchFilter: string | undefined) => {

    if (searchFilter != "") {
      var filterResult = schoolsList.allSchools;
      var areafilterResult = [] as any;
      var namefilterResult = [] as any;
      var homeAddressResult = [] as any;
      if (searchFilter != null && searchFilter.length > 0) {
        areafilterResult = filterResult.filter((item: any) => {
          let res = item?.userProfile?.serviceAreaList?.some((area: any) => {
            return area?.location?.city
              ?.toLowerCase()
              .includes(searchFilter.toLowerCase());
          });
          return res;
        });
      }
      // console.log(areafilterResult);
      if (searchFilter != null && searchFilter.length > 0) {
        namefilterResult = filterResult.filter((item: any) => {
          return item?.userProfile?.name.includes(searchFilter);
        });
      }

      if (searchFilter != null && searchFilter.length > 0) {
        namefilterResult = filterResult.filter((item: any) => {
          return item?.userProfile?.homeAddress?.city?.toLowerCase()
            .includes(searchFilter.toLowerCase());;
        });
      }


      filterResult = [...areafilterResult, ...namefilterResult, ...homeAddressResult]
      // filterResult = areafilterResult.concat(namefilterResult);
      console.log(filterResult);
      var uniqueFilterResult = [] as any;
      if (filterResult.length > 1) {
        uniqueFilterResult = filterResult.filter((item: any, index: any) => {
          return filterResult.indexOf(item) == index;
        });
      } else {
        uniqueFilterResult = [...filterResult];
      }
      //console.log(uniqueFilterResult);

      setSchoolsList({
        ...schoolsList,
        filteredSchools: uniqueFilterResult,
      });
    } else {
      setSchoolsList({
        ...schoolsList,
        filteredSchools: schoolsList.allSchools,
      });
    }
  };

  const eqSearchProps: IEqSearch = {
    groupClassNames: "round-search",
    inputClassNames: "round-search-input",
    style: {},
    buttonClick: (selectedVal, searchVal) => {
      setSearch(searchVal);
    },
    searchValueFun: (searchVal) => {
      setSearch(searchVal);
    },
  };
  const searchClicked = () => {
    filterData(search);
  };
  const GetAllSchools = () => {
    setIsSchoolsLoading(true)
    // Api.get(ApiEndpoints.getTrainers).then(
    Api.get(ApiEndpoints.GetAllSchools).then(
      (response) => {

        if (response && response.status == 200) {
          var dto = [] as any;

          response.data?.map((item: any) => {
            var itmLoc = item?.locations
              ?.filter((opt: any) => opt?.location?.type == EqLocationType.Home)
              ?.shift()?.location;
            dto.push({
              userProfile: {
                key: item.key,
                email: item.email,
                about: item.aboutMe,
                name: item?.firstName
                  ? item.firstName
                  : "" + " " + item.lastName
                    ? item.lastName
                    : "",
                homeAddress: itmLoc,
                disciplineList: item.disciplines,
                img: <img src="images/partners/partner.png" alt="trainer" />,
                display: false,
                userImage: item.userImage,
                trainingLevels: item.trainingLevels,
                trainerStudents: item.trainerStudents,
                isMySchool: item.isMySchool,
                serviceAreaList: item.locations?.filter(
                  (opt: any) => opt.location?.type == EqLocationType.Work
                ),
              },
            });
          });
          console.log(dto);


          var mySchools = [...dto].filter(
            (item: any) => {
              return item.userProfile.isMySchool == true;
            }

          );

          console.log(mySchools);
          setSchoolsList({
            allSchools: dto,
            selectedSchools: dto,
            mySchools: mySchools,
            filteredSchools: dto,
          });

          setIsError(false);
          setMessage(GetProfessionalsMessage(response.status));
          setIsSchoolsLoading(false)

        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetProfessionalsMessage(error.response.status));
        setIsSchoolsLoading(false)
      }
    );
  };

  const toggleView = () => {
    setListView(!listView);
  };
  const handleIsCollapseAll = (props: boolean) => {
    setIsCollapseAll(props);
  };
  const collapseAll = () => {
    handleIsCollapseAll(!isCollapseAll);
    schoolsList.filteredSchools.map((f: IEqProfessionals) => {
      if (f.userProfile != undefined) {
        f.userProfile.display = !isCollapseAll;
      }
    });
  };
  const createToggleFilters = () => {
    var filters = [];
    for (var x in SchoolFilters) {
      if (isNaN(Number(x)) == false) {
        filters.push({ key: Number(x), value: SchoolFilters[x] });
      }
    }
    setToggleFilters(filters);
  };

  const accessTogglerProps: IEqToggle = {
    items: toggleFilters,
    defaultSelectedValue: toggleFilters[0],
    classNames: "eq-toggle-orange-border eq-toggle-create-event",
    changeFunc: (value: string) => {
      console.log(schoolsList.selectedTrainers);
      let filter: number = +value;
      //   setSelectedToggle(filter);

      if (filter === SchoolFilters.AllSchools) {
        setSchoolsList({
          ...schoolsList,
          filteredSchools: schoolsList.allSchools,
        });
        // setSelectedTrainingsList(filteredTrainingsList.available);
      } else if (filter === SchoolFilters.MySchools) {
        setSchoolsList({
          ...schoolsList,
          filteredSchools: schoolsList.mySchools,
        });
        // setSelectedTrainingsList(filteredTrainingsList.joined);
      }
    },
  };

  React.useEffect(() => {

    createToggleFilters();
    GetAllSchools();
    window.scrollTo(0, 0);
  }, []);

  return (
    <TransitionLoad>

      <div className="eq-rs-board">
        <div className="eq-container-fluid">
          <Row className="eq-rs-heading-row" align="middle" justify="start">
            <Col className="eq-rs-heading-col" flex="auto">
              <h2 className="eq-rs-heading">{t("ridingschools")}</h2>
            </Col>
            <Col>
              <Row>
                <EqToggle {...accessTogglerProps} />
              </Row>
            </Col>
          </Row>
          <Row className="eq-rs-heading-line"></Row>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={{ span: 8, push: 16 }}
              className="eq-pb-right"
            >
              <Row>
                <div className="eq-pb-search-heading">{t("search")}</div>
              </Row>
              <Row>
                <Col flex="auto">
                  <div className="eq-pb-search">
                    <EqSearch {...eqSearchProps}></EqSearch>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col flex="auto">
                  <div className="eq-pb-btn">
                    <EqButton
                      type="button"
                      block={true}
                      onClick={searchClicked}
                      classNames="btn-height"
                    >
                      <span className="eq-btn-icon">
                        <img alt="" src="/search.svg" />
                      </span>
                      <span className="eq-sb-clear-btn">{t("search")}</span>
                    </EqButton>
                  </div>
                </Col>
              </Row>

            </Col>
            <Col
              xs={24}
              sm={24}
              md={{ span: 16, pull: 8 }}
              className="eq-pb-left col-xs-push-16"
            >
              <div className="eq-pl-row">
                <Row>
                  <Col flex="auto">
                    <Row className="eq-pl-heading" justify="start">
                      {t("ridingschools")}
                    </Row>
                  </Col>
                  <Col flex="auto" className="hidden-sm">
                    <Row className="eq-pl-icons" justify="end">
                      {listView ? (
                        <>
                          <span className="eq-pl-icon">
                            <img
                              alt=""
                              src="/orange-th-list.svg"
                              onClick={toggleView}
                            />
                          </span>
                          <span className="eq-pl-icon">
                            <img
                              alt=""
                              src="/th-large.svg"
                              onClick={toggleView}
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="eq-pl-icon">
                            <img alt="" src="/th-list.svg" onClick={toggleView} />
                          </span>
                          <span className="eq-pl-icon">
                            <img
                              alt=""
                              src="/orange-th-large.svg"
                              onClick={toggleView}
                            />
                          </span>
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col className="hidden-md">
                    <EqButton
                      type="button"
                      classNames="btn-background-orange"
                      onClick={collapseAll}
                    >
                      {isCollapseAll ? "Collapse All" : "Expand All"}
                    </EqButton>
                  </Col>
                </Row>
              </div>
              {/* <Skeleton  active={isSchoolsLoading} /> */}
              <Row justify="center">
                <Spin size="large" spinning={isSchoolsLoading} />
              </Row>

              {listView ? (
                <EqProfessionalsList
                  {...{ professionalsList: schoolsList.filteredSchools }}
                ></EqProfessionalsList>
              ) : (
                <Row>
                  <EqProfessionalsGrid
                    {...{ professionalsList: schoolsList.filteredSchools }}
                  ></EqProfessionalsGrid>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </TransitionLoad>
  );
};
export default EqRidingSchools;
