import * as React from 'react';
import EqStudentEventCard from '../eqStudentEventCard/EqStudentEventCard'
import { Row, Col } from 'antd';
import { addDays, addDaysUTC, dateFormat } from '../../shared/Common';
import './EqStudentEventGrid.scss';
import EqButton from '../../custom/eqButton/EqButton';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';
import { IEqEventList } from '../../shared/Interfaces';
import { IEqEventData } from '../../shared/interfaces/IEqEventDto';
import  EqCard, { IEqCard } from '../../custom/eqCard/EqCard';


export interface IEqEventSplit {
    today: Array<IEqEventData>;
    tomorrow: Array<IEqEventData>;
    later: Array<IEqEventData>;
}


export interface IEqStudentEventGrid {
    studentEventClasses: Array<any>;
    removeEventFunc?: (data: IEqEventData) => void;
  
}
const EqStudentEventGrid = ({ studentEventClasses, removeEventFunc }: IEqStudentEventGrid) => {
    const { t, i18n } = useTranslation();

    const [eventClassData, setEventClassData] = React.useState({} as IEqEventSplit);
    const options: Intl.DateTimeFormatOptions = {
        weekday: "long", // Correctly typed as "long" | "short" | "narrow" | undefined
        day: "2-digit",  // Correctly typed as "numeric" | "2-digit" | undefined
        month: "short",  // Correctly typed as "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined
        year: "numeric", // Correctly typed as "numeric" | "2-digit" | undefined
    };
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayTime = today.getTime();
    const tomorrow = new Date();
    tomorrow.setHours(24, 0, 0, 0);
    const tomorrowTime = tomorrow.getTime();

    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setHours(48, 0, 0, 0);
    const dayAfterTomorrowTime = dayAfterTomorrow.getTime();

    React.useEffect(() => {

        var formedData = {} as IEqEventSplit;
        //formedData.today = eventList;
        formedData.today = studentEventClasses?.filter((opt: any) => (opt.event.startDate < tomorrowTime && opt.event.startDate >= todayTime));
        formedData.tomorrow = studentEventClasses?.filter((opt: any) => (opt.event.startDate < dayAfterTomorrowTime && opt.event.startDate >= tomorrowTime));
        formedData.later = studentEventClasses?.filter((opt: any) => (opt.event.startDate >= dayAfterTomorrowTime));
        setEventClassData(formedData);
        console.log(studentEventClasses);
        
    }, [studentEventClasses])

    return (
        <>
            <div className="eq-el-container">
                <Row>
                    <Col flex="auto">
                        <h4 className="eq-el-heading">{t("today")}</h4>
                    </Col>
                    <Col className="eq-el-dateHeading">
                        <h5 className="eq-el-date">{new Date().toLocaleDateString(t("dateLang"),options as any)}</h5>

                        <div className="eq-el-date-icon hidden-sm">
                            <span><img alt="" src="/DateEdit.svg" /></span>
                        </div>
                    </Col>
                </Row> 
                <Row>
                    {eventClassData?.today?.length > 0 &&
                    <>
                        {eventClassData.today.map((item: IEqEventData, index: number) =>
                        <>
                            <Col md={8} xs={24} sm={24}>
                                    <EqStudentEventCard {...{ eventClass: { ...item }, removeEventFunc: removeEventFunc }}></EqStudentEventCard>
                            </Col>
                          </>
                            )}
                    </>
                    }
                    </Row>
            </div>
            <div className="eq-el-container">
                <Row>
                    <Col flex="auto">
                        <h4 className="eq-el-heading">{t("tomorrow")}</h4>
                    </Col>
                    <Col className="eq-el-dateHeading">
                        <h5 className="eq-el-date">{addDays(new Date(), 1).toLocaleDateString(t("dateLang"), options)}</h5>

                        <div className="eq-el-date-icon hidden-sm">
                            <span><img alt="" src="/DateEdit.svg" /></span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {eventClassData?.tomorrow?.length > 0 &&
                    <>
                        {eventClassData.tomorrow.map((item: IEqEventData, index: number) =>
                        <Col md={8} xs={24} sm={24}>
                                    <EqStudentEventCard {...{ eventClass: { ...item }, removeEventFunc: removeEventFunc }}></EqStudentEventCard>
                        </Col>
                        )}
                    </>
                    }
                    </Row>
            </div>
            <div className="eq-el-container">
                <h4 className="eq-el-heading">{t("later")}</h4>
                <Row>
                    {eventClassData?.later?.length > 0 &&
                    <>
                        {eventClassData.later.map((item: IEqEventData, index: number) =>
                        <Col md={8} xs={24} sm={24}>
                                    <EqStudentEventCard {...{ eventClass: { ...item }, removeEventFunc: removeEventFunc }}></EqStudentEventCard>
                        </Col>
                        )}
                    </>
                    }
                    </Row>
            </div>
        </>
    );
}
export default EqStudentEventGrid;