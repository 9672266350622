import React, { useEffect, useState } from 'react';
import './EqButton.scss';
import {
    Button, ButtonProps
} from 'antd';


export interface IEqButton extends ButtonProps {
    //changeFunc?: (e: any) => void;
    classNames?: string;
    centered?: any;
    bordered?: any;
    type?: any;
}

const EqButton = ({classNames, bordered, centered, ...rest }: IEqButton) => {

    //const onChange = (e: any) => {
    //    changeFunc(e);
    //}

    return (
        <div className={`eqButton ${centered ? "centered" : "" } ${classNames}`} >
            <Button {...rest} className={`${bordered ? "bordered" : ""} ${classNames}`}/>
        </div>
    );
}

export default EqButton;
