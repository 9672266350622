import * as React from "react";
import "./EqTrainerProductsBoard.scss";
import { Row, Col, Card, Popconfirm, Input } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsTrainer,
  ValidateIsUser,
  FrontEndPoints,
} from "../../shared/Common";
import Api from "../../shared/Api";
import "./EqTrainerProductsBoard.scss";
import { useTranslation, Trans } from "react-i18next";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import {
  EqDisciplineType,
  EqEventRepeatType,
  ProductType,
  TrainingFilters,
} from "../../shared/enums";
import CreateActivity from "../createActivity/CreateActivity";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import EqActivityTrainingList from "../eqActivityTrainingList/EqActivityTrainingList";
import EqActivityCollapse from "../eqActivityCollapse/EqActivityCollapse";
import EqToggle, { IEqToggle } from "../../custom/eqToggle/EqToggle";
import EqProductList from "../../custom/eqProductList/EqProductList";
import { EqProductsBoardOrderList } from "../../custom/eqProductsBoardOrderList/EqProductsBoardOrderList";
import EditorCreateProduct from "../../custom/editorCreateProduct/EditorCreateProduct";
import DatePicker from "react-multi-date-picker";
import EqSelect from "../../custom/eqSelect/EqSelect";
import Search from "antd/lib/transfer/search";
import { ISelectProps } from "../../shared/Interfaces";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";
import RevealList from "../../custom/RevealList/RevealList";

export interface IFilteredTrainings {
  name: any;
  type: any;
  date: any;
}

const EqTrainerProductsBoard = (props: any) => {
  const { Search } = Input;
  const userDetails: any = useAuthState();
  const isUserValid: boolean = ValidateIsUser(userDetails);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [trainerStudentsAndGroups, setTrainerStudentsAndGroups] = React.useState([]) as any;
  const [errorMessage, setErrorMessage] = React.useState("");
  const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
  const [addNew, setAddNew] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  const [selectedProduct, setSelectedProduct] = React.useState() as any;

  // const [showEventList, setShowEventList] = React.useState(true);
  // const [createActivityList, setCreateActivityList] = React.useState([] as Array<any>);
  const [trainingsList, setTrainingsList] = React.useState([]) as any;
  const [filteredTrainingsList, setFilteredTrainingsList] = React.useState(
    {} as IFilteredTrainings
  );
  const [selectedTrainingsList, setSelectedTrainingsList] = React.useState(
    []
  ) as any;

  const [toggleFilters, setToggleFilters] = React.useState([] as any);
  const [selectedToggle, setSelectedToggle] = React.useState(
    TrainingFilters.All
  ) as any;

  const [productsList, setProductsList] = React.useState({
    all: [],
    filteredList: [],
    private: [],
    public: [],
  }) as any;
  const [productListToggle, setProductListToggle] = React.useState(
    ProductType.Private
  );
  const [productTypeOptions, setProductTypeOptions] = React.useState([]);
  const productInitial = {
    type: ProductType.Public,
    number: "",
    price: 0,
    title: "",
    productNumber: "",
    description: "",
    vat: "",
    imageUrl: "",
    // userId:0
  };
  const [productFormData, setProductFormData] = React.useState({
    ...productInitial,
  } as any);
  //initial gets

  //filter
  const [selectedProducts, setSelectedProducts] = React.useState([] as any);
  const [productOptions, setProductOptions] = React.useState([] as any);

  const [selectedProductType, setSelectedProductType] = React.useState([] as any);
  const [productTypeOption, setProductTypeOption] = React.useState([] as any);

  const [studentsName, setStudentsName] = React.useState([] as any)
  const [search, setSearch] = React.useState({
    searchVal: "",
  });

  const getProductList = () => {
    Api.get(ApiEndpoints.UserProduct).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");

          // setProductsList(response.data);
          formatProductListData(response.data);
          createProductList(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const formatProductListData = (data: any) => {
    var privateList = data?.filter(
      (opt: any) => opt.type == ProductType.Private
    );
    var publicList = data?.filter((opt: any) => opt.type == ProductType.Public);

    var productList = {
      all: [...data],
      filteredList: [...data],
      private: [...privateList],
      public: [...publicList],
    };
    setProductsList({ ...productList });
  };

  const productListToggleProps: IEqToggle = {
    items: productTypeOptions.map((filter: any) => ({
      key: filter.key,
      value: t(filter.value), // Translate the filter label
    })),
    defaultSelectedValue: productListToggle,
    classNames: "eq-toggle-orange-border eq-toggle-create-event",
    changeFunc: (value: string) => {
      let type: number = +value;
      // setSelectedToggle(filter);
      setProductListToggle(type);
    },
  };
  const handleOnProductSend = (data: any) => {
    const list = [...productsList.all];
    var foundIndex = list.findIndex((x) => x.id == data[0].product.id);
    list[foundIndex].productOrders.push(data);

    formatProductListData(list);
  };
  const removeProduct = (data: any) => {
    Api.delete(ApiEndpoints.UserProduct + `/${data.id}`).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(data);
        } else {
          setIsError(true);
          // setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        // setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const removeItem = (data: any) => {
    if (productsList.all != undefined) {
      var updatedList =
        productsList.all != undefined
          ? productsList.all.filter((item: any) => item.id !== data.id)
          : new Array<any>();
      // setProductList(updatedList);
      formatProductListData(updatedList);
      // horsesList.map((item: any, index: number) => {
      //   item.display = index == 0 ? true : false;
      // });
    }
  };





  // const GetTrainerStudents = () => {
  //   Api.get(ApiEndpoints.GetTrainerStudents).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         setIsError(false);
  //         setMessage("");
  //         setTrainerStudents(response.data);
  //         // console.log("TrainerStudents:", response.data);
  //       } else {
  //         setIsError(true);
  //         setMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };


  React.useEffect(() => {
    createProductTypeOptions();
    GetTrainerStudentsAndGroupsAsOptions()
    getProductList();
    createProductTypeOptions();
    createProductTypeList()
    // getAreas();
    // getWeekDays();
    // GetTrainerStudents();
    // getPopulation();
    // getGroupPopulation();
    // getAllTrainingsByTrainer();
    window.scrollTo(0, 0);
  }, []);

  // Toggle Filters

  const createProductTypeOptions = () => {
    var types = [] as any;

    for (var x in ProductType) {
      if (isNaN(Number(x)) == false) {
        types.push({ key: Number(x), value: ProductType[x] });
      }
    }
    setProductTypeOptions(types);
  };

  const GetTrainerStudentsAndGroupsAsOptions = async () => {
    try {
      const response = await Api.get(ApiEndpoints.Trainer.GetTrainerStudentsAndGroupsAsOptions);
      if (response && response.status === 200) {
        setTrainerStudentsAndGroups(response.data);
      } else {
        setMessage(GetEventMessage(response.status));
      }
    } catch (error: any) {
      setIsError(true);
      setMessage(GetEventMessage(error.response.status));
    }
  };
  /////


  // const formatProductListData = (data: any) => {
  //   var privateList = data?.filter(
  //     (opt: any) => opt.type == ProductType.Private
  //   );
  //   var publicList = data?.filter((opt: any) => opt.type == ProductType.Public);

  //   var productList = {
  //     all: [...data],
  //     private: [...privateList],
  //     public: [...publicList],
  //   };
  //   setProductsList({ ...productList });
  // };


  const showAddNew = () => {
    setAddNew(true);
  };
  const handleOnCreate = (data: any) => {
    const list = [...productsList.all];
    list.push(data);
    formatProductListData(list);
  };
  const cancelFunc = (e: any) => {
    setSelectedProduct();
    setAddNew(false);
  };

  const editProduct = (data: any) => {
    setSelectedProduct(data);
    setIsEdit(true);
  };
  const handleOnEdit = (data: any) => {
    const list = [...productsList.all];
    var foundIndex = list.findIndex((x) => x.id == data.id);
    list[foundIndex] = data;

    formatProductListData(list);
    setIsEdit(false);
  };
  const cancelEdit = (e: any) => {
    setProductFormData({ ...productInitial });
    setIsEdit(false);
  };

  //filter 
  // ...

  //filter 
  // const filterProductList = (productList: any[]) => {
  //   console.log('studentsName:', studentsName);
  //   console.log('productList:', productList);

  //   let filteredList = productList;

  //   if (studentsName && studentsName.length > 0) {
  //     const searchName = studentsName.toLowerCase();
  //     filteredList = filteredList.filter((item) => {
  //       const productOrders = item?.productOrders ;

  //       return productOrders.map((productOrder: any) => {
  //         const firstName_Pro = productOrder?.student?.firstName;
  //         const lastName_Pro = productOrder?.student?.lastName;
  //         const username_Pro = productOrder?.student?.email;

  //         return (
  //           (firstName_Pro && (firstName_Pro + " " + lastName_Pro).toLowerCase().includes(searchName)) ||
  //           (username_Pro && username_Pro.toLowerCase().includes(searchName))
  //         );
  //       });
  //     });
  //   }

  //   console.log('filteredList:', filteredList);
  //   return filteredList;
  // };

  const filterProductList = (productList: any[]) => {


    let filteredList = productList;

    if (studentsName && studentsName.length > 0) {
      const searchName = studentsName.toLowerCase();
      //name
      filteredList = productList.map((item) => {
        const productOrders = item?.productOrders;

        const filteredProductOrders = productOrders.filter((productOrder: any) => {
          const firstName_Pro = productOrder?.student?.firstName;
          const lastName_Pro = productOrder?.student?.lastName;
          const username_Pro = productOrder?.student?.email;

          return (
            (firstName_Pro && (firstName_Pro + " " + lastName_Pro).toLowerCase().includes(searchName)) ||
            (username_Pro && username_Pro.toLowerCase().includes(searchName))
          );
        });

        return {
          ...item,
          productOrders: filteredProductOrders,
        };
      });
    }

    //Product 
    if (selectedProducts && selectedProducts.length > 0) {
      filteredList = filteredList.filter((item) => {

        return selectedProducts.includes(item?.title + "")

      }
      );
    }

    //Type
    // if (selectedProductType && selectedProductType.length > 0) {
    //   filteredList = filteredList.filter((item) => {

    //     return selectedProductType.includes(item?.type + "")

    //   }
    //   );
    // }
    if (selectedProductType && selectedProductType.length > 0) {
      filteredList = filteredList.filter((item) => {
        return selectedProductType.some((selectedType: any) => item?.type === selectedType);
      });
    }

    console.log('filteredList:', filteredList);
    return filteredList;
  };


  const searchNameClick = (searchStr: string) => {
    setSearch({ ...search, searchVal: searchStr });
    setStudentsName(searchStr)

    const filteredProducts = filterProductList(productsList.all);
    setProductsList({ ...productsList, filteredList: filteredProducts });
  };


  const createProductList = (items: any[]) => {
    const productTitlesMap = new Map();
    items.forEach((item) => {
      const productTitle = item?.title;
      if (productTitle) {
        productTitlesMap.set(productTitle, {
          key: productTitle,
          value: productTitle,
          label: productTitle,
        });
      }
    });
    const uniqueProductTitles = Array.from(productTitlesMap.values());

    setProductOptions(uniqueProductTitles);
    console.log("titles", uniqueProductTitles);
  };

  const productProps: ISelectProps = {

    classNames: `eq-multiSelect eq-searchSelect`,
    placeholder: "Product",
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedProducts ? selectedProducts : [],
    options: productOptions,
    changeFunc: (value: any) => {
      console.log(value);

      setSelectedProducts(value);
    },
  };
  const createProductTypeList = () => {
    var productTypes = [];
    productTypes.push({
      key: ProductType.Public,
      value: t(ProductType[ProductType.Public]),
    });
    productTypes.push({
      key: ProductType.Private,
      value: t(ProductType[ProductType.Private]),
    });

    setProductTypeOption(productTypes);
    console.log("options", productOptions);
  };

  const productTypeProps: ISelectProps = {
    classNames: `eq-multiSelect eq-searchSelect`,
    placeholder: "Product Type",
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedProductType ? selectedProductType : [],
    options: productTypeOption,
    changeFunc: (value: any) => {
      console.log(value);

      setSelectedProductType(value);
    },
  };



  return (
    <AnimationDiv>

      <div className={`eq-trainer-products-board`}>
        <div className="eq-container-fluid">
          <Row className="eq-tp-heading-row" align="middle" justify="start">
            <Col className="eq-tp-heading-col" flex="auto">
              <h3 className="eq-tp-heading">{t("allProductsAndCourses")}</h3>
            </Col>
          </Row>

          <Row
          // gutter={gutter}
          >
            <Col className="gutter-row" span={24}>
              <div className="product-type">
                <h5 className="label">{t("selectProductType")}</h5>
                <span className="dis">
                   {/* {t("publicProductCreateNote")} */}
                   {t("publicProductCreate")}
                  <br/>
                  {t("privateProductCreate")}
                </span>

                <div className="toggler">
                  <EqToggle {...productListToggleProps} />
                </div>
              </div>
            </Col>
          </Row>

          {productListToggle == ProductType.Private ? (
            <div className="eq-section">
              <Row>
                {productsList.private?.length > 0 && (
                  <RevealList>
                    <Col span={24}>
                      {/* <div className="eq-db-h2"> Private Products</div> */}
                      {/* <h3 className="section-heading">Private Products</h3> */}
                      <EqProductList
                        studentsList={trainerStudents}
                        studentsAndGroups={trainerStudentsAndGroups}
                        productList={productsList.private}
                        removeProduct={removeProduct}
                        onProductSend={handleOnProductSend}
                        editProduct={editProduct}
                      />
                    </Col>
                  </RevealList>
                )}
              </Row>
            </div>
          ) : (
            <div className="eq-section">
              <Row>
                {productsList.public?.length > 0 && (
                  <RevealList>
                    <Col span={24}>
                      {/* <div className="eq-db-h2"> Public Products</div> */}
                      {/* <h3 className="section-heading">Public Products</h3> */}

                      <EqProductList
                        studentsList={trainerStudents}
                        productList={productsList.public}
                        removeProduct={removeProduct}
                        editProduct={editProduct}
                      />
                    </Col>
                  </RevealList>
                )}
              </Row>
            </div>
          )}

          <Row
          // justify="end"
          >
            {isEdit && (
              <EqCollapsePanel
                defaultOpen={true}
                header={t("editProduct")}
                children={
                  <EditorCreateProduct
                    isEdit={isEdit}
                    product={selectedProduct}
                    onEdit={handleOnEdit}
                    onCancel={cancelEdit}
                  />
                }
              />
            )}
          </Row>
          <Row>
            {!addNew ? (
              <Row className="add-btn-icon" onClick={showAddNew}>
                <img alt="" className="add" src="/plus-black.svg" />
              </Row>
            ) : (
              <>
                <EqCollapsePanel
                  defaultOpen={true}
                  header={t("createProduct")}
                  children={
                    <EditorCreateProduct
                      onCreate={handleOnCreate}
                      onCancel={cancelFunc}
                    />
                  }
                />
              </>
            )}
          </Row>
          <div className="product-order-details">
            <EqCollapsePanel
              defaultOpen={true}
              header={<div className="">{t("productOrderDetails")}</div>}
            >
              {/* filter */}
              <Row className="filter-row">
                <Col span={24} className="filters">
                  {/* <div className="filters"> */}
                  <Row gutter={[16, 16]} className="filter-type">
                    <Col className="location-col" xs={24} sm={24} md={12} lg={11}>
                      <Row className="location-selector">
                        <Col xs={6} sm={5} md={6} lg={5}>
                          <label className="picker-label">{t("student")}</label>
                        </Col>
                        <Col className="location-input" flex="auto">
                          <Search
                            placeholder={t("searchNameMail")}
                            onChange={(e: any) => searchNameClick(e.target.value)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col className="radius-col" xs={24} sm={24} md={12} lg={11}>
                      <Row className="radius-selector">
                        <Col xs={6} sm={5} md={6} lg={5}>
                          <label className="picker-label">
                            {t("product")}
                          </label>
                        </Col>
                        <Col className="radius-input" flex="auto">
                          <EqSelect {...productProps}></EqSelect>

                        </Col>
                      </Row>
                    </Col>

                    <Col className="date-col " xs={24} sm={24} md={12} lg={11}>
                      <Row className="date-selector  start-date-selector">
                        <Col xs={6} sm={5} md={6} lg={5} >
                          <label className="picker-label">{t("type")}</label>
                        </Col>
                        <Col className="date-input" flex="auto">

                          <EqSelect {...productTypeProps}></EqSelect>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="search-col" xs={24} sm={24} md={12} lg={11}>
                      <div className="search">
                        <EqButton classNames="eq-input-info" type="primary" htmlType="button">
                          {t("search")}
                        </EqButton>
                      </div>
                    </Col>
                  </Row >

                </Col >
              </Row >
              <EqProductsBoardOrderList products={filterProductList(productsList.filteredList)} />
            </EqCollapsePanel>
          </div>
        </div>
      </div>

    </AnimationDiv >
  );
};
export default EqTrainerProductsBoard;
