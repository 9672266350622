import * as React from "react";
import './EqSubscribedProfessionalGrid.scss'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../context/Context';
import { Col, Row } from 'antd';
import EqTags from '../../custom/eqTags/EqTags';
import { EqDisciplineType, EqLocationType, eqNotificationTypes, EqProfessionType } from '../../shared/enums';
import { ApiEndpoints, FrontEndPoints, getDefaultImage, imageType, openNotificationWithIcon } from '../../shared/Common';
import EqCard from "../../custom/eqCard/EqCard";
import EqSendMessageModal from "../../eqTrainer/eqMessageBoard/eqSendMessageModal/EqSendMessageModal";
import Api from "../../shared/Api";

export interface IEqSubscribedTrainerGrid {
    trainer?: any;
}

const EqSubscribedProfessionalGrid: React.FC<IEqSubscribedTrainerGrid> = ({ trainer }) => {
    const [userProfile, setUserProfile] = React.useState<any>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userDetails: any = useAuthState();
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [selectedEmail, setSelectedEmail] = React.useState<string>('');
  
    const showMessageModal = (email: string) => {
      
      setSelectedEmail(email);
      setIsModalVisible(true);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
  
  
  
  
    const handleSendMessage = async (data: any) => {
      try {
          const response = await Api.post(ApiEndpoints.UserMessage.SendMessageToEmail, data);
  
          if (response.status === 200) {
          
              openNotificationWithIcon(
                  t("sent"),
                  t("successfullySentMessage"),
                  eqNotificationTypes.success
              );
          } else {
              console.error("Unexpected response status:", response.status);
          }
      setIsModalVisible(false);
  
      } catch (error) {
          openNotificationWithIcon(
              t("error"),
              t("errorOccurredSending"),
              eqNotificationTypes.error
          );
      setIsModalVisible(false);
  
      }
  };
  
  const visitProfile = (e: any) => {
    e.stopPropagation();

    if (userProfile && userProfile.key) {
        if (userProfile.professionType === EqProfessionType.Trainer) {
            navigate(`${FrontEndPoints.trainer.profileBoard}?key=${userProfile.key}`);
        } else {
            navigate(`${FrontEndPoints.school.profileBoard}?key=${userProfile.key}`);
        }
    }
};
const rowClicked = (event: any) => {
    if (userProfile && userProfile.key) {
        if (userProfile.professionType === EqProfessionType.Trainer) {
            navigate(`${FrontEndPoints.trainer.profileBoard}?key=${userProfile.key}`);
        } else {
            navigate(`${FrontEndPoints.school.profileBoard}?key=${userProfile.key}`);
        }
    }
};

    const FormatTrainerProfile = (item: any) => {

        var itmLoc = item?.locations
            ?.filter((opt: any) => opt?.location?.type == EqLocationType.Home)
            ?.shift()?.location;
        const userProfile = {
            key: item.key,
            email: item.email,
            about: item.aboutMe,
            name: item?.firstName
                ? item.firstName
                : "" + " " + item.lastName
                    ? item.lastName
                    : "",
            homeAddress: itmLoc,
            disciplineList: item.disciplines,
            img: <img src="images/partners/partner.png" alt="trainer" />,
            display: false,
            userImage: item.userImage,
            trainingLevels: item.trainingLevels,
            trainerStudents: item.trainerStudents,
            isMyTrainer: item.isMyTrainer,
            isMySchool: item.isMySchool,
            professionType:item.professionType,
            serviceAreaList: item.locations?.filter(
                (opt: any) => opt.location?.type == EqLocationType.Work
            ),
        };
        setUserProfile(userProfile);
    };


    React.useEffect(() => {
        if (trainer) {
            FormatTrainerProfile(trainer);
        }
    }, [trainer]);

    // ... (rest of the code remains unchanged)

    return (
        <>
            <div onClick={rowClicked} className="eq-connection-card">
                <EqCard imgSrc={userProfile?.userImage?.url || getDefaultImage(imageType.partner)}
                    title={
                        <>
                            <div className="eq-pg-name" >
                                {userProfile?.name}</div>
                        </>
                    }
                    date=""
                    description={
                        <>
                            <div className="eq-pg-row">
                                <Row className="eq-pg-address">
                                    <span className="eq-pl-address-icon"><img alt="" src="/map-marker-alt.svg" /></span>
                                    <div className="eq-pl-address">
                                        {userProfile?.homeAddress?.locationName ? userProfile?.homeAddress?.locationName :
                                            ((userProfile?.homeAddress?.street) ? (userProfile?.homeAddress?.street + ' ') : '')
                                            + ((userProfile?.homeAddress?.zipCode) ? (userProfile?.homeAddress?.zipCode + ', ') : '')
                                            + ((userProfile?.homeAddress?.city) ? (userProfile?.homeAddress?.city) : '')
                                        }
                                    </div>
                                </Row>
                                <Row className="eq-row-discipline">
                                    <Col span={24} className="eq-pl-discipline">
                                        {t("discipline")}
                                    </Col>
                                    <Col flex="auto">
                                        <Row className="tag-col">
                                            {userProfile && userProfile.disciplineList && userProfile?.disciplineList?.length > 0 && (
                                                <Col className="tag-span">
                                                    {userProfile?.disciplineList?.map((item: any, index: number) => (
                                                        <>
                                                            {index < 3 ? (
                                                                <span key={index}>
                                                                    <EqTags text={t(EqDisciplineType[item.discipline?.type])} />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    ))}
                                                    
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>

                            </div>
                        </>
                    }
                    actionsList={
                        [
                            <div className="eq-pl-msg"
                            onClick={(e:any) => { 
                                e.stopPropagation()
                                showMessageModal(userProfile.email);
                              }}
                            // onClick={messageClicked}
                            >
                                <span className="eq-pl-icon"><img alt="" src="/Envelope.svg" /></span>
                            </div>,
                            <div className="eq-pg-profile-link" onClick={visitProfile} >{t("Visitprofile")}</div>
                        ]
                    }

                />
            </div>
            <EqSendMessageModal
        visible={isModalVisible}
        recipientEmail={selectedEmail}
        onCreate={handleSendMessage}
        onCancel={handleCancel}
      />
        </>
    );
};

export default EqSubscribedProfessionalGrid;
