import * as React from "react";
import { Row, Col, Empty } from "antd";
import {
  addDays,
} from "../../shared/Common";
import "./EqActivityTrainingList.scss";
import moment from "moment";

import EqActivityCollapse from "../eqActivityCollapse/EqActivityCollapse";
import { useTranslation } from "react-i18next";
//testgit

export interface IEqEventSplit {
  today: any;
  tomorrow: any;
  later: any;
}
// export interface IEqEventSplit {
//     today: Array<IEqEventData>;
//     tomorrow: Array<IEqEventData>;
//     later: Array<IEqEventData>;
// }

export interface IEqActivityTrainingList {
  
  eventList: any;
  
  creatorUser?: any;
  areas?: any;
  weekDays?: any;
  population?: any;
  groupPopulation?: any;
  trainerStudents?: any;
  studentHorsesList?: any;
  removeEventFunc?: (trainingData: any,refund?:any) => void;
  editEventFunc?: (data: any) => void;
  removeAttendeeFunc?: (data: any) => void;
  onCreateOrUpdateActivity?: (data: any, oldData?: any) => void;
}
const EqActivityTrainingList = ({
  creatorUser,
  areas,
  weekDays,
  population,
  groupPopulation,
  trainerStudents,
  studentHorsesList,
  eventList,
  removeEventFunc,
  editEventFunc,
  removeAttendeeFunc,
  onCreateOrUpdateActivity,
  ...rest
}: IEqActivityTrainingList) => {
  const { t, i18n } = useTranslation();
  const [eventData, setEventData] = React.useState({} as IEqEventSplit);

  const options: Intl.DateTimeFormatOptions = {
    weekday: "long", // Correctly typed as "long" | "short" | "narrow" | undefined
    day: "2-digit",  // Correctly typed as "numeric" | "2-digit" | undefined
    month: "short",  // Correctly typed as "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined
    year: "numeric", // Correctly typed as "numeric" | "2-digit" | undefined
};
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

  const tomorrow = new Date();
  tomorrow.setHours(24, 0, 0, 0);
  const tomorrowTime = tomorrow.getTime();

  const dayAfterTomorrow = new Date();
  dayAfterTomorrow.setHours(48, 0, 0, 0);
  const dayAfterTomorrowTime = dayAfterTomorrow.getTime();

  const [todayIsCollapseAll, setIsTodayCollapseAll] = React.useState(false);
  const todayCollapseAll = () => {
    setIsTodayCollapseAll(!todayIsCollapseAll);
    eventData?.today.map((item: any) => {
      item.display = !todayIsCollapseAll;
    });
  };

  const [tomorrowIsCollapseAll, tomorrowSetIsCollapseAll] =
    React.useState(false);

  const tomorrowCollapseAll = () => {
    tomorrowSetIsCollapseAll(!tomorrowIsCollapseAll);
    eventData?.tomorrow.map((item: any) => {
      item.display = !tomorrowIsCollapseAll;
    });
  };

  const [laterIsCollapseAll, laterSetIsCollapseAll] = React.useState(false);

  const laterCollapseAll = () => {
    laterSetIsCollapseAll(!laterIsCollapseAll);
    eventData?.later.map((item: any) => {
      item.display = !laterIsCollapseAll;
    });
  };
  React.useEffect(() => {
    var formedData = {} as IEqEventSplit;
    if (eventList?.length > 0) {
      // formedData.today=eventList
      formedData.today = eventList?.filter(
        // (opt: any) => opt.startDate < tomorrowTime && opt.startDate >= todayTime
        (opt: any) => opt.startDate < tomorrowTime 
      );
      formedData.tomorrow = eventList?.filter(
        (opt: any) =>
          opt.startDate < dayAfterTomorrowTime && opt.startDate >= tomorrowTime
      );
      formedData.later = eventList?.filter(
        (opt: any) => opt.startDate > dayAfterTomorrowTime
      );
      setEventData(formedData);
    }
    //formedData.today = eventList;
  }, [eventList, areas, weekDays]);

  return (
    <>
      {eventData?.later?.length > 0 ||
      eventData?.tomorrow?.length > 0 ||
      eventData?.today?.length > 0 ? (
        <>
          <div className="eq-el-container">
            <Row>
              <Col flex="auto">
                <h4 className="eq-el-heading">{t("today")}</h4>
              </Col>
              <Col className="eq-el-dateHeading">
                <h5 className="eq-el-date">
                {new Date().toLocaleDateString(t("dateLang"),options as any)}
                </h5>

                <div className="eq-el-date-icon hidden-sm">
                  <span>
                    <img alt="" src="/DateEdit.svg" />
                  </span>
                </div>
              </Col>
            </Row>

            {eventData?.today?.length > 0 ? (
              <>
                {eventData.today.map((item: any, index: number) => (
                  <>
                    <EqActivityCollapse
                      key={index}
                      creatorUser={creatorUser}
                      event={item}
                      areas={areas}
                      weekDays={weekDays}
                      population={population}
                      groupPopulation={groupPopulation}
                      trainerStudents={trainerStudents}
                      studentHorsesList={studentHorsesList}
                      removeEventFunc={removeEventFunc}
                      editEventFunc={editEventFunc}
                      removeAttendeeFunc={removeAttendeeFunc}
                      onCreateOrUpdateActivity={onCreateOrUpdateActivity}
                    ></EqActivityCollapse>
                  </>
                ))}
              </>
            ) : (
              <Row justify="center">
                <Empty description={t("noTrainingsAvailable")} />
              </Row>
            )}
          </div>

          <div className="eq-el-container">
            <Row>
              <Col flex="auto">
                <h4 className="eq-el-heading">{t("tomorrow")}</h4>
              </Col>
              <Col className="eq-el-dateHeading">
                <h5 className="eq-el-date">
                {addDays(new Date(), 1).toLocaleDateString(t("dateLang"), options)}
                </h5>

                <div className="eq-el-date-icon hidden-sm">
                  <span>
                    <img alt="" src="/DateEdit.svg" />
                  </span>
                </div>
              </Col>
            </Row>

            {eventData?.tomorrow?.length > 0 ? (
              <>
                {eventData.tomorrow.map((item: any, index: number) => (
                  // <EqEvent {...{ event: { ...item }, removeEventFunc: removeEventFunc, editEventFunc: editEventFunc, removeAttendeeFunc: removeAttendeeFunc  }} ></EqEvent>
                  <EqActivityCollapse
                    key={index}
                    creatorUser={creatorUser}
                    event={item}
                    areas={areas}
                    weekDays={weekDays}
                    population={population}
                    groupPopulation={groupPopulation}
                    trainerStudents={trainerStudents}
                    studentHorsesList={studentHorsesList}
                    removeEventFunc={removeEventFunc}
                    editEventFunc={editEventFunc}
                    removeAttendeeFunc={removeAttendeeFunc}
                    onCreateOrUpdateActivity={onCreateOrUpdateActivity}
                  ></EqActivityCollapse>
                ))}
              </>
            ) : (
              <Row justify="center">
                <Empty description={t("noTrainingsAvailable")} />
              </Row>
            )}
          </div>
          <div className="eq-el-container">
            <h4 className="eq-el-heading">{t("later")}</h4>

            {eventData?.later?.length > 0 ? (
              <>
                {eventData.later.map((item: any, index: number) => (
                  <EqActivityCollapse
                    key={index}
                    creatorUser={creatorUser}
                    event={item}
                    areas={areas}
                    weekDays={weekDays}
                    population={population}
                    groupPopulation={groupPopulation}
                    trainerStudents={trainerStudents}
                    studentHorsesList={studentHorsesList}
                    removeEventFunc={removeEventFunc}
                    editEventFunc={editEventFunc}
                    removeAttendeeFunc={removeAttendeeFunc}
                    onCreateOrUpdateActivity={onCreateOrUpdateActivity}
                  ></EqActivityCollapse>

                  // <EqEvent {...{ event: { ...item }, removeEventFunc: removeEventFunc, editEventFunc: editEventFunc, removeAttendeeFunc: removeAttendeeFunc }} key={index} ></EqEvent>
                ))}
              </>
            ) : (
              <Row justify="center">
                <Empty description={t("noTrainingsAvailable")} />
              </Row>
            )}
          </div>
        </>
      ) : (
        <div className="eq-el-container">
          <Empty description={t("noTrainingsAvailable")} />
        </div>
      )}
    </>
  );
};
export default EqActivityTrainingList;
