import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Space } from 'antd';
import axios from 'axios';
import { EqInput } from '../../../custom/eqInput/EqInput';
import { Col, Row } from 'antd-v5';
import EqButton from '../../../custom/eqButton/EqButton';

const EqStatusTab: React.FC = () => {
  const [allData, setAllData] = useState([]); // State to store all status data
  const [filteredData, setFilteredData] = useState([]); // State to store filtered status data
  const [search, setSearch] = useState(''); // State for search input

  // Function to fetch all status data from backend
  const fetchAllStatusData = async () => {
    try {
      const response = await axios.get('/api/sendGrid/GetStatuses'); // Assuming your API is hosted at /api/EmailStatus
      setAllData(response.data);
      setFilteredData(response.data); // Initialize filtered data with all data initially
    } catch (error) {
      console.error('Error fetching status data:', error);
    }
  };

  useEffect(() => {
    fetchAllStatusData();
  }, []); // Fetch data on component mount

  // Function to handle search
  const handleSearch = () => {
    const searchTerm = search.toLowerCase();
    const filtered = allData.filter(
      (item: any) =>
        item.email.toLowerCase().includes(searchTerm) || item.messageId.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filtered);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Message ID',
      dataIndex: 'messageId',
      key: 'messageId',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Event Type',
      dataIndex: 'eventType',
      key: 'eventType',
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp: number | undefined) => {
        return timestamp ? new Date(timestamp).toLocaleString() : '-';
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date: string | undefined) => {
        return date ? new Date(date).toLocaleDateString() : '-';
      },
    },
    {
      title: 'Response or Reason',
      dataIndex: 'responseOrReason',
      key: 'responseOrReason',
    },
    // Add any other relevant columns based on your data structure
  ];

  return (
    <div>
    
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <EqInput
          placeholder="Search by email or MessageId"
          onChange={(e: any) => setSearch(e.target.value)}
        />
      </Col>
      <Col span={6}>
        <EqButton type="primary" onClick={handleSearch}>
          Search
        </EqButton>
      </Col>
    </Row>
    {/* <Table columns={columns} dataSource={filteredData} /> */}
    <Table columns={columns} dataSource={filteredData} 
    scroll={{ x: true }} 
    style={{ marginTop: '16px' }} />

  </div>
  );
};

export default EqStatusTab;
