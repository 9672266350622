import React, { useState } from "react";
import "./EqSubscriptions.scss";

import { Button, Col, Divider, Modal, Row, Space } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
// import EqSubscriptionsForm from "./eqSubscriptionSettingForm/EqSubscriptionsForm";
import Api from "../../shared/Api";
import { ApiEndpoints, openNotificationWithIcon } from "../../shared/Common";
import { ISubscription } from "../../shared/interfaces/subsctiption/ISubscription";
import EqSubscriptionsTable from "./eqSubscriptionTable/EqSubscriptionTable";
import { EqProfessionType, SubscriptionTypeEnum, eqNotificationTypes } from "../../shared/enums";
import EqSubscriptionTable from "./eqSubscriptionTable/EqSubscriptionTable";
import EqSelect from "../../custom/eqSelect/EqSelect";
import { ISubscriptionSetting } from "../../shared/interfaces/subsctiption/ISubscriptionSetting";
import EqSubscriptionForm from "./eqSubscriptionForm/EqSubscriptionForm";

const EqSubscriptions = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [updateFormVisible, setUpdateFormVisible] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<
    ISubscription | undefined
  >(undefined);
  const [selectedSetting, setSelectedSetting] = useState<string | undefined>(
    undefined
  );

  const [settings, setSettings] = useState<ISubscriptionSetting[]>([]); // Fetch settings from your API
  const [subscriptions, setSubscriptions] = useState([]) as any; // Fetch products from your API

  const [professionalList, setProfessionalList] = React.useState([] as any);
  const [professionalOptions, setProfessionalOptions] = React.useState({
    trainerOptions: [],
    eventHolderOptions: [],
    schoolOptions: [],
  } as any);

  React.useEffect(() => {
    GetSubscriptions();
    GetSubscriptionSettings();
    GetAllProfessionals();
  }, []);

  const GetAllProfessionals = () => {
    Api.get(ApiEndpoints.ProfessionalUser).then(
      (response) => {
        var professionalsFromDB = [] as any;

        var eventHolderOptions = [] as any;
        var schoolsOptions = [] as any;
        var trainerOptions = [] as any;

        if (response && response.status == 200) {
          response.data?.map((d: any) => {
         
            let obj = {
              key: d.key,
              value: d.firstName ? d.firstName + " " + d.lastName : d.email,
            };
            if (d.professionType === EqProfessionType.Trainer) {
              trainerOptions.push(obj);
            } else if (d.professionType === EqProfessionType.EventHolder) {
              eventHolderOptions.push(obj);
            } else {
              schoolsOptions.push(obj);
            }

            // let professional: IProfileDto = d;
            // professional.display = false;
            // professional.name = d.firstName
            //   ? d.firstName
            //   : "" + " " + d.lastName
            //   ? d.lastName
            //   : "" || d.userName;

            // professionalsFromDB.push(professional);
          });

          setProfessionalOptions({
            trainerOptions: trainerOptions,
            eventHolderOptions: eventHolderOptions,
            schoolsOptions: schoolsOptions,
          });
          console.log(response.data);
          setProfessionalList(response.data);
        } else {
          //setMessageString('');
        }
      },
      (err) => {
        //setMessageString('');
      }
    );
  };

  const GetSubscriptions = () => {
    Api.get("api/Subscription/Get/Active").then(
      (response) => {
        if (response && response.status == 200) {
          console.log(response);
          setSubscriptions(response.data);
        }
      },
      (error) => {
        throw error;
      }
    );
  };

  const GetSubscriptionSettings = () => {
    Api.get("SubscriptionSetting").then(
      (response) => {
        if (response && response.status == 200) {
          console.log(response);
          setSettings(response.data);
        }
      },
      (error) => {
        throw error;
      }
    );
  };

  const handleCreateSubscriptionSetting = (data: any) => {
    
    Api.post("/api/Subscription/Assign", data).then(
      (result) => {
        console.log(result);
        setFormVisible(false);
        addItem(result.data);
        openNotificationWithIcon(
          "Created!",
          "Subscription have been Assigned to user",
          eqNotificationTypes.success
        );
      },
      (error) => {
        setFormVisible(false);
        if (error.response && error.response.status === 409) {
          // Conflict error
          openNotificationWithIcon(
            "Conflict",
            "A similar subscription setting already exists."
          );
        } else {
          // Other errors
          openNotificationWithIcon(
            "Server error",
            "Error occurred while creating. Please try again or contact the administrator."
          );
        }
        // throw error;
      }
    );
  };
  const addItem = (subscription: any) => {
    const UpdatedSubscriptions = [...subscriptions];
    UpdatedSubscriptions.push(subscription);
    setSubscriptions(UpdatedSubscriptions);
  };

  const handleEdit = (setting: ISubscription) => {
    setSelectedSubscription(setting);
    setUpdateFormVisible(true);
  };

  const handleUpdateSubscription = () => {
    if (!selectedSetting || !selectedSubscription) {
      // Handle validation or show an error message
      return;
  }
    const data = {
      subscription: selectedSubscription,
      SubscriptionSettingId: selectedSetting,
  };

    Api.put("/api/Subscription/update", data).then(
      (result) => {
        console.log(result);
        updateItem(selectedSubscription.id, result.data);
        setUpdateFormVisible(false);
        setSelectedSetting(undefined)
        openNotificationWithIcon(
          "Updated!",
          "Subscription have been Update SuccessFully",
          eqNotificationTypes.success
        );
      },
      (error) => {
        setUpdateFormVisible(false);
        setSelectedSetting(undefined)
        
        if (error.response && error.response.status === 409) {
          // Conflict error
          openNotificationWithIcon(
            "Conflict",
            "A similar subscription setting already exists."
          );
        } else {
          // Other errors
          openNotificationWithIcon(
            "Server error",
            "Error occurred while Updating. Please try again or contact the administrator."
          );
        }
      }
    );
  };
  const updateItem = (updatedId:string, updatedData :ISubscription) => {
    const updatedList = subscriptions.map((item:any) =>
      item.id === updatedId ? updatedData : item
    );
    setSubscriptions(updatedList);
  };

  const handleDelete = (subscriptionId: any) => {
    Api.delete("Subscription" + `/${subscriptionId}`).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(subscriptionId);
        } else {
          // error
          // 404 means not found
        }
      },
      (error) => {
        throw error;
      }
    );
  };

  const removeItem = (subscriptionId: any) => {
    if (subscriptions != undefined) {
      var updatedList =
        subscriptions != undefined
          ? subscriptions.filter((item: any) => item.id !== subscriptionId)
          : new Array<any>();
      setSubscriptions(updatedList);
    }
  };
  const handleSelectSetting = (evt: any) => {
    setSelectedSetting(evt);
  };
  return (
    <div className="eq-subscription-product">
      <Row gutter={[10, 10]}>
        <Col
          sm={{ span: 22, offset: 1 }}
          md={{ span: 22, offset: 1 }}
          lg={{ span: 20, offset: 2 }}
        >
          <Row>
            <Col span={24}>
              <h2 className="eq-heading-row"> Subscriptions</h2>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <h4>Assign Subscription Setting</h4>
            </Col>
            <Col
              style={{
                display: "flex",
                flex: "1",
              }}
            >
              <EqButton
                classNames="btn-create"
                type="button"
                onClick={() => setFormVisible(true)}
              >
                Create Setting
              </EqButton>
            </Col>
          </Row>
          <Divider dashed />

          <EqSubscriptionForm
              visible={formVisible}
              onCreate={handleCreateSubscriptionSetting}
              onCancel={() => setFormVisible(false)}
              professionals={professionalList}
              subscriptionSettings={settings}
            />


          <EqSubscriptionTable
            subscriptions={subscriptions}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />

          <Modal
            title="Update Subscription"
            open={updateFormVisible}
            onOk={handleUpdateSubscription}
            onCancel={() => setUpdateFormVisible(false)}
          >
            <EqSelect
              label=" SelectSetting"
              options={settings.map(({ id, title }) => ({
                key: id,
                value: title,
                label: title,
              }))}
              defaultSelectedValue={selectedSetting}
              classNames="extra-height"
              changeFunc={handleSelectSetting}
            />
          </Modal>
        </Col>
      </Row>
    </div>
  );
};

export default EqSubscriptions;
