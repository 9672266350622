import React, { useState, useEffect } from "react";
import { Row, Col, message, Tooltip, Popconfirm } from "antd";
import { Checkbox, Tag } from "antd";
import "./EqStudent.scss";
import { paymentStatusType } from "../../shared/enums";
import { IStudentDto } from "../../shared/interfaces/IStudentDto";
import { FrontEndPoints, ValidateIsStudent, ValidateIsTrainer } from "../../shared/Common";
import history from "../../shared/history";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EqTags from "../../custom/eqTags/EqTags";
import EqCollapse from "../../custom/eqCollapse/EqCollapse";
import { useTranslation, Trans } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAuthState } from "../../context/Context";
export interface IEqStudent {
  student: IStudentDto;
  deleteFunc?: any;
  selected?: boolean;
  onCheck?: any;
}

const EqStudent = ({ student, deleteFunc, selected, onCheck }: IEqStudent) => {
  //const [studentData, setStudentData] = useState({ ...student });
  // const [check, setCheck] = useState(selected as any);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [copyMessage, contextCopyMessage] = message.useMessage();
  const userDetails: any = useAuthState();
  const location = useLocation();
  const isStudent: boolean = ValidateIsStudent(userDetails);
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const messageClicked = () => {
    var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${student.email}`;
    navigate(out1);
  };
  const deleteClicked = (data: any) => {
    if (deleteFunc != undefined) {
      
      deleteFunc(student);
    }
  };

  useEffect(() => {
    // 
    console.log(student)
    
  }, [student]);

  // function checkChange(selected: boolean) {
  //   setCheck(selected);
  // }
  // function onChange() {
  //   setCheck(!check);
  // }
  const getClass = (param: paymentStatusType) => {
    switch (param) {
      case paymentStatusType.Paid:
        return "green";
      case paymentStatusType.UnpaidButAttending:
        return "red";
      case paymentStatusType.StudentCancelled:
        return "purple";

      default:
        return "green";
    }
  };

  const getPaymentStatus = (param: paymentStatusType) => {
    switch (param) {
      case paymentStatusType.Paid:
        return "Accepted";
      case paymentStatusType.UnpaidButAttending:
        return paymentStatusType[student.payment];
      case paymentStatusType.UnpaidButAttending:
        return paymentStatusType[student.payment];
      case paymentStatusType.StudentCancelled:
        return paymentStatusType[student.payment];
      default:
        return paymentStatusType[student.payment];
    }
  };

  const checkPathFortrainerList = (path: string): boolean => {
    if (
      path === "/school/trainers"
    ) {
      return false;
    } else {
      return true;
    }
  };
  const getEmailsText = () => {
    const emails = student.email || "";

    return emails;
  };

  const getNumbersText = () => {
    const numbers = student?.phoneNumber || "";

    return numbers;
  };
  const handleCopyLink = () => {
    copyMessage.open({
      type: "success",
      content: "Copied",
    });
  };
  return (
    <>
      <Row className="eq-s-tr  hidden-sm" align="middle" wrap={true}>
        <Col className="eq-s-td gutter-row" span={1}>
          {isStudent ? (
            ""
          ) : (

            <Checkbox
              onChange={() => {
                onCheck(student);
              }}
              checked={student.selected}
            ></Checkbox>
          )}
        </Col>

        {isStudent ? (
          <Col className="eq-s-td gutter-row" xs={5} sm={5} md={5}>
            {student?.firstName} {student?.lastName}
          </Col>
        ) : (
          <Col className="eq-s-td gutter-row" xs={7} sm={6} md={4} lg={4}>
            {student?.firstName} {student?.lastName}
          </Col>
        )}

        {isStudent ? (
          <Col
            className="eq-s-td gutter-row"
            md={6} xs={6} sm={6}
          >
            {contextCopyMessage}
            <div className="copy-text">
              {student?.userName}
            </div>

          </Col>
        ) : (
          <Col
            className="eq-s-td gutter-row  student-detail-copy"
            xs={7}
            sm={6}
            md={5}
            lg={5}
          >
            {contextCopyMessage}
            <CopyToClipboard text={getEmailsText()}>
              <div className="copy-text" onClick={handleCopyLink}>
                <Tooltip title={t("copyEmails")} color="#9cb9ca">
                  {student?.userName}
                </Tooltip>
              </div>
            </CopyToClipboard>
          </Col>
        )}


        {isStudent ? (
          <Col className="eq-s-td gutter-row " xs={5} sm={5} md={5}>
            <div className="copy-text" >
              {student?.phoneNumber}

            </div>
          </Col>
        ) : (

          <Col className="eq-s-td gutter-row  student-detail-copy" xs={0} sm={3} md={4} lg={4}>
            <CopyToClipboard text={getNumbersText()}>
              <div className="copy-text" onClick={handleCopyLink}>
                <Tooltip title={t("copyNumbers")} color="#9cb9ca">
                  {student?.phoneNumber}
                </Tooltip>
              </div>
            </CopyToClipboard>

            {/* {student?.location
            ? student.location
            : (student.locations?.location?.street
              ? student.locations?.location?.street + " "
              : "") +
            (student.locations?.location?.zipCode
              ? student.locations?.location?.zipCode + ", "
              : "") +
            (student.locations?.location?.city
              ? student.locations?.location?.city
              : "")} */}
          </Col>
        )}



        {isStudent ? (
          ""
        ) : (

          <>
            {checkPathFortrainerList(location.pathname) ?
              (
                <Col className="eq-s-td gutter-row" xs={0} sm={0} md={4} lg={4}>
                  <span className={`eq-status-${getClass(student.payment)}`}>
                    {/* {paymentStatusType[student.payment]} */}
                    {getPaymentStatus(student.payment)}
                    {/*{payment && [<span className="eq-btn-icon"><img alt="" src="/green-Check.svg" /></span >]}*/}
                  </span>
                </Col>
              ) : (
                ""

              )
            }
          </>
        )}

        {isStudent ? (
          <Col
            className="eq-s-td gutter-row eq-s-groups hidden-sm-students"
            md={5} xs={10} sm={10}
          >
            {student.groups?.map((item: any, index: number) => (
              <EqTags
                // className={`eq-group-color-${(index % 4) + 1}`}
                color={item?.color}
                key={index}
                text={item?.name}
              ></EqTags>
            ))}
          </Col>
        ) : (

          <>
            {checkPathFortrainerList(location.pathname) ?
              (
                <Col className="eq-s-td gutter-row eq-s-groups hidden-sm-students" xs={7} sm={6} md={4} lg={4}>
                  {student.groups?.map((item: any, index: number) => (
                    <EqTags
                      // className={`eq-group-color-${(index % 4) + 1}`}
                      color={item?.color}
                      key={index}
                      text={item?.name}
                    ></EqTags>
                  ))}
                </Col>
              )
              : (
                <Col className="eq-s-td gutter-row eq-s-groups hidden-sm-students" xs={10} sm={8} md={6} lg={7}>
                  {student.groups?.map((item: any, index: number) => (
                    <EqTags
                      // className={`eq-group-color-${(index % 4) + 1}`}
                      color={item?.color}
                      key={index}
                      text={item?.name}
                    ></EqTags>
                  ))}
                </Col>

              )
            }
          </>


        )}



        <Col className="eq-s-td gutter-row hidden-sm-groups" span={2}>
          <div className="">
            <span className="eq-action-invite" onClick={messageClicked}>
              <img alt="" src="/Invite.svg" />
            </span>

            <span className="eq-action-delete hidden-action-groups">
              <Popconfirm
                placement="bottomRight"
                title={"Are you sure to delete?"}
                okText="Yes"
                cancelText="No"
                onConfirm={deleteClicked}
              >
                <span className="gh-icon">
                  <img alt="" src="/Delete.svg" />
                </span>
              </Popconfirm>

            </span>

          </div>
          <div className=""></div>
        </Col>
      </Row>

      {/* For Mobile view */}

      <div className="hidden-md mobile-view">
        <EqCollapse
          header={
            <Row justify="start" align="middle">
              {isStudent ? (
                ""
              ) : (
                <Col className="" span={2}>

                  <Checkbox
                    onChange={() => {
                      onCheck(student);
                    }}
                    checked={student.selected}
                  ></Checkbox>
                </Col>

              )}
              <Col className="" flex="auto">
                {student?.userName}
              </Col>
            </Row>
          }
        >
          <Row className="eq-card-row">
            <Col className="eq-card-col-heading" span={24}>
              {t("name")}
            </Col>
            <Col className="eq-card-row " span={24}>
              {student?.firstName} {student?.lastName}
            </Col>
          </Row>
          <Row>
            <Col flex="auto" className="eq-card-divider"></Col>
          </Row>

          {isStudent ? (
            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                {t("e-mail")}
              </Col>
              <Col className="eq-card-row " span={24}>
                <div className="copy-text" >
                  {student?.userName}
                </div>
              </Col>
            </Row>
          ) : (
            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                {t("e-mail")}
              </Col>
              <Col className="eq-card-row student-detail-copy" span={24}>
                <CopyToClipboard text={getEmailsText()}>
                  <div className="copy-text" onClick={handleCopyLink}>
                    <Tooltip title={t("copyEmails")} color="#9cb9ca">
                      {student?.userName}
                    </Tooltip>
                  </div>
                </CopyToClipboard>
              </Col>
            </Row>
          )}


          <Row>
            <Col flex="auto" className="eq-card-divider"></Col>
          </Row>

          {isStudent ? (
            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                {t("phoneNumber")}
              </Col>
              <Col className="eq-card-row" span={24}>
                <div className="copy-text" >
                  {student?.locations?.user?.phoneNumber}
                </div>
              </Col>
            </Row>
          ) : (
            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                {t("phoneNumber")}
              </Col>
              <Col className="eq-card-row student-detail-copy" span={24}>
                <CopyToClipboard text={getNumbersText()}>
                  <div className="copy-text" onClick={handleCopyLink}>
                    <Tooltip title={t("copyNumbers")} color="#9cb9ca">
                      {student?.phoneNumber}
                    </Tooltip>
                  </div>
                </CopyToClipboard>
              </Col>
            </Row>
          )}

          {isStudent ? (
            ""
          ) : (

            <>
              {checkPathFortrainerList(location.pathname) ?
                (
                  <>
                    <Row>
                      <Col flex="auto" className="eq-card-divider"></Col>
                    </Row>
                    <Row className="eq-card-row">
                      <Col className="eq-card-col-heading" span={24}>
                        {t("payments")} {t("status")}
                      </Col>
                      <Col className="eq-card-row horse-detail" span={24}>
                        <span className={`eq-status-${getClass(student.payment)}`}>
                          {/* {paymentStatusType[student.payment]} */}
                          {getPaymentStatus(student.payment)}
                          {/*{payment && [<span className="eq-btn-icon"><img alt="" src="/green-Check.svg" /></span >]}*/}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""

                )
              }
            </>
          )}

          <Row>
            <Col flex="auto" className="eq-card-divider"></Col>
          </Row>
          <Row className="eq-card-row">
            <Col className="eq-card-col-heading" span={24}>
              {t("groups")}
            </Col>
            <Col className="eq-card-row  eq-s-groups" span={24}>
              {student.groups?.map((item: any, index: number) => (
                <EqTags
                  // className={`eq-group-color-${(index % 4) + 1}`}
                  color={item?.group?.color}
                  key={index}
                  text={item?.group?.name}
                ></EqTags>
              ))}
            </Col>
          </Row>
          <Row>
            <Col flex="auto" className="eq-card-divider"></Col>
          </Row>
          <Row className="eq-card-row">
            <Col className="eq-card-col-heading" span={24}>
              {t("actions")}
            </Col>
            <Col className="action-btn" span={24}>
              <span className="eq-action-invite" onClick={messageClicked}>
                <img alt="" src="/Invite.svg" />
              </span>

              <span
                className="eq-action-delete hidden-action-groups"
              >
                <Popconfirm
                  placement="bottomRight"
                  title={"Are you sure to delete?"}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={deleteClicked}
                >
                  <span className="gh-icon">
                    <img alt="" src="/Delete.svg" />
                  </span>
                </Popconfirm>

              </span>
            </Col>
          </Row>
          <Row>
            <Col flex="auto" className="eq-card-divider"></Col>
          </Row>
        </EqCollapse>
      </div>
    </>
  );
};
export default EqStudent;
