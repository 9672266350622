import * as React from "react";
import "./EditProfile.scss";
import {
    Row,
    Col,
    Alert,
    Tabs,
    Card,
    Image,
    notification,
    Checkbox,
    Tooltip,
    Radio,
    Space,
} from "antd";
import EqButton from "../custom/eqButton/EqButton";
import { EqInput } from "../custom/eqInput/EqInput";
import { Input } from "antd";
import EqSelect from "../custom/eqSelect/EqSelect";
import { ISelectProps } from "../shared/Interfaces";
import Api from "../shared/Api";
import { ILocationDto } from "../shared/interfaces/ILocationDto";
import {
    genderType,
    EqDisciplineType,
    EqLocationType,
    EqPaymentAccountStatus,
    eqNotificationTypes,
    Countries,
    BusinessType,
    EqRefundType,
} from "../shared/enums";
import { EqLocation, IEqLocationProps } from "../custom/eqLocation/EqLocation";
import { AxiosResponse } from "axios";
import {
    ApiEndpoints,
    dateFormat,
    FrontEndPoints,
    ValidateIsAdmin,
    ValidateIsEventHolder,
    ValidateIsParent,
    ValidateIsSchool,
    ValidateIsStudent,
    ValidateIsTrainer,
} from "../shared/Common";
import { useAuthDispatch, useAuthState } from "../context/Context";
import { Upload, message, Switch } from "antd";
import { CopyFilled, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Compressor from "compressorjs";
import { logout, updateImage } from "../context/Actions";
import EqGoogleMap from "../custom/eqGoogleMap/eqGoogleMap";
import { useLoadScript } from "@react-google-maps/api";
import { googleMap_API_Key } from "../shared/Common";
import EqToggle, { IEqToggle } from "../custom/eqToggle/EqToggle";

import { useLocation, useNavigate } from "react-router-dom";
import EqDatePicker, { IDatePickerOptions } from "../custom/eqDatePicker/EqDatePicker";
import moment from "moment";
import EqAddParent from "../custom/eqAddParent/EqAddParent";
import EqProductSection from "../custom/eqProductSection/EqProductSection";
import { CardElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import EqStripeCardForm from "../eqStripCardForm/EqStripeCardForm";
import { CopyToClipboard } from "react-copy-to-clipboard";
import config from "../shared/Config";
import HorsesList from "../eqTrainer/HorsesList/HorsesList";
import { useTranslation, Trans } from "react-i18next";
import EqTags from "../custom/eqTags/EqTags";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EqSubscriptionsPlan from "../custom/eqSubscriptionsPlan/EqSubscriptionsPlan";
import EqIntegrationSection from "../custom/eqIntegrations/EqIntegrationSection";

import AnimationDiv from "../custom/animationDiv/AnimationDiv";
import RevealList from "../custom/RevealList/RevealList";
const EditProfile = (props?: any) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const userDetails: any = useAuthState();
    const isParent: boolean = ValidateIsParent(userDetails);
    const isStudent: boolean = ValidateIsStudent(userDetails);
    const isTrainer: boolean = ValidateIsTrainer(userDetails);
    const isSchool: boolean = ValidateIsSchool(userDetails);
    const isEventHolder: boolean = ValidateIsEventHolder(userDetails);

    // const stripeTestPromise = loadStripe(
    //   "pk_test_51MHTljJWnNjo1nE6OprHn3QFaM5siqA8GeEB1NF8MUje05lIdr6cTSLqVLCHEr3Ci3bhkrI42cZE0E9r1O71rfse00I42bPvCp"
    // );

    const stripeTestPromise = loadStripe(
        "pk_live_51MHTljJWnNjo1nE6Kl60eSBgz1hPaHTg2Ptlq351JzML13QuQmgJ3V1vpTQkIYroOLrD6qCQ0BVTuE6ZLUwhvk4200mlquqKs0"
    );
    const CARD_OPTIONS: any = {
        iconStyle: "solid",
        style: {
            base: {
                iconColor: "#c4f0ff",
                color: "black",
                fontWeight: "500",
                fontFamily: "Inter,Open Sans,Segeo UI,sans-serif",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": { color: "#fce883" },
                ":-placeholder": { color: "#87bbfd" },
            },
            invalid: {
                iconColor: "#ffc7ee",
                color: "red",
            },
        },
    };

    const libraries = ["places"] as any;
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: googleMap_API_Key,
        libraries: libraries,
    });

    // not in use
    const [copied, setCopied] = React.useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
    };

    const { Meta } = Card;
    let profileData: any = null;

    const { TabPane } = Tabs;

    // const { isStudent } = props;

    const horseInitial = {
        name: "",
        about: "",
        dateOfBirth: 0,
        imageUrl: "",
        type: 1,
        // userId:0
    };

    const [horseFormData, setHorseFormData] = React.useState({
        ...horseInitial,
    } as any);
    const [homeLocation, setHomeLocation] = React.useState({} as ILocationDto);
    const [horsesList, setHorsesList] = React.useState([] as any);
    const [tabKey, setTabKey] = React.useState("1");
    const [user, setUser] = React.useState({} as any);
    const [allUsers, setAllUsers] = React.useState([] as any);
    const [value, setValue] = React.useState("");
    const [locationState, setLocationState] = React.useState({
        allLocations: [],
        selectedLocations: [],
        locationTags: [],
        allMapLocations: [],
        selectedMapLocations: [],
    } as any);
    const [mapLocations, setMapLocations] = React.useState([] as any);
    const [disciplineState, setDisciplineState] = React.useState({
        allDisciplines: [],
        selectedDisciplines: [],
        disciplineTags: [],
    } as any);
    const [trainingLevelState, setTrainingLevelState] = React.useState({
        allTrainingLevels: [],
        selectedTrainingLevels: [],
        trainingLevelTags: [],
    } as any);
    const [latePayment, setLatePayment] = React.useState({
        allow: false,
        days: 10,
    });
    const [messageApi, contextHolder] = message.useMessage();

    const [isError, setIsError] = React.useState(false);
    const [paymentInfo, setPaymentInfo] = React.useState() as any;

    const [loading, setLoading] = React.useState(false);
    const [imageModel, setImageModel] = React.useState();
    const [toggleFilters, setToggleFilters] = React.useState([] as any);
    const [integrationInfo, setIntegrationInfo] = React.useState({});

    const [countryOptions, setCountryOptions] = React.useState([] as any);
    const [selectedCountry, setSelectedCountry] = React.useState("");

    const [businessTypeOptions, setBusinessTypeOptions] = React.useState([] as any);
    const [selectedBusinessType, setSelectedBusinessType] = React.useState("");

    const [isAccountClicked, setIsAccountClicked] = React.useState(false);
    const [addStripCard, setAddStripCard] = React.useState(false);
    const [changeStripCard, setChangeStripCard] = React.useState(false);

    const [selectedToggle, setSelectedToggle] = React.useState() as any;
    const [ImageUrl, setImageUrl] = React.useState("") as any;

    const [isSuccess, setIsSuccess] = React.useState(false);
    const successText = "Profile is updated.";
    const errorText = "Error occurred and image/profile could'nt be updated.";
    const dispatch = useAuthDispatch();

    const createCountryOptions = () => {
        var types = [] as any;

        for (var x in Countries) {
            types.push({
                key: x,
                value: Countries[x as keyof typeof Countries],
            });
        }
        setCountryOptions(types);
    };

    const createBusinessTypeOptions = () => {
        var types = [] as any;

        for (var x in BusinessType) {
            types.push({
                key: x,
                value: BusinessType[x as keyof typeof BusinessType],
            });
        }
        setBusinessTypeOptions(types);
    };

    const newLocationAdded = (param: any, detail?: any) => {
        var loc = [...locationState.allLocations];
        loc.push(param);
        var selLoc = [...locationState.selectedLocations];
        selLoc.unshift(param.key);
        var locTags = [...locationState.locationTags];
        locTags.push(param);
        if (detail != null || undefined) {
            var allMapLocs = [...locationState.allMapLocations];
            allMapLocs.push(detail);
            var mapLocs = [...locationState.selectedMapLocations];
            mapLocs.push(detail);
            setLocationState({
                ...locationState,
                allLocations: loc,
                selectedLocations: selLoc,
                locationTags: locTags,
                allMapLocations: allMapLocs,
                selectedMapLocations: mapLocs,
            });
        } else {
            setLocationState({
                ...locationState,
                allLocations: loc,
                selectedLocations: selLoc,
                locationTags: locTags,
            });
        }
    };
    const [addLocationProps, setAddLocationProps] = React.useState({
        title: "Add new Location",
        cancelClicked: (param: any) => {
            setAddLocationProps({ ...addLocationProps, visible: false });
        },
        okClicked: newLocationAdded,
        visible: false,
        email: "",
    });

    const { TextArea } = Input;
    const dobProps: IDatePickerOptions = {
        isDisabled: true,
        isSuffixIcon: true,
        isBordered: true,
        defaultValue: user?.dateOfBirth,
        placeHolder: "",
        classNames: "eq-event",
        onChangeFunc: (date: any) => {
            setUser({ ...user, dateOfBirth: moment(date) });
        },
    };
    const locationProps: ISelectProps = {
        mode: "multiple",
        isTag: false,
        showArrow: true,
        allowClear: true,
        classNames: "eq-multiSelect eq-addSelect",
        ddClassName: "eq-select-dropdown",
        defaultSelectedValue: locationState.selectedLocations,
        selectedIcon: [
            <span className="">
                <img alt="" src="/check-square.svg" />
            </span>,
        ],
        options: locationState.allLocations,
        changeFunc: (param: any) => {
            const selLocs = locationState.allLocations.filter((d: any) => param.includes(d.key));
            console.log(selLocs);
            const selMapLocs = locationState.allMapLocations.filter((d: any) =>
                param.includes(d.key.toString())
            );
            console.log(selMapLocs);
            setLocationState({
                ...locationState,
                locationTags: selLocs,
                selectedMapLocations: selMapLocs,
            });
        },
    };

    const disciplineProps: ISelectProps = {
        mode: "multiple",
        isTag: false,
        showArrow: true,
        allowClear: true,
        classNames: "eq-multiSelect eq-addSelect",
        ddClassName: "eq-select-dropdown",
        defaultSelectedValue: disciplineState.selectedDisciplines,
        selectedIcon: [
            <span className="">
                <img alt="" src="/check-square.svg" />
            </span>,
        ],
        options: disciplineState.allDisciplines.map((filter: any) => ({
            key: filter.key,
            value: t(filter.value), // Translate the filter label
        })),
        changeFunc: (param: any) => {
            const selectedDiscips = disciplineState.allDisciplines.filter((d: any) =>
                param.includes(d.key)
            );
            setDisciplineState({
                ...disciplineState,
                disciplineTags: selectedDiscips,
                selectedDisciplines: param,
            });
        },
    };

    const trainingProps: ISelectProps = {
        mode: "multiple",
        isTag: false,
        showArrow: true,
        allowClear: true,
        classNames: "eq-multiSelect eq-addSelect",
        ddClassName: "eq-select-dropdown",
        defaultSelectedValue: trainingLevelState.selectedTrainingLevels,
        selectedIcon: [
            <span className="">
                <img alt="" src="/check-square.svg" />
            </span>,
        ],
        options: trainingLevelState.allTrainingLevels,
        changeFunc: (param: any) => {
            const selectedLevels = trainingLevelState.allTrainingLevels.filter((d: any) =>
                param.includes(d.key)
            );
            setTrainingLevelState({
                ...trainingLevelState,
                trainingLevelTags: selectedLevels,
            });
        },
    };

    const userProps: ISelectProps = {
        isTag: false,
        showSearch: true,
        showArrow: true,
        allowClear: true,
        classNames: "eq-Select eq-addSelect",
        ddClassName: "eq-select-dropdown",
        defaultSelectedValue: "",
        selectedIcon: [
            <span className="">
                <img alt="" src="/check-square.svg" />
            </span>,
        ],
        options: allUsers,
        label: "Select a user to edit",
        changeFunc: (param: any) => {
            if (param && param.length) {
                processProfile(Api.post("Profile/GetProfile", { email: param }));
            }
        },
    };

    const horseDobProps: IDatePickerOptions = {
        isSuffixIcon: true,
        isBordered: true,
        defaultValue: horseFormData?.dateOfBirth,
        placeHolder: t("dateofBirth"),
        classNames: "eq-event",
        onChangeFunc: (date: any, dateString: string) => {
            setHorseFormData({ ...horseFormData, dateOfBirth: moment(date) });
        },
    };

    const countryProps: ISelectProps = {
        isTag: false,
        placeholder: t("selectCountry"),
        defaultSelectedValue: selectedCountry,
        classNames: "extra-height",
        label: t("selectCountry"),
        options: countryOptions,
        changeFunc: (value: string) => {
            setSelectedCountry(value);
        },
    };

    const businessTypeProps: ISelectProps = {
        isTag: false,
        placeholder: t("selectBusinessType"),
        defaultSelectedValue: selectedBusinessType,
        classNames: "extra-height",
        label:  t("selectBusinessType"),
        options: businessTypeOptions,
        changeFunc: (value: string) => {
            setSelectedBusinessType(value);
        },
    };

    const handleCopyLink = () => {
        messageApi.open({
            type: "success",
            content: "Copied",
        });
    };

    const deleteUserAccount = () => {
        let path = `userAccount/DeleteUser`;

        Api.delete(path)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    // Provide feedback to the user about the successful deletion

                    openNotificationWithIcon("Success", "User account deleted successfully.");
                    setTimeout(() => navigate(FrontEndPoints.login), 3000);

                    // Redirect the user to the desired location (if needed)
                    // window.location.href = "/";
                } else if (response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., redirect to login)
                    openNotificationWithIcon(
                        "Unauthorized",
                        "You are not authorized to perform this action."
                    );
                } else {
                    // Other status codes, handle accordingly
                    openNotificationWithIcon(
                        "Server error",
                        "Error occurred. Please try again or contact the administrator."
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                openNotificationWithIcon(
                    "Network error",
                    "An error occurred while communicating with the server. Please check your internet connection."
                );
            });
    };

    React.useEffect(() => {
        createCountryOptions();
        createBusinessTypeOptions();
        createToggleFilters();

        if (!userDetails.isSubscribed) {
            setTabKey("8");
        }

        if (ValidateIsAdmin(userDetails)) {
            Api.get("Home/GetAllUsers").then(
                (response) => {
                    setAllUsers(response.data);
                },
                (error) => {
                    setIsError(true);
                }
            );
        } else {
            processProfile(Api.get("Profile/GetProfile"));
        }
        GetStripeAccount();
        GetIntegrationInfo();
    }, []);

    const GetStripeAccount = () => {
        Api.get(ApiEndpoints.StripeConnectAccount.Get).then(
            (response) => {
                if (response.status == 200) {
                    setPaymentInfo(response.data);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const addAccount = () => {
        let path =
            ApiEndpoints.StripeConnectAccount.Create +
            `?country=${selectedCountry}&businessType=${selectedBusinessType}`;
        Api.get(path)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    window.location = response.data;
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const CompleteAccount = () => {
        Api.get("Payment/CreateConnectedAccount").then(
            (response) => {
                console.log(response, response.data);
                if (response.status == 200) {
                    window.location = response.data;
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const deleteAccount = () => {
        Api.get("Payment/DeleteConnectedAccount").then(
            (response) => {
                if (response.status == 200) {
                }
            },
            (error) => {}
        );
    };
    const processProfile = (param: Promise<AxiosResponse<any>>) => {
        param.then(
            (response) => {
                profileData = { ...response.data };
                //console.log(response.data);
                var userDate = { ...response.data.user };
                userDate.dateOfBirth = userDate.dateOfBirth ? moment(userDate.dateOfBirth) : null;
                setUser({ ...userDate });

                setAddLocationProps({
                    ...addLocationProps,
                    email: response.data.user.email,
                });

                const selLocs = [] as any;
                const mapLocs = [] as any;
                response?.data?.user?.locations?.filter((x: any) => {
                    if (x.location?.type == EqLocationType.Work) {
                        selLocs.push(x.location?.key?.toString());
                        mapLocs.push(x.location);
                    }
                });
                const locTags = [
                    ...response.data?.locations?.filter((d: any) => selLocs.includes(d.key)),
                ];
                setLocationState({
                    allLocations: [...response.data?.locations],
                    selectedLocations: selLocs,
                    locationTags: locTags,
                    allMapLocations: [...response.data?.allLocations],
                    selectedMapLocations: mapLocs,
                });
                // remove open from disciplines
                const disciplines = response.data?.disciplines.filter(
                    (d: any) => d.value != EqDisciplineType[EqDisciplineType.Open]
                );

                const selDicps =
                    [
                        ...response?.data?.user?.disciplines?.map((d: any) =>
                            d.discipline?.type?.toString()
                        ),
                    ] || [];
                const dispTags =
                    [...response.data?.disciplines?.filter((d: any) => selDicps.includes(d.key))] ||
                    [];
                setDisciplineState({
                    allDisciplines: [...disciplines],
                    selectedDisciplines: selDicps,
                    disciplineTags: dispTags,
                });

                const selLvls = [
                    ...response?.data?.user?.trainingLevels?.map((d: any) =>
                        d.trainingLevel?.key?.toString()
                    ),
                ];
                const lvlTags = [
                    ...response?.data?.trainingLevels?.filter((d: any) => selLvls.includes(d.key)),
                ];

                setTrainingLevelState({
                    allTrainingLevels: [...response.data?.trainingLevels],
                    selectedTrainingLevels: selLvls,
                    trainingLevelTags: lvlTags,
                });

                if (response.data?.homeLocation) {
                    setHomeLocation(response.data.homeLocation);
                }
                if (response.data?.user?.horses) {
                    setHorsesList(response.data.user.horses);
                }
            },
            (error) => {}
        );
    };

    const changeLocationView = () => {
        setAddLocationProps({
            ...addLocationProps,
            visible: true,
            email: user.email,
            okClicked: newLocationAdded,
        });
    };

    const handleChangeTextEditor = (value: any) => {
        setValue(value);
    };

    const saveProfile = () => {
        const userData = { ...user };
        userData.dateOfBirth = moment(user.dateOfBirth, dateFormat).utc().valueOf();
        const userProfile = {
            user: userData,
            homeLocation: homeLocation,
            locations: locationState.locationTags,
            trainingLevels: trainingLevelState.trainingLevelTags,
            disciplines: disciplineState.disciplineTags,
        };

        setIsSuccess(false);
        setIsError(false);

        Api.post("Profile/UpdateProfile", userProfile).then(
            (response) => {
                setIsSuccess(true);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });

                if (location.state) {
                    if (location.state.prevPath == FrontEndPoints.trainer.trainings) {
                        navigate(FrontEndPoints.trainer.trainings, {
                            state: { prevPath: location.pathname },
                        });
                    }
                }
            },
            (error) => {
                setIsError(true);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            }
        );
    };

    const getBase64 = (img: any) => {
        setLoading(true);
        new Compressor(img, {
            quality: 0.7,
            success: (compressedResult) => {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    var srcStr = reader.result as any;
                    var apiImgSrc = srcStr.substr(srcStr.indexOf(",") + 1);

                    var form = new FormData();
                    form.append("imageFile", img);
                    form.append("imageSrc", apiImgSrc);
                    form.append("imageName", img.name);

                    setIsError(false);

                    Api.post("image/uploadUserImage", form).then(
                        (response) => {
                            // setImageUrl(dispatch, response?.data?.url);
                            updateImage(dispatch, response?.data?.url);
                            setLoading(false);
                        },
                        (error) => {
                            setLoading(false);
                            setIsError(true);
                        }
                    );

                    //updateImage(dispatch, reader.result);
                });
                reader.readAsDataURL(compressedResult);
            },
        });
    };

    const beforeUpload = (file: any) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = (info: any) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.originFileObj) {
            getBase64(info.file.originFileObj);
        } else {
            getBase64(info.file);
        }
    };

    const handleChangeHorseUpload = (info: any) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.originFileObj) {
            getBase64Horse(info.file.originFileObj);
        } else {
            getBase64Horse(info.file);
        }
    };

    const getBase64Horse = (img: any) => {
        setLoading(true);
        new Compressor(img, {
            quality: 0.7,
            success: (compressedResult) => {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    var srcStr = reader.result as any;
                    var apiImgSrc = srcStr.substr(srcStr.indexOf(",") + 1);

                    var form = new FormData();
                    form.append("imageFile", img);
                    form.append("imageSrc", apiImgSrc);
                    form.append("imageName", img.name);

                    setIsError(false);

                    Api.post("image/UploadImage", form).then(
                        (response) => {
                            setImageUrl(response?.data?.url);
                            setHorseFormData({
                                ...horseFormData,
                                imageUrl: response?.data?.url,
                            });
                            setLoading(false);
                        },
                        (error) => {
                            setLoading(false);
                            setIsError(true);
                        }
                    );

                    //updateImage(dispatch, reader.result);
                });
                reader.readAsDataURL(compressedResult);
            },
        });
    };

    const removeHorse = (data: any) => {
        Api.delete(ApiEndpoints.DeleteHorse + `/${data.id}`).then(
            (response) => {
                if (response && response.status == 200) {
                    removeItem(data);
                } else {
                    setIsError(true);
                    // setErrorMessage(GetEventMessage(response.status));
                }
            },
            (error) => {
                setIsError(true);
                // setErrorMessage(GetEventMessage(error.response.status));
            }
        );
    };
    const GetIntegrationInfo = () => {
        Api.get(ApiEndpoints.IntegrationPartner).then(
            (response) => {
                if (response && response?.status === 200) {
                    setIntegrationInfo(response?.data);
                }
            },
            (error) => {
                // setIsError(true);
            }
        );
    };
    const removeItem = (data: any) => {
        if (horsesList != undefined) {
            var updatedList =
                horsesList != undefined
                    ? horsesList.filter((item: any) => item.id !== data.id)
                    : new Array<any>();
            setHorsesList(updatedList);

            // horsesList.map((item: any, index: number) => {
            //   item.display = index == 0 ? true : false;
            // });
        }
    };

    const handleSaveHorse = () => {
        let validate = validateData(horseFormData);
        if (validate == true) {
            const formData = {
                ...horseFormData,
                dateOfBirth: moment(horseFormData.dateOfBirth, dateFormat).utc().valueOf(),
            };
            console.log(formData);

            if (formData.dateOfBirth) {
                Api.post(ApiEndpoints.CreateHorse, formData).then(
                    (response) => {
                        if (response && response.status == 200) {
                            openNotificationWithIcon(
                                "Created!",
                                "Horse have been Added",
                                eqNotificationTypes.success
                            );
                            setIsError(false);

                            setHorseFormData({ ...horseInitial });

                            const list = [...horsesList];
                            list.push(response.data);

                            setHorsesList(list);
                            setImageUrl("");
                        } else {
                            openNotificationWithIcon(
                                "Server error",
                                "Error occurred while creating Horse. Please try again or contact administrator."
                            );
                            setIsError(true);
                        }
                    },
                    (error) => {
                        if (error?.response?.status === 403) {
                            openNotificationWithIcon(
                                "Server error",
                                "Not Authorized Kindly contact administrator."
                            );
                            setIsError(true);
                        } else {
                            openNotificationWithIcon(
                                "Server error",
                                "Error occurred while creating the Horse. Please try again or contact administrator."
                            );
                            setIsError(true);
                        }
                    }
                );
            } else {
                setIsError(true);
            }
        }
    };

    const openNotificationWithIcon = (msg: any, desc: any, type = eqNotificationTypes.error) => {
        if (type == eqNotificationTypes.success) {
            notification["success"]({
                message: msg,
                description: desc,
            });
        } else if (type == eqNotificationTypes.info) {
            notification["info"]({
                message: msg,
                description: desc,
            });
        } else {
            notification["error"]({
                message: msg,
                description: desc,
            });
        }
    };
    const validateData = (data: any): boolean => {
        let isComplete = true;
        const msg = "Incomplete data";
        const desc = " is empty.";
        if (data.name == "") {
            openNotificationWithIcon(msg, "Name" + desc);
            isComplete = false;
        } else if (!data.dateOfBirth) {
            openNotificationWithIcon(msg, "Date Of Birth" + desc);
            isComplete = false;
        } else if (ImageUrl == "") {
            openNotificationWithIcon(msg, "Image" + desc);
            isComplete = false;
            // } else if (uiImage == null || Object.keys(uiImage).length === 0) {
            //   openNotificationWithIcon(msg, "Image" + desc);
            //   isComplete = false;
        }

        return isComplete;
    };

    const onNameChange = (e: any) => {
        let name = e.target.value;
        let firstName = name.split(" ").shift();
        let lastName = name.slice(firstName.length + 1);
        setUser({ ...user, firstName: firstName, lastName: lastName });
    };
    //
    // const togglerProps: IEqToggle = {
    //   items: [
    //     {
    //       key: genderType.Stallion.toString(),
    //       value: [<span className="hidden-sm">Stallion</span>],

    //     },
    //     {
    //       key: genderType.Gelding.toString(),
    //       value: [<span className="hidden-sm">Gelding</span>],

    //     },
    //     {
    //       key: genderType.Mare.toString(),
    //       value: [<span className="hidden-sm">Mare</span>],

    //     },

    //   ],
    // };

    // Toggle Filters
    const createToggleFilters = () => {
        var filters = [];
        for (var x in genderType) {
            if (isNaN(Number(x)) == false) {
                filters.push({ key: Number(x), value: genderType[x] });
            }
        }
        setToggleFilters(filters);
        setSelectedToggle(filters[0]);
    };

    const togglerProps: IEqToggle = {
        items: toggleFilters.map((filter: any) => ({
            key: t(filter.key),
            value: t(filter.value), // Translate the filter label
        })),
        defaultSelectedValue: selectedToggle,
        classNames: "eq-toggle-orange-border eq-toggle-create-event",
        changeFunc: (value: string) => {
            // console.log(filteredTrainingsList);
            let filter: number = +value;
            setSelectedToggle(filter);

            if (filter === genderType.Stallion) {
                setHorseFormData({ ...horseFormData, type: filter });
                // setSelectedTrainingsList(filteredTrainingsList.available);
            } else if (filter === genderType.Gelding) {
                setHorseFormData({ ...horseFormData, type: filter });

                // setSelectedTrainingsList(filteredTrainingsList.joined);
            } else if (filter === genderType.Mare) {
                setHorseFormData({ ...horseFormData, type: filter });

                // setSelectedTrainingsList(filteredTrainingsList.previous);
            }
        },
    };

    return (
        <AnimationDiv>
            <>
                {<EqLocation {...addLocationProps}></EqLocation>}
                <div className="eq-board-container">
                    <div className="container">
                        <Row className="eq-board-heading">
                            <Col className="eq-board-col" flex="auto">
                                {isStudent ? t("myProfile") : t("myProfessionalProfile")}
                            </Col>
											{/* <Col className="eq-board-col" flex="auto">
							<Row className="eq-board-header" justify="end" align="bottom">
								<Col>
								<EqButton
									type="button"
									bordered="true"
									classNames="btn-border-text-orange"
									onClick={saveProfile}
								>
									Save Changes
								</EqButton>
								</Col>
							</Row>
							</Col> */}
                        </Row>
                        <Row className="eq-board-line"></Row>
                        <Row>
                            <Col>
                                <div className="feedback">
                                    {isError && (
                                        <Alert
                                            message="Error"
                                            description={errorText}
                                            type="error"
                                            showIcon
                                            banner
                                        />
                                    )}
                                    {isSuccess && (
                                        <Alert
                                            message="Success"
                                            type="success"
                                            description={successText}
                                            showIcon
                                            banner
                                        />
                                    )}
                                </div>
                            </Col>
                        </Row>
                        {ValidateIsAdmin(userDetails) && (
                            <Row>
                                <Col flex="auto">
                                    <EqSelect {...userProps}></EqSelect>
                                </Col>
                            </Row>
                        )}
                        <div className="profile-section">
                            <Row align="middle" className="">
                                <Col span={24}>
                                    <Tabs
                                        defaultActiveKey="1"
                                        activeKey={tabKey}
                                        // onChange={onChange}
                                        onChange={(key) => {
                                            setTabKey(key);
                                            // navigate(`?tab=${key}`);
                                        }}
                                    >
                                        <TabPane tab={t("personal")} key="1">
                                            <RevealList>
                                                <div className="my-details">
                                                    <Row>
                                                        <Col span={24}>
                                                            <div className="top-section">
                                                                <h3 className="section-heading my-details-heading">
                                                                    {t("myDetails")}
                                                                </h3>

                                                                <Upload
                                                                    name="avatar"
                                                                    listType="picture-card"
                                                                    className="avatar-uploader"
                                                                    showUploadList={false}
                                                                    onChange={handleChange}
                                                                >
                                                                    <div>
                                                                        {loading ? (
                                                                            <LoadingOutlined />
                                                                        ) : (
                                                                            <PlusOutlined />
                                                                        )}
                                                                        <div
                                                                            style={{
                                                                                marginTop: 0,
                                                                            }}
                                                                        >
                                                                            {t("picture")}
                                                                        </div>
                                                                    </div>
                                                                </Upload>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row align="middle" className="input-row">
                                                    <Col
                                                            md={12}
                                                            xs={24}
                                                            sm={24}
                                                            className="col-left mb-16"
                                                        >
                                                            <EqInput
                                                                label={t("companyName")}
                                                                className="profile-input"
                                                                value={
                                                                    user.companyName
                                                                }
                                                                placeholder=""
                                                                // onChange={onNameChange}
                                                                onChange={(e: any) =>
                                                                    setUser({
                                                                        ...user,
                                                                        companyName: e.target.value,
                                                                    })
                                                                }
                                                            ></EqInput>
                                                        </Col>

                                                        <Col
                                                            md={12}
                                                            xs={24}
                                                            sm={24}
                                                            className="mb-16"
                                                        >
                                                            <EqInput
                                                                label={t("schoolName")}
                                                                className="profile-input"
                                                                onChange={(e: any) =>
                                                                    setUser({
                                                                        ...user,
                                                                        schoolName: e.target.value,
                                                                    })
                                                                }
                                                                
                                                                value={user.schoolName}
                                                                placeholder=""
                                                            ></EqInput>
                                                        </Col>
                                                        
                                                        <Col
                                                            md={12}
                                                            xs={24}
                                                            sm={24}
                                                            className="col-left mb-16"
                                                        >
                                                            <EqInput
                                                                label={t("name")}
                                                                className="profile-input"
                                                                value={
                                                                    user.firstName +
                                                                    " " +
                                                                    (user.lastName
                                                                        ? user.lastName
                                                                        : "")
                                                                }
                                                                placeholder=""
                                                                onChange={onNameChange}
                                                            ></EqInput>
                                                        </Col>

                                                        <Col
                                                            md={12}
                                                            xs={24}
                                                            sm={24}
                                                            className="mb-16"
                                                        >
                                                            <EqInput
                                                                label={t("e-mail")}
                                                                className="profile-input"
                                                                onChange={(e: any) =>
                                                                    setUser({
                                                                        ...user,
                                                                        email: e.target.value,
                                                                    })
                                                                }
                                                                disabled={true}
                                                                value={user.email}
                                                                placeholder=""
                                                            ></EqInput>
                                                        </Col>
                                                        <Col
                                                            md={12}
                                                            xs={24}
                                                            sm={24}
                                                            className="col-left mb-16"
                                                        >
                                                            <EqInput
                                                                label={t("phoneNumber")}
                                                                className="profile-input"
                                                                onChange={(e: any) =>
                                                                    setUser({
                                                                        ...user,
                                                                        phoneNumber: e.target.value,
                                                                    })
                                                                }
                                                                value={user.phoneNumber}
                                                                placeholder=""
                                                            ></EqInput>
                                                        </Col>
                                                        {/*location*/}
                                                        <Col
                                                            md={12}
                                                            xs={24}
                                                            sm={24}
                                                            className="mb-16"
                                                        >
                                                            <EqInput
                                                                label={t("address")}
                                                                className="profile-input"
                                                                onChange={(e: any) =>
                                                                    setHomeLocation({
                                                                        ...homeLocation,
                                                                        street: e.target.value,
                                                                    })
                                                                }
                                                                value={homeLocation?.street}
                                                                placeholder=""
                                                            ></EqInput>
                                                        </Col>
                                                        <Col
                                                            md={12}
                                                            xs={24}
                                                            sm={24}
                                                            className="col-left mb-16"
                                                        >
                                                            <EqInput
                                                                label={t("city")}
                                                                className="profile-input"
                                                                onChange={(e: any) =>
                                                                    setHomeLocation({
                                                                        ...homeLocation,
                                                                        city: e.target.value,
                                                                    })
                                                                }
                                                                value={homeLocation?.city}
                                                                placeholder=""
                                                            ></EqInput>
                                                        </Col>
                                                        <Col
                                                            md={12}
                                                            xs={24}
                                                            sm={24}
                                                            className="mb-16"
                                                        >
                                                            <EqInput
                                                                label={t("zip")}
                                                                className="profile-input"
                                                                onChange={(e: any) =>
                                                                    setHomeLocation({
                                                                        ...homeLocation,
                                                                        zipCode: e.target.value,
                                                                    })
                                                                }
                                                                value={homeLocation?.zipCode}
                                                                placeholder=""
                                                            ></EqInput>
                                                        </Col>

                                                        {/*DOB*/}

                                                        {(isParent || isStudent) && (
                                                            <>
                                                                <Col
                                                                    md={12}
                                                                    xs={24}
                                                                    sm={24}
                                                                    className="col-left mb-16"
                                                                >
                                                                    <p className="label-left">
                                                                        {t("dateofBirth")}
                                                                    </p>

                                                                    <EqDatePicker
                                                                        // isDisabled={isStudent}
                                                                        {...dobProps}
                                                                    ></EqDatePicker>
                                                                </Col>
                                                            </>
                                                        )}

                                                        {/*Checks*/}

                                                        {isParent && (
                                                            <>
                                                                <Col
                                                                    className="col-left mb-16"
                                                                    span={24}
                                                                >
                                                                    {/* <Row className="mb-16">
                                <Checkbox
                                  className="label"
                                  checked={user.isUnderAge}
                                  onChange={(e: any) => {
                                    
                                    setUser({
                                      ...user,
                                      isUnderAge: e.target.checked,
                                    });
                                  }}
                                >
                                  I am under 18
                                </Checkbox>
                              </Row> */}
                                                                </Col>
                                                            </>
                                                        )}

                                                        {/*About me*/}
                                                        <Col span={24} className="">
                                                            <div className="about-my-self-heading">
                                                                {t("about")}
                                                            </div>
                                                        </Col>
                                                        <Col span={24}>
                                                            <TextArea
                                                                rows={4}
                                                                onChange={(e: any) =>
                                                                    setUser({
                                                                        ...user,
                                                                        aboutMe: e.target.value,
                                                                    })
                                                                }
                                                                value={user.aboutMe}
                                                                className="profile-input text-area"
                                                            ></TextArea>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </RevealList>
                                        </TabPane>

                                        {isParent && (
                                            <TabPane tab={t("parentalControl")} key="2">
                                                <RevealList>
                                                    <div className="my-details">
                                                        <Row align="middle" className="input-row">
                                                            {/*Checks*/}

                                                            <>
                                                                <Col
                                                                    className="col-left mb-16"
                                                                    span={24}
                                                                >
                                                                    {/* <Row className="mb-16">
                                <Checkbox
                                  className="label"
                                  checked={user.isUnderAge}
                                  onChange={(e: any) => {
                                    
                                    setUser({
                                      ...user,
                                      isUnderAge: e.target.checked,
                                    });
                                  }}
                                >
                                  I am under 18
                                </Checkbox>
                              </Row> */}
                                                                    <Row className="mb-16">
                                                                        <Checkbox
                                                                            className="label"
                                                                            checked={
                                                                                user.canHandleBooking
                                                                            }
                                                                            onChange={(e: any) => {
                                                                                setUser({
                                                                                    ...user,
                                                                                    canHandleBooking:
                                                                                        e.target
                                                                                            .checked,
                                                                                    canHandlePayment:
                                                                                        e.target
                                                                                            .checked,
                                                                                });
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                "childCanHandleBooking"
                                                                            )}
                                                                        </Checkbox>
                                                                    </Row>
                                                                    {user.canHandleBooking ? (
                                                                        <Row className="mb-16">
                                                                            <Checkbox
                                                                                className="label"
                                                                                checked={
                                                                                    user.canHandlePayment
                                                                                }
                                                                                onChange={(
                                                                                    e: any
                                                                                ) => {
                                                                                    setUser({
                                                                                        ...user,
                                                                                        canHandlePayment:
                                                                                            e.target
                                                                                                .checked,
                                                                                    });
                                                                                }}
                                                                            >
                                                                                {t(
                                                                                    "childCanHandlePayment"
                                                                                )}
                                                                            </Checkbox>
                                                                        </Row>
                                                                    ) : (
                                                                        ""
                                                                    )}

                                                                    {!user.hasCard ? (
                                                                        <div className="">
                                                                            {!addStripCard ? (
                                                                                <EqButton
                                                                                    type="button"
                                                                                    bordered="true"
                                                                                    onClick={() =>
                                                                                        setAddStripCard(
                                                                                            true
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {t("addCard")}
                                                                                </EqButton>
                                                                            ) : (
                                                                                // <Row>
                                                                                //   <Col xs={22} md={20} xl={18}>
                                                                                <Elements
                                                                                    stripe={
                                                                                        stripeTestPromise
                                                                                    }
                                                                                >
                                                                                    <EqStripeCardForm
                                                                                        isEdit={
                                                                                            false
                                                                                        }
                                                                                        onCreate={() => {
                                                                                            setAddStripCard(
                                                                                                false
                                                                                            );
                                                                                            setUser(
                                                                                                {
                                                                                                    ...user,
                                                                                                    hasCard:
                                                                                                        true,
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                        // onCancel={() => setAddStripCard(false)}
                                                                                    ></EqStripeCardForm>
                                                                                </Elements>
                                                                                //    </Col>
                                                                                //  </Row>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            {!changeStripCard ? (
                                                                                <EqButton
                                                                                    type="button"
                                                                                    bordered="true"
                                                                                    onClick={() =>
                                                                                        setChangeStripCard(
                                                                                            true
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {t(
                                                                                        "changeCard"
                                                                                    )}
                                                                                </EqButton>
                                                                            ) : (
                                                                                <Elements
                                                                                    stripe={
                                                                                        stripeTestPromise
                                                                                    }
                                                                                >
                                                                                    <EqStripeCardForm
                                                                                        isEdit={
                                                                                            true
                                                                                        }
                                                                                        onCreate={() =>
                                                                                            setChangeStripCard(
                                                                                                false
                                                                                            )
                                                                                        }
                                                                                        // onCancel={() => setChangeStripCard(false)}
                                                                                    ></EqStripeCardForm>
                                                                                </Elements>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </Col>
                                                            </>
                                                        </Row>
                                                    </div>
                                                </RevealList>
                                            </TabPane>
                                        )}
                                        <TabPane tab={t("settings")} key="3">
                                            {/*parent*/}
                                            <RevealList>
                                                <>
                                                    {isStudent && user.isUnderAge && (
                                                        <div className="profile-section parent-section">
                                                            <EqAddParent />
                                                        </div>
                                                    )}

                                                    {/*Disciplines*/}
                                                    <div className="profile-section discipline">
                                                        <Row>
                                                            <Col>
                                                                <h3 className="section-heading">
                                                                    {t("discipline")}
                                                                </h3>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {disciplineState?.disciplineTags?.map(
                                                                (item: any, index: number) => (
                                                                    <Col
                                                                        className=""
                                                                        key={item.key}
                                                                    >
                                                                        <EqTags
                                                                            className=""
                                                                            text={t(item.value)}
                                                                        ></EqTags>
                                                                    </Col>
                                                                )
                                                            )}
                                                        </Row>
                                                        <Row>
                                                            <Col flex="auto">
                                                                <EqSelect
                                                                    extraHeight={true}
                                                                    {...disciplineProps}
                                                                ></EqSelect>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    {/*Training levels*/}
                                                    {/* <div className="profile-section offered-trainings">
                                            <Row>
                                                <Col>
                                                    <h3 className="section-heading">
                                                        Offered Trainings
                                                    </h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {trainingLevelState?.trainingLevelTags?.map((item: any, index: number) =>
                                                    <Col className="eq-pl-discipline-list" key={item.key}>
                                                        <span className="eq-pl-discipline-list-active">{item.value}</span>
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row>
                                                <Col flex="auto">
                                                <EqSelect extraHeight={true} {...trainingProps} ></EqSelect>
                                                </Col>
                                                </Row>
                                              </div> */}

                                                    {/*Payments*/}
                                                    {!isStudent && (
                                                        <div className="profile-section payment-section">
                                                            <div>
                                                                <Row>
                                                                    {/* <h3 >Payment</h3> */}
                                                                    <h3 className="section-heading">
                                                                        {t("payment")}
                                                                    </h3>
                                                                </Row>
                                                                <Row>
                                                                    <span className="sub-head">
                                                                        {latePayment.allow
                                                                            ? "*Allowing late payments means that you allow your students to pay for their lesson after the lesson"
                                                                            : t(
                                                                                  "studentsAcceptsTrainingInvitations"
                                                                              )}
                                                                    </span>
                                                                </Row>
                                                            </div>

                                                            {
                                                                paymentInfo &&
                                                                    paymentInfo?.status ===
                                                                        EqPaymentAccountStatus.Complete && (
                                                                        <div className="account-info">
                                                                            <Row>
                                                                                <Col
                                                                                    xs={12}
                                                                                    md={4}
                                                                                    className="info-item"
                                                                                >
                                                                                    <label className="label">
                                                                                        {t(
                                                                                            "allowLatePayments"
                                                                                        )}
                                                                                    </label>
                                                                                </Col>
                                                                                <Col className="info-item">
                                                                                    <span className="value">
                                                                                        <Switch
                                                                                            checked={
                                                                                                user.allowLatePayments
                                                                                            }
                                                                                            onChange={(
                                                                                                checked: any
                                                                                            ) =>
                                                                                                setUser(
                                                                                                    {
                                                                                                        ...user,
                                                                                                        allowLatePayments:
                                                                                                            checked,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                            {user.allowLatePayments && (
                                                                                <Row>
                                                                                    <Col
                                                                                        xs={12}
                                                                                        md={4}
                                                                                        className="info-item"
                                                                                    >
                                                                                        <label className="label">
                                                                                            {t(
                                                                                                "PaymentTerms"
                                                                                            )}
                                                                                        </label>
                                                                                    </Col>
                                                                                    <Col className="info-item">
                                                                                        <span className="value">
                                                                                            <EqInput
                                                                                                className="profile-input"
                                                                                                onChange={(
                                                                                                    e: any
                                                                                                ) =>
                                                                                                    setUser(
                                                                                                        {
                                                                                                            ...user,
                                                                                                            paymentDays:
                                                                                                                e
                                                                                                                    .target
                                                                                                                    .value,
                                                                                                        }
                                                                                                    )
                                                                                                }
                                                                                                value={
                                                                                                    user?.paymentDays
                                                                                                }
                                                                                                placeholder=""
                                                                                            ></EqInput>
                                                                                        </span>
                                                                                    </Col>
                                                                                </Row>
                                                                            )}
                                                                            <Row className="info-item">
                                                                                <h3 className="sub-heading">
                                                                                    {t(
                                                                                        "cancelDeadline"
                                                                                    )}
                                                                                </h3>
                                                                            </Row>
                                                                            <Row>
                                                                                <span className="sub-head">
                                                                                    {t("settingsCancellationDeadlineText1")}
                                                                                    <br/>
                                                                                    {t("settingsCancellationDeadlineText2")}
                                                                                </span>
                                                                            </Row>
                                                                            <Radio.Group
                                                                                onChange={(
                                                                                    e: any
                                                                                ) => {
                                                                                    setUser({
                                                                                        ...user,
                                                                                        cancellationDeadline:
                                                                                            e.target
                                                                                                .value,
                                                                                    });
                                                                                }}
                                                                                name="radiogroup"
                                                                                defaultValue={
                                                                                    user.cancellationDeadline
                                                                                }
                                                                                value={
                                                                                    user.cancellationDeadline
                                                                                }
                                                                            >
                                                                                <Radio value={0}>
                                                                                    {" "}
                                                                                    {t("none")}
                                                                                </Radio>
                                                                                <Radio value={5}>
                                                                                    5 {t("hours")}
                                                                                </Radio>
                                                                                <Radio value={12}>
                                                                                    12 {t("hours")}
                                                                                </Radio>
                                                                                <Radio value={24}>
                                                                                    24 {t("hours")}
                                                                                </Radio>
                                                                                <Radio value={48}>
                                                                                    48 {t("hours")}
                                                                                </Radio>
                                                                            </Radio.Group>

                                                                            {user.cancellationDeadline &&
                                                                            user.cancellationDeadline !==
                                                                                0 ? (
                                                                                <div
                                                                                    style={{
                                                                                        marginTop: 20,
                                                                                    }}
                                                                                >
                                                                                    <h3 className="sub-heading">
                                                                                        {t("selectRefundOptionForTraining")}
                                                                                    </h3>
                                                                                    <Radio.Group
                                                                                        onChange={(
                                                                                            e: any
                                                                                        ) => {
                                                                                            setUser(
                                                                                                {
                                                                                                    ...user,
                                                                                                    refundType:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                        value={
                                                                                            user.refundType
                                                                                        }
                                                                                    >
                                                                                        <Space direction="vertical">
                                                                                            <Radio
                                                                                                value={EqRefundType.NoRefund}
                                                                                            >
                                                                                                {t("noRefund")}
                                                                                            </Radio>
                                                                                            <Radio
                                                                                                value={
                                                                                                    EqRefundType.Refund
                                                                                                }
                                                                                            >
                                                                                                {t("refund")}
                                                                                            </Radio>
                                                                                            <Radio
                                                                                                value={
                                                                                                    EqRefundType.Credit
                                                                                                }
                                                                                            >
                                                                                                {t("credit")}
                                                                                            </Radio>
                                                                                        </Space>
                                                                                    </Radio.Group>
                                                                                    {isSchool ? <>
																						<Row
                                                                                        style={{
                                                                                            marginTop: 20,
                                                                                        }}
                                                                                        className="info-item"
                                                                                    >
                                                                                        <h3 className="sub-heading">
                                                                                            {t("ridingCourseCancellation")}
                                                                                        </h3>
                                                                                    </Row>

                                                                                    <Radio.Group
                                                                                        onChange={(
                                                                                            e: any
                                                                                        ) => {
																							
                                                                                            setUser(
                                                                                                {...user, allowProductTrainingCancel:e.target.value}
                                                                                            );
                                                                                        }}
                                                                                        name="radiogroup"
                                                                                        defaultValue={
                                                                                            user.allowProductTrainingCancel
                                                                                        }
                                                                                        value={
                                                                                            user.allowProductTrainingCancel
                                                                                        }
                                                                                    >
                                                                                        <Radio
                                                                                            value={
                                                                                                true
                                                                                            }
                                                                                        >
                                                                                            {t("ridingCourseCancellationOption1")}
                                                                                        </Radio>
                                                                                        <Radio
                                                                                            value={
                                                                                                false
                                                                                            }
                                                                                        >
                                                                                            {t("ridingCourseCancellationOption2")}
                                                                                        </Radio>
                                                                                    </Radio.Group>
																					</>:""}
                                     

                                                                                    {isSchool &&
                                                                                    user.allowProductTrainingCancel &&
                                                                                    user.allowProductTrainingCancel === true ? (
                                                                                        <div
                                                                                            style={{
                                                                                                marginTop: 20,
                                                                                            }}
                                                                                        >
                                                                                            <h3 className="sub-heading">
                                                                                                {t("selectRefundOptionForRidingCourse")}
                                                                                            </h3>
                                                                                            <Radio.Group
                                                                                                onChange={(
                                                                                                    e: any
                                                                                                ) => {
																							

                                                                                                    setUser(
                                                                                                        {
                                                                                                            ...user,
                                                                                                            productRefundType:
                                                                                                                e.target.value,
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                                value={
                                                                                                    user.productRefundType
                                                                                                }
                                                                                            >
                                                                                                <Space direction="vertical">
                                                                                                    <Radio
                                                                                                        value={
                                                                                                            EqRefundType.Refund
                                                                                                        }
                                                                                                    >
                                                                                                         {t("refundAmountOfEachLesson")}
                                                                                                       
                                                                                                    </Radio>
                                                                                                    <Radio
                                                                                                        value={
                                                                                                            EqRefundType.Credit
                                                                                                        }
                                                                                                    > {t("giveMyStudentsCredit")}
                                                                                                       
                                                                                                    </Radio>
                                                                                                </Space>
                                                                                            </Radio.Group>
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    )
                                                                //<div className="account-info">
                                                                //    <div className="info-item">
                                                                //        <label className="label">Balance</label>
                                                                //        <span className="value">{paymentInfo?.balance}</span>
                                                                //    </div>
                                                                //    <div className="info-item">
                                                                //        <label className="label">Currency</label>
                                                                //        <span className="value">{paymentInfo?.currency}</span>
                                                                //    </div>
                                                                //</div>
                                                            }
                                                            <Row className="bank-account">
                                                                {(!paymentInfo ||
                                                                    !paymentInfo.status) && (
                                                                    <>
                                                                        {!isAccountClicked ? (
                                                                            <EqButton
                                                                                type="button"
                                                                                bordered="true"
                                                                                onClick={() =>
                                                                                    setIsAccountClicked(
                                                                                        true
                                                                                    )
                                                                                }
                                                                            >
                                                                                {t("addAccount")}
                                                                            </EqButton>
                                                                        ) : (
                                                                            <>
                                                                                <Col>
                                                                                    <EqSelect
                                                                                        extraHeight={
                                                                                            true
                                                                                        }
                                                                                        {...businessTypeProps}
                                                                                    />
                                                                                </Col>
                                                                                <Col>
                                                                                    <EqSelect
                                                                                        extraHeight={
                                                                                            true
                                                                                        }
                                                                                        {...countryProps}
                                                                                    />
                                                                                </Col>

                                                                                <Col>
                                                                                    {selectedCountry !==
                                                                                    "" ? (
                                                                                        <EqButton
                                                                                            type="button"
                                                                                            bordered="true"
                                                                                            onClick={
                                                                                                addAccount
                                                                                            }
                                                                                            // onClick={addAccount}
                                                                                        >
                                                                                            {t(
                                                                                                "continue"
                                                                                            )}
                                                                                        </EqButton>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </Col>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {paymentInfo &&
                                                                    (paymentInfo?.status ==
                                                                        EqPaymentAccountStatus.Pending ||
                                                                        paymentInfo?.status ==
                                                                            EqPaymentAccountStatus.NotCompleted) && (
                                                                        <EqButton
                                                                            type="button"
                                                                            bordered="true"
                                                                            onClick={
                                                                                CompleteAccount
                                                                            }
                                                                        >
                                                                            {t(
                                                                                "completeAccountIntegration"
                                                                            )}
                                                                        </EqButton>
                                                                    )}
                                                                {paymentInfo &&
                                                                    paymentInfo.status && <></>}
                                                                {/*<EqButton type="button" bordered="true" onClick={deleteAccount}>*/}
                                                                {/*    Delete Account*/}
                                                                {/*</EqButton>                                        */}
                                                            </Row>
                                                        </div>
                                                    )}

                                                    {/*RegisterInviteLink*/}

                                                    {!isStudent && !isEventHolder && (
                                                        <div className="profile-section Payments">
                                                            <Row>
                                                                <Col>
                                                                    <h3 className="section-heading">
                                                                        {t("studentInvite")}
                                                                    </h3>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                {contextHolder}

                                                                <Col>
                                                                    <CopyToClipboard
                                                                        text={
                                                                            isTrainer? config.apiUrl + `newUser?trainerKey=${user.key}&profession=3`: config.apiUrl + `newUser?schoolKey=${user.key}&profession=3`
                                                                        }
                                                                        onCopy={handleCopyLink}
                                                                    >
                                                                        <EqInput
                                                                            classNames="eq-input-copy"
                                                                            readOnly
                                                                            //  disabled
                                                                            value={
                                                                                isTrainer? config.apiUrl + `newUser?trainerKey=${user.key}&profession=3`: config.apiUrl + `newUser?schoolKey=${user.key}&profession=3`

                                                                            }
                                                                            suffix={
                                                                                <CopyToClipboard
                                                                                    text={
                                                                                        isTrainer? config.apiUrl + `newUser?trainerKey=${user.key}&profession=3`: config.apiUrl + `newUser?schoolKey=${user.key}&profession=3`

                                                                                    }
                                                                                    onCopy={
                                                                                        handleCopyLink
                                                                                    }
                                                                                    // options={{message:"asdas"}}
                                                                                >
                                                                                    <CopyFilled />
                                                                                </CopyToClipboard>
                                                                            }
                                                                        />
                                                                    </CopyToClipboard>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                    {/*DeleteAccount*/}

                                                    {/* <div className="profile-section payment-section">
                            <div>
                              <Row>
                          
                                <h3 className="section-heading">
                                  {t("Delete Account")}
                                </h3>
                              </Row>
                              <Row>
                                <span className="sub-head">
                                  {t(
                                    "on click you will delete your account completely form app"
                                  )}
                                </span>
                              </Row>
                            </div>
                            <EqButton
                            type="button"
                            bordered="true"
                            onClick={deleteUserAccount}
                          >
                            {t("deleteAccount")}
                          </EqButton>
                          </div> */}
                                                </>
                                            </RevealList>
                                        </TabPane>
                                        {/*My locations*/}
                                        {!isStudent && (
                                            <TabPane tab={t("locations")} key="4">
                                                <RevealList>
                                                    <div className="profile-section locations">
                                                        <Row align="middle">
                                                            <Col flex="auto">
                                                                <h3 className="section-heading">
                                                                    {t("locations")}
                                                                </h3>
                                                            </Col>
                                                            <Col className="loc-icon">
                                                                <span className="">
                                                                    <img
                                                                        alt=""
                                                                        src="/chart-pie.svg"
                                                                    />
                                                                </span>
                                                            </Col>
                                                            <Col className="my-loc">
                                                                {t("myTrainingLocations")}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {locationState?.selectedMapLocations &&
                                                                locationState?.selectedMapLocations
                                                                    ?.length > 0 && (
                                                                    <>
                                                                        {isLoaded &&
                                                                            locationState?.selectedMapLocations.map(
                                                                                (
                                                                                    item: ILocationDto,
                                                                                    index: number
                                                                                ) => (
                                                                                    <>
                                                                                        <Col
                                                                                            xs={24}
                                                                                            sm={24}
                                                                                            md={12}
                                                                                        >
                                                                                            <Card className="eq-loc-map">
                                                                                                {item.locationImageUrl &&
                                                                                                item.locationImageUrl !=
                                                                                                    "" ? (
                                                                                                    <div>
                                                                                                        <img
                                                                                                            className="eq-loc-img"
                                                                                                            src={
                                                                                                                item.locationImageUrl
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div className="eq-loc-marker">
                                                                                                        <EqGoogleMap
                                                                                                            selected={{
                                                                                                                lat: item.latitude,
                                                                                                                lng: item.longitude,
                                                                                                            }}
                                                                                                            containerStyle={{
                                                                                                                height: "450px",
                                                                                                                width: "100%",
                                                                                                            }}
                                                                                                        ></EqGoogleMap>
                                                                                                    </div>
                                                                                                )}
                                                                                                <div className="eq-loc-heading1">
                                                                                                    {
                                                                                                        item.locationName
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="eq-loc-heading2">
                                                                                                    {
                                                                                                        locationState
                                                                                                            ?.locationTags[
                                                                                                            index
                                                                                                        ]
                                                                                                            ?.description
                                                                                                    }
                                                                                                </div>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </>
                                                                                )
                                                                            )}
                                                                    </>
                                                                )}
                                                        </Row>
                                                        <Row>
                                                            {locationState?.locationTags?.length >
                                                                0 && (
                                                                <>
                                                                    {locationState?.locationTags?.map(
                                                                        (
                                                                            item: any,
                                                                            index: number
                                                                        ) => (
                                                                            <>
                                                                                <Col
                                                                                    className="eq-pl-discipline-list"
                                                                                    key={item.key}
                                                                                >
                                                                                    <span className="eq-pl-discipline-list-active">
                                                                                        {
                                                                                            item.description
                                                                                        }
                                                                                    </span>
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    )}
                                                                </>
                                                            )}
                                                        </Row>

                                                        <Row>
                                                            <Col flex="auto">
                                                                <EqSelect
                                                                    {...locationProps}
                                                                    extraHeight={true}
                                                                ></EqSelect>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col flex="auto">
                                                                <h6>
                                                                    {t(
                                                                        "locationNotAvailableinDropdown"
                                                                    )}
                                                                </h6>
                                                                <EqButton
                                                                    type="button"
                                                                    bordered="true"
                                                                    onClick={changeLocationView}
                                                                    classNames="btn-dotted"
                                                                >
                                                                    + {t("addNewArea")}
                                                                </EqButton>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </RevealList>
                                            </TabPane>
                                        )}
                                        {/* Horse */}

                                        {/* Product */}
                                        {/* {!isStudent && !isEventHolder && (
                      <TabPane tab={t("products")} key="6">
                        <RevealList>
                          <div className="profile-section product-section">
                            <EqProductSection />
                          </div>
                        </RevealList>
                      </TabPane>
                    )} */}
                                        {!isStudent && (
                                            <TabPane tab={t("terms")} key="7">
                                                <RevealList>
                                                    <div className="profile-section product-section">
                                                        <h3 className="section-heading">
                                                            {t("termsAndConditions")}
                                                        </h3>

                                                        {/* <TextArea
                          rows={5}
                          value={user.trainerTerms}
                          onChange={(e: any) =>
                            setUser({ ...user, trainerTerms: e.target.value })
                          }
                        /> */}

                                                        <ReactQuill
                                                            theme="snow"
                                                            value={user.trainerTerms}
                                                            // onChange={}
                                                            onChange={(e: any) => {
                                                                setUser({
                                                                    ...user,
                                                                    trainerTerms: e,
                                                                });
                                                                handleChangeTextEditor(e);
                                                            }}
                                                        />
                                                    </div>
                                                </RevealList>
                                            </TabPane>
                                        )}
                                        {!isStudent && !isEventHolder  && (
                                            <TabPane tab={t("subscriptions")} key="8">
                                                <div className="profile-section subscription-section">
                                                    <EqSubscriptionsPlan />
                                                </div>
                                            </TabPane>
                                        )}
                                        {/* {isTrainer && ( */}
                                            <TabPane tab={t("integrations")} key="9">
                                                <EqIntegrationSection
                                                    integrationData={integrationInfo}
                                                />
                                            </TabPane>
                                        {/* )} */}
                                    </Tabs>
                                </Col>
                            </Row>
                            <Row>
                                <Col></Col>
                            </Row>
                        </div>
                        {tabKey !== "5" && tabKey !== "6" && tabKey !== "8" && (
                            <div className="save-changes">
                                <Row align="middle" justify="end">
                                    <Col>
                                        <EqButton
                                            type="button"
                                            onClick={saveProfile}
                                            classNames="btn-background-purple"
                                        >
                                            {t("saveChanges")}
                                        </EqButton>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </div>
                </div>
            </>
        </AnimationDiv>
    );
};
export default EditProfile;
