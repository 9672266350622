import * as React from 'react';
import { useEffect, useState } from 'react';
import { dateFormat, FrontEndPoints } from '../../shared/Common';
import "./EqAdminDashboard.scss";
import { Row, Col, Checkbox } from 'antd';
import EqButton from '../../custom/eqButton/EqButton';
import { useTranslation, Trans } from 'react-i18next';
import EqMessage, { IEqMessage, IEqMessageData } from '../../eqTrainer/eqMessage/EqMessage';
import {  } from '../eqTrainer/EqTrainer';
import EqApproveTrainer, {  } from '../eqApproveTrainer/EqApproveTrainer';
import { Link } from 'react-router-dom';
import Api from '../../shared/Api';
import { EqMessageType, EqLocationType } from '../../shared/enums';
import { ApiEndpoints } from '../../shared/Common';
import { IProfileDto } from '../../shared/interfaces/IProfileDto';
import { useAuthState } from '../../context/Context';


const EqAdminDashboard = (props: any) => {
    const userDetails: any = useAuthState();
    const { t, i18n } = useTranslation();
    const [messageString, setMessageString] = useState('');
    const [misCheckAll, msetIsCheckAll] = useState(false);
    const [fisCheckAll, fsetIsCheckAll] = useState(false);
    const [messages, setMessages] = useState([] as Array<any>);
    const [trainersList, setTrainersList] = React.useState([]) as any;
    // const [trainersList, setTrainersList] = React.useState([] as Array<any>);
    const [keys, setKeys] = useState(new Array<any>());
    var selectedMessages = new Array<any>();

    const replyEmail = (message: IEqMessageData) => {
        GetMessages();
    }
    const mardFlagedEmail = (message: IEqMessageData) => {
        if (message != null) {
            Api.post(ApiEndpoints.saveMessage, { Message: { Key: message.key }, IsFlaged: message.isFlaged, IsRead: message.isRead }).then(response => {

                if (response && response.status == 200) {
                    return true;
                } else {
                    return false;
                }
            }, err => {
                return false;
            });
        } else {
            return false;
        }
    }
    const deleteEmail = (message: IEqMessageData) => {
        
        if (message != null) {
            Api.post(ApiEndpoints.deleteMessage, { Message: { Key: message.key } }).then(response => {

                if (response && response.status == 200) {
                    GetMessages();
                    return true;
                } else {
                    return false;
                }
            }, err => {
                return false;
            });
        } else {
            return false;
        }
    }
    const onCheckChange = (message: IEqMessageData, checked: boolean) => {
        if (checked) {
            selectedMessages.push({ Key: message.key });
        }
        else {
            if (selectedMessages != null && selectedMessages.length > 0) {
                const index = selectedMessages.findIndex(x => x.Key == message.key);
                if (index > -1) {
                    selectedMessages.splice(index, 1);
                }
            }
        }
        setKeys(selectedMessages);
    }
    const deleteSelectedMessages = () => {
        
        if (keys?.length > 0) {
            Api.post(ApiEndpoints.deleteSelectedMessages, keys).then(response => {
                if (response && response.status == 200) {
                    GetMessages();

                    return true;
                } else {
                    return false;
                }
            }, err => {
                return false;
            });
        } else {
            return false;
        }
    }
    const readSelectedMessages = () => {
        selectedMessages = [];
        keys.map((opt: any) => {
            selectedMessages.push({ Message: { Key: opt.Key }, IsRead: true })
        });
        if (keys?.length > 0) {
            Api.post(ApiEndpoints.saveSelectedMessages, selectedMessages).then(response => {
                if (response && response.status == 200) {
                    GetMessages();
                    selectedMessages = [];
                    return true;
                } else {
                    return false;
                }
            }, err => {
                return false;
            });
        } else {
            return false;
        }
    }
    const GetMessages = () => {
        setMessageString('');
        var messagesFromDB = [] as any;
        Api.get(ApiEndpoints.getMessages).then(response => {
            if (response && response.status == 200) {
                response.data?.result?.map((d: any) => {
                    messagesFromDB.push({
                        message: {
                            key: d.message.key, messageOn: new Date(d.message.sentOn), type: EqMessageType[d.message.messageType],
                            from: d.message.sender.userName, to: "trainer", subject: d.message.subject, text: d.message.message,
                            isRead: d.isRead, isSent: d.isSent, isFlaged: d.isFlaged,
                        },
                        checkBoxBordered: false,
                        clickedReplyFun: replyEmail,
                        clickedFlagFun: mardFlagedEmail,
                        clickedDeleteFunc: deleteEmail,
                        onCheckChangeFunc: onCheckChange,
                        selected: false
                    });
                });
                messagesFromDB = messagesFromDB?.sort((obj1: any, obj2: any) => {
                    if (obj1.message.isRead > obj2.message.isRead) { return 1; }
                    else if (obj1.message.isRead < obj2.message.isRead) { return -1 }
                    else { return 0 }
                });

                setMessages(messagesFromDB);
            } else {
                setMessageString("Not found");
            }
        }, err => {
            setMessageString("Not found");
        });
    }

    const messagesCheckAll = () => {
        msetIsCheckAll(!misCheckAll);

        messages.map(
            (m: IEqMessage) => {
                if (m.selected == false) {
                    m.selected = !misCheckAll;
                    selectedMessages.push({ Key: m.message.key });
                }
                else {
                    m.selected = !misCheckAll;
                    if (selectedMessages != null && selectedMessages.length > 0) {
                        const index = selectedMessages.findIndex(x => x.Key == m.message.key);
                        if (index > -1) {
                            selectedMessages.splice(index, 1);
                        }
                    }
                }
                setKeys(selectedMessages);
            }
        );
        const modifiedList = [...messages];
        setMessages(modifiedList);
    }
    const acceptClicked = (data: IProfileDto) => {
        GetTrainers();
    }
    const rejectClicked = (data: IProfileDto) => {
       
    }
    const editClicked = (data: IProfileDto) => {
       
    }
    const GetTrainers = () => {
        setMessageString('');
        
        Api.get(ApiEndpoints.getUnApprovedTrainers).then(response => {
            var trainersFromDB = [] as any;
            if (response && response.status == 200) {
                response.data?.map((d: any) => {
                    let trainer: IProfileDto = d;
                    trainer.name = d.firstName ? d.firstName : '' + ' ' + d.lastName ? d.lastName : '' || d.userName;
                    trainer.display = false;
                    trainer.homeAddress = d.locations?.filter((opt: any) => (opt.location?.type == EqLocationType.Home)).shift()?.location;
                    trainer.serviceAreaList= d.locations?.filter((opt: any) => (opt.location?.type == EqLocationType.Work));
                    // trainer.trainingLevels=[];
                    trainersFromDB.push({
                        data: trainer,
                        acceptedFun: acceptClicked,
                        rejectedFun: rejectClicked,
                        editFun: editClicked
                    });
                });
                
                setTrainersList(trainersFromDB);
            } else {
                setMessageString('');
            }
        }, err => {
            setMessageString('');
        });
    }
    useEffect(() => {
        GetMessages();
        GetTrainers();
    }, []);
    return (
        <>
            <div className="eq-sb-container">
                <Row className="eq-sb-heading-row">
                    <Col className="eq-sb-col" flex="auto" md={5} xs={24} sm={24}>
                        <h2 className="eq-sb-heading">{t("myDashboard")}</h2>
                    </Col>
                </Row>
                <div className="eq-section">
                    <Row className="eq-db-row" align="stretch">
                        <Col flex="auto" className="eq-db-icons">
                            <Row>
                                <div className="eq-db-h2">
                                    {t("quickLinks")}
                                </div>
                            </Row>
                            <Row align="bottom" justify="start" className="eq-db-icon-row">
                                
                                <Col className="eq-db-icon" xs={8} sm={8} md={4}>
                                    <Link to={FrontEndPoints.admin.composeMessage}>
                                    <span><img alt="" src="/message.svg" /></span>
                                        <div className="eq-db-icon-text">{t("sendMessage")}</div>
                                        </Link>
                                </Col>
                                <Col className="eq-db-icon" xs={8} sm={8} md={4}>
                                    <Link to={FrontEndPoints.trainer.search}>
                                    <span><img alt="" src="/dashboard-search.svg" /></span>
                                        <div className="eq-db-icon-text">{t("search")}</div>
                                        </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={6} className="eq-db-invite">
                            <Row>
                                <div className="eq-db-h2">
                                    Invite a Professional
                                </div>
                            </Row>
                            <Row>
                                <div className="eq-pb-invite-info">
                                    Know a professional, ask them to join Equinet.
                                </div>
                            </Row>
                            <Row>
                                <Col flex="auto">
                                    <div className="eq-pb-btn">
                                        <Link to={FrontEndPoints.trainer.inviteStudent}>
                                            <EqButton type="button" bordered="true" block={true} classNames="btn-height btn-border-text-orange">
                                                Send Invite</EqButton>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="eq-section-divider"></div>
                <div className="eq-section">
                    <Row className="eq-msg-header" align="bottom">
                        <Col flex="auto">
                            <div className="eq-db-h2">
                                {t("newMessages")}
                            </div></Col>
                        <Col md={2}>
                            <Row>
                                <Row>
                                    <Col className="eq-msg-icons" onClick={readSelectedMessages}>
                                        <span><img alt="" src="/black-check.svg" /></span>
                                    </Col>
                                    <Col className="eq-msg-icons">
                                        <Link to={FrontEndPoints.admin.composeMessage}>
                                            <span><img alt="" src="/black-Envelope.svg" /></span>
                                        </Link>
                                    </Col>
                                    <Col className="eq-msg-icons" onClick={deleteSelectedMessages}>
                                        <span><img alt="" src="/trash-alt.svg" /></span>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                    {messages.length > 0 &&
                        <>
                            <Row className="eq-list-header hidden-sm">
                                <Col md={22} flex="auto">
                                    <Row>
                                        <Col xs={2} sm={2} md={1} className="" >
                                            <Checkbox onChange={messagesCheckAll} checked={misCheckAll}></Checkbox>
                                        </Col>
                                        <Col span={3} className="" >{t("type")}</Col>
                                        <Col span={5} className="" >{t("from")}</Col>
                                        <Col flex="auto" className="" >{t("messageTitle")}</Col>
                                    </Row>
                                </Col>
                                <Col xs={4} sm={4} md={2} className="" >{t("action")}</Col>
                            </Row>
                        {messages.map((item: IEqMessage, index: number) =>
                                <EqMessage key={index} {...item} ></EqMessage>
                            )}
                        </>
                    }
                </div>
                <div className="eq-section-divider"></div>
                <div className="eq-section">
                    <Row className="" align="bottom">
                        <Col flex="auto">
                            <div className="eq-db-h2">
                                {t("approveTrainers")}
                            </div>
                        </Col>
                    </Row>
                    <Row className="eq-events-header">
                        <Col span={4}>
                            <h4 className="eq-el-heading">{t("trainers")}</h4>
                        </Col>
                    </Row>

                    {trainersList.length > 0 &&
                        <>
                            {
                            trainersList.map((item: any, index: number) =>
                                    <EqApproveTrainer
                                key={index} 
                                {...item}  
                                // data={item.data}
                                // acceptedFun={item.acceptedFun }
                                // rejectedFun={item.rejectedFun }
                                // editFun={item.editFun}
                            >

                            </EqApproveTrainer>
                                )
                            }
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default EqAdminDashboard;