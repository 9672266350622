import * as React from "react";
import "./EqInviteByUser.scss";
import { Row, Col, Space, Button } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  getDefaultImage,
  imageType,
} from "../../shared/Common";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
export interface IEqInviteByUser {
  user?: any;
  acceptClicked: (user: any) => void;
  rejectClicked: (user: any) => void;
}
const EqInviteByUser = ({
  user,
  acceptClicked,
  rejectClicked,
}: IEqInviteByUser) => {


  const accept = (event: any) => {
    acceptClicked(event);
  };
  const reject = (event: any) => {
    rejectClicked(event);
  };
  const { t } = useTranslation();
  React.useEffect(() => {
    
    // console.log(step);
  }, [user]);
  return (
    <>
      <Row
        className="eq-invite-row"
        justify="space-around"
        // onClick={rowClicked}
      >
        <Col
          xs={{ span: 4, offset: 0 }}
          sm={{ span: 4, offset: 0 }}
          md={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          xl={{ span: 2, offset: 0 }}
          xxl={{ span: 2, offset: 0 }}
        >
          <img
            src={user?.userImage?.url || getDefaultImage(imageType.partner)}
            className="user-img"
            alt="user"
          />
        </Col>

        <Col xs={17} sm={17} md={20} lg={20}>
          <Row>
            <Col xs={24} sm={24} md={5}>
              <Text className="eq-invite-row-subTitle" type="secondary">
                {t("school")} {user?.firstName}
              </Text>
            </Col>

            <Col xs={24} sm={24} md={5}>
              <Text className="eq-invite-row-title">
                {user?.firstName + " " + user?.lastName}
              </Text>
            </Col>
            <Col className="eq-invite-row-dis" xs={24} sm={24} md={8}>
              {t("from")}: {user?.userName}
            </Col>
            <Col className="eq-invite-row-dis" xs={24} sm={24} md={6}>
              {t("invitesYou")}
              {/* {t("invitesYouTo")}: {events?.length==1?(events.length+"activity"):(events.length+" activities")} */}
            </Col>
          </Row>
        </Col>
        <Col
               xs={{ span: 3, offset: 0 }}
               sm={{ span: 3, offset: 0 }}
               md={{ span: 2, offset: 0 }}
               lg={{ span: 2, offset: 0 }}
               xl={{ span: 2, offset: 0 }}
               xxl={{ span: 2, offset: 0 }}
        >
        
          <Space>
            <Button
              onClick={() => accept(user)}
              className="invite-accept-btn"
              size="small"
              shape="circle"
              icon={<CheckOutlined />}
            />
            <Button
              onClick={() => reject(user)}
              className="invite-reject-btn"
              size="small"
              shape="circle"
              icon={<CloseOutlined />}
            />
          </Space>
        </Col>
   
      </Row>
    </>
  );
};
export default EqInviteByUser;
