import * as React from 'react';
import './EqGroupMembersList.scss';
import EqGroupMembers, { IEqGroupMember } from '../eqGroupMembers/EqGroupMembers';
import { Row, Col, message, Popconfirm } from 'antd';
import { Checkbox } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { IStudentGroupDto } from '../../shared/interfaces/IStudentGroupDto';
import { IStudentDto } from '../../shared/interfaces/IStudentDto';
import EqButton from '../../custom/eqButton/EqButton';
import CopyToClipboard from 'react-copy-to-clipboard';

export interface IEqGroupMembersList {
    groupMembersList: Array<IStudentGroupDto>;
    delFunc?: (item: IStudentGroupDto) => void;
    selected?: boolean;
    hideGroup?: boolean;
    hideName?: boolean;
}

const EqGroupMembersList = ({ groupMembersList, delFunc, selected, hideGroup, hideName }: IEqGroupMembersList) => {
    const { t, i18n } = useTranslation();
    const [isCheckAll, setIsCheckAll] = React.useState(false);
    const [selectedAttendees, setSelectedAttendees] = React.useState([]) as any;
    const [membersList, setMembersList] = React.useState(groupMembersList) as any;
    const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([] as any);
    const [copyMessage, contextCopyMessage] = message.useMessage();

    const gutter = { xs: 8, sm: 16, md: 24, lg: 32 };

    const checkAll = (e: any) => {

        setIsCheckAll(e.target.checked);

        const updatedMembersList = membersList.map((item: any) => {
            return {
                ...item,
                selected: e.target.checked,
            };
        });

        setMembersList(updatedMembersList);
        const updatedSelectedCheckboxes = updatedMembersList.filter((x: any) => x.selected);
        setSelectedCheckboxes(updatedSelectedCheckboxes);
    }

    const toggleCheck = (member: IStudentGroupDto, checked: boolean) => {
        if (checked) {
            selectedAttendees.push(member);
        }
        else {
            let filteredMembers = selectedAttendees.filter((item: IEqGroupMember) => item.member?.key !== member.key);
            setSelectedAttendees([...filteredMembers]);
        }
    }

    const handleOnCheck = (member: any) => {
        const list = [...membersList];
        const foundIndex = list.findIndex((x) => x.key === member.key);
        const prevmember = list.find((x) => x.key === member.key);
        prevmember.selected = !prevmember.selected;
        list[foundIndex] = prevmember;
        setMembersList(list);
        const updatedSelectedCheckboxes = list.filter((x) => x.selected);
        setSelectedCheckboxes(updatedSelectedCheckboxes);
    };
    const getEmailsText = () => {
        const emails = selectedCheckboxes.map((item: any) => item?.user?.email || "")
        console.log(emails);
        return emails;
    };

    const getNumbersText = () => {
        const numbers = selectedCheckboxes.map((item: any) => item?.user?.phoneNumber || "")
        console.log(numbers);
        return numbers
    };
    const handleCopyLink = () => {
        copyMessage.open({
            type: "success",
            content: "Copied",
        });
    };

    React.useEffect(() => {

        if (groupMembersList?.length > 0) {
            setMembersList(groupMembersList);
        }
    }, [groupMembersList]);

    return (
        <Row justify="space-between" >
            <Col className="copy-data-btn hidden-sm">
                {contextCopyMessage}
                {selectedCheckboxes.length > 1 && (
                    <>
                        <CopyToClipboard text={getEmailsText()}>
                            <EqButton classNames="mail-copy" onClick={handleCopyLink}>{t("copyEmails")}</EqButton>
                        </CopyToClipboard>
                        <CopyToClipboard text={getNumbersText()}>
                            <EqButton onClick={handleCopyLink}>{t("copyNumbers")}</EqButton>

                        </CopyToClipboard>

                    </>
                )}
            </Col>
            <Col span={24}>
                <Row className="eq-sl-row hidden-sm" justify="space-between" align="middle">
                    {hideGroup != true &&
                        <Col className="eq-th-checkbox" xs={1} sm={1} md={1}>
                            <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                        </Col>
                    }
                    <Col className="eq-sl-th" xs={5} sm={5} md={5}>
                        {t("name")}
                    </Col>
                    <Col className="eq-sl-th" xs={6} sm={6} md={6}>
                        {t("e-mail")}
                    </Col>
                    <Col className="eq-sl-th" xs={5} sm={5} md={5}>
                        {t("phoneNumber")}
                    </Col>
                    {hideGroup != true &&
                        <Col className="eq-sl-th" xs={5} sm={5} md={5}>{t("groups")}
                        </Col>
                    }
                    <Col className="eq-sl-th" xs={2} sm={2} md={2}>
                        {t("actions")}
                    </Col>
                </Row>
                <div className="hidden-md">
                    <Row justify="start" align="middle" className="eq-list-checkAll">
                        <Col span={2}>
                            <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                        </Col>
                        <Col flex="auto">{t("checkAll")}

                        </Col>

                        <Col className="copy-data-btn-xs">
                            
                            {selectedCheckboxes.length > 1 && (
                                <>
                                    <CopyToClipboard text={getEmailsText()}>
                                        <EqButton style={{marginLeft: "7px"}}
                                         classNames="mail-copy" onClick={handleCopyLink}>{t("copyEmails")}</EqButton>
                                    </CopyToClipboard>
                                    <CopyToClipboard text={getNumbersText()}>
                                        <EqButton onClick={handleCopyLink}>{t("copyNumbers")}</EqButton>

                                    </CopyToClipboard>

                                </>
                            )}
                        </Col>
                        <Col>
                            <EqButton
                                type="button"
                                classNames="btn-background-orange"
                            >

                            </EqButton>
                        </Col>
                    </Row>
                </div>
                {membersList && membersList.length > 0 &&
                    <Row>
                        {
                            membersList.map((item: IStudentGroupDto, index: number) =>
                                <>
                                    <Col span={24}>
                                        <EqGroupMembers
                                            member={item}
                                            hideGroup={hideGroup}
                                            onCheck={handleOnCheck}
                                            delFunc={delFunc}
                                        // selected={item.selected}
                                        // Pass the selected state from membersList
                                        ></EqGroupMembers>                                    </Col>
                                </>
                            )
                        }
                    </Row>
                }
            </Col>
        </Row>
    );
}
export default EqGroupMembersList;