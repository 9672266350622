import React, { useState, useEffect } from "react";
import "./EqSchoolProfileBoard.scss";
import { Row, Col, message, Avatar } from "antd";
import { useTranslation } from "react-i18next";
import Api from "../../shared/Api";
import {
  GetEventMessage,
  ApiEndpoints,
  ValidateIsTrainer,
  getEmailFromToken,
  getDefaultImage,
  imageType,
  checkDuePayment,
  ValidateIsStudent,
  ValidateIsSchool,
} from "../../shared/Common";
import {
  EqDisciplineType,
  EqLocationType,
  eqNotificationTypes,
  ProductType,
} from "../../shared/enums";
import history from "../../shared/history";

import { IProfileDto } from "../../shared/interfaces/IProfileDto";
import EqButton from "../../custom/eqButton/EqButton";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useAuthState } from "../../context/Context";
import { FrontEndPoints, ValidateIsUser } from "../../shared/Common";

import { notification } from "antd";
import { saveEvent } from "../../shared/LocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckCircleTwoTone, CopyFilled, ExclamationCircleOutlined } from "@ant-design/icons/lib/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import config from "../../shared/Config";
import { EqInput } from "../../custom/eqInput/EqInput";
import EqScheduleList from "../../eqTrainer/eqScheduleList/EqScheduleList";
import EqTags from "../../custom/eqTags/EqTags";
import { IGetSchoolTrainingDto } from "../../shared/interfaces/schoolTraining/IGetSchoolTrainingDto";
import EqSchoolSchedule from "./eqSchoolSchedule/EqSchoolSchedule";
import modal from "antd-v5/es/modal";
import EqSendMessageModal from "../../eqTrainer/eqMessageBoard/eqSendMessageModal/EqSendMessageModal";

const EqSchoolProfileBoard = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = useAuthState();
  const isSignedIn: boolean = ValidateIsUser(userDetails);
  const isStudent: boolean = ValidateIsStudent(userDetails);
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const isSchool: boolean = ValidateIsSchool(userDetails);
  const currentUserKey = getEmailFromToken();
  const [messageApi, contextHolder] = message.useMessage();
  const [isOwner, setIsOwner] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [messageString, setMessageString] = useState("");
  const [schedules, setSchedules] = useState([] as any);
  const [isError, setIsError] = React.useState(false);
  const [messageText, setMessageText] = React.useState("");
  const [trainersList, setTrainersList] = React.useState([]) as any;

  const [trainersOptions, setTrainersOptions] = React.useState([]) as any;
  const [errorMessage, setErrorMessage] = React.useState("");
  // const [message, setMessage] = React.useState("");

  const [areas, setAreas] = React.useState([]) as any;
  const [schoolEventTypes, setSchoolEventTypes] = React.useState([]) as any;

  const [weekDays, setWeekDays] = React.useState([]) as any;
  const [imagesData, setImagesData] = React.useState([]) as any;

  const [population, setPopulation] = React.useState([]) as any;
  const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
  const [schoolStudents, setSchoolStudents] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;
  const [eventsList, setEventsList] = React.useState([] as Array<any>);
  const [trainings, setTrainings] = React.useState([] as Array<any>);
  const [trainerHorsesList, setTrainerHorsesList] = React.useState({});
  const [studentHorsesList, setStudentHorsesList] = React.useState([]);

  const [profileData, setProfile] = useState<IProfileDto>();
  const [bookingButtonState, setBookingButtonState] = React.useState({
    buttonDisabled: false,
    buttonText: t("bookASession"),
  } as any);
  const [subscribeButtonState, setSubscribeButtonState] = React.useState({
    buttonDisabled: false,
    buttonText: t("subscribe"),
    // buttonTextSubscribe: "Subscribe",
    // buttonTextSubscribed: "Subscribed",
  } as any);

  const [productsList, setProductsList] = React.useState({
    all: [],
    private: [],
    public: [],
  }) as any;
  const [productOptions, setProductOptions] = React.useState([]) as any;

  const [activitiesList, setActivitiesList] = React.useState({
    all: [],
    events: [],
    trainings: [],
  }) as any;
  const [userEmail, setUserEmail] = React.useState("");

  const [openEventList, setOpenEventList] = React.useState(
    [] as Array<IEqEventData>
  );
  const [selectedUserKey, setSelectedUserKey] = useState() as any;


  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [selectedEmail, setSelectedEmail] = React.useState<string>('');

  const showMessageModal = (email: string) => {
    
    setSelectedEmail(email);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };




  const handleSendMessage = async (data: any) => {
    try {
        const response = await Api.post(ApiEndpoints.UserMessage.SendMessageToEmail, data);

        if (response.status === 200) {
        
            openNotificationWithIcon(
                t("sent"),
                t("successfullySentMessage"),
                eqNotificationTypes.success
            );
        } else {
            console.error("Unexpected response status:", response.status);
        }
    setIsModalVisible(false);

    } catch (error) {
        openNotificationWithIcon(
            t("error"),
            t("errorOccurredSending"),
            eqNotificationTypes.error
        );
    setIsModalVisible(false);

    }
};


  schedules.map((f: IEqEventData, index: number) => {
    f.display = index == 0 ? true : false;
  });

  // const messageClicked = () => {
  //   if (ValidateIsUser(userDetails)) {
  //     var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${userEmail}`;
  //     navigate(out1);
  //   } else {
  //     var out1 = FrontEndPoints.login;
  //     navigate(out1);
  //   }
  // };

  const formatProductListData = (data: any) => {
    var privateList = data?.filter(
      (opt: any) => opt.type == ProductType.Private
    );
    var publicList = data?.filter(
      (opt: any) => opt.type === ProductType.Public
    );
    // var publicList = data?.filter((opt: any) => opt.type === ProductType.Public && opt.isUsed===false);

    var productList = {
      all: [...data],
      private: [...privateList],
      public: [...publicList],
    };
    setProductsList({ ...productList });

    var products = [] as any;

    publicList.map((item: any, Idx: any) => {
      let obj = {
        key: item.id,
        value: item.title,
        label: item.title,
      };
      products.push(obj);
    });
    setProductOptions(products);
  };

  useEffect(() => {
    console.log("--School-Profile---");
    const params = new URLSearchParams(window.location.search);
    var key = params.get("key");
    var userKey;
    if (key) {
      userKey = Number(key);
      GetProfileDetails(userKey);
      GetSchoolPublicEventsByKey(userKey);
      GetSchoolProductsByKey(userKey);
      setSelectedUserKey(userKey);

      if (isSchool && userKey == userDetails.key) {
        setIsOwner(true);
        GetSchoolTrainingsByKey(userKey);
        // GetSchoolPublicEvents();
        // GetSchoolPrivateTrainings();

        getImages();
        getAreas();
        getWeekDays();
        getPopulation();
        getGroupPopulation();

        GetSchoolTrainers();
        GetSchoolStudents();
        GetAllSchoolHorses();
        createEventTypes();
      } else {
        setIsOwner(false);
        GetAllSchoolTrainingsLessonsBySchoolKey(userKey);
      }
    } else {
      userKey = 0;
      setSelectedUserKey(0);
    }
  }, [props]);

  const destroyAllModals = () => {
    modal.destroyAll();
  };

  const confirmUserTermsAndSubscribe = (userData?: any) => {
    let terms = profileData?.trainerTerms || userData?.trainerTerms;
    destroyAllModals();
    modal.confirm({
      width: 1000,
      title: "Terms And Conditions",
      icon: <ExclamationCircleOutlined />,
      content: (
        <p style={{ whiteSpace: "pre-wrap" }}>
          {" "}
          <div dangerouslySetInnerHTML={{ __html: terms }} />
        </p>
      ),
      okText: "Accept",
      onOk: () => SubscribeTrainer(userData),
      onCancel: () => {
        destroyAllModals();
      },
    });
  };

  const GetProfileDetails = async (key?: any) => {
    setMessageString("");
    
    const params = new URLSearchParams(window.location.search);
    let trainerKey = params.get("key") || key;

    try {
      const response = await Api.post(ApiEndpoints.GetTrainerProfileByKey, {
        Key: trainerKey,
      });

      if (response && response.status === 200) {
        const data = response.data ? { ...response.data } : null;

        if (data != null) {
          console.log("trainer-profile");
          console.log(data.key);
          console.log(data.email);

          setSelectedUserKey(data.key);
          setUserEmail(data.email);



          if (data.isMySchool) {
            setSubscribeButtonState({
              ...subscribeButtonState,
              buttonDisabled: true,
              buttonText: "Subscribed",
            });
          } else {
            if (
              location.state?.prevPath === FrontEndPoints.login &&
              isStudent
            ) {
              confirmUserTermsAndSubscribe(data);
            }
          }

          const profList = data.disciplines?.map(
            (item: any) => EqDisciplineType[Number(item.discipline)]
          );

          const profile: any = {
            email: data.email,
            about: data.aboutMe,
            name:
              (data.firstName ? data.firstName : "") +
              " " +
              (data.lastName ? data.lastName : ""),
            rating: 4.5,
            experience: "10 Years",
            area: data.locations?.find(
              (opt: any) => opt.location?.type === EqLocationType.Home
            ),
            radius: "20Km",
            disciplineList: data.disciplines,
            imagePath: data.imagePath,
            display: false,
            professionsList: profList,
            userImage: data.userImage,
            trainingLevels: data.trainingLevels,
            serviceAreaList: data.locations?.filter(
              (opt: any) => opt.location?.type === EqLocationType.Work
            ),
            trainerTerms: data.trainerTerms,
          };

          setProfile(profile);
        }
      } else {
        setMessageString("Not found");
      }
    } catch (err) {
      setMessageString("Not found");
    }
  };

  // const GetProfileDetails = (key?: any) => {
  //   setMessageString("");
  //   const params = new URLSearchParams(window.location.search);
  //   let trainerKey = params.get("key") || key;

  //   Api.post(ApiEndpoints.GetTrainerProfileByKey, { Key: trainerKey }).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         var data = response.data ? { ...response.data } : null;
  //         if (data != null) {
  //           // console.log("trainer key");
  //           // console.log(selectedUserKey);
  //           // console.log(data.key);

  //           setSelectedUserKey(data.key);
  //           setUserEmail(data.email);

  //           if (data.isMySchool) {
  //             setSubscribeButtonState({
  //               ...subscribeButtonState,
  //               buttonDisabled: true,
  //               buttonText: "Subscribed",
  //             });
  //           }

  //           var profList = [] as any;

  //           data.disciplines?.map((item: any) =>
  //             profList.push(EqDisciplineType[Number(item.discipline)])
  //           );

  //           var profile: any = {
  //             email: data.email,
  //             about: data.aboutMe,
  //             name: data.firstName
  //               ? data.firstName
  //               : "" + " " + data.lastName
  //               ? data.lastName
  //               : "",
  //             professionType: data.professionType,
  //             rating: 4.5,
  //             // experience: "10 Years",
  //             area: data.locations
  //               ?.filter(
  //                 (opt: any) => opt.location?.type == EqLocationType.Home
  //               )
  //               .shift(),
  //             radius: "20Km",
  //             disciplineList: data.disciplines,
  //             imagePath: data.imagePath,
  //             display: false,
  //             professionsList: profList,
  //             userImage: data.userImage,
  //             trainingLevels: data.trainingLevels,
  //             serviceAreaList: data.locations?.filter(
  //               (opt: any) => opt.location?.type == EqLocationType.Work
  //             ),
  //           };
  //           setProfile(profile);
  //         }
  //       } else {
  //         setMessageString("Not found");
  //       }
  //     },
  //     (err) => {
  //       setMessageString("Not found");
  //     }
  //   );
  // };

  const GetSchoolPublicEventsByKey = async (key?: any) => {
    setMessageString("");
    const params = new URLSearchParams(window.location.search);
    let schoolKey = params.get("key") || key;

    let path = ApiEndpoints.UserPublicEvent.GetPublicEventsByKeyForMonths;
    Api.get(path + `?userKey=${schoolKey}`).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          setEventsList(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const GetSchoolProductsByKey = async (key?: any) => {
    setMessageString("");
    const params = new URLSearchParams(window.location.search);
    let userKey = params.get("key") || key;

    let path = ApiEndpoints.GetUserProductByUserKey;
    Api.get(path + `?userKey=${userKey}`).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          if (data.length > 0) {
            formatProductListData(data);
          }
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getImages = () => {
    Api.get("image/getEventImages").then(
      (response) => {
        if (response.data && response.data.length) {
          setImagesData(response.data);
        }
      },
      () => {
        setIsError(true);
        setErrorMessage("Error Occurred while fetching images from server");
      }
    );
  };

  const GetAllSchoolHorses = () => {
    Api.get(ApiEndpoints.GetAllHorses).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          var data = response.data ? [...response.data] : new Array<any>();
          setTrainerHorsesList(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetSchoolStudents = () => {
    Api.get(ApiEndpoints.GetSchoolStudents).then(
      (response) => {
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var schoolStudents = response.data;
          setIsError(false);
          // console.log(userDetails);

          setErrorMessage("");
          setSchoolStudents(response.data);
          // console.log(schoolHorsesList);
          [...schoolStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.school.horses.length > 0) {
              [...item.school.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
          console.log("studentHorses:", studentHorsesListOptions);
          // console.log("TrainerStudents:",response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetSchoolTrainers = async () => {
    try {
      const response = await Api.get(ApiEndpoints.SchoolGetTrainers);
  
      if (response && response.status === 200) {
        const trainers = response.data;
        setTrainersList(trainers);
      
  
        const trainerObjList = [
          {
            key: userDetails.key,
            value: userDetails.name,
            label: userDetails.name,
            icon: (
              <Avatar
                size={30}
                src={
                  userDetails.userImage
                    ? userDetails.userImage.url
                    : getDefaultImage(imageType.trainer)
                }
              />
            ),
          },
          ...trainers.map((item:any) => ({
            key: item.key,
            value: item.firstName + item.lastName,
            label: item.firstName,
            icon: (
              <Avatar
                size={30}
                src={
                  item.userImage
                    ? item.userImage.url
                    : getDefaultImage(imageType.trainer)
                }
              />
            ),
          })),
        ];
  
        setTrainersOptions(trainerObjList);
      }
    } catch (error) {
      throw error;
    }
  };

  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setPopulation(response.data);
          // console.log("population:",response.data);
          // console.table(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getAreas = () => {
    Api.get(ApiEndpoints.eventGetAreas).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const createEventTypes = () => {
    var eventTypes = [];

    eventTypes.push({
      key: EqDisciplineType.Dressage,
      value: EqDisciplineType[EqDisciplineType.Dressage],
    });
    eventTypes.push({
      key: EqDisciplineType.Jumping,
      value: EqDisciplineType[EqDisciplineType.Jumping],
    });
    eventTypes.push({
      key: EqDisciplineType.Open,
      value: EqDisciplineType[EqDisciplineType.Open],
    });
    // eventTypes.push({
    //   key: EqDisciplineType.Product,
    //   value: EqDisciplineType[EqDisciplineType.Product],
    // });

    setSchoolEventTypes(eventTypes);
  };

  // const GetSchoolPublicEvents = async () => {
  //   let path = ApiEndpoints.GetTrainerEvents;

  //   Api.get(path).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         var data = response.data ? [...response.data] : new Array<any>();
  //         setEventsList(data);
  //       } else {
  //         setIsError(true);
  //         setErrorMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setErrorMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };

  const GetSchoolTrainingsByKey = (key?: any) => {
    setErrorMessage("");
    const params = new URLSearchParams(window.location.search);
    let userKey = params.get("key") || key;

    Api.get(
      ApiEndpoints.SchoolTraining.GetSchoolTrainingsBySchoolKeyOfMonths +
        `?userKey=${userKey}`
    ).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data
            ? [...response.data]
            : new Array<any>();
          data.map((f: any, index: number) => {
            f.display = index == 0 ? true : false;
          });
          setTrainings(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };


  const GetAllSchoolTrainingsLessonsBySchoolKey = (key?: any) => {
    setErrorMessage("");
    const params = new URLSearchParams(window.location.search);
    let userKey = params.get("key") || key;
    Api.get(
      ApiEndpoints.SchoolTraining.GetAllSchoolsTrainingsLessonsForStudents +
        `?userKey=${userKey}`
    ).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data
            ? [...response.data]
            : new Array<IEqEventData>();
          data.map((f: IEqEventData, index: number) => {
            f.display = index == 0 ? true : false;
          });
          setTrainings(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  // const GetSchoolPrivateTrainings = () => {
  //   setErrorMessage("");
  //   let eventsArr = [] as any;

  //   Api.get(ApiEndpoints.GetAllSchoolTrainingsWithLessons).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         var data = response.data
  //           ? [...response.data]
  //           : new Array<IEqEventData>();
  //         data.map((f: IEqEventData, index: number) => {
  //           f.display = index == 0 ? true : false;
  //         });
  //         setTrainings(data);
  //       } else {
  //         setIsError(true);
  //         setErrorMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setErrorMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };

  const handleOnUpdateTraining = (newData: any, oldData: any) => {
    if (trainings != undefined) {
      const newTrainings = [...trainings];
      var foundIndex = newTrainings.findIndex((x) => x.key == oldData.key);
      newTrainings[foundIndex] = newData;
      setTrainings(newTrainings);
    }
  };

  const handleOnUpdateEvent = (newData: any, oldData: any) => {
    if (eventsList != undefined) {
      const events = [...eventsList];
      var foundIndex = events.findIndex(
        (x) => x.referenceKey == oldData.referenceKey
      );
      events[foundIndex] = newData;
      setEventsList(events);
    }
  };

  const handleRemoveTraining = (
    trainingData: IGetSchoolTrainingDto,
    refund?: boolean
  ) => {
    let path =
      ApiEndpoints.SchoolTraining.Delete +
      `?key=${trainingData.key}&refund=${refund}`;
    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeTrainingItem(trainingData);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const removeTrainingItem = (data: any) => {
    if (trainings != undefined) {
      var updatedList =
        trainings != undefined
          ? trainings.filter((item) => item.key !== data.key)
          : new Array<any>();
      setTrainings(updatedList);

      trainings.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  const handleRemoveEvent = (data: IEqEventData, refund?: boolean) => {
    let path =
      ApiEndpoints.DeleteTrainerEvent + `?key=${data.key}&refund=${refund}`;

    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeEventItem(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const removeEventItem = (data: any) => {
    if (eventsList != undefined) {
      var updatedList =
        eventsList != undefined
          ? eventsList.filter((item) => item.key !== data.key)
          : new Array<any>();
      setEventsList(updatedList);

      eventsList.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  const [sessionBookingProps, setSessionBookingProps] = React.useState({
    title: t("bookASession"),
    visible: false,
    email: userEmail,
  });

  const openNotificationWithIcon = (msg: any, desc: any, event: any) => {
    const key = `open${Date.now()}`;
    const btn = (
      <EqButton type="button" onClick={() => viewEvent(event)}>
        Pay Now
      </EqButton>
    );
    notification["error"]({
      message: msg,
      btn,
      key,
      description: desc,
    });
  };
  const viewEvent = (event: IEqEventData) => {
    saveEvent(event);
    const key = `open${Date.now()}`;
    notification.close(key);
    history.push(`${FrontEndPoints.student.viewEvent}?key=${event.key}`, {
      event: event,
    });
  };

  const SubscribeTrainer = (trainerEmail?: any) => {
    const data = {
      SchoolKey: userEmail || trainerEmail,
      subscriberKey: currentUserKey,
    };

    var path = "";
    if (isStudent) {
      path = ApiEndpoints.StudentSubscribeSchool;
    } else if (isTrainer) {
      path = ApiEndpoints.TrainerSubscribeSchool;
    }

    Api.post(path, data).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessageText("");

          if (response.data) {
            setMessageText("User Subscribed Trainer");
            console.log("User Subscribed Trainer");
            GetProfileDetails(selectedUserKey);
          } else {
            setMessageText("User is unable Subscribed Trainer");
            console.log("User is unable Subscribed Trainer");
          }
        } else {
          GetProfileDetails(selectedUserKey);

          setIsError(true);
        }
      },
      (error) => {
        GetProfileDetails(selectedUserKey);

        setIsError(true);
        console.log(error);
      }
    );
  };
  const handleCopyLink = () => {
    messageApi.open({
      type: "success",
      content: "Copied",
    });
  };
  const changeBookSessionView = () => {
    if (ValidateIsUser(userDetails)) {
      checkDuePayment(openNotificationWithIcon).then(
        (success: any) => {
          console.log("success", success);
          if (success == true) {
          } else {
            setSessionBookingProps({
              ...sessionBookingProps,
              visible: true,
              email: userEmail,
            });
          }
        },
        (err: any) => {
          console.log("error", err);
        }
      );
    } else {
      navigate(FrontEndPoints.login);
    }
  };

  return (
    <>
      <div className="eq-trainer-profile-container">
        <Row>
          <Col md={6} xs={24} sm={24} className="trainer-info">
            <Row className="profile-img">
              <Col>
                <img
                  src={
                    profileData?.userImage?.url ||
                    getDefaultImage(imageType.partner)
                  }
                  className="user"
                  alt="user"
                />
              </Col>
            </Row>
            <Row className="profile-name">
              <Col>{profileData?.name}</Col>
            </Row>
            <Row className="profile-certified">
              <Col>{t("school")}</Col>
            </Row>
            <Row className="contact">
              <div 
              // onClick={messageClicked} 
              onClick={(e:any) => { 
                e.stopPropagation()
                showMessageModal(userEmail);
              }}
              className="about-message">
                <Row align="middle">
                  <Col className="msg-icon">
                    <span>
                      <img alt="" src="/Envelope.svg" />
                    </span>
                  </Col>
                  <Col flex="auto" className="msg-text">
                    {t("SendAMessage")}
                  </Col>
                </Row>
              </div>
            </Row>

            {!isSignedIn && (
              <Row className="eq-subscribe-button">
                <Col>
                  <EqButton
                    type="button"
                    onClick={() => {
                      navigate(FrontEndPoints.login, {
                        state: {
                          prevPath: location.pathname,
                          schoolKey: selectedUserKey,
                        },
                      });
                    }}
                    disabled={subscribeButtonState.buttonDisabled}
                  >
                    {subscribeButtonState.buttonText}
                    {subscribeButtonState.buttonText == "Subscribed" && (
                      <CheckCircleTwoTone style={{ fontSize: "16px" }} />
                    )}
                  </EqButton>
                </Col>
              </Row>
            )}
            {(isStudent || isTrainer) && (
              <Row className="eq-subscribe-button">
                <Col>
                  <EqButton
                    type="button"
                    onClick={SubscribeTrainer}
                    disabled={subscribeButtonState.buttonDisabled}
                  >
                    {subscribeButtonState.buttonText}
                    {subscribeButtonState.buttonText == "Subscribed" && (
                      <CheckCircleTwoTone style={{ fontSize: "16px" }} />
                    )}
                  </EqButton>
                </Col>
              </Row>
            )}
            <Row className="eq-board-line"></Row>

            <Row>
              {/* <Col span={10} className="profile-info-h">
                Experience:
              </Col> */}
              <Col span={10} className="profile-info-i">
                {profileData?.experience}
              </Col>
            </Row>
            <Row>
              <Col span={10} className="profile-info-h">
                {t("area")}:
              </Col>
              <Col span={10} className="profile-info-i">
                {profileData?.area?.location?.city}
              </Col>
            </Row>
            <Row>
              <Col span={10} className="profile-info-h">
                {t("radius")}:
              </Col>
              <Col span={10} className="profile-info-i">
                {profileData?.radius}
              </Col>
            </Row>
            <Row className="section-divider">
              <Col flex="auto"></Col>
            </Row>
            <Row>
              <Col className="section-heading">{t("studentInvite")}</Col>
            </Row>

            <Row className="eq-invite-link">
              {contextHolder}

              <Col span={20}>
                {" "}
                <CopyToClipboard
                  text={
                    config.apiUrl +
                    `newUser?schoolKey=${selectedUserKey}&profession=3`
                  }
                  onCopy={handleCopyLink}
                >
                  <EqInput
                    classNames="eq-input-copy"
                    readOnly
                    //  disabled
                    value={
                      config.apiUrl +
                      `newUser?schoolKey=${selectedUserKey}&profession=3`
                    }
                    suffix={
                      <CopyToClipboard
                        text={
                          config.apiUrl +
                          `newUser?schoolKey=${selectedUserKey}&profession=3`
                        }
                        onCopy={handleCopyLink}
                        // options={{message:"asdas"}}
                      >
                        <CopyFilled />
                      </CopyToClipboard>
                    }
                  />
                </CopyToClipboard>
              </Col>
            </Row>
          </Col>
          <Col md={18} xs={24} sm={24} className="col-right">
            <Row>
              <Col md={18} xs={24} sm={24}>
                <div className="profile-about">
                  {/* <Row className="eq-board-line"></Row> */}

                  <Row className="about-info info">
                    <Col xs={24} sm={24} md={18} className="about-left-col">
                      <Row>
                        <h3 className="about-heading heading">
                          {t("aboutSchool")}
                        </h3>
                      </Row>
                      <Row className="about-text">{profileData?.about}</Row>
                    </Col>
                    <Col></Col>
                  </Row>
                </div>
              </Col>
              <Col md={6} xs={24} sm={24}>
                <div className="profile-about">
                  <Row className="about-info info">
                    <Col xs={24} sm={24} md={18} className="about-left-col">
                      <Row>
                        <h3 className="about-heading heading">
                          {t("discipline")}
                        </h3>
                      </Row>

                      {profileData &&
                        profileData.disciplineList &&
                        profileData?.disciplineList?.length > 0 && (
                          <>
                            {profileData?.disciplineList?.map(
                              (item: any, index: number) => (
                                <Row>
                                  <EqTags
                                    borderColor
                                    className=""
                                    text={t(
                                      EqDisciplineType[item.discipline?.type]
                                    )}
                                  ></EqTags>
                                </Row>
                              )
                            )}
                          </>
                        )}
                    </Col>
                  </Row>

                  <Row>
                    {profileData?.area?.location?.city ? (
                      <Col flex="end" className="location">
                        <div>
                          <Col className="loc-info">
                            <Col className="loc-icon">
                              <span className="">
                                <img alt="" src="/chart-pie.svg" />
                              </span>
                            </Col>
                            <Col className="my-loc">
                              {profileData?.area?.location?.city}
                            </Col>
                          </Col>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              {/* <EqScheduleList
                {...{
                  isOwner: isOwner,
                  onCreateOrUpdateTraining: handleOnUpdateTraining,
                  onCreateOrUpdateEvent: handleOnUpdateEvent,
                  onRemoveTraining: handleRemoveTraining,
                  areas: areas,
                  imagesData: imagesData,
                  weekDays: weekDays,
                  population: population,
                  groupPopulation: groupPopulation,
                  trainerStudents: trainerStudents,
                  studentHorsesList: studentHorsesList,
                  profileData: profileData,
                  eventList: [...eventsList],
                  trainings: [...trainings],
                  activitiesList: activitiesList,
                  publicProducts: [...productsList?.public],
                  isStudent: isStudent,
                  isSubscribed: subscribeButtonState.buttonDisabled,
                  subscribeTrainer: SubscribeTrainer,
                  // for school
                  trainersOptions: { trainersOptions },
                  schoolEventTypes: { schoolEventTypes },
                  productOptions: { productOptions },
                  schoolStudents: { schoolStudents },
                }}
              ></EqScheduleList> */}

              <EqSchoolSchedule
                isOwner={isOwner}
                eventList={[...eventsList]}
                trainings={[...trainings]}
                onCreateOrUpdateTraining={handleOnUpdateTraining}
                onCreateOrUpdateEvent={handleOnUpdateEvent}
                onStudentJoin={GetAllSchoolTrainingsLessonsBySchoolKey}
                onRemoveTraining={handleRemoveTraining}
                onRemoveEvent={handleRemoveEvent}
                areas={areas}
                imagesData={imagesData}
                weekDays={weekDays}
                population={population}
                groupPopulation={groupPopulation}
                
                studentHorsesList={studentHorsesList}
                profileData={profileData}
                
                activitiesList={activitiesList}
                publicProducts={[...productsList?.public]}
                isStudent={isStudent}
                isSubscribed={subscribeButtonState.buttonDisabled}

                schoolEventTypes={schoolEventTypes}
                trainersOptions={trainersOptions}
                productOptions={productOptions}
                schoolStudents={schoolStudents}
                // subscribeTrainer={SubscribeTrainer}
              />
            </Row>
          </Col>
        </Row>
        </div>
        <EqSendMessageModal
        visible={isModalVisible}
        recipientEmail={selectedEmail}
        onCreate={handleSendMessage}
        onCancel={handleCancel}
      />
    </>
  );
};
export default EqSchoolProfileBoard;
