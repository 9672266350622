import { useEffect, useReducer, useRef } from 'react';
import * as React from 'react';
import { initialState, AuthReducer } from './Reducer';
import { loadState, saveState } from '../shared/LocalStorage';

const AuthStateContext = (React as any).createContext();
const AuthDispatchContext = (React as any).createContext();

export function useAuthState() {
    
    const context = React.useContext(AuthStateContext);
    if (context === undefined) {
        throw new Error('useAuthState must be used within a AuthProvider');
    }

    return context;
}

export function useAuthDispatch() {
    
    const context = React.useContext(AuthDispatchContext);
    if (context === undefined) {
        throw new Error('useAuthDispatch must be used within a AuthProvider');
    }

    return context;
}


export const AuthProvider = ({ children }: any) => {
// 
// new
    // var currentState = loadState();
    // const rememberMe = localStorage.getItem("rememberMe");
    
    // if (rememberMe === "true") {
  
    //     if (!currentState) {
    //         currentState = initialState;
   
    //     }

    //     // Use the token to authenticate the user
    //   } else {
    //     // The user does not want to be remembered, so clear any stored tokens
    //     currentState = initialState;
    //   }



    // currently in Use
    var currentState = loadState();

    if (!currentState) {
        currentState = initialState;
        
        console.log("initialState",currentState);

    }
    console.log("auth_provider",currentState);

    const [user, dispatch] = useReducer(AuthReducer, currentState);

    return (
        <AuthStateContext.Provider value={user} >
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    );
};
