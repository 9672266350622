import { Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import * as React from "react";
import "./NavMenu.scss";
import { useAuthState, useAuthDispatch } from "../context/Context";
import {
  FrontEndPoints,
  ValidateIsUser,
  ValidateIsAdmin,
  ValidateIsTrainer,
  ValidateIsStudent,
  ApiEndpoints,
  EqMessageType,
  ValidateIsEventHolder,
  ValidateIsSchool,
} from "./Common";
import { loginUser, logout } from "../context/Actions";
import { Badge, Button, Col, Dropdown, Menu, MenuProps, Popover, Row, Select, Space, Tooltip } from "antd";
import { useRef, useState } from "react";
import { Layout } from "antd";
import EqButton from "../custom/eqButton/EqButton";
import { useTranslation, Trans } from "react-i18next";
import {
  MailOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  SettingOutlined,
  LinkOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  BellOutlined,
  BellTwoTone,
  BellFilled,
  TranslationOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { loadState, saveState } from "./LocalStorage";
import { initialState } from "../context/Reducer";
import Api from "./Api";
import EqNotificationList from "../eqStudent/notificationList/EqNotificationList";
import MenuItem from "antd/lib/menu/MenuItem";
import EqNotificationBell from "../eqStudent/notificationBell/EqNotificationBell";
import EqLanguageMenu from "../eqLanguageMenu/EqLanguageMenu";
import AsyncImage from "../custom/asyncImage/AsyncImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCalendarDay, faCalendarDays, faUserPlus, faUserShield } from '@fortawesome/free-solid-svg-icons';

const NavMenu = (props: any) => {
  const userDetails: any = useAuthState();
  const isValidUser: boolean = ValidateIsTrainer(userDetails);
  const dispatch = useAuthDispatch;
  const [transparentBg, setTransparentBg] = React.useState(false);
  const [current, setCurrent] = useState("");
  const [unReadCount, setUnReadCount] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState() as any;

  const isStudent: boolean = ValidateIsStudent(userDetails);
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const isSchool: boolean = ValidateIsSchool(userDetails);
  const isEventHolder: boolean = ValidateIsEventHolder(userDetails);




  const { Header, Footer, Sider, Content } = Layout;

  const { SubMenu } = Menu;

  const routeChange = (key: string) => {
    let path = "";

    switch (parseInt(key)) {
      case 1:
        path = FrontEndPoints.all;
        break;

      case 2:
        path = FrontEndPoints.profile;
        break;
      case 3:
        path = FrontEndPoints.trainer.eventBoard;
        break;
      case 4:
        path = FrontEndPoints.all;
        break;
      case 5:
        path = FrontEndPoints.logout;
        break;
      case 7:
        path = FrontEndPoints.trainer.dashboard;
        break;
      case 8:
        path = FrontEndPoints.all;
        break;
      case 10 :
        path = FrontEndPoints.trainer.allProfessionalsBoard;
        break;
      case 11:
      case 12:
        // changeLanguage();
            return;
        // changeLanguage();
            // return;
      case 29:
        if (ValidateIsAdmin(userDetails)) {
          path = FrontEndPoints.admin.dashboard;
        } else if (ValidateIsTrainer(userDetails)) {
          path = FrontEndPoints.trainer.dashboard;
        } else if (ValidateIsEventHolder(userDetails)) {
          path = FrontEndPoints.eventHolder.dashboard;
        } else if (ValidateIsSchool(userDetails)) {
          path = FrontEndPoints.school.dashboard;
        } else if (ValidateIsStudent(userDetails)) {
          path = FrontEndPoints.student.dashboard;
        } else {
          path = FrontEndPoints.login;
        }
        break;
      case 30:
        path = FrontEndPoints.student.dashboard;
        break;
      case 31:
        path = FrontEndPoints.trainer.allEventsBoard;
        break;
      case 32:
        path = FrontEndPoints.trainer.createEvent;
        break;
      case 33:
        path = FrontEndPoints.features;
        break;
      case 34:
        if (ValidateIsTrainer(userDetails)) {
          path = FrontEndPoints.trainer.studentBoard
        } else if (ValidateIsSchool(userDetails)) {
          path = FrontEndPoints.school.studentBoard
        } else {
          return false
        }

        break;
      case 35:
        if (ValidateIsTrainer(userDetails)) {
          path = FrontEndPoints.trainer.activityBoard
        } else if (ValidateIsSchool(userDetails)) {
          path = FrontEndPoints.school.activityBoard
        } else {
          return false
        }
        break;
      case 36:
        // path = onClick={GetStripeDashboardUrlAsync};
        return;
        case 37:
          path = FrontEndPoints.student.allTrainings
          break;
          case 38:
          path = FrontEndPoints.student.schedule
          break;
      default:
        path = FrontEndPoints.login;
        break;
    }

    navigate(path);
  };


  interface LanguageFlags {
    [key: string]: string;
  }
  const languageFlags: LanguageFlags = {
    en: "flags/uk.jpg",
    no: "flags/norway.jpg",
    sv: "flags/sweden.jpg"
  };
  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'no', label: 'Norwegian' },
    { value: 'sv', label: 'Swedish' },
  ];
  const handleClick = (event: any) => {

    setCurrent(event.key);
    routeChange(event.key);
  };

  const checkPathForDashboard = (path: string): boolean => {
    if (
      path === "/" ||
      path === "/trainer/allProfessionals" ||
      path === "/trainer/profile" ||
      path === "/school/profile" ||
      path === "/features" ||
      path === "/ridingSchool" ||
      path === "/eventHolder"
    ) {
      return true;
    } else {
      return false;
    }
  };
  const checkPathForFeature = (path: string): boolean => {
    if (
      path === "/" ||
      path === "/trainer/allProfessionals" ||
      path === "/trainer/profile"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkPathForQuicLinks = (path: string): boolean => {
    if (
      path === "/trainer/home" ||
      path === "/student/home" ||
      path === "/school/home"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const mounted = useRef();


  const changeLanguage = (value: any) => {
    setLang(value);
    i18n.changeLanguage(value);

    // Update the URL with the selected language
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('lang', value);
    window.history.replaceState(null, '', newUrl.href);

    localStorage.setItem('selectedLanguage', value);
  };
  const GetStripeDashboardUrlAsync = () => {
    Api.get("Payment/GetAccountUrl").then(
      (response) => {
        if (response.status == 200) {
          const data = response.data;
          // window.location.href = data.url;
          window.open(data.url, "_blank");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };


  const GetMessages = async () => {
    var messagesFromDB = [] as any;
    var unReadCount = 0;
    // const {data} =await Api.get(ApiEndpoints.getMessages);
    // console.log(data)
    Api.get(ApiEndpoints.GetNotifications).then(
      (response) => {
        if (response && response.status == 200) {
          messagesFromDB = response.data?.result?.map((obj1: any) => {
            if (obj1.isRead == false) {
              ++unReadCount;
            }
          });

          setUnReadCount(unReadCount);
          // setMessages(messagesFromDB);
        } else {
          // setMessageString("Not found");
        }
      },
      (err) => {
        // setMessageString("Not found");
      }
    );
  };

  React.useEffect(() => {

    if (location.pathname === "/") {
      setTransparentBg(true);
    } else {
      setTransparentBg(false);
    }

    var currentState = loadState();
    if (!currentState) {
      currentState = initialState;
    }
    saveState(currentState);
debugger;
    // Check if a language is already set in local storage
    const storedLang = localStorage.getItem("selectedLanguage");
    // Check if the language is specified in the URL

    const urlParams = new URLSearchParams(window.location.search);
    const urlLang = urlParams.get('lang');


    if (urlLang) {
      setLang(urlLang); // Update the language state
      i18n.changeLanguage(urlLang); // Change the language in your app
    } else if (storedLang) {
      setLang(storedLang); // Update the language state
      i18n.changeLanguage(storedLang); // Change the language in your app
    }else{
      changeLanguage('en') // default case
    }

    // if (storedLang) {
    //   // If a language is found in local storage, set it as the current language
    //   setLang(storedLang);
    //   i18n.changeLanguage(storedLang);
    // } else {
    //   // If no language is found in local storage, set the default language to 'en'
    //   localStorage.setItem("selectedLanguage", "en");
    //   setLang('en');
    //   i18n.changeLanguage('en');

    // }

    if (isValidUser) {
      GetMessages();
    }
  }, [location, userDetails, mounted, i18n]);   

  return (
    <div className="eq-nav-menu">


      <Menu
        mode="vertical"
        selectedKeys={["1"]}
        theme="dark"
        onClick={handleClick}
        className={`${transparentBg ? "eq-header transparent-bg" : "eq-header"
          }`}
      >
        <Menu.Item key="8" unselectable="on" className="menu-logo-btn">
          {transparentBg ? (
            <div className="logo-home">
              <AsyncImage className="logo-image" src="images/Logo.svg" />
              Equinet
            </div>

          ) : (
            <div
              className={`${transparentBg ? "logo transparent-bg" : "logo"}`}
            >
              <AsyncImage className="logo-img" src="images/Logo.svg" />
              Equinet
            </div>
          )}
        </Menu.Item>

{/* Quick Links */}
        {ValidateIsUser(userDetails) ?
          (
            <>
              {(isTrainer 
              || isSchool
              ) && (
                <>
                  {checkPathForQuicLinks(location.pathname) && (
                    <Menu.Item key="34">
                      <Tooltip title={t("inviteStudent")} color="#9cb9ca">
                        <span className="quick-links">
                        <FontAwesomeIcon icon={faUserPlus} />
                        </span>
                      </Tooltip>
                    </Menu.Item>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              
            </>
          )
        }
        {ValidateIsUser(userDetails) ?
          (
            <>
              {(isTrainer 
              || isSchool
              ) && (
                <>
                  {checkPathForQuicLinks(location.pathname) && (
                    <Menu.Item key="35">
                      <Tooltip title={t("createActivities")} color="#9cb9ca">
                        <span className="quick-links">
                        <FontAwesomeIcon icon={faCalendarDays} />
                        </span>
                      </Tooltip>
                    </Menu.Item>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              
            </>
          )
        }
        {ValidateIsUser(userDetails) ?
          (
            <>
              {(isTrainer 
              || isSchool
              ) && (
                <>
                  {checkPathForQuicLinks(location.pathname) && (
                    <Menu.Item key="36" className="quick-links">
                      <Tooltip title={t("stripeDashboard")} color="#9cb9ca">
                        <span className="stripeDashboard" onClick={GetStripeDashboardUrlAsync}>
                        {/* <FontAwesomeIcon icon={faUserShield} /> */}
                        s         {/* Stripe */}
                        </span>
                      </Tooltip>
                    </Menu.Item>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              
            </>
          )
        }
        {ValidateIsUser(userDetails) ?
          (
            <>
              {isStudent && (
                <>
                  {checkPathForQuicLinks(location.pathname) && (
                    <Menu.Item key="37">
                      <Tooltip title={t("myTrainings")} color="#9cb9ca">
                        <span className="quick-links">
                        <FontAwesomeIcon icon={faCalendarDay} />
                        </span>
                      </Tooltip>
                    </Menu.Item>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              
            </>
          )
        }
        {/* {ValidateIsUser(userDetails) ?
          (
            <>
              {isStudent && (
                <>
                  {checkPathForQuicLinks(location.pathname) && (
                    <Menu.Item key="38">
                      <Tooltip title={t("calendar")} color="#9cb9ca">
                        <span className="quick-links">
                        <FontAwesomeIcon icon={faCalendarDays} />
                        </span>
                      </Tooltip>
                    </Menu.Item>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              
            </>
          )
        } */}
{/* end */}

        {ValidateIsUser(userDetails) ? (

          <>
            {checkPathForDashboard(location.pathname) && (
              <Menu.Item key="29" className="menu-dashbord-btn">
                <EqButton type="button" classNames="round-bordered">
                  {t("dashboard")}
                </EqButton>
              </Menu.Item>
            )}
          </>
        ) : (
          <Menu.Item key="6" className="menu-dashbord-btn">
            <EqButton type="button" classNames="round-bordered">
              {t("logIn")}
            </EqButton>
          </Menu.Item>
        )}



        {/* {checkPathForFeature(location.pathname) && (
          <Menu.Item key="33" className="menu-feature-btn">
            <EqButton type="button" classNames="round-bordered">
              {t("feature")}
            </EqButton>
          </Menu.Item>

        )} */}

        <Menu.SubMenu
          title={
            <span className="lang-title">
              <img
                src={languageFlags[lang]}
                alt={lang}
                className="lang-flag-title"

              />

              <span className="lang-in-title">
                {lang}
              </span>

            </span>
          }
          key="11" className="lang-btn">
          {languageOptions.map((option: any, index: any) => (
              <Menu.Item 
              key="12" 
              className="language-dropdown" onClick={() => changeLanguage(option.value)}>
              <img className="lang-flags" src={languageFlags[option.value]} alt={option.label} />
              <span className="lang-option">{option.label}</span>

            </Menu.Item>
          ))}
        </Menu.SubMenu>
      </Menu>

    </div>

  );
};

export default NavMenu;
