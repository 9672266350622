import React, { useState, useEffect } from "react";
import "./EqSchoolProductTrainings.scss";
import { Row, Col, Avatar, Skeleton, Empty } from "antd";
import { useTranslation, Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import EqButton from "../../custom/eqButton/EqButton";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import SchoolTrainingList from "../SchoolTrainingList/SchoolTrainingList";
import CreateOrEditSchoolTraining from "../createOrEditSchoolTraining/CreateOrEditSchoolTraining";

import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsUser,
  FrontEndPoints,
  ValidateIsSchool,
  getDefaultImage,
  imageType,
  GenericMessage,
  openNotificationWithIcon,
} from "../../shared/Common";
import Api from "../../shared/Api";
import { useAuthState } from "../../context/Context";
import { EqDisciplineType, ProductType } from "../../shared/enums";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { IGetSchoolTrainingDto } from "../../shared/interfaces/schoolTraining/IGetSchoolTrainingDto";

export interface IEqSchoolProductTrainings {
  updateCount?: (count: number) => void;
}
const EqSchoolProductTrainings = ({
  updateCount,
}: IEqSchoolProductTrainings) => {
  const userDetails: any = useAuthState();
  const navigate = useNavigate();
  const location = useLocation();
  const isUserValid: boolean = ValidateIsUser(userDetails);
  const { t, i18n } = useTranslation();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

  const tomorrow = new Date();
  tomorrow.setHours(24, 0, 0, 0);
  const tomorrowTime = tomorrow.getTime();

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [trainersList, setTrainersList] = useState([]) as any;
  const [trainersOptions, setTrainersOptions] = useState([]) as any;

  const [products, setProducts] = useState([]) as any;
  const [productOptions, setProductOptions] = useState({
    createOptions: [],
    editOptions: [],
  }) as any;

  const [areas, setAreas] = useState([]) as any;
  const [weekDays, setWeekDays] = useState([]) as any;
  const [eventTypes, setEventTypes] = useState([]) as any;

  const [schoolStudents, setSchoolStudents] = useState([]) as any;
  const [groupPopulation, setGroupPopulation] = useState([]) as any;
  const [studentHorsesList, setStudentHorsesList] = useState([]);

  const [createSchoolTrainingShow, setCreateSchoolTrainingShow] =
    useState(false);

  const [trainingsList, setTrainingsList] = useState([] as Array<IEqEventData>);

  const [trainingsCourseList, setTrainingsCourseList] = useState(null as any);
  const [activityTitle, setActivityTitle] = useState("");

  useEffect(() => {
    console.log("---School Product Trainings--", location);
    GetSchoolTrainers();
    GetSchoolStudents();
    createEventTypes();
    getWeekDays();
    GetProductList();
    getAreas();
    getGroupPopulation();
    GetAllSchoolProductTrainings();

    window.scrollTo(0, 0);
    if (location.state) {
      if (
        location.state.prevPath == FrontEndPoints.trainer.dashboard ||
        location.state.prevPath == FrontEndPoints.trainer.groupsBoard
      ) {
        setCreateSchoolTrainingShow(true);
      }
    }
  }, []);

  const GetSchoolTrainers = async () => {
    try {
      const response = await Api.get(ApiEndpoints.SchoolGetTrainers);
  
      if (response && response.status === 200) {
        const trainers = response.data;
        setTrainersList(trainers);
      
  
        const trainerObjList = [
          {
            key: userDetails.key,
            value: userDetails.name,
            label: userDetails.name,
            icon: (
              <Avatar
                size={30}
                src={
                  userDetails.userImage
                    ? userDetails.userImage.url
                    : getDefaultImage(imageType.trainer)
                }
              />
            ),
          },
          ...trainers.map((item:any) => ({
            key: item.key,
            value: item.firstName + item.lastName,
            label: item.firstName,
            icon: (
              <Avatar
                size={30}
                src={
                  item.userImage
                    ? item.userImage.url
                    : getDefaultImage(imageType.trainer)
                }
              />
            ),
          })),
        ];
  
        setTrainersOptions(trainerObjList);
      }
    } catch (error) {
      throw error;
    }
  };
  

  const GetSchoolStudents = () => {
    // Api.get(ApiEndpoints.GetSchoolStudents).then(
    Api.get(ApiEndpoints.SchoolStudents.GetSchoolStudentsGroupDto).then(
      (response) => {
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var schoolStudents = response.data;
          setIsError(false);
          setMessage("");
          setSchoolStudents(response.data);

          // getting both student and School Horses and making list
          // and adding school horse in each student horse list

          [...schoolStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.school.horses.length > 0) {
              [...item.school.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  // const GetSchoolStudents = () => {
  //   Api.get(ApiEndpoints.GetSchoolStudents).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         var studentHorsesListOptions = [] as any;
  //         var schoolStudents = response.data;
  //         setIsError(false);
  //         setMessage("");
  //         setSchoolStudents(response.data);

  //         // getting both student and School Horses and making list
  //         // and adding school horse in each student horse list

  //         [...schoolStudents].map((item: any) => {
  //           let horseListObj = [] as any;

  //           if (item.user.horses.length > 0) {
  //             [...item.user.horses].map((h: any) => {
  //               let horseObj = {
  //                 key: h.id,
  //                 value: h.name,
  //                 label: h.name,
  //               };
  //               horseListObj.push(horseObj);
  //             });
  //           }
  //           if (item.school.horses.length > 0) {
  //             [...item.school.horses].map((h: any) => {
  //               let horseObj = {
  //                 key: h.id,
  //                 value: h.name,
  //                 label: h.name,
  //               };
  //               horseListObj.push(horseObj);
  //             });
  //           }

  //           let studentHorsesObj = {
  //             key: item.key,
  //             horseList: horseListObj,
  //           };

  //           studentHorsesListOptions.push(studentHorsesObj);
  //         });
  //         setStudentHorsesList(studentHorsesListOptions);
  //       } else {
  //         setIsError(true);
  //         setMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };

  const createEventTypes = () => {
    var eventTypes = [];

    eventTypes.push({
      key: EqDisciplineType.RidingCourse,
      value: EqDisciplineType[EqDisciplineType.RidingCourse],
    });

    setEventTypes(eventTypes);
  };

  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getAreas = () => {
    Api.get(ApiEndpoints.eventGetAreas).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetProductList = () => {
    Api.get(ApiEndpoints.UserProduct).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          let data = response.data;

          var createProductOptions = [] as any;
          var editProductOptions = [] as any;

          if (data) {
            var publicProducts = data?.filter(
              (opt: any) => opt.type == ProductType.Public
            );

            publicProducts.map((item: any, Idx: any) => {
              let obj = {
                key: item.id,
                value: item.title,
                label: item.title,
                limit: item.limit,
              };
              if (item.isUsed == false) {
                createProductOptions.push(obj);
              } else {
                obj.value = obj.value + "- used";
              }
              editProductOptions.push(obj);
            });

            let obj = {
              createOptions: createProductOptions,
              editOptions: editProductOptions,
            };
            setProductOptions({ ...obj });
            setProducts(publicProducts);
          }
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
    // if (updateCount) {
    //   updateCount(trainingsCourseList.length);
    // }
  };

  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const handleChangeActivityTitle = (e: any) => {
    setActivityTitle(e);
  };

  const updateTrainingsList = (data: any) => {
    setTrainingsList(data);

    let latestData = data?.filter((opt: any) => opt.startDate >= todayTime);
    let course = groupByReferenceKey(latestData);
    setTrainingsCourseList(course);
  };

  const handleOnCreateTraining = (createdTrainings: any) => {
    console.log(createdTrainings);
    const trainings = [...trainingsList];
    createdTrainings.forEach((e: any) => {
      trainings.push(e);
    });
    updateTrainingsList(trainings);
    if (updateCount) {
      updateCount(trainingsCourseList.length);
    }
  };

  const handleOnUpdateTraining = (newData: any, oldData: any) => {
    if (trainingsList != undefined) {
      const trainings = [...trainingsList];
      var foundIndex = trainings.findIndex((x) => x.key == oldData.key);
      trainings[foundIndex] = newData;
      updateTrainingsList(trainings);
    }
  };

  const removeTraining = (
    trainingData: IGetSchoolTrainingDto,
    refund?: boolean
  ) => {
    let path =
      ApiEndpoints.SchoolTraining.Delete +
      `?key=${trainingData.key}&refund=${refund}`;
    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(trainingData);
        } else {
          setIsError(true);
          setErrorMessage(GenericMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GenericMessage(error.response.status));
        openNotificationWithIcon(
          GenericMessage(error.response.status),
          "Error occurred while Deleting. Please try again or contact administrator."
        );
      }
    );
    if (updateCount) {
      updateCount(trainingsList.length);
    }
  };

  const GetAllSchoolProductTrainings = async () => {
    try {
      setErrorMessage("");
      let path = ApiEndpoints.GetAllSchoolProductTrainings;
      const response = await Api.get(path);

      if (response && response.status === 200) {
        const data = response.data ? [...response.data] : new Array<any>();
        updateTrainingsList(data);
      } else {
        setIsError(true);
        setErrorMessage(GetEventMessage(response.status));
      }
    } catch (error: any) {
      setIsError(true);
      setErrorMessage(GetEventMessage(error.response.status));
    }
  };

  // const GetAllSchoolProductTrainings = async () => {
  //   setErrorMessage("");
  //   let path = ApiEndpoints.GetAllSchoolProductTrainings;
  //   Api.get(path).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         var data = response.data ? [...response.data] : new Array<any>();
  //         setTrainingsList(data);

  //         let latestData = data?.filter(
  //           (opt: any) => opt.startDate >= todayTime
  //         );
  //         var course = groupByReferenceKey(latestData);
  //         setTrainingsCourseList(course);
  //         // console.log("GetTrainerPrivateTrainings");

  //         // console.log(data);
  //       } else {
  //         setIsError(true);
  //         setErrorMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setErrorMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };
  const groupByReferenceKey = (data: any) => {
    const groupedData = {} as any;
    data.forEach((obj: any) => {
      const referenceKey = obj.referenceKey;
      if (!groupedData[referenceKey]) {
        groupedData[referenceKey] = {
          referenceKey: referenceKey,
          title: obj.title,
          trainings: [],
        };
      }
      groupedData[referenceKey].trainings.push(obj);
    });
    return Object.values(groupedData);
  };

  const removeItem = (data: any) => {
    if (trainingsList != undefined) {
      var updatedList =
        trainingsList != undefined
          ? trainingsList.filter((item) => item.key !== data.key)
          : new Array<any>();
      setTrainingsList(updatedList);

      let latestData = updatedList?.filter(
        (opt: any) => opt.startDate >= todayTime
      );
      var course = groupByReferenceKey(latestData);
      setTrainingsCourseList(course);
      trainingsList.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  return (
    <div className={`eq-school-training`}>
      <div className="eq-container-fluid">
        <Row align="middle" justify="end">
          {!createSchoolTrainingShow && (
            <>
              {ValidateIsSchool(userDetails) ? (
                <Row align="middle" justify="end">
                  <Col className="create-button">
                    <EqButton
                      type="button"
                      classNames="btn-background-orange"
                      onClick={() => setCreateSchoolTrainingShow(true)}
                    >
                      {t("createRidingCourse")}
                    </EqButton>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </>
          )}
        </Row>

        <Row className="eq-event-heading-line"></Row>

        {/* singleCreateSchoolTrainingComponent */}

        {createSchoolTrainingShow && (
          <EqCollapsePanel
            defaultOpen={true}
            header={t("createRidingCourse")}
            children={
              <CreateOrEditSchoolTraining
                onActivityTitleChange={(e: any) => {
                  handleChangeActivityTitle(e);
                }}
                eventKey={0}
                eventTypes={eventTypes}
                trainersOptions={trainersOptions}
                products={products}
                productOptions={productOptions.createOptions}
                areas={areas}
                weekDaysData={weekDays}
                // population={population}
                groupPopulation={groupPopulation}
                schoolStudents={schoolStudents}
                studentHorsesList={studentHorsesList}
                onCreateOrUpdateSchoolTraining={handleOnCreateTraining}
                onCancelSchoolTraining={() =>
                  setCreateSchoolTrainingShow(false)
                }
                isStudent={false}
              />
            }
          />
        )}

        <Row>
          {[...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              loading={!trainingsCourseList}
              paragraph={{ rows: 2 }}
              active
            />
          ))}
        </Row>

        {trainingsCourseList && (
          <>
            {trainingsCourseList.length > 0 ? (
              trainingsCourseList.map((course: any) => (
                <EqCollapsePanel
                  defaultOpen={false}
                  header={course.title}
                  children={
                    <div className="training-course-list">
                      <SchoolTrainingList
                        trainingList={course.trainings}
                        trainersOptions={trainersOptions}
                        eventTypes={eventTypes}
                        productOptions={productOptions.editOptions}
                        products={products}
                        areas={areas}
                        weekDays={weekDays}
                        // population={population}
                        groupPopulation={groupPopulation}
                        schoolStudents={schoolStudents}
                        studentHorsesList={studentHorsesList}
                        removeEventFunc={removeTraining}
                        onCreateOrUpdateSchoolTraining={handleOnUpdateTraining}
                      ></SchoolTrainingList>
                    </div>
                  }
                />
              ))
            ) : (
              <Row justify="center">
                <Empty description={t("noTrainingsAvailable")} />
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default EqSchoolProductTrainings;
