import React, { useState, useEffect } from "react";
import "./EqEditStudent.scss";
import { Row, Col, Checkbox } from "antd";
import EqCollapse, { IEqCollapse } from "./../../custom/eqCollapse/EqCollapse";
export interface IEqEditStudent {
  name: string;
  area: string;
  disciplines: Array<string>;
  selected: boolean;
  display: boolean;
}

const EqEditStudent = ({
  name,
  area,
  disciplines,
  selected,
  display,
}: IEqEditStudent) => {
  const [check, setCheck] = useState(selected);
  useEffect(() => {
    checkChange(selected);
  }, [selected]);
  function checkChange(selected: boolean) {
    setCheck(selected);
  }
  function onChange(e: any) {
    stop(e);
    setCheck(!check);
  }
  function stop(e: any) {
    e.stopPropagation();
  }
  const CollapseProps: IEqCollapse = {
    display: display,
    header: [
      <Row justify="start" align="middle">
        <Col className="" span={2}>
          <Checkbox
            onChange={onChange}
            checked={check}
            onClick={stop}
          ></Checkbox>
        </Col>
        <Col flex="auto">{name}</Col>
      </Row>,
    ],
  };
  return (
    <>
      {display}
      <Row className="eq-row hidden-sm">
        <Col className="" span={1}>
          <Checkbox
            onChange={onChange}
            checked={check}
            onClick={stop}
          ></Checkbox>
        </Col>
        <Col span={5}>{name}</Col>
        <Col span={5}>{area}</Col>
        <Col flex="auto">
          <Row>
            {disciplines.length > 0 && (
              <>
                {disciplines.map((item: string, index: number) => (
                  <Col className="eq-col-discipline">{item}</Col>
                ))}
              </>
            )}
          </Row>
        </Col>
        <Col span={2}>
          <span className="eq-action-invite">
            <img alt="" src="/Invite.svg" />
          </span>
        </Col>
      </Row>

      <div className="hidden-md">
        <EqCollapse {...CollapseProps}>
          <>
            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                {" "}
                Area
              </Col>
              <Col span={24}>{area}</Col>
            </Row>
            <Row>
              <Col flex="auto" className="eq-card-divider"></Col>
            </Row>
            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                Disciplines
              </Col>
              <Col span={24}>
                <Row>
                  {disciplines.length > 0 && (
                    <>
                      {disciplines.map((item: string, index: number) => (
                        <Col className="eq-col-discipline">{item}</Col>
                      ))}
                    </>
                  )}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col flex="auto" className="eq-card-divider"></Col>
            </Row>
            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                Actions
              </Col>
              <Col span={24}>
                <span className="eq-action-invite">
                  <img alt="" src="/Invite.svg" />
                </span>
              </Col>
            </Row>
          </>
        </EqCollapse>
      </div>
    </>
  );
};
export default EqEditStudent;
