import * as React from "react";
import "./EqTrainerAllPreviousEvents.scss";
import { Row, Skeleton, Empty } from "antd";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsTrainer,
  ValidateIsSchool,
  openNotificationWithIcon,
} from "../../shared/Common";
import Api from "../../shared/Api";
import { useTranslation } from "react-i18next";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useAuthState } from "../../context/Context";
import EqActivityCollapse from "../eqActivityCollapse/EqActivityCollapse";
import EqPreviousEventCollapse from "./previousEventCollapse/EqPreviousEventCollapse";
import {
  IClassTimes,
  ICreateEventDto,
  IEventClassDto,
} from "../../shared/interfaces/ICreateEventDto";
import moment from "moment";
import { eqNotificationTypes } from "../../shared/enums";
import EventClass from "../eqEventsView/EqEventsView";
import { Spin } from "antd-v5";

export interface IEqTrainerAllPreviousEvents {
  onCreateDuplicateEvent: (event: any) => void;
}
const EqTrainerAllPreviousEvents = ({
  onCreateDuplicateEvent,
}: IEqTrainerAllPreviousEvents) => {
  const userDetails: any = useAuthState();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const isSchool: boolean = ValidateIsSchool(userDetails);
  const { t, i18n } = useTranslation();

  const [message, setMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [areas, setAreas] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState([]) as any;
  const [population, setPopulation] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;
  const [studentHorsesList, setStudentHorsesList] = React.useState({});
  const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
  const [spinning, setSpinning] = React.useState<boolean>(false);

  const [imagesData, setImagesData] = React.useState([]) as any;

  const [CreatePublicEventShow, setCreatePublicEventShow] =
    React.useState(true);

  const [eventsList, setEventsList] = React.useState(null) as any;
  // const [eventsList, setEventsList] = React.useState([] as Array<IEqEventData>);

  React.useEffect(() => {
    GetTrainerPublicEvents();
    GetTrainerStudents();
    getImages();
    getAreas();
    getWeekDays();
    getPopulation();
    getGroupPopulation();

    window.scrollTo(0, 0);
  }, []);

  const getAreas = async () => {
    try {
      const response = await Api.get(ApiEndpoints.eventGetAreas);
      if (response && response.status === 200) {
        setAreas(response.data);
      } else {
        setMessage(GetEventMessage(response.status));
      }
    } catch (error: any) {
      setIsError(true);
      setMessage(GetEventMessage(error.response.status));
    }
  };

  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setPopulation(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setGroupPopulation(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getImages = () => {
    Api.get("image/getEventImages").then(
      (response) => {
        if (response.data && response.data.length) {
          setImagesData(response.data);
        }
      },
      () => {
        setIsError(true);
        setMessage("Error Occurred while fetching images from server");
      }
    );
  };

  const GetTrainerStudents = () => {
    Api.get(ApiEndpoints.GetTrainerStudents).then(
      (response) => {
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var trainerStudents = response.data;
          setIsError(false);
          setMessage("");
          setTrainerStudents(response.data);
          [...trainerStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.trainer.horses.length > 0) {
              [...item.trainer.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetTrainerPublicEvents = async () => {
    let path = `${
      ApiEndpoints.GetTrainerPreviousEventsByMonth
    }?monthCount=${2}`;
    Api.get(path).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          setEventsList(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };


  const setEventObject = (data: any): any => {
    const newDto: ICreateEventDto = {
      accessType: data.accessType,
      eqImage: data.eqImage,
      title: data.title,
      disciplineType: data.disciplineType,
      currency: data.currency,
      locationKey: +data.locationKey,
      email: data.email,
      phoneNumber: data.phoneNumber,
      description: data.description,
      eventTermsFile: data.eventTermsFile,

      customClassName: data.customClassName,
      isClassPreviewAllowed: data.isClassPreviewAllowed,
      // multiple startDates
      // EventClasses
      // Questions
      multipleStartDates: [],
      eventClasses: [],
      questions: data.questions ? [...data.questions] : [],
    };

    let dateToday = new Date();

    data.eventClasses?.forEach((eventClass: any, idxClass: number) => {
      const dStartClass = moment(dateToday, "DD/MM/YYYY")
        .add(idxClass + 1, "days")
        .toDate();

      const classTimes = eventClass.classTimes.map((classTime: any) => {
        const dClassTimeFrom = new Date(classTime.startTime);
        const calClassTimeFrom = new Date(
          dStartClass.getFullYear(),
          dStartClass.getMonth(),
          dStartClass.getDate(),
          dClassTimeFrom.getHours(),
          dClassTimeFrom.getMinutes(),
          0,
          0
        );

        return {
          ...classTime,
          startDate: moment(dStartClass.valueOf()).utc().valueOf(),
          startTime: moment(calClassTimeFrom.valueOf()).utc().valueOf(),
        };
      });

      newDto.multipleStartDates?.push(
        moment(dStartClass.valueOf()).utc().valueOf()
      );
      newDto.eventClasses?.push({
        trainingLessonsKey: "",
        startDate: moment(dStartClass.valueOf()).utc().valueOf(),
        classTimes,
      });
    });
    return newDto;
  };

  const handleCreateDuplicateEvent = (event: any) => {
    var data = setEventObject(event);
    if (data != null) {
      setSpinning(true);
      // console.log(data);
      
      Api.post(ApiEndpoints.CreateTrainerEvent, data).then(
        (response) => {
      setSpinning(false);

          if (response && response.status == 200) {
              onCreateDuplicateEvent(response.data);
            
            openNotificationWithIcon(
              "Duplicate!",
              "You have successfully made a copy of your event",
              eqNotificationTypes.success
            );

            setIsError(false);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the event(s). Please try again or contact administrator."
            );
            setIsError(true);
          }
        },
        (error) => {
      setSpinning(false);

          if (error?.response?.status === 403) {
            openNotificationWithIcon(
              "Server error",
              "Not Authorized Kindly Add Payment or contact administrator."
            );
            setIsError(true);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the event(s). Please try again or contact administrator."
            );
            setIsError(true);
          }
        }
      );
    } else {
      setIsError(true);
    }
  };


  const handleOnUpdateEvent = (newData: any, oldData: any) => {
    if (eventsList != undefined) {
      const events = [...eventsList];
      var foundIndex = events.findIndex(
        (x) => x.referenceKey == oldData.referenceKey
      );
      events[foundIndex] = newData;
      setEventsList(events);
    }


  };


  const handleOnRemoveEvent = (data: IEqEventData, refund?: boolean) => {
    // let path=ApiEndpoints.DeleteTrainerEvent + `/${data.key}`;
    setSpinning(true);

    let path =
      ApiEndpoints.DeleteTrainerEvent + `?key=${data.key}&refund=${refund}`;

    Api.delete(path).then(
      (response) => {
      setSpinning(false);

        if (response && response.status == 200) {
          removeItem(data);
          // GetTrainerPublicEvents();
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
      setSpinning(false);

        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );

 
  };
  const removeItem = (data: any) => {
    
    if (eventsList != undefined) {
      var updatedList =
        eventsList != undefined
          ? eventsList.filter((item: any) => item.key !== data.key)
          : new Array<any>();
      setEventsList(updatedList);

  
    }
  };

  //${(!isUserValid || location.pathname == FrontEndPoints.trainer.allEventsBoard) ? "container" : ""}
  return (
    <div className={`eq-trainer-event-board`}>
      <Spin spinning={spinning} size="large">

      
      <div className="eq-container-fluid">
        <Row>
        {[...Array(5)].map((_, index) => (
          <Skeleton key={index} loading={!eventsList} paragraph={{ rows: 2 }} active />
        ))}
        </Row>
        {eventsList && (
          <>
            {eventsList.length > 0 ? (
              <>
                {eventsList.map((item: any, index: number) => (
                  <EqPreviousEventCollapse
                    key={index}
                    event={item}
                    areas={areas}
                    weekDays={weekDays}
                    population={population}
                    groupPopulation={groupPopulation}
                    trainerStudents={trainerStudents}
                    studentHorsesList={studentHorsesList}
                    removeEventFunc={handleOnRemoveEvent}
                    duplicateEvent={handleCreateDuplicateEvent}
                    onCreateOrUpdateActivity={handleOnUpdateEvent}

                  ></EqPreviousEventCollapse>
                ))}
              </>
            ) : (
              <Row justify="center">
                <Empty description={t("noEventsAvailable")} />
              </Row>
            )}
          </>
        )}
      </div>
      </Spin>
    </div>
  );
};
export default EqTrainerAllPreviousEvents;
