import * as React from "react";
import { useEffect, useState } from "react";
import {
  FrontEndPoints,
  getDefaultImage,
  imageType,
} from "../../shared/Common";
import "./EqSchoolDashboard.scss";
import { Row, Col, Checkbox, Avatar, Skeleton, DatePicker } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import { useTranslation, Trans } from "react-i18next";

import {
  EqDisciplineType,
  EqLocationType,
  EqMessageType,
  EqTrainingType,
  ProductType,
  paymentStatusType,
} from "../../shared/enums";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import Api from "../../shared/Api";
import {
  GetEventMessage,
  ApiEndpoints,
  ValidateIsSchool,
} from "../../shared/Common";

import { IStudentDto } from "../../shared/interfaces/IStudentDto";

import { useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import EqMessage, {
  IEqMessage,
  IEqMessageData,
} from "../../eqTrainer/eqMessage/EqMessage";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import SchoolTrainingList from "../SchoolTrainingList/SchoolTrainingList";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import EqActivityTrainingList from "../../eqTrainer/eqActivityTrainingList/EqActivityTrainingList";
import EqActivityTrainingListDashboard from "../../eqTrainer/eqActivityTrainingList/EqActivityTrainingListDashbord/EqActivityTrainingListDashboard";
import { IGetSchoolTrainingDto } from "../../shared/interfaces/schoolTraining/IGetSchoolTrainingDto";
import EqTrainerTrainingJoinRequestBoard from "../../eqTrainer/trainerTrainingJoinRequestBoard/EqTrainerTrainingJoinRequestBoard";
import EqOverviewProfile from "../../eqTrainer/overViewTrainerProfile/EqOverviewTrainerProfile";
import EqSchoolTrainingListDashbord from "../SchoolTrainingList/EqSchoolTrainingListDashbord/EqSchoolTrainingListDashbord";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import EqSelect from "../../custom/eqSelect/EqSelect";
import { ISelectProps } from "../../shared/Interfaces";
import EqActivityListSchoolDashboard from "../eqActivityListSchoolDashboard/EqActivityListSchoolDashboard";
import EqOverviewSchoolProfile from "../overViewSchoolProfile/OverViewSchoolProfile";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";

const EqSchoolDashboard = (props: any) => {
  const userDetails: any = useAuthState();
  const isSchool: boolean = ValidateIsSchool(userDetails);
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [messages, setMessages] = useState([] as Array<any>);
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState("");

  // school Trainings
  const [showEventList, setShowEventList] = React.useState(true);
  const [trainingsList, setTrainingsList] = React.useState(null) as Array<any>;
  const [trainingInvitation, setTrainingInvitation] = useState(true);

  const [areas, setAreas] = React.useState([]) as any;
  const [trainersList, setTrainersList] = React.useState([]) as any;
  const [trainersOptions, setTrainersOptions] = React.useState([]) as any;
  const [productOptions, setProductOptions] = React.useState({
    createOptions: [],
    editOptions: [],
  }) as any;
  const [products, setProducts] = React.useState([]) as any;

  const [weekDays, setWeekDays] = React.useState([]) as any;
  const [eventTypes, setEventTypes] = React.useState([]) as any;

  const [population, setPopulation] = React.useState([]) as any;
  const [schoolStudents, setSchoolStudents] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;
  const [studentHorsesList, setStudentHorsesList] = React.useState([]);

  // events
  const [eventsList, setEventsList] = React.useState(null) as Array<any>;


  const [schoolEvents, setSchoolEvents] = React.useState(null) as any;
  const [schoolTrainings, setSchoolTrainings] = React.useState(null) as any;
  const [stateView, setStateView] = useState({
    unpaidTraining: [],
    pendingTraining: [],
    unpaidProduct: [],
    pendingProduct: [],
    upcomingTraining: [],
    monthlySales: []
  });


  //filter
  const [selectedTraining, setSelectedTraining] = React.useState([] as any);
  const [trainingOptions, setTrainingOptions] = React.useState([] as any);

  const [selectedEvents, setSelectedEvents] = React.useState([] as any);
  const [eventsOptions, setEventsOptions] = React.useState([] as any);

  const [fromDate, setFromDate] = useState<moment.Moment | null>(null);


  //
  var selectedMessages = new Array<any>();
  const [keys, setKeys] = useState(new Array<any>());
  const [misCheckAll, msetIsCheckAll] = useState(false);

  const [messageString, setMessageString] = useState("");
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

  // update required
  //  Message Section----->
  const replyEmail = (message: IEqMessageData) => {
    GetMessages();
  };
  const mardFlagedEmail = (message: IEqMessageData) => {
    if (message != null) {
      Api.post(ApiEndpoints.saveMessage, {
        Message: { Key: message.key },
        IsFlaged: message.isFlaged,
        IsRead: message.isRead,
      }).then(
        (response) => {
          if (response && response.status == 200) {
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const deleteEmail = (message: IEqMessageData) => {
    if (message != null) {
      Api.post(ApiEndpoints.deleteMessage, {
        Message: { Key: message.key },
      }).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };

  //stat
  const dashboardState = () => {

    Api.get(ApiEndpoints.GetSchoolDashboardState)
      .then((response) => {
        if (response && response.status === 200) {
          // var result = response.data;
          const { pendingProductInvites,
            pendingTrainingInvites,
            unPaidProductOrders,
            unPaidTrainingOrders,
            upcomingTrainings,
            monthlySales
          } =
            response.data;
          setStateView({
            unpaidTraining: unPaidTrainingOrders,
            pendingTraining: pendingTrainingInvites,
            unpaidProduct: unPaidProductOrders,
            pendingProduct: pendingProductInvites,
            upcomingTraining: upcomingTrainings,
            monthlySales: monthlySales
          });
          console.log("datsssssss", response.data)
        } else {
          setIsError(true);
          (GetEventMessage(response.status));
        }
      })
      .catch((error) => {
        (GetEventMessage(error.response.status));
      });
  };

  const GetSchoolEventsByMonth = () => {
    Api.get(ApiEndpoints.GetTrainerEventsByMonth)
      .then((response) => {
        if (response && response.status === 200) {
          var data = response.data
          setSchoolEvents(data);
        } else {
          setIsError(true);
          GetEventMessage(response.status);
        }
      })
      .catch((error) => {
        GetEventMessage(error.response.status);
      });
  };

  const GetSchoolTrainingByMonth = () => {

    Api.get(ApiEndpoints.SchoolTrainingsOfMonths)
      .then((response) => {
        if (response && response.status === 200) {
          var data = response.data
          setSchoolTrainings(data);


          console.log("School training", data)
        } else {
          setIsError(true);
          GetEventMessage(response.status);
        }
      })
      .catch((error) => {
        GetEventMessage(error.response.status);
      });
  };
  const onCheckChange = (message: IEqMessageData, checked: boolean) => {
    if (checked) {
      selectedMessages.push({ Key: message.key });
    } else {
      if (selectedMessages != null && selectedMessages.length > 0) {
        const index = selectedMessages.findIndex((x) => x.Key == message.key);
        if (index > -1) {
          selectedMessages.splice(index, 1);
        }
      }
    }

    setKeys(selectedMessages);
  };

  const deleteSelectedMessages = () => {
    msetIsCheckAll(false);

    if (keys?.length > 0) {
      Api.post(ApiEndpoints.deleteSelectedMessages, keys).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();

            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const readSelectedMessages = () => {
    msetIsCheckAll(false);
    selectedMessages = [];

    keys.map((opt: any) => {
      selectedMessages.push({ Message: { Key: opt.Key }, IsRead: true });
    });
    if (keys?.length > 0) {
      Api.post(ApiEndpoints.saveSelectedMessages, selectedMessages).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();
            selectedMessages = [];
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };

  const messagesCheckAll = () => {
    msetIsCheckAll(!misCheckAll);

    messages.map((m: IEqMessage) => {
      if (m.selected == false) {
        m.selected = !misCheckAll;
        selectedMessages.push({ Key: m.message.key });
      } else {
        m.selected = !misCheckAll;
        if (selectedMessages != null && selectedMessages.length > 0) {
          const index = selectedMessages.findIndex(
            (x) => x.Key == m.message.key
          );
          if (index > -1) {
            selectedMessages.splice(index, 1);
          }
        }
      }
      setKeys(selectedMessages);
    });
    const modifiedList = [...messages];
    setMessages(modifiedList);
  };
  const GetMessages = async () => {
    setMessageString("");
    var messagesFromDB = [] as any;
    await Api.get(ApiEndpoints.getMessages).then(
      (response) => {
        if (response && response.status == 200) {
          response.data?.result?.map((d: any) => {
            if (d.message.messageType !== EqMessageType.Notification) {
              messagesFromDB.push({
                message: {
                  key: d.message.key,
                  messageOn: new Date(d.message.sentOn),
                  type: EqMessageType[d.message.messageType],
                  from: d.message?.sender?.userName,
                  to: "Riding School",
                  subject: d.message.subject,
                  text: d.message.message,
                  isRead: d.isRead,
                  isSent: d.isSent,
                  isFlaged: d.isFlaged,
                },
                checkBoxBordered: false,
                clickedReplyFun: replyEmail,
                clickedFlagFun: mardFlagedEmail,
                clickedDeleteFunc: deleteEmail,
                onCheckChangeFunc: onCheckChange,
                selected: false,
              });
            }
          });
          messagesFromDB = messagesFromDB?.sort((obj1: any, obj2: any) => {
            if (obj1.message.isRead > obj2.message.isRead) {
              return 1;
            } else if (obj1.message.isRead < obj2.message.isRead) {
              return -1;
            } else {
              return 0;
            }
          });

          setMessages(messagesFromDB);
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };

  //---------------//
  //------> School Trainings

  const GetAllSchoolTrainingsByWithLessons = async () => {
    setMessage("");
    let path =
      ApiEndpoints.GetAllSchoolTrainingsWithLessons +
      `?ExcludeDisciple=${EqDisciplineType.Open}`;
    Api.get(path).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          setTrainingsList(data);

        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetSchoolStudents = () => {
    Api.get(ApiEndpoints.GetSchoolStudents).then(
      (response) => {
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var schoolStudents = response.data;
          setIsError(false);
          // console.log(userDetails);
          setMessage("");
          setSchoolStudents(response.data);
          // console.log(schoolHorsesList);
          [...schoolStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.school.horses.length > 0) {
              [...item.school.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
          console.log("studentHorses:", studentHorsesListOptions);
          // console.log("TrainerStudents:",response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setPopulation(response.data);
          // console.log("population:",response.data);
          // console.table(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getAreas = () => {
    Api.get(ApiEndpoints.eventGetAreas).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetSchoolTrainers = async () => {
    try {
      const response = await Api.get(ApiEndpoints.SchoolGetTrainers);
  
      if (response && response.status === 200) {
        const trainers = response.data;
        setTrainersList(trainers);
      
  
        const trainerObjList = [
          {
            key: userDetails.key,
            value: userDetails.name,
            label: userDetails.name,
            icon: (
              <Avatar
                size={30}
                src={
                  userDetails.userImage
                    ? userDetails.userImage.url
                    : getDefaultImage(imageType.trainer)
                }
              />
            ),
          },
          ...trainers.map((item:any) => ({
            key: item.key,
            value: item.firstName + item.lastName,
            label: item.firstName,
            icon: (
              <Avatar
                size={30}
                src={
                  item.userImage
                    ? item.userImage.url
                    : getDefaultImage(imageType.trainer)
                }
              />
            ),
          })),
        ];
  
        setTrainersOptions(trainerObjList);
      }
    } catch (error) {
      throw error;
    }
  };

  const GetProductList = () => {
    Api.get(ApiEndpoints.UserProduct).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          let data = response.data;

          var createProductOptions = [] as any;
          var editProductOptions = [] as any;

          if (data) {
            var publicProducts = data?.filter(
              (opt: any) => opt.type == ProductType.Public
            );

            publicProducts.map((item: any, Idx: any) => {
              let obj = {
                key: item.id,
                value: item.title,
                label: item.title,
                limit: item.limit,
              };
              if (item.isUsed == false) {
                createProductOptions.push(obj);
              } else {
                obj.value = obj.value + "- used";
              }
              editProductOptions.push(obj);
            });

            let obj = {
              createOptions: createProductOptions,
              editOptions: editProductOptions,
            };
            setProductOptions({ ...obj });
            setProducts(publicProducts);
          }
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const createEventTypes = () => {
    var eventTypes = [];

    eventTypes.push({
      key: EqDisciplineType.Dressage,
      value: EqTrainingType[EqDisciplineType.Dressage],
    });
    eventTypes.push({
      key: EqDisciplineType.Jumping,
      value: EqTrainingType[EqDisciplineType.Jumping],
    });
    eventTypes.push({
      key: EqDisciplineType.Open,
      value: EqTrainingType[EqDisciplineType.Open],
    });
    eventTypes.push({
      key: EqDisciplineType.RidingCourse,
      value: EqDisciplineType[EqDisciplineType.RidingCourse],
    });

    setEventTypes(eventTypes);
  };

  // const handleOnUpdateTraining = (newData: any, oldData: any) => {
  //   if (trainingsList != undefined) {
  //     const trainings = [...trainingsList];
  //     var foundIndex = trainings.findIndex((x) => x.key == oldData.key);
  //     trainings[foundIndex] = newData;
  //     setTrainingsList(trainings);
  //   }
  // };

  const handleOnUpdateSchoolEvent = () => {
    GetSchoolEventsByMonth();
  };
  const handleOnUpdateSchoolTraining = () => {
    GetSchoolTrainingByMonth();
  };

  const editTraining = (data: IEqEventData) => {
    navigate(FrontEndPoints.trainer.createEvent, { state: data });
    // history.push({ pathname: FrontEndPoints.trainer.createEvent, });
    // history.push(FrontEndPoints.trainer.createEvent, { event: data });
  };
  const toggleEventList = () => {
    setShowEventList(!showEventList);
  };

  const removeTraining = (trainingData: IGetSchoolTrainingDto, refund?: boolean) => {

    
    let path =
      ApiEndpoints.SchoolTraining.Delete +
      `?key=${trainingData.key}&refund=${refund}`;
    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(trainingData);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const removeItem = (data: any) => {
    if (trainingsList != undefined) {
      var updatedList =
        trainingsList != undefined
          ? trainingsList.filter((item: any) => item.key !== data.key)
          : new Array<any>();
      setSchoolTrainings(updatedList);

      trainingsList.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  //---------> Events

  const GetTrainerPublicEvents = async () => {
    let path = ApiEndpoints.GetTrainerEvents;

    Api.get(path).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          setEventsList(data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const handleOnUpdateEvent = (newData: any, oldData: any) => {

    GetSchoolEventsByMonth();
    // if (eventsList != undefined) {
    //   const events = [...eventsList];
    //   var foundIndex = events.findIndex(
    //     (x) => x.referenceKey == oldData.referenceKey
    //   );
    //   events[foundIndex] = newData;
    //   setEventsList(events);
    // }
  };
  const removeEvent = (data: IEqEventData, refund?: boolean) => {
    let path =
      ApiEndpoints.DeleteTrainerEvent +
      `?key=${data.key}&refund=${refund}`;
    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeEventItem(response.data);
          GetTrainerPublicEvents();
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const removeEventItem = (data: any) => {
    GetSchoolEventsByMonth();
    // if (eventsList != undefined) {
    //   var updatedList =
    //     eventsList != undefined
    //       ? eventsList.filter((item: any) => item.key !== data.key)
    //       : new Array<any>();
    //   setEventsList(updatedList);

    //   eventsList.map((item: any, index: number) => {
    //     item.display = index == 0 ? true : false;
    //   });
    // }
  };
  const GetStripeDashboardUrlAsync = () => {
    Api.get("Payment/GetAccountUrl").then(
      (response) => {
        if (response.status == 200) {
          const data = response.data;
          // window.location.href = data.url;
          window.open(data.url, "_blank");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  //filter for training
  const filterTrainings = (trainingList: any[]) => {

    let filteredList = trainingList;
    if (selectedTraining && selectedTraining.length > 0) {
      filteredList = filteredList.filter((item) => {
        return selectedTraining.some((selectedType: any) => item?.disciplineType === selectedType);
      }
      );
    }



    if (fromDate) {
      const FromDate = moment(fromDate).startOf("day").valueOf();
      filteredList = filteredList.filter((item) => {

        const startDate = moment(item?.startDate).startOf("day").valueOf();
        return startDate === FromDate;
      });
    }

    console.log(filteredList)
    return filteredList;
  }


  const trainingTypeProps: ISelectProps = {
    classNames: `eq-multiSelect eq-searchSelect`,
    placeholder: t("training"),
    extraHeight: true,
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedTraining ? selectedTraining : [],
    options: trainingOptions,
    changeFunc: (value: any) => {
      console.log(value);
      setSelectedTraining(value);
    },
  };
  const createTrainingList = () => {
    var eventTypes = [];
    eventTypes.push({
      key: EqDisciplineType.Dressage,
      value: EqTrainingType[EqTrainingType.PrivateLesson],
    });
    eventTypes.push({
      key: EqDisciplineType.Jumping,
      value: EqTrainingType[EqTrainingType.GroupLesson],
    });
    eventTypes.push({
      key: EqDisciplineType.Open,
      value: EqTrainingType[EqTrainingType.AvailableTimeSlots],
    });
    setTrainingOptions(eventTypes);
    console.log("options", trainingOptions);
  };


  const filterEvents = (eventList: any[]) => {

    let filteredList = eventList;
    if (selectedEvents && selectedEvents.length > 0) {
      filteredList = filteredList.filter((item) => {
        return selectedEvents.some((selectedType: any) => item?.disciplineType === selectedType);
      });
    }

    if (fromDate) {
      const FromDate = moment(fromDate).startOf("day").valueOf();
      filteredList = filteredList.filter((item) => {

        const startDate = moment(item?.startDate).startOf("day").valueOf();
        return startDate === FromDate;
      });
    }

    console.log(filteredList)
    return filteredList;
  }


  const eventTypeProps: ISelectProps = {
    classNames: `eq-multiSelect eq-searchSelect`,
    placeholder: t("events"),
    extraHeight: true,
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedEvents ? selectedEvents : [],
    options: eventsOptions,
    changeFunc: (value: any) => {
      console.log(value);
      setSelectedEvents(value);
    },
  };
  const createEventList = () => {
    var eventTypes = [];
    eventTypes.push({
      key: EqDisciplineType.Dressage,
      value: EqDisciplineType[EqDisciplineType.Dressage],
    });
    eventTypes.push({
      key: EqDisciplineType.Jumping,
      value: EqDisciplineType[EqDisciplineType.Jumping],
    });
    eventTypes.push({
      key: EqDisciplineType.Western,
      value: EqDisciplineType[EqDisciplineType.Western],
    });
    eventTypes.push({
      key: EqDisciplineType.Eventing,
      value: EqDisciplineType[EqDisciplineType.Eventing],
    }); eventTypes.push({
      key: EqDisciplineType.MountedGames,
      value: EqDisciplineType[EqDisciplineType.MountedGames],
    }); eventTypes.push({
      key: EqDisciplineType.IslandicHorses,
      value: EqDisciplineType[EqDisciplineType.IslandicHorses],
    }); eventTypes.push({
      key: EqDisciplineType.Racehorse,
      value: EqDisciplineType[EqDisciplineType.Racehorse],
    });
    setEventsOptions(eventTypes);
    console.log("options", eventsOptions);
  };


  useEffect(() => {
    createTrainingList();
    createEventList();
    dashboardState();
    GetSchoolEventsByMonth();
    GetSchoolTrainingByMonth();

    GetMessages();
    GetSchoolTrainers();
    GetSchoolStudents();
    createEventTypes();
    getWeekDays();
    GetProductList();
    getAreas();
    getPopulation();
    getGroupPopulation();
    GetAllSchoolTrainingsByWithLessons();
    GetTrainerPublicEvents();
  }, []);

  return (
    <AnimationDiv>
      <div className="eq-sb-container">
        <div className="eq-container-fluid">
          <Row className="eq-sb-heading-row">
            <Col className="eq-sb-col" flex="auto" md={5} xs={24} sm={24}>
              <h2 className="eq-sb-heading">{t("myDashboard")}</h2>
            </Col>
          </Row>
          <div className="eq-section">
            <EqOverviewSchoolProfile
              stat={stateView}
              trainingList={schoolTrainings}
            />
          </div>
          {/* <div className="eq-section">
          <Row className="eq-db-row" align="stretch">
            <Col xs={24} sm={24} md={18} className="eq-db-icons">

              <Row>
                <div className="eq-db-h2">{t("quickLinks")}</div>
              </Row>
              <Row align="bottom" justify="start" className="eq-db-icon-row">
                <Col className="eq-db-icon" xs={8} sm={8} md={4}>
                  <Link
                    to={FrontEndPoints.school.studentBoard}
                    state={{ prevPath: location.pathname }}
                  >
                    <span>
                      <img alt="" src="/studentplus.svg" />
                    </span>
                    <div className="eq-db-icon-text">{t("inviteStudent")}</div>
                  </Link>
                </Col>
                <Col className="eq-db-icon" xs={8} sm={8} md={4}>
                  <Link
                    to={FrontEndPoints.school.activityBoard}
                    state={{ prevPath: location.pathname }}
                  >
                    <span>
                      <img alt="" src="/calendarplus.svg" />
                    </span>
                    <div className="eq-db-icon-text">
                      {t("createActivities")}
                    </div>
                  </Link>
                </Col>
                <Col className="eq-db-icon" xs={8} sm={8} md={4}>
                  <div onClick={GetStripeDashboardUrlAsync}>
                    <span>
                      <img alt="" src="/profile.svg" />
                    </span>
                    <div className="eq-db-icon-text">{t("stripeDashboard")}d</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div> */}


 {/* commenting because now we have separate  page for requests  */}
          {/* {trainingInvitation ? (
            <>
              <div className="eq-section-divider"></div>
              <div className="eq-section">
                <EqCollapsePanel
                  header={t("trainingJoinRequests")}
                  children={
                    <div>
                      <EqTrainerTrainingJoinRequestBoard
                        onloadActivity={(e: any) => {
                          setTrainingInvitation(e);
                        }}
                      />
                    </div>
                  }
                />
              </div>
            </>
          ) : (
            ""
          )} */}

          {/* <div className="eq-section-divider"></div>
          <div className="eq-section">
            <EqCollapsePanel
              header={t("newMessages")}
              children={
                <>
                  <Row className="eq-msg-header" align="bottom">
                    <Col flex="auto">
                      <div className="eq-db-h2-collapse">{t("newMessages")}</div>
                    </Col>
                    <Col md={2}>
                      <Row>
                        <Col
                          className="eq-msg-icons"
                          onClick={readSelectedMessages}
                        >
                          <span>
                            <img alt="" src="/black-check.svg" />
                          </span>
                        </Col>
         
                        <Col
                          className="eq-msg-icons"
                          onClick={deleteSelectedMessages}
                        >
                          <span>
                            <img alt="" src="/trash-alt.svg" />
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {messages.length > 0 && (
                    <>
                      {
                        <Row className="eq-list-header hidden-sm">
                          <Col md={22} flex="auto">
                            <Row>
                              <Col span={1} className="">
                                <Checkbox
                                  onChange={messagesCheckAll}
                                  checked={misCheckAll}
                                ></Checkbox>
                              </Col>
                              <Col span={3} className="">
                                {t("type")}
                              </Col>
                              <Col span={5} className="">
                                {t("type")}
                              </Col>
                              <Col flex="auto" className="">
                                {t("messageTitle")}
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={4} sm={4} md={2} className="">
                            {t("action")}
                          </Col>
                        </Row>
                      }

                      {messages.map((item: IEqMessage, index: number) => (
                        <EqMessage key={index} {...item}></EqMessage>
                      ))}
                    </>
                  )}
                </>
              }
            />
          </div> */}


          <div className="eq-section-divider"></div>

          <div className="eq-section eq-section-training">
            <Skeleton loading={!schoolTrainings} active></Skeleton>
            <Row>
              {schoolTrainings && schoolTrainings.length > 0 && (
                ////-------------------//
                <Col span={24}>
                  <EqCollapsePanel
                    defaultOpen
                    header={t("trainings")}
                    children={
                      <div>
                        <Row gutter={[16, 16]} className="eq-section-training-filter">
                          <Col xs={24} md={8}>
                            <EqSelect {...trainingTypeProps}></EqSelect>
                          </Col>
                          <Col xs={24} md={8}>
                            <DatePicker
                              placeholder={t("selectDate")}
                              className="eq-filter-date eq-input-info"
                              onChange={(date) => setFromDate(date)}
                              value={fromDate && fromDate.isValid() ? fromDate : null}

                            />
                          </Col>
                          <Col xs={24} md={8}>
                            <EqButton classNames="btn-background-white btn-border-text-orange eq-filter-btn">
                              <FontAwesomeIcon icon={faFilter} className="eq-filter-btn-icon" />
                              {t("filter")}
                            </EqButton>
                          </Col>
                        </Row>
                        <EqSchoolTrainingListDashbord
                          trainingList={filterTrainings(schoolTrainings)}
                          trainersOptions={trainersOptions}
                          eventTypes={eventTypes}
                          productOptions={productOptions.editOptions}
                          products={products}
                          areas={areas}
                          weekDays={weekDays}
                          population={population}
                          groupPopulation={groupPopulation}
                          schoolStudents={schoolStudents}
                          studentHorsesList={studentHorsesList}
                          removeEventFunc={removeTraining}
                          editEventFunc={editTraining}
                          onCreateOrUpdateSchoolTraining={handleOnUpdateSchoolTraining}
                        ></EqSchoolTrainingListDashbord>
                      </div>
                    }
                  />

                </Col>
              )}
            </Row>
          </div>
          <div className="eq-section-divider"></div>

          <div className="eq-section eq-section-training">
            <Skeleton loading={!schoolEvents} active></Skeleton>

            <Row>
              {schoolEvents && schoolEvents?.length > 0 && (
                ////-------------------//
                <Col span={24}>
                  <EqCollapsePanel
                    defaultOpen
                    header={t("events")}
                    children={
                      <div>
                        <Row gutter={[16, 16]} className="eq-section-training-filter">
                          <Col xs={24} md={8}>
                            <EqSelect {...eventTypeProps}></EqSelect>
                          </Col>
                          <Col xs={24} md={8}>
                            <DatePicker
                              placeholder={t("selectDate")}
                              className="eq-filter-date eq-input-info"
                              onChange={(date) => setFromDate(date)}
                              value={fromDate && fromDate.isValid() ? fromDate : null}
                            />
                          </Col>
                          <Col xs={24} md={8}>
                            <EqButton classNames="btn-background-white btn-border-text-orange eq-filter-btn">
                              <FontAwesomeIcon icon={faFilter} className="eq-filter-btn-icon" />
                              {t("filter")}
                            </EqButton>
                          </Col>
                        </Row>
                        <EqActivityListSchoolDashboard
                          eventList={filterEvents(schoolEvents)}
                          areas={areas}
                          weekDays={weekDays}
                          population={population}
                          groupPopulation={groupPopulation}
                          trainerStudents={schoolStudents}
                          studentHorsesList={studentHorsesList}
                          removeEventFunc={removeEvent}
                          // editEventFunc= editEvent
                          // onCreateOrUpdateActivity={trainerEventDefaults}
                          onCreateOrUpdateActivity={handleOnUpdateSchoolEvent}
                        ></EqActivityListSchoolDashboard>
                      </div>}
                  />


                </Col>
              )}
            </Row>
          </div>

        </div>
      </div>

    </AnimationDiv>
  );
};

export default EqSchoolDashboard;
