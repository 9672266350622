import React from 'react';
import { Card, Button } from 'antd';
import './EqRequestCard.scss';
import EqButton from '../../eqButton/EqButton';
import { useTranslation } from 'react-i18next';

interface EqRequestCardProps {
  heading: string;
  text1: string;
  text2: string;
  text3?: string;
  text4?: string;
  onButtonClick: () => void;
}

const EqRequestCard: React.FC<EqRequestCardProps> = ({ heading, text1, text2,text3,text4, onButtonClick }) => {
  const { t } = useTranslation();
  return (

    <Card className="custom-card">
      <div className="card-content">
        <h3>{heading}</h3>
        <div>
        <p>{text1}</p>
        <p>{text2}</p>
        <p>{text3}</p>
        <p>{text4}</p>

        </div>
        <EqButton type="primary" onClick={onButtonClick}>{t("requests")}</EqButton>
      </div>
    </Card>
  );
};

export default EqRequestCard;
