import * as React from "react";
import EqTrainerList, { IEqTrainersList } from "../eqTrainerList/EqTrainerList";
import EqSearch, { IEqSearch } from "../../custom/eqSearch/EqSearch";
import {
    Row,
    Col,
    Input,
    Typography,
    Grid,
    Layout,
    Space,
    Tabs,
    Divider,
} from "antd";
import { useTranslation, Trans } from "react-i18next";
import Api from "../../shared/Api";
import {
    ApiEndpoints,
    FrontEndPoints,
    GetAdminHomeMessage,
    getDefaultImage,
    imageType,
    openNotificationWithIcon,
} from "../../shared/Common";
import {
    EqMessageType,
    EqLocationType,
    EqProfessionType,
    EqSubscriptionRoleType,
    SubscriptionTypeEnum,
} from "../../shared/enums";
import { IProfileDto } from "../../shared/interfaces/IProfileDto";
import "./EqCreateSubscription.scss";
import { EqInput, EqInputNumber } from "../../custom/eqInput/EqInput";
import EqSelect from "../../custom/eqSelect/EqSelect";
import { PlusOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import EqButton from "../../custom/eqButton/EqButton";
import TabPane from "antd/lib/tabs/TabPane";
import { ISelectProps } from "../../shared/Interfaces";
import EqCreateSubscriptionProduct from "../eqCreateSubscriptionProduct/EqCreateSubscriptionProduct";
import { Table } from "antd-v5";


const EqCreateSubscription = () => {

    const [subscriptionTypes, setSubscriptionTypes] = React.useState(
        {
            students: [],
            trainers: [],
            eventHolders: [],
            schools: [],
        }) as any;

    const [createProductTrainerModalVisible, setCreateProductTrainerModalVisible] =
        React.useState(false);

    const [createProductModalVisible, setCreateProductModalVisible] =
        React.useState(false);

    const [subscriptionSettings, setSubscriptionSettings] = React.useState(
        {}
    ) as any;


    const { t, i18n } = useTranslation();
    const { Search } = Input;
    const [trainersList, setTrainersList] = React.useState([] as any);

    const [professionalList, setProfessionalList] = React.useState([] as any);
    const [existingSubscriptions, setExistingSubscriptions] = React.useState([] as any);
    const [professionalOptions, setProfessionalOptions] = React.useState({
        trainerOptions: [],
        eventHolderOptions: [],
        schoolOptions: [],
    } as any);

    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {

        createSubscriptionTypes();

        subscriptionSettings.event = {
            customSettings: [
                {
                    monthlySubscription: 150,
                    transactionFee: 2,
                    minCapacity: 0,
                    MaxCapacity: 30,
                    freeDays: 90,
                },
                {
                    monthlySubscription: 250,
                    transactionFee: 2,
                    minCapacity: 31,
                    MaxCapacity: 50,
                    freeDays: 45,
                },
            ],
        };
        //this will be removed
        GetEventOwners();

        //- to get trainer schools and event holders
        GetAllProfessionals();
    }, []);


    const createSubscriptionTypes = () => {
        const subscriptionTypes = {
            // students: [
            //     { key: SubscriptionTypeEnum.StudentFree, value: SubscriptionTypeEnum[SubscriptionTypeEnum.StudentFree] },
            //     { key: SubscriptionTypeEnum.SchoolBasic, value: SubscriptionTypeEnum[SubscriptionTypeEnum.SchoolBasic] },
            // ],
            // eventHolders: [
            //     { key: SubscriptionTypeEnum.EventHolderBasic, value: SubscriptionTypeEnum[SubscriptionTypeEnum.EventHolderBasic] },
            // ],
            // trainers: [
            //     { key: SubscriptionTypeEnum.TrainerBasic, value: SubscriptionTypeEnum[SubscriptionTypeEnum.TrainerBasic] },
            //     { key: SubscriptionTypeEnum.TrainerPlus, value: SubscriptionTypeEnum[SubscriptionTypeEnum.TrainerPlus] },
            //     { key: SubscriptionTypeEnum.TrainerPro, value: SubscriptionTypeEnum[SubscriptionTypeEnum.TrainerPro] },
            // ],
            // schools: [
            //     { key: SubscriptionTypeEnum.SchoolBasic, value: SubscriptionTypeEnum[SubscriptionTypeEnum.SchoolBasic] },
            // ],
        };

        setSubscriptionTypes(subscriptionTypes);
    };

    const GetEventOwners = () => {
        getSubscriptions();
        Api.get(ApiEndpoints.GetAllTrainers).then(
            (response) => {
                var trainersFromDB = [] as any;
                if (response && response.status == 200) {
                    response.data?.map((d: any) => {
                        console.log(d);

                        let trainer: IProfileDto = d;
                        trainer.display = false;
                        trainer.name = d.firstName
                            ? d.firstName
                            : "" + " " + d.lastName
                                ? d.lastName
                                : "" || d.userName;
                        trainer.homeAddress = d.locations
                            ?.filter((opt: any) => opt.location?.type == EqLocationType.Home)
                            .shift()?.location;
                        trainer.serviceAreaList =
                            d.locations?.filter(
                                (opt: any) => opt.location?.type == EqLocationType.Work
                            ) || [];
                        // trainer.trainingLevels = [];
                        //trainersFromDB.push({
                        //    data: trainer,
                        //    acceptedFun: acceptClicked,
                        //    rejectedFun: rejectClicked,
                        //    editFun: editClicked
                        //});
                    });
                    console.log(trainersFromDB);
                    setTrainersList(trainersFromDB);
                } else {
                    //setMessageString('');
                }
            },
            (err) => {
                //setMessageString('');
            }
        );
    };

    const GetAllProfessionals = () => {
        Api.get(ApiEndpoints.ProfessionalUser).then(
            (response) => {
                var professionalsFromDB = [] as any;

                var eventHolderOptions = [] as any;
                var schoolsOptions = [] as any;
                var trainerOptions = [] as any;

                if (response && response.status == 200) {
                    response.data?.map((d: any) => {
                        console.log(d);
                        let obj = {
                            key: d.key,
                            value: d.firstName ? d.firstName + " " + d.lastName : d.email,
                        };
                        if (d.professionType === EqProfessionType.Trainer) {
                            trainerOptions.push(obj);
                        } else if (d.professionType === EqProfessionType.EventHolder) {
                            eventHolderOptions.push(obj);
                        } else {
                            schoolsOptions.push(obj);
                        }

                        let professional: IProfileDto = d;
                        professional.display = false;
                        professional.name = d.firstName
                            ? d.firstName
                            : "" + " " + d.lastName
                                ? d.lastName
                                : "" || d.userName;

                        professionalsFromDB.push(professional);
                    });

                    setProfessionalOptions({
                        trainerOptions: trainerOptions,
                        eventHolderOptions: eventHolderOptions,
                        schoolsOptions: schoolsOptions,
                    });
                    console.log(professionalsFromDB);
                    setProfessionalList(professionalsFromDB);
                } else {
                    //setMessageString('');
                }
            },
            (err) => {
                //setMessageString('');
            }
        );
    };

    //const[subscriptionSettings, setSubscriptionSettings] = React.useState();

    const handleSelectedTrainersOnChange = (evt: any) => { };

    const handleSelectedTrainersOnBlur = (evt: any) => { };

    const changeDefaultMonthlySubscription = (evt: any) => { };

    const changeDefaultTransactionFee = (evt: any) => { };

    const changeCustomMonthlySubscription = (evt: any) => { };

    const changeCustomTransactionFee = (evt: any) => { };

    const HandleCreateSubscriptionProduct = (e: any) => {
        let data = e;
        
        data.roleType = EqSubscriptionRoleType.EventHolder;
        Api.post("SubscriptionProduct", data).then(
            (result) => {
                console.log(result);
                setCreateProductModalVisible(false);
            },
            (error) => {
                setCreateProductModalVisible(false);
                openNotificationWithIcon(
                    "Server error",
                    "Error occurred while creating. Please try again or contact administrator."
                );
                // throw error;
            }
        );
    };

    const addNewCustomRow = (evt: any) => {
        // new Row Data
        var newCustomerRow = {
            monthlySubscription: 150,
            transactionFee: 2,
            minCapacity: 0,
            MaxCapacity: 30,
            freeDays: 90,
        };

        let eventSubscription = subscriptionSettings.event;
        var newCustomSubscriptions = subscriptionSettings.event.customSettings;
        newCustomSubscriptions.push(newCustomerRow);
        eventSubscription.customSettings = newCustomSubscriptions;

        setSubscriptionSettings({
            ...subscriptionSettings,
            event: eventSubscription,
        });
    };

    const monthlySubscriptionSelectProps: ISelectProps = {
        label: "Monthly subscription",
        options: subscriptionSettings.subscriptionOptions,
        defaultSelectedValue: "",
        classNames: "extra-height",
        placeholder: t("selectTrainer"),
        extraHeight: true,
        changeFunc: (value: string) => {
            let type: number = +value;
            // setFormData({ ...formData, trainerKey: type });
        },
    };

    const eventHolderSelectProps: ISelectProps = {
        label: "Select eventHolder",
        options: professionalOptions.eventHolderOptions,
        defaultSelectedValue: "",
        classNames: "extra-height",
        placeholder: t("selectTrainer"),
        extraHeight: true,
        changeFunc: (value: string) => {
            let type: number = +value;
            // setFormData({ ...formData, trainerKey: type });
        },
    };

    const trainerDropDownOptions = {
        label: "Selected trainers",
        classNames: "text-input-height",
        mode: "multiple",
        defaultSelectedValue: "",
        changeFunc: handleSelectedTrainersOnChange,
        options: subscriptionSettings?.options,
        showArrow: true,
        selectedOption: {},
        dropdownClassName: "",
        isTag: true,
        ddClassName: "",
        allowClear: true,
        showSearch: true,
        optionFilterProp: {},
        filterOption: {},
        isDisabled: false,
        isSeperator: true,
        seperatorList: {},
        handleOnBlur: handleSelectedTrainersOnBlur,
    };
    const currencyDropDownOptions = {
        label: "Currency",
        classNames: "text-input-height",
        mode: "",
        defaultSelectedValue: "",
        changeFunc: handleSelectedTrainersOnChange,
        options: subscriptionSettings?.options,
        showArrow: true,
        selectedOption: {},
        dropdownClassName: "",
        isTag: true,
        ddClassName: "",
        allowClear: false,
        showSearch: false,
        optionFilterProp: {},
        filterOption: {},
        isDisabled: false,
        isSeperator: true,
        seperatorList: {},
        handleOnBlur: handleSelectedTrainersOnBlur,
    };

    const getSubscriptions = () => {
        Api.get("subscriptionManager").then(
            (result) => {
                console.log(result);

                // creating Options for subscription
                var subscriptionOptions = [] as any;
                result.data?.map((d: any) => {
                    console.log(d);
                    let obj = {
                        key: d.id,
                        name: d.name,
                        description: d.description,
                    };

                    subscriptionOptions.push(obj);
                });

                setSubscriptionSettings({
                    ...subscriptionSettings,
                    subscriptions: result,
                    subscriptionOptions: subscriptionOptions,
                });
            },
            (error) => {
                throw error;
            }
        );
    };


    //////
    const [newProduct, setNewProduct] = React.useState({} as any) as any;

    const updateNewProductName = (param: any, key: string) => {
        setNewProduct({ ...newProduct, name: param?.target?.value });
    }
    const updateNewProductPrice = (param: any) => {
        setNewProduct({ ...newProduct, price: param?.target?.value });
    }
    const updateNewProductDescription = (param: any) => {
        setNewProduct({ ...newProduct, description: param?.target?.value });
    }

    return (
        <Row gutter={[16, 16]}>
            <Col
                sm={{ span: 22, offset: 1 }}
                md={{ span: 20, offset: 2 }}
                lg={{ span: 18, offset: 3 }}
            >
                <div className="eq-create-subscription">
                    <Row>
                        <Col span={24}>
                            <h2 className="eq-pf-heading"> Subscription settings</h2>
                        </Col>
                    </Row>

                    <Tabs defaultActiveKey="1">

                        <TabPane tab="Trainers" key="1">
                            <Row>
                                <Col xs={24} sm={24} md={24}>
                                    <Table>
                                        <thead>
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th>Description</th>
                                        </thead>
                                        <tbody>
                                            {existingSubscriptions.map((es :any)=> (
                                                <>
                                                    <td>es.name</td>
                                                    <td>es.price</td>
                                                    <td>es.description</td>
                                                </>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Divider
                                dashed
                                style={{ backgroundColor: "#FF8838", borderWidth: "1px" }}
                            />
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24}>
                                    <h4>Default settings (NOK)</h4>
                                    <p>
                                        Add more lines of default settings if default subscription
                                        fee is dependent on limits of students that can join event
                                    </p>
                                </Col>

                                <Col xs={24} sm={24} md={8}>
                                    <EqInput
                                        classNames=""
                                        label="Name"
                                        onChange={updateNewProductName}
                                    />
                                </Col>

                                <Col xs={24} sm={24} md={8}>
                                    <EqInputNumber
                                        classNames=""
                                        label="Price"
                                        onChange={updateNewProductPrice}
                                    />
                                </Col>

                                <Col xs={24} sm={24} md={8}>
                                    <EqInput
                                        classNames="avg-height-35"
                                        label="Description"
                                        onChange={updateNewProductDescription}
                                    />
                                </Col>
                                <Col
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        flex: "1",
                                    }}
                                >
                                    <EqButton
                                        classNames="btn-update"
                                        type="button"
                                        onClick={addNewCustomRow}
                                        disabled={true}
                                    >
                                        Update
                                    </EqButton>
                                </Col>
                            </Row>

                            <Divider
                                dashed
                                style={{ backgroundColor: "#FF8838", borderWidth: "1px" }}
                            />

                            <div className="custom-settings">
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={24} md={24}>
                                        <h4>Custom settings</h4>
                                        <p>
                                            Add custom settings if you want to charge some one
                                            differently
                                        </p>
                                    </Col>
                                </Row>

                                {subscriptionSettings?.event?.customSettings?.map(
                                    (cs: any, idx: number) => (
                                        <Row gutter={[16, 16]} id={"" + idx}>
                                            <Col xs={24} sm={12} md={3}>
                                                <EqInput
                                                    labelClassNames="eq-wrap"
                                                    classNames=""
                                                    label="Monthly subscription"
                                                    value={cs.monthlySubscription}
                                                    onChange={changeCustomMonthlySubscription}
                                                />
                                            </Col>
                                            <Col xs={24} sm={12} md={3}>
                                                <EqInput
                                                    labelClassNames="eq-wrap"
                                                    classNames=""
                                                    label="Transaction fee"
                                                    type=""
                                                    value={cs.transactionFee}
                                                    onChange={changeCustomTransactionFee}
                                                />
                                            </Col>

                                            <Col xs={24} sm={8} md={2}>
                                                <EqInputNumber
                                                    labelClassNames="eq-wrap"
                                                    classNames="avg-height-35"
                                                    label="Min capacity"
                                                    value={cs.minCapacity}
                                                    onChange={changeDefaultMonthlySubscription}
                                                />
                                            </Col>
                                            <Col xs={24} sm={8} md={2}>
                                                <EqInputNumber
                                                    labelClassNames="eq-wrap"
                                                    classNames="avg-height-35"
                                                    label="Max capacity"
                                                    value={cs.MaxCapacity}
                                                    onChange={changeDefaultMonthlySubscription}
                                                />
                                            </Col>
                                            <Col xs={24} sm={8} md={2}>
                                                <EqInputNumber
                                                    labelClassNames="eq-wrap"
                                                    classNames="avg-height-35"
                                                    label="Free days"
                                                    value={cs.freeDays}
                                                    onChange={changeDefaultMonthlySubscription}
                                                />
                                            </Col>

                                            {/* <Col xs={24} sm={24} md={12}>
                        <EqSelect {...trainerDropDownOptions} />
                      </Col>
                       */}

                                            <Col xs={24} sm={24} md={12}>
                                                <EqSelect {...eventHolderSelectProps} />
                                            </Col>
                                        </Row>
                                    )
                                )}
                                <Row gutter={[16, 16]}>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            flex: "1",
                                            alignContent: "center",
                                        }}
                                    >
                                        <EqButton
                                            style={{ marginLeft: "6px" }}
                                            classNames="btn-round"
                                            type="button"
                                            onClick={addNewCustomRow}
                                            disabled={false}
                                        >
                                            {" "}
                                            <PlusOutlined />
                                        </EqButton>
                                        <EqButton
                                            classNames="btn-update"
                                            type="button"
                                            onClick={addNewCustomRow}
                                            disabled={false}
                                        >
                                            Update
                                        </EqButton>
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tab="Student" key="2">
                            <Row>
                                <Col xs={24} sm={24} md={24}>
                                    <h4>Add Subscription Product</h4>
                                    <p>create default or new custom subscriptions</p>
                                </Col>
                                <Col
                                    style={{
                                        display: "flex",
                                        flex: "1",
                                    }}
                                >
                                    <EqButton
                                        classNames="btn-create"
                                        type="button"
                                        onClick={() => setCreateProductModalVisible(true)}
                                    >
                                        Create Product
                                    </EqButton>
                                </Col>
                                <EqCreateSubscriptionProduct
                                    visible={createProductModalVisible}
                                    onCreate={HandleCreateSubscriptionProduct}
                                    onCancel={() => setCreateProductModalVisible(false)}
                                />
                            </Row>
                            <Divider
                                dashed
                                style={{ backgroundColor: "#FF8838", borderWidth: "1px" }}
                            />
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24}>
                                    <h4>Student Free</h4>
                                    <p>Add Student Free Subscription settings</p>
                                </Col>

                                <Col xs={24} sm={24} md={8}>
                                    <EqSelect {...monthlySubscriptionSelectProps} />
                                </Col>

                                <Col xs={24} sm={24} md={8}>
                                    <EqInput
                                        classNames=""
                                        label="Transaction fee"
                                        onChange={changeDefaultTransactionFee}
                                    />
                                </Col>

                                <Col xs={12} sm={12} md={4}>
                                    <EqInputNumber
                                        classNames="avg-height-35"
                                        label="Min capacity"
                                        onChange={changeDefaultMonthlySubscription}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={4}>
                                    <EqInputNumber
                                        classNames="avg-height-35"
                                        label="Max capacity"
                                        onChange={changeDefaultMonthlySubscription}
                                    />
                                </Col>
                                <Col
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        flex: "1",
                                    }}
                                >
                                    <EqButton
                                        classNames="btn-update"
                                        type="button"
                                        // onClick={addNewCustomRow}
                                        disabled={true}
                                    >
                                        Update
                                    </EqButton>
                                </Col>
                            </Row>

                            <Divider
                                dashed
                                style={{ backgroundColor: "#FF8838", borderWidth: "1px" }}
                            />

                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24}>
                                    <h4>StudentBasic</h4>
                                    <p>Add Student Basic Subscription settings</p>
                                </Col>

                                <Col xs={24} sm={24} md={8}>
                                    <EqSelect {...monthlySubscriptionSelectProps} />
                                </Col>

                                <Col xs={24} sm={24} md={8}>
                                    <EqInput
                                        classNames=""
                                        label="Transaction fee"
                                        onChange={changeDefaultTransactionFee}
                                    />
                                </Col>

                                <Col xs={12} sm={12} md={4}>
                                    <EqInputNumber
                                        classNames="avg-height-35"
                                        label="Min capacity"
                                        onChange={changeDefaultMonthlySubscription}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={4}>
                                    <EqInputNumber
                                        classNames="avg-height-35"
                                        label="Max capacity"
                                        onChange={changeDefaultMonthlySubscription}
                                    />
                                </Col>
                                <Col
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        flex: "1",
                                    }}
                                >
                                    <EqButton
                                        classNames="btn-update"
                                        type="button"
                                        // onClick={addNewCustomRow}
                                        disabled={true}
                                    >
                                        Update
                                    </EqButton>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane tab="Events Holder" key="3">
                            <Row>
                                <Col xs={24} sm={24} md={24}>
                                    <h4>Add Subscription Product</h4>
                                    <p>create default or new custom subscriptions</p>
                                </Col>
                                <Col
                                    style={{
                                        display: "flex",
                                        flex: "1",
                                    }}
                                >
                                    <EqButton
                                        classNames="btn-create"
                                        type="button"
                                        onClick={() => setCreateProductModalVisible(true)}
                                    >
                                        Create Product
                                    </EqButton>
                                </Col>
                                <EqCreateSubscriptionProduct
                                    visible={createProductModalVisible}
                                    onCreate={HandleCreateSubscriptionProduct}
                                    onCancel={() => setCreateProductModalVisible(false)}
                                />
                            </Row>
                            <Divider
                                dashed
                                style={{ backgroundColor: "#FF8838", borderWidth: "1px" }}
                            />
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24}>
                                    <h4>Default settings (NOK)</h4>
                                    <p>
                                        Add more lines of default settings if default subscription
                                        fee is dependent on limits of students that can join event
                                    </p>
                                </Col>

                                <Col xs={24} sm={24} md={8}>
                                    {/* <EqInput
                    classNames=""
                    label="Monthly subscription"
                    onChange={changeDefaultMonthlySubscription}
                  /> */}
                                    <EqSelect {...monthlySubscriptionSelectProps} />
                                </Col>

                                <Col xs={24} sm={24} md={8}>
                                    <EqInput
                                        classNames=""
                                        label="Transaction fee"
                                        onChange={changeDefaultTransactionFee}
                                    />
                                </Col>

                                <Col xs={12} sm={12} md={4}>
                                    <EqInputNumber
                                        classNames="avg-height-35"
                                        label="Min capacity"
                                        onChange={changeDefaultMonthlySubscription}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={4}>
                                    <EqInputNumber
                                        classNames="avg-height-35"
                                        label="Max capacity"
                                        onChange={changeDefaultMonthlySubscription}
                                    />
                                </Col>
                                <Col
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        flex: "1",
                                    }}
                                >
                                    <EqButton
                                        classNames="btn-update"
                                        type="button"
                                        onClick={addNewCustomRow}
                                        disabled={true}
                                    >
                                        Update
                                    </EqButton>
                                </Col>
                            </Row>


                        </TabPane>

                        <TabPane tab="Schools" key="4"></TabPane>
                        {/* <TabPane tab="Trainers" key="4"></TabPane> */}
                    </Tabs>
                </div>
            </Col>
        </Row>
    );
};
export default EqCreateSubscription;
