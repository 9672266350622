import React, { useEffect, useState } from 'react';
import './EqSpace.scss';
import {
    Space
} from 'antd';

export const EqSpace = (props: any) => {
    return (
        <div className="eqSpace">
            <Space {...props} />
        </div>
    );
}
