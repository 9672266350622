import * as React from "react";
import "./eqEventClassCard.scss";
import {
  Row,
  Col,
  Tag,
  Tabs,
  Checkbox,
  Popover,
  Button,
  Space,
  Modal,
} from "antd";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";
import {
  ApiEndpoints,
  dateFormat,
  GetEventMessage,
} from "../../../../shared/Common";
import EqSelect from "../../../../custom/eqSelect/EqSelect";
import EqTimePicker, {
  ITimePickerOptions,
} from "../../../../custom/eqTimePicker/EqTimePicker";
import Api from "../../../../shared/Api";
import { IStudentGroupDto } from "../../../../shared/interfaces/IStudentGroupDto";
import { ISelectProps } from "../../../../shared/Interfaces";
import { Card } from "antd";
import {
  EqDisciplineType,
  EqTrainingLevels,
  EqUserType,
} from "../../../../shared/enums";
import { EqInput, EqInputNumber } from "../../../../custom/eqInput/EqInput";
import EqCollapse, {
  IEqCollapse,
} from "./../../../../custom/eqCollapse/EqCollapse";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import {
  SyncOutlined,
  MoreOutlined,
  CheckCircleTwoTone,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import {
  FrontEndPoints,
  ValidateIsUser,
  getDefaultImage,
  imageType,
} from "../../../../shared/Common";
import { EqIconCalendar } from "../../../../custom/eqIcon/EqIcons";
export interface IEqEventClassCardData {
  key: number;
  cardkey: number;
  timeFrom: any;
  // timeTo: any;
  // duration: any;
  maxSpaceCount: any;
  price: any;
  height: any;
  classDescription: any;
  // trainingLevel: any;
  attendeesHorse?: Array<any>;
  display?: boolean;
  selectedDates?: Array<any>;
  attendees: Array<any>;
  attendeesKeys: Array<any>;
}
export interface IEqEventClassCardProps {
  tabIndex: any;
  data: IEqEventClassCardData;
  timeFromChange: any;
  // timeToChange: any;
  // durationChange: any;
  maxSpaceCountChange: any;
  eventClassCardPriceChange?: any;
  eventClassCardHeightChange?: any;
  eventClassCardClassDescriptionChange?: any;
  // attendeesChange: any;
  eventClassCardHorseChange?: any;
  removeCard: any;
  removeAttendeeFromCard?: any;
  groupKeys?: Array<any>;
  multipleStartOn?: Array<any>;
  startDate?: any;
  trainingType?: any;
  // trainingLevelChange?: any;
  selectedDateChange?: any;
  enableCardRemove?: any;
  selectedGroups?: any;
  studentsList?: any;
  groupStudentsList?: any;

  groupStudentPopulation?: any;
  studentsByGroup?: any;

  studentPopulation?: any;
  searchStudentsList?: any;
  studentHorsesList?: any;
  isStudent?: boolean;
  isEdit?: boolean;
}
const EqEventClassCard = ({
  tabIndex,
  data,
  timeFromChange,
  // timeToChange,
  // durationChange,
  maxSpaceCountChange,
  eventClassCardPriceChange,
  eventClassCardHeightChange,
  eventClassCardClassDescriptionChange,
  // attendeesChange,
  eventClassCardHorseChange,
  // trainingLevelChange,
  selectedDateChange,
  removeCard,
  removeAttendeeFromCard,
  groupKeys,
  multipleStartOn,
  startDate,
  trainingType,
  enableCardRemove,
  selectedGroups,
  studentsList,
  groupStudentsList,
  groupStudentPopulation,
  studentsByGroup,
  studentPopulation,
  searchStudentsList,
  studentHorsesList,
  isStudent,
  isEdit,
  ...rest
}: IEqEventClassCardProps) => {
  const [modal, contextHolder] = Modal.useModal();

  //console.log(groupKeys);
  //console.log(cardkey);
  const TIME_FORMAT = "HH:mm";
  const { TabPane } = Tabs;
  const {
    cardkey,
    timeFrom,
    price,
    height,
    classDescription,
    // timeTo,
    // duration,
    maxSpaceCount,
    // trainingLevel,
    attendees,
    // attendeesKeys,
    attendeesHorse,
    selectedDates,
    display,
  } = data;
  // const [selectedAttendees, setSelectedAttendees] = React.useState({
  //   // keys: [] as any,
  //   keys: attendeesKeys,
  //   data: attendees,
  // });

  const [selectedHorses, setSelectedHorses] = React.useState(
    attendeesHorse ? attendeesHorse : ([] as any[])
  );
  // const [selectedHorses, setSelectedHorses] = React.useState([] as any);
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { t, i18n } = useTranslation();
  const [eventDurations, setEventDurations] = React.useState([]) as any;

  const [groupSelected, setGroupSelected] = React.useState(false);
  const [cardDateOptions, setCardDateOptions] = React.useState([] as any);

  const [trainingLevels, setTrainingLevels] = React.useState([]) as any;
  const [formData, setFormData] = React.useState({ ...data }) as any;
  const [selectedCardDates, setSelectedCardDates] = React.useState(
    selectedDates
  ) as any;
  const [check, setCheck] = React.useState(false);

  const createEventDurations = () => {
    var durations = [];
    let durationVals = [30, 45, 60, 90];
    for (var x = 0; x < durationVals.length; x++) {
      durations.push({ key: durationVals[x], value: durationVals[x] });
    }
    setEventDurations(durations);
  };

  const createTrainingLevels = () => {
    var levels = [];
    for (var x in EqTrainingLevels) {
      if (isNaN(Number(x)) == false) {
        levels.push({ key: x, value: EqTrainingLevels[x] });
      }
    }
    setTrainingLevels(levels);
  };

  const spaceChangeFunc = (value: any) => {
    let spaces: number = +value;
    setFormData({ ...formData, maxSpaceCount: spaces });
    maxSpaceCountChange(tabIndex, cardkey, spaces);
  };

  const handleOnChangePrice = (e: any) => {
    let priceValue: number = +e?.target?.value;
    setFormData({ ...formData, price: priceValue });
    eventClassCardPriceChange(tabIndex, cardkey, priceValue);
  };

  const handleOnChangeHeight = (e: any) => {
    let value = e?.target?.value;

    setFormData({ ...formData, height: value });
    eventClassCardHeightChange(tabIndex, cardkey, value);
  };

  const handleOnChangeClassDescription = (e: any) => {
    let value = e?.target?.value;
    setFormData({ ...formData, classDescription: value });
    eventClassCardClassDescriptionChange(tabIndex, cardkey, value);
  };

  const horseSelectProps: ISelectProps = {
    isTag: false,
    placeholder: t("selectHorse"),
    // ddClassName: "eq-select-dropdown",
    // classNames: isStudent ? "eq-card" : "eq-event",
    classNames: `eq-addSelect extra-height ${isStudent ? "eq-card" : ""}`,

    // defaultSelectedValue: formData?.locationKey
    // ? formData.locationKey + ""
    // : "",,
    // defaultSelectedValue: "",

    // allowClear: true,
  };

  const MultipleDateSelectProps: ISelectProps = {
    mode: "multiple",
    showSearch: true,
    optionFilterProp: "label",
    filterOption: true,
    isTag: false,
    showArrow: false,
    allowClear: true,

    classNames: "eq-multiSelect eq-searchSelect extra-height",
    ddClassName: "eq-select-dropdown",
    icon: [
      <span className="eq-search-icon">
        <img alt="" src="/search-icon.svg" />
      </span>,
    ],
    options: cardDateOptions,
    defaultSelectedValue: selectedCardDates,
    changeFunc: (value: any) => {
      const dates = [...value];
      setSelectedCardDates(dates);
      // let levels: number = +value;
      setFormData({ ...formData, classDates: dates });
      selectedDateChange(cardkey, dates);
    },
  };

  const timeFromProps: ITimePickerOptions = {
    isSuffixIcon: false,
    isBordered: true,
    classNames: isStudent ? "eq-card" : "eq-event",
    defaultValue: timeFrom.valueOf(),
    minuteInterval: 15,
    placeHolder: "Select duration..",
    onChangeFunc: (time: any) => {
      setFormData({ ...formData, timeFrom: moment(time) });
      timeFromChange(tabIndex, cardkey, time);
    },
  };

  const removeEventClassCard = () => {
    removeCard(tabIndex, cardkey);
  };

  const collapsibleProps: IEqCollapse = {
    display: data.display,
    // classNames:
    //   maxSpaceCount && attendees && attendees.length == maxSpaceCount
    //     ? "eq-green-header"
    //     : "",
    header: [
      <div className="at-card-header">
        <Row className="at-header-row" align="middle">
          <Col className="content-col">
            <Row>
              {data.cardkey == 1 && (
                <>
                  <Col>
                    <div className="date-icon">
                      <EqIconCalendar
                        eqWidth={34}
                        eqHeight={34}
                        eqClass="eq-cal-icon"
                        eqFill="#083b49"
                      />
                      <div className="day">
                        {moment(startDate).format("DD")}
                      </div>
                      <div className="month">
                        {moment(startDate).format("MMM")}
                      </div>
                    </div>
                  </Col>
                </>
              )}

              <Col>
                <Row>
                  <Col className="header-label">{formData.height}</Col>
                </Row>
                {data.cardkey == 1 && (
                  <Row>
                    <div>{moment(timeFrom).format(TIME_FORMAT)}</div>
                  </Row>
                )}
              </Col>
              {/* <Col>
          <div className="header-label">
            {moment(timeFrom).format(TIME_FORMAT)}
          </div>
        </Col> */}

              {/* <Col>
                <div className="header-label">
                  {EqTrainingLevels[trainingLevel]}
                </div>
              </Col> */}

              {/* {maxSpaceCount && (
                <Col xs={2} sm={2} md={1}>
                  <div className="header-label">
                    {attendees ? attendees.length : 0}/{maxSpaceCount}
                  </div>
                </Col>
              )} */}

              {/* <Col xs={} sm={3} md={2}>
     
        </Col> */}
            </Row>
          </Col>
          <Col className="action-col">
            <Row>
              <Col>
                {enableCardRemove && attendees.length == 0 ? (
                  <div className="trash-bin">
                    <span>
                      <img
                        alt=""
                        src="/trash-alt-red.svg"
                        onClick={removeEventClassCard}
                      />
                    </span>
                  </div>
                ) : null}
              </Col>
              <Col>
                <MoreOutlined />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>,
    ],
  };
  function onChange() {
    setCheck(!check);
  }

  const eventClassCardDateOptions = () => {
    var dates = [] as any;

    multipleStartOn?.map((date: any) =>
      dates.push({
        key: moment(date).format(dateFormat),
        value: moment(date).format(dateFormat),
      })
    );

    setCardDateOptions(dates);
  };

  const handleOnclickRemoveAttendee = (
    tabIdx: number,
    cardkey: number,
    attendee: any
  ) => {
    // if attendee is paid then show popup Refund

    confirmPaymentRefund(tabIdx, cardkey, attendee.attendeeIndex);
  };

  const confirmPaymentRefund = (
    tabIdx: number,
    cardkey: number,
    attendeeKey: any
  ) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Would you like to Refund Attendee",
      okText: "Refund",
      cancelText: "Pay Later",
      onOk: () => removeAttendeeFromCard(tabIdx, cardkey, attendeeKey, true),
      onCancel: () =>
        removeAttendeeFromCard(tabIdx, cardkey, attendeeKey, false),
    });
  };

  React.useEffect(() => {
    // getStudentPopulation();

    createEventDurations();
    createTrainingLevels();
    if (multipleStartOn && multipleStartOn.length > 0) {
      eventClassCardDateOptions();
    }
    if (selectedGroups && selectedGroups.length > 0) {
      setGroupSelected(true);
    } else {
      setGroupSelected(false);
    }
  }, [
    data,
    selectedGroups,
    studentPopulation,
    searchStudentsList,
    studentsByGroup,
    multipleStartOn,
  ]);
  // }, [selectedGroups,studentPopulation,studentPopulation,searchStudentsList]);
  return (
    <>
      <div className="eq-create-class-card">
        <EqCollapse key={data.cardkey} {...collapsibleProps}>
          <div key={data.cardkey} className="card-body">
            <Row>
              <Col span={24}>
                <Row className="eq-input-group">
                  <Col
                    xs={data.cardkey > 1 ? 0 : 24}
                    md={data.cardkey > 1 ? 0 : 5}
                  >
                    <Row className="label-h2">From</Row>
                    <Row>
                      <EqTimePicker
                        isDisabled={isStudent}
                        {...timeFromProps}
                      ></EqTimePicker>
                    </Row>
                  </Col>
                  <Col xs={24} md={5}>
                    <Row className="label-h2">Title</Row>
                    <Row>
                      <EqInput
                        disabled={isStudent}
                        className="name-input"
                        defaultValue={data?.height ? data.height : null}
                        value={formData.height}
                        onChange={handleOnChangeHeight}
                      />
                    </Row>
                  </Col>
                  <Col xs={24} md={5}>
                    <Row className="label-h2">Description</Row>
                    <Row>
                      <EqInput
                        disabled={isStudent}
                        className="name-input"
                        defaultValue={
                          data?.classDescription ? data.classDescription : null
                        }
                        value={formData.classDescription}
                        onChange={handleOnChangeClassDescription}
                      />
                    </Row>
                  </Col>
                  {/* {trainingType != EqDisciplineType.Open ? (
                        <Col span={5}>
                          <Row className="label-h2">Duration</Row>
                          <Row>
                            <EqSelect
                              isDisabled={isStudent}
                              {...durationSelectProps}
                            />
                          </Row>
                        </Col>
                      ) : (
                        <Col span={5}>
                          <>
                            <Row className="label-h2">To</Row>
                            <Row>
                              <EqTimePicker
                                isDisabled={isStudent}
                                {...timeToProps}
                              ></EqTimePicker>
                            </Row>
                          </>
                        </Col>
                      )} */}

                  {/* {trainingType == EqDisciplineType.Jumping && (
                        <Col span={5}>
                          <Row className="label-h2">Level</Row>
                          <Row>
                            <EqSelect
                              isDisabled={isStudent}
                              {...levelSelectProps}
                            />
                          </Row>
                        </Col>
                      )} */}

                  {trainingType != EqDisciplineType.Open && (
                    <Col xs={24} md={5}>
                      <Row className="label-h2">{t("capacity")}</Row>
                      <Row className="name-number-input">
                        <EqInputNumber
                          disabled={isStudent}
                          classNames={`${isStudent ? "eq-card-input" : ""}`}
                          // defaultValue={4}
                          defaultValue={
                            data?.maxSpaceCount ? data.maxSpaceCount : 1
                          }
                          // defaultValue={
                          //   data?.maxSpaceCount!== null ? data.maxSpaceCount : trainingType == EqDisciplineType.Jumping ? 4:1
                          // }
                          min={1}
                          max={Number.MAX_SAFE_INTEGER}
                          // max={
                          //   trainingType != EqDisciplineType.Dressage
                          //     ? Number.MAX_SAFE_INTEGER
                          //     : 1
                          // }
                          value={formData.maxSpaceCount}
                          onChange={spaceChangeFunc}
                        />
                      </Row>
                    </Col>
                  )}
                  <Col xs={24} md={4}>
                    <Row className="label-h2">Price</Row>
                    <Row className="name-number-input">
                      {/* <EqSelect isDisabled={isStudent} {...levelSelectProps} /> */}
                      {!isStudent ? (
                        <EqInput
                          className="name-input"
                          defaultValue={data?.price ? data.price : null}
                          value={formData.price}
                          onChange={handleOnChangePrice}
                        />
                      ) : (
                        <EqInputNumber
                          classNames={`${isStudent ? "eq-card-input" : ""}`}
                          disabled={isStudent}
                          defaultValue={data?.price ? data.price : null}
                          value={formData.price}
                          onChange={handleOnChangePrice}
                        />
                      )}
                    </Row>
                  </Col>
                </Row>

                <Row className="eq-std">
                  <Col span={24}>
                    {attendees && attendees.length > 0 ? (
                      <div>
                        <Row className="eq-std-th hidden-sm">
                          <Col xs={8} sm={7} md={6} className="">
                            Name
                          </Col>
                          {/* <Col xs={7} sm={7} md={8} className="eq-std-th">
                            Horse
                          </Col> */}
                          {/* <Col xs={11} sm={7} md={8} className="">
                            Horse
                          </Col> */}
                          <Col xs={2} sm={2} md={1} className="">
                            Action
                          </Col>
                          {/* {!isStudent && (
                            <Col xs={3} sm={3} md={2} className="eq-std-th">
                              Status
                            </Col>
                          )} */}
                        </Row>
                        <Row className="eq-std-th hidden-md">
                          <Col className="">Students</Col>
                        </Row>
                        <>
                          {attendees?.map((item: any, index: number) => {
                            //

                            return (
                              <div className="drg-row">
                                <Draggable
                                  isDragDisabled={isStudent}
                                  key={item?.attendeeIndex + ""}
                                  draggableId={
                                    item?.attendeeIndex + "," + cardkey
                                  }
                                  // draggableId={item.attendeeIndex}

                                  // key={item?.key}
                                  // draggableId={item?.key}
                                  index={index}
                                >
                                  {(provided: any, snapshot: any) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      onClick={(e: any) => {
                                        console.log(e);
                                      }}
                                    >
                                      <Row
                                        key={index}
                                        className={`eq-std-row ${
                                          snapshot.isDragging
                                            ? "isDragging"
                                            : ""
                                        } `}
                                      >
                                        {/* <Col xs={2} sm={2} md={1}>
                                              <Checkbox></Checkbox>
                                            </Col> */}
                                        <Col xs={8} sm={7} md={6}>
                                          <Row>
                                            <Space>
                                              <Col
                                                flex="auto"
                                                className="eq-std-td"
                                                // xs={5}
                                                // sm={4}
                                                // md={3}
                                              >
                                                <span className="eq-up-img">
                                                  <img
                                                    src={
                                                      item?.user?.userImage
                                                        ?.url ||
                                                      getDefaultImage(
                                                        imageType.partner
                                                      )
                                                    }
                                                    className="user"
                                                    alt="user"
                                                  />
                                                </span>
                                              </Col>
                                              <Col
                                                className="eq-std-td"
                                                // xs={19}
                                                // sm={20}
                                                // md={21}
                                              >
                                                <div className="eq-up-name">
                                                  {item.attendUsingName
                                                    ? item.attendUsingName
                                                    : item?.user?.firstName ||
                                                      item?.user?.userName}
                                                </div>
                                                {/* <div>{item?.group?.name}</div> */}
                                              </Col>
                                            </Space>
                                          </Row>
                                        </Col>

                                        {/* <Col
                                          xs={11}
                                          sm={7}
                                          md={8}
                                          className="eq-std-td"
                                        >
                                         
                                          <div>{item?.classHorse}</div>
                                        </Col> */}

                                        <Col xs={2} sm={2} md={1}>
                                          {!isStudent && (
                                            <div className="action-col">
                                              <Row className="actions-btn">
                                                <Col className="eq-event-actions-trash">
                                                  <span>
                                                    <img
                                                      alt=""
                                                      // src="/trash-alt.svg"
                                                      src="/trash-alt-red.svg"
                                                      onClick={() =>
                                                        handleOnclickRemoveAttendee(
                                                          tabIndex,
                                                          cardkey,
                                                          item
                                                        )
                                                      }
                                                    />
                                                  </span>
                                                </Col>
                                              </Row>
                                            </div>
                                          )}
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                </Draggable>
                              </div>
                            );
                          })}
                        </>
                      </div>
                    ) : isStudent ? (
                      <div className="">No Student</div>
                    ) : // <div className="eq-std-row">No Student</div>
                    null}
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* {enableCardRemove ? (
              <span className="eq-btn-icon" onClick={removeEventClassCard}>
                <img alt="" src="/Delete.svg" />
              </span>
            ) : null} */}
          </div>
        </EqCollapse>
      </div>
      {contextHolder}
    </>
  );
};
export default EqEventClassCard;
