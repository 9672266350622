import React, { useState, useEffect } from "react";
import "./EqEventHolderProfileBoard.scss";
import { Row, Col, Tabs, message } from "antd";
import { Rate } from "antd";

import Api from "../../shared/Api";
import {
  GetEventMessage,
  ApiEndpoints,
  ValidateIsTrainer,
  getEmailFromToken,
  getDefaultImage,
  imageType,
  ValidateIsStudent,
  FrontEndPoints,
  ValidateIsUser,
} from "../../shared/Common";
import {
  EqDisciplineType,
  EqEventAccessType,
  EqLocationType,
  EqTrainingLevels,
  ProductType,
} from "../../shared/enums";

import { IProfileDto } from "../../shared/interfaces/IProfileDto";
import EqButton from "../../custom/eqButton/EqButton";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useAuthState, useAuthDispatch } from "../../context/Context";
import { notification } from "antd";
import { saveEvent } from "../../shared/LocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import Item from "antd/lib/list/Item";
import {
  CheckCircleTwoTone,
  CopyFilled,
  UserOutlined,
} from "@ant-design/icons/lib/icons";
import EqProductList from "../../custom/eqProductList/EqProductList";
import TabPane from "antd/lib/tabs/TabPane";
import { EqDashboardLayout } from "../../shared/EqDashboardLayout";
import RoleBasedRoute from "../../shared/RoleBasedRoute";
import CopyToClipboard from "react-copy-to-clipboard";
import config from "../../shared/Config";
import { EqInput } from "../../custom/eqInput/EqInput";
import { debug } from "console";
import EqScheduleList from "../../eqTrainer/eqScheduleList/EqScheduleList";
import { useTranslation, Trans } from "react-i18next";
import EqTags from "../../custom/eqTags/EqTags";

const EqEventHolderProfileBoard = (props: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = useAuthState();
  const isSignedIn: boolean = ValidateIsUser(userDetails);
  const isStudent: boolean = ValidateIsStudent(userDetails);
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const [isOwner, setIsOwner] = React.useState(false);
  const currentUserKey = getEmailFromToken();
  const [messageApi, contextHolder] = message.useMessage();

  const [messageString, setMessageString] = useState("");
  const [schedules, setSchedules] = useState([] as any);
  const [isError, setIsError] = React.useState(false);
  const [messageText, setMessageText] = React.useState("");

  const [errorMessage, setErrorMessage] = React.useState("");
  // const [message, setMessage] = React.useState("");

  const [areas, setAreas] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState([]) as any;
  const [imagesData, setImagesData] = React.useState([]) as any;

  const [population, setPopulation] = React.useState([]) as any;
  const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;
  const [eventsList, setEventsList] = React.useState([] as Array<any>);
  const [trainings, setTrainings] = React.useState([] as Array<any>);
  const [trainerHorsesList, setTrainerHorsesList] = React.useState({});
  const [studentHorsesList, setStudentHorsesList] = React.useState([]);

  const [profileData, setProfile] = useState<IProfileDto>();
  const [bookingButtonState, setBookingButtonState] = React.useState({
    buttonDisabled: false,
    buttonText: t("bookASession"),
  } as any);
  const [subscribeButtonState, setSubscribeButtonState] = React.useState({
    buttonDisabled: false,
    buttonText: "Subscribe",
    // buttonTextSubscribe: "Subscribe",
    // buttonTextSubscribed: "Subscribed",
  } as any);

  const [productsList, setProductsList] = React.useState({
    all: [],
    private: [],
    public: [],
  }) as any;

  const [activitiesList, setActivitiesList] = React.useState({
    all: [],
    events: [],
    trainings: [],
  }) as any;
  const [userEmail, setUserEmail] = React.useState("");

  const [openEventList, setOpenEventList] = React.useState(
    [] as Array<IEqEventData>
  );
  const [selectedUserKey, setSelectedUserKey] = useState() as any;

  schedules.map((f: IEqEventData, index: number) => {
    f.display = index == 0 ? true : false;
  });

  const messageClicked = () => {
    if (ValidateIsUser(userDetails)) {
      var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${userEmail}`;
      navigate(out1);
    } else {
      var out1 = FrontEndPoints.login;
      navigate(out1);
    }
  };

  // const GetProfessionalActivitiesByKey = (key: any) => {
  //   setErrorMessage("");
  //   // Api.post(ApiEndpoints.getProfessionalEvents, { Key: key }).then(response => {
  //   Api.post(ApiEndpoints.GetProfessionalActivitiesByKey, { Key: key }).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         var data = response.data
  //           ? [...response.data]
  //           : new Array<IEqEventData>();
  //         setEventsList(data);
  //         if (data.length > 0) {
  //           formatActivitiesListData(data);
  //         }
  //       } else {
  //         setIsError(true);
  //         setErrorMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setErrorMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };

  // const formatActivitiesListData = (data: any) => {
  //   var trainings = data?.filter(
  //     (opt: any) => opt.accessType == EqEventAccessType.Private
  //   );
  //   var events = data?.filter(
  //     (opt: any) => opt.accessType == EqEventAccessType.Public
  //   );

  //   var activityList = {
  //     all: [...data],
  //     events: [...events],
  //     trainings: [...trainings],
  //   };
  //   setActivitiesList({ ...activityList });
  // };

  const formatProductListData = (data: any) => {
    var privateList = data?.filter(
      (opt: any) => opt.type == ProductType.Private
    );
    var publicList = data?.filter((opt: any) => opt.type == ProductType.Public);

    var productList = {
      all: [...data],
      private: [...privateList],
      public: [...publicList],
    };
    setProductsList({ ...productList });
  };

  const GetProfileDetails = (key?: any) => {
    setMessageString("");
    const params = new URLSearchParams(window.location.search);
    let trainerKey = params.get("key") || key;

    // let trainerKey=selectedUserKey || key
    Api.post(ApiEndpoints.GetTrainerProfileByKey, { Key: trainerKey }).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? { ...response.data } : null;
          if (data != null) {
            console.log("trainer key");
            console.log(selectedUserKey);
            console.log(data.key);

            setSelectedUserKey(data.key);
            setUserEmail(data.email);

            // const studentIndex = data.trainerStudents.findIndex(
            //   (Item: any) => Item.student.email === currentUserKey
            // );
            // if (studentIndex !== -1) {
            //   setSubscribeButtonState({
            //     ...subscribeButtonState,
            //     buttonDisabled: true,
            //     buttonText: "Subscribed",
            //   });
            // } else {
            //   if (
            //     location.state?.prevPath == FrontEndPoints.login &&
            //     isStudent
            //   ) {
            //     SubscribeTrainer(data.email);
            //   }
            // }

           
            var profList = [] as any;
            data.disciplines?.map((item: any) =>
              profList.push(t(EqDisciplineType[Number(item.discipline)]))
            );

            var profile: any = {
              email: data.email,
              about: data.aboutMe,
              name: data.firstName
                ? data.firstName
                : "" + " " + data.lastName
                  ? data.lastName
                  : "",
              rating: 4.5,
              experience: "10 Years",
              area: data.locations
                ?.filter(
                  (opt: any) => opt.location?.type == EqLocationType.Home
                )
                .shift(),
              radius: "20Km",
              disciplineList: data.disciplines,
              imagePath: data.imagePath,
              display: false,
              professionsList: profList,
              userImage: data.userImage,
              trainingLevels: data.trainingLevels,
              serviceAreaList: data.locations?.filter(
                (opt: any) => opt.location?.type == EqLocationType.Work
              ),
            };
            setProfile(profile);
          }
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };

  const GetTrainerPublicEventsByKey = async (key?: any) => {
    setMessageString("");
    const params = new URLSearchParams(window.location.search);
    let trainerKey = params.get("key") || key;

    let path = ApiEndpoints.GetTrainerEventsByKey;
    Api.get(path + `?key=${trainerKey}`).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          setEventsList(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const GetUserProductsByKey = async (key?: any) => {
    setMessageString("");
    const params = new URLSearchParams(window.location.search);
    let userKey = params.get("key") || key;

    let path = ApiEndpoints.GetUserProductByUserKey;
    Api.get(path + `?userKey=${userKey}`).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          if (data.length > 0) {
            formatProductListData(data);
          }

        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getOpenEvents = () => {
    let list = [] as any;
    eventsList?.map((item: IEqEventData) => {
      if (item.disciplineType == EqDisciplineType.Open) {
        list.push(item);
      }
    });
    setOpenEventList(list);
  };

  useEffect(() => {
    ;
    console.log(window.location.search);
    console.log(userDetails);
    const params = new URLSearchParams(window.location.search);
    var key = params.get("key");
    var userKey;
    if (key) {
      userKey = Number(key);

      GetProfileDetails(userKey);
      GetTrainerPublicEventsByKey(userKey);
      GetUserProductsByKey(userKey)
      setSelectedUserKey(userKey);

      if (userKey == userDetails.key) {
        setIsOwner(true);
        GetTrainerTrainingsByKey(userKey);
        // GetTrainerPublicEvents();
        getImages();
        // GetTrainerStudents();
        getAreas();
        getWeekDays();
        getPopulation();
        getGroupPopulation();
        GetAllTrainerHorses();
      } else {
        setIsOwner(false);
        GetAllTrainerTrainingsLessonsByTrainerKey(userKey);
      }
    } else {
      userKey = 0;
      setSelectedUserKey(0);
    }
  }, [props]);

  const getImages = () => {
    Api.get("image/getEventImages").then(
      (response) => {
        if (response.data && response.data.length) {
          setImagesData(response.data);
        }
      },
      () => {
        setIsError(true);
        setErrorMessage("Error Occurred while fetching images from server");
      }
    );
  };
  const GetAllTrainerHorses = () => {
    Api.get(ApiEndpoints.GetAllHorses).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          var data = response.data ? [...response.data] : new Array<any>();
          setTrainerHorsesList(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetTrainerStudents = () => {
    Api.get(ApiEndpoints.GetTrainerStudents).then(
      (response) => {
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var trainerStudents = response.data;
          setIsError(false);
          // console.log(userDetails);
          setErrorMessage("");
          setTrainerStudents(response.data);
          // console.log(trainerHorsesList);
          [...trainerStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.trainer.horses.length > 0) {
              [...item.trainer.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
          console.log("studentHorses:", studentHorsesListOptions);
          // console.log("TrainerStudents:",response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setPopulation(response.data);
          // console.log("population:",response.data);
          // console.table(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getAreas = () => {
    Api.get(ApiEndpoints.eventGetAreas).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetTrainerTrainingsByKey = (key?: any) => {
    setErrorMessage("");
    const params = new URLSearchParams(window.location.search);
    let trainerKey = params.get("key") || key;

    Api.get(
      ApiEndpoints.TrainerTraining.GetAllByTrainerKey +
      `?trainerKey=${trainerKey}&ExcludeDisciple=${EqDisciplineType.Open}`
    ).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data
            ? [...response.data]
            : new Array<IEqEventData>();
          data.map((f: IEqEventData, index: number) => {
            f.display = index == 0 ? true : false;
          });
          setTrainings(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetAllTrainerTrainingsLessonsByTrainerKey = (key?: any) => {
    setErrorMessage("");
    const params = new URLSearchParams(window.location.search);
    let trainerKey = params.get("key") || key;

    Api.get(
      ApiEndpoints.TrainerTraining.GetAllLessonsByTrainerKey +
      `?trainerKey=${trainerKey}`
    ).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data
            ? [...response.data]
            : new Array<IEqEventData>();
          data.map((f: IEqEventData, index: number) => {
            f.display = index == 0 ? true : false;
          });
          setTrainings(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const handleOnUpdateTraining = (newData: any, oldData: any) => {
    if (trainings != undefined) {
      const newTrainings = [...trainings];
      var foundIndex = newTrainings.findIndex((x) => x.key == oldData.key);
      newTrainings[foundIndex] = newData;
      setTrainings(newTrainings);
    }
  };

  const handleOnUpdateEvent = (newData: any, oldData: any) => {
    if (eventsList != undefined) {
      const events = [...eventsList];
      var foundIndex = events.findIndex(
        (x) => x.referenceKey == oldData.referenceKey
      );
      events[foundIndex] = newData;
      setEventsList(events);
    }
  };

  // --- Remove That
  const handleRemoveTraining = (data: IEqEventData) => {
    Api.post(ApiEndpoints.deleteEvent, { Key: data.key }).then(
      (response) => {
        if (response && response.status == 200) {
          removeTrainingItem(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const handleRemoveEvent = (data: IEqEventData, refund?: boolean) => {
    // let path=ApiEndpoints.DeleteTrainerEvent + `/${data.key}`;
    let path =
      ApiEndpoints.DeleteTrainerEvent +
      `?key=${data.key}&refund=${refund}`;

    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeEventItem(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const removeEventItem = (data: any) => {
    if (eventsList != undefined) {
      var updatedList =
        eventsList != undefined
          ? eventsList.filter((item) => item.key !== data.key)
          : new Array<any>();
      setEventsList(updatedList);

      eventsList.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  const removeTrainingItem = (data: any) => {
    if (trainings != undefined) {
      var updatedList =
        trainings != undefined
          ? trainings.filter((item) => item.key !== data.key)
          : new Array<any>();
      setTrainings(updatedList);

      trainings.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  return (
    <>
      <div className="eq-trainer-profile-container">
        <Row>
          <Col md={6} xs={24} sm={24} className="trainer-info">
            <Row className="profile-img">
              <Col>
                <img
                  src={
                    profileData?.userImage?.url ||
                    getDefaultImage(imageType.partner)
                  }
                  className="user"
                  alt="user"
                />
              </Col>
            </Row>
            <Row className="profile-name">
              <Col>{profileData?.name}</Col>
            </Row>
            <Row className="profile-certified">
              <Col>{t("eventHolder")}</Col>
            </Row>
            <Row className="contact">
              <div onClick={messageClicked} className="about-message">
                <Row align="middle">
                  {/*<Col className="msg-icon">*/}
                  {/*  <span>*/}
                  {/*    <img alt="" src="/Envelope.svg" />*/}
                  {/*  </span>*/}
                  {/*</Col>*/}
                  <Col flex="auto" className="msg-text">
                    Send a message
                  </Col>
                </Row>
              </div>
            </Row>

            <Row className="eq-board-line"></Row>
            <Row>
              <Col span={10} className="profile-info-h">
                Experience:
              </Col>
              <Col span={10} className="profile-info-i">
                {profileData?.experience}
              </Col>
            </Row>
            <Row>
              <Col span={10} className="profile-info-h">
                Area:
              </Col>
              <Col span={10} className="profile-info-i">
                {profileData?.area?.location?.city}
              </Col>
            </Row>
            <Row>
              <Col span={10} className="profile-info-h">
                Radius:
              </Col>
              <Col span={10} className="profile-info-i">
                {profileData?.radius}
              </Col>
            </Row>

            <Row>
              <Col className="section-heading">{t("areaOfService")}</Col>
            </Row>

            {profileData &&
              profileData.serviceAreaList &&
              profileData?.serviceAreaList?.length > 0 && (
                <>
                  <div className="eq-list">
                    <div className="list">
                      {profileData.serviceAreaList.map(
                        (item: any, index: number) => (


                          <EqTags borderColor className="" text={item.location?.city}></EqTags>


                        )
                      )}
                    </div>
                  </div>
                </>
              )}
          </Col>
          <Col md={18} xs={24} sm={24} className="col-right">
            <Row>
              <Col md={18} xs={24} sm={24}>
                <div className="profile-about">
                  {/* <Row className="eq-board-line"></Row> */}

                  <Row className="about-info info">
                    <Col xs={24} sm={24} md={18} className="about-left-col">
                      <Row>
                        <h3 className="about-heading heading">
                          {t("aboutTrainer")}
                        </h3>
                      </Row>
                      <Row className="about-text">{profileData?.about}</Row>
                    </Col>
                    <Col></Col>
                  </Row>
                </div>
              </Col>
              <Col md={6} xs={24} sm={24}>
                <div className="profile-about">
                  <Row className="about-info info">
                    <Col xs={24} sm={24} md={18} className="about-left-col">
                      <Row>
                        <h3 className="about-heading heading">{t("discipline")}</h3>
                      </Row>

                      {profileData &&
                        profileData.disciplineList &&
                        profileData?.disciplineList?.length > 0 && (
                          <>
                            {profileData?.disciplineList?.map(
                              (item: any, index: number) => (
                                <Row>
                                  <Row>
                                    <EqTags borderColor className=""
                                      text={t(EqDisciplineType[item.discipline?.type])}></EqTags>
                                  </Row>
                                </Row>
                              )
                            )}
                          </>
                        )}
                    </Col>
                  </Row>

                  <Row>
                    {profileData?.area?.location?.city ? (
                      <Col flex="end" className="location">
                        <div>
                          <Col className="loc-info">
                            <Col className="loc-icon">
                              <span className="">
                                <img alt="" src="/chart-pie.svg" />
                              </span>
                            </Col>
                            <Col className="my-loc">
                              {profileData?.area?.location?.city}
                            </Col>
                          </Col>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <EqScheduleList
                {...{
                  isOwner: isOwner,
                  eventList: [...eventsList],
                  trainings: [...trainings],
                  onCreateOrUpdateTraining: handleOnUpdateTraining,
                  onCreateOrUpdateEvent: handleOnUpdateEvent,
                  onStudentJoin: GetAllTrainerTrainingsLessonsByTrainerKey,
                  onRemoveTraining: handleRemoveTraining,
                  onRemoveEvent: handleRemoveEvent,
                  areas: areas,
                  imagesData: imagesData,
                  weekDays: weekDays,
                  population: population,
                  groupPopulation: groupPopulation,
                  // trainerStudents: trainerStudents,
                  studentHorsesList: studentHorsesList,
                  profileData: profileData,
                  activitiesList: activitiesList,
                  publicProducts: [...productsList?.public],
                  isStudent: isStudent,
                  isSubscribed: subscribeButtonState.buttonDisabled,
                }}
              ></EqScheduleList>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default EqEventHolderProfileBoard;
