import * as React from 'react';
import './EqHorseGroupList.scss';
import EqGroupMembers, { IEqGroupMember } from '../eqGroupMembers/EqGroupMembers';
import { Row, Col, message, Popconfirm, Tooltip } from 'antd';
import { Checkbox } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { IStudentGroupDto } from '../../shared/interfaces/IStudentGroupDto';
import { IStudentDto } from '../../shared/interfaces/IStudentDto';
import EqButton from '../../custom/eqButton/EqButton';
import EqTags from '../../custom/eqTags/EqTags';
import EqCollapse from '../../custom/eqCollapse/EqCollapse';
import Avatar from 'antd/lib/avatar/avatar';
import moment from 'moment';

export interface IEqGroupHorsesList {
    groupMembersList: Array<any>;
    delFunc?: (item: any) => void;
    selected?: boolean;
    hideGroup?: boolean;
    hideName?: boolean;
}

const EqHorseGroupList = ({ groupMembersList, delFunc, selected, hideGroup, hideName }: IEqGroupHorsesList) => {

    const { t, i18n } = useTranslation();
    const [isCheckAll, setIsCheckAll] = React.useState(false);
    const [selectedAttendees, setSelectedAttendees] = React.useState([]) as any;
    const [membersList, setMembersList] = React.useState(groupMembersList) as any;
    const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([] as any);
    const [copyMessage, contextCopyMessage] = message.useMessage();

    const gutter = { xs: 8, sm: 16, md: 24, lg: 32 };

    const checkAll = (e: any) => {

        setIsCheckAll(e.target.checked);

        const updatedMembersList = membersList.map((item: any) => {
            return {
                ...item,
                selected: e.target.checked,
            };
        });

        setMembersList(updatedMembersList);
        const updatedSelectedCheckboxes = updatedMembersList.filter((x: any) => x.selected);
        setSelectedCheckboxes(updatedSelectedCheckboxes);
    }

    const toggleCheck = (member: IStudentGroupDto, checked: boolean) => {
        if (checked) {
            selectedAttendees.push(member);
        }
        else {
            let filteredMembers = selectedAttendees.filter((item: IEqGroupMember) => item.member?.key !== member.key);
            setSelectedAttendees([...filteredMembers]);
        }
    }

    const handleOnCheck = (member: any) => {
        const list = [...membersList];
        const foundIndex = list.findIndex((x) => x.key === member.key);
        const prevmember = list.find((x) => x.key === member.key);
        prevmember.selected = !prevmember.selected;
        list[foundIndex] = prevmember;
        setMembersList(list);
        const updatedSelectedCheckboxes = list.filter((x) => x.selected);
        setSelectedCheckboxes(updatedSelectedCheckboxes);
    };

    React.useEffect(() => {
        setMembersList(groupMembersList);
    }, [groupMembersList]);

    //members______________________________________________________
    function onChange(event: any) {
        handleOnCheck(event);
    }
    const deleteClicked = (member: any) => {
        //e.preventDefault();
        //e.stopPropagation();
        
        if (delFunc != null) {
            delFunc(member);
        }
    }

    return (
        <Row justify="space-between" >

            <Col span={24}>
                <Row className="eq-sl-row hidden-sm" justify="space-between" align="middle">
                    {hideGroup != true &&
                        <Col className="eq-th-checkbox" xs={2} sm={2} md={2}>
                            <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                        </Col>
                    }
                    <Col className="eq-sl-th" xs={14} sm={14} md={14}>
                        {t("name")}
                    </Col>
                    <Col className="eq-sl-th" xs={5} sm={5} md={5}>
                        {t("dateOfBirth")}
                    </Col>

                    <Col className="eq-sl-th" xs={3} sm={3} md={3}>
                        {t("actions")}
                    </Col>
                </Row>
                <div className="hidden-md">
                    <Row justify="start" align="middle" className="eq-list-checkAll">
                        <Col span={2}>
                            <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                        </Col>
                        <Col flex="auto">
                            {t("checkAll")}
                        </Col>

                        <Col>
                            <EqButton
                                type="button"
                                classNames="btn-background-orange"
                            >

                            </EqButton>
                        </Col>
                    </Row>
                </div>
                {membersList && membersList.length > 0 &&
                    <>
                        {
                            membersList.map((member: any, index: number) =>
                                <>

                                    <Row className="eq-s-tr hidden-sm" justify="space-between" align="middle">
                                        {hideGroup != true &&
                                            <Col className="eq-td-checkbox" span={2}>
                                                <Checkbox
                                                    onChange={() => {
                                                        handleOnCheck(member);
                                                    }}
                                                    checked={member.selected}></Checkbox>
                                            </Col>
                                        }
                                        <Col className="eq-s-td" span={14}>
                                            <Avatar src={member?.imageUrl} className="horse-avatar" ></Avatar>
                                            {" "} {member?.name}
                                        </Col>
                                        <Col className="eq-s-td horse-detail-copy" span={5}>
                                            {member?.dateOfBirth && moment(member.dateOfBirth).format("DD-MMM-YYYY")}
                                        </Col>

                                        <Col className="eq-s-td action-btn" span={3}>
                                            <div className="" >
                                                <span className="eq-action-delete">
                                                    <img alt="" src="/Delete.svg" onClick={(e: any): void => deleteClicked(member)} /></span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="hidden-md mobile-view">
                                        <EqCollapse header={
                                            <Row justify="start" align="middle">
                                                <Col className="" span={2}>
                                                    <Checkbox
                                                        onChange={() => {
                                                            handleOnCheck(member);
                                                        }}
                                                        checked={member.selected}></Checkbox>
                                                </Col>
                                                <Col className="" flex="auto">
                                                    {member?.user?.firstName ?? member?.user?.userName ?? member?.group?.name ?? ""}

                                                </Col>
                                            </Row>


                                        } >
                                            <>
                                                <Row className="eq-card-row">
                                                    <Col className="eq-card-col-heading" span={24}>
                                                        {t("name")}
                                                    </Col>
                                                    <Col className="eq-card-row horse-detail" span={24}>
                                                        {member?.user?.firstName ?? member?.user?.userName ?? member?.group?.name ?? ""}

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col flex="auto" className="eq-card-divider"></Col>
                                                </Row>
                                                <Row className="eq-card-row">
                                                    <Col className="eq-card-col-heading " span={24}>
                                                        {t("e-mail")}
                                                    </Col>
                                                    <Col className="horse-detail-copy" span={24}>
                                                        <div className="copy-text">
                                                            <Tooltip title={t("copyEmails")} color="#9cb9ca">
                                                                {member?.user?.email ?? ""}
                                                            </Tooltip>
                                                        </div>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col flex="auto" className="eq-card-divider"></Col>
                                                </Row>
                                                <Row>
                                                    <Col flex="auto" className="eq-card-divider"></Col>
                                                </Row>
                                                <Row className="eq-card-row">
                                                    <Col className="eq-card-col-heading" span={24}>
                                                        {t("groups")}
                                                    </Col>
                                                    <Col className="eq-s-td eq-s-groups" span={24}>
                                                        {hideGroup != true &&
                                                            <>
                                                                {member && member.group ? (
                                                                    <EqTags color={member?.group?.color}
                                                                        text={member.group.name}></EqTags>
                                                                ) :
                                                                    (
                                                                        member.user?.groups?.map((item: any, index: number) =>
                                                                            <EqTags
                                                                                color={item?.group?.color}
                                                                                key={index}
                                                                                text={item?.group?.name}></EqTags>
                                                                        )
                                                                    )
                                                                }
                                                            </>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row className="eq-card-row">
                                                    <Col className="eq-card-col-heading" span={24}>
                                                        {t("actions")}
                                                    </Col>
                                                    <Col span={24} className='action-btn'>
                                                        <div className="" >
                                                            <span className="eq-action-delete"><img alt="" src="/Delete.svg" onClick={deleteClicked} /></span>
                                                        </div>

                                                    </Col>


                                                </Row>

                                            </>

                                        </EqCollapse>

                                    </div >

                                </>
                            )
                        }
                    </>
                }
            </Col>
        </Row>
    );
}
export default EqHorseGroupList;