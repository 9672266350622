import * as React from "react";
import "./EqTrainerTrainingJoinRequestBoard.scss";
import Api from "../../shared/Api";
import { ApiEndpoints } from "../../shared/Common";
import { Row, Col } from "antd";
import { EqEventAttendeeStatus } from "../../shared/enums";
import EqEventInvitesList from "../../eqStudent/EqEventinviteList/EqEventinviteList";
import EqEventInviteDetail from "../../eqStudent/EqEventInviteDetail/EqEventInviteDetail";
import { useTranslation, Trans } from 'react-i18next';

export interface IEqTrainerTrainingJoinRequestBoard {
  onloadActivity?: any;
}

const EqTrainerTrainingJoinRequestBoard = ({
  onloadActivity,
}: IEqTrainerTrainingJoinRequestBoard) => {
  const [messageString, setMessageString] = React.useState("");
  const [eventInvites, setEventInvites] = React.useState([] as any);
  const [pendingInvites, setPendingInvites] = React.useState([] as any);
  const [acceptedInvites, setAcceptedInvites] = React.useState([] as any);
  const [steps, setSteps] = React.useState(1 as number);
  const [eventDetail, setEventDetail] = React.useState({} as any);
  const { t, i18n } = useTranslation();

  function AcceptStudentTrainingRequest(e: any) {
    Api.post(ApiEndpoints.TrainingRequest.AcceptStudentTrainingRequest, {
      key: e.eventKey,
      email: e.user.email,
    }).then(
      (response) => {
        if (response && response.status == 200) {
          GetAllPendingTrainerEventInvites();
          setSteps(1);
        } else {
          setMessageString("");
        }
      },
      (err) => {
        setMessageString("");
      }
    );
  }
  function AcceptStudentTrainingMultipleRequests(e: any) {
    Api.post(ApiEndpoints.TrainingRequest.AcceptStudentTrainingMultipleRequests, e).then(
      (response) => {
        if (response && response.status == 200) {
          GetAllPendingTrainerEventInvites();
          setSteps(1);
        } else {
          setMessageString("");
        }
      },
      (err) => {
        setMessageString("");
      }
    );
  }

  function RejectStudentTrainingMultipleRequests(e: any) {
    Api.post(ApiEndpoints.TrainingRequest.RejectStudentTrainingMultipleRequests, e).then(
      (response) => {
        if (response && response.status == 200) {
          GetAllPendingTrainerEventInvites();
          setSteps(1);
        } else {
          setMessageString("");
        }
      },
      (err) => {
        setMessageString("");
      }
    );
  }
  function RejectStudentTrainingRequest(e: any) {
    Api.post(ApiEndpoints.TrainingRequest.RejectStudentTrainingRequest, {
      key: e.eventKey,
      email: e.user.email,
    }).then(
      (response) => {
        if (response && response.status == 200) {
          GetAllPendingTrainerEventInvites();
          setSteps(1);
        } else {
          setMessageString("");
        }
      },
      (err) => {
        setMessageString("");
      }
    );
  }

  const viewEventDetail = (detail: any) => {
    if (steps === 1) {
      setSteps(2);
      console.log(detail);
      setEventDetail(detail);
    } else {
      setSteps(1);
    }
  };
  const acceptClickedFun = (event: any) => {
    AcceptStudentTrainingRequest(event);
    // setSteps(3);
    // console.log(event);
  };
  const acceptAllClickedFun = (events: any) => {
    let result = events.map((item: any) => {
      return { key: item.eventKey, email: item.user.email };
    });
    AcceptStudentTrainingMultipleRequests(result);
    // console.log(events);
    // setSteps(3);
  };
  const rejectClickedFun = (event: any) => {
    RejectStudentTrainingRequest(event);
    // console.log(event);
    // setSteps(5);
  };
  const rejectAllClickedFun = (events: any) => {
    let result = events.map((item: any) => {
      return { key: item.eventKey, email: item.user.email };
    });
    RejectStudentTrainingMultipleRequests(result);
    // setSteps(5);
  };
  React.useEffect(() => {
    
    GetAllPendingTrainerEventInvites();
  }, []);

  const GetAllPendingTrainerEventInvites = () => {
    setMessageString("");
    Api.get(ApiEndpoints.TrainingRequest.GetAllTrainingJoinRequestsByStudents).then(
      (response) => {
        if (response && response.status == 200) {
        
          var data = response?.data ? response?.data : ([] as any);
          setPendingInvites(data);
          if (data.length) {
            onloadActivity(true);
          } else {
            onloadActivity(false);
          }
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };
  return (
    <>
      <div className="eq-trainerEventInvitationBoard-container">
        <div className="">
          {/*Pending Invites*/}
          {steps === 1 && (
            <EqEventInvitesList
            requestText= {t("requestsToBook")}
            invitesList= {[...pendingInvites]}
            setEventDetail= {viewEventDetail}
            step= {steps}
            ></EqEventInvitesList>
          )}
        </div>
        <div className="">
          {/*All Invites*/}
          {steps === 2 && (
            <EqEventInviteDetail
              {...{
                requestText: t("requestsToBook"),
                props: eventDetail,
                acceptClicked: acceptClickedFun,
                acceptAllClicked: acceptAllClickedFun,
                rejectClicked: rejectClickedFun,
                rejectAllClicked: rejectAllClickedFun,
                setEventDetail: viewEventDetail,
                step: steps,
              }}
            ></EqEventInviteDetail>
          )}
        </div>
      </div>
    </>
  );
};
export default EqTrainerTrainingJoinRequestBoard;
