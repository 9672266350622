import * as React from 'react';
import './eqAutocomplete.scss';
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode, getDetails } from "use-places-autocomplete";
import EqButton from '../eqButton/EqButton';
import { Input } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import { useLoadScript } from "@react-google-maps/api";
import { googleMap_API_Key } from '../../shared/Common';
import { ILocationDto } from '../../shared/interfaces/ILocationDto';
import { useTranslation } from 'react-i18next';

const EqAutocomplete = ({ setSelected, setLocation }: any) => {
    const { t } = useTranslation();

    const [selectedPlace, setSelectedPlace] = React.useState({} as ILocationDto);
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
    });
    const handleInput = (e: any) => {
        // Update the keyword of the input element
        setValue(e.target.value);
    };
    const getPlaceDetails = (place_id: any, description:any, lat:any, lng:any) => {
        ;
        // console.log(place_id);
        const parameter = {
            // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
            placeId: place_id,
            // Specify the return data that you want (optional)
        };
        getDetails(parameter)
            .then((details:any) => {
                console.log("Details: ", details);
                addLocation(details);
                var zip = "";
                var city = "";
                var pic = "";
                if (details?.photos && details?.photos.length > 0) {
                    pic = details?.photos[0]?.getUrl();
                }
                details?.address_components?.map((cmp: any) => {
                    //console.log(cmp);
                    if (cmp.types.includes("postal_code")) {
                        zip = cmp?.long_name;
                    }
                    else if (cmp.types.includes("administrative_area_level_1")) {
                        city = cmp?.long_name;
                    }
                });
                //console.log({ locationName: description, street: description, longitude: lng, latitude: lat, city: city, zipCode: zip, locationImageUrl: pic });
                setLocation({ locationName: description, street: description, longitude: lng, latitude: lat, city: city, zipCode: zip, locationImageUrl:pic});
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    }

    const handleSelect = ({ place_id, description }: any) =>
        () => {
            // When user selects a place, we can replace the keyword without request data from API
            // by setting the second parameter to "false"
            setValue(description, false);
            clearSuggestions();
            // Get latitude and longitude via utility functions
            getGeocode({ address: description }).then((results) => {
                const { lat, lng } = getLatLng(results[0]);
                console.log("📍 Coordinates: ", { lat, lng });
                setSelected({ lat, lng });
                getPlaceDetails(place_id, description, lat, lng);
            });
        };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;
            //console.log(suggestion);
            return (
                <li key={place_id} onClick={handleSelect(suggestion)}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });

    const addLocation = (placeDetails: any) => {
    }
    return (
        <div className="eq-autocomplete">
            <Input
                value={value}
                onChange={handleInput}
                disabled={!ready}
                placeholder={t("enterPlace")}
                className="eq-search-input"
                prefix={<SearchOutlined />}
            />
            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            <div className="eq-search-results">
                {status === "OK" && <ul>{renderSuggestions()}</ul>}
            </div>
        </div>
    );
}
export default EqAutocomplete;