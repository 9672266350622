import React from 'react'
import AsyncImage from '../custom/asyncImage/AsyncImage'
import EqButton from '../custom/eqButton/EqButton'
import { useTranslation, Trans } from "react-i18next";
import './Features.scss'
import { Card, Carousel, Col, Row } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faChessKnight, faCalendarDays, faBoxArchive, faAddressCard, faCoins, faHorseHead } from "@fortawesome/free-solid-svg-icons";
import {
    CheckCircleOutlined
} from "@ant-design/icons";
import EqReview from '../custom/eqReview/EqReview';
import { useNavigate } from 'react-router-dom';
import { FrontEndPoints } from '../shared/Common';
import Meta from 'antd/lib/card/Meta';
const Features = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const gutter = { xs: 8, sm: 16, md: 24, lg: 32 };
    const basicFeature = [
    ]
    const eventHolder = [
        {
            id: 1,
            text: "If your event free of charge, is is free to advertise your event on our site.",
        },
        {
            id: 2,
            text: "Set a limit for participants in each class.",
        },
        {
            id: 3,
            text: "Create your own event booking form that fits your special needs.",
        },
        {
            id: 4,
            text: "Get paid upfront",
        },
        {
            id: 5,
            text: "All participants get a receipt on email",
        },
        {
            id: 6,
            text: "Automatically collecting all the transactions in one document",
        },
        {
            id: 7,
            text: "Automatically get a  startinglist of all participants",
        },
    ]
    const ridingSchools = [
        {
            id: 1,
            text: "Sell products on your profile or send products as orders",
        },
        {
            id: 2,
            text: "Open for drop in on your created lessons to fill up all the spots.",
        },
        {
            id: 3,
            text: "Set up each lesson with riders and horses.",
        },
        {
            id: 4,
            text: "Set up lessons for weeks ahead and sell a package of lessons.",
        },
        {
            id: 5,
            text: "Send training invitation to your students for booking of trainings.",
        },
        {
            id: 6,
            text: "Get overview of all your horses vaccinations, deworming and shoes and set alerts for when they need their tasks done.",
        },
        {
            id: 7,
            text: "Get an overview of all your finances and transactions.",
        },
        {
            id: 8,
            text: "See a schedule for all your horses and create trainings and tasks.",
        },
    ]
    const trainer = [
        {
            id: 1,
            text: "Set up your available time slots and open for booking requests on your profile.",
        },
        {
            id: 2,
            text: "Invite students to book your group trainings or individual lessons.",
        },
        {
            id: 3,
            text: "Adding locations to your trainings make other people see where and when you have training and how many spots are free.",
        },
        {
            id: 4,
            text: "Let other people book your trainings on the right level and time.",
        },
        {
            id: 5,
            text: "Get overview of all the financial activities in your training business. No more investigating who showed up to which training, who paid and who has not paid.",
        },
        {
            id: 6,
            text: "Set your terms as a trainer.",
        },
        {
            id: 7,
            text: "Set your payment deadline and cancelation deadline.",
        },
        {
            id: 8,
            text: "Add your horses and students and set up a schedule.",
        },
    ]
    const riders = [
        {
            id: 1,
            text: "Get a complete overview of all horse events in your area and discipline.",
        },
        {
            id: 2,
            text: "Manage your horses through our simple horse management app.",
        },
        {
            id: 3,
            text: "Add trainings and tasks in your horses’ calendar.",
        },
        {
            id: 4,
            text: "Save information about your horses and plan their next activities.",
        },
        {
            id: 5,
            text: "Set alerts for when your horse needs a new vaccination or their next deworming",
        },
    ]
    const featuresData = [
        {
            icon: faCalendarDay,
            heading: "Organize Events",
            description:
                "Discover the ultimate solution for hosting your horse event! Set up classes and multiple options for people to sign up and get an automatic starting list of attendees. If your event is free of charge, it is free to publich your event on our platform.",
        },
        {
            icon: faHorseHead,
            heading: "Riding School Management",
            description:
                "Get an overview of the horses’ activities, set up lessons, sell drop-in and package lessons. Get an overview of all the students and groups. Let the students see all planned lessons and free spots and open lessons for easy one-click booking requests. Get a full overview of all the finances.",
        },
        {
            icon: faBoxArchive,
            heading: "Sell Products",
            description:
                "Sell your products on your public profile, or send your products as an order to your students or clients. With a few clicks, you can send a product to selected people and get an overview of everyone who has bought and paid for your products.",
        },
        {
            icon: faCalendarDays,
            heading: "Organize Your Lessons",
            description:
                "Open time slots in your calendar to be booked, let people see where your lessons are and how many spots are free. Organize your lessons in groups and invite your students. Set up your payment settings and cancellation deadlines.",
        },
        {
            icon: faAddressCard,
            heading: "Horse Management",
            description:
                "Get an overview of all the horses’ activities. Set up tasks for your horses and get alerts on selected tasks. See the training schedule of each horse and track their training.",
        },
        {
            icon: faCoins,
            heading: "Full Overview of Finances",
            description:
                "No more having to check if people have paid and chasing your payments. On our platform, you will have a full overview of all bookings and payments. Our system automatically reminds students to pay for their bookings.",
        },
    ];
    const callRegister = () => {
        navigate(FrontEndPoints.register);
    };

    return (
        <>
            <div className='eq-features'>
                <div className="page-banner">
                    <div className="overlay">
                        <div className="feature-banner-text">
                            <p className='text'>Equinet connects people with passion for horses.</p>
                            <h1 className="heading">
                                Faster and smoother booking and
                                <br /><div className='gradient'></div>
                                administration with Equinet
                            </h1>
                        </div>
                        <div className="create-profile-btn">
                            <EqButton
                                type="button"
                                centered="true"
                                onClick={callRegister}
                            >
                                {t("Get free trial")}
                            </EqButton>
                        </div>
                        <AsyncImage className="hero-image-banner" src="eqFeatureBanner.png" />

                    </div>
                </div>
                <div className='features-section'>
                    <div className='feature-sub-section'>
                        <div className='feature-img-section'>
                            <AsyncImage className="feature-img feature-img-1" src="Ellipse1.png" />
                            <AsyncImage className="feature-img feature-img-2" src="Ellipse2.png" />
                            <AsyncImage className="feature-img feature-img-3" src="Ellipse3.png" />
                            <AsyncImage className="feature-img feature-img-4" src="Ellipse4.png" />
                            <AsyncImage className="feature-img feature-img-5" src="Ellipse5.png" />
                            <AsyncImage className="feature-img feature-img-6" src="Ellipse6.png" />
                        </div>
                        
                        <div className='basic-feature'>
                            <h3 className="heading">Basic Features</h3>
                            {/* <Row  className='feature-container'> */}
                           
                                <Row className='feature-row' gutter={[gutter, gutter]}>
                                    {featuresData.map((feature, index) => (
                                        <Col className='feature-col' md={12} lg={8} sm={24}>
                                            <Card
                                                key={index}
                                                hoverable
                                                className="basic-content"
                                            // style={{ width: '100%' }}
                                            >
                                                <Col>
                                                    <FontAwesomeIcon className="basic-icon" icon={feature.icon} />
                                                    <h4 className="heading">{feature.heading}</h4>
                                                    <p>{feature.description}</p>
                                                </Col>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            
                        </div>
                    </div>
                </div>
                <div className='offer-section'>
                    <h3 className="heading">What we offer</h3>
                    <div className="register-block">
                        <div className="container">
                            <Row className="rider event-holder">
                                <Col className="rider-image event-holder-image" sm={24} md={10}>
                                    <div className='image-block'>
                                        <AsyncImage className="image" src="eventHolderMobile.png" />
                                    </div>
                                </Col>
                                <Col className="rider-register" sm={24} md={12}>
                                    <div className="rider-desc">
                                        <h4 className="heading">
                                            {t("Event Holder")}
                                        </h4>
                                        <div className="explanation">
                                            <p className='text1'>
                                                {t(
                                                    "Advertise your horse event on our platform let our system do the administration of your event."
                                                )}
                                            </p>
                                            <p className='text2'>
                                                {t("Our goal is to collect all the horse events in all disciplines to contribute to a better overview for everyone in the sport and contribute to recruitment through availability of events. Do not let people miss out on your event and a possibility of raised knowledge, experience and new connections.")}
                                            </p>
                                        </div>
                                        <div className='rigester-points'>
                                            <ul>
                                                {eventHolder.map(event => (
                                                    <li key={event.id}>
                                                        <div >
                                                            <CheckCircleOutlined className='icon' />
                                                        </div>
                                                        <span className="event-heading">{event.text}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="rider riding-schools">
                                <Col className="rider-register" sm={24} md={12}>
                                    <div className="rider-desc">
                                        <h4 className="heading">
                                            {t("Riding Schools")}
                                        </h4>
                                        <div className="explanation">
                                            <p className='text1'>
                                                {t(
                                                    "Organize your riding school with us We offer unique services online for your riding schools' bookings and lessons to run smoothly according to your preferences."
                                                )}
                                            </p>
                                        </div>
                                        <div className='rigester-points'>
                                            <ul>
                                                {ridingSchools.map(event => (
                                                    <li key={event.id}>
                                                        <div >
                                                            <CheckCircleOutlined className='icon' />
                                                        </div>
                                                        <span className="event-heading">{event.text}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="rider-image riding-school-image" sm={24} md={10}>
                                    <div className='image-block image-block-1'>
                                        <AsyncImage className="image" src="ridingSchoolMobile.png" />
                                    </div>
                                    <div className='image-block image-block-2'>
                                        <AsyncImage className="image" src="RidingSchoolLaptop.png" />
                                    </div>

                                </Col>
                            </Row>
                            <Row className="rider trainer">
                                <Col className="rider-image trainer-image" sm={24} md={10}>
                                    <div className='image-block'>
                                        <AsyncImage className="image" src="trainerMobile.png" />
                                    </div>
                                </Col>
                                <Col className="rider-register" sm={24} md={12}>
                                    <div className="rider-desc">
                                        <h4 className="heading">
                                            {t("Trainer")}
                                        </h4>
                                        <div className="explanation">
                                            <p className='text1'>
                                                {t(
                                                    "Connect to your students through our platform and get automatic bookings. Set up your available time slots and open for booking requests on your profile. Invite students to book your group trainings or individual lessons. Adding locations to your trainings make other people see where and when you have training and how many spots are free. Get a full overview of payment status and your finances. No more chasing your students for money. Let our system handle all your pains."
                                                )}
                                            </p>
                                        </div>
                                        <div className='rigester-points'>
                                            <ul>
                                                {trainer.map(event => (
                                                    <li key={event.id}>
                                                        <div >
                                                            <CheckCircleOutlined className='icon' />
                                                        </div>
                                                        <span className="event-heading">{event.text}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="rider student">
                                <Col className="rider-register" sm={24} md={12}>
                                    <div className="rider-desc">
                                        <h4 className="heading">
                                            {t("Riders")}
                                        </h4>
                                        <div className="explanation">
                                            <p className='text1'>
                                                {t(
                                                    "Never miss out on an event again when keeping track on our platform that covers all horse events in your area and dicipline. Track your horses’ activities and get an overview of all your lessons and horse activities in your calendar."
                                                )}
                                            </p>
                                        </div>
                                        <div className='rigester-points'>
                                            <ul>
                                                {riders.map(event => (
                                                    <li key={event.id}>
                                                        <div >
                                                            <CheckCircleOutlined className='icon' />
                                                        </div>
                                                        <span className="event-heading">{event.text}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="rider-image student-image" sm={24} md={10}>
                                    <div className='image-block'>
                                        <AsyncImage className="image" src="riderMobile.png" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="reviews" style={{ display: "none" }}>
                    <h3 className="heading">What our customers says</h3>
                    <EqReview />
                </div>
            </div>
        </>
    )
}
export default Features
