import React from "react";
import { Tabs, Input, Button, Table, Row, Col } from "antd";

import EqResponseTab from "./ResponseTab/EqResponseTab";
import EqStatusTab from "./StatusTab/EqStatusTab";
import "./EqEmailReportBoard.scss"; // Import the external CSS file
const { TabPane } = Tabs;

const EqEmailReportBoard: React.FC = () => {
  return (
    <Row className="eqEmailReport">
      <Col>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Response" key="1">
            <EqResponseTab />
          </TabPane>
          <TabPane tab="Status" key="2">
            <EqStatusTab />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default EqEmailReportBoard;
