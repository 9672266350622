// EqSubscriptionForm.tsx

import React, { useState, useEffect, ChangeEvent } from "react";
import { Modal, Button } from "antd";

import {
  EqProfessionType,
  EqSubscriptionRoleType,
} from "../../../shared/enums";

import EqSelect from "../../../custom/eqSelect/EqSelect";
import { openNotificationWithIcon } from "../../../shared/Common";

interface ICreateSubscription {
  // roleType: EqSubscriptionRoleType | undefined;
  email: string;
  subscriptionSettingId: string;
}

interface EqSubscriptionFormProps {
  professionals: any;
  subscriptionSettings: any;
  visible: boolean;
  onCreate: (values: ICreateSubscription) => void;
  onCancel: () => void;
}

const EqSubscriptionForm: React.FC<EqSubscriptionFormProps> = ({
  professionals,
  subscriptionSettings,
  visible,
  onCreate,
  onCancel,
}) => {
  const initialFormData: ICreateSubscription = {
    // roleType: undefined,
    email: "",
    subscriptionSettingId: "",
  };

  const [formData, setFormData] =
    useState<ICreateSubscription>(initialFormData);

  useEffect(() => {
    // Reset form data if creating a new setting
    setFormData(initialFormData);
  }, [professionals, subscriptionSettings]);

  const handleSelectChange = (name: string) => (value: string) => {
    
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = () => {
    let validate = validateData(formData);
    if (validate) {
      onCreate(formData);
    }
    
    // Reset form data after saving
    setFormData(initialFormData);
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    onCancel();
  };
  const validateData = (data: ICreateSubscription): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    // if (
    //   !Object.values(EqSubscriptionRoleType).includes(
    //     data.roleType as EqSubscriptionRoleType
    //   )
    // ) {
    //   openNotificationWithIcon(msg, "Invalid Role Type" + desc);
    //   isComplete = false;
    // } else
    if (data.email === "") {
      openNotificationWithIcon(msg, "User" + desc);
      isComplete = false;
    } else if (data.subscriptionSettingId === "") {
      openNotificationWithIcon(msg, "Subscription Setting" + desc);
      isComplete = false;
    }

    return isComplete;
  };

  return (
    <Modal
      open={visible}
      title={"Create Subscription"}
      okText="Save"
      cancelText="Cancel"
      onCancel={() => {
        handleCancel();
      }}
      onOk={() => {
        handleSave();
      }}
    >
      {/* <EqSelect
        label="Role Type"
        isTag={false}
        classNames="extra-height"
        options={Object.entries(EqSubscriptionRoleType)
          .filter(([key, value]) => !isNaN(Number(key)))
          .map(([key, value]) => ({
            key: Number(key),
            value,
            label: value as string,
          }))}
        defaultSelectedValue={formData.roleType}
        changeFunc={handleSelectChange("roleType")}
      /> */}
      {/* {formData.roleType !== undefined ? (
        <>   </>
        ) : null} */}
      <EqSelect
        label="User"
        isTag={false}
        // showSearch
        // filterOption={true}
        classNames="extra-height"
        ddClassName="eq-select-dropdown"
        options={
          professionals &&
          professionals.map(
            /* Map your professional options */
            (user: any) => ({
              key: user.email,
              value: user.firstName
                ? user.firstName + user.lastName
                : user.email,
              label: user.firstName
                ? user.firstName + user.lastName
                : user.email,
              description: EqProfessionType[user.professionType],
            })
          )
        }
        defaultSelectedValue={formData.email}
        changeFunc={handleSelectChange("email")}
      />

      <EqSelect
        classNames="extra-height"
        label="Subscription Setting"
        options={
          subscriptionSettings &&
          subscriptionSettings.map(
            (setting: { id: string; title: string }) => ({
              key: setting.id,
              value: setting.title,
              label: setting.title,
            })
          )
        }
        defaultSelectedValue={formData.subscriptionSettingId}
        changeFunc={handleSelectChange("subscriptionSettingId")}
      />

      {/* Subscription Setting Select */}
    </Modal>
  );
};

export default EqSubscriptionForm;
