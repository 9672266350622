import React from 'react';
import './EqProfessionalsCard.scss';
import { Row, Col } from 'antd';
import EqCollapse, { IEqCollapse } from './../../custom/eqCollapse/EqCollapse';
import { EqDisciplineType, eqNotificationTypes } from '../../shared/enums';
import { IProfileDto } from '../../shared/interfaces/IProfileDto';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../../context/Context';
import EqCard from '../../custom/eqCard/EqCard';
import {
    ApiEndpoints,
    FrontEndPoints, ValidateIsUser, getDefaultImage, imageType,
    openNotificationWithIcon
} from '../../shared/Common';
import { useTranslation, Trans } from 'react-i18next';
import EqTags from '../../custom/eqTags/EqTags';
import EqSendMessageModal from '../eqMessageBoard/eqSendMessageModal/EqSendMessageModal';
import Api from '../../shared/Api';

export interface IEqProfessionals {
    userProfile: IProfileDto;
}

const EqProfessionalsCard = ({ userProfile, ...rest }: IEqProfessionals) => {
    const navigate = useNavigate();
    const userDetails: any = useAuthState();
    const { t, i18n } = useTranslation();
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [selectedEmail, setSelectedEmail] = React.useState<string>('');
  
    const showMessageModal = (email: string) => {
      
      setSelectedEmail(email);
      setIsModalVisible(true);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
  
  
  
  
    const handleSendMessage = async (data: any) => {
      try {
          const response = await Api.post(ApiEndpoints.UserMessage.SendMessageToEmail, data);
  
          if (response.status === 200) {
          
              openNotificationWithIcon(
                  t("sent"),
                  t("successfullySentMessage"),
                  eqNotificationTypes.success
              );
          } else {
              console.error("Unexpected response status:", response.status);
          }
      setIsModalVisible(false);
  
      } catch (error) {
          openNotificationWithIcon(
              t("error"),
              t("errorOccurredSending"),
              eqNotificationTypes.error
          );
      setIsModalVisible(false);
  
      }
  };
  
    const rowClicked = (event: any) => {

        if (userProfile && userProfile.key) {
            navigate(`${FrontEndPoints.trainer.profileBoard}?key=${userProfile.key}`);
        }
    }
    const visitProfile = (e: any) => {
        e.stopPropagation();
        if (userProfile && userProfile.key) {
            navigate(`${FrontEndPoints.trainer.profileBoard}?key=${userProfile.key}`);
        }
    }

    const messageClicked = (e: any) => {
        e.stopPropagation();
        if (ValidateIsUser(userDetails)) {
            var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${userProfile.email}`;
            navigate(out1);
        }
        else {
            var out1 = FrontEndPoints.login;
            navigate(out1);
        }
    }

    return (
        <>
            <div onClick={rowClicked} className="eq-prf-card">
                <EqCard imgSrc={userProfile?.userImage?.url || getDefaultImage(imageType.partner)}
                    title={
                        <>
                            <div className="eq-pg-name" >
                                {userProfile?.name}</div>
                        </>
                    }
                    date=""
                    description={
                        <>
                            <div className="eq-pg-row">
                                <Row>
                                    <span className="eq-pl-address-icon"><img alt="" src="/map-marker-alt.svg" /></span>
                                    <div className="eq-pl-address">
                                        {userProfile?.homeAddress?.locationName ? userProfile?.homeAddress?.locationName :
                                            ((userProfile?.homeAddress?.street) ? (userProfile?.homeAddress?.street + ' ') : '')
                                            + ((userProfile?.homeAddress?.zipCode) ? (userProfile?.homeAddress?.zipCode + ', ') : '')
                                            + ((userProfile?.homeAddress?.city) ? (userProfile?.homeAddress?.city) : '')
                                        }
                                    </div>
                                </Row>
                                <Row>
                                    <Col span={24} className="eq-pl-discipline">
                                        {t("discipline")}
                                    </Col>
                                    <Col flex="auto">
                                        <Row>
                                            {userProfile && userProfile.disciplineList && userProfile?.disciplineList?.length > 0 &&
                                                <>
                                                    {
                                                        userProfile?.disciplineList?.map((item: any, index: number) =>
                                                            <Col className="">
                                                                {index == 0 ? <span className="">
                                                                    <EqTags
                                                                        text={t(EqDisciplineType[item.discipline?.type])}

                                                                    />
                                                                </span> : <EqTags
                                                                    text={t(EqDisciplineType[item.discipline?.type])}

                                                                />}
                                                            </Col>
                                                        )
                                                    }
                                                </>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    }
                    actionsList={
                        [
                            <div className="eq-pl-msg" 
                            onClick={(e:any) => { 
                                e.stopPropagation()
                                showMessageModal(userProfile.email);
                              }}
                            // onClick={messageClicked}
                            >
                                <span className="eq-pl-icon"><img alt="" src="/Envelope.svg" /></span>
                            </div>,
                            <div className="eq-pg-profile-link" onClick={visitProfile} >{t("Visitprofile")}</div>
                        ]
                    }

                />
            </div>
            <EqSendMessageModal
        visible={isModalVisible}
        recipientEmail={selectedEmail}
        onCreate={handleSendMessage}
        onCancel={handleCancel}
      />
        </>
    );
}
export default EqProfessionalsCard;