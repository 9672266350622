import React, { useEffect, useState } from "react";
import "./EqGroupMessages.scss";
import { Row, Col, Checkbox, Radio, Popconfirm } from "antd";
import { useTranslation, Trans } from "react-i18next";
import Api from "../../../shared/Api";
import {
  ApiEndpoints,
  EqMessageType,
  GetEventMessage,
} from "../../../shared/Common";
import { FrontEndPoints } from "../../../shared/Common";
import history from "../../../shared/history";
import EqMessage, { IEqMessage, IEqMessageData } from "../../eqMessage/EqMessage";
import { Link } from "react-router-dom";



export interface IEqGroupMessages {
  groupKey?: any;

}
const EqGroupMessages = ({ groupKey }: IEqGroupMessages) => {
  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);

  
  // const [show, setShow] = useState(display);
  const [eventsList, setEventsList] = React.useState([] as Array<any>);
  const [messageString, setMessageString] = useState("");
  const [messages, setMessages] = useState([] as Array<any>);
  const [keys, setKeys] = useState(new Array<any>());
  var selectedMessages = new Array<any>();
  const [misCheckAll, msetIsCheckAll] = useState(false);



  // Messages
  const GetMessages = () => {
    setMessageString("");
    
    var messagesFromDB = [] as any;
    // Api.get(ApiEndpoints.getMessages).then(response => {
    Api.post(ApiEndpoints.GetMessagesByGroupKey, {
      key: groupKey,
    }).then(
      (response) => {
        if (response && response.status == 200) {
          response.data?.result?.map((d: any) => {
            messagesFromDB.push({
              message: {
                key: d.message.key,
                messageOn: new Date(d.message.sentOn),
                type: EqMessageType[d.message.messageType],
                from: d.message?.sender?.userName,
                to: "trainer",
                subject: d.message.subject,
                text: d.message.message,
                isRead: d.isRead,
                isSent: d.isSent,
                isFlaged: d.isFlaged,
              },
              checkBoxBordered: false,
              clickedReplyFun: replyEmail,
              clickedFlagFun: mardFlagedEmail,
              clickedDeleteFunc: deleteEmail,
              onCheckChangeFunc: onCheckChange,
              selected: false,
            });
          });
          messagesFromDB = messagesFromDB?.sort((obj1: any, obj2: any) => {
            if (obj1.message.isRead > obj2.message.isRead) {
              return 1;
            } else if (obj1.message.isRead < obj2.message.isRead) {
              return -1;
            } else {
              return 0;
            }
          });

          setMessages(messagesFromDB);
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };
  const replyEmail = (message: IEqMessageData) => {
    GetMessages();
  };
  const mardFlagedEmail = (message: IEqMessageData) => {
    if (message != null) {
      Api.post(ApiEndpoints.saveMessage, {
        Message: { Key: message.key },
        IsFlaged: message.isFlaged,
        IsRead: message.isRead,
      }).then(
        (response) => {
          if (response && response.status == 200) {
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const deleteEmail = (message: IEqMessageData) => {
    if (message != null) {
      Api.post(ApiEndpoints.deleteMessage, {
        Message: { Key: message.key },
      }).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const onCheckChange = (message: IEqMessageData, checked: boolean) => {
    if (checked) {
      selectedMessages.push({ Key: message.key });
    } else {
      if (selectedMessages != null && selectedMessages.length > 0) {
        const index = selectedMessages.findIndex((x) => x.Key == message.key);
        if (index > -1) {
          selectedMessages.splice(index, 1);
        }
      }
    }

    setKeys(selectedMessages);
  };
  const deleteSelectedMessages = () => {
    msetIsCheckAll(false);

    if (keys?.length > 0) {
      Api.post(ApiEndpoints.deleteSelectedMessages, keys).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();

            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const readSelectedMessages = () => {
    msetIsCheckAll(false);
    selectedMessages = [];

    keys.map((opt: any) => {
      selectedMessages.push({ Message: { Key: opt.Key }, IsRead: true });
    });
    if (keys?.length > 0) {
      Api.post(ApiEndpoints.saveSelectedMessages, selectedMessages).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();
            selectedMessages = [];
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };

  const messagesCheckAll = () => {
    msetIsCheckAll(!misCheckAll);

    messages.map((m: IEqMessage) => {
      if (m.selected == false) {
        m.selected = !misCheckAll;
        selectedMessages.push({ Key: m.message.key });
      } else {
        m.selected = !misCheckAll;
        if (selectedMessages != null && selectedMessages.length > 0) {
          const index = selectedMessages.findIndex(
            (x) => x.Key == m.message.key
          );
          if (index > -1) {
            selectedMessages.splice(index, 1);
          }
        }
      }
      setKeys(selectedMessages);
    });
    const modifiedList = [...messages];
    setMessages(modifiedList);
  };
  // ------
  useEffect(() => { GetMessages()}, [groupKey]);


  return (
    <>
      <div className="eq-group-messages">
        {/* <div className="eq-tab-container eq-messages-container"> */}
                  <Row className="eq-msg-header" align="bottom">
                    <Col flex="auto">
                      <div className="eq-db-h2">Messages</div>
                    </Col>
                    <Col md={2}>
                      <Row>
                        <Col
                          className="eq-msg-icons"
                          onClick={readSelectedMessages}
                        >
                          <span>
                            <img alt="" src="/black-check.svg" />
                          </span>
                        </Col>
                        <Col className="eq-msg-icons">
                          <Link to={FrontEndPoints.trainer.composeMessage}>
                            <span>
                              <img alt="" src="/black-Envelope.svg" />
                            </span>
                          </Link>
                        </Col>
                        <Col
                          className="eq-msg-icons"
                          onClick={deleteSelectedMessages}
                        >
                          <span>
                            <img alt="" src="/trash-alt.svg" />
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {messages.length > 0 && (
                    <>
                      {
                        <Row className="eq-list-header hidden-sm">
                          <Col md={22} flex="auto">
                            <Row>
                              <Col span={1} className="">
                                <Checkbox
                                  onChange={messagesCheckAll}
                                  checked={misCheckAll}
                                ></Checkbox>
                              </Col>
                              <Col span={3} className="">
                              {t("type")}
                              </Col>
                              <Col span={5} className="">
                              {t("from")}
                              </Col>
                              <Col flex="auto" className="">
                              {t("messageTitle")}
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={4} sm={4} md={2} className="">
                          {t("action")}
                          </Col>
                        </Row>
                      }

                      {messages.map((item: IEqMessage, index: number) => (
                        <EqMessage key={index} {...item}></EqMessage>
                      ))}
                    </>
                  )}
                
              {/* </div> */}
      
      </div>
    </>
  );
};
export default EqGroupMessages;
