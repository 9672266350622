import React from 'react';
import { Document, PDFViewer, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { EqCurrency } from '../../../shared/enums';

const PdfProductView = ({ productOrder }: any) => {
    const styles = StyleSheet.create({
        container: {
            padding: 15,
        },
        header: {
            fontSize: 34,
            fontWeight: "bold",
            padding: 5,
            paddingBottom: 15,
            marginTop: 20,
            marginBottom: 0,
            borderBottom: 1,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
        },
        headerCost: {
            color: '#FF8838'
        },
        details: {
            fontSize: 30,
            fontWeight: "bold",
            padding: 5,
            paddingBottom: 15,
            marginTop: 20,
            marginBottom: 0,
        },
        table: {
            fontSize: 8,
            marginBottom: 0,
            fontWeight: "bold",
            backgroundColor: "#EEC29933"
        },
        tableRowHead: {
            fontSize: 10,
            fontWeight: "bold",
            flexDirection: "row",
        },
        tableCell: {
            padding: 4,
            paddingTop: 10,
            paddingBottom: 10,
            borderBottom: '1 solid #979797'
        },
        tableRowHeadLast: {
            fontSize: 10,
            fontWeight: "bold",
            flexDirection: "row",
            borderTop:"1px"
        },
        tableCellLast: {
            padding: 4,
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: "#F49B4866"
        },
    });

    let orders = Array.isArray(productOrder) ? productOrder : [productOrder];

    if (!Array.isArray(productOrder)) {
        // Handle single receipt 
        orders = [productOrder];
    }

    const totalCost = orders.reduce(
        (acc: number, order: any) => acc + (order.product ? order.product.price : 0),
        0
    );

    return (
        <PDFViewer className="pdf-viewer" width={1000} height={1000}>
            <Document>
                <Page size="A4" style={styles.container}>
                    <Text style={styles.header}>Total Estimated Cost:&nbsp;
                        <Text style={styles.headerCost}>
                            {totalCost}
                        </Text>
                    </Text>
                    <Text style={styles.details}>Product Purchasing Details</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRowHead}>
                            <Text style={[styles.tableCell, { width: "40%" }]}>
                                Product Name
                            </Text>
                            <Text style={[styles.tableCell, { width: "30%" }]}>
                                From
                            </Text>
                            <Text style={[styles.tableCell, { width: "30%" }]}>
                                Price
                            </Text>
                        </View>
                        {orders.map((order: any, index: number) => (
                            <View style={styles.tableRowHead} key={index}>
                                <Text style={[styles.tableCell, { width: "40%" }]}>
                                    {order.product ? order.product.title : 'N/A'}
                                </Text>
                                <Text style={[styles.tableCell, { width: "30%" }]}>
                                    {`${order.product.user.firstName} ${order.product.user.lastName}`}
                                </Text>
                                <Text style={[styles.tableCell, { width: "30%" }]}>
                                    {order.product ? `${order.product.price}` : 'N/A'}
                                </Text>
                            </View>
                        ))}
                        <View style={styles.tableRowHeadLast}>
                            <Text style={[styles.tableCellLast, { width: "70%" }]}>
                                Total Cost
                            </Text>
                            <Text style={[styles.tableCellLast, { width: "30%" }]}>
                                {totalCost}
                            </Text>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
}

export default PdfProductView;
