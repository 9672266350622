import React, { useState, useEffect } from "react";
import { Row, Col, Input } from "antd";
import { useTranslation } from "react-i18next";
import Api from "../../shared/Api";
import { ApiEndpoints } from "../../shared/Common";
import { IProfileDto } from "../../shared/interfaces/IProfileDto";
import EqApprovalRequest from "../../custom/EqApprovalRequest/EqApprovalRequest";
import "./EqApproveProfessionals.scss";

const EqApproveProfessionals = () => {
  const { t } = useTranslation();
  const { Search } = Input;
  const [messageString, setMessageString] = useState("");
  const [trainersList, setTrainersList] = useState([]);

  const acceptClicked = async (data:any) => {
    try {
      const response = await Api.post(ApiEndpoints.approveTrainer, { Email: data.email });
      if (response && response.status === 200) {
        // Handle success if needed
        setTrainersList((prevList) => prevList.filter((trainer:any) => trainer.email !== data.email));
      } else {
        setMessageString("");
      }
    } catch (err) {
      setMessageString("");
    }
  };

  const rejectClicked = (data: IProfileDto) => {
    // Implement rejection logic if needed
  };

  useEffect(() => {
    getAllUnapprovedProfessionals();
  }, []);

  const getAllUnapprovedProfessionals = async () => {
    try {
      const response = await Api.get(ApiEndpoints.UnApprovedProfessional.GetAll);
      if (response && response.status === 200) {
        setTrainersList(response.data);
      } else {
        setMessageString("");
      }
    } catch (err) {
      setMessageString("");
    }
  };

  return (
    <div className="eq-pf-board">
      <Row className="eq-pf-heading-row">
        <Col className="eq-pf-heading-col">
          <h3 className="eq-pf-heading">{t("UnApproved Professionals")}</h3>
        </Col>
      </Row>
      <Row className="eq-pf-heading-line"></Row>
      <Row>
        <Col xs={24} sm={24} md={24} className="eq-pb-left col-xs-push-16">
          {trainersList.length > 0 &&
            trainersList.map((item: any, index: number) => (
              <EqApprovalRequest
                key={index}
                user={item}
                acceptClicked={acceptClicked}
                rejectClicked={rejectClicked}
              />
            ))}
        </Col>
      </Row>
    </div>
  );
};

export default EqApproveProfessionals;
