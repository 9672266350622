import React, { ChangeEvent, useState } from "react";
import { Button, Input, Select, Row, Col, Divider } from "antd";
import { EqMessageType } from "../../../shared/enums";
import { EqInput } from "../../../custom/eqInput/EqInput";
import { ISelectProps } from "../../../shared/Interfaces";
import EqSelect from "../../../custom/eqSelect/EqSelect";
import "./EqSendMessageForm.scss";
import EqButton from "../../../custom/eqButton/EqButton";
import { openNotificationWithIcon } from "../../../shared/Common";
import { useTranslation } from "react-i18next";

const { Option } = Select;

interface EqSendMessageFormFormProps {
  studentsAndGroups: any;
  onCreate: (values: any) => void;
  onCancel: () => void;
}

const EqSendMessageForm: React.FC<EqSendMessageFormFormProps> = ({
  studentsAndGroups,
  onCreate,
  onCancel,
}) => {
  const initialMessage = {
    subject: "",
    message: "",
    messageType: EqMessageType.Group,
    selectedOptions: [],
  };
  const initialRecipientSelection = {
    selectedKeys: [],
    selectedOptions: [],
  };
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState<any>({
    ...initialMessage,
  });

  const [selectedStudent, setSelectedStudent] = React.useState({
    ...initialRecipientSelection,
  }) as any;

  const handleInputChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleMessageTypeChange = (value: EqMessageType) => {
    setFormData((prevData: any) => ({ ...prevData, messageType: value }));
  };

  const handleSubmit = () => {
    // Handle the form submission here
    let validate = validateData(formData);
    if (validate) {
      let data = { ...formData };
      data.selectedOptions = selectedStudent.selectedOptions;
      onCreate(data);
    }
    setFormData({
      ...initialMessage,
    });
    setSelectedStudent({
      ...initialRecipientSelection
    })
  };
  const handleCancel = () => {
    setFormData({
      ...initialMessage,
    });

    onCancel();
  };

  const validateData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    if (data.subject === "") {
      openNotificationWithIcon(msg, "subject" + desc);
      isComplete = false;
    } else if (data.message === "") {
      openNotificationWithIcon(msg, "message" + desc);
      isComplete = false;
    } else if (selectedStudent.keys.length === 0) {
      openNotificationWithIcon(msg, "To" + desc);
      isComplete = false;
    }

    return isComplete;
  };
  const studentSelectProps: ISelectProps = {
    
    mode: "multiple",
    placeholder:t("to"),
    showArrow: true,
    allowClear: false,
    showSearch: true,
    filterOption: true,
    optionFilterProp: "label",
    classNames: `eq-multiSelect eq-searchSelect extra-height eq-bottom-border`,
    // ddClassName: "eq-select-dropdown",
    isTag: false,
    changeFunc: (selectedKeys: any) => {
      var selectedObjects = getSelectedValues(selectedKeys);
      setSelectedStudent({
        keys: selectedKeys,
        selectedOptions: selectedObjects,
      });
    },
    options: studentsAndGroups,
    defaultSelectedValue: selectedStudent.keys,
  };

  const getSelectedValues = (selectedKeys: any) => {
    return selectedKeys.map((key: any) => {
      const selectedOption = studentsAndGroups.find(
        (option: any) => option.key === key
      );
      return selectedOption ? selectedOption : null;
    });
  };

  return (
    <div className="eq-sendMessage">
      {/* <Row gutter={[16, 16]}>
<Col span={24}></Col>
<Col span={24}></Col>
<Col span={24}></Col>
<Col span={24}></Col>

        </Row> */}
      <Row gutter={16} justify="space-between" align="middle">
        <Col>
          <h2 className="msg-title">{t("newMessage")}</h2>
        </Col>
        <Col>
          <Select
            value={formData.messageType}
            onChange={handleMessageTypeChange}
          >
            <Option value={EqMessageType.Group}>{t("groupMessage")}</Option>
            <Option value={EqMessageType.Message}>{t("privateMessage")}</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <EqSelect
          
          {...studentSelectProps}
        ></EqSelect>
      </Row>
      <Row>
        <EqInput
        // prefix="Subject:"
          classNames="eq-bottom-border"
          name="subject"
          placeHolder= {t("subject")}
          value={formData.subject}
          onChange={handleInputChange}
          //   classNames="eq-card-input"
        />
      </Row>

      <Input.TextArea
        className="eq-text-area-border"
        name="message"
        placeholder={t("writeMessageHere")}
        value={formData.message}
        onChange={handleInputChange}
        rows={4}
      />

      <Row justify="space-between" align="middle" className="action-row">
        <Col>
          <EqButton type="primary" onClick={handleSubmit}>
            {t("sendMessage")}
          </EqButton>
        </Col>
        <Col>
          <span onClick={handleCancel}>
            <img alt="" src="/trash-alt.svg" />
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default EqSendMessageForm;
