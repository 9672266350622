import React from "react";
import "./EqProfessionals.scss";
import { Row, Col } from "antd";
import EqCollapse, { IEqCollapse } from "./../../custom/eqCollapse/EqCollapse";
import { EqDisciplineType, eqNotificationTypes } from "../../shared/enums";
import { IProfileDto } from "../../shared/interfaces/IProfileDto";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import { useTranslation, Trans } from 'react-i18next';

import {
  ApiEndpoints,
  FrontEndPoints,
  ValidateIsUser,
  getDefaultImage,
  imageType,
  openNotificationWithIcon,
} from "../../shared/Common";
import EqTags from "../../custom/eqTags/EqTags";
import AsyncImage from "../../custom/asyncImage/AsyncImage";
import Api from "../../shared/Api";
import EqSendMessageModal from "../eqMessageBoard/eqSendMessageModal/EqSendMessageModal";

export interface IEqProfessionals {
  userProfile: IProfileDto;
}

const EqProfessionals = ({ userProfile, ...rest }: IEqProfessionals) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const userDetails: any = useAuthState();


  
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [selectedEmail, setSelectedEmail] = React.useState<string>('');

  const showMessageModal = (email: string) => {
    
    setSelectedEmail(email);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };




  const handleSendMessage = async (data: any) => {
    try {
        const response = await Api.post(ApiEndpoints.UserMessage.SendMessageToEmail, data);

        if (response.status === 200) {
        
            openNotificationWithIcon(
                t("sent"),
                t("successfullySentMessage"),
                eqNotificationTypes.success
            );
        } else {
            console.error("Unexpected response status:", response.status);
        }
    setIsModalVisible(false);

    } catch (error) {
        openNotificationWithIcon(
            t("error"),
            t("errorOccurredSending"),
            eqNotificationTypes.error
        );
    setIsModalVisible(false);

    }
};
  //console.log(userProfile);
  const rowClicked = (event: any) => {
    if (userProfile && userProfile.key) {
      //history.push({ FrontEndPoints.trainer.profileBoard})
      navigate(`${FrontEndPoints.trainer.profileBoard}?key=${userProfile.key}`);
    }
  };
  const visitProfile = (e: any) => {
    e.stopPropagation();

    if (userProfile && userProfile.key) {
      if (location.pathname === FrontEndPoints.RidingSchools) {
        navigate(
          `${FrontEndPoints.school.profileBoard}?key=${userProfile.key}`
        );
      } else {
        navigate(
          `${FrontEndPoints.trainer.profileBoard}?key=${userProfile.key}`
        );
      }
    }
  };

  const messageClicked = (e: any) => {
    e.stopPropagation();
    if (ValidateIsUser(userDetails)) {
      var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${userProfile.email}`;
      navigate(out1);
    } else {
      var out1 = FrontEndPoints.login;
      navigate(out1);
    }
  };
  const collapsableProps: IEqCollapse = {
    display: userProfile?.display,
    header: [
      <Row align="middle">
        <Col span={3}>
          <div className="eq-pl-img">
            <img
              src={
                userProfile?.userImage?.url ||
                getDefaultImage(imageType.partner)
              }
              className="user"
              alt="user"
            />
          </div>
        </Col>
        <Col flex="auto">
          <div className="eq-at-name">{userProfile?.name}</div>
        </Col>
        <Col span={3}>
          <div className="eq-pl-msg" onClick={messageClicked}>
            <span className="eq-pl-icon">
              <img alt="" src="/Envelope.svg" />
            </span>
          </div>
        </Col>
      </Row>,
    ],
  };
  React.useEffect(() => {
    console.log("--- Professional ---");
    console.log(location);
  }, [userProfile]);
  return (
    <>
      <Row className="eq-p-row">
        <Col flex="auto">
          <Row className="hidden-sm eq-pl-container">
            <Col>
              <div className="eq-pl-img">
                <img
                  src={
                    userProfile?.userImage?.url ||
                    getDefaultImage(imageType.partner)
                  }
                  className="user"
                  alt="user"
                />
              </div>
            </Col>
            <Col className="eq-pl-info" flex="auto">
              <Row onClick={rowClicked}>
                <Col flex="auto">
                  <div className="eq-at-name">{userProfile?.name}</div>
                </Col>
                <Col flex="auto">
                  <Row justify="end" align="top">
                    <div className="eq-pl-msg" 
                    // onClick={messageClicked}
                    onClick={(e:any) => { 
                      e.stopPropagation()
                      showMessageModal(userProfile.email);
                    }}
                    >
                      <span className="eq-pl-icon">
                        <img alt="" src="/Envelope.svg" />
                      </span>
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <span className="eq-pl-address-icon">
                  <img alt="" src="/map-marker-alt.svg" />
                </span>
                <div className="eq-pl-address">
                  {userProfile?.homeAddress?.locationName
                    ? userProfile?.homeAddress?.locationName
                    : (userProfile?.homeAddress?.street
                      ? userProfile?.homeAddress?.street + " "
                      : "") +
                    (userProfile?.homeAddress?.zipCode
                      ? userProfile?.homeAddress?.zipCode + ", "
                      : "") +
                    (userProfile?.homeAddress?.city
                      ? userProfile?.homeAddress?.city
                      : "")}
                </div>
              </Row>
              <Row className="eq-p-discipline-plus-profile">
                <Col flex="auto">
                  <Row>
                    {location.pathname != FrontEndPoints.RidingSchools && (
                      <>
                        <Col className="eq-pl-discipline">{t("discipline")}</Col>
                        {userProfile &&
                          userProfile.disciplineList &&
                          userProfile?.disciplineList?.length > 0 && (
                            <>
                              {userProfile?.disciplineList?.map(
                                (item: any, index: number) => (
                                  <Col className="eq-pl-discipline-list" key={item.key}>
                                    <EqTags
                                      text={t(EqDisciplineType[item.discipline?.type])}
                                      
                                    />
                                  </Col>
                                  // <Col className="eq-pl-discipline-list">
                                  //   {index == 0 ? (
                                  //     <span className="eq-pl-discipline-list-active">
                                  //       {
                                  //         EqDisciplineType[
                                  //           item.discipline?.type
                                  //         ]
                                  //       }
                                  //     </span>
                                  //   ) : (
                                  //     EqDisciplineType[item.discipline?.type]
                                  //   )}
                                  // </Col>
                                )
                              )}
                            </>
                          )}
                      </>
                    )}
                  </Row>
                </Col>
                <Col  className="eq-p-profile-visit">
                  <Row justify="end" align="bottom" className="eq-p-profile-btn">
                    <div className="eq-p-profile-link" onClick={visitProfile}>
                     {t("Visitprofile")}
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="hidden-md">
            <EqCollapse {...collapsableProps}>
              <Row>
                <Col className="eq-pl-info" flex="auto">
                  <Row className="eq-pl-address">
                    <span className="eq-pl-address-icon">
                      <img alt="" src="/map-marker-alt.svg" />
                    </span>
                    <div>
                      {userProfile?.homeAddress?.locationName
                        ? userProfile?.homeAddress?.locationName
                        : (userProfile?.homeAddress?.street
                          ? userProfile?.homeAddress?.street + " "
                          : "") +
                        (userProfile?.homeAddress?.zipCode
                          ? userProfile?.homeAddress?.zipCode + ", "
                          : "") +
                        (userProfile?.homeAddress?.city
                          ? userProfile?.homeAddress?.city
                          : "")}
                    </div>
                  </Row>

                  {location.pathname != FrontEndPoints.RidingSchools && (
                    <>
                      <Row>
                        <Col className="eq-pl-discipline">{t("discipline")}</Col>
                      </Row>
                      <Row>
                        {userProfile &&
                          userProfile.disciplineList &&
                          userProfile?.disciplineList?.length > 0 && (
                            <>
                              {userProfile?.disciplineList?.map(
                                (item: any, index: number) => (
                                  <Col
                                    key={index}
                                    className="eq-pl-discipline-list"
                                  >
                                    {index == 0 ? (
                                      <span className="eq-pl-discipline-list-active">
                                         <EqTags
                                      text={t(EqDisciplineType[item.discipline?.type])}

                                    />
                                      </span>
                                    ) : (
                                      <EqTags
                                      text={t(EqDisciplineType[item.discipline?.type])}
                                    />
                                    )}
                                  </Col>
                                )
                              )}
                            </>
                          )}
                      </Row>
                    </>
                  )}

                  <Row>
                    <Col>
                      <Row justify="end" align="bottom">
                        <div
                          className="eq-p-profile-link"
                          onClick={visitProfile}
                        >
                          {t("Visitprofile")}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </EqCollapse>
          </div>
        </Col>
      </Row>

      <EqSendMessageModal
        visible={isModalVisible}
        recipientEmail={selectedEmail}
        onCreate={handleSendMessage}
        onCancel={handleCancel}
      />
    </>
  );
};
export default EqProfessionals;
