import React, { useState, useEffect } from "react";
import {  Drawer, message, Tabs, Row, Col, Avatar, Space } from "antd";
import Api from "../../shared/AApi";
import { ApiEndpoints, getDefaultImage, imageType, openNotificationWithIcon } from "../../shared/Common";
import EqRequestCard from "./EqRequestCard/EqRequestCard";
import TabPane from "antd/lib/tabs/TabPane";

import "./EqTrainingRequests.scss";
import EqButton from "../eqButton/EqButton";
import { EqEventAttendeeStatus, eqNotificationTypes } from "../../shared/enums";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface ITrainingLessonRequests {
    trainingLesson: any;
    organizers: any[];
    attendees: any[];
}
 interface IEqTrainingRequests {
    onLoadRequests?: any;
  }

// const EqTrainingRequests: React.FC = () => {
const EqTrainingRequests = ({
onLoadRequests,
}: IEqTrainingRequests) => {
    const { t } = useTranslation();

    const [trainingLessons, setTrainingLessons] = useState<ITrainingLessonRequests[]>([]);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedTrainingLesson, setSelectedTrainingLesson] =
        useState<ITrainingLessonRequests | null>(null);
        const [activeDrawerTab, setActiveDrawerTab] = React.useState("1");

    useEffect(() => {
        fetchTrainingLessonsWithAttendees();
    }, []);

    const fetchTrainingLessonsWithAttendees = async () => {
        try {
            const response = await Api.get(
                ApiEndpoints.UserTrainingRequest.GetAll
            );
            setTrainingLessons(response.data);
            onLoadRequests(response.data.length)
            // filter trainings if no waiting or request attendee
            // const filteredList = response.data.filter((lesson: any) => {
            //     // Check if there are any attendees with the status 'Waiting' or 'Requested'
            //     return lesson.attendees.some((attendee: any) => 
            //         attendee.userStatus === EqEventAttendeeStatus.Waiting || 
            //         attendee.userStatus === EqEventAttendeeStatus.Requested
            //     );
            // });
            // setTrainingLessons(filteredList);

        } catch (error) {
            message.error("Failed to fetch trainingLessons");
        }
    };

    const openDrawer = (event: ITrainingLessonRequests) => {
        setSelectedTrainingLesson(event);
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
        setSelectedTrainingLesson(null);
    };



    const updateLessonAttendees = (requestId: string) => {
      if (!selectedTrainingLesson) return;

      const updatedLesson = {
        ...selectedTrainingLesson,
        attendees: selectedTrainingLesson.attendees.filter(attendee => attendee.id !== requestId),
      };
  
      setTrainingLessons(prevLessons =>
        prevLessons.map(lesson =>
          lesson.trainingLesson.id === updatedLesson.trainingLesson.id ? updatedLesson : lesson
        )
      );
      setSelectedTrainingLesson(updatedLesson);
    };

    const handleAcceptRequest = async (requestId: string) => {
      try {
        const response = await Api.put(
            `${ApiEndpoints.UserTrainingRequest.Accept}/${requestId}`
        );
        if (response.status === 200) {
        
        // update setTrainingLessons and remove accepted Request 
          updateLessonAttendees(requestId);
            openNotificationWithIcon(
                "Accepted!",
                "Successfully Accepted Request",
                eqNotificationTypes.success
            );
        } else {
            console.error("Unexpected response status:", response.status);
        }
    } catch (error: any) {
      console.error("Error Accepting invite:", error);
      openNotificationWithIcon("Error!", "Unable to Accept", eqNotificationTypes.error);
    }
    };

    const handleRejectRequest = async (requestId: string) => {
      try {
        const response = await Api.put(
            `${ApiEndpoints.UserTrainingRequest.Reject}/${requestId}`
        );

        if (response.status === 200) {
        // update setTrainingLessons and remove Rejected Request 
        updateLessonAttendees(requestId);

            openNotificationWithIcon(
                "Rejected!",
                "Successfully Rejected Request",
                eqNotificationTypes.success
            );
        } else {
            console.error("Unexpected response status:", response.status);
        }
    } catch (error) {
        console.error("Error Reject invite:", error);
        openNotificationWithIcon("Error!", "Unable to Reject", eqNotificationTypes.error);
    }
    };


    const updateLessonMultipleAttendees = (requestIds: string[]) => {
      if (!selectedTrainingLesson) return;

      const updatedLesson = {
        ...selectedTrainingLesson,
        attendees: selectedTrainingLesson.attendees.filter(attendee =>  !requestIds.includes(attendee.id)),
      };
  
      setTrainingLessons(prevLessons =>
        prevLessons.map(lesson =>
          lesson.trainingLesson.id === updatedLesson.trainingLesson.id ? updatedLesson : lesson
        )
      );
      setSelectedTrainingLesson(updatedLesson);
    };

    const handleAcceptAllRequests = async () => {
        if (!selectedTrainingLesson) return;
;
        let  requestIds= selectedTrainingLesson.attendees.filter(att => att.userStatus === EqEventAttendeeStatus.Requested).map((request:any)=>request.id)
        try {
          const response = await Api.put(
              ApiEndpoints.UserTrainingRequest.AcceptMultipleRequests,
              requestIds
          );

          if (response.status === 200) {
              // Remove deleted requestIds from the local state
           
              updateLessonMultipleAttendees(requestIds);
              openNotificationWithIcon(
                  "Accepted!",
                  "SucessFully Accepted Requests",
                  eqNotificationTypes.success
              );
          } else {
              console.error("Unexpected response status:", response.status);
          }
      } catch (error: any) {
          if (error.response.status === 409) {
              openNotificationWithIcon(
                  "Error!",
                  `Unable to Accept Request for ${error.response.data.product.title}`,
                  eqNotificationTypes.error
              );
          } else {
              console.error("Error Accepting invite:", error);
              openNotificationWithIcon("Error!", "Unable to Accept", eqNotificationTypes.error);
          }
      }
    };


    const handleAcceptWaitingRequest = async (requestId: string) => {
      try {
        const response = await Api.put(
            `${ApiEndpoints.UserTrainingRequest.AcceptWaiting}/${requestId}`
        );
        if (response.status === 200) {
        
        // update setTrainingLessons and remove accepted Request 
          updateLessonAttendees(requestId);

            openNotificationWithIcon(
                "Accepted!",
                "Successfully Accepted Request",
                eqNotificationTypes.success
            );
        } else {
            console.error("Unexpected response status:", response.status);
        }
    } catch (error: any) {

      if (error.response.status === 409) {
        openNotificationWithIcon(
            "Conflict!",
            `Unable to Accept Request`,
            eqNotificationTypes.error
        );
    } else {
        console.error("Error Accepting invite:", error);
        openNotificationWithIcon("Error!", "Unable to Accept", eqNotificationTypes.error);
    }

     
    }
    };

    return (
        <>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
                {trainingLessons.map((data) => {
                    
                    const organizer = data.organizers.find((org) => org.eventUserType === 1);
                    const organizerEmail = organizer ? organizer.user.email : "";
                    const organizerName = organizer
                        ? `${organizer.user.firstName} ${organizer.user.lastName}`
                        : "";

                    return (
                        <EqRequestCard
                            key={data.trainingLesson.id}
                            heading={data.trainingLesson.title}
                            text1={`${t("from")} ${organizerEmail}`}
                            text2={`${t("date")}: ${moment(data?.trainingLesson.startTime).format("DD/MM/YYYY hh:mm A")}`}
                            text3={`${t("requests")}: ${data?.attendees?.filter((att:any) => att.userStatus === EqEventAttendeeStatus.Requested).length}`}
                            text4={`${t("waiting")}: ${data?.attendees?.filter((att:any) => att.userStatus === EqEventAttendeeStatus.Waiting).length}`}
                            // actions={[<Button onClick={() => openDrawer(data)}>Requests</Button>]}
                            onButtonClick={() => openDrawer(data)}
                        />
                         
                        // </EqRequestCard>
                    );
                })}
            </div>

            <Drawer
                className="training-request-drawer"
                title={
                    <Row className="drawer-header" justify="space-between">
                        <Col>{t("requests")}</Col>
                      {activeDrawerTab==="1" && <Col>
                            <EqButton type="primary" onClick={handleAcceptAllRequests}>
                                {t("acceptAll")}
                            </EqButton>
                        </Col>}  
                    </Row>
                }
                open={drawerVisible}
                onClose={closeDrawer}
                width={"50%"}
            >
                <div className="drawer-content">
                    <h3  style={{ margin: 0 }}>{selectedTrainingLesson?.trainingLesson.title}</h3>
                    <p>
                        {
                            selectedTrainingLesson?.organizers.find(
                                (org) => org.eventUserType === 1
                            )?.user.email
                        }
                    </p>
                    <p>{selectedTrainingLesson?.trainingLesson.description}</p>
                    <Tabs 
                      activeKey={activeDrawerTab}
                      onChange={setActiveDrawerTab}  
                    >
                        <TabPane tab={t("requests")} key="1">
                            <div>
                                {selectedTrainingLesson?.attendees
                                    .filter(att => att.userStatus === EqEventAttendeeStatus.Requested)
                                    .sort((a, b) => a.attendeeIndex - b.attendeeIndex) // Sort by attendeeIndex
                                    .map((item,idx) => (
                                        <Row
                                            key={item.id}
                                            className="request-row"
                                            gutter={16}
                                            align="middle"
                                        >
                                            
                                            <Col >{idx+1}</Col>

                                            <Col xs={20} md={16}>
                                                <Row>
                                                    <Col>
                                                        {" "}
                                                        <Avatar
                                                            size={50}
                                                            src={
                                                                item.user.userImage
                                                                    ? item.user.userImage?.url
                                                                    : getDefaultImage(
                                                                          imageType.trainer
                                                                      )
                                                            }
                                                        />
                                                    </Col>
                                                    <Col offset={1}>
                                                        <div>
                                                            <h3 style={{ margin: 0 }}>
                                                                {item.user.firstName +
                                                                    " " +
                                                                    item.user.lastName}
                                                            </h3>
                                                            <span>{t("email")}: {item.user.email}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                  <Space>

                                                    <EqButton
                                                        type="primary"
                                                        onClick={() => handleAcceptRequest(item.id)}
                                                        >
                                                        {t("accept")}
                                                    </EqButton>
                                                    <EqButton
                                                        type="button"
                                                        classNames="btn-background-white"
                                                        onClick={() => handleRejectRequest(item.id)}
                                                        >
                                                        {t("Reject")}
                                                    </EqButton>
                                                      </Space>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))}
                            </div>
                        </TabPane>
                        <TabPane tab={t("waitingList")} key="2">
                        <div>
                                {selectedTrainingLesson?.attendees
                                    .filter(att => att.userStatus === EqEventAttendeeStatus.Waiting)
                                    .sort((a, b) => a.attendeeIndex - b.attendeeIndex) // Sort by attendeeIndex
                                    .map((item,idx) => (
                                        <Row
                                            key={item.id}
                                            className="request-row"
                                            gutter={16}
                                            align="middle"
                                        >
                                            
                                            <Col>{idx+1}</Col>
                                            <Col  xs={20} md={16}>
                                                <Row>
                                                    <Col>
                                                        {" "}
                                                        <Avatar
                                                            size={50}
                                                            src={
                                                                item.user.userImage
                                                                    ? item.user.userImage?.url
                                                                    : getDefaultImage(
                                                                          imageType.trainer
                                                                      )
                                                            }
                                                        />
                                                    </Col>
                                                    <Col offset={1}>
                                                        <div>
                                                            <h3 style={{ margin: 0 }}>
                                                                {item.user.firstName +
                                                                    " " +
                                                                    item.user.lastName}
                                                            </h3>
                                                            <span>{t("email")}: {item.user.email}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                  <Space>

                                                    <EqButton
                                                        type="primary"
                                                        onClick={() => handleAcceptWaitingRequest(item.id)}
                                                        >
                                                        {t("accept")}
                                                    </EqButton>
                                                    <EqButton
                                                        type="button"
                                                        classNames="btn-background-white"
                                                        onClick={() => handleRejectRequest(item.id)}
                                                        >
                                                        {t("Reject")}
                                                    </EqButton>
                                                      </Space>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))}
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </Drawer>

      
        </>
    );
};

export default EqTrainingRequests;
