import React, { useEffect, useState } from "react";
import { Modal,Space, Row, Col } from "antd";
import "./EqSubscriptionProductForm.scss";
import { EqInput, EqInputNumber } from "../../../custom/eqInput/EqInput";
import EqButton from "../../../custom/eqButton/EqButton";
import { openNotificationWithIcon } from "../../../shared/Common";

import EqSelect from "../../../custom/eqSelect/EqSelect";
import { ISelectProps } from "../../../shared/Interfaces";

export interface IEqSubscriptionProductForm {
  visible?: any;
  onCreate?: any;
  onCancel?: any;
}
const EqSubscriptionProductForm = ({
  visible,
  onCreate,
  onCancel,
}: IEqSubscriptionProductForm) => {
  
  const [formData, setFormData] = useState({
    name: "",
    price: 0,
    description: "",
  }) as any;

  const handleNameTextChange = (e: any) => {
    setFormData({ ...formData, name: e.target.value });
  };

  const handlePriceChange = (e: any) => {
    setFormData({ ...formData, price: e });
  };
  const handleDescriptionChange = (e: any) => {
    setFormData({ ...formData, description: e.target.value });
  };

  const handleCreate = () => {
    let validate = validateData(formData);
    if (validate) {
      onCreate(formData);
   
    }
  };

  const validateData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    //same for public and private
    if (data.name === "") {
      openNotificationWithIcon(msg, "Name" + desc);
      isComplete = false;
    } else if (data.price === 0 || data.price === null) {
      openNotificationWithIcon(msg, "Price" + desc);
      isComplete = false;
    } else if (data.description == "") {
      openNotificationWithIcon(msg, "Description" + desc);
      isComplete = false;
    }
    return isComplete;
  };

  useEffect(() => {}, [visible]);

  return (
    <div className="eq-question-modal">
      <Modal
        open={visible}
        title={"Add Subscription Product"}
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={handleCreate}
        footer={[
          <Row justify="center">
            <Space>
              <Col>
                <EqButton
                  block
                  type="button"
                  classNames="btn-background-orange"
                  onClick={handleCreate}
                >
                  Create
                </EqButton>
              </Col>
              <Col>
                <EqButton
                  type="button"
                  bordered="true"
                  classNames="btn-light-gray"
                  onClick={onCancel}
                >
                  Cancel
                </EqButton>
              </Col>{" "}
            </Space>
          </Row>,
        ]}
      >
        <EqInput
          label="Name"
          value={formData.name}
          onChange={handleNameTextChange}
        />
        <EqInputNumber
          label="Price"
          value={formData.price}
          onChange={handlePriceChange}
        />
        <EqInput
          label="Description"
          value={formData.description}
          onChange={handleDescriptionChange}
        />

      </Modal>


    </div>
  );
};
export default EqSubscriptionProductForm;
