import React, { useState } from 'react';
import './EqEditStudentBoard.scss';
import EqEditStudentList, { IEqEditStudentList } from '../eqEditStudentList/EqEditStudentList';
import EqEditStudent, { IEqEditStudent } from '../eqEditStudent/EqEditStudent';
import { Row, Col } from 'antd';
import EqButton from '../../custom/eqButton/EqButton';
import EqSearch, { IEqSearch } from '../../custom/eqSearch/EqSearch';
import { UserOutlined, BankOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';

const EqEditStudentBoard = () => {
    const { t, i18n } = useTranslation();
    const students: Array<IEqEditStudent> = [{
        name: "Student Name",
        area: "Oslo",
        disciplines: ["Dressage", "Show Jumping", "Mounted Games"],
        selected: true, display: false
    }, {
            name: "Student Name",
            area: "Oslo",
            disciplines: ["Dressage", "Show Jumping", "Mounted Games"],
        selected: false,
        display: false
        }, {
            name: "Student Name",
            area: "Oslo",
            disciplines: ["Dressage", "Show Jumping", "Mounted Games"],
        selected: false,
        display: false
        }
    ];
    students.map(
        (s: IEqEditStudent, index: number) => {
            s.display = (index==0?true:false);
        }
    );
    const studentsList: IEqEditStudentList = { studentList: students };
    const eqSearchProps: IEqSearch = {
        selectAfterValues: [{ icon: <UserOutlined />, key: "instructor", value: "instructor" },
        { icon: <BankOutlined />, key: "school", value: "school" }
        ],
        selectDefaultValue: { icon: <UserOutlined />, key: "instructor", value: "instructor" },
        buttonClick: (selectedVal, searchVal) => {},
        buttonTitle: "Submit",
        defaultValue: ""
    }
    return (
        <>
            <div className="eq-board-container">
                <Row className="eq-board-heading">
                    <Col className="eq-board-col" flex="auto">
                        {t("Professionals")}
                    </Col>
                    <Col className="eq-board-col" flex="auto">
                        <Row className="eq-board-header" justify="end" align="bottom">
                            <Col>
                                <div className="eq-board-invite hidden-sm">
                                    Know a professional, ask them to join Equinet.
                                    </div>
                                </Col>
                            <Col>
                                <EqButton type="button" bordered="true" classNames="btn-border-text-orange">
                                    Send Invite</EqButton>
                                </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="eq-board-line">
                </Row>
                    <Row>
                    <Col flex="auto">
                        <div className="section">
                            <h3 className="heading">Find a Horse Center, Riding schools, or Instructors near you.</h3>
                            <div className="search">
                                <EqSearch {...eqSearchProps} />
                                </div>
                        </div>
                        </Col>
                    </Row>
                <EqEditStudentList {...studentsList}></EqEditStudentList>
            </div>
        </>
    );
}
export default EqEditStudentBoard;