// EqProductPaymentPopup.js
import React from "react";
import { Modal, Row, Col, Image, Space } from "antd";
import EqButton from "../eqButton/EqButton";
import { EqInput } from "../eqInput/EqInput";
import { CreditCardTwoTone } from "@ant-design/icons";
import "./EqProductPaymentPopup.scss";
import { openNotificationWithIcon } from "../../shared/Common";
import { Select } from "antd-v5";
const { Option } = Select;

interface EqProductPaymentPopupProps {
  visible: boolean;
  onPayNow: (item: any) => void;
  onCancel: () => void;
  product: any;
}

const EqProductPaymentPopup = ({
  visible,
  onCancel,
  onPayNow,
  product,
}: EqProductPaymentPopupProps) => {
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [countryCode, setCountryCode] = React.useState("+1"); // set the initial country code
  const handleCountryCodeChange = (value: string) => {
    setCountryCode(value);
  };
  const handleCancel = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    });
    onCancel();
  };

  const handlePayNow = () => {
    const isValid = validatePaymentData(formData);

    if (isValid) {
      // Handle payment logic here
      
      onPayNow(formData);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      });
    }
  };

  const validatePaymentData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";

    if (data.firstName.trim() === "") {
      openNotificationWithIcon(msg, "First Name" + desc);
      isComplete = false;
    } else if (data.lastName.trim() === "") {
      openNotificationWithIcon(msg, "Last Name" + desc);
      isComplete = false;
    } else if (!isValidEmail(data.email)) {
      openNotificationWithIcon(msg, "Invalid Email format");
      isComplete = false;
    } else if (data.phoneNumber.trim() === "") {
      openNotificationWithIcon(msg, "Phone Number" + desc);
      isComplete = false;
    }

    return isComplete;
  };
  const isValidEmail = (email:any) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const onlyNumber = value.replace(/[^0-9]/g, ""); // allow only numbers

    // set the formatted phone number as the input value
    e.target.value = onlyNumber;
    setFormData({ ...formData, phoneNumber: onlyNumber });
  };

  return (
    <Modal
      className="eq-product-payment-popup"
      title={
        <>
          <div className="modal-title-container">
            <div className="card-icon-container">
              <div className="circle-outer">
                <div className="circle-inner">
                  <CreditCardTwoTone
                    twoToneColor="#FF8838"
                    className="card-icon"
                  />
                </div>
              </div>
            </div>

            <div className="payment-details">
              <div>Payment</div>
              <p>
                Payment will be completed in Stripe, do not exit until you
                receive a confirmation
              </p>
            </div>
          </div>
        </>
      }
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={null}
    >
      <Row gutter={[16, 16]} className="product-details-container">
        {/* Left Column for Product Image */}
        <Col>
          <img
            src={product.imageUrl}
            alt={product.alt}
            className="product-image"
          />
        </Col>

        {/* Right Column for Product Details */}
        <Col className="product-details">
          <div>
            <h2>Horse Riding Course</h2>
            <div>Title: {product.title}</div>
            <p>Price: {product.price} Kr.</p>
          </div>
        </Col>
      </Row>

      <div className="input-container">
        {/* Use your custom EqInput component */}
        <EqInput
          label="First Name"
          value={formData.firstName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, firstName: e.target.value })
          }
        />
        {/* Use your custom EqInput component */}
        <EqInput
          label="Last Name"
          value={formData.lastName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, lastName: e.target.value })
          }
        />
        {/* Use your custom EqInput component */}
        <EqInput
          label="Email"
          value={formData.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, email: e.target.value })
          }
        />
        {/* Use your custom EqInput component */}
        <EqInput
          label="Phone Number"
          addonBefore={
            <>
              <Select
                value={countryCode}
                onChange={handleCountryCodeChange}
                bordered={false}
              >
                <Option value="+47">
                  <span role="img" aria-label="Norway">
                    🇳🇴
                  </span>{" "}
                  +47
                </Option>
                <Option value="+46">
                  <span role="img" aria-label="Sweden">
                    🇸🇪
                  </span>{" "}
                  +46
                </Option>
                <Option value="+1">
                  <span role="img" aria-label="USA">
                    🇺🇸
                  </span>{" "}
                  +1
                </Option>
                <Option value="+45">
                  <span role="img" aria-label="Denmark">
                    🇩🇰
                  </span>{" "}
                  +45
                </Option>
              </Select>
            </>
          }
          value={formData.phoneNumber}
          onChange={handlePhoneNumberChange}
        />
      </div>

      {/* Buttons directly within the modal body */}
      <Row className="button-container">
        <Space>
          <EqButton
            onClick={handleCancel}
            classNames="btn-background-white"
          >
            Cancel
          </EqButton>
          <EqButton
            onClick={handlePayNow}
          >
            Pay Now
          </EqButton>
        </Space>
      </Row>
    </Modal>
  );
};

export default EqProductPaymentPopup;
