import React, { useEffect, useState } from 'react'
import EqCollapsePanel from '../eqCollapsePanel/EqCollapsePanel';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Checkbox, Col, Row, Select } from 'antd';
import { eqHorseActivityStatusEnum, eqNotificationTypes, genderType, HorseTaskStatusEnum } from '../../shared/enums';
import moment from 'moment';
import { ApiEndpoints, dateFormat } from '../../shared/Common';
import AsyncImage from '../asyncImage/AsyncImage';
import EqCollapse from '../eqCollapse/EqCollapse';
import { useTranslation } from "react-i18next";
import EqSelect from '../eqSelect/EqSelect';
import { ISelectProps } from '../../shared/Interfaces';
import EqTags from '../eqTags/EqTags';
import { Option } from 'antd/lib/mentions';
import { As } from 'react-flags-select';
import "./EqHorsesTaskList.scss"
import { Popconfirm } from "antd-v5";
import { HorseActivityForm, EditOrCreateHorseActivityProps } from '../horseActivityForm/HorseActivityForm';
import Api from '../../shared/Api';

export interface IEqHorseTaskList {
    horsesTaskList: any;
    // display: boolean;

    // onEdit: any
    delFunc?: (item: any) => void;
    editFunc: (item: any) => void;
    selectedFunc: (item: any) => void;
    openNotificationWithIcon?: (msg: any, desc: any, type: any) => void;
    selected?: boolean;
    hideGroup?: boolean;
    hideName?: boolean;
}
const EqHorsesTaskList = ({
    horsesTaskList,
    // onEdit,
    delFunc,
    editFunc,
    selectedFunc,
    // display,
    selected,
    hideGroup,
    hideName,
    openNotificationWithIcon,
    ...rest
}: IEqHorseTaskList) => {

    const { t, i18n } = useTranslation();
    const [check, setCheck] = useState(selected);
    const [editingTask, setEditingTask] = useState(null);
    const [horsesTaskListBinding, setHorsesTaskListBinding] = useState(horsesTaskList);
    const [selectedOption, setSelectedOption] = useState(" " as string);
    const [activityUpdating, setActivityUpdating] = useState(-1);

    const [editHorseActivityFormProps, setEditHorseActivityFormProps] = useState({
        horsesList: null,
        cancelFunc: (item: any, val: any) => {
            //this is not working, hence commented out for now
            if (item && horsesTaskListBinding?.filteredData?.length) {
                var selectedItem = horsesTaskListBinding.filteredData.filter((d: any) => d.id == item.id);
                if (selectedItem.length) {
                    selectedItem[0].isEditing = !selectedItem[0].isEditing;
                }
                //item.isEditing = !item.isEditing;
            }
        },
        createResultFunc: (isSuccess: boolean, val: any) => {
            console.log(val);
        }
    } as EditOrCreateHorseActivityProps);

    useEffect(() => {
        
        var unique: any = [];
        if (!editHorseActivityFormProps?.horsesList?.length && horsesTaskList && horsesTaskList.allData && horsesTaskList.allData.length) {
            horsesTaskList.allData.map((d: any) => {
                var flt = unique.filter((s: any) => s.key == d.id)
                if (flt.length == 0) {
                    unique.push({
                        key: d.id,
                        value: d.name,
                    });
                }
            });
            setEditHorseActivityFormProps({ ...editHorseActivityFormProps, horsesList: unique });
        }
        setHorsesTaskListBinding(horsesTaskList)

    }, [horsesTaskList])

    const CalcNotificationTime = (param: any) => {
        switch (param) {
            case 1: // 6 weeks
                var nfcTime = moment().add(6, 'w').valueOf();
                return nfcTime;
            case 2: // 8 weeks
                return moment().add(8, 'w').valueOf();
            case 3: // 3 months
                return moment().add(3, 'M').valueOf();
            case 4: // 6 months
                return moment().add(8, 'M').valueOf();
            case 5: // 1 year
                return moment().add(1, 'y').valueOf();

            default:
                var nfcTime = moment().add(8, 'w').valueOf();
                return nfcTime;
        }
    }

    const handleChange = (item: any, value: any) => {
        setActivityUpdating(item.activityKey);
        console.log(item, value);

        var date = moment().add(-1, "m").valueOf();

        var dataToSend: any = {
            horseId: item.id,
            time: moment(item.taskTime).valueOf(),
            date: moment(item.taskDate).valueOf(),
            notificationTime: CalcNotificationTime(date),
            description: item.description,
            key: item.activityKey
        }

        if (item.activityType == 1) {
            //task
            dataToSend.taskType = item.activityType;
            Api.post(ApiEndpoints.horse.task.update, dataToSend).then(result => {
                updateBindingData(date, item);
                openNotificationWithIcon != null && openNotificationWithIcon(t('update'), t('horseTaskUpdateTxt'), eqNotificationTypes.success);
                setActivityUpdating(-1);
            }, error => {
                console.log(error);
                openNotificationWithIcon != null && openNotificationWithIcon(t('update'), t('horseTaskErrorTxt'), eqNotificationTypes.error);
                setActivityUpdating(-1);
                //throw error;
            })
        } else {
            //training
            dataToSend.trainingType = item.activityType;
            dataToSend.durationInMinutes = item.duration;
            Api.post(ApiEndpoints.horse.training.update, dataToSend).then(result => {
                updateBindingData(date, item);
                openNotificationWithIcon != null && openNotificationWithIcon(t('update'), t('horseTrainingUpdateTxt'), eqNotificationTypes.success);
                setActivityUpdating(-1);
            }, error => {
                console.log(error);
                openNotificationWithIcon != null && openNotificationWithIcon(t('update'), t('horseTaskErrorTxt'), eqNotificationTypes.error);
                setActivityUpdating(-1);
                //throw error;
            })
        }

                    
        setSelectedOption(value);        
    };

    const updateBindingData = (date: any, item: any) => {
        
        var data = [...horsesTaskListBinding.filteredData];
        var allData = [...horsesTaskListBinding.allData];
        var filtered = data.filter(d => d.activityKey == item.activityKey);
        var filteredAllData = allData.filter(d => d.activityKey == item.activityKey);

        filtered[0].time = date;
        filtered[0].status = HorseTaskStatusEnum.Completed;
        filteredAllData[0].time = date;
        filteredAllData[0].status =  HorseTaskStatusEnum.Completed;
        setHorsesTaskListBinding({ ...horsesTaskListBinding, filteredData: data, allData: allData })
    }

    const removeCard = (item: any) => {
        if (delFunc != null) {
            delFunc(item);
        }
    };

    const editCard = (item: any) => {
        setEditHorseActivityFormProps({ ...editHorseActivityFormProps, formValues: item })
        console.log(item);
        item.isEditing = true;
        setEditingTask(item);
    };

    const onChange = () => {
        setCheck(!check);
    }

    const onCancel = () => {
        // setEditingTask(!editingTask);
    };

    const formatDuration = (minutes: number): string => {
        return Math.floor(minutes / 60) + 'h ' + minutes % 60 + 'm';
    }

    const getStatusDefaultValue = (statusVal: any) => {
        return eqHorseActivityStatusEnum[statusVal];
    }


    return (
        <div className="horse-task-list">

            <Col md={12} xs={24} sm={24} className="mb-16"></Col>

            {horsesTaskListBinding?.filteredData?.length && horsesTaskListBinding?.filteredData.map((item: any, index: any) => (

                <>
                    <Row className="eq-f-row hidden-sm">
                        <Col className="eq-f-td  horse-task-detail" span={5}>
                            <div className="task-info">
                                <AsyncImage
                                    className="event-img"
                                    alt="example"
                                    src={item.imageUrl}
                                />
                            </div>
                            <div>
                                {item.name}
                            </div>
                        </Col>
                        <Col className="eq-f-td" span={3}>
                            {item.task}
                        </Col>
                        <Col className="eq-f-td" span={3}>
                            {moment(item?.time).format(dateFormat)}
                        </Col>
                        <Col className="eq-f-td" span={3}>
                            {item?.duration ? formatDuration(item?.duration) : '-'}
                        </Col>
                        <Col className="eq-f-td" span={2}>
                            {(item?.activityType == 1 ? t('task') : t('training'))}
                        </Col>

                        <Col className="eq-f-td" span={3}>
                            {item.notificationTime!=0?moment(item?.notificationTime).format(dateFormat):"--/--"}
                        </Col>
                        <Col className="eq-f-td task-status" span={3}>
                            <Select
                                // defaultValue={eqHorseActivityStatusEnum[item?.status]}
                                defaultValue={item?.status+""}
                                onChange={(val: any) => handleChange(item, val)}
                                labelInValue // Enable the labelInValue prop
                                loading={activityUpdating == item.activityKey}
                            >
                                <Option value="1">
                                    <EqTags color={"#11A433"} text={t("done")} />
                                </Option>
                                <Option value="0" disabled>
                                    <EqTags color={"#948A20"} text={t("notDone")} />
                                </Option>
                            </Select>

                            {/*{item?.status ? <EqTags color={item?.status == eqHorseActivityStatusEnum.planned ? "#11A433" : "#948A20"}*/}
                            {/*    text={eqHorseActivityStatusEnum[item?.status]} /> :*/}
                            {/*    <EqTags color={"#333"} text={t('unkown')} />}*/}
                        </Col>

                        <Col className="eq-f-td" span={2}>

                            <Popconfirm
                                title="Delete Horses"
                                description="Are you sure to delete all the selected Horses?"
                                onConfirm={() => removeCard(item)}
                                onCancel={() => onCancel()}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteOutlined className="action-delete " />
                            </Popconfirm>


                            {/*<EditOutlined className="action-edit "*/}
                            {/*    onClick={() => editCard(item)}*/}
                            {/*/>*/}
                        </Col>
                        <div className="edit-task-panel">
                            {item?.isEditing && (
                                <EqCollapsePanel
                                    defaultOpen={true}
                                    header={'Edit Task'}
                                    children={<HorseActivityForm {...editHorseActivityFormProps} />}
                                />
                            )}
                        </div>
                    </Row>

                    <div className="hidden-md">
                        <EqCollapse header={
                            <Row justify="start" align="middle">
                                <Col className="" span={2}>
                                    <Checkbox onChange={onChange} checked={check}></Checkbox>
                                </Col>
                                <Col className="" flex="auto">
                                    {item?.name}
                                </Col>
                            </Row>


                        } >
                            <>
                                <Row className="eq-card-row">
                                    <Col className="eq-card-col-heading" span={24}>
                                        {t("name")}
                                    </Col>
                                    <Col className="eq-card-row horse-task-detail" span={24}>
                                        <div className="task-info">
                                            <AsyncImage
                                                className="event-img"
                                                alt="example"
                                                src={item.imageUrl}
                                            />
                                        </div>
                                        <div>
                                            {item.name}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="eq-card-row">
                                    <Col className="eq-card-col-heading" span={24}>
                                        {t("Task")}
                                    </Col>
                                    <Col className="eq-card-row horse-task-detail" span={24}>

                                        <div>
                                            {item.name}

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col flex="auto" className="eq-card-divider"></Col>
                                </Row>
                                <Row className="eq-card-row">
                                    <Col className="eq-card-col-heading" span={24}>
                                        {t("time")}
                                    </Col>
                                    <Col className="" span={24}>
                                        {moment(item?.time).format(dateFormat)}

                                    </Col>
                                </Row>
                                <Row className="eq-card-row">
                                    <Col className="eq-card-col-heading" span={24}>
                                        {t("duration")}
                                    </Col>
                                    <Col className="" span={24}>
                                        {item?.duration ? formatDuration(item?.duration) : '-'}
                                    </Col>
                                </Row>
                                <Row className="eq-card-row">
                                    <Col className="eq-card-col-heading" span={24}>
                                        {t("type")}
                                    </Col>
                                    <Col className="" span={24}>
                                        {(item?.activityType == 1 ? t('task') : t('training'))}
                                    </Col>
                                </Row>
                                <Row className="eq-card-row">
                                    <Col className="eq-card-col-heading" span={24}>
                                        {t("notificationTime")}
                                    </Col>
                                    <Col className="" span={24}>
                                        {moment(item?.time).format(dateFormat)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col flex="auto" className="eq-card-divider"></Col>
                                </Row>
                                <Row className="eq-card-row">
                                    <Col className="eq-card-col-heading" span={24}>
                                        {t("status")}
                                    </Col>
                                    <Col className="eq-card-row horse-task-detail" span={24}>
                                        <div>
                                            {item?.status ? <EqTags color={item?.status == eqHorseActivityStatusEnum.planned ? "#11A433" : "#948A20"}
                                                text={eqHorseActivityStatusEnum[item?.status]} /> :
                                                <EqTags color={"#333"} text={t('unkown')} />}
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="eq-card-row">
                                    <Col className="eq-card-col-heading" span={24}>
                                        {t("actions")}
                                    </Col>
                                    <Col className="" span={24}>

                                        <Popconfirm
                                            title="Delete Horses"
                                            description="Are you sure to delete all the selected Horses?"
                                            onConfirm={() => removeCard(item)}
                                            onCancel={() => onCancel()}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <DeleteOutlined className="action-delete " />
                                        </Popconfirm>

                                        {/*<EditOutlined className="action-edit "*/}
                                        {/*    onClick={editCard}*/}
                                        {/*/>*/}
                                    </Col>
                                </Row>
                            </>
                            {item?.isEditing && (
                                <EqCollapsePanel
                                    defaultOpen={true}
                                    header={'Edit Task'}
                                    children={<HorseActivityForm {...editHorseActivityFormProps} />}
                                />
                            )}
                        </EqCollapse>

                    </div>

                </>


            ))}

            {/* )} */}
        </div>
    )
}

export default EqHorsesTaskList