import { Alert, Checkbox, Col, DatePicker, Input, notification, Popconfirm, Radio, Row, Select, Skeleton, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import EqButton from '../eqButton/EqButton'

import EqCollapsePanel from '../eqCollapsePanel/EqCollapsePanel';
import "./EqHorseTaskBoard.scss"
import EqSelect from '../eqSelect/EqSelect';
import { ISelectProps } from '../../shared/Interfaces';
import EqDatePicker, { IDatePickerOptions } from '../eqDatePicker/EqDatePicker';
import moment from 'moment';
import { EqDisciplineType, eqHorseActivityStatusEnum, EqHorseTrainingType, eqLoadingState, eqNotificationTypes, EqTaskTypes, EqTrainingType, HorseTaskStatusEnum } from '../../shared/enums';
import EqTimePicker, { ITimePickerOptions } from '../eqTimePicker/EqTimePicker';
import { EqInput } from '../eqInput/EqInput';
import Api from '../../shared/AApi';
import { ApiEndpoints, FrontEndPoints, GetEventMessage } from '../../shared/Common';
import { useTranslation, Trans, setDefaults } from "react-i18next";
import { Empty } from 'antd-v5';
import EqHorsesTaskList from '../eqHorsesTaskList/EqHorsesTaskList';
import TabPane from 'antd/lib/tabs/TabPane';
import RevealList from '../RevealList/RevealList';
import EqHorseBoard from '../eqHorseBoard/EqHorseBoard';
import { EditOrCreateHorseActivityProps, HorseActivityForm } from '../horseActivityForm/HorseActivityForm';
import EqHorseStats from '../eqHorseStats/EqHorseStats';

const EqHorseTaskBoard = () => {
    //hooks
    //#region "Hooks-variables"
    const [loading, setLoading] = React.useState(eqLoadingState.initial);
    const [tabKey, setTabKey] = React.useState("1");
    const { t, i18n } = useTranslation();
    const [message, setMessage] = React.useState("");
    const [addTask, setAddTask] = useState(false);
    const [selectedOption, setSelectedOption] = useState(1);
    const [horsesDataListFormatted, setHorsesDataListFormatted] = useState({} as any);
    const [disciplines, setDisciplines] = React.useState({
        allDisciplines: [],
        selectedDiscipline: [],
    } as any);

    var activityFilterInitialState = {
        selectedActivityType: null,
        selectedDate: null,
        selectedStatus: null,
        selectedHorse: null,
        activityTypes: [
            { key: 1, value: t('task') },
            { key: 2, value: t('training') },
        ],
        statusTypes: [
            { key: 0, value: t('notDone') },
            { key: 1, value: t('done') },
        ],
        horses: []
    }


    var initialState = {
        horseId: "",
        taskDate: moment(),
        taskTime: moment(),
        timeTo: moment(),
        notificationTime: moment(),
        taskType: EqTaskTypes,
        trainingType: EqHorseTrainingType,
        duration: 1,
        description: ""
    }
    const [formData, setFormData] = React.useState(initialState);
    const [horsesList, setHorsesList] = React.useState([] as any);
    const [activityFilter, setActivityFilter] = React.useState(activityFilterInitialState as any);


    const openNotificationWithIcon = (
        msg: any,
        desc: any,
        type = eqNotificationTypes.error
    ) => {
        if (type == eqNotificationTypes.success) {
            notification["success"]({
                message: msg,
                description: desc,
            });
        } else if (type == eqNotificationTypes.info) {
            notification["info"]({
                message: msg,
                description: desc,
            });
        } else {
            notification["error"]({
                message: msg,
                description: desc,
            });
        }
    };

    const [isCheckAll, setIsCheckAll] = useState(false);
    //#endregion "Hooks-variables"

    //functions
    //#region "functions"

    const checkAll = () => {
        setIsCheckAll(!isCheckAll);

    };

    const [isCollapseAll, setIsCollapseAll] = useState(false);
    const handleIsCollapseAll = (props: boolean) => {
        setIsCollapseAll(props);
    };
    const collapseAll = () => {
        handleIsCollapseAll(!isCollapseAll);
    };

    const formatHorseListWithActivities = (data: any) => {
        
        var output: any = [];
        data.map((hrs: any) => {
            hrs.horseTasks.map((ht: any) => {
                output.push(mapActivity(hrs, 1, ht));
            });

            hrs.horseTrainings.map((ht: any) => {
                output.push(mapActivity(hrs, 2, ht));
            })
        })
        return output;
    }

    const mapActivity = (hrs: any, activityType: number, ht: any): any => {
        
        return {
            id: hrs.id,// horse id
            key: hrs.key,// horse key
            activityKey: ht.key,
            name: hrs.name,
            imageUrl: hrs.imageUrl,
            task: activityType == 2 ? EqHorseTrainingType[ht.trainingType] : EqTaskTypes[ht.taskType],
            activityType,// 2 is training
            time: ht.time,
            duration: ht.durationInMinutes || "",
            notificationTime: ht.notificationTime,
            // status: (moment().diff(moment(ht.time), 'hours')) < 0 ? eqHorseActivityStatusEnum.planned : eqHorseActivityStatusEnum.completed,
            status: ht.status,
            isEditing: false
        };
    }

    const getHorseList = () => {
        setLoading(eqLoadingState.loading)
        
        Api.get(ApiEndpoints.horse.getAllHorsesIncluded).then(
            (response: any) => {
                setLoading(eqLoadingState.loadingCompleted);
                if (response && response.status == 200) {
                    console.log(response.data, "_____")
                    sortTasks(response.data);
                    var formattedData = formatHorseListWithActivities([...response.data]);
                    setHorsesDataListFormatted({ allData: [...formattedData], filteredData: [...formattedData], apiData: [...response.data] });

                    let data = response.data;
                    var horses: any = [] as any

                    data.map((item: any, index: any) => {
                        let obj = {
                            key: item.id,
                            value: item.name
                        }
                        horses.push(obj)
                    })
                    setHorsesList(horses)
                } else {
                    openNotificationWithIcon(t('update'), t('horseTaskErrorTxt'), eqNotificationTypes.error)
                }
            },
            (error) => {
                setLoading(eqLoadingState.loadingCompleted)
                openNotificationWithIcon(t('update'), t('horseTaskErrorTxt'), eqNotificationTypes.error)
            }
        );
    };

    const sortTasks = (data: any) => {

        if (!data || !data.length) return;

        data.forEach((item: any) => {
            const currentDate = moment();
            const comingSunday = currentDate.clone().startOf('week').add(1, 'week').day(0);
            const sundayAfterComingSunday = comingSunday.clone().add(1, 'week');
            sortNow(item.horseTasks, sundayAfterComingSunday, currentDate);
            sortNow(item.horseTrainings, sundayAfterComingSunday, currentDate);
            
        });


    }

    const sortNow = (arr: any, sundayAfterComingSunday: any, currentDate: any) => {
        arr.sort((a: any, b: any) => {
            const dateA: any = moment(a.date);
            const dateB: any = moment(b.date);

            // Helper function to check if a date is within the next Sunday
            const isWithinNextSunday = (date: any) => date.isBetween(currentDate, sundayAfterComingSunday, null, '[]');

            const aIsWithinNextSunday = isWithinNextSunday(dateA);
            const bIsWithinNextSunday = isWithinNextSunday(dateB);

            // Prioritize dates within the next Sunday
            if (aIsWithinNextSunday && !bIsWithinNextSunday) return -1;
            if (!aIsWithinNextSunday && bIsWithinNextSunday) return 1;

            // If both dates are within the next Sunday, sort them by date
            if (aIsWithinNextSunday && bIsWithinNextSunday) return dateA  - dateB;

            // For dates not within the next Sunday, future dates come before past dates
            if (dateA.isAfter(currentDate) && dateB.isBefore(currentDate)) return -1;
            if (dateA.isBefore(currentDate) && dateB.isAfter(currentDate)) return 1;

            // If both dates are in the future or both in the past, sort normally
            if (dateA.isAfter(currentDate) && dateB.isAfter(currentDate)) return dateA - dateB;
            if (dateA.isBefore(currentDate) && dateB.isBefore(currentDate)) return dateB - dateA;
            return 0;
        });
    }

    //#endregion "functions"

    //#region "props"

    const eqHorsesTaskListProps: any = {
        horsesTaskList: horsesDataListFormatted,
        delFunc: (val: any) => {
            console.log(val);
            var objToSend: any = {
                key: val.activityKey,
                date: val.time,
                time: val.time,
                description: "",
                notificationTime: val.notificationTime,
                horseId: val.id
            }

            if (val.activityType == 1) { //delete horse task                
                objToSend.taskType = EqTaskTypes[val.task]
                Api.post(ApiEndpoints.horse.task.delete, objToSend).then((response: any) => {

                    removeItemOnDelete(val);

                    
                    openNotificationWithIcon(t('update'), t('horseTaskDeleteTxt'), eqNotificationTypes.success)
                }, error => {
                    console.log(error)
                    openNotificationWithIcon(t('update'), t('horseTaskErrorTxt'), eqNotificationTypes.error)
                })
            } else { // horse training deleted
                objToSend.trainingType = EqHorseTrainingType[val.task]
                objToSend.durationInMinutes = val.duration!=""?val.duration:0;

                Api.post(ApiEndpoints.horse.training.delete, objToSend).then((response: any) => {
                    
                    removeItemOnDelete(val);


                    openNotificationWithIcon(t('update'), t('horseTrainingDeleteTxt'), eqNotificationTypes.success)
                }, error => {
                    openNotificationWithIcon(t('update'), t('horseTaskErrorTxt'), eqNotificationTypes.error)
                })
            }
        },
        editFunc: (val: any) => {
            console.log(horsesDataListFormatted, "horsesDataListFormatted");
        },
        selectedFunc: (val: any) => {

        },
        openNotificationWithIcon: openNotificationWithIcon
    }
    const removeItemOnDelete = (val: any) => {
        var updatedList={...horsesDataListFormatted}

        var index = updatedList.allData.findIndex((d: any) => d.activityKey == val.activityKey);
        updatedList.allData.splice(index, 1);

        index = updatedList.filteredData.findIndex((d: any) => d.activityKey == val.activityKey);
        updatedList.filteredData.splice(index, 1);
        setHorsesDataListFormatted(updatedList)
  
      };

    const horseList: ISelectProps = {
        label: `${t('horse')}`,
        extraHeight: "40px",
        options: horsesList,
        defaultSelectedValue: formData?.horseId ? formData?.horseId : [],
        classNames: "extra-height",
        changeFunc: (value: any) => {
            console.log("selected horse: ", value);
            //let type: number = +value;
            setFormData({ ...formData, horseId: value });
        },
    };

    const taskType: ISelectProps = {
        classNames: "extra-height",
        placeholder: `${t('selectTaskType')}`,
        isTag: false,
        label: `${t('taskType')}`,
        defaultSelectedValue: formData?.taskType ? formData?.taskType : [],
        options: Object.entries(EqTaskTypes).filter((e: any) => !isNaN(e[0] as any)).map((e: any) => ({ key: parseInt(e[0]), value: `${t(e[1] as any)}` })),
        changeFunc: (value: any) => {
            setFormData({ ...formData, taskType: value });
        }
    };

    const traningSelectProps: ISelectProps = {
        classNames: "extra-height",
        placeholder: `${t('trainingType')}`,
        isTag: false,
        label: `${t('trainingType')}`,
        defaultSelectedValue: formData?.trainingType ? formData?.trainingType : [],
        options: Object.entries(EqHorseTrainingType).filter((e: any) => !isNaN(e[0] as any)).map((e: any) => ({ key: parseInt(e[0]), value: `${t(e[1] as any)}` })),
        changeFunc: (value: any) => {
            setFormData({ ...formData, trainingType: value });
        },
    };

    const setEventDefaults = () => {
        setFormData({ ...initialState });
    };

    
    const [addHorseActivityProps, setAddHorseActivityProps] = useState({
        horsesList: null,
        cancelFunc: (item: any, val: any) => {
            setAddTask(!val);
        },
        createResultFunc: (isSuccess: boolean, addedActivityData: any) => {
            if (isSuccess) {
                getHorseList();
                openNotificationWithIcon(t('update'), t('horseTrainingAddTxt'), eqNotificationTypes.success)
            } else {
                openNotificationWithIcon(t('update'), t('horseTaskErrorTxt'), eqNotificationTypes.error)
            }
        },
        formValues: {
            horseId: "",
            taskDate: moment(),
            taskTime: moment(),
            timeTo: moment(),
            notificationTime: moment(),
            taskType: EqTaskTypes as any,
            trainingType: EqHorseTrainingType as any,
            duration: 1,
            description: ""
        }
    } as EditOrCreateHorseActivityProps)

    //#endregion "props"

    //#region "binding-functions"

    const handleSaveTask = () => {
        var tim = moment(formData.taskTime);
        var dat = moment(formData.taskDate);
        dat.set("hour", tim.get('hour'));
        dat.set("minute", tim.get('minute'));
        var dataToSend: any = {
            horseId: formData.horseId,
            time: moment(dat).valueOf(),
            date: moment(dat).valueOf(),
            notificationTime: CalcNotificationTime(formData.notificationTime),
            description: formData.description
        }

        if (selectedOption == 1) {
            //task
            dataToSend.taskType = formData.taskType;
            Api.post(ApiEndpoints.horse.task.add, dataToSend).then(result => {
                setEventDefaults();
                openNotificationWithIcon(t('update'), t('horseTaskAddTxt'), eqNotificationTypes.success)
            }, error => {
                console.log(error);
                openNotificationWithIcon(t('update'), t('horseTaskErrorTxt'), eqNotificationTypes.error);
                //throw error;
            })
        } else {
            //training
            dataToSend.trainingType = formData.trainingType;
            dataToSend.durationInMinutes = CalcDurationInMinutes(formData.duration);
            Api.post(ApiEndpoints.horse.training.add, dataToSend).then(result => {

                setEventDefaults();
                openNotificationWithIcon(t('update'), t('horseTrainingAddTxt'), eqNotificationTypes.success)
            }, error => {
                console.log(error);
                openNotificationWithIcon(t('update'), t('horseTaskErrorTxt'), eqNotificationTypes.error)
                //throw error;
            })
        }
    }

    const CalcDurationInMinutes = (param: any): any => {
        switch (param) {
            case 1: // 20 min
                return 20;
            case 2: // 30 min
                return 30;
            case 3: // 1 hour
                return 60;
            case 4: // 2 hours
                return 120;
            default: return 30;
        }
    }

    const CalcNotificationTime = (param: any) => {
        switch (param) {
            case 1: // 6 weeks
                var nfcTime = moment().add(6, 'w').valueOf();
                return nfcTime;
            case 2: // 8 weeks
                return moment().add(8, 'w').valueOf();
            case 3: // 3 months
                return moment().add(3, 'M').valueOf();
            case 4: // 6 months
                return moment().add(8, 'M').valueOf();
            case 5: // 1 year
                return moment().add(1, 'y').valueOf();

            default:
                var nfcTime = moment().add(8, 'w').valueOf();
                return nfcTime;
        }
    }

    const handleRadioChange = (e: any) => {
        setSelectedOption(e.target.value);
    };


    useEffect(() => {
        
        var unique: any = [];
        if (horsesDataListFormatted && horsesDataListFormatted.apiData && horsesDataListFormatted.apiData.length) {
            horsesDataListFormatted.apiData.map((d: any) => {
                var flt = unique.filter((s: any) => s.key == d.id)
                if (flt.length == 0) {
                    unique.push({
                        key: d.id,
                        value: d.name,
                    });
                }
            });
            setAddHorseActivityProps({ ...addHorseActivityProps, horsesList: unique })
        }

    }, [horsesDataListFormatted])

    useEffect(() => {
        setEventDefaults();
        (!(horsesDataListFormatted?.length)) && getHorseList();
    }, [])

    useEffect(() => {

        var unique: any = [];
        if (horsesDataListFormatted?.apiData && horsesDataListFormatted?.apiData?.length) {
            horsesDataListFormatted.apiData.map((d: any) => {
                var flt = unique.filter((s: any) => s.key == d.id)
                if (flt.length == 0) {
                    unique.push({
                        key: d.id,
                        value: t(d.name),
                    });
                }
            });
            setActivityFilter({ ...activityFilter, horses: unique });
        }

    }, [horsesDataListFormatted])

    const onChangeTab = (key: any) => {
        clearFilters();
        setTabKey(key);
    };

    const handleChange = (value: any) => {
        var date = value ? moment(value) : value;
        setActivityFilter({
            ...activityFilter, selectedDate: date
        })

        applyfilters(date, activityFilter.selectedActivityType, activityFilter.selectedStatus, activityFilter.selectedHorse);
    };

    const handleActivityTypeChange = (value: any) => {

        setActivityFilter({
            ...activityFilter, selectedActivityType: value
        })
        var intVal = parseInt(value);
        applyfilters(activityFilter.selectedDate, intVal, activityFilter.selectedStatus, activityFilter.selectedHorse);
    };

    const handleStatusChange = (value: any) => {
        
        setActivityFilter({
            ...activityFilter, selectedStatus: value
        })
        var intVal = parseInt(value);
        applyfilters(activityFilter.selectedDate, activityFilter.selectedActivityType, intVal, activityFilter.selectedHorse);
    };
    const handleHorseChange = (value: any) => {
        setActivityFilter({
            ...activityFilter, selectedHorse: value
        })
        var intVal = parseInt(value);
        applyfilters(activityFilter.selectedDate, activityFilter.selectedActivityType, activityFilter.selectedStatus, value);
    };

    const clearFilters = () => {
        setActivityFilter(activityFilterInitialState);
        setHorsesDataListFormatted({ ...horsesDataListFormatted, filteredData: horsesDataListFormatted.allData })
    };

    const applyfilters = (date: any, activityType: any, status: any, horse: any) => {

        let statusValid=status !== null && status !== undefined && !isNaN(status);

        var filtered = horsesDataListFormatted.allData.filter((d: any) => { return moment().diff(d.time, "days") == 0 });

        if (date && activityType && statusValid) {
            filtered = horsesDataListFormatted.allData.filter((d: any) =>
                moment().diff(d.time, "days") == 0 && d.activityType == activityType && d.status == status);

        } else if (date && activityType) {
            filtered = horsesDataListFormatted.allData.filter((d: any) =>
                moment().diff(d.time, "days") == 0 && d.activityType == activityType);
        } else if (date && statusValid) {
            filtered = horsesDataListFormatted.allData.filter((d: any) =>
                moment().diff(d.time, "days") == 0 && d.status == status);

        } else if (activityType && statusValid) {
            filtered = horsesDataListFormatted.allData.filter((d: any) =>
                d.activityType == activityType && d.status == status);

        } else if (date) { // only date filter is applied
            filtered = horsesDataListFormatted.allData.filter((d: any) =>
                moment().diff(d.time, "days") == 0);

        } else if (activityType) { // only activityType filter is applied
            filtered = horsesDataListFormatted.allData.filter((d: any) =>
                d.activityType == activityType);
        } else if (statusValid) { // only status filter is applied
            filtered = horsesDataListFormatted.allData.filter((d: any) =>
                d.status == status);
        } else { // no filter applied
            filtered = horsesDataListFormatted.allData
        }

        if (horse) {
            filtered = filtered.filter((d: any) => d.id == horse);
        }

        setHorsesDataListFormatted({ ...horsesDataListFormatted, filteredData: filtered })
    }
    //#endregion "binding-functions"

    const prepareAddTask = (val: any) => {
        var firstValue = horsesDataListFormatted.filteredData[0];
        setAddHorseActivityProps({ ...addHorseActivityProps, formValues: firstValue });
        setAddTask(val);
    }

    const removeHorseActivity = (val: any) => {

    }
    const deleteActivityCancel = (val: any) => {

    }

    return (
        <div className="horse-management">

            <Tabs defaultActiveKey="1" onChange={onChangeTab}>
                <TabPane tab={t("horseActivities")} key="1">
                    <div className="loading"><Skeleton loading={loading === eqLoadingState.loading} active></Skeleton></div>
                    <div className="list">
                        <RevealList>
                            <Col className="eq-HorseTask-container">
                                <div className="add-task">
                                    <EqButton
                                        type="primary"
                                        className=""
                                        onClick={() => prepareAddTask(!addTask)}
                                    >
                                        +{t('addActivity')}
                                    </EqButton>
                                </div>
                                {addTask && (
                                    <EqCollapsePanel
                                        defaultOpen={true}
                                        header={t("horseTask")}
                                        children={
                                            <HorseActivityForm {...addHorseActivityProps} />
                                        }
                                    />
                                )}




                                {/* filter */}
                                <Row>
                                    <Col span={24} className="filters">
                                        {/* <div className="filters"> */}
                                        <Row gutter={[16, 16]} className="filter-type">

                                            {/* <div className="event-location"> */}
                                            <Col className="location-col " xs={24} sm={24} md={12} lg={11}>
                                                <Row className="location-selector">
                                                    <Col xs={6} sm={5} md={6} lg={5}>
                                                        <label className="picker-label">{t("horse")}</label>
                                                    </Col>
                                                    <Col className="location-input" flex="auto">
                                                        <Select
                                                            className="eq-filter-radius eq-input-info"
                                                            allowClear
                                                            placeholder={t("selectHorse")}
                                                            onChange={handleHorseChange}
                                                            value={activityFilter.selectedHorse}
                                                        >
                                                            {activityFilter?.horses?.length && activityFilter?.horses.map((actvty: any) => (
                                                                <Select.Option
                                                                    label={actvty.value}
                                                                    value={actvty.key}
                                                                    key={actvty.key}
                                                                >
                                                                    {actvty.value}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="location-col " xs={24} sm={24} md={12} lg={11}>
                                                <Row className="location-selector">
                                                    <Col xs={6} sm={5} md={6} lg={5}>
                                                        <label className="picker-label">{t("activityType")}</label>
                                                    </Col>
                                                    <Col className="location-input" flex="auto">
                                                        <Select
                                                            className="eq-filter-radius eq-input-info"
                                                            allowClear
                                                            placeholder={t("selectType")}
                                                            onChange={handleActivityTypeChange}
                                                            value={activityFilter.selectedActivityType}
                                                        >
                                                            {activityFilter.activityTypes.map((actvty: any) => (
                                                                <Select.Option
                                                                    label={actvty.value}
                                                                    value={actvty.key}
                                                                    key={actvty.key}
                                                                >
                                                                    {actvty.value}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="radius-col" xs={24} sm={24} md={12} lg={11}>
                                                <Row className="radius-selector">
                                                    <Col xs={6} sm={5} md={6} lg={5}>
                                                        <label className="picker-label">{t("status")}</label>
                                                    </Col>
                                                    <Col className="radius-input" flex="auto">
                                                        <Select
                                                            className="eq-filter-radius  eq-input-info"
                                                            allowClear
                                                            placeholder={t("status")}
                                                            onChange={handleStatusChange}
                                                            value={activityFilter.selectedStatus}
                                                        >
                                                            {activityFilter.statusTypes.map((mark: any) => (
                                                                <Select.Option label={t(mark.value)} value={mark.key} key={mark.key}>
                                                                    {t(mark.value)}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Col>



                                            <Col className="date-col " xs={24} sm={24} md={12} lg={11}>
                                                <Row className="date-selector  start-date-selector">
                                                    <Col xs={6} sm={5} md={6} lg={5}>
                                                        <label className="picker-label">{t("date")}</label>
                                                    </Col>
                                                    <Col className="date-input" flex="auto">
                                                        <DatePicker
                                                            placeholder={t("selectDate")}
                                                            className="eq-filter-radius  eq-input-info"
                                                            value={activityFilter.selectedDate}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="date-col " xs={24} sm={24} md={12} lg={11}>
                                                &nbsp;
                                            </Col>

                                            <Col className="search-col" xs={24} sm={24} md={12} lg={11}>
                                                <Row className="date-selector  start-date-selector">
                                                    <Col xs={6} sm={5} md={6} lg={5}>
                                                        <label className="picker-label">&nbsp;</label>
                                                    </Col>
                                                    <Col className="date-input" flex="auto">
                                                        <div className="search">
                                                            <EqButton onClick={clearFilters} classNames="eq-input-info" type="primary" htmlType="button">
                                                                {t("clearFilters")}
                                                            </EqButton>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                                {/* end filter */}

                                {loading === eqLoadingState.loadingCompleted && horsesDataListFormatted?.filteredData?.length ?
                                    <Col span={24}>
                                        <Row className="eq-fl-row hidden-sm">
                                            <Col className="eq-fl-th" span={5}>
                                                {t("name")}
                                            </Col>
                                            <Col className="eq-fl-th" span={3}>
                                                {t("activity")}
                                            </Col>
                                            <Col className="eq-fl-th" span={3}>
                                                {t("time")}
                                            </Col>
                                            <Col className="eq-fl-th" span={3}>
                                                {t("duration")}
                                            </Col>
                                            <Col className="eq-fl-th" span={2}>
                                                {t("type")}
                                            </Col>
                                            <Col className="eq-fl-th" span={3}>
                                                {t("notification")}
                                            </Col>
                                            <Col className="eq-fl-th" span={3}>
                                                {t("status")}
                                            </Col>
                                            <Col className="eq-fl-th" span={2}>
                                                {t("actions")}
                                            </Col>
                                        </Row>
                                        <div className="hidden-md">
                                            <Row justify="start" align="middle" className="eq-list-checkAll">
                                                <Col>
                                                    <EqButton
                                                        type="button"
                                                        classNames="btn-background-orange"
                                                        onClick={collapseAll}
                                                    >
                                                        {isCollapseAll ? "Collapse All" : "Expand All"}
                                                    </EqButton>
                                                </Col>
                                            </Row>
                                        </div>
                                        <EqHorsesTaskList {...eqHorsesTaskListProps} />
                                    </Col> : ""}
                                {loading === eqLoadingState.loadingCompleted && !horsesDataListFormatted?.filteredData?.length && <div className="no-data">
                                    <Empty description={t("noHorseActivityAvailable")}>
                                    </Empty>
                                </div>}
                            </Col>
                        </RevealList>
                    </div>
                </TabPane>
                <TabPane tab={t("horseProfiles")} key="2">
                    <RevealList>
                        <EqHorseBoard />
                    </RevealList>
                </TabPane>
                <TabPane tab={t("horseStats")} key="3">
                    <RevealList>
                        <EqHorseStats />
                    </RevealList>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default EqHorseTaskBoard