import React, { useEffect, useState } from 'react';
import './EqProfessionalsGrid.scss';
import EqProfessionals, { IEqProfessionals } from '../eqProfessionals/EqProfessionals';
import EqProfessionalsCard from '../eqProfessioanlsCard/EqProfessionalsCard';
import { useTranslation, Trans } from 'react-i18next';
import { Row, Col, Checkbox } from 'antd';

export interface IEqProfessionalsList {
    professionalsList: Array<IEqProfessionals>;
}
const EqProfessionalsGrid = ({ professionalsList }: IEqProfessionalsList) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            {professionalsList.length > 0 &&
                <>
                    {
                        professionalsList.map((item: IEqProfessionals, index: number) =>
                            <>
                                <Col md={8}>
                                    <EqProfessionalsCard key={index} {...item} ></EqProfessionalsCard>
                                </Col>
                            </>
                        )
                    }
                </>
            }
        </>
    );
}
export default EqProfessionalsGrid;