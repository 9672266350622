import React, { useState, useEffect } from 'react';
import './EqSchoolStudentsBoard.scss';
import { paymentStatus, paymentStatusType, EqLocationType } from '../../shared/enums';
import { Row, Col } from 'antd';
import { Input } from 'antd';
import EqSelect from '../../custom/eqSelect/EqSelect';
import EqButton from '../../custom/eqButton/EqButton';
import { useTranslation, Trans } from 'react-i18next';
import Api from '../../shared/Api';
import { EqStatusCode, GetInvitesMessage, ApiEndpoints, GetSentInviteMessage } from '../../shared/Common';
import { ISelectProps } from '../../shared/Interfaces';
import EqInviteToJoin from '../../custom/eqInviteToJoin/EqInviteToJoin';
import EqSentInvitesList, { IEqSentInvitesList } from '../../eqTrainer/eqSentInvitesList/EqSentInvitesList';
import { IEqSentInvites } from '../../eqTrainer/eqSentInvites/EqSentInvites';
import EqStudentList, { IEqStudentList } from '../../eqTrainer/eqStudentList/EqStudentList';
import AnimationDiv from '../../custom/animationDiv/AnimationDiv';

const EqSchoolStudentsBoard = () => {
    const { t, i18n } = useTranslation();
    const { Search } = Input;
    const [addNew, setAddNew] = useState(false);
    const [reqisCheckAll, reqsetIsCheckAll] = useState(false);
    const [inisCheckAll, insetIsCheckAll] = useState(false);
    const [requestslist, setrequestsList] = React.useState([]) as any;
    const [invitesList, setInvitesList] = React.useState([]) as any;
    const [currentStudentslist, setCurrentStudentsList] = React.useState({ allStudents: [], filteredStudents: [] }) as any;
    const [messageString, setMessageString] = useState('');
    const [searchStr, setSearchStr] = useState("");
    const [requestKeys, setRequestKeys] = useState(new Array<any>());
    const [inviteKeys, setInviteKeys] = useState(new Array<any>());
    const [paymentStatus, setPaymentStatus] = React.useState([] as any);
    const [search, setSearch] = React.useState({ searchVal: '', paymentFilter: [] as any });
    var selectedRequests = new Array<any>();
    var selectedInvites = new Array<any>();

    var sortedList: Array<any> | undefined;

    const getPaymentStatusClass = (status: any): string => {
        if (status == paymentStatusType.Paid) return "green";
        else if (status == paymentStatusType.UnpaidButAttending) return "red";
        else if (status == paymentStatusType.StudentCancelled) return "purple";
        else return "orange";
    };
    const createPaymentStatusTypes = () => {
        var types = [];

        for (var x in paymentStatusType) {
            if (isNaN(Number(x)) == false) {
                types.push({ key: x, value: getPaymentStatusClass(x), label: paymentStatusType[x] });
            }
        }
        setPaymentStatus(types);
        //console.log(types);
    }
    const onRequestCheckChange = (data: any, checked: boolean) => {
        if (checked) {
            selectedRequests.push({ Key: data.key });
        }
        else {
            if (selectedRequests != null && selectedRequests.length > 0) {
                const index = selectedRequests.findIndex(x => x.Key == data.key);
                if (index > -1) {
                    selectedRequests.splice(index, 1);
                }
            }
        }
        console.log(selectedRequests);
        setRequestKeys(selectedRequests);
    }
    // const requestsCheckAll = (list: any, checked: boolean) => {
    //     //console.log(list);
    //     reqsetIsCheckAll(!reqisCheckAll);
    //     list.map(
    //         (m: IEqJoiningRequest) => {
    //             if (m.selected == false) {
    //                 m.selected = !checked;
    //                 selectedRequests.push({
    //                     Key: m.request.key, Email: m.request.email
    //                 });
    //             }
    //             else {
    //                 m.selected = !checked;
    //                 if (selectedRequests != null && selectedRequests.length > 0) {
    //                     const index = selectedInvites.findIndex(x => x.Key == m.request.key);
    //                     if (index > -1) {
    //                         selectedRequests.splice(index, 1);
    //                     }
    //                 }
    //             }
    //             setRequestKeys(selectedRequests);
    //         }
    //     );
    //     //console.log(selectedRequests);
    //     const modifiedList = [...list];
    //     setrequestsList(modifiedList);
    // }
    const deleteSelectedRequest = () => {
        reqsetIsCheckAll(false);
        console.log("clear request", requestKeys);
        if (requestKeys.length > 0) {

            Api.post(ApiEndpoints.SchoolStudentDeleteSelectedRequest, requestKeys).then(response => {
                if (response && response.status == 200) {
                    GetPendingRequests();
                    setMessageString('success');
                } else {
                    setMessageString('failed');
                }
            }, err => {
                setMessageString('failed');
            });
        }
    }
    const invitesCheckAll = (list: any, checked: boolean) => {
        //console.log(list);

        insetIsCheckAll(!inisCheckAll);

        list.map(
            (m: IEqSentInvites) => {
                if (m.selected == false) {
                    m.selected = !checked;
                    selectedInvites.push({ Key: m.student.key, Email: m.student.email });
                }
                else {
                    m.selected = !checked;
                    if (selectedInvites != null && selectedInvites.length > 0) {
                        const index = selectedInvites.findIndex(x => x.Key == m.student.key);
                        if (index > -1) {
                            selectedInvites.splice(index, 1);
                        }
                    }
                }
                setInviteKeys(selectedInvites);
            }
        );
        console.log(selectedInvites);
        const modifiedList = [...list];
        setInvitesList(modifiedList);
    }
    const onInviteCheckChange = (data: any, checked: boolean) => {

        if (checked) {
            selectedInvites.push({ Key: data.key, Email: data.email });
        }
        else {
            if (selectedInvites != null && selectedInvites.length > 0) {
                const index = selectedInvites.findIndex(x => x.Key == data.key);
                if (index > -1) {
                    selectedInvites.splice(index, 1);
                }
            }
        }
        console.log(selectedInvites);
        setInviteKeys(selectedInvites);
    }
    const deleteSelectedInvitation = () => {
        //console.log(inviteKeys);

        insetIsCheckAll(false);
        if (inviteKeys.length > 0) {

            Api.post(ApiEndpoints.SchoolStudentDeleteSelectedInvitation, inviteKeys).then(response => {
                if (response && response.status == 200) {
                    GetInvitations();
                    setMessageString('success');
                } else {
                    setMessageString('failed');
                }
            }, err => {
                setMessageString('failed');
            });
        }
    }
    const showAddNew = () => {
        setAddNew(true);
    }
    const cancelFunction = (e: any) => {
        setAddNew(false);
    }
    const SendInviteClicked = (success: boolean) => {
        if (success == true) {
            GetInvitations();
        }
    }
    const onSortChange = (event: any) => {
        // console.log(currentStudentslist.filteredStudents);
        switch (event) {
            case "Sort A-Z":
                sortedList = [...currentStudentslist.filteredStudents].sort((obj1, obj2) => {
                    if ((obj1.student.userName).toLowerCase() > (obj2.student.userName).toLowerCase()) { return 1; }
                    else if ((obj1.student.userName).toLowerCase() < (obj2.student.userName).toLowerCase()) { return -1 } else { return 0 }
                });
                setCurrentStudentsList({ ...currentStudentslist, filteredStudents: sortedList });
                break;
            case "Sort Z-A":
                sortedList = [...currentStudentslist.filteredStudents].sort((obj1, obj2) => {
                    if ((obj1.student.userName).toLowerCase() < (obj2.student.userName).toLowerCase()) { return 1; }
                    else if ((obj1.student.userName).toLowerCase() > (obj2.student.userName).toLowerCase()) { return -1 } else { return 0 }
                });
                setCurrentStudentsList({ ...currentStudentslist, filteredStudents: sortedList });
                break;
        }
    }
    const onRequestsSortChange = (event: any) => {
        switch (event) {
            case "Sort A-Z":
                sortedList = [...requestslist].sort((obj1, obj2) => {
                    if ((obj1.request.userName).toLowerCase() > (obj2.request.userName).toLowerCase()) { return 1; }
                    else if ((obj1.request.userName).toLowerCase() < (obj2.request.userName).toLowerCase()) { return -1 } else { return 0 }
                });
                setrequestsList(sortedList);
                break;
            case "Sort Z-A":
                sortedList = [...requestslist].sort((obj1, obj2) => {
                    if ((obj1.request.userName).toLowerCase() < (obj2.request.userName).toLowerCase()) { return 1; }
                    else if ((obj1.request.userName).toLowerCase() > (obj2.request.userName).toLowerCase()) { return -1 } else { return 0 }
                });
                setrequestsList(sortedList);
                break;
        }
    }
    const onInvitesSortChange = (event: any) => {
        switch (event) {
            case "Sort A-Z":
                sortedList = [...invitesList].sort((obj1, obj2) => {
                    if ((obj1.student.userName).toLowerCase() > (obj2.student.userName).toLowerCase()) { return 1; }
                    else if ((obj1.student.userName).toLowerCase() < (obj2.student.userName).toLowerCase()) { return -1 } else { return 0 }
                });
                setInvitesList(sortedList);
                break;
            case "Sort Z-A":
                sortedList = [...invitesList].sort((obj1, obj2) => {
                    if ((obj1.student.userName).toLowerCase() < (obj2.student.userName).toLowerCase()) { return 1; }
                    else if ((obj1.student.userName).toLowerCase() > (obj2.student.userName).toLowerCase()) { return -1 } else { return 0 }
                });
                setInvitesList(sortedList);
                break;
        }
    }
    const acceptJoiningRequest = (student: any) => {
        Api.post(ApiEndpoints.acceptJoiningRequest, student).then(response => {
            if (response && response.status == 200) {
                GetPendingRequests();
                GetCurrentStudents();
                return true;
            } else {
                return false;
            }
        }, err => {
            return false;
        });
    }
    const deleteInvitation = (student: any): boolean => {

        Api.post(ApiEndpoints.SchoolStudentDeleteInvitation, student).then(response => {
            if (response && response.status == 200) {
                GetInvitations();
                return true;
            } else {
                return false;
            }
        }, err => {
            return false;
        });
        return false;
    }
    const deleteRequest = (student: any): boolean => {
        setrequestsList([] as any);
        Api.post(ApiEndpoints.deleteRequest, student).then(response => {
            if (response && response.status == 200) {
                GetPendingRequests();
                return true;
            } else {
                return false;
            }
        }, err => {
            return false;
        });
        return false;
    }
    const deleteStudent = (student: any) => {

        Api.post(ApiEndpoints.SchoolDeleteStudent, student).then(response => {
            if (response && response.status == 200) {
                GetCurrentStudents();
                return true;
            } else {
                return false;
            }
        }, err => {
            return false;
        });
    }
    const GetInvitations = () => {
        setMessageString('');
        setInvitesList([] as any);
        Api.get(ApiEndpoints.SchoolGetSentInvites).then(response => {
            var students = [] as any;
            //console.log(response);
            if (response && response.status == 200) {

                var invitedStudents = response.data?.result ? [...response.data.result] : [] as any;
                invitedStudents.map((d: any) => {
                    students.push({
                        student: {
                            key: d.key,
                            firstName: d.firstName,
                            lastName: d?.lastName,
                            userName: d?.userName,
                            email: d.email,
                            locations: d.locations?.filter((opt: any) => (opt.location?.type == EqLocationType.Home)).shift(),
                            payment: d.paymentStatus,
                            groups: d.groups,
                            selected: false
                        },
                        deleteFunc: deleteInvitation,
                        checkChangeFunc: onInviteCheckChange,
                        selected: false
                    }
                    )
                });
                setInvitesList(students);
            } else {
                setMessageString(GetInvitesMessage(EqStatusCode.serverException502));
            }
        }, err => {
            setMessageString(GetInvitesMessage(err.response.status));
        });
    }
    const GetPendingRequests = () => {
        setMessageString('');
        setrequestsList([] as any);
        Api.get(ApiEndpoints.getRequests).then(response => {
            var requests = [] as any;
            if (response && response.status == 200) {

                var pendingRequests = response.data ? [...response.data] : [] as any;
                pendingRequests.map((d: any) => {
                    //console.log(d);
                    requests.push({
                        request: {
                            key: d.user?.key,
                            firstName: d.user?.firstName,
                            lastName: d.user?.lastName,
                            userName: d.user?.userName,
                            email: d.user?.email,
                            locations: d.user?.locations?.filter((opt: any) => (opt.location?.type == EqLocationType.Home)).shift(),
                            payment: d.user?.paymentStatus,
                            groups: d.user?.groups,
                            selected: false
                        },
                        deleteFunc: deleteRequest,
                        acceptFunc: acceptJoiningRequest,
                        checkChangeFunc: onRequestCheckChange,
                        selected: false
                    })
                });
                setrequestsList(requests);
            } else {
                setMessageString("Not found");
            }
        }, err => {
            setMessageString("Not found");
        });
    }
    const GetCurrentStudents = () => {
        setMessageString('');
        setCurrentStudentsList([] as any);
        var students = [] as any;
        Api.get(ApiEndpoints.SchoolGetCurrentStudents).then(response => {

            if (response && response.status == 200) {
                var data = response.data ? [...response.data] : [] as any;
                data.map((d: any) => {
                    students.push({
                        student: {
                            firstName: d?.firstName,
                            lastName: d?.lastName,
                            userName: d?.userName,
                            phoneNumber: d?.phoneNumber,
                            email: d?.email,
                            locations: d?.locations?.filter((opt: any) => (opt.location?.type == EqLocationType.Home)).shift(),
                            payment: d?.paymentStatus,
                            groups: d?.groups,
                            selected: false
                        },
                        deleteFunc: deleteStudent,
                        selected: false
                    }
                    )
                });
                setCurrentStudentsList({ allStudents: [...students], filteredStudents: [...students] });
            } else {
                setMessageString("Not found");
            }
        }, err => {
            setMessageString("Not found");
        });
    }
    const searchClick = (searchStr: string) => {
        setSearch({ ...search, searchVal: searchStr })
        filterStudents(searchStr, search.paymentFilter);
    }
    const filterStudents = (searchFilter: string, paymentFilter: any) => {
        //console.log(searchFilter);
        //console.log(paymentFilter);

        var filterResult = currentStudentslist.allStudents;
        if (searchFilter != "" && searchFilter != null && searchFilter.length > 0) {
            filterResult = filterResult.filter((g: any) => {
                return g?.student?.userName.includes(searchStr);
            })
        }
        if (paymentFilter != null && paymentFilter.length > 0) {
            filterResult = filterResult.filter((g: any) => {
                let res = paymentFilter.some(
                    (status: any) => {
                        return status == g?.student?.payment
                    }
                );
                return res;
            });
        }
        setCurrentStudentsList({ ...currentStudentslist, filteredStudents: filterResult });
    }
    const studentInviteProps = {
        header: t("Invite-Student"), emailHeading: "Please add your student email address", messageHeading: "Message",
        cancelFunc: cancelFunction, sendFunc: SendInviteClicked
    };
    const filterProps: ISelectProps = {
        mode: "multiple",
        classNames: "eq-multiSelect",
        border: false,
        dropdownClassName: 'eq-select-dropdown',
        isTag: true,
        ddClassName: "eq-select-dropdown",
        icon: [<span className="eq-fb-filter"><img alt="" src="/Filter.svg" /></span>],
        selectedIcon: [<span className="eq-fb-filter"><img alt="" src="/check-square.svg" /></span>],
        options: paymentStatus,
        changeFunc: (value: any) => {
            // console.log(value);
            setSearch({ ...search, paymentFilter: value });
            filterStudents(search.searchVal, value);
        }
    }
    const invitesSortProps: ISelectProps = {
        border: false, allowClear: false, classNames: "eq-select-sort", defaultSelectedValue: "Sort A-Z", changeFunc: onInvitesSortChange,
        options: [{ key: "Sort A-Z", value: "Sort A-Z" }, { key: "Sort Z-A", value: "Sort Z-A" }]
    };
    const requestsSortProps: ISelectProps = {
        border: false, allowClear: false, classNames: "eq-select-sort", defaultSelectedValue: "Sort A-Z", changeFunc: onRequestsSortChange,
        options: [{ key: "Sort A-Z", value: "Sort A-Z" }, { key: "Sort Z-A", value: "Sort Z-A" }]
    };
    const studentSortProps: ISelectProps = {
        border: false, allowClear: false, classNames: "eq-select-sort", defaultSelectedValue: "Sort A-Z", changeFunc: onSortChange,
        options: [{ key: "Sort A-Z", value: "Sort A-Z" }, { key: "Sort Z-A", value: "Sort Z-A" }]
    };
    var studentsList: IEqStudentList = { studentsList: currentStudentslist.filteredStudents };
    // var requestList: IEqJoiningRequestList = { joiningRequestList: requestslist, checkAllFun: requestsCheckAll, checkAll: reqisCheckAll };
    var sentInvitesList: IEqSentInvitesList = { sentInvitesList: invitesList, checkAllFun: invitesCheckAll, checkAll: inisCheckAll };
    useEffect(() => {
        GetInvitations();
        GetCurrentStudents();
        // GetPendingRequests();
        createPaymentStatusTypes();
    }, []);
    return (
        <>

            <AnimationDiv>

                <div className="eq-sb-container">
                    <Row className="eq-sb-heading-row">
                        <Col className="eq-sb-col" flex="auto" sm={5} xs={4}>
                            <h2 className="eq-sb-heading">{t("students")}</h2>
                        </Col>
                        <Col className="eq-sb-col" flex="auto" sm={19} xs={20}>
                            <Row className="eq-sb-student-header" justify="end" align="bottom">
                                <Col className="eq-sb-followers hidden-sm">
                                    {t("followers")} {currentStudentslist.allStudents?.length}
                                </Col>
                                <Col className="eq-sb-student-sort hidden-sm">
                                    <EqSelect key={1}  {...studentSortProps} ></EqSelect>
                                </Col>
                                <Col className="eq-sb-student-search" xs={14} sm={14} md={6} lg={6} xl={6} xxl={6}>
                                    <Search placeholder={t("inputSearchText")} onChange={(e: any) => setSearchStr(e.target.value)} onSearch={searchClick} />
                                </Col>
                                <Col>

                                </Col>
                                <Col>
                                    <div className="eq-sb-student-btn" >
                                        <EqButton type="button" onClick={showAddNew} classNames="btn-background-orange">
                                            <img alt="" src="/plus.svg" />
                                            <span className="hidden-sm"><span className="icon-space"></span>{t("addNew")}</span></EqButton>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <div>
                        {addNew &&
                            <EqInviteToJoin {...studentInviteProps}></EqInviteToJoin>
                        }
                    </div>
                    <Row className="eq-sb-list-header" justify="center" align="middle">
                        <Col className="hidden-md" xs={24} sm={24}>
                            <div className="eq-sb-followers">
                                {t("followers")} {currentStudentslist.allStudents?.length}
                            </div>
                        </Col>
                        <Col className="hidden-md" xs={7} sm={6}>
                            <div className="eq-sb-student-sort">
                                <EqSelect key={2} {...studentSortProps} ></EqSelect>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <Row justify="center">

                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="eq-sb-select hidden-sm" span={24}>
                            <EqSelect key={3} {...filterProps}></EqSelect>
                        </Col>
                    </Row>
                    <div className="">
                        <EqStudentList {...studentsList}></EqStudentList>
                    </div>
                    {/* <Row className="eq-sb-heading-row">
                    <Col className="eq-sb-heading" flex="auto">
                        {t("joiningRequests")}
                    </Col>
                    <Col className="eq-sb-col hidden-sm">
                        <Row className="eq-sb-student-header" justify="end" align="bottom">
                            <Col className="eq-sb-followers">
                                Total Requests {requestslist.length}
                            </Col>
                            <Col className="eq-sb-student-sort">
                                <EqSelect key={4} {...requestsSortProps}></EqSelect>
                            </Col>
                        
                            <Col>
                                <div className="eq-sb-student-btn">
                                    <EqButton type="button" bordered="true" classNames="btn-border-orange" onClick={deleteSelectedRequest}>
                                        <img alt="" src="/text.svg" /><span className="icon-space"></span>
                                        Clear Request</EqButton>
                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row> */}
                    {/* <Row className="eq-sb-list-header" justify="center" align="middle">
                    <Col className="hidden-md" xs={24} sm={24}>
                        <div className="eq-sb-followers">
                            Total Requests {requestslist.length}
                        </div>
                    </Col>
                    <Col className="hidden-md" xs={7} sm={6}>
                        <div className="eq-sb-student-sort">
                            <EqSelect key={5} {...requestsSortProps} ></EqSelect>
                        </div>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Row justify="center">
                      
                            <Col >
                                <div className="eq-sb-student-btn hidden-md">
                                    <EqButton type="button" bordered="true" classNames="btn-border-orange" onClick={deleteSelectedRequest}>
                                        <img alt="" src="/text.svg" /><span className="icon-space"></span>
                                        Clear Request</EqButton>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
             
                <div className="">
                    <EqJoiningRequestList {...requestList}></EqJoiningRequestList>
                </div> */}

                    <Row className="eq-sb-heading-row">
                        <Col className="eq-sb-heading" flex="auto">
                            {t("sentInvites")}
                        </Col>
                        <Col className="eq-sb-col hidden-sm" flex="auto">
                            <Row className="eq-sb-student-header" justify="end" align="bottom">
                                <Col className="eq-sb-followers">
                                    {t("sentInvites")} {invitesList.length}
                                </Col>
                                <Col className="eq-sb-student-header-right">
                                    <div className="eq-sb-student-sort">
                                        <EqSelect key={6} {...invitesSortProps}></EqSelect>
                                    </div>
                                </Col>
                                {/* <Col>
                                <div className="eq-sb-student-btn">
                                    <EqButton type="button" bordered="true" classNames="btn-light-gray">
                                        Filters</EqButton>
                                </div>
                            </Col> */}
                                <Col>
                                    <div className="eq-sb-student-btn">
                                        <EqButton type="button" bordered="true" classNames="btn-border-orange" onClick={deleteSelectedInvitation}>
                                            <span className="eq-btn-icon"><img alt="" src="/text.svg" /></span>
                                            {t("clearInvites")}</EqButton>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="eq-sb-list-header" justify="center" align="middle">
                        <Col className="hidden-md" xs={24} sm={24}>
                            <div className="eq-sb-followers">
                                {t("sentInvites")} {invitesList.length}
                            </div>
                        </Col>
                        <Col className="hidden-md" xs={7} sm={6}>
                            <div className="eq-sb-student-sort">
                                <EqSelect key={7} {...invitesSortProps} ></EqSelect>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <Row justify="center">
                                {/* <Col>
                                <div className="eq-sb-student-btn hidden-md">
                                    <EqButton type="button" bordered="true">
                                        <span className="">Filters</span></EqButton>
                                </div>
                            </Col> */}
                                <Col >
                                    <div className="eq-sb-student-btn hidden-md" onClick={deleteSelectedInvitation}>
                                        <EqButton type="button" bordered="true" classNames="btn-border-orange">
                                            <img alt="" src="/text.svg" /><span className="icon-space"></span>
                                            {t("clearInvites")}</EqButton>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <div className="">
                        <EqSentInvitesList {...sentInvitesList}></EqSentInvitesList>
                    </div>
                </div>

            </AnimationDiv>
        </>
    );
}
export default EqSchoolStudentsBoard;