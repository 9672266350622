import React from 'react';
import './ConfirmRegister.scss';
import AsyncImage from '../../custom/asyncImage/AsyncImage';
import { Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const ConfirmRegister = () => {
    const { t } = useTranslation();
    const { Text, Title } = Typography;

    return (
        <div className='eq-confirmRegister'>
            <Row className='confirm-container' justify='center'>
            <AsyncImage className="confirm-image" src="confirmRegister/horseback-riding-illustration.jpg" />
            <Text className='confirm-text'>
                {/* {t("confirmationEmailSent")} <br />
                {t("checkEmailAndConfirmEmailLink")} */}
                {/* {t("verifyEmailSent")} */}
            </Text>

            </Row>
        </div>
    );
}

export default ConfirmRegister;
