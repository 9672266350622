import * as React from "react";
import EqEvent from "../../eqEvent/EqEvent";
import { Row, Col, Empty, Tabs, Badge } from "antd";
import {
    addDays,
    addDaysUTC,
    dateFormat,
    FrontEndPoints,
    ValidateIsEventHolder,
    ValidateIsSchool,
    ValidateIsStudent,
    ValidateIsTrainer,
} from "../../../shared/Common";
import ".././EqActivityTrainingList.scss";
import "./EqActivityTrainingListDashboard.scss";
import EqButton from "../../../custom/eqButton/EqButton";
import moment from "moment";

import { useAuthState } from "../../../context/Context";
import { IEqEventList } from "../../../shared/Interfaces";
import { IEqEventData } from "../../../shared/interfaces/IEqEventDto";
import { IEqEvent } from "../../../shared/Interfaces";
import { useLocation } from "react-router-dom";
import EqActivityCollapse from "../../eqActivityCollapse/EqActivityCollapse";
import { useTranslation, Trans } from "react-i18next";
import TabPane from "antd/lib/tabs/TabPane";
import { Link } from "react-router-dom";
import EqActivityCollapseDashboard from "../../eqActivityCollapseDashboard/EqActivityCollapseDashboard";
import { addMonths } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
//testgit

export interface IEqEventSplit {
    today: any;
    tomorrow: any;
    later: any;
}

export interface IEqActivityTrainingList {

    eventList: any;

    creatorUser?: any;
    areas?: any;
    weekDays?: any;
    population?: any;
    groupPopulation?: any;
    trainerStudents?: any;
    studentHorsesList?: any;
    removeEventFunc?: (data: any) => void;
    editEventFunc?: (data: any) => void;
    removeAttendeeFunc?: (data: any) => void;
    onCreateOrUpdateActivity?: (data: any, oldData?: any) => void;
}

const EqActivityTrainingListDashboard = ({
    creatorUser,
    areas,
    weekDays,
    population,
    groupPopulation,
    trainerStudents,
    studentHorsesList,
    eventList,
    removeEventFunc,
    editEventFunc,
    removeAttendeeFunc,
    onCreateOrUpdateActivity,
    ...rest
}: IEqActivityTrainingList) => {
    const { t, i18n } = useTranslation();
    const [eventData, setEventData] = React.useState({} as IEqEventSplit);
    const [tabCounts, setTabCounts] = React.useState({
        today: 0,
        tomorrow: 0,
        later: 0,
    });
    const userDetails: any = useAuthState();
    const isStudent: boolean = ValidateIsStudent(userDetails);
    const isTrainer: boolean = ValidateIsTrainer(userDetails);
    const isSchool: boolean = ValidateIsSchool(userDetails);
    const isEventHolder: boolean = ValidateIsEventHolder(userDetails);

    const options: Intl.DateTimeFormatOptions = {
        weekday: "long", // Correctly typed as "long" | "short" | "narrow" | undefined
        day: "2-digit",  // Correctly typed as "numeric" | "2-digit" | undefined
        month: "short",  // Correctly typed as "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined
        year: "numeric", // Correctly typed as "numeric" | "2-digit" | undefined
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayTime = today.getTime();

    const tomorrow = new Date();
    tomorrow.setHours(24, 0, 0, 0);
    const tomorrowTime = tomorrow.getTime();

    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setHours(48, 0, 0, 0);
    const dayAfterTomorrowTime = dayAfterTomorrow.getTime();

    const currentDate = addDays(new Date(), 2).toLocaleDateString(t("dateLang"), options)
    const futureDate = addMonths(new Date(), 1).toLocaleDateString(t("dateLang"), options);

    const dateRange = `${currentDate} to ${futureDate}`;

    
    React.useEffect(() => {
        var formedData = {} as IEqEventSplit;
        if (eventList?.length > 0) {
            formedData.today = eventList?.filter(
                (opt: any) => opt.startDate < tomorrowTime && opt.startDate >= todayTime
                // (opt: any) => opt.startDate < tomorrowTime 
            );
            formedData.tomorrow = eventList?.filter(
                (opt: any) =>
                    opt.startDate < dayAfterTomorrowTime && opt.startDate >= tomorrowTime
            );
            formedData.later = eventList?.filter(
                (opt: any) => opt.startDate > dayAfterTomorrowTime
            );
            setEventData(formedData);
            // Update tab counts
            setTabCounts({
                today: formedData.today.length,
                tomorrow: formedData.tomorrow.length,
                later: formedData.later.length,
            });
        }
        else {
            setEventData({} as any);

        }
        //formedData.today = eventList;
    }, [eventList, areas, weekDays]);

    return (
        <>
            <div className="eq-tr-dashboard-list">
                {eventData?.later?.length > 0 ||
                    eventData?.tomorrow?.length > 0 ||
                    eventData?.today?.length > 0 ? (
                    <>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={
                                <span>{t("today")}{" "}
                                    <Badge
                                        count={tabCounts.today}
                                        offset={[0, -15]}
                                        className="tab-badge"
                                        size="default"
                                    >
                                    </Badge>
                                </span>}

                                key="1">
                                <div>
                                    {eventData?.today?.length > 0 ? (
                                        <div className="eq-el-container">
                                            <Row justify='end'>
                                                <Col className="eq-el-dateHeading">
                                                    <h5 className="eq-el-date">
                                                    {new Date().toLocaleDateString(t("dateLang"),options as any)}
                                                    </h5>

                                                    <div className="eq-el-date-icon hidden-sm">
                                                        <span>
                                                        <FontAwesomeIcon icon={faCalendar} />
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {eventData.today.map((item: any, index: number) => (
                                                <>

                                                    <EqActivityCollapseDashboard
                                                        key={index}
                                                        creatorUser={creatorUser}
                                                        event={item}
                                                        areas={areas}
                                                        weekDays={weekDays}
                                                        population={population}
                                                        groupPopulation={groupPopulation}
                                                        trainerStudents={trainerStudents}
                                                        studentHorsesList={studentHorsesList}
                                                        removeEventFunc={removeEventFunc}
                                                        editEventFunc={editEventFunc}
                                                        removeAttendeeFunc={removeAttendeeFunc}
                                                        onCreateOrUpdateActivity={onCreateOrUpdateActivity}
                                                    ></EqActivityCollapseDashboard>
                                                </>
                                            ))}

                                        </div>
                                    ) : (
                                        <Empty description={t("noTrainingsAvailable")} />
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab={
                                <span>{t("tomorrow")}{" "}
                                    <Badge
                                        count={tabCounts.tomorrow}
                                        offset={[0, -15]}
                                        className="tab-badge"
                                        size="default"
                                    >
                                    </Badge>
                                </span>} key="2">
                                <div>
                                    {eventData?.tomorrow?.length > 0 ? (

                                        <div className="eq-el-container">
                                            <Row justify='end'>
                                                <Col className="eq-el-dateHeading">
                                                    <h5 className="eq-el-date">
                                                    {addDays(new Date(), 1).toLocaleDateString(t("dateLang"), options)}
                                                    </h5>

                                                    <div className="eq-el-date-icon hidden-sm">
                                                        <span>
                                                        <FontAwesomeIcon icon={faCalendar} />
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {eventData.tomorrow.map((item: any, index: number) => (
                                                // <EqEvent {...{ event: { ...item }, removeEventFunc: removeEventFunc, editEventFunc: editEventFunc, removeAttendeeFunc: removeAttendeeFunc  }} ></EqEvent>
                                                <EqActivityCollapseDashboard
                                                    key={index}
                                                    creatorUser={creatorUser}
                                                    event={item}
                                                    areas={areas}
                                                    weekDays={weekDays}
                                                    population={population}
                                                    groupPopulation={groupPopulation}
                                                    trainerStudents={trainerStudents}
                                                    studentHorsesList={studentHorsesList}
                                                    removeEventFunc={removeEventFunc}
                                                    editEventFunc={editEventFunc}
                                                    removeAttendeeFunc={removeAttendeeFunc}
                                                    onCreateOrUpdateActivity={onCreateOrUpdateActivity}
                                                ></EqActivityCollapseDashboard>
                                            ))}
                                        </div>
                                    ) : (
                                        <Empty description={t("noTrainingsAvailable")} />
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab={
                                <span>{t("later")}{" "}
                                    <Badge
                                        count={tabCounts.later}
                                        offset={[0, -15]}
                                        className="tab-badge"
                                        size="default"
                                    >
                                    </Badge>
                                </span>} key="3">
                                <div>

                                    {eventData?.later?.length > 0 ? (
                                        <div className="eq-el-container">
                                            <Row justify='end'>
                                                <Col className="eq-el-dateHeading">
                                                    <h5 className="eq-el-date">
                                                        {dateRange}
                                                    </h5>

                                                    <div className="eq-el-date-icon hidden-sm">
                                                        <span>
                                                        <FontAwesomeIcon icon={faCalendar} />
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {eventData.later.map((item: any, index: number) => (
                                                <EqActivityCollapseDashboard
                                                    key={index}
                                                    creatorUser={creatorUser}
                                                    event={item}
                                                    areas={areas}
                                                    weekDays={weekDays}
                                                    population={population}
                                                    groupPopulation={groupPopulation}
                                                    trainerStudents={trainerStudents}
                                                    studentHorsesList={studentHorsesList}
                                                    removeEventFunc={removeEventFunc}
                                                    editEventFunc={editEventFunc}
                                                    removeAttendeeFunc={removeAttendeeFunc}
                                                    onCreateOrUpdateActivity={onCreateOrUpdateActivity}
                                                ></EqActivityCollapseDashboard>

                                                // <EqEvent {...{ event: { ...item }, removeEventFunc: removeEventFunc, editEventFunc: editEventFunc, removeAttendeeFunc: removeAttendeeFunc }} key={index} ></EqEvent>
                                            ))}
                                        </div>
                                    ) : (
                                        <Empty description={t("noTrainingsAvailable")} />
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>

                    </>
                ) : (
                    <div className="eq-el-container">
                        <Empty description={t("noTrainingsAvailable")} />
                    </div>
                )}
                <>
                    {isTrainer ? (
                        <Link to={FrontEndPoints.trainer.activityBoard}>
                            <EqButton classNames="eq-el-btn">
                                {t("showMore")}
                            </EqButton>
                        </Link>
                    ) : (
                        <Link to={FrontEndPoints.school.activityBoard}>
                            <EqButton classNames="eq-el-btn">
                            {t("showMore")}
                            </EqButton>
                        </Link>
                    )}
                </>
            </div>
        </>
    );
};

export default EqActivityTrainingListDashboard