import React, { useState, useEffect } from "react";
import "./EqMessage.scss";
import { Row, Col, Checkbox } from "antd";
import EqComposeMessageInline from "../eqComposeMessageInline/EqComposeMessageInline";
import Api from "../../shared/Api";
import { ApiEndpoints, EqMessageType } from "../../shared/Common";

export interface IEqMessage {
  message: IEqMessageData;
  selected: boolean;
  checkBoxBordered: boolean;
  clickedReplyFun: (value: IEqMessageData) => void;
  clickedFlagFun: (value: IEqMessageData) => void;
  clickedDeleteFunc: (value: IEqMessageData) => void;
  onCheckChangeFunc: (value: IEqMessageData, checked: boolean) => void;
  onReply: (value: IEqMessageData) => void;
}

export interface IEqMessageData {
  key: string;
  isRead?: boolean;
  isSent?: boolean;
  isFlaged?: boolean;
  messageOn: Date;
  type: string;
  from: string;
  to: string;
  subject: string;
  text: string;
}

const EqMessage = ({
  message,
  selected,
  clickedReplyFun,
  clickedFlagFun,
  clickedDeleteFunc,
  onCheckChangeFunc,
  onReply,
  ...rest
}: IEqMessage) => {
  const [receivedMessage, setReceivedMessage] = useState({ ...message });
  const [check, setCheck] = useState(selected);
  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState(receivedMessage.isFlaged);

  const replyClicked = (e: any) => {
    toggleShow(true);
    e.stopPropagation();
  };

  const flagClicked = (e: any) => {
    e.stopPropagation();
    setFlag(!flag);
    setReceivedMessage({ ...receivedMessage, isFlaged: !flag });
    clickedFlagFun({ ...receivedMessage, isFlaged: !flag });
  };

  const deleteClicked = (e: any) => {
    e.stopPropagation();
    clickedDeleteFunc(message);
  };
  const toggleShow = (value: any) => {
    setShow(value);
    if (receivedMessage.isRead == false) {
      saveMessage();
    }
    return null;
  };
  function onChange(e: any) {
    setCheck(!check);
    e.stopPropagation();
    onCheckChangeFunc(message, !check);
  }
  const saveMessage = () => {
    if (receivedMessage != null) {
      Api.post(ApiEndpoints.saveMessage, {
        Message: { Key: receivedMessage.key },
        IsFlaged: receivedMessage.isFlaged,
        IsRead: true,
      }).then(
        (response) => {
          if (response && response.status == 200) {
            setReceivedMessage({ ...receivedMessage, isRead: true });
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const inlineMessageProps = {
    messageDetail: receivedMessage,
    showComponent: show,
    setShowComponent: toggleShow,
    sendReplyClicked: clickedReplyFun,
  };
  useEffect(() => {
    
    setCheck(selected);
    setReceivedMessage({ ...message });
  }, [message, selected]);

  return (
    <div className="eq-message">
      <div className="eq-message-container">
        {receivedMessage && (
          <>
            <Row
              className={
                receivedMessage.isRead == true
                  ? "eq-message-read"
                  : "eq-message-unread"
              }
              id="message-container"
              onClick={() => toggleShow(!show)}
            >
              <Col xs={20} sm={20} md={22}>
                <Row>
                  <Col
                    span={1}
                    id="mcheckbox"
                    className="hidden-sm eq-message-item eq-message-item-check"
                  >
                    <Checkbox
                      onChange={onChange}
                      checked={check}
                      onClick={(e: any) => e.stopPropagation()}
                    ></Checkbox>
                  </Col>
                  <Col
                    xs={4}
                    sm={4}
                    md={3}
                    id="mType"
                    className="eq-message-item eq-message-item-type eq-message-body-text"
                  >
                    {receivedMessage.type}
                  </Col>
                  <Col
                    xs={11}
                    sm={11}
                    md={5}
                    id="mFrom"
                    className="eq-message-item eq-message-item-from eq-message-body-text"
                  >
                    {receivedMessage.from}
                  </Col>
                  <Col
                    xs={9}
                    sm={9}
                    md={11}
                    id="mSubject"
                    className="eq-message-item eq-message-item-title eq-message-body-text"
                  >
                    {receivedMessage.subject}
                  </Col>
                </Row>
              </Col>
              <Col
                xs={4}
                sm={4}
                md={2}
                id="mActions"
                className="eq-message-item eq-message-item-action"
              >
                <Row className="eq-message-action">
                  {message.type == EqMessageType[EqMessageType.Message] && (
                    <Col
                      className="eq-message-action-reply"
                      onClick={replyClicked}
                    >
                      <span>
                        <img alt="" src="/reply.svg" />
                      </span>
                    </Col>
                  )}

                  {/* <Col className="eq-message-action-flag" onClick={flagClicked} >
                                        {
                                            receivedMessage.isFlaged == true ?
                                                <span className=""><img alt="" src="/flag.svg" /></span> :
                                                <span className=""><img alt="" src="/empty-flag.svg" /></span>
                                        }
                                    </Col> */}
                  <Col
                    className="eq-message-action-delete"
                    onClick={deleteClicked}
                  >
                    <span className="">
                      <img alt="" src="/trash-alt.svg" />
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            
            {message.type == EqMessageType[EqMessageType.Message] && (
              <EqComposeMessageInline
                {...inlineMessageProps}
              ></EqComposeMessageInline>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EqMessage;
