import React, { useEffect } from 'react'
import EqStudentSchedule from '../eqStudentSchedule/EqStudentSchedule'
import Api from '../../shared/AApi';
import { ApiEndpoints } from '../../shared/Common';
import { IEqEventData } from '../../shared/interfaces/IEqEventDto';
import './EqStudentScheduleList.scss'
const EqStudentScheduleList = () => {
    const [isError, setIsError] = React.useState(false);
    const [activities, setActivities] = React.useState([] as Array<any>);

    const [studentEventClasses, setStudentEventClasses] = React.useState(
        null
    ) as any; //Array<IEqEventData>
    const [trainings, setTrainings] = React.useState(null) as any;
    const [events, setEvents] = React.useState([] as Array<any>); //Array<IEqEventData>
    const [studentProductOrders, setStudentProductsOrders] = React.useState(
        [] as Array<any>
      ); //Array<IEqEventData>
    const GetStudentJoinedEventClasses = () => {
        Api.get(ApiEndpoints.GetStudentJoinedClasses).then(
            (response) => {
                if (response && response.status == 200) {
                    var data = response.data ? [...response.data] : new Array<any>();
                    data.map((f: any, index: number) => {
                        f.display = index == 0 ? true : false;
                    });
                    setStudentEventClasses(data);
                } else {
                    setIsError(true);
                }
            },
            (error) => {
                setIsError(true);
            }
        );
    };
    const GetUserAcceptedEvents = () => {
        Api.get(ApiEndpoints.GetUserAcceptedEvents).then(
            (response) => {
                if (response && response.status == 200) {
                    var data = response.data
                        ? [...response.data]
                        : new Array<IEqEventData>();
                    data.map((f: any, index: number) => {
                        f.display = index == 0 ? true : false;
                    });
                    var event = data?.filter((opt: any) => opt.accessType == 2);
                    var trainings = data?.filter((opt: any) => opt.accessType == 1);

                    setActivities(data);
                    setEvents(event);
                    setTrainings(trainings);
                } else {
                    setIsError(true);
                }
            },
            (error) => {
                setIsError(true);
            }
        );
    };
    


    const GetStudentProductOrders = () => {
        Api.get(ApiEndpoints.ProductOrder).then(
          (response) => {
            if (response && response.status == 200) {
              var data = response.data ? [...response.data] : new Array<any>();
    
              if (data.length > 0) {
                var joinedProducts = data?.filter(
                  (opt: any) => opt.status == 1 || opt.status == 3
                );
    
                setStudentProductsOrders(joinedProducts);
              }
            } else {
              setIsError(true);
            }
          },
          (error) => {
            setIsError(true);
          }
        );
      };
    
    useEffect(() => {

        GetUserAcceptedEvents();
        GetStudentProductOrders();
        GetStudentJoinedEventClasses();

    }, []);
    return (
        <div className='student-schedule-list'>
            <EqStudentSchedule
                isOwner={false}
                isStudent={true}
                eventList={studentEventClasses}
                trainings={trainings}
                studentProductOrders={studentProductOrders}
            // onCreateOrUpdateTraining: handleOnUpdateTraining,
            // onCreateOrUpdateEvent: handleOnUpdateEvent,
            // onStudentJoin: GetAllTrainerTrainingsLessonsByTrainerKey,
            // onRemoveTraining: handleRemoveTraining,
            // onRemoveEvent: handleRemoveEvent,
            // areas: areas,
            // imagesData: imagesData,
            // weekDays: weekDays,
            // population: population,
            // groupPopulation: groupPopulation,
            // trainerStudents: trainerStudents,
            // studentHorsesList: studentHorsesList,
            // profileData: profileData,
            // activitiesList: activitiesList,
            // publicProducts: [...productsList?.public],
            // isSubscribed: subscribeButtonState.buttonDisabled,
            // subscribeTrainer: SubscribeTrainer,
            ></EqStudentSchedule>
        </div>
    )
}

export default EqStudentScheduleList