import React, { useEffect, useState } from "react";
import "./EqProductFinancial.scss";
import { Row, Col, Tooltip } from "antd";
import { Checkbox } from "antd";
import EqCollapse, {
  IEqCollapse,
} from "../../../../custom/eqCollapse/EqCollapse";
import {
  paymentStatusType,
  EqDisciplineType,
  EqEventAccessType,
} from "../../../../shared/enums";
import moment from "moment";
import { FrontEndPoints } from "../../../../shared/Common";
import { useNavigate } from "react-router-dom";
import { InfoCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export interface IEqProductFinancial {
  financial: any;
}
const EqProductFinancial = ({ financial }: IEqProductFinancial) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [check, setCheck] = useState(financial.selected);
  useEffect(() => {
    checkChange(financial.selected);
    console.log(financial);
  }, [financial]);
  function checkChange(selected: boolean) {
    setCheck(selected);
  }
  function onChange() {
    setCheck(!check);
  }
  const messageClicked = () => {
    var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${financial.student.email}`;
    navigate(out1);
  };

  const getClass = (param: number) => {
    switch (param) {
      case 1:
        return "purple";
      case 2:
        return "red";
      case 3:
        return "green";
      case 4:
        return "orange";
      default:
        return "orange";
    }
  };

  const calculatePercentage = (price: number, vat: number) => {
    const vatAmount = (vat / 100) * price;
    const roundedVatAmount = Number(vatAmount.toFixed(2));

    return roundedVatAmount;
  };
  const CollapseProps: IEqCollapse = {
    display: financial.display,
    header: [
      <Row justify="start" align="middle">
        <Col className="" span={2}>
          <Checkbox onChange={onChange} checked={check}></Checkbox>
        </Col>
        <Col className="" flex="auto">
          {financial.student?.firstName} {financial.student?.lastName}
        </Col>
      </Row>,
    ],
  };
  return (
    <>
      <Row className="eq-f-row hidden-sm">
        <Col className="eq-f-td" span={1}>
          <Checkbox onChange={onChange} checked={check}></Checkbox>
        </Col>
        <Col className="eq-f-td" span={3}>
          {financial.student?.firstName} {financial.student?.lastName}
        </Col>
        <Col className="eq-f-td" span={3}>
          {/* {EqDisciplineType[financial.product.disciplineType]} */}
          {financial.product.title}
        </Col>
        <Col className="eq-f-td" span={4}>
          {moment(financial.payment.paymentDate).format("DD MMMM, YYYY HH:mm")}
        </Col>

        <Col className="eq-f-td" span={3}>
          <span className="eq-f-currency">Kr. </span>
          {financial.product.price}{" "}

          <span className="eq-f-currency">
            <Tooltip placement="topLeft" title={t("sumFeeAndVat")}>
              <InfoCircleFilled />
            </Tooltip>
          </span>
        </Col>
        <Col className="eq-f-td" span={3}>
          <span className="eq-f-currency">Kr. </span>
          {calculatePercentage(financial.product.price, financial.product.vat)}
          {/* <span className="eq-f-currency">% </span> */}
        </Col>
        <Col className="eq-f-td" span={2}>
          <span className="eq-f-currency">Kr. </span>
          {calculatePercentage(financial.product.price, 1)}
        </Col>
        <Col className="eq-f-td" span={3}>
          <span className={`eq-status-${getClass(financial.status)}`}>
            {(paymentStatusType[financial.status])}
          </span>
        </Col>
        <Col className="eq-f-td" span={2}>
          <div className="">
            {/* <span className="eq-action-invite" onClick={messageClicked}>
              <img alt="" src="/Invite.svg" />
            </span> */}
            <span className="eq-action-invite" onClick={undefined}>
              <img alt="" src="/Envelop.svg" /> Invoice
            </span>
            {/*<span className="eq-action-delete"><img alt="" src="/Delete.svg" /></span>*/}
          </div>
        </Col>
      </Row>

      <div className="hidden-md">
        <EqCollapse {...CollapseProps}>
          <>
            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                Product
              </Col>
              <Col className="" span={24}>
                {/* {EqDisciplineType[financial.product.disciplineType]} */}
                {financial.product.title}
                {/* {financial.product} */}
              </Col>
            </Row>
            <Row>
              <Col flex="auto" className="eq-card-divider"></Col>
            </Row>
            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                Payment Date
              </Col>
              <Col className="" span={24}>
                {moment(financial.payment.paymentDate).format(
                  "DD MMMM, YYYY HH:mm"
                )}
              </Col>
            </Row>
            <Row>
              <Col flex="auto" className="eq-card-divider"></Col>
            </Row>

            <Row>
              <Col flex="auto" className="eq-card-divider"></Col>
            </Row>
            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                Amount
              </Col>
              <Col className="" span={24}>
                Kr.{" "}
                <span className="eq-f-currency">{financial.product.price}</span>
                <span className="eq-f-currency">
                  <Tooltip
                    placement="topLeft"
                    title={"Sum including fee and VAT"}
                  >
                    <InfoCircleFilled />
                  </Tooltip>
                </span>
              </Col>
            </Row>
            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                Vat
              </Col>
              <Col className="" span={24}>
                Kr.
                <span className="eq-f-currency">
                  {calculatePercentage(
                    financial.product.price,
                    financial.product.vat
                  )}
                </span>
              </Col>
            </Row>

            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                Fee
              </Col>
              <Col className="" span={24}>
                Kr. <span className="eq-f-currency"> {calculatePercentage(financial.product.price, 1)}</span>
              </Col>
            </Row>
            <Row>
              <Col flex="auto" className="eq-card-divider"></Col>
            </Row>
            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                Status
              </Col>
              <Col className="" span={24}>
                <span className={`eq-status-${getClass(financial.status)}`}>
                  {paymentStatusType[financial.status]}
                </span>
              </Col>
            </Row>
            <Row>
              <Col flex="auto" className="eq-card-divider"></Col>
            </Row>
            <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                Actions
              </Col>
              <Col className="" span={24}>
                <div className="">
                  <span className="eq-action-message" onClick={messageClicked}>
                    <img alt="" src="/Invite.svg" />
                  </span>
                </div>
              </Col>
            </Row>
          </>
        </EqCollapse>
      </div>
    </>
  );
};
export default EqProductFinancial;
