import { useEffect, useState } from "react";
import React from "react";
import './EqIntegrationSection.scss';
import { useTranslation } from "react-i18next";
import { Col, Row, Spin, Switch } from "antd";
import { EqIntegrationsType, eqNotificationTypes } from "../../shared/enums";
import { EqInput } from "../eqInput/EqInput";
import EqButton from "../eqButton/EqButton";
import Api from "../../shared/Api";
import { ApiEndpoints, openNotificationWithIcon } from "../../shared/Common";


const EqIntegrationSection = ({integrationData}:any) => {


  const initialFormData = {
    id:"",
    type:EqIntegrationsType.None,
    employeeToken:"",
    tripletexToken:"",
    powerOfficToken:"",
  }
  const { t, i18n } = useTranslation();
  const [loading ,setLoading] = useState(false);
  const [error ,setIsError] = useState(false);
  const [formData ,setFormData] = useState(initialFormData);

  const onEmployeKeyChange=(e:any)=>{
    let value = e.target.value;
    setFormData({...formData,employeeToken:value,tripletexToken:value});
  }
  const onClientKeyChange=(e:any)=>{
    let value = e.target.value;
    setFormData({...formData,employeeToken:value,powerOfficToken:value});
  }
  const integrateUserAccount=(e:any)=>{
    if(formData.id === ""){
      addIntegration();
    }else{
      updateIntegration();
    }
  }
  const addIntegration=()=>{
    setLoading(true);
    Api.post(ApiEndpoints.IntegrationPartner, formData).then(
      (response) => {
        if(response && response?.status === 200){
          let result =  response.data;
          mapIntegrationdata(result);
          openNotificationWithIcon(
            "Success",
            "Integration Completed.",
            eqNotificationTypes.success
          );
        }
        
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        openNotificationWithIcon(
          "Error",
          error?.response?.data ? error?.response?.data:"Error occurred while integrating. Please try again or contact administrator."
        );
      }
    );
  }
  const updateIntegration=()=>{
    setLoading(true);
    Api.put(ApiEndpoints.IntegrationPartner, formData).then(
      (response) => {
        if(response && response?.status === 200){
          let result =  response.data;
          mapIntegrationdata(result);
          openNotificationWithIcon(
            "Success",
            "Integration Completed.",
            eqNotificationTypes.success
          );
        }
        
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        openNotificationWithIcon(
          "Error",
          error?.response?.data ? error?.response?.data:"Error occurred while integrating. Please try again or contact administrator."
        );
      }
    );
  }

  useEffect(()=>{
    if(Object.keys(integrationData).length === 0){
      setFormData(initialFormData);
    }else{ 
      mapIntegrationdata(integrationData);
    }
  },[integrationData])

  const mapIntegrationdata=(info:any)=>{
    let data = {
      id:info.id,
      type:info.type,
      employeeToken:info.employeeToken,
      tripletexToken:info.type === EqIntegrationsType.TripleTex ? info.employeeToken:"",
      powerOfficToken:info.type === EqIntegrationsType.PowerOfficeGo ? info.employeeToken:"",
    }
    setFormData({...data});
  }
  return (
    <>
      <Spin spinning={loading}>
      <div className="integration-section">
        {/* For Tripletex Integration */}
        <Row>
          <Col className="integration-selection" span={8}>
            <h3 className="section-heading">
              {t("tripletex")} Api Key
            </h3>
            <Switch
              className="switch-input"
              defaultChecked={formData.type === EqIntegrationsType.TripleTex ? true:false}
              checked={formData.type === EqIntegrationsType.TripleTex ? true:false}
              onChange={(checked: any) =>
                setFormData({
                  ...formData,
                  type:checked? EqIntegrationsType.TripleTex:EqIntegrationsType.None,
                  employeeToken:checked?formData.tripletexToken:formData.employeeToken
                })
              }
            />
          </Col>
        </Row>
        <Row>
        </Row>
        <Row className="key-row">
          <Col span={8}>
            <EqInput
              label={t("employeKey")}
              className="profile-input"
              value={formData.tripletexToken}
              disabled={formData.type === EqIntegrationsType.TripleTex ? false:true}
              onChange={onEmployeKeyChange}
            ></EqInput>
          </Col>
        </Row>
        {/* For PowerOfficeGo Integration */}
         <Row>
          <Col className="integration-selection" span={8}>
            <h3 className="section-heading">
              {t("powerOfficeGo")} Api Key
            </h3>
            <Switch
              className="switch-input"
              defaultChecked={formData.type === EqIntegrationsType.PowerOfficeGo ? true:false}
              checked={formData.type === EqIntegrationsType.PowerOfficeGo ? true:false}
              onChange={(checked: any) =>
                setFormData({
                  ...formData,
                  type:checked? EqIntegrationsType.PowerOfficeGo:EqIntegrationsType.None,
                  employeeToken:checked?formData.powerOfficToken:formData.employeeToken
                })
              }
            />
          </Col>
        </Row> 
        <Row>
          <Col span={8}>
            <EqInput
              label={t("clientKey")}
              className="profile-input"
              value={formData.powerOfficToken}
              disabled={formData.type === EqIntegrationsType.PowerOfficeGo ? false:true}
              onChange={onClientKeyChange}
            ></EqInput>
          </Col>
        </Row>
        <Row>
          <Col className="submitButton">
            <EqButton
              type="button"
              onClick={integrateUserAccount}
              classNames="btn-background-purple"
            >
              {t("integrate")}
            </EqButton>
          </Col>
        </Row>
       
        
      </div>
      </Spin>
    </>
  );
};

export default EqIntegrationSection;
