import React, { useState } from "react";
import "./EqProductCard.scss";
import AsyncImage from "../asyncImage/AsyncImage";
import { Card, Col, Modal, notification, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EqButton from "../eqButton/EqButton";
import EqSelect from "../eqSelect/EqSelect";
import {
  EqCurrency,
  eqNotificationTypes,
  EqProfessionType,
  ProductOrderStatus,
  ProductType,
} from "../../shared/enums";
import { ISelectProps } from "../../shared/Interfaces";
import Api from "../../shared/Api";
import {
  ApiEndpoints,
  FrontEndPoints,
  getEmailFromToken,
  GetEventMessage,
  openNotificationWithIcon,
  ValidateIsSchool,
  ValidateIsStudent,
  ValidateIsTrainer,
  ValidateIsUser,
} from "../../shared/Common";
import { useAuthState } from "../../context/Context";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// const { Meta } = Card;
import { useTranslation } from "react-i18next";
import EqProductPaymentPopup from "../eqProductPaymentPopup/EqProductPaymentPopup";
import { EqIconUser } from "../eqIcon/EqIcons";

export interface IEqProductCard {
  product: any;
  removeProduct?: any;
  editProduct?: any;
  studentsList?: any;
  studentsAndGroups?: any;
  onProductSend?: any;
}

const EqProductCard = ({
  product,
  removeProduct,
  editProduct,
  studentsList,
  studentsAndGroups,
  onProductSend,
}: IEqProductCard) => {
  const userDetails: any = useAuthState();
  const isLoggedInUser: boolean = ValidateIsUser(userDetails);
  const currentUserEmail = getEmailFromToken();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const isStudent: boolean = ValidateIsStudent(userDetails);
  const isSchool: boolean = ValidateIsSchool(userDetails);
  const isOwner: boolean = currentUserEmail === product.user.email;

  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  
  const [isProductSelected, setIsProductSelected] = React.useState(false);
  const [selectedStudent, setSelectedStudent] = React.useState({
    selectedKeys: [],
    selectedOptions: [],
  }) as any;
  const [studentPopulation, setStudentPopulation] = React.useState({
    allStudents: [] as any,
    filteredStudents: [] as any,
  });

  const [isPaymentPopupVisible, setIsPaymentPopupVisible] =
    React.useState(false);

  const removeCard = (item: any) => {
    if(item.isUsed){
      openNotificationWithIcon(
        "Error!",
        "Product is already Used in Training",
        eqNotificationTypes.error
      );
      return;
    }
    removeProduct(item);
  };
  const editCard = (item: any) => {
    if(item.isUsed){
      openNotificationWithIcon(
        "Error!",
        "Product is already Used in Training",
        eqNotificationTypes.error
      );
      return;
    }
    editProduct(item);
  };

  const CreateCheckoutSession = (orderId: any) => {
    Api.get(
      ApiEndpoints.ProductPayment.GetProductOrderCheckoutSession + `/${orderId}`
    )
      .then((response) => {
        window.location.href = response.data.sessionUrl;
      })
      .catch((error) => {
        openNotificationWithIcon(
          "Error!",
          "Unable to Create checkout Session",
          eqNotificationTypes.error
        );
      });
  };

  const openCheckOutNotificationProduct = (
    msg: any,
    desc: any,
    orderId: any
  ) => {
    const btn = (
      <div className="right">
        <EqButton onClick={() => CreateCheckoutSession(orderId)}>
          Pay Now
        </EqButton>
      </div>
    );
    notification["info"]({
      message: msg,
      btn,
      description: desc,
    });
  };

  const handlePayNow = (formData: any) => {
    
    // Perform the API call using the form data
    const apiData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      productId: product.id, // Replace with your actual product ID retrieval logic
    };

    // Make your API call here
    // Example using axios:
    Api.post(
      ApiEndpoints.ProductOrderBuySchoolPublicProductWithCheckout,
      apiData
    )
      .then((response) => {
        // Handle successful response, e.g., redirect to success page
        window.location.href = response.headers.location;
      })
      .catch((error) => {
        // Handle error, show notification, etc.
        
        console.error("API call failed:", error);
        if (error?.response?.status === 409) {
          openNotificationWithIcon(
            "Server error",
            "order is already available."
          );
          setIsError(true);
        } else {
          openNotificationWithIcon(
            "Server error",
            "Error occurred while creating the Product Order. Please try again or contact administrator."
          );
          setIsError(true);
        }
      });
  };

  const BuyProduct = () => {
    
    if (ValidateIsUser(userDetails)) {
      const currentUserKey = getEmailFromToken();

      // place order
      const formData = {
        studentEmail: currentUserKey,
        productId: product.id,
        status: ProductOrderStatus.Accepted,
      };
      let path = ApiEndpoints.ProductOrder;
      if (
        product.type === ProductType.Public &&
        product.user.professionType == EqProfessionType.RidingSchool
      ) {
        path = ApiEndpoints.ProductOrderBuySchoolPublicProduct;
      }
setButtonLoading(true)
      Api.post(path, formData).then(
        (response) => {
          if (response && response.status == 200) {
            openCheckOutNotificationProduct(
              "Pay for the accepted order request",
              "",
              response.data.id
            );

            // openNotificationWithIcon(
            //   "Created!",
            //   "Product Order have been created",
            //   eqNotificationTypes.success
            // );
            setIsError(false);
            setMessage(GetEventMessage(response.status));
            setIsProductSelected(false);
            setSelectedStudent("");
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the event(s). Please try again or contact administrator."
            );
            setIsError(true);
          }
setButtonLoading(false)

        },
        (error) => {
setButtonLoading(false)

          if (error?.response?.status === 409) {
            openNotificationWithIcon(
              "Server error",
              "order is already available."
            );
            setIsError(true);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the Product Order. Please try again or contact administrator."
            );
            setIsError(true);
          }
        }
      );
    } else {
      // navigate(FrontEndPoints.login);
      setIsPaymentPopupVisible(true);
    }
  };



  const RequestProduct = () => {
    
    if (ValidateIsUser(userDetails)) {
      const currentUserKey = getEmailFromToken();

      // place order
      const formData = {
        studentEmail: currentUserKey,
        productId: product.id,
        status: ProductOrderStatus.Requested,
      };
      let path = ApiEndpoints.StudentProduct.RequestPublicProduct;
      setButtonLoading(true)

      Api.post(path, formData).then(
        (response) => {
          if (response && response.status === 200) {
           
            openNotificationWithIcon(
              "Requested!",
              "Product Request is Sent",
              eqNotificationTypes.success
            );
            setIsError(false);
            setMessage(GetEventMessage(response.status));
          
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the event(s). Please try again or contact administrator."
            );
            setIsError(true);
          }
      setButtonLoading(false)

        },
        (error) => {
      setButtonLoading(false)

          if (error?.response?.status === 409) {
            openNotificationWithIcon(
              "Server error",
              "order is already available."
            );
            setIsError(true);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the Product Order. Please try again or contact administrator."
            );
            setIsError(true);
          }
        }
      );
    } else {
      navigate(FrontEndPoints.login);
      // setIsPaymentPopupVisible(true);
    }
  };

  // old was to send invite to single user
  // const OnProductSend = () => {
  //   const formData = {
  //     studentEmail: selectedStudent,
  //     productId: product.id,
  //     status: ProductOrderStatus.Pending,
  //   };

  //   Api.post(ApiEndpoints.ProductOrder, formData).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         openNotificationWithIcon(
  //           "Sent!",
  //           "Product order has been sent to student Student will accept or reject your order ",
  //           eqNotificationTypes.success
  //         );
  //         setIsError(false);
  //         setMessage(GetEventMessage(response.status));

  //         setSelectedStudent("");
  //         setIsProductSelected(false);
  //         onProductSend(response.data);

  //       } else {
  //         openNotificationWithIcon(
  //           "Server error",
  //           "Error occurred while creating the event(s). Please try again or contact administrator."
  //         );
  //         setIsError(true);
  //       }
  //     },
  //     (error) => {
  //       if (error?.response?.status === 403) {
  //         openNotificationWithIcon(
  //           "Server error",
  //           "Not Authorized Kindly contact administrator."
  //         );
  //         setIsError(true);
  //       } else {
  //         openNotificationWithIcon(
  //           "Server error",
  //           "Error occurred while creating the Product Order. Please try again or contact administrator."
  //         );
  //         setIsError(true);
  //       }
  //     }
  //   );
  // };

  const OnProductSendToStudentsAndGroups = () => {
    const formData = {
      productId: product.id,
      SelectedOptions: selectedStudent.selectedOptions,
    };
    setConfirmLoading(true);
    Api.post(ApiEndpoints.ProductOrderCreateForStudentAndGroups, formData).then(
      (response) => {
        if (response && response.status == 200) {
          openNotificationWithIcon(
            "Sent!",
            "Product orders has been sent to students, Student will accept or reject your order ",
            eqNotificationTypes.success
          );
          setIsError(false);
          setMessage(GetEventMessage(response.status));

          setSelectedStudent("");
          setIsProductSelected(false);
          onProductSend(response.data);
        } else {
          openNotificationWithIcon(
            "Server error",
            "Error occurred while creating Invites. Please try again or contact administrator."
          );
          setIsError(true);
        }
        setConfirmLoading(false);

      },
      (error) => {
        setConfirmLoading(false);

        if (error?.response?.status === 403) {
          openNotificationWithIcon(
            "Server error",
            "Not Authorized Kindly contact administrator."
          );
          setIsError(true);
        } else {
          openNotificationWithIcon(
            "Server error",
            "Error occurred while creating the Product Order. Please try again or contact administrator."
          );
          setIsError(true);
        }
      }
    );
  };

  const getStudentPopulation = () => {
    if (!studentsList) {
      return;
    }

    const students = studentsList.map((student: any) => {
      const name = student.user
        ? `${student.user.firstName || student.user.userName} ${
            student.user.lastName || ""
          } - ${student.user.email || ""}`
        : "";

      return {
        key: student.key,
        value: name,
        label: name,
      };
    });

    setStudentPopulation({
      ...studentPopulation,
      allStudents: students,
    });
  };

  const studentSelectProps: ISelectProps = {
    mode: "multiple", // multiSelect
    showArrow: true,
    allowClear: false,
    showSearch: true, // to show search Icon
    filterOption: true, // To Allow Filter on Search
    optionFilterProp: "label", // Attribute to Search

    // Class For MultiSelect
    classNames: `eq-multiSelect eq-searchSelect extra-height `,
    ddClassName: "eq-select-dropdown",

    isTag: false, // if is tag is true
    // in options we get Label
    // and on selected get keys list

    // changeFunc: onSearchChange,
    changeFunc: (selectedKeys: any) => {
      var selectedObjects = getSelectedValues(selectedKeys);
      setSelectedStudent({
        keys: selectedKeys,
        selectedOptions: selectedObjects,
      });
    },
    options: studentsAndGroups,
    // options: studentPopulation.allStudents,
    defaultSelectedValue: selectedStudent.keys,
  };

  const getSelectedValues = (selectedKeys: any) => {
    return selectedKeys.map((key: any) => {
      const selectedOption = studentsAndGroups.find(
        (option: any) => option.key === key
      );
      return selectedOption ? selectedOption : null;
    });
  };
  React.useEffect(() => {
    // console.log("---ProductCard---");
    // console.log("Location",location);
    // console.log("product",product);
    // getStudentPopulation();
  }, [product, studentsList]);


const getProductAvailableSpace=(product: any)=>{
  
 if(isStudent){
  return product.availableSpace
 }

  let isRidingSchool= product.user.professionType === EqProfessionType.RidingSchool;
  let orders = [];
  if (isRidingSchool && product?.productOrders) {
    orders = product?.productOrders.filter(
      (entry: any) =>
        entry.status === ProductOrderStatus.Accepted ||
        entry.status === ProductOrderStatus.Paid
    );
  }
  return product.limit-orders.length
} 

  const showActions = () => {
    
    if (isOwner) {
      if (isSchool && location.pathname === "/school/productBoard") {
        if (product.type === ProductType.Public) {
          
          //product order check could be added to stop sending products after limit exceeds
          return [
            <DeleteOutlined disabled={product.isUsed} key="delete" onClick={() => removeCard(product)} />,
            <EditOutlined key="edit" onClick={() => editCard(product)} />,
            <EqButton
              key="sendProduct"
              classNames="custom-action-send"
              onClick={() => setIsProductSelected(true)}
            >
              {t("send")}
            </EqButton>,
            
          ];
        } else if (product.type === ProductType.Private) {
          return [
            <DeleteOutlined key="delete" onClick={() => removeCard(product)} />,
            <EditOutlined key="edit" onClick={() => editCard(product)} />,
            <EqButton
              key="sendProduct"
              classNames="custom-action-send"
              onClick={() => setIsProductSelected(true)}
            >
              {t("send")}
            </EqButton>,
            
          ];
        }
      } else if (isTrainer && location.pathname === "/trainer/productBoard") {
        if (product.type === ProductType.Public) {
          return [
            <DeleteOutlined key="delete" onClick={() => removeCard(product)} />,
            <EditOutlined key="edit" onClick={() => editCard(product)} />,
          ];
        } else if (
          product.type === ProductType.Private &&
          studentsAndGroups.length > 0
        ) {
          return [
            <DeleteOutlined key="delete" onClick={() => removeCard(product)} />,
            <EditOutlined key="edit" onClick={() => editCard(product)} />,
            <EqButton
              key="sendProduct"
              classNames="custom-action-send"
              onClick={() => setIsProductSelected(true)}
            >
              {t("send")}
            </EqButton>,
            
          ];
        }
      }
    } else if (isStudent || !isLoggedInUser) {
      
      let isRidingSchool =
        product.user.professionType === EqProfessionType.RidingSchool;
      let orders = [];
      if (isRidingSchool && product?.productOrders) {
        orders = product?.productOrders.filter(
          (entry: any) =>
            entry.status === ProductOrderStatus.Accepted ||
            entry.status === ProductOrderStatus.Paid
        );
      }

      if (
        isRidingSchool &&
        product.type === ProductType.Public &&
        orders.length >= product.limit
      ) {
        return [
          <EqButton key="buy" classNames="custom-action-buy" disabled>
            {t("full")}
          </EqButton>,
        ];
      } 
      else if(product.type === ProductType.Public && product.isRequestRequired){
        return [
          <EqButton 
          loading={buttonLoading}

          key="request" 
          classNames="custom-action-buy" 
          onClick={RequestProduct}
          >
            {t("Request")}
          </EqButton>,
        ];
      }
      else {

        return [
          <EqButton
            loading={buttonLoading}
            key="buy"
            classNames="custom-action-buy"
            onClick={BuyProduct}
          >
            {t("buy")}
          </EqButton>,
        ];
      }
    }

    return [];
  };

  return (
    <>
      <Card
        className="custom-product-card product-view"
        actions={showActions()}
        // <EditOutlined />,
        // <BellOutlined />
        //  ]}
        // key={index}
        hoverable
        style={{ width: 350 }}
      >
        <Meta
          description={
            <>
              <Row className="">
                <Col>
                  <AsyncImage
                    className="product-img"
                    alt={product.alt}
                    src={product.imageUrl}
                  />
                </Col>
              </Row>
              <div className="product-detail">
                <Row className="product-heading">
                  <Col className="product-title">{product.title}</Col>
                </Row>
               {isStudent &&
                <Row className="product-user">
                  <Col className="icon">
                    <EqIconUser
                      eqWidth="16"
                      eqHeight="16"
                      eqFill="#b5b5b5"
                      />
                  </Col>
                    <span className="user-text">
                      {product.user.firstName +" "+ product.user.lastName}
                    </span>
                </Row>
              }
                <Row className="product-description">
                  <Col>
                    <div className="description-text">
                      {product.description}
                    </div>
                  </Col>
                </Row>
                {product.limit !== null && (
                <Row justify="space-between">
                    <Col className="product-info">
                      {t("capacity")}: {product.limit}
                    </Col>

                    <Col className="product-info">
                      {t("Available")}: {getProductAvailableSpace(product)}
                      {/* {t("Available")}: {product.availableSpace} */}
                    </Col>
                </Row>
                  )}

                
                <Row justify="space-between">
                 
                  {product.lessons !== null && (
                    <Col className="product-info">
                      {t("lessons")}: {product.lessons}
                    </Col>
                  )}
                  <Col className="product-info">
                    {t("price")}: {product.price} {EqCurrency[product.currency]}
                  </Col>
                </Row>
              </div>
            </>
          }
        />

        {/* <Meta className="card-decs" description={"Price: " + product.price + " Kr."} /> */}

        {/* <div className="time">{item.date}</div> */}
      </Card>
      <Modal
      confirmLoading={confirmLoading}
        title="Select User"
        open={isProductSelected}
        // onOk={OnProductSend}
        onOk={OnProductSendToStudentsAndGroups}
        onCancel={() => {
          setIsProductSelected(false);
          setSelectedStudent("");
        }}
      >
        <EqSelect {...studentSelectProps}></EqSelect>
      </Modal>

      <EqProductPaymentPopup
        visible={isPaymentPopupVisible}
        onCancel={() => setIsPaymentPopupVisible(false)}
        onPayNow={(values) => {
          // Handle payment logic using the form values
          handlePayNow(values);
          
          console.log("Payment details:", values);
          setIsPaymentPopupVisible(false);
        }}
        product={product}
      />
    </>
  );
};

export default EqProductCard;
