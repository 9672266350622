import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n/config'

import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './components/context/Context';
// import { AxiosInterceptor } from './components/shared/Api';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
//<React.StrictMode>
root.render(
    <AuthProvider>
        {/* <AxiosInterceptor> */}
        <App />
        {/* </AxiosInterceptor> */}
    </AuthProvider>
);
//</React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();