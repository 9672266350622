import React, { useEffect } from 'react'
import { faCalendarDays, faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { FrontEndPoints } from '../../shared/Common';
import AsyncImage from '../../custom/asyncImage/AsyncImage';
import EqButton from '../../custom/eqButton/EqButton';
import { Card, Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckCircleOutlined } from '@ant-design/icons';
import EqReview from '../../custom/eqReview/EqReview';
import "./EqFeatureTrainer.scss"
import { motion } from 'framer-motion';
import Reveal from '../../custom/Reveal/Reveal';
const EqFeatureTrainer = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const gutter = { xs: 8, sm: 16, md: 24, lg: 32 };

    const communication = [
        {
            id: 1,
            text: t("everythingInOnePlace"),
        },
        {
            id: 2,
            text: t("updatedListOfYourContacts"),
        },
        {
            id: 3,
            text: t("updateTheirContactInformation"),
        },
    ]

    const administratingServices = [
        {
            id: 1,
            text: t("sellProductsOrSendProducts"),
        },
        {
            id: 2,
            text: t("clientsCanBuyProductsOnYourProfile"),
        },
        {
            id: 3,
            text: t("sendProductsToSelectedStudents"),
        },
        {
            id: 4,
            text: t("studentsPayDirectlyInApp"),
        },
        {
            id: 5,
            text: t("getOverviewOfEverything"),
        },
    ]

    const organizing = [
        {
            id: 1,
            text: t("setUpLessonsForWeeksAtTime"),
        },
        {
            id: 2,
            text: t("sellMultipleLessons"),
        },
        {
            id: 3,
            text: t("setUpLessonsFarAheadWhichCanBeChanged"),
        },
        {
            id: 4,
            text: t("studentsAreInvitedToOneOrMoreLessons"),
        },
        {
            id: 5,
            text: t("seeWhereYourLessonsAreLocated"),
        },

    ]

    const administratingFinances = [
        {
            id: 1,
            text: t("takesCareOfPayments"),
        },
        {
            id: 2,
            text: t("youNoLongerHaveToLook"),
        },
        {
            id: 3,
            text: t("overviewOfAllFinances"),
        },
        {
            id: 4,
            text: t("sendingIndividualInvoice"),
        },
    ]
    const managing = [
        {
            id: 1,
            text: t("systemGiveYouCompleteOverview"),
        },
        {
            id: 2,
            text: t("manageHorsesThroughHorseManagementApp"),
        },
        {
            id: 3,
            text: t("addTrainingsYourCalendar"),
        },
        {
            id: 4,
            text: t("saveInformationAboutYourHorses"),
        },
        {
            id: 5,
            text: t("overviewOfAllYourHorsesTask"),
        },
    ]
    const administratingEvents = [
        {
            id: 1,
            text: t("everyoneCanRegister"),
        },
        {
            id: 2,
            text: t("createYourOwnEvents"),
        },
        {
            id: 3,
            text: t("getAutomaticallyGeneratedStartingList"),
        },
        {
            id: 4,
            text: t("easyForPeopleToSignUp"),
        },
        {
            id: 5,
            text: t("createYourOwnInformationInputFields"),
        },
    ]

    const featuresData = [
        {
            icon: faCalendarDays,
            heading: t("optimizeYourBusiness"),
            description: t("optimizeYourBusinessInformation")
        },
        {
            icon: faChartSimple,
            heading: t("saveTime"),
            description: t("saveTimeInformation"),
        },

    ];

    const callRegister = () => {
        navigate(FrontEndPoints.register);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "100%", opacity: 1, transition: { duration: 0.5, ease: 'easeInOut' } }}
                exit={{ height: 0, opacity: 0, transition: { duration: 0.5, ease: 'easeInOut' } }}
            >
                <div className='eq-types-features eq-trainer-features'>

                    <div className="page-banner">
                        <AsyncImage className="hero-image" src="feature/trainerBanner.png" />
                        <div className="overlay">
                            <h1 className="welcome-heading">
                                {t("fasterSmootherBooking")}
                                <br />
                                {t("andAdministration")}
                            </h1>

                            <div className="create-profile-btn">
                                <EqButton
                                    type="button"
                                    centered="true"
                                    onClick={callRegister}
                                >
                                    {t("getStarted")}
                                </EqButton>
                            </div>
                        </div>
                    </div>

                    <div className='features-section'>
                        <Reveal>
                            <div className='feature-sub-section'>
                                <div className='feature-img-section'>
                                    <AsyncImage className="feature-img feature-img-1" src="feature/Ellipse1.png" />
                                    <AsyncImage className="feature-img feature-img-2" src="feature/Ellipse2.png" />
                                    <AsyncImage className="feature-img feature-img-3" src="feature/Ellipse3.png" />
                                    <AsyncImage className="feature-img feature-img-4" src="feature/Ellipse4.png" />
                                    <AsyncImage className="feature-img feature-img-5" src="feature/Ellipse5.png" />
                                    <AsyncImage className="feature-img feature-img-6" src="feature/Ellipse6.png" />
                                </div>

                                <div className='basic-feature'>
                                    <h3 className="heading">{t("whyChooseUs")}</h3>
                                    {/* <Row  className='feature-container'> */}
                                    <p className='heading-text'>{t("spendLessTimeInOffice")}
                                        <br />
                                        {t("lessAdministrationMoreHorsing")}
                                        <AsyncImage className="emoji" src="feature/25518.jpg" />
                                    </p>
                                    <Row className='feature-row' gutter={[gutter, gutter]}>
                                        {featuresData.map((feature, index) => (

                                            <Col className='feature-col' md={12} lg={12} sm={24}>
                                                <Card
                                                    key={index}
                                                    // hoverable
                                                    className="basic-content"
                                                // style={{ width: '100%' }}
                                                >
                                                    <Col>
                                                        <FontAwesomeIcon className="basic-icon" icon={feature.icon} />
                                                        <h4 className="content-heading">{feature.heading}</h4>
                                                        <p className='content-text'>{feature.description}</p>
                                                    </Col>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </Reveal>
                    </div>
                    <div className='offer-section'>
                        <h3 className="heading">{t("ourSolution")}</h3>
                        <p className='heading-text'>{t("saveTimeAndMoneyForYourBusinessForYourStudents")}</p>
                        <div className="offer-block">
                            <div className="container">
                                <Reveal>
                                    <Row className="offer-col offer-right-col">
                                        <Col className="offer-image" sm={24} md={10}>
                                            <AsyncImage className="image" src="feature/Communication.png" />

                                        </Col>
                                        <Col className="offer-content" sm={24} md={12}>
                                            <div className="offer-desc">
                                                <h4 className="sub-heading">
                                                    {t("communication")}
                                                </h4>
                                                <p className='sub-text'>
                                                    {t("communicationInfo")}
                                                </p>

                                                <div className='sub-text-points'>
                                                    <ul>
                                                        {communication.map(event => (
                                                            <li key={event.id}>
                                                                <div >
                                                                    <CheckCircleOutlined className='icon' />
                                                                </div>
                                                                <span className="event-heading">{event.text}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Reveal>
                                <Reveal>
                                    <Row className="offer-col offer-left-col">

                                        <Col className="offer-content" sm={24} md={12}>
                                            <div className="offer-desc">
                                                <h4 className="sub-heading">
                                                    {t("administratingYourServices")}
                                                </h4>

                                                <p className='sub-text'>
                                                    {t("administratingYourServicesInfo")}
                                                </p>

                                                <div className='sub-text-points'>
                                                    <ul>
                                                        {administratingServices.map(event => (
                                                            <li key={event.id}>
                                                                <div >
                                                                    <CheckCircleOutlined className='icon' />
                                                                </div>
                                                                <span className="event-heading">{event.text}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="offer-image" sm={24} md={10}>
                                            <AsyncImage className="image" src="feature/Administrating.png" />
                                        </Col>
                                    </Row>
                                </Reveal>
                                <Reveal>
                                    <Row className="offer-col offer-right-col">
                                        <Col className="offer-image" sm={24} md={10}>
                                            <AsyncImage className="image" src="feature/Organizing.png" />

                                        </Col>
                                        <Col className="offer-content" sm={24} md={12}>
                                            <div className="offer-desc">
                                                <h4 className="sub-heading">
                                                    {t("organizingLessons")}
                                                </h4>

                                                <p className='sub-text'>
                                                    {t("organizingLessonsInfoTrainer")}
                                                </p>

                                                <div className='sub-text-points'>
                                                    <ul>
                                                        {organizing.map(event => (
                                                            <li key={event.id}>
                                                                <div >
                                                                    <CheckCircleOutlined className='icon' />
                                                                </div>
                                                                <span className="event-heading">{event.text}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Reveal>
                                <Reveal>
                                    <Row className="offer-col offer-left-col">

                                        <Col className="offer-content" sm={24} md={12}>
                                            <div className="offer-desc">
                                                <h4 className="sub-heading">
                                                    {t("administratingFinances")}
                                                </h4>

                                                <p className='sub-text'>
                                                    {t("administratingFinancesInfo")}
                                                </p>

                                                <div className='sub-text-points'>
                                                    <ul>
                                                        {administratingFinances.map(event => (
                                                            <li key={event.id}>
                                                                <div >
                                                                    <CheckCircleOutlined className='icon' />
                                                                </div>
                                                                <span className="event-heading">{event.text}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="offer-image" sm={24} md={10}>
                                            <AsyncImage className="image" src="feature/AdministratingFinances.png" />
                                        </Col>
                                    </Row>
                                </Reveal>
                                <Reveal>
                                    <Row className="offer-col offer-right-col">
                                        <Col className="offer-image" sm={24} md={10}>
                                            <AsyncImage className="image" src="feature/Managing.png" />

                                        </Col>
                                        <Col className="offer-content" sm={24} md={12}>
                                            <div className="offer-desc">
                                                <h4 className="sub-heading">
                                                    {t("managingYourRidingSchoolHorses")}
                                                </h4>

                                                <p className='sub-text'>
                                                    {t("managingYourRidingSchoolHorsesInfo")}
                                                </p>

                                                <div className='sub-text-points'>
                                                    <ul>
                                                        {managing.map(event => (
                                                            <li key={event.id}>
                                                                <div >
                                                                    <CheckCircleOutlined className='icon' />
                                                                </div>
                                                                <span className="event-heading">{event.text}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Reveal>
                                <Reveal>
                                    <Row className="offer-col offer-left-col">

                                        <Col className="offer-content" sm={24} md={12}>
                                            <div className="offer-desc">
                                                <h4 className="sub-heading">
                                                    {t("administratingEvents")}
                                                </h4>

                                                <p className='sub-text'>
                                                    {t("administratingEventsInfo")}
                                                </p>

                                                <div className='sub-text-points'>
                                                    <ul>
                                                        {administratingEvents.map(event => (
                                                            <li key={event.id}>
                                                                <div >
                                                                    <CheckCircleOutlined className='icon' />
                                                                </div>
                                                                <span className="event-heading">{event.text}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="offer-image" sm={24} md={10}>
                                            <AsyncImage className="image" src="feature/AdministratingEvents.png" />
                                        </Col>
                                    </Row>
                                </Reveal>
                            </div>
                        </div>
                    </div>
                    <EqReview />

                </div>
            </motion.div>
        </>
    )
}

export default EqFeatureTrainer