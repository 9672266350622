
import { useEffect, useState } from 'react';
import * as React from 'react';
import "./EqCategory.scss";
import AsyncImage from '../custom/asyncImage/AsyncImage';
import { ArrowUpOutlined } from '@ant-design/icons';
export interface IEqCategory {
    imageName: string;
    iconName: string;
    caption: string;
    name: string;
    categoryClicked: (categoryName: string) => void;
}
const EqCategory = ({ imageName, iconName, caption, name, categoryClicked }: IEqCategory) => {
    const [categoryImage, setCategoryImage] = React.useState([]) as any;
    const [categoryIcon, setCategoryIcon] = React.useState([]) as any;
    useEffect(() => {
        var catImage = new Image();
        catImage.src = imageName;
        setCategoryImage(catImage);
        var iImage = new Image();
        iImage.src = iconName;
        setCategoryIcon(iImage);
    }, [imageName, iconName]);
    return (
        <div className="eq-category" onClick={() => categoryClicked(name)}>
            <AsyncImage src={categoryImage.src} className="category-image" alt="Trainer" />
            <div className="overlay">
                <div className="caption">
                    <span>{caption}</span>
                    <span><ArrowUpOutlined /></span>
                </div>
            </div>
            {/* <div className="overlay">
                <AsyncImage src={categoryIcon.src} className="category-icon" alt="Trainer" />
                <div className="caption">{caption}</div>
            </div> */}

        </div>
    );
}
export default EqCategory;



