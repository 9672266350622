import { useState } from "react";
import * as React from "react";
import "./EqTrainerEvent.scss";
import { Row, Col, Card, Popconfirm, Skeleton, Select, DatePicker } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsTrainer,
  ValidateIsUser,
  FrontEndPoints,
  ValidateIsStudent,
  GetHomeMessage,
  dateFormat,
  getDefaultImage,
  imageType,
} from "../../shared/Common";
import Api from "../../shared/Api";
import { useTranslation, Trans } from "react-i18next";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import EqCreateOrEditPublicEvent from "../eqCreateOrEditPublicEvent/EqCreateOrEditPublicEvent";
import EqEventGrid from "../eqEventGrid/EqEventGrid";
import EqStudentEventGrid from "../eqStudentEventGrid/EqStudentEventGrid";

import { EqDisciplineType } from "../../shared/enums";
import moment from "moment";
import { aS } from "@fullcalendar/core/internal-common";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";

const EqTrainerEvent = (props: any) => {
  const userDetails: any = useAuthState();
  const isStudent = ValidateIsStudent(userDetails);
  const isUserValid: boolean = ValidateIsUser(userDetails);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [trainers, setTrainers] = React.useState([] as any);
  const [isSuccess, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [toggle, setToggle] = React.useState(false);
  const [areas, setAreas] = React.useState([]) as any;
  const [imagesData, setImagesData] = React.useState([]) as any;
  const [population, setPopulation] = React.useState([]) as any;

  const [showEventList, setShowEventList] = React.useState(true);
  // const [CreatePublicEventShow, setCreatePublicEventShow] = React.useState(false);
  const [CreatePublicEventList, setCreatePublicEventList] = React.useState(
    [] as Array<any>
  );
  const [loadMore, setLoadMore] = React.useState(true) as any;
  const [loading, setLoading] = React.useState(false); // Add this state

  const [eventsList, setEventsList] = React.useState(null) as any;
  const [eventsResult, setEventsResult] = React.useState({
    resultCount: 0,
    monthCount: 2,
  }) as any;
  const location = useLocation();

  // filter

  const [dates, setDates] = React.useState([]) as any;
  const [value, setValue] = React.useState() as any;
  const [isRangeDate, setIsRangeDate] = React.useState(true);
  const [events, setEvents] = React.useState({
    allEvents: null,
    filteredEvents: null,
  } as any);

  const marks = [
    { key: 0, value: "Near" },
    { key: 1, value: "20KM" },
    { key: 2, value: "40KM" },
    { key: 3, value: "60KM" },
    { key: 3, value: "All" },
  ];
  const [radiusValues, setRadiusValues] = React.useState<string | null>(null);
  const [startDate, setStartDate] = useState(null) as any;
  const [endDate, setEndDate] = useState(null) as any;
  const handleRadius = (value: string) => {
    setRadiusValues(value);
  };

  const [selectedDiscipline, setSelectedDiscipline] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [disciplines, setDisciplines] = React.useState({
    allDisciplines: [],
    selectedDiscipline: [],
  } as any);

  const disciplineClicked = (value: any) => {
    if (value.includes("All")) {
      setSelectedDiscipline(["All"] as any);
      setIsAllSelected(true);
    } else {

      setSelectedDiscipline(value);
      setIsAllSelected(false);
    }

    updateEventsFilter(
      value.includes("All") ? [] : value.filter((item: any) => item !== "All"),
      locations.selectedLocations,
      dates,
      isRangeDate
    );
  };




  const [locations, setLocations] = React.useState({
    allLocations: [] as Location[],
    selectedLocations: [] as number[],
  });

  const handleChange = (value: any) => {
    setLocations({ ...locations, selectedLocations: value });
    updateEventsFilter(
      disciplines.selectedDiscipline,
      value,
      dates,
      isRangeDate
    );
  };

  // Handler for start date change
  const onStartDateChange = (date: any | null) => {
    setStartDate(date);
    updateEventsFilter(
      disciplines.selectedDiscipline,
      locations.selectedLocations,
      [date, endDate],
      true // Always in range date mode
    );
  };

  // Handler for end date change
  const onEndDateChange = (date: any | null) => {
    setEndDate(date);
    updateEventsFilter(
      disciplines.selectedDiscipline,
      locations.selectedLocations,
      [startDate, date],
      true // Always in range date mode
    );
  };

  const updateEventsFilter = (
    disciplineFilter: string[],
    locationFilter: any,
    dates: any,
    isRange: boolean
  ) => {
    var filterResult = [] as any;
    //dicipline
    if (Array.isArray(disciplineFilter) && disciplineFilter.length > 0) {
      var result = [] as any;

      if (filterResult?.length > 0) {
        filterResult?.filter((item: any) => {
          if (disciplineFilter.includes(EqDisciplineType[item.disciplineType])) {
            result.push(item);
          }
        });
      } else {
        events.allEvents?.filter((item: any) => {
          if (disciplineFilter.includes(EqDisciplineType[item.disciplineType])) {
            result.push(item);
          }
        });
      }

      filterResult = result;
    } else {

      filterResult = events.allEvents;
    }
    //location
    if (locationFilter != undefined && locationFilter.length > 0) {
      var result = [] as any;

      if (filterResult?.length > 0) {
        filterResult?.filter((item: any) => {
          if (
            locationFilter?.some((loc: any) => {
              return loc == item.locationKey;
            })
          ) {
            result.push(item);
          }
        });
      } else {
        events.allEvents?.filter((item: any) => {
          if (
            locationFilter?.some((loc: any) => {
              return loc == item.locationKey;
            })
          ) {
            result.push(item);
          }
        });
      }

      filterResult = result;
    }

    var dateResult = [] as any;

    if (isRange && dates && dates[0] != null && dates[1] != null) {
      dates[0].startOf("day");
      var startTime = dates[0].valueOf();
      dates[1].endOf("day");
      var endTime = dates[1].valueOf();

      filterResult?.filter((item: any) => {
        if (item.startDate > startTime && item.endDate < endTime) {
          dateResult.push(item);
        }
      });

      filterResult = dateResult;
    } else if (isRange == false && dates[0] != null) {
      dates[0].startOf("day");
      var startTime = dates[0].valueOf();

      filterResult?.filter((item: any) => {
        if (item.startDate > startTime) {
          dateResult.push(item);
        }
      });

      filterResult = dateResult;
    }

    setEvents({ ...events, filteredEvents: filterResult });
  };

  const getAreas = () => {
    Api.get(ApiEndpoints.eventGetAreas).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setPopulation(response.data);
          // console.log("population:",response.data);
          // console.table(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getImages = () => {
    Api.get("image/getEventImages").then(
      (response) => {
        if (response.data && response.data.length) {
          setImagesData(response.data);
        }
      },
      () => {
        setIsError(true);
        setMessage("Error Occurred while fetching images from server");
      }
    );
  };

  const getEventDisciplines = () => {
    Api.get(ApiEndpoints.homeDisciplines).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          var data = response.data?.map((item: any) => {
            return {
              caption: item.value,
              categoryClicked: disciplineClicked,
              iconName: getDefaultImage(imageType.trainerIcon),
              imageName: getDefaultImage(imageType.trainer),
              name: item.value,
            };
          });
          setDisciplines({
            allDisciplines: data,
            selectedDiscipline: data[0].name,
          });
        } else {
          setIsError(true);
          setMessage(GetHomeMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetHomeMessage(error.response.status));
      }
    );
  };

  const getTrainers = () => {
    Api.get(ApiEndpoints.homeTrainers).then(
      (response) => {
        //console.log(response);
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setTrainers(response.data);
        } else {
          setIsError(true);
          setMessage(GetHomeMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetHomeMessage(error.response.status));
      }
    );
  };

  const getLocations = () => {
    Api.get(ApiEndpoints.homeLocations).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setLocations({ ...locations, allLocations: response.data });
        } else {
          setIsError(true);
          setMessage(GetHomeMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetHomeMessage(error.response?.status));
      }
    );
  };

  const GetAllPublicEvents = () => {
    let path =
      ApiEndpoints.GetAllEvents +
      `?resultCount=${eventsResult.resultCount}&monthCount=${eventsResult.monthCount}`;
    Api.get(path).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setLoading(false);

          setEvents({
            allEvents: [...response.data],
            filteredEvents: [...response.data],
          });
        } else {
          setIsError(true);
          setMessage(GetHomeMessage(response.status));
        }
      },
      (error) => {
        setLoading(false);

        setIsError(true);
        setMessage(GetHomeMessage(error.response.status));
      }
    );
  };
  const handleLoadMore = () => {
    setLoading(true);
    let result = eventsResult;
    result.resultCount = 0;
    result.monthCount = 3;
    setEventsResult(result);
    GetAllPublicEvents();
    setLoadMore(false);
  };

  React.useEffect(() => {
    GetAllPublicEvents();
    getAreas();
    getEventDisciplines();
    getLocations();
    getTrainers();
    getPopulation();
    console.log("Filtered Events:", events.filteredEvents);

    // getImages();
    window.scrollTo(0, 0);
  }, [CreatePublicEventList]);

  //${(!isUserValid || location.pathname == FrontEndPoints.trainer.allEventsBoard) ? "container" : ""}
  return (
    <AnimationDiv>

      <div className={`eq-trainer-event-board`}>
        <div className="eq-container-fluid">
          <Row className="eq-event-heading-row" align="middle" justify="start">
            <Col className="eq-event-heading-col" flex="auto">
              <h3 className="eq-event-heading">{t("events")}</h3>
            </Col>
          </Row>
          {/* filter */}
          <Row>
            <Col span={24} className="filters">
              {/* <div className="filters"> */}
              <Row gutter={[16, 16]} className="filter-type">

                {/* <div className="event-location"> */}
                <Col className="location-col " xs={24} sm={24} md={12} lg={11}>
                  <Row className="location-selector">
                    <Col xs={6} sm={5} md={6} lg={5}>
                      <label className="picker-label">{t("location")}</label>
                    </Col>
                    <Col className="location-input" flex="auto">
                      <Select
                        className="eq-filter-radius eq-input-info"
                        mode="multiple"
                        allowClear
                        placeholder="Select location"
                        onChange={handleChange}
                        value={locations.selectedLocations}
                      >
                        {locations.allLocations.map((location: any) => (
                          <Select.Option
                            label={location.value}
                            value={location.key}
                            key={location.key}
                          >
                            {location.value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>
                <Col className="radius-col" xs={24} sm={24} md={12} lg={11}>
                  <Row className="radius-selector">
                    <Col xs={6} sm={5} md={6} lg={5}>
                      <label className="picker-label">{t("radius")}</label>
                    </Col>
                    <Col className="radius-input" flex="auto">
                      <Select
                        className="eq-filter-radius  eq-input-info"
                        placeholder="Select Radius"
                        onChange={handleRadius}
                        value={radiusValues}
                      >
                        {marks.map((mark) => (
                          <Select.Option value={mark.value} key={mark.key}>
                            {mark.value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>



                <Col className="date-col " xs={24} sm={24} md={12} lg={11}>
                  <Row className="date-selector  start-date-selector">
                    <Col xs={6} sm={5} md={6} lg={5}>
                      <label className="picker-label">{t("startDate")}</label>
                    </Col>
                    <Col className="date-input" flex="auto">
                      <DatePicker
                      placeholder={t("selectDate")}
                        className="eq-filter-radius  eq-input-info"
                        value={startDate}
                        onChange={onStartDateChange}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="date-col" xs={24} sm={24} md={12} lg={11}>
                  <Row className="date-selector">
                    <Col xs={6} sm={5} md={6} lg={5}>
                      <label className="picker-label">{t("endDate")}</label>
                    </Col>
                    <Col className="date-input" flex="auto">
                      <DatePicker
                      placeholder={t("selectDate")}
                        className="eq-filter-radius  eq-input-info"
                        value={endDate}
                        onChange={onEndDateChange}
                      />
                    </Col>
                  </Row>
                </Col>


                <Col className="categories-col " xs={24} sm={24} md={12} lg={11}>
                  <Row className="categories-selector">
                    <Col xs={6} sm={5} md={6} lg={5}>
                      <label className="picker-label">{t("discipline")}</label>
                    </Col>
                    {disciplines && disciplines?.allDisciplines?.length > 0 && (
                      <Col className="categories-input" flex="auto">
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Select Discipline"
                          className="eq-filter-radius  eq-input-info"
                          value={isAllSelected ? ["All"] : selectedDiscipline}
                          onChange={disciplineClicked}
                        >
                          <Select.Option key="All" value="All">
                            All
                          </Select.Option>
                          {disciplines?.allDisciplines
                            .filter((item: any) => item.name !== "Product" && item.name !== "Open")
                            .map((item: any) => (
                              <Select.Option key={item.name} value={item.name}>
                                {t(item.name)}
                              </Select.Option>
                            ))}
                        </Select>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col className="search-col" xs={24} sm={24} md={12} lg={11}>
                  <div className="search">
                    <EqButton classNames="eq-input-info" type="primary" htmlType="button">
                      {t("search")}
                    </EqButton>
                  </div>
                </Col>

              </Row>
            </Col>
          </Row>
          {/* end filter */}

          {events.filteredEvents ? (
            <>
              <EqEventGrid
                {...{
                  eventList: [...events.filteredEvents],
                  // removeEventFunc: removeEvent,
                  // editEventFunc: editEvent,
                }}
              ></EqEventGrid>
              {loadMore && (
                <Row justify="center" className="section-padding">
                  <EqButton
                    type="submit"
                    classNames="btn-background-orange"
                    onClick={handleLoadMore}
                  >
                    {t("loadMore")}
                  </EqButton>
                </Row>
              )}
            </>
          ) : (
            <Row>
              <Skeleton loading={!events.filteredEvents} active></Skeleton>
            </Row>
          )}

          {loading && ( // Show skeleton when loading is true
            <Row className="section-padding">
              <Skeleton active></Skeleton>
            </Row>
          )}
        </div>
      </div>

    </AnimationDiv>
  );
};
export default EqTrainerEvent;
