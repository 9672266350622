import React, { useState } from "react";
import "./EqApproveTrainer.scss";
import { Row, Col } from "antd";
import EqCollapse, { IEqCollapse } from "./../../custom/eqCollapse/EqCollapse";
import { FrontEndPoints, FrontendRoles } from "./../../shared/Common";
import { EqDisciplineType, EqTrainingLevels } from "../../shared/enums";
import Api from "../../shared/Api";
import { ApiEndpoints } from "../../shared/Common";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';

export interface IEqTrainers {
  data: any;
  // data: any,
  acceptedFun: any;
  rejectedFun: any;
  editFun: any;
}
const EqApproveTrainer = ({
  data,
  acceptedFun,
  rejectedFun,
  editFun,
}: IEqTrainers) => {
  const {
    key,
    name,
    img,
    homeAddress,
    disciplineList,
    serviceAreaList,
    trainingLevels,
    display,
  } = data;
  const { t, i18n } = useTranslation();

  const [status, setStatus] = useState("");
  const [messageString, setMessageString] = useState("");
  const navigate = useNavigate();

  const visitProfile = (e: any) => {
    e.stopPropagation();
    if (key) {
      navigate(`${FrontEndPoints.trainer.profileBoard}?key=${key}`);
    }
  };
  function accepted(e: any) {
    e.stopPropagation();
    setStatus("accept");
    Api.post(ApiEndpoints.approveTrainer, { Email: data.email }).then(
      (response) => {
        if (response && response.status == 200) {
          acceptedFun(data);
        } else {
          setMessageString("");
        }
      },
      (err) => {
        setMessageString("");
      }
    );
  }
  function rejected(e: any) {
    e.stopPropagation();
    setStatus("reject");
    rejectedFun(data);
  }

  function edit(e: any) {
    e.stopPropagation();
    editFun();
    navigate(FrontEndPoints.trainer.editTrainer, { state: { trainer: data } });
  }
  const collapsableProps: IEqCollapse = {
    display: display,
    header: [
      <Row align="middle">
        <Col span={3}>
          <div className="eq-pl-img">{img}</div>
        </Col>
        <Col flex="auto">
          <div className="eq-pl-name">{name}</div>
        </Col>
        <Col span={3}>
          <div className="eq-tl-icons" onClick={accepted}>
            <span className="eq-tl-icon">
              <img alt="" src="/green-Check.svg" />
            </span>
          </div>
        </Col>
        <Col span={3}>
          <div className="eq-tl-icons" onClick={rejected}>
            <span className="eq-tl-icon">
              <img alt="" src="/Delete.svg" />
            </span>
          </div>
        </Col>
        <Col span={3}>
          <div className="eq-tl-icons" onClick={edit}>
            <span className="eq-tl-icon">
              <img alt="" src="/edit.svg" />
            </span>
          </div>
        </Col>
      </Row>,
    ],
  };

  React.useEffect(() => {});

  return (
    <>
      <Row className="eq-p-row">
        <Col flex="auto">
          <Row className="hidden-sm" onClick={visitProfile}>
            <Col>
              <div className="eq-pl-img">{img}</div>
            </Col>
            <Col className="eq-pl-info" flex="auto">
              <Row>
                <Col flex="auto">
                  <div className="eq-at-name">{name}</div>
                </Col>
                <Col flex="auto">
                  <Row justify="end" align="top">
                    <div className="eq-tl-icons" onClick={accepted}>
                      <span className="eq-tl-icon">
                        <img alt="" src="/green-Check.svg" />
                      </span>
                    </div>
                    <div className="eq-tl-icons" onClick={rejected}>
                      <span className="eq-tl-icon">
                        <img alt="" src="/Delete.svg" />
                      </span>
                    </div>
                    <div className="eq-tl-icons" onClick={edit}>
                      <span className="eq-tl-icon">
                        <img alt="" src="/edit.svg" />
                      </span>
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <span className="eq-pl-address-icon">
                  <img alt="" src="/map-marker-alt.svg" />
                </span>
                <div className="eq-pl-address">
                  {homeAddress?.locationName
                    ? homeAddress?.locationName
                    : (homeAddress?.street ? homeAddress?.street + " " : "") +
                      (homeAddress?.zipCode
                        ? homeAddress?.zipCode + ", "
                        : "") +
                      (homeAddress?.city ? homeAddress?.city : "")}
                </div>
              </Row>
              <Row align="middle">
                <Col className="eq-at-discipline">
                  <Row align="middle">
                    {disciplineList && disciplineList?.length > 0 && (
                      <>
                        {disciplineList?.map((item: any, index: number) => (
                          <Col className="eq-pl-discipline-list">
                            {index == 0 ? (
                              <span className="eq-pl-discipline-list-active">
                                {t(EqDisciplineType[item.discipline?.type])}
                              </span>
                            ) : (
                              t(EqDisciplineType[item.discipline?.type])
                            )}
                          </Col>
                        ))}
                      </>
                    )}
                  </Row>
                </Col>
                {trainingLevels && trainingLevels.length > 0 ? (
                  <Col className="eq-at-seperator">/</Col>
                ) : (
                  ""
                )}
                <Col className="eq-at-discipline">
                  <Row>
                    {trainingLevels && trainingLevels.length > 0 && (
                      <>
                        {trainingLevels.map((item: any, index: number) => (
                          <Col className="">
                            {/* {index == 0 && <span >{EqTrainingLevels[item.trainingLevel?.type]} </span>} */}
                            {index == 0 ? (
                              <span className="eq-pl-discipline-list-active">
                                {EqTrainingLevels[item.trainingLevel?.type]}
                              </span>
                            ) : (
                              EqTrainingLevels[item.trainingLevel?.type]
                            )}
                          </Col>
                        ))}
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="hidden-md">
            <EqCollapse {...collapsableProps}>
              <Row>
                <Col className="eq-pl-info" flex="auto">
                  <Row className="eq-pl-address">
                    <span className="eq-pl-address-icon">
                      <img alt="" src="/map-marker-alt.svg" />
                    </span>
                    <div>
                      {homeAddress?.locationName
                        ? homeAddress?.locationName
                        : (homeAddress?.street
                            ? homeAddress?.street + " "
                            : "") +
                          (homeAddress?.zipCode
                            ? homeAddress?.zipCode + ", "
                            : "") +
                          (homeAddress?.city ? homeAddress?.city : "")}
                    </div>
                  </Row>
                  <Row align="middle">
                    <Col className="eq-at-discipline">
                      <Row align="middle">
                        {disciplineList && disciplineList.length > 0 && (
                          <>
                            {disciplineList.map(
                              (item: string, index: number) => (
                                <Col className="">
                                  {index == 0 && (
                                    <span className="eq-at-discipline-list-active">
                                      {item}
                                    </span>
                                  )}
                                </Col>
                              )
                            )}
                          </>
                        )}
                      </Row>
                    </Col>
                    <Col className="eq-at-discipline">/</Col>
                    <Col className="eq-at-discipline">
                      <Row>
                        {trainingLevels && trainingLevels.length > 0 && (
                          <>
                            {trainingLevels.map(
                              (item: string, index: number) => (
                                <Col className="">
                                  {index == 0 && (
                                    <span className="eq-at-discipline-list-active">
                                      {item}
                                    </span>
                                  )}
                                </Col>
                              )
                            )}
                          </>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row justify="end" align="bottom">
                        <div
                          className="eq-p-profile-link"
                          onClick={visitProfile}
                        >
                          {t("Visitprofile")}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </EqCollapse>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default EqApproveTrainer;
