import * as React from "react";
import "./EqEventStudentView.scss";
import { Row, Col, Tag, Input } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  ApiEndpoints,
  dateFormat,
  GetEventMessage,
} from "../../../shared/Common";
import EqButton from "../../../custom/eqButton/EqButton";
import { EqInput, EqInputNumber } from "../../../custom/eqInput/EqInput";
import EqSelect from "../../../custom/eqSelect/EqSelect";
// import EqGroupMembersList from "../../eqGroupMembersList/EqGroupMembersList";
import EqTimePicker, {
  ITimePickerOptions,
} from "../../../custom/eqTimePicker/EqTimePicker";
import EqDatePicker, {
  IDatePickerOptions,
} from "../../../custom/eqDatePicker/EqDatePicker";
import { IMultiDatePickerOptions } from "../../../custom/eqMultiDatePicker/EqMultiDatePicker";
import Api from "../../../shared/Api";

import { IStudentGroupDto } from "../../../shared/interfaces/IStudentGroupDto";
import {
  EqDisciplineType,
  EqEventRepeatType,
  EqEventAccessType,
  EqEventHorseAvailabilityStatus,
  eqNotificationTypes,
} from "../../../shared/enums";
import EqUpload from "../../../custom/eqUpload/EqUpload";
import { ISelectProps } from "../../../shared/Interfaces";
import { notification } from "antd";
// import { IEqAttendeeCardData } from "../../eqAttendeeCard/EqAttendeeCard";
import EqCollapse, {
  IEqCollapse,
} from "../../../custom/eqCollapse/EqCollapse";
import EqGroupMembersList from "../../../eqTrainer/eqGroupMembersList/EqGroupMembersList";
import { IEqAttendeeCardData } from "../../../eqTrainer/eqAttendeeCard/EqAttendeeCard";
import EqTags from "../../../custom/eqTags/EqTags";

export interface IEqEventStudentView {
  areas: any;
  population: any;
  groupPopulation: any;
  eventTypes: any;
  imagesData: any;
  eventKey: any;
  onActivityTitleChange:any;
}

const EqEventStudentView = ({
  population,
  groupPopulation,
  eventTypes,
  areas,
  imagesData,
  eventKey,
  onActivityTitleChange,
}: IEqEventStudentView) => {
  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { t, i18n } = useTranslation();
  const [value, setValue] = React.useState(1);

  const [uiImage, setUiImage] = React.useState({} as any);

  const [searchedAttendees, setSearchedAttendees] = React.useState([]) as any;
  const [eventAttendees, setEventAttendees] = React.useState<
    Array<IStudentGroupDto>
  >([]);
  const [eventDurations, setEventDurations] = React.useState([]) as any;
  const [eventRepeats, setEventRepeats] = React.useState([]) as any;

  const [eventAccessTypes, setEventAccessTypes] = React.useState({
    allAccessTypes: [],
    privateOnly: [],
    toggleOptions: [],
  } as any);
  const [horseAvailbility, setHorseAvailbility] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState({
    allWeekDays: [],
    selectedWeekDays: [],
    weekDayTags: [],
  } as any);
  //   const [eventKey, setEventKey] = React.useState(0);
  const [memberSearch, setMemberSearch] = React.useState([] as any);
  const [groupKeys, setGroupKeys] = React.useState([] as any);
  const [isMultipleDateSelected, setIsMultipleDateSelected] =
    React.useState(false);
  const [isPublic, setIsPublic] = React.useState(false);
  const [isOpenEvent, setIsOpenEvent] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isPaymentDeadline, setIsPaymentDeadline] = React.useState(true);
  const [selectedLoc, setSelectedLoc] = React.useState("");
  const [isEventCollapse, setIsEventCollapse] = React.useState(true);
  //for private event

  const [multipleStartOn, setMultipleStartOn] = React.useState([] as any);
  const [attendeeCardData, setAttendeeCardData] = React.useState(
    [] as Array<IEqAttendeeCardData>
  );


  const [formData, setFormData] = React.useState({}) as any;

  const openNotificationWithIcon = (
    msg: any,
    desc: any,
    type = eqNotificationTypes.error
  ) => {
    if (type == eqNotificationTypes.success) {
      notification["success"]({
        message: msg,
        description: desc,
      });
    } else if (type == eqNotificationTypes.info) {
      notification["info"]({
        message: msg,
        description: desc,
      });
    } else {
      notification["error"]({
        message: msg,
        description: desc,
      });
    }
  };
  const setEditFormData = (data: any) => {
    if (data != undefined) {
      var selectedDate = new Date(data.startDate);
      var selectedTimeFrom = new Date(data.startTime);

      var calculatedTimeFrom = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        selectedTimeFrom.getHours(),
        selectedTimeFrom.getMinutes(),
        0,
        0
      );

      var calculatedTimeTo;
      if (data.endTime != undefined) {
        var selectedTimeTo = new Date(data.endTime);
        calculatedTimeTo = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          selectedTimeTo.getHours(),
          selectedTimeTo.getMinutes(),
          0,
          0
        );
      }

      //setSelectedStartDate(selectedDate);
      //setSelectedStartTime(calculatedTimeFrom);

      return {
        title: data.title,
        startOn: moment(data.startDate).local(),
        endOn: moment(data.endDate).local(),
        repeats: data.repeatType,
        isRepeat: data.isRepeat,
        timeFrom: moment(calculatedTimeFrom.valueOf()).local(),
        timeTo:
          calculatedTimeTo != undefined
            ? moment(calculatedTimeTo.valueOf()).local()
            : calculatedTimeTo,
        duration: data.duration,
        disciplineType: data.disciplineType as EqDisciplineType,
        trainingLevel: data?.trainingLevel,
        locationKey: data.locationKey,
        maxSpaceCount: data.maxAvailableSpace,
        isPaid: data.price > 0 ? true : false,
        eventPrice: data.price > 0 ? data.price : "",
        paymentMethod: data.paymentMethod,
        isLatePayment: data.isLatePayment,
        paymentDeadline:
          data.paymentDeadline > 0
            ? moment(data.paymentDeadline).local()
            : null,
        message: data.message,
        accessType: data.accessType as EqEventAccessType,
        horseAvailabilityStatus: data.horseAvailabilityStatus,
        eqImage: uiImage,
      };
    } else {
      return {
        title: "",
        startOn: moment(),
        endOn: moment(),
        repeats: "",
        isRepeat: false,
        timeFrom: moment(),
        timeTo: moment(),
        duration: "",
        disciplineType: EqDisciplineType.Dressage,
        trainingLevel: "",
        locationKey: "",
        maxSpaceCount: "",
        isPaid: true,
        eventPrice: "",
        paymentMethod: 1,
        isLatePayment: false,
        paymentDeadline: null,
        message: "",
        accessType: EqEventAccessType.Private + "",
        horseAvailabilityStatus: 0,
        eqImage: {},
      };
    }
  };

  const { TextArea } = Input;
  const gutter = { xs: 8, sm: 16, md: 24, lg: 32 };

  const checkChanged = (e: any) => {};

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const inviteClicked = (participant: IStudentGroupDto) => {};

  const removeClicked = (participant: IStudentGroupDto) => {};

  /////////////

  const setEventDefaults = () => {
    setFormData({
      title: "",
      startOn: moment(),
      endOn: moment(),
      repeats: "",
      isRepeat: false,
      timeFrom: moment(),
      timeTo: moment(),
      duration: "",
      disciplineType: EqDisciplineType.Dressage,
      trainingLevel: "",
      locationKey: "",
      maxSpaceCount: "",
      isPaid: true,
      eventPrice: "",
      paymentMethod: 1,
      isLatePayment: false,
      paymentDeadline: null,
      message: "",
      accessType: EqEventAccessType.Public + "",
      horseAvailabilityStatus: 0,
      eqImage: {},
    });
    //setEventAttendees([] as any);
    setAttendeeCardData([] as Array<IEqAttendeeCardData>);
    setMultipleStartOn([]);
    // setEventAccessAndDisciplineTypes(
    //   false,
    //   EqEventAccessType.Private,
    //   EqDisciplineType.Dressage
    // );
    setIsPublic(false);
    setIsPaymentDeadline(true);
    setIsOpenEvent(false);
  };

  const validateData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    //same for public and private
    if (data.title == "") {
      openNotificationWithIcon(msg, "Title" + desc);
      isComplete = false;
    } else if (uiImage == null || Object.keys(uiImage).length === 0) {
      openNotificationWithIcon(msg, "Image" + desc);
      isComplete = false;
    } else if (data.disciplineType == "") {
      openNotificationWithIcon(msg, "Event Type" + desc);
      isComplete = false;
    } else if (data.accessType == "") {
      openNotificationWithIcon(msg, "Access type" + desc);
      isComplete = false;
    } else if (data.locationKey == "") {
      openNotificationWithIcon(msg, "Area of service" + desc);
      isComplete = false;
    } else if (!data.startOn) {
      openNotificationWithIcon(msg, "Start On" + desc);
      isComplete = false;
    } else if (data.duration == "") {
      openNotificationWithIcon(msg, "Duration" + desc);
      isComplete = false;
    }
    //else if (data.message == "") {
    //    openNotificationWithIcon(msg, "Message" + desc);
    //    isComplete = false;
    //}
    //else if (data.isPaid == true && data.price == "") {
    //    openNotificationWithIcon(msg, "Price" + desc);
    //    isComplete = false;
    //}
    // else if (multipleStartOn.length == 0 && isEdit == false) {
    //   openNotificationWithIcon(msg, "Start On" + desc);
    //   isComplete = false;
    // }
    //public function properties
    else if (
      data.accessType == EqEventAccessType.Public &&
      data.maxSpaceCount == ""
    ) {
      openNotificationWithIcon(msg, "Maximum available spaces" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Public &&
      data.duration == ""
    ) {
      openNotificationWithIcon(msg, "Duration" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Public &&
      data.horseAvailabilityStatus == ""
    ) {
      openNotificationWithIcon(msg, "Horses" + desc);
      isComplete = false;
    }
    
    return isComplete;
  };



  const createEventDurations = () => {
    var durations = [];
    let durationVals = [30, 45, 60, 90];
    for (var x = 0; x < durationVals.length; x++) {
      durations.push({ key: durationVals[x], value: durationVals[x] });
    }
    //for (var i = 5; i < 101; i += 5) {
    //    durations.push({ key: i, value: i });
    //}
    setEventDurations(durations);
  };

  const createEventRepeats = () => {
    var repeats = [];
    for (var x in EqEventRepeatType) {
      if (isNaN(Number(x)) == false) {
        repeats.push({ key: x, value: EqEventRepeatType[x] });
      }
    }
    setEventRepeats(repeats);
  };

 

  const createHorseAvailabilities = () => {
    var accessTypes = [];
    for (var x in EqEventHorseAvailabilityStatus) {
      if (isNaN(Number(x)) == false) {
        accessTypes.push({ key: x, value: EqEventHorseAvailabilityStatus[x] });
      }
    }
    setHorseAvailbility(accessTypes);
  };

  const createEventAccessTypes = () => {
    var accessTypes = [];
    var pAccess = [];
    for (var x in EqEventAccessType) {
      let label;
      if (x == EqEventAccessType.Private.toString()) {
        label = "Training";
      } else if (x == EqEventAccessType.Public.toString()) {
        label = "Event";
      }
      if (isNaN(Number(x)) == false) {
        accessTypes.push({ key: x, value: label });

        if (Number(x) == EqEventAccessType.Private) {
          pAccess.push({ key: Number(x), value: label });
        }
      }
    }
    // console.log(formData?.disciplineType);
    if (isEdit == true) {
      if (formData?.disciplineType == EqDisciplineType.Open) {
        setEventAccessTypes({
          allAccessTypes: [...accessTypes],
          privateOnly: [...pAccess],
          toggleOptions: [...pAccess],
        });
      } else {
        setEventAccessTypes({
          allAccessTypes: [...accessTypes],
          privateOnly: [...pAccess],
          toggleOptions: [...accessTypes],
        });
      }
    } else {
      setEventAccessTypes({
        allAccessTypes: [...accessTypes],
        privateOnly: [...pAccess],
        toggleOptions: [...accessTypes],
      });
    }
  };

  const updateStartTime = (
    fullYear: number,
    month: number,
    date: number,
    hours: number,
    minutes: number,
    seconds: number,
    milliseconds: number
  ) => {
    var calculatedDate = new Date(
      fullYear,
      month,
      date,
      hours,
      minutes,
      seconds,
      milliseconds
    );
    setFormData({ ...formData, timeFrom: moment(calculatedDate.valueOf()) });
  };

  const updateEndTime = (
    fullYear: number,
    month: number,
    date: number,
    hours: number,
    minutes: number,
    seconds: number,
    milliseconds: number
  ) => {
    var calculatedDate = new Date(
      fullYear,
      month,
      date,
      hours,
      minutes,
      seconds,
      milliseconds
    );
    setFormData({ ...formData, timeTo: moment(calculatedDate.valueOf()) });
  };

  // const createEvent = (event: any) => {
  //   var data = setEventObject(formData);

  //   if (data.startDate != null) {
  //     // Api.post(ApiEndpoints.eventCreate, data).then(
  //     Api.post(ApiEndpoints.CreateEvent, data).then(
  //       (response) => {
  //         if (response && response.status == 200) {
  //           openNotificationWithIcon(
  //             "Created!",
  //             "Training(s) have been created, you can go to trainings and look at all trainings",
  //             eqNotificationTypes.success
  //           );
  //           setIsError(false);
  //           setMessage(GetEventMessage(response.status));
  //           setEventDefaults();
  //           setEventAttendees([] as any);
  //         } else {
  //           openNotificationWithIcon(
  //             "Server error",
  //             "Error occurred while creating the event(s). Please try again or contact administrator."
  //           );
  //           setIsError(true);
  //         }
  //       },
  //       (error) => {
  //         if (error?.response?.status === 403) {
  //           openNotificationWithIcon(
  //             "Server error",
  //             "Not Authorized Kindly Add Payment or contact administrator."
  //           );
  //           setIsError(true);
  //         } else {
  //           openNotificationWithIcon(
  //             "Server error",
  //             "Error occurred while creating the event(s). Please try again or contact administrator."
  //           );
  //           setIsError(true);
  //         }
  //       }
  //     );
  //   } else {
  //     setIsError(true);
  //   }
  // };

  // const editEvent = (event: any) => {
  //   var data = setEventObject(formData);
  //   Api.post(ApiEndpoints.UpdateEvent, data).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         setIsError(false);
  //         setIsEdit(false);
  //         setMessage(GetEventMessage(response.status));

  //         setEventDefaults();
  //         createEventAccessTypes();
  //         //   createEventTypes();
  //       } else {
  //         setIsError(true);
  //         setMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };

  const sortAscending = (unsorted: Array<IStudentGroupDto>) => {
    const sorted = [...unsorted].sort((obj1, obj2) => {
      if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() >
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return 1;
      } else if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() <
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    // console.log(sorted);
    return sorted;
  };

  const sortDescending = (unsorted: Array<IStudentGroupDto>) => {
    const sorted = [...unsorted].sort((obj1, obj2) => {
      console.log(obj1);
      console.log(obj2);
      if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() <
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return 1;
      } else if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() >
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    // console.log(sorted);
    return sorted;
  };

  const onSortChange = (group: any): any => {
    var sortedList = [] as any;
    switch (group) {
      case "asc":
        sortedList = sortAscending(eventAttendees);
        setEventAttendees(sortedList);
        break;
      case "desc":
        sortedList = sortDescending(eventAttendees);
        setEventAttendees(sortedList);
        break;
      default:
        sortedList = sortAscending(eventAttendees);
        setEventAttendees(sortedList);
    }
  };

  const removeEventMember = (member: any) => {
    console.log(eventAttendees);
    var attendees = [...eventAttendees];
    var keys = [...groupKeys];
    if (attendees != null && attendees.length > 0) {
      const index = attendees.findIndex((x: any) => x.key == member.key);
      if (index > -1) {
        attendees.splice(index, 1);
      }
    }
    if (keys != null && keys.length > 0) {
      const index = keys.findIndex((x: any) => x);
      if (index > -1) {
        keys.splice(index, 1);
      }
    }
    setGroupKeys([...keys]);
    console.log(keys);
    setEventAttendees([...attendees]);
    console.log(attendees);
  };

  const onSearchChange = (value: any) => {
    const selectedMembers = [...value];
    var selectedPopulation = [] as any;
    population.filter((item: any) => {
      if (selectedMembers.includes(item.key) == true) {
        selectedPopulation.push(item);
      }
    });
    setSearchedAttendees([...selectedPopulation]);
  };

  const onGroupChange = (value: any) => {
    const selectedMembers = [...value];
    var selectedPopulation = [] as any;
    population.filter((item: any) => {
      if (selectedMembers.includes(item.key) == true) {
        selectedPopulation.push(item);
      }
    });
    console.log(selectedMembers);
    console.log(selectedPopulation);
    setSearchedAttendees([...selectedPopulation]);
  };

  const addGroupMembers = () => {
    var keys = new Array<String>();
    var isDuplicate = false;

    searchedAttendees?.map((item: any) => {
      isDuplicate = eventAttendees?.some((gMember: IStudentGroupDto) => {
        if (gMember.key == item.key) {
          return true;
        }
      });
      if (isDuplicate == false) {
        keys.push(item.key);
      }
    });

    if (keys?.length > 0) {
      //console.log(keys);
      //console.log(groupKeys);
      setGroupKeys([...groupKeys, ...keys]);
      Api.post(ApiEndpoints.eventGetEventMember, keys).then(
        (response) => {
          if (response && response.status == 200) {
            setIsError(false);
            setMessage(GetEventMessage(response.status));

            var newData = [...eventAttendees, ...response.data];
            var memberData = [] as Array<IStudentGroupDto>;

            newData.map((item: IStudentGroupDto) => {
              memberData.push(item);
            });
            setEventAttendees(memberData);
          } else {
            setIsError(true);
            setMessage(GetEventMessage(response.status));
          }
        },
        (error) => {
          setIsError(true);
          setMessage(GetEventMessage(error.response.status));
        }
      );
    }
    setSearchedAttendees([] as any);
    setMemberSearch([] as any);
  };

  ////////////////

  const typeSelectProps: ISelectProps = {
    options: eventTypes.toggleOptions,
    defaultSelectedValue: formData?.disciplineType,
    classNames: "extra-height",
    changeFunc: (value: string) => {
      let type: number = +value;
      setFormData({ ...formData, disciplineType: type });

      if (isEdit == true) {
        if (type == EqDisciplineType.Open) {
          setIsOpenEvent(true);
        } else {
          setIsOpenEvent(false);
        }
      } else {
        if (type == EqDisciplineType.Open) {
          setIsOpenEvent(true);
        } else {
          setIsOpenEvent(false);
        }
        // setEventAccessAndDisciplineTypes(false, formData?.accessType, type);
      }
    },
  };



  const repeatSelectProps: ISelectProps = {
    mode: "tags",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    placeholder: t("selectRepeatingDuration"),
    classNames: "eq-addSelect extra-height",
    defaultSelectedValue: formData?.repeatType
      ? formData.repeatType.toString()
      : "",
    options: eventRepeats,
    changeFunc: (value: string) => {
      let repeat: number = +value;
      setFormData({ ...formData, repeats: repeat });
    },
  };

  const areaSelectProps: ISelectProps = {
    isTag: false,
    placeholder: t("selectArea"),
    defaultSelectedValue: formData?.locationKey
      ? formData.locationKey + ""
      : "",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    options: areas,
    changeFunc: (value: string) => {
      setFormData({ ...formData, locationKey: value });

      let loc = areas
        ?.filter((item: any) => {
          return item.key == value;
        })
        ?.shift();
      if (loc != null && loc != undefined) {
        setSelectedLoc(loc.value);
      }
    },
  };

  const durationSelectProps: ISelectProps = {
    isTag: false,
    classNames: "eq-addSelect extra-height",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    options: eventDurations,
    defaultSelectedValue: formData?.duration ? formData.duration + "" : "",
    changeFunc: (value: string) => {
      let minutes: number = +value;
      setFormData({ ...formData, duration: minutes });
    },
  };

    const searchProps: ISelectProps = {
    mode: "multiple",
    showSearch: true,
    optionFilterProp: "label",
    filterOption: true,
    isTag: false,
    showArrow: false,
    allowClear: true,
    changeFunc: onSearchChange,
    classNames: "eq-multiSelect eq-searchSelect extra-height",
    ddClassName: "eq-select-dropdown",
    icon: [
      <span className="eq-search-icon">
        <img alt="" src="/search-icon.svg" />
      </span>,
    ],
    selectedIcon: [
      <span className="">
        <img alt="" src="/check-square.svg" />
      </span>,
    ],
    options: population,
    defaultSelectedValue: memberSearch,
  };


  const sortProps = {
    border: false,
    allowClear: false,
    classNames: "eq-select-sort",
    ddClassName: "eq-cg-dropdown",
    defaultSelectedValue: "Sort A-Z",
    changeFunc: onSortChange,
    options: [
      { key: "asc", value: "Sort A-Z" },
      { key: "desc", value: "Sort Z-A" },
    ],
  };

  const timeFromProps: ITimePickerOptions = {
    isSuffixIcon: false,
    isBordered: true,
    classNames: "eq-event small-helight",
    defaultValue: formData?.timeFrom?.valueOf(),
    placeHolder: "Select duration..",
    onChangeFunc: (time: any) => {
      setFormData({ ...formData, timeFrom: moment(time) });
    },
  };

  const timeToProps: ITimePickerOptions = {
    isSuffixIcon: false,
    isBordered: true,
    classNames: "eq-event small-helight",
    defaultValue: formData?.timeTo?.valueOf(),
    placeHolder: "Select duration..",
    onChangeFunc: (time: any) => {
      setFormData({ ...formData, timeTo: moment(time) });

      var selectedtime = new Date(time);
      var selectedDate = formData?.startOn
        ? new Date(formData?.startOn)
        : new Date(time);

      if (selectedtime != undefined && selectedDate != undefined) {
        updateEndTime(
          selectedDate.getFullYear(),
          selectedDate.getMonth() + 1,
          selectedDate.getDate(),
          selectedtime.getHours(),
          selectedtime.getMinutes(),
          0,
          0
        );
      }
    },
  };

  const startOnProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.startOn,
    placeHolder: t("date"),
    classNames: "eq-event",
    onChangeFunc: (date: any, dateString: string) => {
      setFormData({ ...formData, startOn: moment(date) });
    },
  };

  const multipleStartOnProps: IMultiDatePickerOptions = {
    defaultValue: multipleStartOn,
    placeHolder: t("date"),
    classNames: "eq-event",
    onChangeFunc: (selectedDates: any) => {
      console.log(selectedDates);
      var dateArr = [] as any;
      if (selectedDates && selectedDates.length > 0) {
        if (selectedDates.length == 1) {
          setIsMultipleDateSelected(false);
        } else {
          setFormData({
            ...formData,
            repeats: "",
            isRepeat: false,
          });
          setIsMultipleDateSelected(true);
        }
        dateArr = selectedDates.map((date: any) => {
          return date;
        });
      }
      setMultipleStartOn(dateArr);
    },
  };

  const endOnProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.endOn,
    placeHolder: "Date",
    classNames: "eq-event",
    onChangeFunc: (date: any, dateString: string) => {
      setFormData({ ...formData, endOn: moment(date) });
    },
  };

  const advancePaymentDateProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.paymentDeadline,
    placeHolder: t("date"),
    classNames: "eq-event",
    onChangeFunc: (date: any, dateString: string) => {
      setFormData({ ...formData, isLatePayment: false });
      setFormData({ ...formData, paymentDeadline: moment(date) });
    },
  };

  const latePaymentDateProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.paymentDeadline,
    placeHolder: "Date",
    classNames: "eq-event",
    onChangeFunc: (date: any, dateString: string) => {
      setFormData({ ...formData, isLatePayment: true });
      setFormData({ ...formData, paymentDeadline: moment(date) });
    },
  };

  const horseAvailbilitySelectProps: ISelectProps = {
    
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    placeholder: "Select availbility of horse",
    classNames: "eq-addSelect extra-height",
    defaultSelectedValue: formData?.horseAvailabilityStatus
      ? formData.horseAvailabilityStatus + ""
      : "",
    options: horseAvailbility,
    changeFunc: (value: string) => {
      setFormData({ ...formData, horseAvailabilityStatus: +value });
    },
  };

  const weekDaysSelectProps: ISelectProps = {
    mode: "multiple",
    isTag: false,
    showArrow: true,
    allowClear: true,
    classNames: "eq-multiSelect eq-addSelect",
    ddClassName: "eq-select-dropdown",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    placeholder: "Select days",
    defaultSelectedValue: weekDays.selectedWeekDays,
    options: weekDays.allWeekDays,
    changeFunc: (param: any) => {
      setWeekDays({
        ...weekDays,
        selectedWeekDays: [...param],
        weekDayTags: [...param],
      });
    },
  };
  const changeEventCollapseDisplay = (display: boolean) => {
    setIsEventCollapse(display);
  };
  const eventCollapsableProps: IEqCollapse = {
    display: isEventCollapse,
    setDisplay: changeEventCollapseDisplay,
    showArrow: false,
    classNames: "eq-transaparent-header",
    header: [
      <Row align="middle" justify="end">
        {selectedLoc && (
          <>
            <Col span={1}>
              <span className="">
                <img alt="" src="/map-marker-alt.svg" />
              </span>
            </Col>
          </>
        )}
        <Col flex="auto">
          <div className="header-label">{selectedLoc}</div>
        </Col>
        <Col>
          <EqTags className=""
           text={t(EqDisciplineType[formData.disciplineType])}
           dicipline={true}
          />
           
          
        </Col>
        <Col>
          {eventAttendees?.map((member: IStudentGroupDto) => (
            <>
              <EqTags className="eq-tag"
              text={member?.user?.firstName ??
                member?.user?.userName ??
                member?.group?.name ??
                ""}
                color={member?.group?.color}
              />
                
            
            </>
          ))}
        </Col>
      </Row>,
    ],
  };

  const setSelectedImage = (eqUiImage: any) => {
    //console.log(eqUiImage);
    setUiImage(eqUiImage);
    setFormData({ ...formData, eqImage: eqUiImage });
  };

  const GetEventByKey = (e: any) => {
    Api.post(ApiEndpoints.GetEventByKey, { key: e }).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          //   setTrainingData({ ...response.data });

          //   setEditAttendeeCardData({...response.data});
          setEventAttendees([...response.data.attendees]);
          var formObj = setEditFormData({ ...response.data });

          setFormData(formObj);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  React.useEffect(() => {
    // history.push(FrontEndPoints.trainer.createEvent);
    if(population){sortAscending(population);}
    createEventDurations();
    createEventRepeats();
    createHorseAvailabilities();
    ////GenerateEventAccessAndDisciplineTypes();

    if (eventKey !== 0) {
      setIsEdit(true);
      GetEventByKey(eventKey);
    } else {
      setIsEdit(false);
      setEventDefaults();
      createEventAccessTypes();
      // if (attendeeCardData.length == 0) {
      //   addNewAttendeeCard();
      // }
    }


  }, [eventKey, imagesData]);

  //console.log("Data", attendeeCardData);

  return (
    <div className="eq-create-event-container">
      <div className="">
        <Row gutter={gutter} className="event-info-input">
          <Col className="gutter-row" span={24}>
            <EqCollapse {...eventCollapsableProps}>
     
              <Row>
                <Col span={24}>
                  {" "}
                  <div className="label">{t("selectImage")}</div>
                  <EqUpload
                    preview={true}
                    imagesData={imagesData}
                    selected={setSelectedImage}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="gutter-row" span={24}>
                  <Row justify="space-between">
                    <Col className="col-1" xs={24} sm={24} md={12}>
                      <div className="eq-input-group">
                        <div className="input-label">{t("title")}</div>
                        <EqInput
                          className="name-input"
                          onBlur={onActivityTitleChange}
                          value={formData.title}
                          onChange={(event: any) =>
                            setFormData({
                              ...formData,
                              title: event?.target?.value,
                            })
                          }
                        />
                      </div>
                      <div className="eq-input-group">
                        <div className="event-type">
                          <div className="input-label">{t("date")}</div>
                          <div className="toggler">
                            <EqSelect {...typeSelectProps} />
                          </div>
                        </div>
                      </div>
                      <Row className="eq-input-group">
                        <Col className="input-label">{t("location")}</Col>
                        <Col span={24}>
                          <EqSelect extraHeight={true} {...areaSelectProps} />
                        </Col>
                      </Row>
                      <div className="date-time-inputs">
                        <Row className="eq-input-group">
                          <Col className="input-label">{t("date")}</Col>
                          <Col span={24}>
                            <EqDatePicker {...startOnProps}></EqDatePicker>
                          </Col>
                        </Row>
                      </div>

                      {
                        //formData.isPaid == true &&
                        <>
                          <Row className="eq-input-group">
                            <Col span={24} className="input-label">
                              {t("price")}
                            </Col>
                            <Col span={24}>
                              <EqInput
                                className="name-input"
                                value={formData.eventPrice}
                                onChange={(event: any) =>
                                  setFormData({
                                    ...formData,
                                    eventPrice: event?.target?.value,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </>
                      }
                    </Col>
                    <Col className="col-2" xs={24} sm={24} md={12}>
                      <Row className="eq-input-group">
                        <Col className="input-label">{t("capacity")}</Col>
                        <Col span={24}>
                          <EqInputNumber
                            min={1}
                            value={formData.maxSpaceCount}
                            onChange={(value: any) =>
                              setFormData({
                                ...formData,
                                maxSpaceCount: value,
                              })
                            }
                          />
                        </Col>
                      </Row>
                      <>
                        <Row className="eq-input-group">
                          <Col className="input-label">{t("from")}</Col>
                          <Col span={24}>
                            <EqTimePicker {...timeFromProps}></EqTimePicker>
                          </Col>
                        </Row>
                        {isOpenEvent ? (
                          <Row className="eq-input-group">
                            <Col className="input-label">{"To"}</Col>
                            <Col span={24}>
                              <EqTimePicker {...timeToProps}></EqTimePicker>
                            </Col>
                          </Row>
                        ) : (
                          <div className="eq-input-group">
                            <Col className="input-label">{t("duration")}</Col>
                            <div className="repeat-time">
                              <EqSelect {...durationSelectProps} />
                            </div>
                          </div>
                        )}
                      </>

                      {/*{isPaymentDeadline &&*/}
                      <Row className="eq-input-group">
                        <Col span={24} className="input-label">
                          {t("paymentDeadline")}
                        </Col>
                        <Col span={24}>
                          <EqDatePicker
                            {...advancePaymentDateProps}
                          ></EqDatePicker>
                        </Col>
                      </Row>
                      <Row className="eq-input-group">
                        <Col span={24} className="input-label">
                          {t("horses")}
                        </Col>
                        <Col span={24}>
                          <EqSelect {...horseAvailbilitySelectProps} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row justify="end" align="bottom" className="eq-cg-btn">
                <Col className="eq-cg-buttons">
                  {isEdit == false && (
                    <EqButton
                      type="submit"
                      classNames="btn-background-orange eq-save-btn"
                      onClick={() => setIsEventCollapse(false)}
                    >
                      Save
                    </EqButton>
                  )}
                </Col>
              </Row>
            </EqCollapse>

            <Row>
              <Col xs={24} sm={24} md={16}>
                <Row gutter={gutter} className="eq-cg-members-header">
                  <Col className="gutter-row" span={24}>
                    <Row align="middle">
                      <Col xs={24} sm={24} md={12}>
                        <h3 className="eq-cg-members-heading">{t("addAttendees")}</h3>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Row
                          className="eq-cg-right"
                          justify="end"
                          align="middle"
                        >
                          <Col className="eq-cg-sort">
                            <EqSelect {...sortProps}></EqSelect>
                          </Col>
                          <Col className="eq-cg-add">
                            <EqButton
                              type="button"
                              classNames="btn-background-orange"
                              onClick={addGroupMembers}
                            >
                              <span className="eq-btn-icon-text">
                                <span className="eq-btn-icon">
                                  <img
                                    alt=""
                                    src="/plus.svg"
                                    style={{ verticalAlign: "text-top" }}
                                  />
                                </span>
                                {t("Add")}
                              </span>
                            </EqButton>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="eq-cg-search">
                        <EqSelect {...searchProps}></EqSelect>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <EqGroupMembersList
                          {...{
                            groupMembersList: eventAttendees,
                            delFunc: removeEventMember,
                          }}
                        ></EqGroupMembersList>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row justify="end" align="bottom" className="eq-cg-btn">
              <Col className="eq-cg-buttons">
                <EqButton
                  type="button"
                  bordered="true"
                  classNames="btn-light-gray"
                >
                  {t("cancel")}
                </EqButton>
              </Col>
              {/* <Col className="eq-cg-buttons">
                {isEdit ? (
                  <EqButton
                    type="submit"
                    classNames="btn-background-orange"
                    onClick={editEvent}
                  >
                    {t("saveChanges")}
                  </EqButton>
                ) : (
                  <EqButton
                    type="submit"
                    classNames="btn-background-orange"
                    onClick={createEvent}
                  >
                    Create Activity
                  </EqButton>
                )}
              </Col> */}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default EqEventStudentView;
