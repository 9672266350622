import Config from './Config';
import axios from 'axios';
import history from "./history";
import { loadState,saveState } from "./LocalStorage";
import { ApiEndpoints, FrontEndPoints } from './Common';


var currentState = loadState() as any;


const Api = axios.create({
    // timeout: 120000,
    baseURL: Config.apiUrl,
    headers: {
      "Content-Type": "application/json",
    },
  });
  
  Api.interceptors.request.use(
    (config: any) => {
      currentState = loadState() as any;
      const accessToken = currentState?.accessToken;
      if (accessToken) {
        config.headers.Authorization = `Bearer ${currentState?.accessToken}`; 
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
var isRefresh = false;
Api.interceptors.response.use(
  (res) => {
     
    return res;
   },
  async (err) => {
    
    const originalConfig = err.config;
    if (originalConfig.url !== FrontEndPoints.login && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const response = await Api.post( Config.apiUrl + ApiEndpoints.RefreshToken, { AccessToken: currentState?.accessToken, refreshToken: currentState?.refreshToken });
          
          if(response.data.refreshToken !== null){
            saveState(response.data);
            // retrigger old request
            originalConfig.headers.Authorization = `Bearer ${response.data?.accessToken}`;
            currentState = loadState() as any;
            if(response.data?.accessToken == currentState?.accessToken ){
              return axios(originalConfig);
            }
          }
          if(response.data.status && response.data.status === 401){
            window.location.replace(Config.apiUrl+FrontEndPoints.login);

            // history.push(FrontEndPoints.logout)
          }
        }catch (_error) {
          return Promise.reject(_error);
        }
      }
      originalConfig._retry = false;
      throw err;
    }else{
      return Promise.reject(err);
    }
  }
);
export default Api;



//---------------//


















// import Config from './Config';
// import axios from 'axios';
// import NavigationService from './NavigationService';
// import { loadState } from "./LocalStorage";

// export const headers = () => {
//     const currentState = loadState() as any;
//     return {
//         headers: {
//             'content-type': 'application/json',
//             Authorization: `Bearer ${currentState?.accessToken}`,
//         },
//     };
// };

// const handleErrors = (handleError: any, error: any, path: any) => {
//     if (
//         error.response &&
//         error.response.status &&
//         error.response.status >= 400
//     ) {
//         if (error.response.status === 401) {
//             //NavigationService.replace('/login');
//             throw error;
//             //return error;
//         }
//     }
//     throw error;
// };

// export const get = async (path: string, handleError = true) => {
//     try {
//         return await axios.get(Config.apiUrl + path, {
//             headers: headers().headers
//         });
//     } catch (error) {
//         throw handleErrors(handleError, error, path);
//     }
// };

// export const postFormData = async (
//     path: string,
//     data?: object,
//     handleError: boolean = true
// ) => {
//     try {
//         return await axios.post(Config.apiUrl + path, data, {
//             headers: headers().headers
//         });
//     } catch (error) {
//         throw handleErrors(handleError, error, path);
//     }
// };

// type withDataTypes = 'put' | 'post';
// const withData = async (
//     path: string,
//     data?: object,
//     method: withDataTypes = 'post',
//     handleError: boolean = true
// ) => {
//     const body = data ? JSON.stringify(data) : undefined;
//     try {
//         return await axios[method](Config.apiUrl + path, body, {
//             headers: headers().headers
//         });
//     } catch (error) {
//         throw handleErrors(handleError, error, path);
//     }
// };

// export const post = async (path: string, data?: object, handleError = true) => {
//     return await withData(path, data, 'post', handleError);
// };

// export const put = async (path: string, data?: object, handleError = true) => {
//     return await withData(path, data, 'put', handleError);
// };

// export const remove = async (path: string, data?: object, handleError = true) => {
//     try {
//         return await axios.delete(Config.apiUrl + path, {
//             headers: headers().headers,
//             data: data
//         });
//     } catch (error) {
//         throw handleErrors(handleError, error, path);
//     }
// };
