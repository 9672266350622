import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./EqProductInvitationBoard.scss";
import Api from "../../shared/Api";
import {
  ApiEndpoints,
  FrontEndPoints,
  openNotificationWithIcon,
  ValidateStudentCanBook,
} from "../../shared/Common";
import { Row, Col, notification, Modal } from "antd";
import { EqEventAttendeeStatus, eqNotificationTypes } from "../../shared/enums";
import EqEventInvitesList from "../EqEventinviteList/EqEventinviteList";
import EqEventInviteDetail from "../EqEventInviteDetail/EqEventInviteDetail";
import EqButton from "../../custom/eqButton/EqButton";
import { loadState } from "../../shared/LocalStorage";
import EqProductOrderInvite from "./EqProductOrderInvite/EqProductOrderInvite";
import EqProductOrderInviteDetail from "./EqProductOrderInviteDetail/EqProductOrderInviteDetail";
import { useAuthState } from "../../context/Context";
import { ExclamationCircleOutlined } from "@ant-design/icons";
export interface IEqProductInvitationBoard {
  onloadActivity?: any;
}
const EqProductInvitationBoard = ({
  onloadActivity,
}: IEqProductInvitationBoard) => {
  const userDetails: any = useAuthState();

  const studentCanBook: boolean = ValidateStudentCanBook(userDetails);

  const [messageString, setMessageString] = React.useState("");
  const [productInvites, setProductOrderInvites] = React.useState([] as any);
  const [pendingInvites, setPendingInvites] = React.useState([] as any);
  const [acceptedInvites, setAcceptedInvites] = React.useState([] as any);
  const [steps, setSteps] = React.useState(1 as number);
  const [productOrderDetail, setProductOrderDetail] = React.useState({} as any);
  const navigate = useNavigate();
  const location = useLocation();

  const [modal, contextHolder] = Modal.useModal();

  const payNow = () => {
    navigate(FrontEndPoints.student.allTrainings, {
      state: { prevPath: location.pathname },
    });
  };


  const CreateCheckoutSession = (orderId: any) => {
    

    Api.get(
      ApiEndpoints.ProductPayment.GetProductOrderCheckoutSession + `/${orderId}`
    )
      .then((response) => {
        window.location.href = response.data.sessionUrl;
      })
      .catch((error) => {
        openNotificationWithIcon(
          "Error!",
          "Unable to Create checkout Session",
          eqNotificationTypes.error
        );
      });
  };


  const openCheckOutNotificationProduct = (
    msg: any,
    desc: any,
    orderId: any
  ) => {
    const btn = (
      <div className="right">
        <EqButton onClick={() => CreateCheckoutSession(orderId)}>
          Pay Now
        </EqButton>
      </div>
    );
    notification["info"]({
      message: msg,
      btn,
      description: desc,
    });
  };



  //   const openNotificationCheckOutNotification = (
  //     msg: any,
  //     desc: any,
  //     productOrder: any
  //   ) => {
  //     const btn = (
  //       <div className="right">

  //           <EqButton onClick={()=>ProductOrderCheckout(productOrder)} >Pay Now</EqButton>

  //       </div>
  //     );
  //     notification["info"]({
  //       message: msg,
  //       btn,
  //       description: desc,
  //     });
  //   };


  const GetProductOrderCheckoutSessionForMultiple = (productOrders:any) => {
    
   
    Api.post(ApiEndpoints.ProductPayment.GetProductOrderCheckoutSessionForMultiple, 
      productOrders)
      .then((response) => {
        console.log(response);
        window.location.href = response.data.sessionUrl;
      })
      .catch((error) => {
        openNotificationWithIcon(
          "Error!",
          "Unable to Create checkout",
          eqNotificationTypes.error
        );
      });
  };

  const openNotificationCheckOutMultipleEvents = (
    msg: any,
    desc: any,
    productOrders: any
  ) => {
    const btn = (
      <div className="right">
      <EqButton onClick={()=>GetProductOrderCheckoutSessionForMultiple(productOrders)}>Pay Now</EqButton>
  </div>
    );
    notification["info"]({
      message: msg,
      btn,
      description: desc,
    });
  };

  //   function ProductOrderCheckout(e: any) {
  //     // e.stopPropagation();
  //     // setStatus("accept");
  //
  //     Api.post(ApiEndpoints.ProductPaymentCheckoutSession, e)

  //   }




  function RejectAllProductOrderInvites(e: any) {
    Api.put(ApiEndpoints.ProductOrderRejectAll, e).then(
      (response) => {
        if (response && response.status == 200) {
          GetAllPendingStudentProductOrderInvites();
          setSteps(1);
        } else {
          setMessageString("");
        }
      },
      (err) => {
        setMessageString("");
      }
    );
  }
  function RejectProductOrderInvite(e: any) {
    // e.stopPropagation();
    // setStatus("accept");
    Api.put(ApiEndpoints.ProductOrderReject + e.id).then(
      (response) => {
        if (response && response.status == 200) {
          GetAllPendingStudentProductOrderInvites();
          setSteps(1);
        } else {
          setMessageString("");
        }
      },
      (err) => {
        setMessageString("");
      }
    );
  }

  const GetAllPendingStudentProductOrderInvites = () => {
    setMessageString("");
    
    Api.get(ApiEndpoints.ProductOrderStudentInvites).then(
      (response) => {
        if (response && response.status == 200) {
          var pendingInvites = [] as any;
          var data = response?.data ? response?.data : ([] as any);
          
          if (data.length > 0) {
            var grouped = data.reduce((acc: any, obj: any) => {
              const key = obj.product.user.email;
              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(obj);
              return acc;
            }, {});

            console.log(grouped);

            pendingInvites = Object.keys(grouped).map((key) => ({
              trainer: grouped[key][0].product.user,
              invites: grouped[key],
            }));
          }
          setProductOrderInvites(data);
          setPendingInvites(pendingInvites);
          if (data.length) {
            onloadActivity(true);
          } else {
            onloadActivity(false);
          }
          //data.map((d: any, index: number) => {
          //    pendingInvites.push(d);
          //});
          //console.log(pendingInvites);
        } else {
          setProductOrderInvites([]);
          setPendingInvites([]);
          setMessageString("Not found");
          onloadActivity(false);
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };
  const getAcceptedInvitations = () => {
    setMessageString("");
    Api.get(ApiEndpoints.getAcceptedUserEventsInvites).then(
      (response) => {
        if (response && response.status == 200) {
          var acceptedInvites = [] as any;
          var data = response?.data ? response?.data : ([] as any);
          setAcceptedInvites(data);
          //data.map((d: any, index: number) => {
          //    acceptedInvites.push(d);
          //});
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };
  const getAllInvitations = () => {
    setMessageString("");
    Api.get(ApiEndpoints.getAllUserEventsInvites).then(
      (response) => {
        if (response && response.status == 200) {
          console.log(response);

          var allInvites = [] as any;
          var data = response?.data ? response?.data : ([] as any);
          setProductOrderInvites(data);
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };
  const viewProductOrderDetail = (detail: any) => {
    if (steps === 1) {
      setSteps(2);
      console.log(detail);
      setProductOrderDetail(detail);
    } else {
      setSteps(1);
    }
  };




  // on click Accept
  const acceptClickedFun = (productOrder: any) => {
    
    // if(!userDetails.isSubscribed){
    //   openNotificationWithIcon(
    //     "Error!",
    //     "Kindly Subscribe to Join",
    //     eqNotificationTypes.error
    //   );
    // }
    // else 
    if (studentCanBook) {

      const isLateAllowed = productOrder?.product?.user.allowLatePayments || false;

      if (isLateAllowed) {
				AcceptProductOrderInvite(productOrder);
			} else {
				// checkout
				CreateCheckoutSession(productOrder.id);
			}
   
      // saved card is skipped for now

      // if (productOrder.student.hasCard) {
      //   // show popup Pay with save card or pay later
      //   confirmPayment(productOrder);
      // }
      // else{
      //   AcceptProductOrderInvite(productOrder)
      // }
    } else {
      openNotificationWithIcon(
        "Error!",
        "you don't have permission, contact Admin",
        // "Add Parent to buy",
        eqNotificationTypes.error
      );
    }
  };

  const confirmPayment = (productOrder: any) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Would you like to proceed with saved card",
      okText: "Saved Card",
      cancelText: "Pay Later",
      onOk: () => AcceptAndPayProductOrderInvite(productOrder),
      onCancel: () => AcceptProductOrderInvite(productOrder),
    });
  };

  function AcceptAndPayProductOrderInvite(e: any) {
    
    Api.put(ApiEndpoints.ProductOrderAcceptAndPay + e.id).then(
      (response) => {
        if (response && response.status == 202) {
          GetAllPendingStudentProductOrderInvites();
          setSteps(1);
          // payNow()
          openCheckOutNotificationProduct(
            "Card Failed Pay for the accepted order request",
            "",
            e.id
          );
        } else if (response && response.status == 200) {
          GetAllPendingStudentProductOrderInvites();

          setSteps(1);
          openNotificationWithIcon(
            "Accepted! ",
            "Invite Accepted and Paid",
            eqNotificationTypes.success
          );
        } else {
          setMessageString("");
        }
      },
      (err) => {
        setMessageString("");
      }
    );
  }

  function AcceptProductOrderInvite(e: any) {
    
    Api.put(ApiEndpoints.ProductOrderAccept + e.id).then(
      (response) => {
        
        if (response && response.status == 200) {
          GetAllPendingStudentProductOrderInvites();
          setSteps(1);
          // payNow()
          openCheckOutNotificationProduct(
            "Accepted!",
            "Pay for the accepted order request",
            e.id
          );
        } else {
          setMessageString("");
        }
      },
      (error) => {
        if (error.response.status == 409) {
          openNotificationWithIcon(
            "Conflict",
            `Product ${error.response.data.product.title} is Full can't accept now`
          );
          GetAllPendingStudentProductOrderInvites();
        }
        else {
          openNotificationWithIcon(
            "Server error",
            "Error occurred while creating the Product Order. Please try again or contact administrator."
          );
        }
      }
    );
  }

  const acceptAllClickedFun = (productOrders: any) => {
    let result = productOrders.map((item: any) => {
      return item.id;
    });
    // if(!userDetails.isSubscribed){
    //   openNotificationWithIcon(
    //     "Error!",
    //     "Kindly Subscribe  to join Trainings",
    //     eqNotificationTypes.error
    //   );
    // }
    // else 
    if (studentCanBook) {
      
      const isLateAllowed = productOrders[0]?.product?.user.allowLatePayments || false;
      if(isLateAllowed){
        AcceptAllProductOrderInvites(result);
        
      }else{
        GetProductOrderCheckoutSessionForMultiple(result)
      }
      
      // pay from card is skipped for now
      // if (productOrders[0].student.hasCard) {
      //   // show popup Pay with save card or pay later
      //   confirmAllPayment(result);
      // }
      // else{
      // AcceptAllProductOrderInvites(result);

      // }
    } else {
      openNotificationWithIcon(
        "Error!",
        "Add Parent to join Trainings",
        eqNotificationTypes.error
      );
    }

    console.log(productOrders);
    // setSteps(3);
  };

  const confirmAllPayment = (productOrders: any) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Would you like to proceed with saved card",
      okText: "Saved Card",
      cancelText: "Pay Later",
      onOk: () => AcceptAndPayAllProductOrderInvites(productOrders),
      onCancel: () => AcceptAllProductOrderInvites(productOrders),
    });
  };

  function AcceptAllProductOrderInvites(e: any) {
    console.log(e);
    var data = JSON.stringify(e);
    console.log(data);
 
    Api.put(ApiEndpoints.ProductOrderAcceptAll, e).then(
      (response) => {
        if (response && response.status == 200) {
          GetAllPendingStudentProductOrderInvites();
          setSteps(1);
          // payNow()
          openNotificationCheckOutMultipleEvents(
            "Accepted !",
            "Pay for the accepted order Requests",
            e
          );
        
        } else {
          setMessageString("");
        }
      },
      (error) => {
        console.error("API call failed:", error);
        if (error?.response?.status === 409) {
          openNotificationWithIcon(
            "Conflict",
            `Product ${error.response.data.product.title} is Full can't accept now`
          );
          GetAllPendingStudentProductOrderInvites();
        } else {
          openNotificationWithIcon(
            "Server error",
            "Error occurred while creating the Product Order. Please try again or contact administrator."
          );
        }
      }
    );
  }


  function AcceptAndPayAllProductOrderInvites(e: any) {
    console.log(e);
    var data = JSON.stringify(e);
    console.log(data);
 
    Api.put(ApiEndpoints.ProductOrderAcceptAndPayAll, e).then(
      (response) => {
        if (response && response.status == 202) {
          GetAllPendingStudentProductOrderInvites();
          setSteps(1);
          // payNow()
          openNotificationCheckOutMultipleEvents(
            "Pay for the accepted orderRequests",
            "",
            data
          );
        } else if (response && response.status == 200) {
          GetAllPendingStudentProductOrderInvites();

          setSteps(1);
          openNotificationWithIcon(
            "Accepted And Paid! ",
            "Invites Accepted and Paid",
            eqNotificationTypes.success
          );
        } else {
          setMessageString("");
        }
      },
      (err) => {
        setMessageString("");
      }
    );
  }

  const rejectClickedFun = (productOrder: any) => {
    RejectProductOrderInvite(productOrder);
    console.log(productOrder);
    // setSteps(5);
  };
  const rejectAllClickedFun = (productOrders: any) => {
    let result = productOrders.map((item: any) => {
      return item.id;
    });
    RejectAllProductOrderInvites(result);
    // setSteps(5);
  };
  React.useEffect(() => {
    GetAllPendingStudentProductOrderInvites();
    // getAcceptedInvitations();
    // console.log(productOrderInvites);
  }, []);
  return (
    <>
      <div className="eq-productOrderInvitationBoard-container">
        {/* <Row className="eq-productOrderInvitationBoard-row">
                    <Col className="eq-productOrderInvitationBoard-col">
                        <div className="eq-productOrderInvitationBoard-heading">Activity Invitations</div>
                    </Col>
                </Row> */}
        <div className="">
          {/*Pending Invites*/}
          {steps === 1 && pendingInvites && pendingInvites.length > 0
            ? [...pendingInvites].map((item: any, index: number) => (
                // <></>
                <EqProductOrderInvite
                  key={index}
                  {...{
                    invitesList: item,
                    setProductOrderDetail: viewProductOrderDetail,
                    step: steps,
                  }}
                ></EqProductOrderInvite>
              ))
            : ""}
        </div>
        <div className="">
          {/*All Invites*/}
          {steps === 2 && (
            <EqProductOrderInviteDetail
              {...{
                props: productOrderDetail,
                acceptClicked: acceptClickedFun,
                acceptAllClicked: acceptAllClickedFun,
                rejectClicked: rejectClickedFun,
                rejectAllClicked: rejectAllClickedFun,
                setProductOrderDetail: viewProductOrderDetail,
                step: steps,
              }}
            ></EqProductOrderInviteDetail>
          )}
        </div>
      </div>
      {contextHolder}
    </>
  );
};
export default EqProductInvitationBoard;
