import React, { useState } from "react";
import "./EqResetPassword.scss";
import Api from "../shared/Api";
import {
    ApiEndpoints,
    FrontEndPoints,
    GetRegistrationMessageKey,
} from "../shared/Common";
import { useNavigate } from "react-router-dom";
import {
    Form,
    Typography,
    Divider,
} from "antd";
import {
    CheckOutlined,
    EyeInvisibleOutlined,
    EyeTwoTone,
    UnlockFilled,
} from "@ant-design/icons";
import { EqInputPassword } from "../custom/eqInput/EqInput";
import { EqSpace } from "../custom/eqSpace/EqSpace";
import EqButton, { IEqButton } from "../custom/eqButton/EqButton";
import { useTranslation } from "react-i18next";


const EqResetPassword = () => {
    const { t, i18n } = useTranslation();
  
    const { Text, Title } = Typography;

   
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [isPasswordChanged, setIsPasswordChanged] = useState("");
    const [isError, setIsError] = useState("");
    const [passwordCriteria, setPasswordCriteria] = useState({
        minLength: false,
        capitalLetter: false,
        specialCharOrNumber: false,
        passIsValid:false,
    });
   
   
  
    const navigate = useNavigate();
    // const location = useLocation();


    // const { state } = useLocation();

    const tailLayout = {
        wrapperCol: { offset: 4, span: 16 },
    };




    // React.useEffect(() => {


    // }, []);


    // const changePassword = (): void => {
    //     debugger;
    //     if (
    //       !password.length ||
    //       !repeatPassword.length ||
    //       password !== repeatPassword
    //     ) {
    //       setIsError("please set password correctly!");
    //       return;
    //     }
    
    //     setIsError("");
    
    //     var searchParams = new URLSearchParams(window.location.search);
    //     Api.post(ApiEndpoints.UserAccount.ResetUserPassword, {
    //       password: password,
    //       repeatPassword: repeatPassword,
    //       email: searchParams.get("email"),
    //       EmailToken: searchParams.get("token"),
    //     }).then(
    //       (response) => {
    //         setIsError("");
    //         setIsPasswordChanged("Password changed, Please Login");
    //       },
    //       (err) => {
    //         setIsPasswordChanged("");
    //         setIsError("Error occurred");
    //       }
    //     );
    //   };


    const validateAndSetPassword = (evt: any): void => {
        const password = evt.target.value;
        const passwordLength = password.length;
        const hasCapitalLetter = /[A-Z]/.test(password);
        const hasSpecialCharOrNumber = /[0-9!@#$%^&*]/.test(password);

        const passIsValid= passwordLength && hasCapitalLetter && hasSpecialCharOrNumber
        setPasswordCriteria({
            minLength: passwordLength >= 8,
            capitalLetter: hasCapitalLetter,
            specialCharOrNumber: hasSpecialCharOrNumber,
            passIsValid:passIsValid
        });



        
        // Calculate the progress percentage based on password length (up to 8 characters)
        const progress = Math.min((passwordLength / 8) * 100, 100);
        // setProgressPercent(progress);
        


        setPassword(password);
    };

    const validateAndLoginUser = async () => {

        if(!passwordCriteria.passIsValid){
            setIsError("passwordError");
            return;
        }
        else if(!repeatPassword || !repeatPassword.length){
            setIsError("repeatPasswordError");
            return;
        }
        else if(password !== repeatPassword){
            setIsError("pwdMatchError");
            return;
        }
        
        // if (
        //   !password.length ||
        //   !repeatPassword.length ||
        //   password !== repeatPassword
        // ) {
        //   setIsError("passwordError");
        //   return;
        // }
    
        setIsError("");
    
        var searchParams = new URLSearchParams(window.location.search);
        Api.post(ApiEndpoints.UserAccount.ResetUserPassword, {
          password: password,
          repeatPassword: repeatPassword,
          email: searchParams.get("email"),
          EmailToken: searchParams.get("token"),
        }).then(
          (response) => {
            setIsError("");
            setIsPasswordChanged("passwordChanged");
            setPassword("")
            setRepeatPassword("")
          },
          (err) => {
            setIsPasswordChanged("");
            setIsError((GetRegistrationMessageKey(err.response.status)));
          }
        );

        
 
    };




    const buttonProps: IEqButton = {
        //changeFunc: validateUser,
        classNames: "eq-sign-btn",
    };


    const onFinishFailed = () => { };


    return (
        <div className="eq-forgot-password">
            <div className="eq-form-wrapper">
                {/* <Image className="eq-logo" src="logo.png" /> */}
                <Title className=" ant-col ant-col-20 ant-col-offset-2 custom-title">
                    {t("resetPassword")}
                </Title>
                <Form
                    {...tailLayout}
                    layout="vertical"
                    size="small"
                    // onFinish={validateUser}
                    onFinish={validateAndLoginUser}
                    onFinishFailed={onFinishFailed}
                >
             
                    <p className="label">{t("password")}</p>

                    <div className="eq-form-item-wrapper">
                        <EqInputPassword
                            placeholder={t("password")}
                            iconRender={(visible: any) =>
                                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                            }
                            prefix={<UnlockFilled />}
                            value={password}
                            onChange={(e: any) =>  validateAndSetPassword(e)}
                        />
                    </div>
                    <p className="label">{t("repeatPassword")}</p>

                    <div className="eq-form-item-wrapper">
                        <EqInputPassword
                            placeholder={t("repeatPassword")}
                            iconRender={(visible: any) =>
                                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                            }
                            prefix={<UnlockFilled />}
                            value={repeatPassword}
                            onChange={(e: any) => setRepeatPassword(e.target.value)}
                        />
                    </div>

                    {password.length > 0 && (
    <div className="password-criteria">
        <Text className={passwordCriteria.minLength ? 'valid' : 'invalid'}>
            {passwordCriteria.minLength ? <CheckOutlined /> : <span className="dot">•</span>} {t("passwordMin")} 
        </Text>
        <Text className={passwordCriteria.capitalLetter ? 'valid' : 'invalid'}>
            {passwordCriteria.capitalLetter ? <CheckOutlined /> : <span className="dot">•</span>} {t("passwordCap")}
        </Text>
        <Text className={passwordCriteria.specialCharOrNumber ? 'valid' : 'invalid'}>
            {passwordCriteria.specialCharOrNumber ? <CheckOutlined /> : <span className="dot">•</span>} {t("passwordNum")}
        </Text>
    </div>)}



                    <div className="eq-form-item-wrapper">
                        {isError && (
                            <span id="merrorMsg" className="error-message">
                                {t(isError)}
                            </span>
                        )}
                        {isPasswordChanged && (
                            <span id="merrorMsg" className="success-message">
                                {t(isPasswordChanged)}
                            </span>
                        )}
                    </div>

             
                    <div className="eq-form-item-wrapper">
                        <EqSpace direction="vertical" style={{ padding: "1% 8%" }}>

                        </EqSpace>
                    </div>
                    <div className="eq-form-item-wrapper">
                        <EqButton
                            type="primary"
                            htmlType="submit"
                            centered="centered"
                            {...buttonProps}
                            // onClick={()=>changePassword}
                        >
                            {t("resetPassword")}
                        </EqButton>
                    </div>
                  
                    <Divider plain>{t("or")}</Divider>

                    <div className="eq-form-item-wrapper">
                        <EqSpace
                            direction="vertical"
                            style={{
                                width: "100%",
                                padding: "1% 8%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <div className="eq-anchor-register">
                                {t("RememberPassword?")}{(" ")}
                                <a href={FrontEndPoints.login}

                                    // onClick={()=>{
                                    //     navigate(FrontEndPoints.login);
                                    // }}
                                // href={FrontEndPoints.register}
                                className="eq-register"
                                >
                                    {t("logIn")}
                                </a>
                            </div >
                        </EqSpace>
                    </div>
                </Form>
            </div>
        </div>
    );
};
export default EqResetPassword
