import React from "react";
import "./EqStudentProductOrderCard.scss";
import { Card, Col, Row } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AsyncImage from "../../custom/asyncImage/AsyncImage";
import Meta from "antd/lib/card/Meta";
import {
  EqCurrency,
  ProductOrderStatus,
  eqNotificationTypes,
} from "../../shared/enums";
import EqButton from "../../custom/eqButton/EqButton";
import { loadState } from "../../shared/LocalStorage";
import { useTranslation, Trans } from "react-i18next";
import Api from "../../shared/AApi";
import { ApiEndpoints, openNotificationWithIcon } from "../../shared/Common";
export interface IEqStudentProductOrderCard {
  productOrder: any;
}
export const EqStudentProductOrderCard = ({
  productOrder,
}: IEqStudentProductOrderCard) => {
  const { t, i18n } = useTranslation();

  const CreateCheckoutSession = (orderId: any) => {
    Api.get(
      ApiEndpoints.ProductPayment.GetProductOrderCheckoutSession + `/${orderId}`
    )
      .then((response) => {
        window.location.href = response.data.sessionUrl;
      })
      .catch((error) => {
        openNotificationWithIcon(
          "Error!",
          "Unable to Create checkout Session",
          eqNotificationTypes.error
        );
      });
  };
  React.useEffect(() => {
    console.log(productOrder);
  }, [productOrder]);

  return (
    <div className="student-product-card">
      <Card
        actions={[
          productOrder.status == ProductOrderStatus.Paid ? (
            <EqButton classNames="custom-action-buy" disabled>
              {t("paid")}
            </EqButton>
          ) : (
            <div className="right">
              <EqButton
                onClick={() =>CreateCheckoutSession(productOrder.id)}
                classNames="custom-action-buy"
                htmlType="submit"
              >
                {t("payNow")}
              </EqButton>
            </div>
          ),
        ]}
        // <EditOutlined />,
        // <BellOutlined />
        //  ]}
        // key={index}
        className="product-view"
        hoverable
        style={{ width: 350 }}
      >
        <Meta
          description={
            <>
              <Row className="">
                <Col>
                  <AsyncImage
                    className="product-img"
                    alt={productOrder.product.alt}
                    src={productOrder.product.imageUrl}
                  />
                </Col>
              </Row>
              <div className="product-detail">
                <Row className="product-heading">
                  <Col className="product-title">
                    {productOrder.product.title}
                  </Col>
                </Row>
                <Row className="product-description">
                  <Col>
                    <div className="description-text">
                      {productOrder.product.description}
                    </div>
                  </Col>
                </Row>
                <Row justify="space-between">
                  {productOrder.product.limit !== null && (
                    <Col className="product-info">
                      {t("capacity")}: {productOrder.product.limit}
                    </Col>
                  )}
                  {productOrder.product.lessons !== null && (
                    <Col className="product-info">
                      {t("lessons")}: {productOrder.product.lessons}
                    </Col>
                  )}
                  <Col className="product-info">
                    {t("price")}: {productOrder.product.price}{" "}
                    {EqCurrency[productOrder.product.currency]}
                  </Col>
                </Row>
              </div>
            </>
          }
        />
      </Card>
    </div>
  );
};
