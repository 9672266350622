import React, { useEffect, useState } from "react";
import "./EqInput.scss";
import { Input, InputNumber } from "antd";

export const EqInput = (props: any) => {

    return (
        // <div className="eqInput">
        <div className= {`eqInput ${props.classNames ? props.classNames : ""}`}>
            {<label className={`eq-label ${props.labelClassNames ? props.labelClassNames : ""}`}>{props.label}</label>}
            <Input  {...props} />
        </div>
    );
}

export const EqInputPassword = (props: any) => {
    return (
        <div className="eqInput-password">
            {props?.label?.length > 0 && <label className={`eq-label ${props.labelClassNames ? props.labelClassNames : ""}`}>{props.label}</label>}

      <Input.Password {...props} />
    </div>
  );
};

export const EqInputNumber = (props: any) => {
    return (
        <div className={`eqInput-number ${props.classNames ? props.classNames : ""}`}>
            {<label className={`eq-label ${props.labelClassNames ? props.labelClassNames: ""}`}>{props.label}</label>}
            <InputNumber {...props} />
        </div>
        );

}