import * as React from 'react';
import { useState, useReducer } from 'react';
import { LocalData } from '../shared/LocalStorage';

export const initialState: LocalData = {	
	isSignedin: false,
	accessToken: '',
	refreshToken:'',
	roles: [],
	permissions: [],
	imageUrl: '',
	name:'',
	activityData:{}
};

export enum ActionTypes {
	LoginSuccess,
	LoadFromHook,
	UpdateImage,
	UpdateKey,
	Logout,
	updateActivityData
}

export const AuthReducer = (initialState: LocalData, action: any) => {
	
	switch (action.type) {
		case ActionTypes.LoadFromHook:
			return initialState;

		case ActionTypes.LoginSuccess:
			
			var successObj = {
				...initialState,
				isSignedin: action.payload.isSignedin,
				accessToken: action.payload.accessToken,
				refreshToken:action.payload.refreshToken,
				roles: action.payload.roles,
				permissions: action.payload.permissions,
				imageUrl: action.payload?.imageUrl,
				key: action.payload?.key,
				name: action.payload?.name,
				isPaymentDue:  action.payload?.isPaymentDue,
				isSubscribed:  action.payload?.isSubscribed,
			};
			return successObj;

		case ActionTypes.UpdateImage:
			return {
				...initialState,
				imageUrl: action?.payload || ''
			}
		case ActionTypes.UpdateKey:
			return {
				...initialState,
				key: action?.payload || ''
			}
		case ActionTypes.Logout:
			return {
				...initialState,
				accessToken: '',
				isSignedin: false,
				roles: [], 
				permissions: [], 
				imageUrl:''
			};

			case ActionTypes.updateActivityData:
			
			return {
				
				...initialState,
				activityData: action?.payload || {}
			}

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
