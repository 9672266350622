import React, { useState } from "react";
import "./EqGroup.scss";
import EqStudent from "../../eqTrainer/eqStudent/EqStudent";
import { Row, Col, Checkbox, Radio, Popconfirm, Empty } from "antd";
import { useTranslation, Trans } from "react-i18next";
import { IStudentDto } from "../../shared/interfaces/IStudentDto";
import { IEqGroupDto } from "../../shared/interfaces/IEqGroup";
import {
  ApiEndpoints,
  FrontEndPoints,
  GetEventMessage,
  ValidateIsStudent,
  ValidateIsTrainer,
} from "../../shared/Common";
import history from "../../shared/history";
import { selectActionTabType } from "../../eqTrainer/eqGroup/EqGroup";
import EqGroupMessages from "../../eqTrainer/eqGroup/eqGroupMessages/EqGroupMessages";
import EqActivityCollapse from "../../eqTrainer/eqActivityCollapse/EqActivityCollapse";
import Api from "../../shared/Api";
import { useAuthState } from "../../context/Context";

export interface IEqGroup {
  groupData: IEqGroupDto;
  editFun?: any;
  delFun?: any;
}
const EqGroup = ({ groupData, editFun, delFun }: IEqGroup) => {
  const { t, i18n } = useTranslation();
  const userDetails: any = useAuthState();
  const isStudent: boolean = ValidateIsStudent(userDetails);
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const {
    groupKey,
    groupIcon,
    groupName,
    numberOfMessages,
    numberOfTrainings,
    sessionFrom,
    sessionTo,
    students,
    display,
    owner,
  } = groupData;
  const [show, setShow] = useState(display);
  const [eventsList, setEventsList] = React.useState([] as Array<any>);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  const [selectedActionTab, setSelectedActionTab] = useState(
    selectActionTabType.info
  ) as any;
  const selectActionTab = (param: any) => {
    setSelectedActionTab(param.target.value);
    if (param.target.value === 3 && eventsList.length === 0) {
      GetAllTrainingsByStudentGroup();
    }
    // else if (param.target.value === 2 && messages.length === 0) {
    //   GetMessages();
    // }
  };

  const [isCheckAll, setIsCheckAll] = useState(false);
  const checkAll = () => {
    setIsCheckAll(!isCheckAll);

    students.map((s: IStudentDto) => {
      s.selected = !isCheckAll;
    });
  };
  const deleteClicked = (e: any) => {
    e.stopPropagation();
    delFun(groupData);
  };
  const messageClicked = (e: any) => {
    e.stopPropagation();
    var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${groupKey}`;
    history.push(out1);
  };

  // Trainings
  const GetAllTrainingsByStudentGroup = async () => {
    console.log(groupKey);
    Api.post(ApiEndpoints.GetAllTrainingsByTrainerGroup, {
      key: groupKey,
    }).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          setEventsList(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  return (
    <>
      <div className="eq-group">
        <Row className="eq-group-header" justify="start" align="middle">
          <Col className="eq-group-header-name" flex="auto">
            {groupIcon}
            {groupName}
          </Col>
          <Col flex="auto">
            <div onClick={toggleShow}>
              <Row justify="end">
                <Col>
                  <div className="eq-action-tab">
                    <Radio.Group
                      value={selectedActionTab}
                      onChange={selectActionTab}
                    >
                      <Radio.Button value={selectActionTabType.info}>
                        <span className="gh-icon">
                          <img alt="" src="/images/icons/groups.svg" />
                        </span>
                        <span className="eq-gh-total">{students?.length}</span>
                      </Radio.Button>
                      <Radio.Button value={selectActionTabType.messages}>
                        <span className="gh-icon">
                          <img alt="" src="/orange-message.svg" />
                        </span>
                        <span className="eq-gh-total">{numberOfMessages}</span>
                      </Radio.Button>
                      <Radio.Button value={selectActionTabType.trainings}>
                        <span className="gh-icon">
                          <img alt="" src="/images/icons/events.svg" />
                        </span>
                        <span className="eq-gh-total">{numberOfTrainings}</span>{" "}
                      </Radio.Button>
                      {/* <Radio.Button value={selectActionTabType.delete}>
                        <Popconfirm
                          placement="bottomRight"
                          title={"Are you sure to delete"}
                          onConfirm={deleteClicked}
                          okText="Yes"
                          cancelText="No"
                        >
                          <span className="gh-icon">
                            <img alt="" src="/group-trash.svg" />
                          </span>
                        </Popconfirm>
                      </Radio.Button> */}
                    </Radio.Group>
                  </div>
                </Col>
                {/* <Col className="eq-group-header-icons">
                  <div className="eq-gh-total-icon">
                    <span className="gh-icon">
                      <img alt="" src="/orange-message.svg" />
                    </span>
                    <span className="eq-gh-total">{numberOfMessages}</span>
                  </div>
                  <div className="eq-gh-message-icon" onClick={messageClicked}>
                    <span className="gh-icon">
                      <img alt="" src="/group-message.svg" />
                    </span>
                  </div>
                  <div className="eq-gh-trash-icon" onClick={deleteClicked}>
                    <span className="gh-icon">
                      <img alt="" src="/group-trash.svg" />
                    </span>
                  </div>
                </Col> */}
                {/* <Col className="group-header-session">
                  <div className="eq-gh-clock-icon">
                    <span className="eq-btn-icon">
                      <img alt="" src="/clock.svg" />
                    </span>
                  </div>
                  {sessionFrom}-{sessionTo}
                </Col> */}
                <Col className="group-header-students">
                  <div className="eq-gh-addUser-icon">
                    <span className="gh-icon">
                      <img alt="" src="/addUser.svg" />
                    </span>
                  </div>
                  <div className="eq-gh-students">
                    |&nbsp;
                    {students?.length}&nbsp;{t("students")}
                  </div>
                </Col>
                <Col className="group-header-students">
                  <div className="eq-gh-addUser-icon">
                    <span className="gh-icon">
                      <img alt="" src="/Orange-User.svg" />
                    </span>
                  </div>
                  <div className="eq-gh-students">
                    |&nbsp;
                    {owner?.user?.firstName
                      ? owner?.user?.firstName
                      : owner?.user.userName}
                    &nbsp;
                  </div>
                </Col>
                <Col className="group-header-arrow" xs={24} sm={24} md={1}>
                  {show ? (
                    <span className="gh-arrow-icon">
                      <img alt="" src="/caret-up.svg" />
                    </span>
                  ) : (
                    <span className="gh-arrow-icon">
                      <img alt="" src="/caret-down.svg" />
                    </span>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {show && (
          <>
            {selectActionTabType.info == selectedActionTab && (
              <div className="eq-studentList-container">
                <Row className="eq-sl-row hidden-sm" justify="start" align="middle">
                 
                    <Col className="" xs={1} sm={1} md={1}>
                    {isStudent ? (
                    ""
                  ) : (
                      <Checkbox
                        onChange={checkAll}
                        checked={isCheckAll}
                      ></Checkbox>
                      )}
                    </Col>
                 

                  <Col className="" xs={5} sm={5} md={5}>
                    {t("name")}
                  </Col>
                  <Col className="" md={6} xs={6} sm={6}>
                    {t("e-mail")}
                  </Col>

                  <Col className="eq-s-td gutter-row " xs={5} sm={5} md={5} >
                  {t("phoneNumber")}

                  </Col>
                  {isStudent ? (
                    ""
                  ) : (
                    <Col className="" flex="auto">
                      {t("payments")}
                    </Col>
                  )}

                  <Col className="" md={5} xs={10} sm={10}>
                    {t("groups")}
                  </Col>
                  <Col className="" md={2} xs={2} sm={2}>
                    {t("actions")}
                  </Col>
                </Row>
                {students.length > 0 && (
                  <>
                    {students.map((item: IStudentDto, index: number) => (
                      <EqStudent key={index} {...{ student: item }}></EqStudent>
                    ))}
                  </>
                )}
              </div>
            )}

            {selectActionTabType.messages == selectedActionTab && (
              <div className="eq-tab-container eq-messages-container">
                {numberOfMessages ? (
                  <EqGroupMessages groupKey={groupKey}></EqGroupMessages>
                )
                  : (<Empty></Empty>)}
              </div>
            )}
            {selectActionTabType.trainings == selectedActionTab && (
              <div className="eq-tab-container eq-trainings-container">
                {eventsList?.length > 0 ? (
                  <>
                    {eventsList.map((item: any, index: number) => (
                      <EqActivityCollapse
                        {...{
                          event: { ...item },
                          //   removeEventFunc: removeEvent,
                          //   editEventFunc: editEvent,
                          //   onCreateOrUpdateActivity: trainerEventDefaults,
                        }}
                        key={index}
                      ></EqActivityCollapse>
                    ))}
                  </>
                ) : (
                  <Empty></Empty>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default EqGroup;
