import React, { useEffect } from 'react';
import './EqGroupList.scss';
import EqGroup, { IEqGroup } from '../eqGroup/EqGroup';
import { Row, Col } from 'antd';
export interface IEqGroupList {
    groupList: Array<IEqGroup>;
}
const EqGroupList = ({ groupList }: IEqGroupList) => {
    useEffect(() => {
        
        console.log(groupList);
    },[groupList])
    return (
        <>
            {groupList.length > 0 &&
                <>

                    {
                    groupList.map((item: IEqGroup, index: number) =>
                        <EqGroup key={index} {...item} ></EqGroup>
                        )
                    }
                </>
            }
            </>
        );
}
export default EqGroupList;